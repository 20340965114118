import { Button, Pagination, Typography } from "@cti-workspace/ui";
import { FormControl, TextField } from "@material-ui/core";
import { ArrowRight, CaretRight, FunnelSimple, MagnifyingGlass } from "@phosphor-icons/react";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import CAPABILITIES from "../../capabilities";
import CapabilityBoundary from "../CapabilityBoundary/CapabilityBoundary";
import { useNavigate, useLocation } from "react-router-dom";
import useNProgressHook from "../../hooks/useNProgressHook";
import FlexGrid from "../FlexGrid";
import { useSnackbar } from "notistack";
import BulkIssueMultiple from "./BulkIssueMultiple";
import { getErrMsg, bulkIssueCredDefsFromXlsx, SORT_OPTION, SORT_QUERY_OPTIONS } from "@cti-workspace/helpers";
import useWidthHook from "../../hooks/useWidthHook";
import styled from 'styled-components';

export default function Credentials({
	onClickLoadMoreCredentials = (pageNumber = 0, searchTxt = "", clearCredArray = false, sortBy = "") => { },
	credentials = { items: [], pageNumber: 0, pageSize: 0, totalItems: 0 },
	enableDefineCredential = true,
	handleIssueCredentialButton = () => { },
	copyCredentialDefinition = () => { },
	archiveCredentialDefinition = () => { },
	deleteCredentialDefinition = () => { },
	connectedCredsData,
	googleClassroomHook = {
		deleteConnectedCredential: () => { },
	},
	certificates,
	setCredentials = () => { },
	bulkAddCreds = () => { },
	getCredsPerPage = () => { },
}) {
	const { enqueueSnackbar } = useSnackbar();

	const [querySearchValue, setQuerySearchValue] = useState("");
	const [queryPageValue, setQueryPageValue] = useState(1);
	const [selectedSort, setSelectedSort] = useState("atoz");

	const importCredentialButtonRef = useRef(null);
	const bulkCredentialTemplateDownloadRef = useRef(null);

	const { progressStart, progressDone } = useNProgressHook();

	const navigate = useNavigate();
	const location = useLocation();
	const clientWidth = useWidthHook();

	useEffect(() => {
		const sortVal = localStorage.getItem(SORT_OPTION);
		setSelectedSort(sortVal || "atoz");
		if (location && location.search) {
			const searchUrlQueryVal = new URLSearchParams(location.search).get("search");
			const pageQueryVal = new URLSearchParams(location.search).get("page");
			setQuerySearchValue(searchUrlQueryVal);
			setQueryPageValue(pageQueryVal || 1);
			onClickLoadMoreCredentials(pageQueryVal || 1, searchUrlQueryVal, true, SORT_QUERY_OPTIONS[sortVal || "atoz"]);
		}
	}, [clientWidth]);

	const reloadCredsByQuery = () => {
		onClickLoadMoreCredentials(queryPageValue || 1, querySearchValue, true, SORT_QUERY_OPTIONS[selectedSort]);
	};

	const onClickImportCredentials = async (e) => {
		progressStart();
		try {
			const credDefRows = await bulkImportCredentialDefinitions(e);
			if (credDefRows && credDefRows.length) {
				const results = await bulkAddCreds(credDefRows);
				if (results && results.length) {
					getCredsPerPage(1, 1, "");
				}
			}
		} catch (err) {
			enqueueSnackbar(getErrMsg(err), {
				variant: "error",
			});
		}
		progressDone();
	};

	const bulkImportCredentialDefinitions = async (event) => {
		try {
			if (!event.target.files) throw new Error("No file found...");
			const excelSheet = event.target.files[0];

			// Clear the submission
			event.target.value = null;

			return await bulkIssueCredDefsFromXlsx({ excelSheet });
		} catch (err) {
			enqueueSnackbar(getErrMsg(err), {
				variant: "error",
			});
		}
	};

	const onPageChange = ({ page }) => {
		const path = (location && location.pathname) || "";
		navigate(path + `?search=${querySearchValue}&page=${page}`, { replace: true });
		setQueryPageValue(page);
		onClickLoadMoreCredentials(page, querySearchValue, true, SORT_QUERY_OPTIONS[selectedSort]);
	};

	const handleSort = (e) => {
		const path = (location && location.pathname) || "";
		navigate(path + `?search=${querySearchValue}&page=${1}`, { replace: true });
		onClickLoadMoreCredentials(1, querySearchValue, true, SORT_QUERY_OPTIONS[e]);
		localStorage.setItem(SORT_OPTION, e);
		setSelectedSort(e);
	}

	return (
		<>
			{/* search box and define credential button */}
			<CredentialSearchBar onClickLoadMoreCredentials={onClickLoadMoreCredentials} navigate={navigate} location={location} defaultSearchValue={querySearchValue} getSearchValue={setQuerySearchValue} selectedSort={selectedSort} />
			<div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between", paddingTop: "1rem" }}>
				<div>
					<SortDropDown onClickSort={handleSort} selectedSort={selectedSort} disabled={!credentials?.items?.length} />
				</div>
				<div style={{ display: "flex", gap: "0.5rem" }}>
					<input style={{ display: "none" }} onChange={onClickImportCredentials} ref={importCredentialButtonRef} type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
					<BulkIssueMultiple
						reloadCreds={({ type, payload: { length, _id } }) => {
							switch (type) {
								case "ISSUED_CRED_COUNT_UPDATE":
									let { items = {} } = credentials;
									const credIndex = items.findIndex((item) => item._id === _id);
									if (credIndex >= 0) {
										items[credIndex]["recipients"] = (items[credIndex]["recipients"] || 0) + length;
										setCredentials({
											...credentials,
											items,
										});
									}
							}
						}}
					/>
					<Button
						color="warning"
						invert
						leftIcon="plus-circle"
						fullWidth={false}
						buttonType="split"
						splitButtonColor="warning"
						splitButtonOptions={[
							{
								label: <span>Import Credentials</span>,
								icon: "upload",
								onClick: () => importCredentialButtonRef && importCredentialButtonRef.current && importCredentialButtonRef.current.click(),
							},
							{
								label: (
									<span>
										Download XLSX Template
                    <a style={{ display: "none" }} ref={bulkCredentialTemplateDownloadRef} href="https://ipwru.s3.us-east-1.amazonaws.com/prod/Example+Bulk+Credential+Import.xlsx" download="credential-definitions.xlsx"></a>
									</span>
								),
								icon: "cloud-download",
								onClick: () => bulkCredentialTemplateDownloadRef && bulkCredentialTemplateDownloadRef.current && bulkCredentialTemplateDownloadRef.current.click(),
							},
						]}
						onClick={() =>
							navigate({
								pathname: `/credentialDefinition/new`,
								state: { background: location },
							})
						}
						disabled={!enableDefineCredential}>
						Define Credential
					</Button>
				</div>
			</div>
			<FlexGrid
				handleIssueCredentialButton={handleIssueCredentialButton}
				credDefs={credentials["items"] || []}
				connectedCredsData={connectedCredsData}
				certificates={certificates}
				archiveCredentialDefinition={archiveCredentialDefinition}
				deleteCredentialDefinition={async (event) => {
					try {
						await deleteCredentialDefinition(event, false);
						reloadCredsByQuery();
					} catch (error) { }
				}}
				deleteConnectedCredential={googleClassroomHook.deleteConnectedCredential}
				copyCredentialDefinition={async (event) => {
					try {
						await copyCredentialDefinition(event, false);
						reloadCredsByQuery();
					} catch (error) { }
				}}
				setSelectedCredToEdit={(val) => {
					navigate({
						pathname: `/credentialDefinition/${val._id}/edit`,
						state: { background: location },
					});
				}}
				reloadCreds={({ type, payload: { length, _id } }) => {
					switch (type) {
						case "ISSUED_CRED_COUNT_UPDATE":
							let { items = {} } = credentials;
							const credIndex = items.findIndex((item) => item._id === _id);
							items[credIndex]["recipients"] = (items[credIndex]["recipients"] || 0) + length;
							setCredentials({
								...credentials,
								items,
							});
					}
				}}
			// setSelectedCredToEdit={setSelectedCredToEdit}
			/>
			{(credentials["totalItems"] || 0) > (credentials["items"] || []).length && (
				<div style={{ marginTop: "40px" }}>
					<Pagination onPageChange={onPageChange} totalItems={credentials["totalItems"] || 0} page={credentials["currentPageNumber"] || 1} pageSize={credentials["currentPageSize"] || 0} />
				</div>
			)}
		</>
	);
}

function CredentialSearchBar({ onClickLoadMoreCredentials = () => { }, navigate, location, defaultSearchValue = "", getSearchValue = (val) => { }, selectedSort = "" }) {
	const [searchValue, setSearchValue] = useState(defaultSearchValue);

	const onClickClearSearch = () => {
		const path = (location && location.pathname) || "";
		navigate && navigate(path, { replace: true });
		setSearchValue("");
		getSearchValue("");
		onClickLoadMoreCredentials(1, "", true, SORT_QUERY_OPTIONS[selectedSort]);
	};

	const onSearchClick = () => {
		const path = (location && location.pathname) || "";
		navigate && navigate(path + `?search=${searchValue}`, { replace: true });
		getSearchValue(searchValue);
		onClickLoadMoreCredentials(1, searchValue, true, SORT_QUERY_OPTIONS[selectedSort]);
	};

	return (
		<CapabilityBoundary
			render={() => (
				<FormControl fullWidth>
					<TextField
            id="standard-adornment-amount"
            fullWidth
            color="secondary"
            InputProps={{
              startAdornment: <MagnifyingGlass size="1.3rem" weight="bold" style={{ marginRight: "1rem" }} />,
              endAdornment: (
                <>
                  {searchValue && <Button buttonType="icon" icon="cancel" invert color="danger" iconSize="1.3rem" buttonStyle={{ marginRight: "0.5rem" }} onClick={onClickClearSearch} />}
                  <Button color="warning" invert={searchValue ? false : true} onClick={onSearchClick}>
                    Search
                  </Button>
                </>
              ),
            }}
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyPress={(ev) => {
               if (ev.key === 'Enter') {
                onSearchClick();
                ev.preventDefault();
              }
            }}
					/>
				</FormControl>
			)}
			capability={CAPABILITIES.SEARCH_CREDENTIAL_DEFINITIONS}
			hide
		/>
	);
}

const SortDropDownContainer = styled.div`
	position: relative;
`;

const SortDropDownButtonContainer = styled.div`
	width: 9rem;
	border: 1px solid #C0C0C0;
	padding: 0.4rem 0.65rem;
	box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	cursor: ${({ disabled }) => disabled ? "not-allowed" : "pointer"};
	background-color: ${({ disabled }) => disabled ? "#DDDDDD" : "#FFFFFF"};
`;

const SortDropDownButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 1;
`;

const SortButtonText = styled(Typography)`
	display: flex;
	align-items: center;
	gap: 0.2rem;
	font-size: 0.75rem;
	cursor: ${({ disabled }) => disabled ? "not-allowed" : "pointer"};
`;

function SortDropDown({ onClickSort = () => { }, selectedSort = "atoz", disabled = false }) {
	const sortOptionsKeyVal = {
		atoz: <>Sort A <ArrowRight /> Z</>,
		ztoa: <>Sort Z <ArrowRight /> A</>,
		modifyFirst: "Most recent first",
		modifyLast: "Most recent last",
	}

	const [display, setDisplay] = useState(false);

	const ref = useRef(null);
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				onClickOutside && onClickOutside();
			}
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [])

	const onClickOutside = () => {
		setDisplay(false);
	}

	const handleSortClick = (e) => {
		onClickSort && onClickSort(e);
		setDisplay(false);
	}

	return <SortDropDownContainer>
		<SortDropDownButtonContainer disabled={disabled}>
			<SortDropDownButton onClick={() => { if (!display && !disabled) setDisplay(true) }}>
				<SortButtonText fontWeight="bold" disabled={disabled}>{sortOptionsKeyVal[selectedSort]}</SortButtonText>
				<FunnelSimple />
			</SortDropDownButton>
		</SortDropDownButtonContainer>
		{display && <SortDropDownOptions handleSortClick={handleSortClick} ref={ref} selectedSort={selectedSort} />}
	</SortDropDownContainer>
}

const SortDropDownOptionContainer = styled.div`
	background: white;
	box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	position: absolute;
	width: 100%;
	z-index: 1;
`;

const SortDropDownOption = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.6rem 0.65rem;
	border-bottom: 0.5px solid #E0E0E0;
	cursor: pointer;
`;

export const SortDropDownOptions = forwardRef(({ handleSortClick = () => { }, selectedSort = "atoz" }, ref) => {
	const [display, setDisplay] = useState(false);

	return <div ref={ref}>
		<SortDropDownOptionContainer>
			<SortDropDownOption onClick={() => handleSortClick("atoz")}>
				<SortButtonText fontWeight={selectedSort === "atoz" ? "bold" : "normal"}>Sort A <ArrowRight /> Z</SortButtonText>
			</SortDropDownOption>
			<SortDropDownOption onClick={() => handleSortClick("ztoa")}>
				<SortButtonText fontWeight={selectedSort === "ztoa" ? "bold" : "normal"}>Sort Z <ArrowRight /> A</SortButtonText>
			</SortDropDownOption>
			<div style={{ position: "relative", display: "flex" }}>
				<SortDropDownOption style={{ borderBottom: "none", width: "100%", backgroundColor: "#FFFFFF" }} onClick={() => setDisplay(true)}>
					<SortButtonText>Last modified</SortButtonText>
					<CaretRight />
				</SortDropDownOption>
				{
					display &&
					<SortDropDownOptionContainer style={{
						left: "100%"
					}}>
						<SortDropDownOption onClick={() => handleSortClick("modifyFirst")}>
							<SortButtonText fontWeight={selectedSort === "modifyFirst" ? "bold" : "normal"}>Most recent first</SortButtonText>
						</SortDropDownOption>
						<SortDropDownOption fontWeight={selectedSort === "modifyLast" ? "bold" : "normal"} onClick={() => handleSortClick("modifyLast")}>
							<SortButtonText>Most recent last</SortButtonText>
						</SortDropDownOption>
					</SortDropDownOptionContainer>
				}
			</div>
		</SortDropDownOptionContainer>
	</div>
})
