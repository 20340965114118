import { createRef, useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import { IntlProvider, addLocaleData } from "react-intl";
import messages_fr from "../translations/fr.json";
import messages_en from "../translations/en.json";
import { SnackbarProvider } from "notistack";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

import publicRoute from "../routes/Routes";

import lightTheme from "../lightTheme";
import darkTheme from "../darkTheme";

import { useDarkMode } from "../hooks/useDarkModeHook";
import { ModalProvider } from "styled-react-modal";
import { JSHINT } from "jshint";

import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "./App.css";

window.JSHINT = JSHINT;

const messages = {
	fr: messages_fr,
	en: messages_en,
};

let language = process.env.NX_REACT_APP_LANGUAGE || navigator.language.split(/[-_]/)[0];

function App() {
	const notistackRef = createRef();
	const onClickDismiss = (key) => () => {
		notistackRef.current.closeSnackbar(key);
	};

	if (messages?.language) {
		language = "en";
	}

	useEffect(() => {
		(async () => {
			const fontCheck = new Set(
				[
					// Windows 10
					"Arial",
					"Arial Black",
					"Bahnschrift",
					"Calibri",
					"Cambria",
					"Cambria Math",
					"Candara",
					"Comic Sans MS",
					"Consolas",
					"Constantia",
					"Corbel",
					"Courier New",
					"Ebrima",
					"Franklin Gothic Medium",
					"Gabriola",
					"Gadugi",
					"Georgia",
					"HoloLens MDL2 Assets",
					"Impact",
					"Ink Free",
					"Javanese Text",
					"Leelawadee UI",
					"Lucida Console",
					"Lucida Sans Unicode",
					"Malgun Gothic",
					"Marlett",
					"Microsoft Himalaya",
					"Microsoft JhengHei",
					"Microsoft New Tai Lue",
					"Microsoft PhagsPa",
					"Microsoft Sans Serif",
					"Microsoft Tai Le",
					"Microsoft YaHei",
					"Microsoft Yi Baiti",
					"MingLiU-ExtB",
					"Mongolian Baiti",
					"MS Gothic",
					"MV Boli",
					"Myanmar Text",
					"Nirmala UI",
					"Palatino Linotype",
					"Segoe MDL2 Assets",
					"Segoe Print",
					"Segoe Script",
					"Segoe UI",
					"Segoe UI Historic",
					"Segoe UI Emoji",
					"Segoe UI Symbol",
					"SimSun",
					"Sitka",
					"Sylfaen",
					"Symbol",
					"Tahoma",
					"Times New Roman",
					"Trebuchet MS",
					"Verdana",
					"Webdings",
					"Wingdings",
					"Yu Gothic",
					// macOS
					"American Typewriter",
					"Andale Mono",
					"Arial",
					"Arial Black",
					"Arial Narrow",
					"Arial Rounded MT Bold",
					"Arial Unicode MS",
					"Avenir",
					"Avenir Next",
					"Avenir Next Condensed",
					"Baskerville",
					"Big Caslon",
					"Bodoni 72",
					"Bodoni 72 Oldstyle",
					"Bodoni 72 Smallcaps",
					"Bradley Hand",
					"Brush Script MT",
					"Chalkboard",
					"Chalkboard SE",
					"Chalkduster",
					"Charter",
					"Cochin",
					"Comic Sans MS",
					"Copperplate",
					"Courier",
					"Courier New",
					"Didot",
					"DIN Alternate",
					"DIN Condensed",
					"Futura",
					"Geneva",
					"Georgia",
					"Gill Sans",
					"Helvetica",
					"Helvetica Neue",
					"Herculanum",
					"Hoefler Text",
					"Impact",
					"Lucida Grande",
					"Luminari",
					"Marker Felt",
					"Menlo",
					"Microsoft Sans Serif",
					"Monaco",
					"Noteworthy",
					"Optima",
					"Palatino",
					"Papyrus",
					"Phosphate",
					"Rockwell",
					"Savoye LET",
					"SignPainter",
					"Skia",
					"Snell Roundhand",
					"Tahoma",
					"Times",
					"Times New Roman",
					"Trattatello",
					"Trebuchet MS",
					"Verdana",
					"Zapfino",
				].sort()
			);

			await window.document.fonts.ready;

			const fontAvailable = new Set();

			for (const font of fontCheck.values()) {
				if (window.document.fonts.check(`12px "${font}"`)) {
					fontAvailable.add(font);
				}
			}

			// console.log("Available Fonts:", [...fontAvailable.values()]);

			let { fonts } = window.document;
			const it = fonts.entries();

			let arr = [];
			let done = false;

			while (!done) {
				const font = it.next();
        if (!font.done) {
          if (font.value && font.value[0]) {
            arr.push(font.value[0].family);
          }
				} else {
					done = font.done;
				}
			}

			// converted to set then arr to filter repetitive values
			// console.log([...new Set(arr)]);
		})();
	}, []);

	const [theme, toggleTheme, componentMounted] = useDarkMode();
	const themeMode = theme === "light" ? lightTheme : darkTheme;

	const queryClient = new QueryClient();

	if (!componentMounted) {
		return <div />;
	}
	return (
		<ThemeProvider theme={themeMode}>
			<CssBaseline />
			<QueryClientProvider client={queryClient}>
				<ModalProvider>
					<IntlProvider locale={language} messages={messages[language]}>
						<SnackbarProvider
							maxSnack={3}
							ref={notistackRef}
							id="snack-bar"
							action={(key) => (
								<Button onClick={onClickDismiss(key)}>
									<CloseIcon style={{ color: "white" }} />
								</Button>
							)}>
							<RouterProvider router={publicRoute} />
							{/* <Routes /> */}
						</SnackbarProvider>
					</IntlProvider>
				</ModalProvider>
			</QueryClientProvider>
		</ThemeProvider>
	);
}

export default App;
