import { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DropzoneDialog, TextField, FieldWrapper, Icon, Card, Typography as Text } from "@cti-workspace/ui";
import { useFieldArray, useWatch } from "react-hook-form";
import { useSnackbar } from "notistack";
import { getErrMsg, isValidURL, IMAGE, PDF, URL, resourceTypesText, getObjectValue } from "@cti-workspace/helpers";

const Resources = ({ showKey = true, register, ...props }) => {
	const [showImagePdfAddDialog, setshowImagePdfAddDialog] = useState(false);
	const [dialogProps, setdialogProps] = useState({});
	const [urlOpen, setUrlOpen] = useState(false);
	const [urlKey, setUrlKey] = useState("");
	const [urlValue, setUrlValue] = useState("");
	const { enqueueSnackbar } = useSnackbar();

	const fieldTypeMapper = {
		[PDF]: "PDF Resource",
		[IMAGE]: "Image Resource",
		[URL]: "URL Resource",
	};

	const values = useWatch({
		control: props?.control,
	});

	const onAddBtnClick = (params) => {
		if (params.type === URL) {
			setUrlOpen(true);
			setUrlKey("");
		} else {
			setdialogProps(params);
			setshowImagePdfAddDialog(true);
		}
	};

	const { fields, append, remove } = useFieldArray({
		control: props?.control,
		name: props?.name,
		keyName: "id",
	});

	const isDuplicateResource = (key, value) => {
		return fields.some((field) => field.key === key || field.value === value);
	};

	const getResourceType = (type) => {
		if (type === "application/pdf") {
			return PDF;
		} else {
			return IMAGE;
		}
	};

	const onUpload = async (file) => {
		if (Object.keys(file).length) {
			try {
				if (file.size > 5120000) throw new Error("File size too large, should be less than 5MB");
				const fileName = file.name;
				const url = file.src;
				const key = props.useInitialKeyName ? fileName : fields.length === 0 ? "default" : fileName;
				if (isDuplicateResource(key, url)) throw new Error(`${getObjectValue(resourceTypesText, file?.type) || "File"} already exsists`);
				append({ key: key, originalFileName: key === "default" ? undefined : key, type: getResourceType(file.type), value: url });
			} catch (err) {
				enqueueSnackbar(getErrMsg(err), { variant: "error" });
			}
		}
		setshowImagePdfAddDialog(false);
	};

	const onError = (err) => {
		enqueueSnackbar(getErrMsg(err), { variant: "error" });
	};

	let e = getObjectValue(props?.errors, props?.name);

	const closeUrlDialog = () => {
		setUrlKey("");
		setUrlValue("");
		setUrlOpen(false);
	};

	const onAddUrl = () => {
		try {
			if (!isValidURL(urlValue)) throw new Error("Invalid Url");
			if (isDuplicateResource(urlKey, urlValue)) throw new Error("URL already exsists");
			append({
				key: urlKey,
				type: URL,
				value: urlValue,
			});
			closeUrlDialog();
		} catch (err) {
			enqueueSnackbar(getErrMsg(err), {
				variant: "error",
			});
		}
	};

	return (
		<Card hasBorder elevation={0} borderRadius="2px" style={{ backgroundColor: "#FAFAFA", padding: "0.8rem", margin: "0" }}>
			<Text type="body1">{props?.displayName}</Text>
			{/* <p style={{ marginBottom: "20px" }}>Add supporting material, links or resources to better explain the credential</p> */}

			<div style={{ display: "flex", gap: "0.7rem", marginTop: "0.5rem" }}>
				{props?.addTypes?.map(({ displayName, type, leftIcon }) => (
					<Button leftIcon={leftIcon} key={displayName} color="warning" invert leftIconColor="black" onClick={() => onAddBtnClick({ displayName, type })} buttonStyle={{ padding: "6px" }}>
						{displayName}
					</Button>
				))}
			</div>

			{fields.map((item, index) => {
				register(`${props?.name}.${index}.originalFileName`, {
					setValueAs: () => {
						return item.originalFileName === "default" ? undefined : item.originalFileName;
					},
				});
				return (
					<FieldWrapper key={`${item?.id}_${index}`} title={getObjectValue(fieldTypeMapper, item.type)} onClose={() => remove(index)} style={{ marginTop: "1rem" }}>
						<div style={{ display: "flex", gap: "0.8rem" }}>
							<div style={{ width: "80px", height: "76px", backgroundColor: "#E0E0E0", padding: "0.25rem", borderRadius: "4px", display: "flex", justifyContent: "center", alignItems: "center" }}>
								{item.type === IMAGE && <img style={{ maxWidth: "100%", maxHeight: "100%" }} src={item.value} />}
								{item.type === PDF && <Icon name="pdf" size={30} />}
								{item.type === URL && <Icon name="link-simple-horizontal" size={30} />}
							</div>

							<div style={{ display: "flex", gap: "1rem", flexBasis: "100%" }}>
								<TextField
									inputRef={register({
										required: "Name is required",
										validate: (value) => {
											if (props.validationErrorMessage) {
												let errorVal = "";
												values[props.name] &&
													values[props.name].forEach((field, i) => {
														if (field.key === value && index !== i) {
															errorVal = props.validationErrorMessage;
														}
													});
												if (errorVal) {
													return props.validationErrorMessage;
												}
											}
										},
									})}
									label="Name"
									variant="static"
									size="small"
									style={{ flexGrow: 1 }}
									name={`${props?.name}[${index}].key`}
									id={`${props?.name}${index}`}
									error={e?.length && e[index] && e[index].key ? true : false}
									defaultValue={item.key === "default" && props.defaultIconName ? props.defaultIconName : item.key}
									fullWidth
									helperText={e?.length && e[index] ? getObjectValue(e[index], "key.message", "") : ""}
								/>
								<TextField
									style={{ visibility: item?.type === URL ? "visible" : "hidden", flexGrow: "1" }}
									inputRef={register({
										required: "Value is required",
									})}
									variant="static"
									id={`${props?.name}${index}-value`}
									name={`${props?.name}[${index}].value`}
									defaultValue={item.value}
									fullWidth
									label="Value"
									size="small"
									hidden
								/>
							</div>
						</div>
					</FieldWrapper>
				);
			})}

			<Dialog borderRadius="8px" maxWidth="xs" disableBackdropClick disableEscapeKeyDown open={urlOpen} onClose={() => setUrlOpen(false)}>
				<DialogTitle>
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<Text type="body2">Enter URL</Text>
						<Button buttonType="icon" icon="cancel" color="primary" iconSize="0.9rem" invert onClick={closeUrlDialog} />
					</div>
				</DialogTitle>
				<DialogContent>
					<TextField id="key" label="Name" size="small" variant="static" defaultValue={urlKey} onChange={(e) => setUrlKey(e?.target?.value)} helperText="Display Name of Url" />
					<TextField id="value" label="Value" size="small" variant="static" defaultValue={urlValue} onChange={(e) => setUrlValue(e?.target?.value)} helperText="https:// based url" />
				</DialogContent>
				<DialogActions>
					<Button color="white" onClick={closeUrlDialog}>
						Cancel
					</Button>
					<Button color="warning" onClick={onAddUrl}>
						Done
					</Button>
				</DialogActions>
			</Dialog>

			<DropzoneDialog
				submitButtonText="Upload"
				open={showImagePdfAddDialog}
				onSave={onUpload}
				acceptedFiles={dialogProps?.type}
				dialogTitle={`${dialogProps.displayName}`}
				dropzoneText={"Drag and Drop / Select"}
				showPreviews
				clearOnUnmount
				maxFileSize={5000000}
				onError={onError}
				onClose={() => setshowImagePdfAddDialog(false)}
			/>
		</Card>
	);
};

export default Resources;
