import { IMAGE_CONTENT } from "@cti-workspace/helpers";
import DataKeyValueField from "../DataKeyValueField";
import DataKeyValueFileField from "../DataKeyValueField/DataKeyValueFileField";

const TranscriptValueForm = ({ transcripts = [], transcriptGrades = {}, settranscriptGrades = () => {} }) => {
	return (
		<div>
			{transcripts &&
				transcripts.map((item, i) =>
					item.grade !== IMAGE_CONTENT ? (
						<DataKeyValueField key={i} name={item.name} transcriptType={item.transcriptType} transcriptDefaultValue={item.grade} transcriptGrades={transcriptGrades} settranscriptGrades={settranscriptGrades} required={item.required || false} />
					) : (
						<DataKeyValueFileField key={i} name={item.name} transcriptType={item.transcriptType} transcriptGrades={transcriptGrades} settranscriptGrades={settranscriptGrades} required={item.required || false} />
					)
				)}
		</div>
	);
};

export default TranscriptValueForm;
