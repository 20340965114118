import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from "@cti-workspace/ui";
import { useRef, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import useNProgressHook from "../../hooks/useNProgressHook";
import { useSnackbar } from "notistack";
import { ISSUER_PREFIX, parseExcelSheetToJson, getApi, postApi, checkFileExtension, getObjectValue } from "@cti-workspace/helpers";
import Alert from "@material-ui/lab/Alert";
import BulkIssue from "../BulkIssue";
import { userStore } from "../../store/userStore";
import useSettingsHook from "../../hooks/useSettingsHook";

const BulkIssueMultiple = ({ reloadCreds }) => {
	const { progressStart, progressDone } = useNProgressHook();
	const { enqueueSnackbar } = useSnackbar();
	const organizations = userStore((state) => state.organizations);
	const [openModal, setOpenModal] = useState(null);
	const [open, setOpen] = useState(false);
	const [bulkIssueState, setBulkIssueState] = useState({
		dialogue: false,
		data: null,
		error: null,
		selectedCred: null,
		modalType: "issuemany",
	});

	const [multipleCredentials, setMultipleCredentials] = useState([]);
	const [reviewingIndex, setReviewingIndex] = useState(-1);
	const [fetchingCredentialDefinitions, setFetchingCredentialDefinitions] = useState(false);
	const [issuingMultiCredentials, setIssuingMultiCredentials] = useState(false);
	const certs = useRef([]);

	const { canView } = useSettingsHook();

	const handleOpenModal = (modalType = "issuemany") => {
		switch (modalType) {
			case "issuemany":
				setOpenModal({
					title: "Bulk Issue Multiple Credentials",
					modalType,
				});
				break;
			case "savemany":
				setOpenModal({
					title: "Save Many",
					modalType,
				});
				break;
		}
	};

	const anchorRef = useRef(null);

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleCloseModal = () => {
		setOpenModal(null);
		resetMultiIssueState();
	};

	const getSectionHeaders = (credential) => {
		try {
			return JSON.parse(credential.definition)
				.additionalData.sections.map((section) => {
					return section.claims.map((claim) => `section_${section.name.toLowerCase()}_${claim.toLowerCase()}`);
				})
				.flat();
		} catch (e) {
			console.error(e);
			return [];
		}
	};

	const getUserOrganizationIds = () => {
		return Object.entries(organizations).map((org) => org[1]._id);
	};

	const getMultipleCredentials = async (sheetData) => {
		// console.log(sheetData);
		setFetchingCredentialDefinitions(true);
		const ids = new Set(sheetData.data.map((d) => d.id));

		try {
			sheetData.arr[0] = sheetData.arr[0].map((row) => row.toLowerCase());

			const getDefinitions = Array.from(ids).map((id) => getApi(`/${ISSUER_PREFIX}/getCredentialDefinitionById/${id}`));
			const definitions = await Promise.all(getDefinitions);
			const userOrganizationIds = getUserOrganizationIds();

			const invalidDefinitions = definitions.some((definition) => !userOrganizationIds.includes(definition.issuerOrganization));
			if (invalidDefinitions) {
				enqueueSnackbar("One or more credential definitions do not belong to the same organization as the user.", { variant: "error" });
				setBulkIssueState({
					...bulkIssueState,
					error: { msg: "One or more credential definitions do not belong to the same organization as the user. Please check the credential Ids of the sheet and try again." },
				});
				return;
			}

			const transformedData = [];

			definitions.forEach((d) => {
				const expectedTranscripts = JSON.parse(d.definition).transcript.map((t) => t.name.toLowerCase().trim());
				const expectedSections = getSectionHeaders(d);

				const availableTranscriptHeaders = sheetData.headers.filter((header) => expectedTranscripts.includes(header));
				const availableSectionHeaders = sheetData.headers.filter((header) => expectedSections.includes(header));
				const availableHeaders = [...availableTranscriptHeaders, ...availableSectionHeaders];
				const headers = ["first name", "last name", "contact", ...availableHeaders];

				const data = sheetData.data
					.filter((dataRow) => dataRow.id === d._id)
					.map((d) => {
						delete d.id;
						return d;
					});
				const sheetRows = sheetData.arr.filter((row) => row[0] === d._id);

				const arr = [];
				for (let rowIndex = 0; rowIndex < sheetRows.length; rowIndex++) {
					const row = [];
					headers.forEach((header) => {
						row.push(getObjectValue(data, `${rowIndex}.${header}`) || "");
					});
					arr.push(row);
				}

				arr.unshift(headers);

				transformedData.push({
					arr,
					data,
					dialogue: true,
					error: null,
					headers,
					modalType: "issuemany",
					selectedCred: d,
				});
			});
			// console.log(transformedData);
			setMultipleCredentials(transformedData);
			if (transformedData.length) {
				setReviewingIndex(0);
			}
			enqueueSnackbar(`Retrieved ${definitions.length} credential definitions for multiple bulk issuance.`, { variant: "success" });
		} catch (e) {
			console.error(e);
			enqueueSnackbar("Failed to retrieve one or more credential definitions. Please try again.", { variant: "error" });
			setBulkIssueState({
				...bulkIssueState,
				error: { msg: "Failed to retrieve one or more credential definitions. Please check the credential Ids of the sheet and try again." },
			});
		} finally {
			setFetchingCredentialDefinitions(false);
		}
	};

	const issueMultiple = async (event) => {
		try {
			if (!event.target.files) throw new Error("No file found...");
			const excelSheet = event.target.files[0];
			if (!excelSheet) throw new Error("No file found...");
			if (!checkFileExtension(excelSheet.type, "xlsx")) throw new Error("Please select valid file format");
			const disableBulkIssueDateValidation = canView("disableBulkIssueDateValidation", true);
			const jsonExcelSheet = await parseExcelSheetToJson(excelSheet, true, true, !disableBulkIssueDateValidation);

			if (jsonExcelSheet && jsonExcelSheet.data) {
				if (jsonExcelSheet.data.length < 1) {
					setBulkIssueState({
						dialogue: true,
						data: null,
						modalType: openModal.modalType,
						error: { msg: `Uploaded file must contain column headers and at least one row of data. ${jsonExcelSheet.data.length} rows found.` },
					});
				} else {
					getMultipleCredentials(jsonExcelSheet);
					setBulkIssueState({
						dialogue: true,
						error: null,
						data: jsonExcelSheet.data,
						arr: jsonExcelSheet.arr,
						headers: jsonExcelSheet.headers,
						modalType: openModal.modalType,
					});
				}
			} else {
				if (jsonExcelSheet.error === "has duplicate column") {
					setBulkIssueState({
						dialogue: true,
						data: null,
						modalType: openModal.modalType,
						error: { msg: `Uploaded file ${jsonExcelSheet.error} column headers ${JSON.stringify(jsonExcelSheet.headers)}` },
					});
				} else if (jsonExcelSheet.error === "The Date is incorrectly formatted. Please ensure you are using the Date Number type (Format > Number > Date) and following the MM/DD/YYYY format.") {
					setBulkIssueState({
						dialogue: true,
						data: null,
						modalType: openModal.modalType,
						error: { msg: jsonExcelSheet.error },
					});
				}
			}
		} catch (err) {
			console.error(err);
			setBulkIssueState({ dialogue: true, error: { ...err }, data: null, selectedCred: null, modalType: "issuemany" });
		}
	};

	const onReview = async (mode, certData, batchLabel) => {
		if (mode === "next" && reviewingIndex < multipleCredentials.length) {
			certs.current = certs.current.filter((cert) => cert.name !== getObjectValue(multipleCredentials, reviewingIndex).selectedCred.name);
			certs.current = [...certs.current, ...certData];

			setReviewingIndex(reviewingIndex + 1);
		} else if (mode === "prev" && reviewingIndex > 0) {
			setReviewingIndex(reviewingIndex - 1);
		} else if (mode === "submit") {
			certs.current = certs.current.filter((cert) => cert.name !== getObjectValue(multipleCredentials, reviewingIndex).selectedCred.name);
			certs.current = [...certs.current, ...certData];

			setIssuingMultiCredentials(true);
			try {
				const credIds = multipleCredentials.map((cred) => cred.selectedCred._id);
				const bulkIssueRequest = {
					multiCredentials: credIds.map((id) => ({
						credDefId: id,
						certs: certs.current.filter((cert) => cert.name === multipleCredentials.find((cred) => cred.selectedCred._id === id).selectedCred.name),
					})),
					batchLabel,
					options: {
						allowPickup: false,
						linkingFactor: [],
					},
					processType: "ISSUE_MANY",
				};

				await postApi("/issuer/issueAndPublishCertificatesBulk", bulkIssueRequest);

				enqueueSnackbar(`Issued ${certs.current.length} credentials for ${multipleCredentials.length}.`, { variant: "success" });
				bulkIssueRequest.multiCredentials.forEach((cred) => {
					reloadCreds({
						type: "ISSUED_CRED_COUNT_UPDATE",
						payload: { length: cred.certs.length, _id: cred.credDefId },
					});
				});
				handleCloseModal();
			} catch (e) {
				console.error(e);
				resetMultiIssueState();
				enqueueSnackbar(`Failed to issue ${certs.current.length} credentials for ${multipleCredentials.length}. Please try again.`, { variant: "error" });
				setBulkIssueState({
					...bulkIssueState,
					error: { msg: "Failed to issue multiple credentials. Please try again." },
				});
			} finally {
				setIssuingMultiCredentials(false);
			}
		}
	};

	const resetMultiIssueState = () => {
		setBulkIssueState({ dialogue: false, data: null, error: null, selectedCred: null, modalType: "issuemany" });
		setMultipleCredentials([]);
		setReviewingIndex(-1);
		certs.current = [];
	};

	return (
		<>
			<Button color="warning" invert leftIcon="multiple-credential-upload" fullWidth={false} onClick={() => handleOpenModal("issuemany")}>
				Bulk Issue Multiple Credentials
			</Button>

			<Dialog onClose={handleCloseModal} open={Boolean(openModal)} maxWidth="sm" fullWidth>
				<DialogTitle id="customized-dialog-title" disableTypography onClose={handleCloseModal} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					<div>
						<Typography variant="h6">{openModal?.title}</Typography>
						<Typography variant="body2" gutterBottom style={{ fontStyle: "italic" }}>
							Supported file types: xlsx.
						</Typography>
					</div>
				</DialogTitle>
				<DialogContent dividers>
					<Box border={2} style={{ borderStyle: "dashed", padding: "3rem 0", position: "relative" }} backgroundColor="textSecondary" display="flex" flexDirection="column" alignItems="center">
						<Typography variant="h5">Drag and Drop/Select</Typography>
						<CloudUploadIcon style={{ fontSize: 80 }} />
						<input
							disabled={fetchingCredentialDefinitions}
							style={{
								opacity: "0",
								position: "absolute",
								width: "100%",
								height: "100%",
								left: "0",
								right: "0",
								top: "0",
								zIndex: "9",
							}}
							id="csv-upload"
							multiple
							type="file"
							onChange={async (e) => {
								progressStart();
								handleClose(e);
								await issueMultiple(e);
								progressDone();
							}}
							accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						/>
					</Box>
					{bulkIssueState?.error?.msg && (
						<Alert icon={false} style={{ marginTop: "16px" }} severity="error">
							<h3>Error in Bulk Issue Multiple</h3>
							<p>{bulkIssueState?.error?.msg}</p>
						</Alert>
					)}
					{fetchingCredentialDefinitions && (
						<Alert icon={false} style={{ marginTop: "16px" }} severity="info">
							<h3>Identifying credentials</h3>
							<p>Please wait ...</p>
						</Alert>
					)}
				</DialogContent>
				<DialogActions>
					<Button color="warning" onClick={handleCloseModal} disabled={fetchingCredentialDefinitions || issuingMultiCredentials}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
			{reviewingIndex >= 0 && (
				<BulkIssue
					state={getObjectValue(multipleCredentials, reviewingIndex)}
					isMultiIssue={true}
					setState={setBulkIssueState}
					resetMultiIssueState={resetMultiIssueState}
					isFinal={reviewingIndex === multipleCredentials.length - 1}
					isFirst={reviewingIndex === 0}
					reloadCreds={() => console.log("reloadCreds")}
					enqueueSnackbar={enqueueSnackbar}
					onReview={onReview}
					issuingMultiCredentials={issuingMultiCredentials}
				/>
			)}
		</>
	);
};

export default BulkIssueMultiple;
