import { useEffect, useState } from "react";
import CreateNewPDFTemplate from "./CreateNewPDFTemplate";
import UpdateOrgPDFTemplate from "./UpdateOrgPDFTemplate";
import UpdateOrgPDFConfig from "./UpdateOrgPDFConfig";
import { useSnackbar } from "notistack";
import { getErrMsg, ISSUE_CREDENTIALS, fetchPDFTemplates, getOrganization } from "@cti-workspace/helpers";

const PDFTemplates = () => {
	const { enqueueSnackbar } = useSnackbar();

	const [pDFTemplates, setPDFTemplates] = useState([]);

	const getPDFTemplates = async (category = ISSUE_CREDENTIALS) => {
		let data = [];
		try {
			data = await fetchPDFTemplates(category);
		} catch (e) {
			enqueueSnackbar(getErrMsg(e, `Failed to retrieve PDF Templates.`, { variant: "error" }));
		} finally {
			setPDFTemplates(data);
		}
	};

	return (
		<div>
			<CreateNewPDFTemplate />
			<br />
			<UpdateOrgPDFTemplate pDFTemplates={pDFTemplates} getPDFTemplates={getPDFTemplates} />
			<br />
			<UpdateOrgPDFConfig />
		</div>
	);
};

export default PDFTemplates;
