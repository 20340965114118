import { Box, Breadcrumbs, Container, Typography } from "@cti-workspace/ui";
import { useState } from "react";
import AuthorizationsTable from "./components/authorizationTable";
import AuthorizationViewDialog from "./components/authorizationViewDialog";

export default function Authorizations() {
	const [viewData, setViewData] = useState({});

	return (
		<Container maxWidth="lg">
			<Box padding={3} paddingTop={4}>
				<div style={{ marginBottom: "3rem" }}>
					<Breadcrumbs options={[{ label: "Authorizations" }]} />
				</div>
				<AuthorizationsTable />
				{viewData && Object.keys(viewData).length > 0 ? <AuthorizationViewDialog /> : null}
			</Box>
		</Container>
	);
}
