import { ListItem, ListItemIcon, ListItemText, Tooltip } from "@material-ui/core";
import { SettingsBrightness, Business, PersonAddOutlined, LockOutlined, Tune, SettingsOverscan, StorageOutlined, AlternateEmail, PictureAsPdf, CardMembership } from "@material-ui/icons";
import { FileCode, Scroll } from "@phosphor-icons/react";

const adminSubMenuItems = [
	{ name: "Organization", icon: <Business /> },
	{ name: "Pending Approvals", icon: <Scroll size={28} weight="duotone" /> },
	{ name: "Renderer Config", icon: <SettingsBrightness /> },
	{ name: "New User Account", icon: <PersonAddOutlined /> },
	{ name: "Set New Password", icon: <LockOutlined /> },
	{ name: "Settings", icon: <Tune /> },
	{ name: "Renderer Config Org", icon: <SettingsOverscan /> },
	{ name: "Database Config", icon: <StorageOutlined /> },
	{ name: "Email Templates", icon: <AlternateEmail /> },
	{ name: "PDF Templates", icon: <PictureAsPdf /> },
	{ name: "Custom Credentials", icon: <CardMembership /> },
	{ name: "NFT Contract", icon: <FileCode size={28} weight="duotone" /> },
];

export const isAdminRouteItem = (route) => {
	return route.name === "SuperAdmin";
};

export const getAdminSubMenuItems = (className, onAdminSubMenuItemClick, search, open) => {
	return adminSubMenuItems.map((item, index) => (
		<Tooltip title={open ? "" : item.name} placement="right">
			<ListItem className={className} selected={getActiveIndex(search) === index} button key={`admin_sub_menu_${index}`} id={`admin_sub_menu_${index}`} onClick={(e) => onAdminSubMenuItemClick(e, index)}>
				<ListItemIcon>{item.icon}</ListItemIcon>
				<ListItemText primary={item.name} />
			</ListItem>
		</Tooltip>
	));
};

export const getActiveIndex = (location) => {
	try {
		return parseFloat(location.search.split("=")[1]);
	} catch (e) {
		console.error(e);
		return 0;
	}
};
