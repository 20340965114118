import styled from "styled-components";
import { Card, Container, Typography as TextTypography, WalletTabs } from "@cti-workspace/ui";
import { makeStyles } from "@material-ui/core/styles";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Wallet from "./WalletRoute";
import PathwayInvitations from "./pathwayInvitations";
import Pathways from "./pathways";
import { useEffect, useState } from "react";
import useSettingsHook from "../../hooks/useSettingsHook";
import { API_URL, getApi, getObjectValue, postApi, VCX_API_KEY } from "@cti-workspace/helpers";
import { userStore } from "../../store/userStore";
import { Map, mergeDeep } from "immutable";

import { useQuery } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		marginTop: theme.spacing(2),
	},
	tabSelected: {
		background: "#FFF2E5",
	},
	categoryBar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "1rem 0",
		[theme.breakpoints.between("xs", "sm")]: {
			flexDirection: "column",
		},
	},
	leftBox: {
		flex: "auto",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
	RightBox: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		[theme.breakpoints.between("xs", "sm")]: {
			padding: "1rem 0",
		},
	},
	iconButton: {
		padding: "0 0.5rem",
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		overflow: "auto",
	},
}));

const PathwaysContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(${({ isLoading, totalData }) => (isLoading || totalData === 0 ? 1 : 3)}, 1fr);
	grid-template-rows: repeat(${({ isLoading, totalData }) => (isLoading || totalData === 0 ? 1 : 3)}, auto);
	margin-top: 1rem;
	grid-gap: 0.5rem;
	width: 100%;
	@media (max-width: 1024px) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(9, auto);
	}
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(10, auto);
	}
`;

const CardContent = styled.div`
	padding: 0.8rem 1.5rem;
	border-bottom: ${({ borderBottom }) => (borderBottom ? "0.5px solid #E0E0E0" : "none")};
`;

export function WalletWrapper({ options, tabIndex, account = {}, primaryColor }) {
	const classes = useStyles();

	return (
		<Container maxWidth="lg">
			<div className={classes.root}>
				<main className={classes.content + " main-content"}>
					<Card style={{ padding: "0px", marginBottom: "1rem" }}>
						<CardContent borderBottom>
							<TextTypography style={{ fontSize: "1.5rem" }}>{`${getObjectValue(account, "firstName", "User")} ${getObjectValue(account, "lastName", "")} Wallet`}</TextTypography>
						</CardContent>
						<CardContent>
							<FormattedMessage
								id={`wallet.route.body1`}
								values={{
									p: (...chunks) => (
										<Typography component={"p"} display={"block"}>
											{chunks}
										</Typography>
									),
									cta: (...chunks) =>
										primaryColor ? (
											<Typography component={"span"} display={"inline"} style={{ color: primaryColor }}>
												{chunks}
											</Typography>
										) : (
											<Typography component={"span"} display={"inline"} color={"secondary"}>
												{chunks}
											</Typography>
										),
								}}>
								{(txt) => (
									<Typography component={"p"} variant={"body1"}>
										{txt}
									</Typography>
								)}
							</FormattedMessage>
						</CardContent>
					</Card>
					<TextTypography type="h6" style={{ fontWeight: "normal", marginBottom: "1rem" }}>
						Verifiable Digital Credentials
					</TextTypography>
					<WalletTabs activeStep={tabIndex} options={options} primaryColor={primaryColor} />
				</main>
			</div>
		</Container>
	);
}

export const WalletRoute = () => {
	const navigate = useNavigate();
	const { getWhiteLabelOptions } = useSettingsHook();

	const [credCount, setCredCount] = useState(0);
	const [tabIndex, setTabIndex] = useState(0);
	// const { count: invitationCount, refreshCount } = usePathwaysInvitationCount();

	const email = userStore((state) => state.email);
	const jwtToken = userStore((state) => state.jwtToken);
	const firstName = userStore((state) => state.firstName);
	const lastName = userStore((state) => state.lastName);
	const account = { firstName, lastName };
	const location = useLocation();

	useEffect(() => {
		if (location.pathname.includes("pathways")) {
			setTabIndex(1);
		} else if (location.pathname.includes("invitations")) {
			setTabIndex(2);
		} else {
			setTabIndex(0);
		}
	}, [location.pathname]);

	const primaryColor = getWhiteLabelOptions()?.walletConfig?.theme?.default?.primaryColor;
	const cardName = getWhiteLabelOptions()?.walletConfig?.cardName;
	const cardNameVal = getWhiteLabelOptions()?.walletConfig?.cardNameVal;
	const cardNameRef = getWhiteLabelOptions()?.walletConfig?.cardNameRef;

	const { isLoading, error, data, isFetching } = useQuery({
		queryKey: [email],
		queryFn: async () => {
			const userPathwayArray = await getApi(`/holder/getActivePathways?email=${email}`, {}, false, API_URL, { "vcx-api-key": VCX_API_KEY, authorization: `Bearer ${jwtToken}` });
			let colors = ["red", "green", "blue"];
			let pathways = {};
			for (const userPathwayData of userPathwayArray) {
				let pathwayData = {};
				for (let pathwayId in userPathwayData.pathways) {
					pathwayData[pathwayId] = {
						...userPathwayData.pathways[pathwayId],
						userPathwayId: userPathwayData._id,
					};
				}
				pathways = { ...pathways, ...pathwayData };
			}

			if (Object.keys(pathways).length) {
				const { payload } = await postApi(`/holder/pathway/details`, { pathwayIds: Object.keys(pathways) });

				const Map1 = Map(pathways);

				const Map2 = Map(payload);

				const MergedMap = mergeDeep(Map1, Map2);

				const newPathways = Object.fromEntries(MergedMap);

				const invitedPathways = Object.keys(newPathways)
					.map((id, _index) => {
						const { [id]: val } = newPathways;
						if (val.status === "INVITED" && val?.name) return { ...val, id };
					})
					.filter(Boolean);
				const acceptedPathways = Object.keys(newPathways)
					.map((id, _index) => {
						const { [id]: val } = newPathways;
						if (val.status === "ACCEPTED" && val?.name) return { ...val, id };
					})
					.filter(Boolean);

				const { pathways: temp, ...rest } = userPathwayArray[0];

				return { ...rest, invitedPathways, acceptedPathways };
			}

			throw new Error({ code: "Wallet-01", message: "unable to retrieve pathway related data" });
		},
	});

	// const { pathways, _id: pathwayID, ...pathwayHolderData } = data;

	const options = [
		{ title: `Credentials ${credCount > 0 ? `(${credCount})` : ""}`, route: "/wallet" },
		{
			title: `PATHWAYS ${data?.acceptedPathways?.length > 0 ? `(${data?.acceptedPathways?.length})` : ""}`,
			route: "pathways",
		},
		{
			title: `INVITATIONS ${data?.invitedPathways?.length > 0 ? `(${data?.invitedPathways?.length})` : ""}`,
			route: "invitations",
		},
	];

	return (
		<Routes>
			<Route element={<WalletWrapper credCount={credCount} tabIndex={tabIndex} options={options} account={account} primaryColor={primaryColor} />}>
				<Route index element={<Wallet credCount={setCredCount} primaryColor={primaryColor} cardName={cardName} cardNameVal={cardNameVal} cardNameRef={cardNameRef} />} />
				<>
					<Route
						path="pathways"
						element={
							<PathwaysContainer isLoading={isLoading} totalData={data?.acceptedPathways?.length ?? 0}>
								<Pathways email={email} data={data} isLoading={isLoading} isFetching={isFetching} error={error} />
							</PathwaysContainer>
						}
					/>
					<Route
						path="invitations"
						element={
							<PathwaysContainer isLoading={isLoading} totalData={data?.invitedPathways?.length ?? 0}>
								<PathwayInvitations email={email} data={data} isLoading={isLoading} isFetching={isFetching} error={error} />
							</PathwaysContainer>
						}
					/>
				</>
			</Route>
		</Routes>
	);
};

// Holder enters the wallet after login

// loads a list of received credentials from API
// loads a list of accepted pathways from API
// loads a list of invited pathways from API

// lets run them in parallel

// already logged in holder may enter pathway view directly (problem login page does not maintain initial link state)
