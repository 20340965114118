import { createContext, useState, useDebugValue, useMemo, useContext } from "react";
import { JWT_TOKEN, API_URL, ISSUER_PREFIX, UploadImage, RegisterOrganization } from "@cti-workspace/helpers";
import { useSnackbar } from "notistack";

const FormContext = createContext({});

const initialForm = {};

const FormProvider = (props) => {
	const baseUrl = `${API_URL}/${ISSUER_PREFIX}`;

	const [form, setForm] = useState(initialForm);
	const { enqueueSnackbar } = useSnackbar();

	const storeFormData = (key, value) => {
		//should be a object
		setForm((obj) => ({ ...obj, [key]: value }));
	};

	const onSubmit = async () => {
		// enqueueSnackbar("Validation Success",{variant:'success'});

		const formData = { ...form };
		const formImages = { ...form.imageUrls };
		let imageUrlsObj = {};

		if (!(Object.keys(formImages).length === 0 && formImages.constructor === Object)) {
			for (const property in formImages) {
				const { [property]: propertyValue } = formImages;
				let imageUrl = await UploadImage(propertyValue, undefined, "public-read");
				imageUrlsObj = {
					...imageUrlsObj,
					[property]: imageUrl,
				};
			}

			formData["imageUrls"] = imageUrlsObj;

			let NewOrg = await RegisterOrganization(formData);

			if (NewOrg) {
				enqueueSnackbar(`New Org with ID:${NewOrg} was created `, {
					variant: "success",
				});
			}

			setForm(initialForm);
		} else {
			enqueueSnackbar("Upload and Select a Default Image", {
				variant: "error",
			});
		}
	};

	const onReset = () => {};

	return useMemo(() => <FormContext.Provider value={{ form, storeFormData, onSubmit, onReset }} {...props} />, [form]);
};

export const useFormContext = () => useContext(FormContext);

export default FormProvider;
