import { useEffect, useState } from "react";
import useNProgressHook from "../useNProgressHook";
import { useSnackbar } from "notistack";
import { getErrMsg, postApi, getApi, VCX_API_KEY, API_URL } from "@cti-workspace/helpers";

export const usePathwaysInvitation = () => {
	const { progressStart, progressDone } = useNProgressHook();
	const { enqueueSnackbar } = useSnackbar();

	const rejectInvitation = async ({ userPathwayId, pathwayId }) => {
		try {
			progressStart();
			await postApi("/holder/respondPathway", { userPathwayId, pathwayId, response: "REJECTED" }, {}, false, API_URL, { "vcx-api-key": VCX_API_KEY, authorization: `Bearer ${localStorage.getItem("jwtToken")}` });
			enqueueSnackbar("Pathway invitation has been rejected.", { variant: "success" });
		} catch (error) {
			enqueueSnackbar(getErrMsg(error, `Something went wrong`, { variant: "error" }));
			throw error;
		} finally {
			progressDone();
		}
	};

	const acceptPathway = async ({ userPathwayId, pathwayId }) => {
		try {
			progressStart();
			await postApi("/holder/respondPathway", { userPathwayId, pathwayId, response: "ACCEPTED" }, {}, false, API_URL, { "vcx-api-key": VCX_API_KEY, authorization: `Bearer ${localStorage.getItem("jwtToken")}` });
			enqueueSnackbar("Accepted invitation successfully", { variant: "success" });
		} catch (error) {
			enqueueSnackbar("Failed to accept invitation. Please try again", { variant: "error" });
			throw error;
		} finally {
			progressDone();
		}
	};

	return { rejectInvitation, acceptPathway };
};
