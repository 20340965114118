import { usePathwaysHook, PATHWAY_PREFIX, putApi, getErrMsg } from "@cti-workspace/helpers";
import { Alert, Button } from "@cti-workspace/ui";
import { Divider, Typography } from "@material-ui/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";

import ipwruLogo from "../../../assets/images/iPWRU_Logo_Med.png";
import { PathwayDetailsSection, PathwayDetailItem, BtnFullWidth } from "../components/pathwayComponents";

export default function DeletePathway() {
	const navigate = useNavigate();
	const { id, leafId } = useParams();

	const [childRef, steps] = useOutletContext();

	const getActiveLeaf = steps.filter((item) => item.stepId === leafId)[0];
	const getActiveParent = steps.filter((item) => item.level === 0)[0];
	const activeCard = leafId ? getActiveLeaf : getActiveParent;

	const { deletePathway, deletePathwayStep } = usePathwaysHook();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (childRef && activeCard) {
			childRef.current.setSelectedWithChildren(activeCard.stepId);
		}
	}, []);

	const queryClient = useQueryClient();

	function deleteArray(arr1, stepId) {
		// filter step with stepId

		arr1.splice(
			arr1.findIndex((item) => item.stepId === stepId),
			1
		);

		const arr = [];

		arr1.forEach((object) => {
			if (object.parentId === stepId) arr.push(object.stepId);
		});

		arr.forEach((id) => {
			deleteArray(arr1, id);
		});

		return arr1;
	}

	const deleteStepMutation = useMutation(({ id, stepId }) => putApi(`/${PATHWAY_PREFIX}/deletePathwayStep/${id}`, { stepId }), {
		// When mutate is called:
		onMutate: async ({ id, stepId }) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries(["pathway", id]);

			// Snapshot the previous value
			const previousPathways = queryClient.getQueryData(["pathway", id]);

			// Optimistically update to the new value
			if (previousPathways) {
				let temp = deleteArray(previousPathways.steps, stepId);
				queryClient.setQueryData(["pathway", id], {
					...previousPathways,
					steps: [...temp],
				});
			}

			navigate(`/pathway/${id}`, { replace: true });

			return { previousPathways };
		},
		onSuccess: (res) => {
			enqueueSnackbar("Step deleted successfully.", { variant: "success" });
		},
		// If the mutation fails, use the context returned from onMutate to roll back
		onError: (err, variables, context) => {
			if (context?.previousPathways) {
				queryClient.setQueryData(["pathway", id], context.previousPathways);
			}
			console.error(err);
			enqueueSnackbar(`The Step could not be deleted. ${err.message || ""}`, { variant: "error" });
		},
		// Always refetch after error or success:
		onSettled: () => {
			queryClient.invalidateQueries(["pathway", id]);
		},
	});

	const onDelete = async () => {
		try {
			if (leafId) {
				// await childRef.current.removeNode(leafId);
				//TODO: this should be converted to a mutation
				deleteStepMutation.mutate({ id, stepId: leafId });
				// enqueueSnackbar(res?.message || "Successfully updated pathway", { variant: "success" });
				// await getSteps(id);
			} else {
				await deletePathway(id);
				navigate(`/pathways/`, { replace: true });
			}
		} catch (error) {
			console.error(error);
			enqueueSnackbar(getErrMsg(error), { variant: "error" });
		}
		childRef.current.clearHighlighting();
	};
	return (
		<PathwayDetailsSection>
			<PathwayDetailItem>
				<img src={ipwruLogo} style={{ width: "13rem" }} alt="iPWRU" />
			</PathwayDetailItem>
			<Divider />

			{/* Pathways Details with delete alert */}
			<PathwayDetailItem text>
				<Alert title="Deletion of this Step will also remove all child Steps" iconSize={50} />
			</PathwayDetailItem>
			<>
				<PathwayDetailItem text>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div>
							<Typography variant="subtitle1" color="secondary" style={{ fontWeight: "bold", lineHeight: "1.5rem" }}>
								{activeCard?.name}
							</Typography>
							<Typography variant="body2" style={{ marginTop: "0.5rem" }} dangerouslySetInnerHTML={{ __html: activeCard?.description?.replace(/(\r\n|\n|\r)/gm, "<br />") }} />
						</div>
					</div>
				</PathwayDetailItem>
				<Divider />
			</>
			{/* Buttons with alert delete */}
			<PathwayDetailItem text flexBottom>
				<Alert title="This process cannot be undone. Are you sure you want to delete?" iconSize={50} />
				<div style={{ marginTop: "1rem", display: "flex", width: "100%", gridGap: "0.5rem" }}>
					<BtnFullWidth>
						<Button
							color="white"
							onClick={() => {
								childRef.current.clearHighlighting();
								leafId ? navigate(`/pathway/${id}/${leafId}`, { replace: true }) : navigate(`/pathway/${id}`, { replace: true });
							}}>
							Cancel
						</Button>
					</BtnFullWidth>
					<BtnFullWidth>
						<Button color={"error"} onClick={() => onDelete()}>
							Delete
						</Button>
					</BtnFullWidth>
				</div>
			</PathwayDetailItem>
		</PathwayDetailsSection>
	);
}
