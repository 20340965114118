import { useState, useEffect, useCallback } from "react";
import { useSnackbar } from "notistack";
import useWidthHook from "../useWidthHook";
import { ADAPTER_URL, SCHOOLOGY_EMAIL, IS_VERIFIED_TEACHER, SCHOOLOGYSECRET, SCHOOLOGYTOKEN, ADAPTER_PREFIX, getObjectValue, postApi, getApi } from "@cti-workspace/helpers";

const useSchoologyHook = ({ organizations, connectedCredsData, setConnectedCredsData }) => {
	const name = "Schoology (PowerSchool)";
	const type = "schoology";
	const { enqueueSnackbar } = useSnackbar();
	const [importedCourses, setImportedCourses] = useState(null);
	const [importedSections, setImportedSections] = useState(null);
	const [loggedInSchoologyEmail, setLoggedInSchoologyEmail] = useState("");
	const [loggedInProvider, setLoggedInProvider] = useState("");
	const [schoologyData, setSchoologyData] = useState("");
	const [selectedCourse, setSelectedCourse] = useState({});
	const [selectedCourseWork, setSelectedCourseWork] = useState({});
	const [selectedCredential, setSelectedCredential] = useState({});
	const [verifiedTeacher, setVerifiedTeacher] = useState(false);
	const currentWidth = useWidthHook();
	const [showLoadMore, setShowLoadMore] = useState({ items: [] });

	const isAuthorized = (response) => {
		if (response === "Unauthorized") {
			enqueueSnackbar("Your session has timed out... Please login again.", {
				variant: "error",
			});
			logout();
			return false;
		}
		return true;
	};

	useEffect(() => {
		const email = localStorage.getItem(SCHOOLOGY_EMAIL);
		const isVerifiedTeacher = localStorage.getItem(IS_VERIFIED_TEACHER);
		if (email) {
			setLoggedInSchoologyEmail(email);
			setLoggedInProvider("schoology");
		}
		if (isVerifiedTeacher == "true") setVerifiedTeacher(true);
	}, []);

	const connectItemWithCredential = useCallback(async (courseWork, credential, course) => {
		try {
			// Course work and credential passed in if using the automated single click flow
			const courseWorkToConnect = courseWork || selectedCourseWork;
			const credentialToConnect = credential || selectedCredential;
			const courseToConnect = course || selectedCourse;
			const courseId = courseToConnect.id.toString();
			const courseWorkId = courseWorkToConnect.id.toString();
			const email = localStorage.getItem(SCHOOLOGY_EMAIL);

			// Save the binding of the LMS event with the credential
			let request = {
				email,
				credDefId: credentialToConnect._id,
				orgId: organizations.default._id,
				courseId,
				courseName: courseToConnect.title,
				courseWorkId,
				courseWorkName: courseWorkToConnect.section_title,
				lmsType: type,
			};

			console.log(request);

			const url = "/adapter/saveConnectedCredential";
			const response = await postApi(url, request);

			setConnectedCredsData([...connectedCredsData, response.credential]);
			// Clear the selections
			setSelectedCourseWork({});
			setSelectedCredential({});

			// Now start listening for events
			request = {
				courseId,
				courseWorkId,
				email,
			};

			//Registering to feed not required on canvas hense commented out
			// const data = await postApi(`/googleClassroom/registerToFeed`, request, {}, true, ADAPTER_URL);

			enqueueSnackbar(`Success!`, {
				variant: "Success",
			});
		} catch (error) {
			enqueueSnackbar("Could not register to feed... please contact support@globaledfoundation.com", {
				variant: "error",
			});
		}
	});

	const deleteConnectedCredential = useCallback(async (courseId, courseWorkId) => {
		try {
			const request = {
				courseId,
				courseWorkId,
			};
			const url = "/adapter/deleteConnectedCredential";
			await postApi(url, request);
			setConnectedCredsData(connectedCredsData.filter((cred) => !(cred.courseId === courseId && cred.courseWorkId === courseWorkId)));
			enqueueSnackbar(`Success!`, {
				variant: "Success",
			});
		} catch (error) {
			enqueueSnackbar("Could not be deleted... please contact support@globaledfoundation.com", {
				variant: "error",
			});
		}
	});

	const getCourseWork = useCallback(async (course) => {
		try {
			const oauth_token = localStorage.getItem(SCHOOLOGYTOKEN);
			const data = await getApi(`/schoology/listSections/${course.id}/?${oauth_token}`, {}, true, ADAPTER_URL);
			if (isAuthorized(data) && data) {
				return data;
			} else {
				return false;
			}
		} catch (error) {
			console.error({ error });
		}
	});

	/**
	 * @TODO - Depreceted Method, To be removed in Future
	 */
	// const importCourses = useCallback(async () => {
	// 	try {
	// 		const oauth_token = localStorage.getItem(SCHOOLOGYTOKEN);
	// 		const data = await getApi(`/schoology/listCourses/${loggedInSchoologyEmail}?${oauth_token}`, {}, false, ADAPTER_URL);
	// 		if (isAuthorized(data) && data) {
	// 			// Do not present the archived courses for now... could also filter by state=ACTIVE...
	// 			const nonArchivedCourses = data.filter((course) => course.title != "");
	// 			enqueueSnackbar(`Success! Downloaded ${nonArchivedCourses.length} courses.`, {
	// 				variant: "success",
	// 			});
	// 			setImportedCourses(nonArchivedCourses);
	// 			return true;
	// 		} else {
	// 			return false;
	// 		}
	// 	} catch (error) {
	// 		console.error({ error });
	// 		enqueueSnackbar("Courses could not be downloaded at this time...", {
	// 			variant: "error",
	// 		});
	// 	}
	// });

	const importCourses = useCallback(async () => {
		try {
			const oauth_token = localStorage.getItem(SCHOOLOGYTOKEN);
			const data = await getApi(`/schoology/listCourses/${loggedInSchoologyEmail}?${oauth_token}`, {}, false, ADAPTER_URL);
			if (isAuthorized(data) && data) {
				getCoursesPerPage(1, 1, "", true);
				return true;
			} else {
				return false;
			}
		} catch (error) {
			console.error({ error });
			enqueueSnackbar("Courses could not be downloaded at this time...", {
				variant: "error",
			});
		}
	});

	const reimportCourses = useCallback(async () => {
		try {
			const oauth_token = localStorage.getItem(SCHOOLOGYTOKEN);
			const data = await getApi(`/schoology/reimportCourses/${loggedInSchoologyEmail}?${oauth_token}`, {}, false, ADAPTER_URL);
			if (isAuthorized(data) && data) {
				getCoursesPerPage(1, 1, "", true);
				return true;
			} else {
				return false;
			}
		} catch (error) {
			console.error({ error });
			enqueueSnackbar("Courses could not be downloaded at this time...", {
				variant: "error",
			});
		}
	});

	const importCourseWork = useCallback(async (course) => {
		try {
			course.name = course.title;
			setSelectedCourse(course);
			const oauth_token = localStorage.getItem(SCHOOLOGYTOKEN);

			const data = await getApi(`/schoology/listSections/${course.id}/${loggedInSchoologyEmail}`, {}, true, ADAPTER_URL);
			console.log(data);
			if (isAuthorized(data) && data) {
				enqueueSnackbar(`Success! Downloaded ${data.section.length} Course Section.`, {
					variant: "success",
				});
				setImportedSections(data.section);
				return true;
			} else {
				return false;
			}
		} catch (error) {
			console.error({ error });
			enqueueSnackbar("Modules could not be downloaded at this time...", {
				variant: "error",
			});
		}
	});

	const login = useCallback(async () => {
		try {
			/**
			 * @TODO - don't do this and properly send an auth request to adapter when cors fixed
			 */

			let orgId = organizations.default._id;
			const settingsData = localStorage.getItem("settings");
			const settingsObj = JSON.parse(settingsData);

			if (!settingsObj || !settingsObj.schoologyKeys || !settingsObj.schoologyKeys.CLIENT_SECRET) {
				enqueueSnackbar("Unfortunately this LMS is not yet connected. Please contact support@globaledfoundation.com to learn more.", {
					variant: "error",
				});
				return;
			}

			const data = await getApi(`/schoology/login?orgId=${orgId}`, {}, false, ADAPTER_URL);
			console.log("data -- ", data);
			localStorage.setItem(SCHOOLOGYSECRET, data.secret);

			window.location = data.return_url;
		} catch (error) {
			console.log(error);
			enqueueSnackbar("Your login attempt to Schoology failed. Please try again or contact support@globaledfoundation.com", {
				variant: "error",
			});
		}
	});

	const logout = useCallback(() => {
		setLoggedInSchoologyEmail("");
		setLoggedInProvider("");
		localStorage.removeItem(SCHOOLOGY_EMAIL);
	});

	const saveAuthorizationCredentials = useCallback(async (oauth_token) => {
		try {
			let orgId = organizations.default._id;
			const oauth_token_secret = localStorage.getItem(SCHOOLOGYSECRET);
			console.log("oauth_token -- ", oauth_token);
			console.log("oauth_token_secret -- ", oauth_token_secret);
			const data = await getApi(`/schoology/oauth2/callback/code/?oauth_token=${oauth_token}&oauth_token_secret=${oauth_token_secret}&orgId=${orgId}`, {}, false, ADAPTER_URL);
			console.log("data -- ", data);
			if ("primary_email" in data.user) {
				enqueueSnackbar(`Success! Logged in as ${data.user.primary_email} `, {
					variant: "success",
				});
				setLoggedInSchoologyEmail(data.user.primary_email);
				setLoggedInProvider("schoology");
				setVerifiedTeacher(true);

				// Save to localStorage for across session auth
				localStorage.setItem(SCHOOLOGY_EMAIL, data.user.primary_email);
				localStorage.setItem(IS_VERIFIED_TEACHER, 1);
				localStorage.setItem(SCHOOLOGYTOKEN, data.authToken);
				// importCourses(data.authToken);
			}
		} catch (error) {
			console.error({ error });
			enqueueSnackbar("The authorization credentials could not be stored...", {
				variant: "error",
			});
		}
	});

	const subscribeToCourseWorkEvents = useCallback(async (selection) => {
		try {
			const email = localStorage.getItem(SCHOOLOGY_EMAIL);
			const request = {
				courseId: selection.courseId,
				courseWorkId: selection.courseWorkId,
				email,
			};
			const data = await postApi(`/googleClassroom/registerToFeed`, request, {}, true, ADAPTER_URL);
			console.log({ data });
		} catch (error) {
			console.error({ error });
			enqueueSnackbar("The authorization credentials could not be stored...", {
				variant: "error",
			});
		}
	});

	const updateConnectedCredential = useCallback(async (_id, credentialName, imageUrl) => {
		try {
			const request = {
				_id,
				credentialName,
				imageUrl,
			};
			const url = "/adapter/updateConnectedCredential";
			await postApi(url, request);
			enqueueSnackbar(`Success!`, {
				variant: "Success",
			});
		} catch (error) {
			enqueueSnackbar("Could not be updated... please contact support@globaledfoundation.com", {
				variant: "error",
			});
		}
	});

	const getCoursesPerPage = async (pageSize = 1, pageNumber = 1, search = "", clearCredentials = false) => {
		try {
			switch (currentWidth) {
				case "xs":
					pageSize = 10;
					break;
				case "sm":
					pageSize = 20;
					break;
				case "md":
					pageSize = 20;
					break;
				case "lg":
					pageSize = 9;
					break;
				default:
					pageSize = 12;
			}

			const email = localStorage.getItem(SCHOOLOGY_EMAIL);

			const data = await getApi(`/${ADAPTER_PREFIX}/getLMSCourses?pageSize=${pageSize}&pageNumber=${pageNumber}&search=${search}&email=${email}&type=schoology`);
			const { totalItems, items = [], pageSize: currentPageSize, pageNumber: currentPageNumber } = data;
			enqueueSnackbar(`Success! Downloaded ${items.length} courses.`, { variant: "success" });

			if (items) {
				if (clearCredentials) {
					setImportedCourses(items);
					setShowLoadMore({ items, totalItems, currentPageNumber, currentPageSize });
				} else {
					setImportedCourses([...importedCourses, ...items]);
					setShowLoadMore({ items: [...getObjectValue(showLoadMore, "items", []), ...items], totalItems, currentPageNumber, currentPageSize });
				}
			} else {
				throw data;
			}
		} catch (error) {
			if (error[0]) {
				error.message = `Error type:${getObjectValue(error, "[0].keyword")} message: ${getObjectValue(error, "[0].message")}`;
			}
			throw error;
		}
	};

	return {
		connectItemWithCredential,
		deleteConnectedCredential,
		importCourses,
		importedCourses,
		importCourseWork,
		reimportCourses,
		importedSections,
		getCourseWork,
		loggedInSchoologyEmail,
		loggedInProvider,
		logout,
		name,
		login,
		saveAuthorizationCredentials,
		setImportedCourses,
		setImportedSections,
		setSelectedCourse,
		selectedCourse,
		setSelectedCourseWork,
		selectedCourseWork,
		setSelectedCredential,
		selectedCredential,
		subscribeToCourseWorkEvents,
		type,
		updateConnectedCredential,
		verifiedTeacher,
		getCoursesPerPage,
		showLoadMore,
		setShowLoadMore,
	};
};

export default useSchoologyHook;
