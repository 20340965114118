import { useEffect } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import { getObjectValue } from "@cti-workspace/helpers";
import { Button, FieldWrapper, TextField } from "@cti-workspace/ui";

export const Claims = ({ control, register, errors, index, name }) => {
	const { append, remove, fields } = useFieldArray({
		name,
		control,
	});

	const values = useWatch({
		control,
	})?.sections;

	useEffect(() => {
		if (fields.length === 0) {
			append({ value: "" }, false);
		}
	}, []);

	const checkClaimRowEmpty = (claims = []) => {
		for (let x = 0; x < claims.length; x++) {
			const { [x]: currentClaim } = claims;
			if (!currentClaim.value || !currentClaim.value.trim()) {
				return false;
			}
		}
		return true;
	};

	const hasErrorCheck = (errTxtMsg = "", i) => {
		if (getObjectValue(errors, `sections[${index}].claims[${i}].value`, "")) {
			return {
				helperText: getObjectValue(errors, `sections[${index}].claims[${i}].value.message`) ? getObjectValue(errors, `sections[${index}].claims[${i}].value.message`) : errTxtMsg,
				error: true,
			};
		}
		return {};
	};

	const isDuplicateRow = (value, i) => {
		let errorVal;
		getObjectValue(values, index).claims.forEach((claim, claimIndex) => {
			if (claim.value.trim() === value.trim() && i !== claimIndex) {
				errorVal = "Row exists";
			}
		});

		return errorVal;
	};

	const isSingleClaimForSection = values?.[index]?.claims?.length === 1;

	return (
		<div>
			{fields.map((claim, i) => (
				<FieldWrapper isSecondary style={{ marginBottom: "16px" }} disableClosebtn={isSingleClaimForSection} title="Add Row" onClose={() => remove(i)}>
					<TextField
						fullWidth
						size="small"
						variant="static"
						label="Name"
						value={getObjectValue(values, `[${index}].claims[${i}].value`, getObjectValue(claim, "value", ""))}
						inputRef={register({
							required: true,
							validate: (value) => isDuplicateRow(value, i),
						})}
						name={`sections[${index}].claims[${i}].value`}
						{...hasErrorCheck("Row input is required", i)}
					/>
				</FieldWrapper>
			))}

			<div style={{ display: "flex", justifyContent: "center" }}>
				<Button leftIcon="plus" color="white" onClick={() => append({ value: "" })} disabled={!checkClaimRowEmpty(getObjectValue(values, `[${index}].claims`, []))}>
					Add Row
				</Button>
			</div>
		</div>
	);
};
