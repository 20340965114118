import { getObjectValue } from "@cti-workspace/helpers";
import { Button, Card } from "@cti-workspace/ui";
import { Divider, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import PathwayIcon from "./PathwayIcon";

const CardWrapper = styled(Card)`
	padding: 1rem 1rem 0.5rem 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
  background-color: ${props => props.isCopied ? "#9c27b047" : "#ffffff"}
`;

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	grid-gap: 0.5rem;
	padding: 0.5rem 0rem;
`;

const ButtonFullWidth = styled.div`
	width: 100%;
`;

const NameContainer = styled.div`
	padding: 0 0 0.5rem 0;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`;

export default function PathwayCard({ data = {}, onDelete = () => {}, onCopy = () => {}, onInviteOne = () => {}, onInviteMany = () => {}, isCopied = false }) {
	const navigate = useNavigate();

	return (
		<CardWrapper isCopied={isCopied}>
			<NameContainer>
				<Typography variant="h6" style={{ lineHeight: "1.3" }}>
					{getObjectValue(data, "steps[0].name") || data.name}
				</Typography>
				<PathwayIcon />
			</NameContainer>
			<div>
				<Divider />
				<ButtonContainer>
					<Button buttonType="icon" icon="eye" invert popover="View" onClick={() => navigate(`/pathway/${data._id}`)} />
					<Button buttonType="icon" icon="edit" invert popover="Edit" onClick={() => navigate(`/pathway/${data._id}/edit`)} />
					<Button buttonType="icon" icon="copy" invert popover="Copy" onClick={onCopy} />
					<Button buttonType="icon" icon="delete" invert popover="Delete" onClick={onDelete} />
				</ButtonContainer>
				<Divider />
				<ButtonContainer>
					<ButtonFullWidth>
						<Button color="secondary" invert onClick={onInviteOne}>
							Invite One
						</Button>
					</ButtonFullWidth>
					<ButtonFullWidth>
						<Button color="secondary" invert onClick={onInviteMany}>
							Invite Many
						</Button>
					</ButtonFullWidth>
				</ButtonContainer>
			</div>
		</CardWrapper>
	);
}
