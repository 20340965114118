import { BATCH_LABELS, DEFAULT_ICON_NAME, GOLD_BADGE_URL, ICONS, ORGANIZATIONS_PREFIX, SETTINGS, PDF_EMAIL_TEMPLATES, getApi, getObjectValue } from "@cti-workspace/helpers";

export default function useSettingsHook() {
	const canView = (prop, value) => {
		const settingsData = localStorage.getItem(SETTINGS);
		if (settingsData) {
			const settingsObj = { ...JSON.parse(settingsData) };
			return getObjectValue(settingsObj, prop) === value;
		}
		return false;
	};

	const getSettings = async (account) => {
		try {
			if (getObjectValue(account, "organizations")) {
				const orgId = getObjectValue(account, "organizations.default._id");
				if (!orgId) throw "";
				const { settings, resources, credentialIssueLabels } = await getApi(`/${ORGANIZATIONS_PREFIX}/${orgId}`);
				if (settings) {
					localStorage.setItem(SETTINGS, JSON.stringify(settings));
				} else {
					localStorage.removeItem(SETTINGS);
				}

				const icons = Object.values(resources?.credentialDefinitions?.icons || []);
				icons.unshift({ iconName: DEFAULT_ICON_NAME, iconURL: GOLD_BADGE_URL });
				localStorage.setItem(ICONS, JSON.stringify(icons));

				const templates = {
					email: resources?.emailTemplates?.ISSUE_CREDENTIALS,
					pdf: resources?.pdfTemplates?.ISSUE_CREDENTIALS,
				};
				localStorage.setItem(PDF_EMAIL_TEMPLATES, JSON.stringify(templates));

				if (credentialIssueLabels) {
					localStorage.setItem(BATCH_LABELS, JSON.stringify(credentialIssueLabels));
				} else {
					localStorage.removeItem(BATCH_LABELS);
				}
			}
		} catch (err) {
			localStorage.removeItem(SETTINGS);
			localStorage.removeItem(ICONS);
			localStorage.removeItem(BATCH_LABELS);
			localStorage.removeItem(PDF_EMAIL_TEMPLATES);
			throw err;
		}
	};

	const getIcons = () => {
		const iconsData = localStorage.getItem(ICONS);
		if (iconsData) {
			return JSON.parse(iconsData);
		}
		return [];
	};

	const updateSavedIcon = (savedIcon) => {
		let icons = getIcons();
		icons = [...icons, savedIcon];
		localStorage.setItem(ICONS, JSON.stringify(icons));
	};

	const getBatchLabels = () => {
		const batchLabels = localStorage.getItem(BATCH_LABELS);
		if (batchLabels) {
			return JSON.parse(batchLabels);
		}
		return [];
	};

	const updateSavedBatchLabel = (batchLabel) => {
		let batchLabels = getBatchLabels();
		batchLabels = [...batchLabels, batchLabel];
		localStorage.setItem(BATCH_LABELS, JSON.stringify(batchLabels));
	};

	const getCredentialDefinitionSettings = () => {
		let settings = localStorage.getItem(SETTINGS);
		if (settings) {
			settings = JSON.parse(settings);
			const credentialDefinitions = settings?.credentialDefinitions;
			return credentialDefinitions || {};
		}
		return {};
	};

	const getPdfSettings = () => {
		let settings = localStorage.getItem(SETTINGS);
		if (settings) {
			settings = JSON.parse(settings);
			const pdfOptions = settings?.pdfOptions;
			return pdfOptions || {};
		}
		return {};
	};

	const getSharingSettings = () => {
		let settings = localStorage.getItem(SETTINGS);
		if (settings) {
			settings = JSON.parse(settings);
			const sharingOptions = settings?.sharingOptions;
			return sharingOptions || {};
		}
		return {};
	};

	const getWalletOptions = () => {
		let settings = localStorage.getItem(SETTINGS);
		if (settings) {
			settings = JSON.parse(settings);
			const walletOptions = settings?.walletOptions;
			return walletOptions || {};
		}
		return {};
	};

	const acn = Object.freeze({
		sideBarConfig: {
			largeLogoURL: "https://s3.ca-central-1.amazonaws.com/trybe.public.assets/prod/Australian+College+of+Nursing/acn-horizontal-692x100.png",
			largeLogoWidth: 240,
			smallLogoURL: "https://s3.ca-central-1.amazonaws.com/trybe.public.assets/prod/Australian+College+of+Nursing/acn-icon.png",
			smallLogoWidth: 60,
		},
		walletConfig: {
			theme: {
				default: {
					primaryColor: "#004E78",
				},
			},
			cardName: "data.transcript",
			cardNameRef: "COURSE NAME",
			cardNameVal: "data.transcript[0].grade",
		},
	});

	const getWhiteLabelOptions = () => {
		let settings = localStorage.getItem(SETTINGS);
		let whiteLabelID = sessionStorage.getItem("whiteLabelID");
		if (whiteLabelID === "acn") {
			return acn;
		} else if (settings) {
			settings = JSON.parse(settings);
			const whitelabel = settings?.whitelabel;
			return whitelabel || {};
		}
		return {};
	};

	const getTemplates = () => {
		let templates = localStorage.getItem(PDF_EMAIL_TEMPLATES);
		if (templates) {
			templates = JSON.parse(templates);
			return templates;
		}
		return {};
	};

	return {
		canView,
		getSettings,
		getIcons,
		updateSavedIcon,
		getBatchLabels,
		updateSavedBatchLabel,
		getCredentialDefinitionSettings,
		getPdfSettings,
		getSharingSettings,
		getWalletOptions,
		getWhiteLabelOptions,
		getTemplates,
	};
}
