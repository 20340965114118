const variableFonts = [
	"Advent Pro",
	"Alegreya",
	"Asap",
	"Archivo Narrow",
	"Josefin Sans",
	"Josefin Slab",
	"Arimo",
	"Bitter",
	"Cabin",
	"Cabin Condensed",
	"Cabin Sketch",
	"Chivo",
	"Cinzel",
	"Comfortaa",
	"Cuprum",
	"Domine",
	"Dosis",
	"EB Garamond",
	"Exo",
	"Exo 2",
	"Expletus Sans",
	"Jura",
	"Karla",
	"Kreon",
	"Lora",
	"Maven Pro",
	"Merienda",
	"Merriweather Sans",
	"Montserrat",
	"Montserrat Alternates",
	"Montserrat Subrayada",
	"Nunito",
	"Open Sans",
	"Orbitron",
	"Oswald",
	"Petrona",
	"Playfair Display",
	"Playfair Display SC",
	"Podkova",
	"Quicksand",
	"Raleway",
	"Raleway Dots",
	"Roboto Slab",
	"Rokkitt",
	"Rosario",
	"Ruda",
	"Signika",
	"Signika Negative",
	"Vollkorn",
	"Yanone Kaffeesatz",
];

const FontsNotInServer = [
	"Aclonica",
	"Andada",
	"Arial",
	"Arial Black",
	"Calligraffitti",
	"Cherry Cream Soda",
	"Chewy",
	"Coming Soon",
	"Cousine",
	"Crafty Girls",
	"Crushed",
	"Droid Sans",
	"Droid Sans Mono",
	"Droid Serif",
	"Fontdiner Swanky",
	"Gentium Basic",
	"Homemade Apple",
	"Irish Grover",
	"Just Another Hand",
	"Kdam Thmor",
	"Kranky",
	"Luckiest Guy",
	"Maiden Orange",
	"Montez",
	"Mountains of Christmas",
	"Muli",
	"Open Sans Condensed",
	"Permanent Marker",
	"Rancho",
	"Redressed",
	"Roboto Condensed",
	"Rochester",
	"Rock Salt",
	"Satisfy",
	"Scheherazade",
	"Schoolbell",
	"Slackey",
	"Smokum",
	"Special Elite",
	"Sunshiney",
	"Syncopate",
	"Tinos",
	"Ubuntu",
	"Ubuntu Condensed",
	"Ubuntu Mono",
	"Ultra",
	"Unkempt",
	"Walter Turncoat",
	"Yellowtail",
];

export const fonts = [
	"ABeeZee",
	"Abel",
	"Abril Fatface",
	"Acme",
	"Actor",
	"Adamina",
	"Aguafina Script",
	"Akronim",
	"Aladin",
	"Aldrich",
	"Alef",
	"Alegreya Sans SC",
	"Alegreya Sans",
	"Alegreya SC",
	"Alex Brush",
	"Alfa Slab One",
	"Alice",
	"Alike Angular",
	"Alike",
	"Allan",
	"Allerta Stencil",
	"Allerta",
	"Allura",
	"Almendra Display",
	"Almendra SC",
	"Almendra",
	"Amarante",
	"Amaranth",
	"Amatic SC",
	"Amethysta",
	"Amiri",
	"Anaheim",
	"Andika",
	"Angkor",
	"Annie Use Your Telescope",
	"Anonymous Pro",
	"Antic Didone",
	"Antic Slab",
	"Antic",
	"Anton",
	"Arapey",
	"Arbutus Slab",
	"Arbutus",
	"Architects Daughter",
	"Archivo Black",
	"Arizonia",
	"Armata",
	"Artifika",
	"Arvo",
	"Asset",
	"Astloch",
	"Asul",
	"Atomic Age",
	"Aubrey",
	"Audiowide",
	"Autour One",
	"Average Sans",
	"Average",
	"Averia Gruesa Libre",
	"Averia Libre",
	"Averia Sans Libre",
	"Averia Serif Libre",
	"Bad Script",
	"Balthazar",
	"Bangers",
	"Basic",
	"Battambang",
	"Baumans",
	"Bayon",
	"Belgrano",
	"Belleza",
	"BenchNine",
	"Bentham",
	"Berkshire Swash",
	"Bevan",
	"Bigelow Rules",
	"Bigshot One",
	"Bilbo Swash Caps",
	"Bilbo",
	"Biryani",
	"Black Ops One",
	"Bokor",
	"Bonbon",
	"Boogaloo",
	"Bowlby One SC",
	"Bowlby One",
	"Brawler",
	"Bree Serif",
	"Bubblegum Sans",
	"Bubbler One",
	"Buda",
	"Buenard",
	"Butcherman",
	"Butterfly Kids",
	"Caesar Dressing",
	"Cagliostro",
	"Cambay",
	"Cambo",
	"Candal",
	"Cantarell",
	"Cantata One",
	"Cantora One",
	"Capriola",
	"Cardo",
	"Carme",
	"Carrois Gothic SC",
	"Carrois Gothic",
	"Carter One",
	"Caudex",
	"Cedarville Cursive",
	"Ceviche One",
	"Changa One",
	"Chango",
	"Chau Philomene One",
	"Chela One",
	"Chelsea Market",
	"Chenla",
	"Cherry Swash",
	"Chicle",
	"Cinzel Decorative",
	"Clicker Script",
	"Coda Caption",
	"Coda",
	"Codystar",
	"Combo",
	"Concert One",
	"Condiment",
	"Content",
	"Contrail One",
	"Convergence",
	"Cookie",
	"Copse",
	"Corben",
	"Courgette",
	"Coustard",
	"Covered By Your Grace",
	"Creepster",
	"Crete Round",
	"Crimson Text",
	"Croissant One",
	"Cutive Mono",
	"Cutive",
	"Damion",
	"Dangrek",
	"Dawning of a New Day",
	"Days One",
	"Dekko",
	"Delius Swash Caps",
	"Delius Unicase",
	"Delius",
	"Della Respira",
	"Denk One",
	"Devonshire",
	"Dhurjati",
	"Didact Gothic",
	"Diplomata SC",
	"Diplomata",
	"Donegal One",
	"Doppio One",
	"Dorsa",
	"Dr Sugiyama",
	"Duru Sans",
	"Dynalight",
	"Eagle Lake",
	"Eater",
	"Economica",
	"Ek Mukta",
	"Electrolize",
	"Elsie Swash Caps",
	"Elsie",
	"Emblema One",
	"Emilys Candy",
	"Engagement",
	"Englebert",
	"Enriqueta",
	"Erica One",
	"Esteban",
	"Euphoria Script",
	"Ewert",
	"Fanwood Text",
	"Fascinate Inline",
	"Fascinate",
	"Faster One",
	"Fasthand",
	"Fauna One",
	"Federant",
	"Federo",
	"Felipa",
	"Fenix",
	"Finger Paint",
	"Fira Mono",
	"Fira Sans",
	"Fjalla One",
	"Fjord One",
	"Flamenco",
	"Flavors",
	"Fondamento",
	"Forum",
	"Francois One",
	"Freckle Face",
	"Fredericka the Great",
	"Fredoka One",
	"Freehand",
	"Fresca",
	"Frijole",
	"Fruktur",
	"Fugaz One",
	"Gabriela",
	"Gafata",
	"Galdeano",
	"Galindo",
	"Gentium Book Basic",
	"Geo",
	"Geostar Fill",
	"Geostar",
	"Germania One",
	"GFS Didot",
	"GFS Neohellenic",
	"Gidugu",
	"Gilda Display",
	"Give You Glory",
	"Glass Antiqua",
	"Glegoo",
	"Gloria Hallelujah",
	"Goblin One",
	"Gochi Hand",
	"Gorditas",
	"Goudy Bookletter 1911",
	"Graduate",
	"Grand Hotel",
	"Gravitas One",
	"Great Vibes",
	"Griffy",
	"Gruppo",
	"Gudea",
	"Gurajada",
	"Habibi",
	"Halant",
	"Hammersmith One",
	"Hanalei Fill",
	"Hanalei",
	"Handlee",
	"Hanuman",
	"Happy Monkey",
	"Headland One",
	"Henny Penny",
	"Herr Von Muellerhoff",
	"Hind",
	"Holtwood One SC",
	"Homenaje",
	"Iceberg",
	"Iceland",
	"IM Fell Double Pica SC",
	"IM Fell Double Pica",
	"IM Fell DW Pica SC",
	"IM Fell DW Pica",
	"IM Fell English SC",
	"IM Fell English",
	"IM Fell French Canon SC",
	"IM Fell French Canon",
	"IM Fell Great Primer SC",
	"IM Fell Great Primer",
	"Imprima",
	"Inder",
	"Indie Flower",
	"Inika",
	"Istok Web",
	"Italiana",
	"Italianno",
	"Jacques Francois Shadow",
	"Jacques Francois",
	"Jaldi",
	"Jim Nightshade",
	"Jockey One",
	"Jolly Lodger",
	"Joti One",
	"Judson",
	"Julee",
	"Julius Sans One",
	"Junge",
	"Just Me Again Down Here",
	"Kalam",
	"Kameron",
	"Kantumruy",
	"Karma",
	"Kaushan Script",
	"Kavoon",
	"Keania One",
	"Kelly Slab",
	"Kenia",
	"Khand",
	"Khmer",
	"Khula",
	"Kite One",
	"Knewave",
	"Kotta One",
	"Koulen",
	"Kristi",
	"Krona One",
	"La Belle Aurore",
	"Laila",
	"Lakki Reddy",
	"Lancelot",
	"Lateef",
	"Lato",
	"League Script",
	"Leckerli One",
	"Ledger",
	"Lekton",
	"Lemon",
	"Libre Baskerville",
	"Life Savers",
	"Lilita One",
	"Lily Script One",
	"Limelight",
	"Linden Hill",
	"Lobster Two",
	"Lobster",
	"Londrina Outline",
	"Londrina Shadow",
	"Londrina Sketch",
	"Londrina Solid",
	"Love Ya Like A Sister",
	"Loved by the King",
	"Lovers Quarrel",
	"Lusitana",
	"Lustria",
	"Macondo Swash Caps",
	"Macondo",
	"Magra",
	"Mako",
	"Mallanna",
	"Mandali",
	"Marcellus SC",
	"Marcellus",
	"Marck Script",
	"Margarine",
	"Marko One",
	"Marmelad",
	"Martel Sans",
	"Martel",
	"Marvel",
	"Mate SC",
	"Mate",
	"McLaren",
	"Meddon",
	"MedievalSharp",
	"Medula One",
	"Megrim",
	"Meie Script",
	"Merienda One",
	"Merriweather",
	"Metal Mania",
	"Metal",
	"Metamorphous",
	"Metrophobic",
	"Michroma",
	"Milonga",
	"Miltonian Tattoo",
	"Miltonian",
	"Miniver",
	"Miss Fajardose",
	"Modak",
	"Modern Antiqua",
	"Molengo",
	"Molle",
	"Monda",
	"Monofett",
	"Monoton",
	"Monsieur La Doulaise",
	"Montaga",
	"Moul",
	"Moulpali",
	"Mouse Memoirs",
	"Mr Bedfort",
	"Mr Dafoe",
	"Mr De Haviland",
	"Mrs Saint Delafield",
	"Mrs Sheppards",
	"Mystery Quest",
	"Neucha",
	"Neuton",
	"New Rocker",
	"News Cycle",
	"Niconne",
	"Nixie One",
	"Nobile",
	"Nokora",
	"Norican",
	"Nosifer",
	"Nothing You Could Do",
	"Noticia Text",
	"Noto Sans",
	"Noto Serif",
	"Nova Cut",
	"Nova Flat",
	"Nova Mono",
	"Nova Oval",
	"Nova Round",
	"Nova Script",
	"Nova Slim",
	"Nova Square",
	"NTR",
	"Numans",
	"Odor Mean Chey",
	"Offside",
	"Old Standard TT",
	"Oldenburg",
	"Oleo Script Swash Caps",
	"Oleo Script",
	"Oranienbaum",
	"Oregano",
	"Orienta",
	"Original Surfer",
	"Over the Rainbow",
	"Overlock SC",
	"Overlock",
	"Ovo",
	"Oxygen Mono",
	"Oxygen",
	"Pacifico",
	"Palanquin Dark",
	"Palanquin",
	"Paprika",
	"Parisienne",
	"Passero One",
	"Passion One",
	"Pathway Gothic One",
	"Patrick Hand SC",
	"Patrick Hand",
	"Patua One",
	"Paytone One",
	"Peddana",
	"Peralta",
	"Petit Formal Script",
	"Philosopher",
	"Piedra",
	"Pinyon Script",
	"Pirata One",
	"Plaster",
	"Play",
	"Playball",
	"Poiret One",
	"Poller One",
	"Poly",
	"Pompiere",
	"Pontano Sans",
	"Port Lligat Sans",
	"Port Lligat Slab",
	"Pragati Narrow",
	"Prata",
	"Preahvihear",
	"Press Start 2P",
	"Princess Sofia",
	"Prociono",
	"Prosto One",
	"PT Mono",
	"PT Sans Caption",
	"PT Sans Narrow",
	"PT Sans",
	"PT Serif Caption",
	"PT Serif",
	"Puritan",
	"Purple Purse",
	"Quando",
	"Quantico",
	"Quattrocento Sans",
	"Quattrocento",
	"Questrial",
	"Quintessential",
	"Qwigley",
	"Racing Sans One",
	"Radley",
	"Rajdhani",
	"Ramabhadra",
	"Ramaraja",
	"Rambla",
	"Rammetto One",
	"Ranchers",
	"Ranga",
	"Rationale",
	"Ravi Prakash",
	"Reenie Beanie",
	"Revalia",
	"Ribeye Marrow",
	"Ribeye",
	"Righteous",
	"Risque",
	"Roboto",
	"Romanesco",
	"Ropa Sans",
	"Rosarivo",
	"Rouge Script",
	"Rozha One",
	"Rubik Mono One",
	"Rubik One",
	"Rufina",
	"Ruge Boogie",
	"Ruluko",
	"Rum Raisin",
	"Ruslan Display",
	"Russo One",
	"Ruthie",
	"Rye",
	"Sacramento",
	"Sail",
	"Salsa",
	"Sanchez",
	"Sancreek",
	"Sansita One",
	"Sarina",
	"Sarpanch",
	"Scada",
	"Seaweed Script",
	"Sevillana",
	"Seymour One",
	"Shadows Into Light Two",
	"Shadows Into Light",
	"Shanti",
	"Share Tech Mono",
	"Share Tech",
	"Share",
	"Shojumaru",
	"Short Stack",
	"Siemreap",
	"Sigmar One",
	"Simonetta",
	"Sintony",
	"Sirin Stencil",
	"Six Caps",
	"Skranji",
	"Slabo 13px",
	"Slabo 27px",
	"Smythe",
	"Sniglet",
	"Snippet",
	"Snowburst One",
	"Sofadi One",
	"Sofia",
	"Sonsie One",
	"Sorts Mill Goudy",
	"Source Code Pro",
	"Source Sans Pro",
	"Source Serif Pro",
	"Spicy Rice",
	"Spinnaker",
	"Spirax",
	"Squada One",
	"Sree Krushnadevaraya",
	"Stalemate",
	"Stalinist One",
	"Stardos Stencil",
	"Stint Ultra Condensed",
	"Stint Ultra Expanded",
	"Stoke",
	"Strait",
	"Sue Ellen Francisco",
	"Supermercado One",
	"Suranna",
	"Suravaram",
	"Suwannaphum",
	"Swanky and Moo Moo",
	"Tangerine",
	"Taprom",
	"Tauri",
	"Teko",
	"Telex",
	"Tenali Ramakrishna",
	"Tenor Sans",
	"Text Me One",
	"The Girl Next Door",
	"Tienne",
	"Timmana",
	"Titan One",
	"Titillium Web",
	"Trade Winds",
	"Trocchi",
	"Trochut",
	"Trykker",
	"Tulpen One",
	"Uncial Antiqua",
	"Underdog",
	"Unica One",
	"UnifrakturCook",
	"UnifrakturMaguntia",
	"Unlock",
	"Unna",
	"Vampiro One",
	"Varela Round",
	"Varela",
	"Vast Shadow",
	"Vesper Libre",
	"Vibur",
	"Vidaloka",
	"Viga",
	"Voces",
	"Volkhov",
	"Voltaire",
	"VT323",
	"Waiting for the Sunrise",
	"Wallpoet",
	"Warnes",
	"Wellfleet",
	"Wendy One",
	"Wire One",
	"Yeseva One",
	"Yesteryear",
	"Zeyada",
];

export const customFonts = { Arial: true, "Arial Black": true };
