import { getObjectValue, hasProperty, isValidEmail, postApi, useQuery } from "@cti-workspace/helpers";
import { Box, Button, Card, Container, TextField, Typography } from "@cti-workspace/ui";
import { Grid } from "@material-ui/core";
import { ACCOUNTS_PREFIX, JWT_TOKEN } from "libs/helpers/src/lib/Constants";
import { useSnackbar } from "notistack";
import { Warning, Eye, EyeSlash } from "@phosphor-icons/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import ipwruLogo from "../../assets/images/iPWRU_Logo_Med.png";

export default function CreateHolderAccount() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
	} = useForm();

	const { enqueueSnackbar } = useSnackbar();
	const queryParams = useQuery();

	const handleCreateAccount = async (event) => {
		try {
			const { firstName, lastName, email, password } = event;
			const token = queryParams.get("token");
			if (!token) throw new Error("Invalid token, please use valid url");
			// TODO: change to hook
			const resp = await postApi(`/${ACCOUNTS_PREFIX}/createHolderAccount?token=${token}`, { firstName: firstName.trim(), lastName: lastName.trim(), password, email });
			const { token: userToken } = resp.payload;
			localStorage.setItem(JWT_TOKEN, userToken);
			window.location.href = "/wallet";
		} catch (error) {
			enqueueSnackbar(typeof error === "object" ? error.message : error, { variant: "error" });
		}
	};

	return (
		<Box displayCenter minHeight="100vh">
			<Container maxWidth="sm">
				<div style={{ textAlign: "center", marginBottom: "1rem" }}>
					<img src={ipwruLogo} style={{ width: "65%" }} />
				</div>
				<Card style={{ padding: "1.5rem 2.5rem" }}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextInput label="First Name" name="firstName" errors={errors} inputRef={register({ required: "First Name is required", validate: (val) => (/^[a-zA-Z][a-zA-Z- ]*$/.test(val) ? true : "Please enter valid name") })} />
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextInput label="Last Name" name="lastName" errors={errors} inputRef={register({ required: "Last Name is required", validate: (val) => (/^[a-zA-Z][a-zA-Z- ]*$/.test(val) ? true : "Please enter valid name") })} />
						</Grid>
						<Grid item xs={12}>
							<TextInput label="Email" name="email" errors={errors} inputRef={register({ required: "Email is required", validate: (val) => (isValidEmail(val) ? true : "Please enter valid email") })} />
						</Grid>
						<Grid item xs={12}>
							<TextInput
								label="Confirm Email"
								name="confirmEmail"
								errors={errors}
								inputRef={register({ required: "Confirm Email is required", validate: (val) => (isValidEmail(val) ? (getValues().email === val ? true : "Email doesn't match") : "Please enter valid email") })}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextInput label="Password" type="password" name="password" errors={errors} inputRef={register({ required: "Password is required" })} />
						</Grid>
						<Grid item xs={12}>
							<TextInput
								label="Confirm Password"
								type="password"
								name="confirmPassword"
								errors={errors}
								inputRef={register({ required: "Confirm Password is required", validate: (val) => (getValues().password === val ? true : "Password doesn't match") })}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button color="secondary" onClick={handleSubmit(handleCreateAccount)}>
								Create Account
							</Button>
						</Grid>
						<Grid>
							<Typography type="caption" textAlign="center">
								This was triggered by an Administrator, if you find this suspicious please contact support@globaledfoundation.com
							</Typography>
						</Grid>
					</Grid>
				</Card>
			</Container>
		</Box>
	);
}

function TextInput({ label = "", name = "", type = "text", inputRef = null, errors }) {
	const [typePasswordView, setTypePasswordView] = useState(true);
	return (
		<TextField
			label={label}
			variant="static"
			size="small"
			name={name}
			type={type === "password" ? (typePasswordView ? "password" : "text") : type}
			inputRef={inputRef}
			error={hasProperty(errors, name)}
			endAdornment={type === "password" ? typePasswordView ? <EyeSlash weight="fill" onClick={() => setTypePasswordView(false)} /> : <Eye weight="fill" onClick={() => setTypePasswordView(true)} /> : null}
			endAdornmentStyle={{ zIndex: 1, cursor: "pointer" }}
			helperText={
				getObjectValue(errors, `${name}.message`) ? (
					<div style={{ display: "flex", alignItems: "center", gridGap: "0.5rem" }}>
						<Warning weight="fill" /> {getObjectValue(errors, `${name}.message`)}
					</div>
				) : null
			}
		/>
	);
}
