import { ACCOUNTS_PREFIX } from "@cti-workspace/helpers";
import { postApi } from "../api";

async function createAccount(formData, noEmail = false) {
	try {
		const url = `/${ACCOUNTS_PREFIX}/register/${noEmail ? "?noEmail=true" : ""}`;
    const accountData = await postApi(url, { ...formData });
    return accountData;
	} catch (err) {
		throw err;
	}
}

export default createAccount;
