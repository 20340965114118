import { format } from "date-fns";

const convertIssuedTimeToLocal = (credentials) => {
	return credentials.map((credential) => ({
		...credential,
		issuedOn: (credential.issuedOn && format(new Date(credential.issuedOn), "yyyy-MM-dd HH:mm:ss.sss").replace(" ", "T").concat("Z")) || "",
	}));
};

export default convertIssuedTimeToLocal;
