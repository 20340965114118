import { checkFileExtension, parseExcelSheetToJson, useRevokeHook } from "@cti-workspace/helpers";
import { Button, ComboBox, Dialog, DialogActions, DialogContent, DialogTitle, Icon, Spinner, Typography } from "@cti-workspace/ui";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { utils, writeFile } from "xlsx";

const ContentContainer = styled.div`
	height: max-content;
	width: 100%;
	min-height: 150px;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	align-items: center;
	justify-content: center;
	background-color: #fafafa;
	border: 0.5px dashed #424242;
	border-radius: 2px;
	cursor: pointer;
	* {
		cursor: pointer;
	}
`;

export default function RevokeManyDialog({ data = {}, onClose = () => {} }) {
	const [revokeStatus, setRevokeStatus] = useState("");
	const [toRevokeData, setToRevokeData] = useState(null);
	const [errorRevokeData, setErrorRevokeData] = useState([]);
  const [revokeRespData, setRevokeRespData] = useState({});
  const [selectedIdentifier, setSelectedIdentifier] = useState({ label: "Recipient Email", value: "recipientEmail" });

	const { revokeBulkCredentials } = useRevokeHook();
	const { enqueueSnackbar } = useSnackbar();

	const transcriptsData = useMemo(() => {
		const { definition } = data;
		if (!definition) return [];
		const { transcript } = JSON.parse(definition);
		return transcript || [];
	}, data?.definition);

	const handleOnFileSelect = (data) => {
		setToRevokeData(data);
	};

	const onRevoke = async () => {
		setRevokeStatus("revoke-start");
		try {
			const res = await revokeBulkCredentials({
				data: {
					certIds: toRevokeData,
				},
				credentialDefinitionId: data._id,
				credentialKeys: [selectedIdentifier?.value],
			});
			setRevokeRespData(res?.result || {});
			setRevokeStatus("revoke-done");
		} catch (error) {
			console.error(error);
			enqueueSnackbar(error?.message || "Something went wrong", { variant: "error" });
		}
	};

	const handleSelectedIdentifier = (e) => {
		setSelectedIdentifier(e);
	};

	return (
		<Dialog open maxWidth="sm">
			<DialogTitle style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<Typography type="subtitle">Revoke Many</Typography>
				{revokeStatus !== "revoke-start" ? <Button buttonType="icon" icon="cancel" color="primary" invert onClick={onClose} /> : null}
			</DialogTitle>
			<DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "1rem" }}>
				<CredentialIdentifierSection onSelectedIdentifier={handleSelectedIdentifier} transcripts={transcriptsData} value={selectedIdentifier} />
				{!toRevokeData ? (
					<FileDropZone selectedFile={handleOnFileSelect} identifier={selectedIdentifier} errorIdentifier={setErrorRevokeData} credDefId={data._id} />
				) : revokeStatus === "" ? (
					<UploadSuccessful data={toRevokeData?.length || 0} errors={errorRevokeData} />
				) : revokeStatus === "revoke-start" ? (
					<RevokeUnderProgress currentNum={toRevokeData?.length} totalNum={toRevokeData?.length} />
				) : revokeStatus === "revoke-done" ? (
					<RevokeComplete data={revokeRespData} totalNum={toRevokeData?.length} />
				) : null}
			</DialogContent>
			<DialogActions style={{ paddingInline: "1rem", borderTop: "none", paddingTop: "0", ...(revokeStatus === "revoke-start" ? { display: "none" } : {}), justifyContent: revokeStatus === "revoke-done" ? "flex-end" : "space-between" }}>
				{!revokeStatus || revokeStatus === "revoke-start" ? (
					<Button invert onClick={onClose}>
						Cancel
					</Button>
				) : null}
				{toRevokeData && toRevokeData.length > 0 && !revokeStatus && (
					<Button color="warning" onClick={onRevoke}>
						Revoke
					</Button>
				)}
				{revokeStatus === "revoke-done" && (
					<Button color="warning" onClick={onClose}>
						Close
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}

const CredentialIdentifierContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem;
	background-color: #fafafa;
	border: 0.5px solid #c0c0c0;
	border-radius: 2px;
	gap: 3rem;
`;

function CredentialIdentifierSection({ transcripts = [], onSelectedIdentifier = () => {}, value = {} }) {
	const handleTemplateDownload = () => {
		try {
			const wb = utils.book_new();
			var ws = utils.aoa_to_sheet([[value["label"]]]);
			utils.book_append_sheet(wb, ws, "Sheet1");
			writeFile(wb, "cti_revoke_many_template.xlsx");
		} catch (error) {
			console.error(error);
		}
	};
	return (
		<CredentialIdentifierContainer>
			<div>
				<ComboBox
					label="Credential Identifier"
					size="small"
					helperText={"Please select the field you wish to use to identify the credentials to revoke."}
					options={[
            { label: "Recipient Email", value: "recipientEmail" },
            { label: "Recipient Name", value: "recipientName" },
						{ label: "Credential ID", value: "id" },
						...transcripts.map((item) => ({
							label: item?.name,
							value: item?.name,
							isTranscript: true,
						})),
					]}
					getOptionValue={(item) => item["value"]}
					getOptionLabel={(item) => item.label}
					value={value}
					onChange={(e) => onSelectedIdentifier(e)}
				/>
			</div>
			<div>
				<Button leftIcon="cloud-download" color="warning" invert leftIconColor="#000000" onClick={handleTemplateDownload}>
					Template
				</Button>
			</div>
		</CredentialIdentifierContainer>
	);
}

function FileDropZone({ onError = () => {}, selectedFile = () => {}, identifier = {}, credDefId = "", errorIdentifier = () => {} }) {
	const [isLoading, setIsLoading] = useState(false);

	const { searchRevokeByIdentifier } = useRevokeHook();
	const { enqueueSnackbar } = useSnackbar();

	const onDrop = useCallback(
		async (files) => {
			try {
				if (files?.length <= 0) return;
				files.forEach(async (file) => {
					try {
						setIsLoading(true);
						const { type } = file;
						if (!checkFileExtension(type, "xlsx")) throw new Error("Please select valid file format");
						const jsonExcelSheet = await parseExcelSheetToJson(file, true, true, false);
						const { arr = [] } = jsonExcelSheet;
						const toSearchArr = arr.reduce((acc = [], item) => [...acc, item[0]], []);
						const checkIdentifier = toSearchArr.shift();
						// T-21 check for identifier
						if (!identifier?.value) throw new Error("Please select credential identifier");
						if (identifier?.label !== checkIdentifier.trim() || arr.length <= 1)
							throw new Error(
								`"You must include one column with at least 1 row for the credential identifier and 1 or more rows for the credentials to revoke." or "Your credential identifier does not match what is currently selected within the dropdown."`
							);
						const res = await searchRevokeByIdentifier({
							credentialDefinitionId: credDefId,
							data: {
								...(identifier?.isTranscript ? { isTranscriptField: true } : {}),
								key: identifier?.value,
								values: toSearchArr,
							},
						});
						const { result = [] } = res;
						const errorIdentifiers = [];
						const toRevokeCredentials = result.reduce((acc, item) => {
							if (item?.found) {
								const confirmedStatusIds = item?.status?.CONFIRMED || item?.status?.ACTIVE || [];
								return [...acc, ...confirmedStatusIds];
							} else {
								errorIdentifiers.push(item.value);
							}
							return [...acc];
						}, []);
						selectedFile(toRevokeCredentials);
						errorIdentifier(errorIdentifiers);
					} catch (error) {
						enqueueSnackbar(error?.message || "Something went wrong while importing the file", { variant: "error" });
					} finally {
						setIsLoading(false);
					}
				});
			} catch (error) {
				onError(error);
			}
		},
		[identifier]
	);

	const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"], multiple: false });

	return (
		<ContentContainer {...getRootProps({ className: "dropzone" })}>
			{isLoading ? (
				<Fragment>
					<Spinner color="#14C25A" duration={2000} />
					<Typography type="body2" color="caption">
						Importing your file. Please wait.
					</Typography>
				</Fragment>
			) : (
				<Fragment>
					<input className="input-zone" {...getInputProps()} />
					<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
						<Icon name="cloud-upload" weight="regular" color="#9C49ED" size={30} />
						<Typography type="h5" style={{ textAlign: "center" }}>
							Drag & Drop/Select
						</Typography>
					</div>
				</Fragment>
			)}
		</ContentContainer>
	);
}

function UploadSuccessful({ data = "", errors = [] }) {
	return (
		<ContentContainer style={{ backgroundColor: "#E8F9EF", gap: "0.25rem" }}>
			<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
				<Icon name="checkcircle" size={40} color="#14C25A" />
				<Typography type="body2" style={{ color: "#424242" }}>
					Upload successful
				</Typography>
				<Typography type="body1" style={{ color: "#424242" }} fontWeight="bold">
					{data} credentials identified to be revoked
				</Typography>
			</div>
			{errors && errors.length > 0 ? (
				<div style={{ width: "80%", borderTop: "1px solid #C0C0C0", display: "flex", flexDirection: "column", alignItems: "center", gap: "0.2rem", paddingTop: "0.5rem", height: "100px", overflow: "scroll" }}>
					{errors.map((item, index) => (
						<div key={index} style={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
							<Icon name="warning-circle" color="#E73152" />
							<Typography type="body4" color="text">
								"{item}" - Identifier not found
							</Typography>
						</div>
					))}
				</div>
			) : null}
		</ContentContainer>
	);
}

function RevokeUnderProgress({ currentNum = 0, totalNum = 0 }) {
	return (
		<ContentContainer>
			<Spinner color="#14C25A" duration={1500} />
			<Typography type="body2" color="caption">
				Revoking Credentials: {currentNum} of {totalNum}
			</Typography>
		</ContentContainer>
	);
}

function RevokeComplete({ data: { revokedCerts = 0, errors = [] }, totalNum = 0 }) {
	return (
		<ContentContainer>
			<Icon name="checkcircle" size={45} color="#14C25A" />
			<Typography type="body2" color="caption">
				Revoked Credentials: {revokedCerts} of {totalNum}
			</Typography>
			{errors?.length > 0 ? (
				<div style={{ width: "80%", borderTop: "1px solid #C0C0C0", display: "flex", flexDirection: "column", alignItems: "center", gap: "0.2rem", paddingTop: "0.5rem" }}>
					<div>
						{errors.map((item, index) => (
							<div key={index} style={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
								<Icon name="warning-circle" color="#E73152" />
								<Typography type="body4" color="text">
									{item.message}
								</Typography>
							</div>
						))}
					</div>
				</div>
			) : null}
		</ContentContainer>
	);
}
