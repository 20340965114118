import mapValues from "lodash/mapValues";

const UUIDV4_LENGTH = 37;
const PRIMITIVE_TYPES = ["string", "number", "boolean", "undefined"];

function assertString(input) {
	const isString = typeof input === "string" || input instanceof String;

	if (!isString) {
		let invalidType = typeof input;
		if (input === null) invalidType = "null";
		else if (invalidType === "object") invalidType = input.constructor.name;

		throw new TypeError(`Expected a string but received a ${invalidType}`);
	}
}

const uuid = {
	1: /^[0-9A-F]{8}-[0-9A-F]{4}-1[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
	2: /^[0-9A-F]{8}-[0-9A-F]{4}-2[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
	3: /^[0-9A-F]{8}-[0-9A-F]{4}-3[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
	4: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
	5: /^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
	all: /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
};

function isUUID(str, version) {
	assertString(str);
	const pattern = uuid[![undefined, null].includes(version) ? version : "all"];
	return !!pattern && pattern.test(str);
}

const startsWithUuidV4 = (input) => {
	if (input && typeof input === "string") {
		const elements = input.split(":");
		return isUUID(elements[0], 4);
	}
	return false;
};

export function typedStringToPrimitive(input) {
	const [type, ...valueArray] = input.split(":");
	const value = valueArray.join(":"); // just in case there are colons in the value

	switch (type) {
		case "number":
			return Number(value);
		case "string":
			return String(value);
		case "boolean":
			return value === "true";
		case "null":
			return null;
		case "undefined":
			return undefined;
		default:
			throw new Error(`Parsing error, type annotation not found in string: ${input}`);
	}
}

const recursivelyApply = (iteratee) => (value) => {
	if (PRIMITIVE_TYPES?.includes(typeof value) || value === null) {
		return iteratee(value);
	}
	return deepMap(value, iteratee); // eslint-disable-line @typescript-eslint/no-use-before-define
};

const deepMap = (collection, iteratee) => {
	if (collection instanceof Array) {
		return collection?.map(recursivelyApply(iteratee));
	}
	if (typeof collection === "object") {
		return mapValues(collection, recursivelyApply(iteratee));
	}
	return collection;
};

export function unsalt(value) {
	if (startsWithUuidV4(value)) {
		const untypedValue = value.substring(UUIDV4_LENGTH).trim();
		return typedStringToPrimitive(untypedValue);
	}
	return value;
}

const unsaltData = (data) => deepMap(data, unsalt);

export const getData = (document) => {
	return unsaltData(document.data);
};
