import { createStyles, Theme } from "@material-ui/core/styles";

const Styles = (theme) =>
	createStyles({
		formContainer: {
			padding: 20,
			margin: 20,
		},

		header: {
			color: "#fff",
			fontFamily: "Roboto",
			fontWeight: 600,
			letterSpacing: "4.2px",
		},

		logo: {
			paddingTop: 2,
			paddingBottom: 2,
			width: 300,
		},
		questLogo: {
			paddingTop: 5,
			paddingBottom: 5,
			width: 300,
		},
		connectionDiv: {
			margin: 0,
			paddingTop: 20,
		},
		connectLink: {
			color: "#3A506B",
		},
		center: {
			maxWidth: 400,
			minWidth: 300,
			paddingTop: 50,
			marginLeft: "auto",
			marginRight: "auto",
			textAlign: "center",
			minHeight: "100vh",
		},
	});

export default Styles;
