import styled from "styled-components";
import { ThemeProvider } from "theme-ui";
import Theme from "../theme/theme";

const CardBody = styled.div`
	width: ${({ maxWidth }) => `${maxWidth}px`};
	height: ${({ minHeight }) => `${minHeight}px`};
	border-radius: 4px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08);
	overflow: hidden;
	display: inline-grid;
	background-color: white;
	grid-template-columns: 16px 4px 60px 80px 80px;
	grid-template-rows: auto;
	grid-template-areas: ${({ label }) =>
		label
			? `"sidebar . main main main"
		"sidebar . main main main "
"sidebar . main main main"`
			: `". main main main main"
		". main main main main "
". main main main main"`};
`;

const LabelContainer = styled.div`
	font-size: 0.65rem;
	background-color: ${Theme.colors.secondary};
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0.5px;
	grid-area: sidebar;
	padding-right: 2px;
`;

const LabelContainerText = styled.label`
	color: white;
	transform: rotate(-90deg);
`;

const CardDetailsContainer = styled.div`
	margin-block: 0.6rem;
	grid-area: main;
	border: 1px;
	display: inline-grid;
	overflow: hidden;
	grid-template-columns: 1fr 4fr;
	grid-template-rows: auto;
	column-gap: 10px;
	grid-template-areas:
		"img title title title"
		"img title title title "
		". title title title";
`;

const TitleDiv = styled.div`
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	word-break: break-word;
`;
const TextDiv = styled.div`
	margin: 0px;
	overflow: hidden;
	grid-area: title;
`;

const TitleText = styled.h6`
	margin: 0px;
	font-size: 1rem;
`;
const DescriptionText = styled.span`
	margin: 0px;
	font-size: 0.7rem;
	width: 165px;
	display: -webkit-box !important;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	opacity: 0.65;
`;

const ImgDiv = styled.div`
	margin: 3px 0px 0px 0px;
	font-size: 0.7rem;
	grid-area: img;
`;

const CardImg = styled.img`
	max-height: 2rem;
`;

export function ChartCard(props) {
	const { data, width, height } = props;
	const title = data.name;
	const imgUrl = data.icon;
	const description = data.description;
	const isRequired = data.isRequired;
	return (
		<ThemeProvider theme={Theme}>
			<CardBody maxWidth={width} minHeight={height} label={isRequired}>
				{isRequired && (
					<LabelContainer>
						<LabelContainerText>Required</LabelContainerText>
					</LabelContainer>
				)}

				<CardDetailsContainer label={"isRequired"}>
					<ImgDiv>{imgUrl && <CardImg src={imgUrl} />}</ImgDiv>
					<TextDiv>
						<TitleDiv>
							<TitleText>{title}</TitleText>
						</TitleDiv>
						<DescriptionText>{description}</DescriptionText>
					</TextDiv>
				</CardDetailsContainer>
			</CardBody>
		</ThemeProvider>
	);
}

/** @jsxImportSource theme-ui */
export default ChartCard;
