import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, Typography } from "@cti-workspace/ui";
import styled from "styled-components";
import { formatInTimeZone } from "date-fns-tz";
import { isValidHttpUrl, openAttestationGetData } from "@cti-workspace/helpers";

const ViewCardContentContainer = styled.div`
	padding: 12px 16px;
	background-color: #fafafa;
	border: 0.5px solid #c0c0c0;
	border-radius: 2px;
`;

const CardContentDetails = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 0.5rem;
	flex-direction: ${({ column }) => (column ? "column" : "row")};
	margin-top: 0.5rem;
`;

const LabelValueContentContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
	gap: 0.3rem;
`;

const StatusContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.3rem;
`;

const processGetData = (rawDocument) => {
	try {
		let val = openAttestationGetData(rawDocument);
		if (val) {
			return val;
		} else {
			return rawDocument;
		}
	} catch (error) {
		return rawDocument;
	}
};

export default function AuthorizationViewDialog({ type = "view", data = {}, onClickAuthorize = () => {}, onClose = () => {}, isLoading = false, expirationTime = null }) {
	const {
		credential: { imageUrl, name, rawCertificate },
		subjectFirstName,
		subjectLastName,
		transcriptGrades = {},
		recipientFirstName,
		recipientLastName,
		recipientEmail,
		recipientAuthentication,
		expirationDate,
		expirationTime: expirationViewTime,
		requiredUserAuthentications,
		expirationTimeZone,
		status = "ACTIVE",
	} = data;

	let recipientAuthenticationValues = [];
	let transcriptValues = [];
	const authenticationKeys = {
		subjectFirstName: "Subject First Name",
		subjectLastName: "Subject Last Name",
	};

	if (type === "view") {
		const rawDocument = JSON.parse(rawCertificate);
		const processedData = processGetData(rawDocument);
		transcriptValues =
			processedData?.transcript?.map((value) => {
				return {
					name: value.name,
					value: value.grade,
				};
			}) ?? [];
		recipientAuthenticationValues = requiredUserAuthentications?.[0]?.claims?.map((value) => value?.name);
	} else {
		transcriptValues =
			Object.keys(transcriptGrades || {}).map((transcript) => {
				return { name: transcript, value: transcriptGrades[transcript] };
			}) ?? [];
		recipientAuthenticationValues = Object.keys(recipientAuthentication || {})
			.filter((recipientAuth) => recipientAuthentication[recipientAuth])
			.map((recipientAuth) => authenticationKeys[recipientAuth] || recipientAuth);
	}

	// const date = expirationDate && expirationTime ? new Date(`${expirationDate}T${expirationTime}`) : new Date(expirationTime);
	const expiryTimeZone = type === "view" ? Intl.DateTimeFormat().resolvedOptions().timeZone : expirationTimeZone;
	const date = type === "view" ? new Date(expirationViewTime) : new Date(expirationTime);
	// expirationTimeZone && date.toLocaleString({ timeZone: expirationTimeZone });

	const authStatusUI = (status = "ACTIVE") => {
		switch (status) {
			case "EXPIRED":
				return (
					<StatusContainer>
						<Icon name="expired" />
						<Typography type="body4" style={{ color: "#FC7377" }}>
							Expired
						</Typography>
					</StatusContainer>
				);
			case "REVOKED":
				return (
					<StatusContainer>
						<Icon name="warning-circle" weight="regular" color="#FC7377" />
						<Typography type="body4" style={{ color: "#FC7377" }}>
							Revoked
						</Typography>
					</StatusContainer>
				);
			default:
				return (
					<StatusContainer>
						<Icon name="active" size={15} />
						<Typography type="body4" color="warning">
							Active
						</Typography>
					</StatusContainer>
				);
		}
	};
	return (
		<Dialog open borderRadius="8px" dialogStyle={{ maxWidth: "650px" }}>
			<DialogTitle style={{ borderBottom: "none", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<Typography type="h5">{type === "view" ? "View" : "Review"} authorization details</Typography>
				{type === "view" && authStatusUI(status)}
			</DialogTitle>
			<DialogContent style={{ paddingBlock: "0px", display: "flex", flexDirection: "column", gap: "0.5rem" }}>
				<ViewCardContentContainer>
					<Typography type="body1" fontWeight="700">
						Credential
					</Typography>
					<CardContentDetails style={{ alignItems: "center" }}>
						{imageUrl && <img src={imageUrl} style={{ maxWidth: "1rem", maxHeight: "1rem" }} />}
						<Typography type="body2" color="textValue2">
							{name}
						</Typography>
					</CardContentDetails>
				</ViewCardContentContainer>
				<ViewCardContentContainer>
					<Typography type="body1" fontWeight="700">
						Credential Subject
					</Typography>
					<CardContentDetails>
						<LabelValue label="First Name" value={subjectFirstName} />
						<LabelValue label="Last Name" value={subjectLastName} />
					</CardContentDetails>
				</ViewCardContentContainer>
				{transcriptValues.length > 0 && (
					<ViewCardContentContainer>
						<Typography type="body1" fontWeight="700">
							Credential Variables
						</Typography>
						<CardContentDetails column>
							{transcriptValues.map((transcript) =>
								/^data:image\/(?:gif|png|jpeg|bmp|webp)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/g.test(transcript.value) || isValidHttpUrl(transcript.value) ? (
									<LabelValue label={transcript.name} value={""} type="image" src={transcript.value} />
								) : (
									<LabelValue label={transcript.name} value={transcript.value} />
								)
							)}
							{/* <LabelValue label="Province" value="Ontario" />
                        <LabelValue label="Date of Birth" value="02/02/2022" />
                        <LabelValue label="Photo" value="FirstPhoto.png" type="image" src="https://s3.ca-central-1.amazonaws.com/trybe.public.assets/Trybe.ID/seal.png" /> */}
						</CardContentDetails>
					</ViewCardContentContainer>
				)}
				<ViewCardContentContainer>
					<Typography type="body1" fontWeight="700">
						Authorization Recipient
					</Typography>
					<CardContentDetails>
						<LabelValue label="First Name" value={recipientFirstName} />
						<LabelValue label="Last Name" value={recipientLastName} />
						<LabelValue label="Email" value={recipientEmail} />
					</CardContentDetails>
				</ViewCardContentContainer>
				{recipientAuthenticationValues?.length > 0 && (
					<ViewCardContentContainer>
						<Typography type="body1" fontWeight="700">
							Recipient Authentication
						</Typography>
						<CardContentDetails>
							{recipientAuthenticationValues.map((recipientAuth) => (
								<LabelValue label={recipientAuth} type="checkbox" />
							))}
						</CardContentDetails>
					</ViewCardContentContainer>
				)}
				<ViewCardContentContainer>
					<Typography type="body1" fontWeight="700">
						Authorized Until
					</Typography>
					<CardContentDetails>
						<LabelValue label="" value={date && formatInTimeZone(date, expiryTimeZone, "PPP hh:mm a zzz")} />
					</CardContentDetails>
				</ViewCardContentContainer>
			</DialogContent>
			<DialogActions style={{ justifyContent: "space-between", padding: "1rem", borderTop: "none" }}>
				<Button invert onClick={onClose} disabled={isLoading}>
					Close
				</Button>
				{type === "review" && (
					<Button color="warning" onClick={onClickAuthorize} disabled={isLoading}>
						Authorize{" "}
						{isLoading && (
							<Icon name={"Spinner"} size={"1rem"}>
								<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 0 0" to="360 0 0" repeatCount="indefinite" />
							</Icon>
						)}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
}

function LabelValue({ label = "", value = "", type = "text", src = "" }) {
	return (
		<LabelValueContentContainer style={type === "image" ? { alignItems: "flex-start" } : {}}>
			{label && type !== "checkbox" ? (
				<Typography type="body2" fontWeight="700">
					{label}:
				</Typography>
			) : type === "checkbox" ? (
				<>
					<Icon name="check-square" size={20} />
					<Typography type="body4" fontWeight="400">
						{label}
					</Typography>
				</>
			) : null}
			{type !== "checkbox" ? (
				<div>
					<Typography type="body2" style={{ wordBreak: "break-all" }}>
						{value}
					</Typography>
					{type === "image" && <img src={src} style={{ maxWidth: "100px", maxHeight: "100px" }} />}
				</div>
			) : null}
		</LabelValueContentContainer>
	);
}
