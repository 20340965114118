import { useContext } from "react";
import { fabric } from "fabric-pure-browser";
import { FabricContext } from "../../context/FabricContext";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import { TextAa, TextT } from "@phosphor-icons/react";
import { getObjectValue } from "@cti-workspace/helpers";

const FabricAddText = ({ id, enableMultiPageCertificate }) => {
	const { canvas } = useContext(FabricContext);

	const addTextBox = () => {
		const textBox = new fabric.Textbox("Add your text here", {
			width: 200,
			top: 10,
			left: 10,
			fontSize: 24,
			fontWeight: "normal",
			fontStyle: "normal",
			textAlign: "left",
			fontFamily: "ABeeZee",
			textDecoration: "normal",
			fill: "#000000",
		});
		const activeCanvas = enableMultiPageCertificate ? getObjectValue(canvas, id) : canvas;
		activeCanvas.add(textBox);
	};

	return <TextT onClick={addTextBox} size={17} />;
};

export default FabricAddText;
