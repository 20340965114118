import { useState } from "react";
import { getApi, postApi, delApi, putApi, getErrMsg, PRESENTATION_PREFIX} from "@cti-workspace/helpers";
import useWidth from "./useWidthHook";
import useNProgressHook from "./useNProgressHook";
import { useSnackbar } from "notistack";

export default function usePresentationHook() {
	const [presentationDefinitions, setPresentationDefinitions] = useState([]);

	const { progressStart, progressDone } = useNProgressHook();
	const { enqueueSnackbar } = useSnackbar();

	const currentWidth = useWidth();

	const addPresentationDefinition = async (data = {}) => {
		try {
			const res = await postApi(`/${PRESENTATION_PREFIX}/addPresentationDefinition`, data);
			return res;
		} catch (error) {
			throw error;
		}
	};
	const updatePresentationDefinition = async (data = {}) => {
		try {
			const res = await putApi(`/${PRESENTATION_PREFIX}/updatePresentationDefinition`, data);
			return res;
		} catch (error) {
			throw error;
		}
	};
	const getPresentationDefinitionItems = async (search = "", pageNumber = 1, pageSize = 10) => {
		try {
			switch (currentWidth) {
				case "xs":
					pageSize = 10;
					break;
				case "sm":
				case "md":
					pageSize = 20;
					break;
				case "lg":
					pageSize = 9;
					break;
				default:
					pageSize = 9;
			}
			let query = {};
			if (search) {
				query.search = search;
			}
			query.pageNumber = pageNumber;
			query.pageSize = pageSize;
			const searchParams = new URLSearchParams(query);
			const res = await getApi(`/${PRESENTATION_PREFIX}/getBatchPresentationDefinitions?${searchParams}`);
			setPresentationDefinitions(res);
			return res;
		} catch (error) {
			throw error;
		}
	};
	const deletePresentationDefinition = async (data = {}) => {
		try {
			progressStart();
			const res = await delApi(`/${PRESENTATION_PREFIX}/deletePresentationDefinition`, data);
			// await getPresentationDefinitions();
			return res;
		} catch (error) {
			enqueueSnackbar(getErrMsg(error), { variant: "error" });
			throw error;
		} finally {
			progressDone();
		}
	};
	const getPresentationDefinitions = async (id) => {
		try {
			const res = await getApi(`/${PRESENTATION_PREFIX}/getPresentationDefinitions`);
			setPresentationDefinitions(res);
			return res;
		} catch (error) {
			const { code } = error;
			if (code === "NotFound") {
				setPresentationDefinitions([]);
				return [];
			}
			throw error;
		}
	};
	return {
		addPresentationDefinition,
		getPresentationDefinitionItems,
		presentationDefinitions,
		deletePresentationDefinition,
		getPresentationDefinitions,
		updatePresentationDefinition,
	};
}
