import QRCode from "qrcode.react";
import {Box} from "@cti-workspace/ui";

const QRCodeViewer = (props) => (
  <Box border={5} padding={1} marginBottom={2} borderColor="warning">
    <QRCode {...props}/>
  </Box>
)

export default QRCodeViewer;
