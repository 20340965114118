import { Controller, FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import { format } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { CredentialAsyncComboBox } from "../definePathway/definePathway";
import { buildCertificateToIssue, CONTENT_TYPE, getObjectValue, hasProperty, IMAGE_CONTENT, TIMEZONE_LIST, useAuthorization } from "@cti-workspace/helpers";
import { Grid } from "@material-ui/core";
import { Box, Breadcrumbs, Button, Card, ComboBox, Container, TextField, Typography as Text } from "@cti-workspace/ui";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useMemo, useRef, useState } from "react";
import TranscriptValueForm from "../../components/IssueCredential/transcriptValueForm";
import { useNavigate } from "react-router-dom";
import AuthorizationViewDialog from "./components/authorizationViewDialog";
import { userStore } from "../../store/userStore";

const Description = styled.p`
	color: rgba(0, 0, 0, 0.54);
	font-size: 0.75rem;
	line-height: 1.2rem;
	margin: 0px;
`;

const TIMEZONE_LIST_OPTIONS = TIMEZONE_LIST.map((value) => ({ label: value, value: value }));

const cardStyle = {
	backgroundColor: "#FAFAFA",
	padding: "0.8rem",
	margin: "0",
};

const AuthorizeDefinitionForm = () => {
	const { watch, control, formState, handleSubmit, errors, ...methods } = useForm({
		mode: "onChange",
		shouldFocusError: false,
		criteriaMode: "all",
	});
	const { authorizeCredential } = useAuthorization();
	const [formData, setFormData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const transcriptChangeRef = useRef();
	const organizations = userStore((state) => state.organizations);
	const selectedCredential = watch("credential");

	const navigate = useNavigate();

	const transcriptsOption = useMemo(() => {
		if (selectedCredential?.definition?.transcript?.length > 0) {
			let transcriptGrades = {};
			selectedCredential?.definition?.transcript?.forEach((value) => {
				transcriptGrades = { ...transcriptGrades, [value.name]: value.grade };
			});
			if (!!transcriptChangeRef.current) {
				transcriptChangeRef.current(transcriptGrades);
			}
			return {
				transcripts: selectedCredential?.definition?.transcript,
				transcriptGrades,
			};
		} else {
			if (!!transcriptChangeRef.current) {
				transcriptChangeRef.current({});
			}
			return {
				transcripts: [],
				transcriptGrades: {},
			};
		}
	}, [selectedCredential?._id]);

	const handleAuthorizeFormToConfirm = (event) => {
		setFormData(event);
	};

	const onClickAuthorize = async () => {
		try {
			const { credential: credDef, transcriptGrades, subjectFirstName, subjectLastName, expirationDate, expirationTime, expirationTimeZone, recipientAuthentication } = formData;
			// TODO: api call to submit authorization form data;
			/**
       const dummyPayload = [
       {
					credential: {

						credentialDefinitionId: "",
						credentialDefinitionName: "",
					},
					options: {
						requiredUserAuthentication: [
							{
								property: "ppn",
								name: "Passport Number",
								description: "Passport Number.",
								acceptedValues: ["HN1234"],
								reason: "authentication",
								orgId: "<orgId>",
							},
						],
						holderContactDetails: {
							lastName: "",
							id: "",
							firstName: "",
							to: "as@gmail.com",
						},
						indexes: "",
						multiUse: true,
						responseType: "WALLET_INSTALL_AND_DOWNLOAD_QR",
						credentialFormat: "",
					},
					authorization: {
						expirationTime: "",
					},
				},
       ];*/
			let customTranscriptsObject = {};
			if (credDef?.customCredentials?.length > 0) {
				credDef?.customCredentials?.[0]?.credentialMappings?.forEach((value) => {
					if (value && value.credDefContentName && !customTranscriptsObject[value.credDefContentName]) {
						customTranscriptsObject = {
							...customTranscriptsObject,
							[value.credDefContentName]: {
								property: value.credSubjectKey || value.credDefContentName,
								idpClaimName: value.credSubjectKey || value.credDefContentName,
								name: value.credDefContentName,
								description: value.credDefContentName,
								acceptedValues: [],
								reason: "authentication",
							},
						};
					}
				});
			}
			const expiryDateWithTime = new Date(`${expirationDate}:${expirationTime}`);
			const utcExpiryDate = zonedTimeToUtc(expiryDateWithTime, expirationTimeZone);
			const certi = buildCertificateToIssue(
				`${subjectFirstName} ${subjectLastName}`,
				subjectFirstName,
				subjectLastName,
				"",
				{
					...credDef,
					definition: JSON.stringify(credDef.definition),
				},
				transcriptGrades,
				[],
				{}
			);

			const submitPayload = {
				credential: {
					...certi,
					credentialDefinitionId: credDef._id,
					credentialDefinitionName: certi.name,
				},
				options: {
					requiredUserAuthentications: [
						{
							identityProvider: "CTI",
							claims: recipientAuthentication
								? Object.keys(recipientAuthentication)
										.filter((key) => recipientAuthentication[key])
										.map((key) => {
											const name = key === "subjectFirstName" ? "Subject first name" : key === "subjectLastName" ? "Subject last name" : key;
											const property = key === "subjectFirstName" ? "recipientFirstName" : key === "subjectLastName" ? "recipientLastName" : name;
											const { [name]: customRequiredUserAuthentications } = customTranscriptsObject;
											if (key === "subjectFirstName" || key === "subjectLastName" || !customRequiredUserAuthentications) {
												return {
													property: property,
													idpClaimName: property,
													name: name,
													description: name,
													acceptedValues: [],
													reason: "authentication",
												};
											} else {
												return customRequiredUserAuthentications;
											}
										})
								: [],
						},
					],
					holderContactDetails: {
						lastName: formData["recipientLastName"],
						firstName: formData["recipientFirstName"],
						to: formData["recipientEmail"],
					},
					orgId: organizations?.default?._id,
					expirationTime: utcExpiryDate,
				},
				authorization: {
					expirationTime: utcExpiryDate.toISOString(),
				},
			};
			setIsLoading(true);
			await authorizeCredential(submitPayload, (error = false) => {
				setIsLoading(false);
				if (!error) navigate("/authorizations");
			});
		} catch (error) {
			setIsLoading(false);
			console.error(error);
		} finally {
			setFormData(null);
		}
	};

	return (
		<FormProvider {...methods} control={control}>
			<div style={{ minHeight: 56 }} />
			<Container maxWidth={"md"}>
				<Box>
					{/* <Card
						hasBorder
						borderRadius={"10px"}
						elevation={6}
						style={{
							padding: "0.7rem 0.9rem",
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}>
						<Text type="subtitle">New authorization</Text>
					</Card> */}
					<div style={{ marginBottom: "1.5rem" }}>
						<Breadcrumbs
							options={[{ label: "Authorizations", navigateTo: "/authorizations" }, { label: "Authorize Access" }]}
							onCrumbClick={({ navigateTo }) => {
								navigate(navigateTo);
							}}
						/>
					</div>
					<form onSubmit={handleSubmit(handleAuthorizeFormToConfirm)}>
						<Card hasBorder elevation={6} style={{ padding: "0.9rem", display: "flex", flexDirection: "column", gap: "1rem" }} borderRadius="10px">
							<Card hasBorder elevation={0} borderRadius="2px" style={cardStyle}>
								<Text type="body1">Credential*</Text>
								<Description>Select the credential you wish to authorize access to</Description>
								<Grid item xs={6}>
									<Controller
										control={control}
										name="credential"
										rules={{
											required: "Credential is required",
										}}
										defaultValue={""}
										render={(render) => (
											<CredentialAsyncComboBox
												required
												placeholder="Select your credential"
												defaultValue={""}
												{...render}
												onChange={(value) => {
													const updatedValue = { ...value };
													updatedValue.definition = JSON.parse(updatedValue.definition);
													render.onChange(updatedValue);
												}}
												selectFullDocument={true}
												error={hasProperty(errors, "credential")}
												helperText={getObjectValue(errors, `credential.message`)}
											/>
										)}
									/>
								</Grid>
							</Card>
							<Card hasBorder elevation={0} borderRadius="2px" style={cardStyle}>
								<Text type="body1">Credential Subject*</Text>
								<Description>Who this credential is about</Description>
								<Grid container alignItems="flex-start" spacing={2}>
									<Grid item xs={6}>
										<Controller
											control={control}
											name={`subjectFirstName`}
											rules={{
												required: "First name is required",
											}}
											render={(propsField) => {
												const { value, onChange, name } = propsField;
												return (
													<TextField
														label={"First Name"}
														variant="static"
														name={name}
														size="small"
														value={value}
														id={`${name}`}
														data-testid={`${name}_key`}
														placeholder={"Name*"}
														onChange={onChange}
														style={{ flexGrow: 1 }}
														error={hasProperty(errors, name)}
														helperText={getObjectValue(errors, `${name}.message`)}
													/>
												);
											}}
										/>
									</Grid>
									<Grid item xs={6}>
										<Controller
											control={control}
											name={`subjectLastName`}
											rules={{
												required: "Last Name is required",
											}}
											render={(propsField) => {
												const { value, onChange, name } = propsField;
												return (
													<TextField
														variant="static"
														label={"Last Name"}
														name={name}
														size="small"
														value={value}
														id={`${name}`}
														data-testid={`${name}_key`}
														placeholder={"Name*"}
														onChange={onChange}
														style={{ flexGrow: 1 }}
														error={hasProperty(errors, name)}
														helperText={getObjectValue(errors, `${name}.message`)}
													/>
												);
											}}
										/>
									</Grid>
								</Grid>
							</Card>
							<Card hasBorder elevation={0} borderRadius="2px" style={cardStyle}>
								<Text type="body1">Credential Variables</Text>
								<Controller
									control={control}
									name="transcriptGrades"
									defaultValue={transcriptsOption.transcriptGrades}
									render={({ onChange, value }) => {
										transcriptChangeRef.current = onChange;
										return <TranscriptValueForm transcriptGrades={value} transcripts={transcriptsOption.transcripts} settranscriptGrades={onChange} />;
									}}
								/>
							</Card>
							<Card hasBorder elevation={0} borderRadius="2px" style={cardStyle}>
								<Text type="body1">Authorization Recipient*</Text>
								<Description>The Recipient who will be authorized to access the credential for a period of time specified below</Description>
								<Grid container alignItems="flex-start" spacing={2}>
									<Grid item xs={4}>
										<Controller
											control={control}
											name={`recipientFirstName`}
											rules={{
												required: "First name is required",
											}}
											render={(propsField) => {
												const { value, onChange, name } = propsField;
												return (
													<TextField
														label={"First Name"}
														variant="static"
														name={name}
														size="small"
														value={value}
														id={`${name}`}
														data-testid={`${name}_key`}
														placeholder={"Name*"}
														onChange={onChange}
														style={{ flexGrow: 1 }}
														error={hasProperty(errors, name)}
														helperText={getObjectValue(errors, `${name}.message`)}
													/>
												);
											}}
										/>
									</Grid>
									<Grid item xs={4}>
										<Controller
											control={control}
											name={`recipientLastName`}
											rules={{
												required: "Last Name is required",
											}}
											render={(propsField) => {
												const { value, onChange, name } = propsField;
												return (
													<TextField
														variant="static"
														label={"Last Name"}
														name={name}
														size="small"
														value={value}
														id={`${name}`}
														data-testid={`${name}_key`}
														placeholder={"Name*"}
														onChange={onChange}
														style={{ flexGrow: 1 }}
														error={hasProperty(errors, name)}
														helperText={getObjectValue(errors, `${name}.message`)}
													/>
												);
											}}
										/>
									</Grid>
									<Grid item xs={4}>
										<Controller
											control={control}
											name={`recipientEmail`}
											rules={{
												required: "Email is required",
												pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
											}}
											render={(propsField) => {
												const { value, onChange, name } = propsField;
												return (
													<TextField
														variant="static"
														label={"Email"}
														name={name}
														size="small"
														value={value}
														id={`${name}`}
														data-testid={`${name}_key`}
														placeholder={"email*"}
														onChange={onChange}
														style={{ flexGrow: 1 }}
														error={hasProperty(errors, name)}
														helperText={getObjectValue(errors, `${name}.message`)}
													/>
												);
											}}
										/>
									</Grid>
								</Grid>
							</Card>
							<Card hasBorder elevation={0} borderRadius="2px" style={cardStyle}>
								<Text type="body1">Recipient Authentication</Text>
								<Description>The details the recipient must enter to access their authorization</Description>
								<Controller
									control={control}
									name={`recipientAuthentication.subjectFirstName`}
									render={(propsField) => {
										const { value, onChange } = propsField;
										return <FormControlLabel control={<Checkbox checked={value} color="secondary" onChange={() => onChange(!value)} />} label="Subject First Name" labelPlacement="end" />;
									}}
								/>
								<Controller
									control={control}
									name={`recipientAuthentication.subjectLastName`}
									render={(propsField) => {
										const { value, onChange } = propsField;
										return <FormControlLabel control={<Checkbox checked={value} color="secondary" onChange={() => onChange(!value)} />} label="Subject Last Name" labelPlacement="end" />;
									}}
								/>
								{transcriptsOption?.transcripts
									?.filter((transcript) => transcript.grade !== IMAGE_CONTENT && transcript?.transcriptType !== CONTENT_TYPE.DATE && transcript?.transcriptType !== CONTENT_TYPE.IMAGE && transcript.required)
									.map((transcript, index) => {
										return (
											<Controller
												control={control}
												key={`${transcript.name}_${selectedCredential._id}_${index}`}
												name={`recipientAuthentication.${transcript.name}`}
												defaultValue={false}
												render={(propsField) => {
													const { value, onChange, name } = propsField;
													return <FormControlLabel control={<Checkbox checked={value} color="secondary" onChange={() => onChange(!value)} />} label={transcript.name} labelPlacement="end" />;
												}}
											/>
										);
									})}
							</Card>
							<Card hasBorder elevation={0} borderRadius="2px" style={cardStyle}>
								<Text type="body1">Expiration Date*</Text>
								<Description>The date when the Recipient will lose access to the credential.</Description>
								<Grid container alignItems="flex-start" spacing={2}>
									<Grid item xs={4}>
										<Controller
											control={control}
											name={`expirationDate`}
											rules={{
												required: "Expiration Date is required",
											}}
											render={(propsField) => {
												const { value, onChange, name } = propsField;
												return (
													<TextField
														label={"Date"}
														variant="static"
														name={name}
														size="small"
														value={value}
														id={`${name}`}
														type={"date"}
														min={format(new Date(), "yyyy-MM-dd")}
														data-testid={`${name}_key`}
														placeholder={"Name*"}
														onChange={onChange}
														style={{ flexGrow: 1 }}
														error={hasProperty(errors, name)}
														helperText={getObjectValue(errors, `${name}.message`)}
													/>
												);
											}}
										/>
									</Grid>
									<Grid item xs={4}>
										<Controller
											control={control}
											name={`expirationTime`}
											rules={{
												required: "Expiration time is required",
											}}
											render={(propsField) => {
												const { value, onChange, name } = propsField;
												return (
													<TextField
														label={"Time"}
														variant="static"
														name={name}
														size="small"
														value={value}
														id={`${name}`}
														type={"time"}
														data-testid={`${name}_key`}
														placeholder={"Name*"}
														onChange={onChange}
														style={{ flexGrow: 1 }}
														error={hasProperty(errors, name)}
														helperText={getObjectValue(errors, `${name}.message`)}
													/>
												);
											}}
										/>
									</Grid>
									<Grid item xs={4}>
										<Controller
											control={control}
											name={`expirationTimeZone`}
											rules={{
												required: "Expiration time zone is required",
											}}
											defaultValue={Intl.DateTimeFormat().resolvedOptions().timeZone}
											render={(propsField) => {
												const { value, onChange, name } = propsField;
												return (
													<ComboBox
														size="small"
														style={{ maxWidth: "250px" }}
														label={"Timezone"}
														menuPlacement="auto"
														placeholder={"Select time zone"}
														options={TIMEZONE_LIST_OPTIONS}
														getOptionValue={(item) => item["value"]}
														getOptionLabel={(item) => item["label"]}
														value={{ label: value, value }}
														onChange={(e) => onChange(e["value"])}
														required={true}
														error={hasProperty(errors, "expirationTimeZone")}
														helperText={getObjectValue(errors, `expirationTimeZone.message`)}
													/>
												);
											}}
										/>
									</Grid>
								</Grid>
							</Card>
						</Card>
						<div style={{ display: "grid", gridTemplateColumns: "0.5fr 1fr 0.5fr", marginBottom: "1rem" }}>
							<div />
							<Button type={"submit"} color="warning" disabled={!formState.isDirty}>
								Authorize
							</Button>
							<div />
						</div>
					</form>
				</Box>
			</Container>
			{formData && (
				<AuthorizationViewDialog
					type="review"
					data={formData}
					onClickAuthorize={onClickAuthorize}
					onClose={() => setFormData(null)}
					isLoading={isLoading}
					expirationTime={formData.expirationTimeZone && formData.expirationDate && formData.expirationTime ? zonedTimeToUtc(new Date(`${formData.expirationDate}:${formData.expirationTime}`), formData.expirationTimeZone) : null}
				/>
			)}
		</FormProvider>
	);
};

export default AuthorizeDefinitionForm;
