import PropTypes from "prop-types";
import { capitalizeText, hasProperty, getObjectValue } from "@cti-workspace/helpers";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
function AdvFormControl({ children, errors, name }) {
	return (
		<FormControl variant="outlined" fullWidth error={hasProperty(errors, name)}>
			<InputLabel variant="outlined">{capitalizeText(name)}</InputLabel>
			{children}
			<FormHelperText variant="outlined" error={hasProperty(errors, name)}>
				{getObjectValue(errors, `${name}.message`)}
			</FormHelperText>
		</FormControl>
	);
}

AdvFormControl.propTypes = {};

export default AdvFormControl;
