import { useState } from "react";
import { Button, InputAdornment, TextField, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import GetAppIcon from "@material-ui/icons/GetApp";
import ExploreAnalyticsTable from "./exploreAnalyticsTable";
import { downloadToExcel } from "./analyticsUtils";
import useNProgressHook from "../../hooks/useNProgressHook";
import { useSnackbar } from "notistack";
import CapabilityBoundary from "../../components/CapabilityBoundary/CapabilityBoundary";
import CAPABILITIES from "../../capabilities";
import { Box } from '@cti-workspace/ui';
import { Button as Btn } from '@cti-workspace/ui';

const ExploreAnalytics = ({ id, onClickGoBack, startDate, endDate }) => {
	const { progressStart, progressDone } = useNProgressHook();
	const { enqueueSnackbar } = useSnackbar();

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [downloading, setDownloading] = useState(false);
	const [sortModel, setSortModel] = useState([]);
  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");

	const handleOnClickDownload = async () => {
		setDownloading(true);
		progressStart();
		try {
			await downloadToExcel(page, pageSize, startDate, endDate, sortModel);
		} catch (e) {
			enqueueSnackbar(`Failed to download file. Please try again.`, { variant: "error" });
		} finally {
			progressDone();
			setDownloading(false);
		}
	};

  const onSearch = () => {
    setSearchText(search)
  }

  return (
		<>
			<Box display="flex">
				<div className="Explore-Analytics-Header">
					<Typography variant="h6">Explore</Typography>
				</div>
				<div className="Explore-Analytics-Buttons">
          <TextField className="explore-analytics-search-input" placeholder="Search" size="small" value={search}
                     InputProps={{
                       endAdornment: <InputAdornment position="">
                         <Btn buttonType="icon" icon="search" onClick={onSearch} buttonStyle={{marginBottom: '5px'}} />
                       </InputAdornment>,
                     }}
                     onChange={(e) => setSearch(e.target.value)} />
					<CapabilityBoundary
						render={(disabled) => (
							<Button startIcon={<GetAppIcon />} onClick={handleOnClickDownload} disabled={downloading || disabled}>
								Download to Excel
							</Button>
						)}
						capability={CAPABILITIES.DOWNLOAD_ADVANCE_ANALYTICS}
					/>
					<Button startIcon={<ArrowBackIcon />} onClick={onClickGoBack} color="secondary">
						Back
					</Button>
				</div>
			</Box>
			<Box elevation={1} marginTop={2} style={{ height: "100%" }}>
				<ExploreAnalyticsTable startDate={startDate} endDate={endDate} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} setSortModel={setSortModel} searchText={searchText}/>
			</Box>
		</>
	);
};

export default ExploreAnalytics;
