import { PathwayDetailsSection, PathwayDetailItem, BtnFullWidth, CredentialCard } from "../components/pathwayComponents";
import ipwruLogo from "../../../assets/images/iPWRU_Logo_Med.png";
import { Divider, Typography } from "@material-ui/core";
import { Button } from "@cti-workspace/ui";
import { useNavigate, useParams, useLocation, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import useCredentialHook from "../../../hooks/useCredentialHook";

const ViewPathway = () => {
	const navigate = useNavigate();
	const [credentialData, setCredentialData] = useState({});

	const [childRef, steps] = useOutletContext();
	const { id, leafId } = useParams();

	const getActiveLeaf = steps.filter((item) => item.stepId === leafId)[0];
	const getActiveParent = steps.filter((item) => item.level === 0)[0];
	const activeCard = leafId ? getActiveLeaf : getActiveParent;

	const { getCredentialById } = useCredentialHook({ account: { roles: [], organizations: {} } }, null, false);

	useEffect(() => {
		setCredentialData({});
		(async () => {
			if (childRef.current && activeCard) {
				// childRef.current.setCentered(activeCard.stepId);
				childRef.current.setSelected(activeCard.stepId);
				const { credentialId } = activeCard;
				if (credentialId) {
					const credData = await getCredentialById(credentialId);
					setCredentialData(credData);
				}
			}
		})();
	}, [childRef, activeCard]);

	return (
		<PathwayDetailsSection>
			<PathwayDetailItem>
				<img src={ipwruLogo} alt="iPWRU" style={{ width: "13rem" }} />
			</PathwayDetailItem>
			<Divider />

			<>
				<PathwayDetailItem text>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div>
							<Typography variant="subtitle1" color="secondary" style={{ fontWeight: "bold", lineHeight: "1.5rem" }}>
								{activeCard?.name}
							</Typography>
							<Typography variant="body2" style={{ marginTop: "0.5rem" }} dangerouslySetInnerHTML={{ __html: activeCard?.description?.replace(/(\r\n|\n|\r)/gm, "<br />") }} />
						</div>
						<div style={{ display: "flex" }}>
							<Button buttonType="icon" icon="plus-circle" invert onClick={() => navigate("addStep")} popover="Add Step" />
							<Button buttonType="icon" icon="edit" invert onClick={() => navigate("edit")} popover="Edit Step" />
							{/* navigate to edit */}
							<Button buttonType="icon" icon="delete" invert onClick={() => navigate("delete")} popover="Delete Step" />
							{/* navigate to delete */}
						</div>
					</div>
				</PathwayDetailItem>
				<Divider />
			</>

			{/* Completion Credential view */}
			{credentialData?.name &&
				(!activeCard?.level ? (
					<>
						<PathwayDetailItem text>
							<Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
								Completion Credential
							</Typography>
							<CredentialCard>
								<img src={credentialData?.imageUrl || "https://ipwru.s3.amazonaws.com/prod/seal.png"} style={{ maxWidth: "2rem" }} alt="logo" />
								<Typography variant="body2">{credentialData?.name}</Typography>
							</CredentialCard>
						</PathwayDetailItem>
						<Divider />
					</>
				) : (
					<>
						<PathwayDetailItem text>
							<Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
								Required Credential
							</Typography>
							<CredentialCard>
								<img src={credentialData?.imageUrl || "https://ipwru.s3.amazonaws.com/prod/seal.png"} style={{ maxWidth: "2rem" }} alt="logo" />
								<Typography variant="body2"> {credentialData?.name}</Typography>
							</CredentialCard>
						</PathwayDetailItem>
						<Divider />
					</>
				))}

			<PathwayDetailItem text flexBottom>
				<div style={{ marginTop: "1rem", display: "flex", width: "100%", gridGap: "0.5rem", flexDirection: "column" }}>
					<BtnFullWidth>
						<Button color={"secondary"} onClick={() => navigate("addStep")}>
							Add Step
						</Button>
					</BtnFullWidth>
					<BtnFullWidth>
						<Button color={"secondary"} onClick={() => navigate("edit")}>
							Edit
						</Button>
						{/* navigate to edit */}
					</BtnFullWidth>
				</div>
			</PathwayDetailItem>
		</PathwayDetailsSection>
	);
};

export default ViewPathway;
