import uuid from "uuid";
import { getObjectValue, IMAGE_CONTENT } from "@cti-workspace/helpers";

function buildCertificateToIssue(recipientName, recipientFirstName, recipientLastName, recipientEmail, credentialDefinition, transcriptGrades = {}, sections = [], expirationDateDuration, bulkType = "issuemany") {
	let cert = JSON.parse(credentialDefinition.definition);
	cert.name = credentialDefinition.name;
	if (bulkType !== "savemany") {
		cert.recipient = {
			name: recipientName.trim(),
			firstName: recipientFirstName.trim(),
			lastName: recipientLastName.trim(),
			email: recipientEmail.trim(),
		};
	}
	cert.id = uuid();
	cert.issuedOn = new Date().toISOString();
	cert.issuers[0] = {
		name: credentialDefinition.issuerEmail,
	};

	// Set the transcript / data field values
	let key,
		keys = Object.keys(transcriptGrades);
	let n = keys.length;
	while (n--) {
		const { [n]: key } = keys;
		const { [key]: transcriptGradesValue } = transcriptGrades;
		transcriptGrades = {
			...transcriptGrades,
			[key.toLowerCase().trim()]: transcriptGradesValue,
		};
	}

	const transcript = [];
	const transcriptLength = cert.transcript.length;
	cert.transcript.map(({ name, required = false }, i) => {
		if (!(name.toLowerCase().trim() in transcriptGrades) && required) {
			throw new Error(`Value is missing for field: ${name}`);
		}
		const { [i]: currentTranscript } = cert.transcript;
		const { [name.toLowerCase().trim()]: transcriptGradesValue } = transcriptGrades;
		delete currentTranscript["transcriptType"];
		cert.transcript = [
			...cert.transcript.slice(0, i),
			{
				...currentTranscript,
				grade: transcriptGradesValue?.trim(),
				required: undefined,
			},
			...cert.transcript.slice(i + 1, transcriptLength),
		];
		const certTranscript = getObjectValue(cert.transcript, i);
		if (certTranscript.grade && certTranscript.grade !== IMAGE_CONTENT) {
			transcript.push(certTranscript);
		}
	});

	let sectionsData = sections.map((section, i) => {
		return {
			...section,
			claims: section.claims.map((claim, i) => ({
				...claim,
				value: claim?.value?.trim(),
			})),
		};
	});

	cert = {
		...cert,
		transcript,
		additionalData: {
			...cert.additionalData,
			sections: sectionsData,
		},
		...expirationDateDuration,
	};

	return cert;
}

export default buildCertificateToIssue;
