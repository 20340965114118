import { getErrMsg, getObjectValue, hasProperty, usePathwaysHook } from "@cti-workspace/helpers";
import { AsyncComboBox, Box, Button, Card, ComboBox, Container, TextField } from "@cti-workspace/ui";
import { Grid, Typography } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import differenceBy from "lodash/differenceBy";
import { components } from "react-select";
import useCredentialHook from "../../hooks/useCredentialHook";
import { forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { userStore } from "../../store/userStore";

export default function DefinePathway() {
	const { control, handleSubmit, errors } = useForm();
	const { definePathway } = usePathwaysHook();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const onSubmit = async (e) => {
		try {
			const { name, description } = e;
			const res = await definePathway({ ...e, name: name.trim(), description: description ? description.trim() : "" });
			enqueueSnackbar(res?.message || "Successfully added pathway", { variant: "success" });
			navigate(-1);
		} catch (error) {
			enqueueSnackbar(getErrMsg(error), { variant: "error" });
		}
	};

	return (
		<Container maxWidth="md">
			<div style={{ height: "64px" }} />
			<Card elevation={0} style={{ padding: "1rem", borderRadius: "10px" }}>
				<Typography variant="h6">New Pathway Definition</Typography>
			</Card>
			<Box style={{ marginTop: "1rem" }}>
				<DefinePathwayForm handleSubmit={handleSubmit(onSubmit)} control={control} errors={errors} />
			</Box>
		</Container>
	);
}

export const DefinePathwayForm = ({ handleSubmit = () => {}, control = null, errors = {} }) => {
	return (
		<div>
			<Card elevation={0} style={{ padding: "2rem", borderRadius: "10px" }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<ControllerInput label="Name" placeholder="Please enter the pathway name" name="name" control={control} errors={errors} />
					</Grid>
					<Grid item xs={12}>
						<ControllerInput label="Description" placeholder="Please enter the pathway description" name="description" control={control} errors={errors} required={false} multiline rows={4} />
					</Grid>
				</Grid>
			</Card>
			<Card elevation={0} style={{ padding: "2rem", marginTop: "1rem", borderRadius: "10px" }}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12}>
						<Controller
							control={control}
							name="credentialId"
							rules={{
								required: "Credential is required",
							}}
							render={(render) => <CredentialAsyncComboBox label="Credential" required placeholder="Select your credential" {...render} error={hasProperty(errors, "credentialId")} helperText={getObjectValue(errors, `${"credentialId"}.message`)} />}
						/>
					</Grid>
				</Grid>
			</Card>
			<Grid container spacing={2}>
				<Grid item xs={12} container style={{ marginTop: "1rem" }}>
					<Grid xs={12} sm={3} />
					<Grid xs={12} sm={6}>
						<Button color="secondary" onClick={handleSubmit} disabled={Object.keys(errors).length}>
							Submit
						</Button>
					</Grid>
					<Grid xs={12} sm={3} />
				</Grid>
			</Grid>
		</div>
	);
};

export function ControllerInput({ control, errors = {}, name = "", label = "", placeholder = "", required = true, multiline = false, rows = 4 }) {
	return (
		<Controller
			control={control}
			name={name}
			rules={{
				...(required ? { required: `${label} is required` } : {}),
				validate: (value) => {
					if (value && value.trim()) {
						return true;
					} else {
						if (required) {
							return `${label} is required`;
						}
					}
				},
				setValueAs: (value) => {
					return value?.trim();
				},
			}}
			render={(field) => <TextField variant="static" label={label} required={required} multiline={multiline} rows={rows} placeholder={placeholder} {...field} error={hasProperty(errors, name)} helperText={getObjectValue(errors, `${name}.message`)} />}
		/>
	);
}

const Option = (props) => {
	return (
		<div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingLeft: "1rem", paddingRight: "1rem" }}>
			<div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "1.5rem", width: "1.5rem" }}>
				<img src={props?.data?.imageUrl} style={{ maxHeight: "100%", maxWidth: "100%" }} />
			</div>
			<components.Option {...props} />
		</div>
	);
};

export const CredentialAsyncComboBox = forwardRef(({ onChange, defaultValue, value, initialOptions = [], label = "", placeholder = "", required = false, error = false, helperText = "", selectFullDocument = false }, ref) => {
	const roles = userStore((state) => state.roles);
	const organizations = userStore((state) => state.organizations);
	const { getCredsPerPage } = useCredentialHook({ account: { roles, organizations } });
	const [resetCache, setResetCache] = useState(false);

	async function loadOptions(search, loadedOptions, { page, onDelete }) {
		if (onDelete) {
			setResetCache((prev) => !prev);
			return {
				options: loadedOptions,
				hasMore: true,
				additional: {
					page: page + 1,
					onDelete: false,
				},
			};
		} else {
			const response = await getCredsPerPage(10, page, search, false);
			return {
				options: differenceBy(response.items, loadedOptions, "_id"),
				hasMore: response.totalItems >= response.pageNumber * response.pageSize,
				additional: {
					page: page + 1,
				},
			};
		}
	}

	return (
		<AsyncComboBox
			selectRef={ref}
			components={{ Option }}
			options={initialOptions}
			defaultValue={defaultValue}
			getOptionValue={(item) => item["_id"]}
			getOptionLabel={(item) => item.name}
			loadOptions={loadOptions}
			onChange={(e) => onChange(selectFullDocument ? e : e["_id"])}
			cacheUniqs={[1, !!resetCache]}
			additional={{
				page: 1,
			}}
			label={
				label ? (
					<span>
						{label}{" "}
						{required && (
							<span
								style={{
									fontWeight: "normal",
									fontSize: "0.8em",
									verticalAlign: "top",
								}}>
								*
							</span>
						)}
					</span>
				) : (
					""
				)
			}
			placeholder={placeholder}
			helperText={helperText}
			error={error}
		/>
	);
});
