import QRCode from "qrcode.react";
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from "@cti-workspace/ui";

export default function QRDialog({ qrCodevalue, open, handleClose, primaryColor }) {
	return (
		<div>
			<Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="sm">
				<DialogTitle id="alert-dialog-title">{"Scan to View Credential"}</DialogTitle>
				<DialogContent style={{ textAlign: "center" }}>
					<QRCode value={qrCodevalue} />
				</DialogContent>
				<DialogActions>
					{primaryColor ? (
						<Button buttonStyle={{ backgroundColor: primaryColor }} onClick={handleClose}>
							Close
						</Button>
					) : (
						<Button color="warning" onClick={handleClose}>
							Close
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
}
