import React, { useEffect, useState } from "react";
import HeaderWithLine from "../HeaderWithLine";
import AdvFormControl from "../AdvFormControl";
import { Controller, useForm } from "react-hook-form";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import useNProgressHook from "../../hooks/useNProgressHook";
import { useSnackbar } from "notistack";
import { Box, Button } from "@cti-workspace/ui";

import AdminFormFieldSeparator from "../CustomCredentials/AdminFormFieldSeparator";
import AdvTextField from "../AdvTextField";
import OrgDropDown from "../OrgDropDown/OrgDropDown";
import { FormHelperText } from "@material-ui/core";
import { getErrMsg, getChainIds, addOrganizationNftContract, getObjectValue, hasProperty } from "@cti-workspace/helpers";

const NFTContract = () => {
	const { handleSubmit, control, errors, reset, register } = useForm();
	const { progressStart, progressDone } = useNProgressHook();
	const { enqueueSnackbar } = useSnackbar();
	const [chainList, setChainList] = useState([]);

	const addNFTContract = async (form) => {
		progressStart();
		if (form.organizations) {
			try {
				const data = {
					orgId: form.organizations,
					chainId: form.chainId,
					tokenSymbol: form.tokenSymbol?.trim(),
				};
				const res = await addOrganizationNftContract(data);
				console.log(res);
				enqueueSnackbar(`NFT Contract added. ${res?.data?.transactionUrl}`, { variant: "success" });
				reset({
					chain: "",
					tokenSymbol: "",
				});
				reset();
				progressDone();
			} catch (error) {
				progressDone();
				enqueueSnackbar("Failed to add NFT Contract", {
					variant: "error",
				});
			}
		}
	};

	useEffect(() => {
		async function fetchData() {
			progressStart();
			try {
				const { supportedBloackchains } = await getChainIds();
				setChainList(supportedBloackchains);
			} catch (e) {
				enqueueSnackbar(getErrMsg(null, "Failed to Retrieve Organization/Blockchain Data"), {
					variant: "error",
				});
			} finally {
				progressDone();
			}
		}
		fetchData();
	}, []);

	return (
		<Box>
			<HeaderWithLine title={"Add Organization NFT Contract"} variant={"h6"} help={"admin.addNFTContract"} />
			<Box mx="auto" p={1}>
				<form onSubmit={handleSubmit(addNFTContract)}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Controller
								name="organizations"
								control={control}
								rules={{ required: "Organization is required" }}
								render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => <OrgDropDown ref={ref} onChange={onChange} required />}
							/>
							<FormHelperText variant="outlined" error={hasProperty(errors, "organizations")}>
								{getObjectValue(errors, `organizations.message`)}
							</FormHelperText>
						</Grid>
						<Grid item xs={12}>
							<Controller
								render={(field) => (
									<AdvFormControl errors={errors} name={"chainId"}>
										<Select id="chain-select" fullWidth label={"Chain ID"} {...field}>
											{chainList.map((item) => (
												<MenuItem key={item.chainId} value={item.chainId}>
													{item.name} {item.chainId}
												</MenuItem>
											))}
											<MenuItem value={undefined}>
												<i>None</i>
											</MenuItem>
										</Select>
									</AdvFormControl>
								)}
								name="chainId"
								rules={{ required: { value: true, message: "Chain ID is required" } }}
								control={control}
								defaultValue=""
							/>
						</Grid>
						<Grid item xs={12}>
							<AdvTextField
								name="tokenSymbol"
								errors={errors}
								inputRef={register({
									required: "Token Symbol is required",
								})}
								placeholder={"Token Symbol"}
								label={"Token Symbol"}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button variant="contained" color="primary" id="addNftContract" type="submit" fullWidth={true}>
								Add Contract
							</Button>
						</Grid>
					</Grid>
				</form>
			</Box>
		</Box>
	);
};

export default NFTContract;
