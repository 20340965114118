import { useEffect, useState } from "react";

export const useDarkMode = () => {
	const [theme, setTheme] = useState("light");
	const [componentMounted, setComponentMounted] = useState(false);
	const setMode = (mode) => {
		// try {
		// 	window.localStorage.setItem("theme", mode);
		// 	setTheme(mode);
		// } catch (err) {
		// 	console.error("Could not set mode.");
		// 	console.error(err);
		// }
		return null
	};

	const toggleTheme = () => {
		if (theme === "light") {
			setMode("dark");
		} else {
			setMode("light");
		}
	};

	useEffect(() => {
		try {
			const localTheme = window.localStorage.getItem("theme");
			if (localTheme === "dark") setTheme("dark");
			if (localTheme === "light") setTheme("light");
		} catch (err) {
			console.error("Could not retrieve theme.");
			console.error(err);
		}
		setComponentMounted(true);
	}, []);

	return [theme, toggleTheme, componentMounted];
};
