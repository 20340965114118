import type { Theme as ThemeType } from "theme-ui";

const Theme: ThemeType = {
	space: [0, 8, 16, 32, 64, 128, 256, 512],
	fonts: {
		body: "Roboto, system-ui, sans-serif",
		heading: "Roboto, system-ui, sans-serif",
		monospace: '"Roboto Mono", monospace',
	},
	fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
	fontWeights: {
		body: 400,
		heading: 600,
		bold: 600,
	},
	lineHeights: {
		body: 1.5,
		heading: 1.125,
	},
	config: {
		initialColorModeName: "light",
		printColorModeName: "light",
	},
	colors: {
		text: "#202124",
		textMuted: "#bbbbbb",
		background: "#fff",
		backgroundSecondary: "#fafafa",
		primary: "#232E3A",
		secondary: "#9c27b0",
		muted: "#f1f3f4",
		danger: "rgba(0,0,0,0.82)",
		warning: "#9c49ed",
		error: "#D32F2F",
		textSecondary: "rgba(0, 0, 0, 0.54)",
		grey: "#afabaf",
		purple: "#9c49ed",
		gray: "#e0e0e0",
		gray1: "#C0C0C0",
		gray2: "#f5f5f5",
	},
	styles: {
		root: {
			fontFamily: "body",
			lineHeight: "body",
			fontWeight: "body",
		},
		h1: {
			color: "text",
			fontFamily: "heading",
			lineHeight: "heading",
			fontWeight: "heading",
			fontSize: 5,
		},
		h2: {
			color: "text",
			fontFamily: "heading",
			lineHeight: "heading",
			fontWeight: "heading",
			fontSize: 4,
		},
		h3: {
			color: "text",
			fontFamily: "heading",
			lineHeight: "heading",
			fontWeight: "heading",
			fontSize: 3,
		},
		h4: {
			color: "text",
			fontFamily: "heading",
			lineHeight: "heading",
			fontWeight: "heading",
			fontSize: 2,
		},
		h5: {
			color: "text",
			fontFamily: "heading",
			lineHeight: "heading",
			fontWeight: "heading",
			fontSize: 1,
		},
		h6: {
			color: "text",
			fontFamily: "heading",
			lineHeight: "heading",
			fontWeight: "heading",
			fontSize: 0,
		},
		p: {
			color: "text",
			fontFamily: "body",
			fontWeight: "body",
			lineHeight: "body",
		},
		a: {
			color: "primary",
		},
		pre: {
			fontFamily: "monospace",
			overflowX: "auto",
			code: {
				color: "inherit",
			},
		},
		code: {
			fontFamily: "monospace",
			fontSize: "inherit",
		},
		table: {
			width: "100%",
			borderCollapse: "separate",
			borderSpacing: 0,
		},
		th: {
			textAlign: "left",
			borderBottomStyle: "solid",
		},
		td: {
			textAlign: "left",
			borderBottomStyle: "solid",
		},
		img: {
			maxWidth: "100%",
		},
		spinner: {
			color: "#9C49ED",
			size: 70,
		},
	},
};

export default Theme;
