import styled from "styled-components";
import { getObjectValue } from "@cti-workspace/helpers";
import Theme from "../theme/theme";

const elevationBoxShadow = {
	0: "0px 0px 0px #ccc",
	1: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
	2: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
	3: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
	4: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
	5: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)",
	6: "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
};

const CardContainer = styled.div`
	background-color: #fff;
	box-shadow: ${(props) => getObjectValue(elevationBoxShadow, props.elevation)};
	border-radius: ${({ borderRadius }) => borderRadius};
	padding: 10px;
	margin-bottom: 20px;
	${({ hasBorder }) => hasBorder ? `border: 0.3px solid ${Theme.colors.gray1};` : ""}
`;

export const Card = ({ children, className = "", elevation = 0, hasBorder = false, borderRadius = "0.3rem", ...rest }) => {
	return (
		<CardContainer elevation={elevation} className={className} hasBorder={hasBorder} borderRadius={borderRadius} {...rest}>
			{children}
		</CardContainer>
	);
};

export default Card;
