import { useContext, useState } from "react";
import { fabric } from "fabric-pure-browser";
import { FabricContext } from "../../context/FabricContext";
import { useWatch } from "react-hook-form";
import snakeCase from "lodash/snakeCase";
import { Button } from "@cti-workspace/ui";
import { CaretDown } from "@phosphor-icons/react";
import { getObjectValue } from "@cti-workspace/helpers";
import ImageVariable from "../../../../../../assets/images/ImageVariableRectangle.jpg";

const FabricAddVariable = ({ control, id, enableMultiPageCertificate, setFabricContentVariables = () => {} }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const { canvas } = useContext(FabricContext);
	const addTextBox = (val, isImageVariable) => {
		if (isImageVariable) {
			fabric.Image.fromURL(ImageVariable, function (img) {
				const activeCanvas = enableMultiPageCertificate ? getObjectValue(canvas, id) : canvas;
				img.scale(1.0);
				const textBox = new fabric.Textbox(`${val}`, {
					width: 100,
					top: 0,
					left: 0,
					fontSize: 16,
					fontWeight: "normal",
					fontStyle: "normal",
					textAlign: "left",
					fontFamily: "ABeeZee",
					textDecoration: "normal",
					fill: "#000000",
					editable: false,
					hasControls: true,
				});
				let group = new fabric.Group([img, textBox], { width: 250, height: 250, id: "testImage" });
				group.toObject = (function (toObject) {
					return function () {
						return fabric.util.object.extend(toObject.call(this), {
							scaleX: this.scaleX,
							scaleY: this.scaleY,
						});
					};
				})(group.toObject);
				activeCanvas.add(group);
			});
		} else {
			const textBox = new fabric.Textbox(`${val}`, {
				width: 200,
				top: 10,
				left: 10,
				fontSize: 24,
				fontWeight: "normal",
				fontStyle: "normal",
				textAlign: "left",
				fontFamily: "ABeeZee",
				textDecoration: "normal",
				fill: "#000000",
				editable: false,
				hasControls: true,
				splitByGrapheme: val === "{{Public_Url}}",
			});
			const activeCanvas = enableMultiPageCertificate ? getObjectValue(canvas, id) : canvas;
			activeCanvas.add(textBox);
		}

		setAnchorEl(null);
	};
	const transcripts = useWatch({
		control,
		name: "transcripts",
	});

	return (
		<Button
			buttonType="dropdown"
			splitButtonIcon={<CaretDown weight="fill" />}
			buttonStyle={{ padding: "0px", display: "flex", alignItems: "center" }}
			popover="custom variables"
			style={{ borderRight: "0.5px solid black", padding: "5px 10px", paddingRight: "0px" }}
			splitButtonOptions={[
				{ label: "Credential Name", onClick: () => addTextBox("{{Credential_Name}}") },
				{ label: "Recipient Name", onClick: () => addTextBox("{{Recipient_Name}}") },
				{ label: "Recipient First Name", onClick: () => addTextBox("{{Recipient_First_Name}}") },
				{ label: "Recipient Last Name", onClick: () => addTextBox("{{Recipient_Last_Name}}") },
				{ label: "Issued Date", onClick: () => addTextBox("{{Issued_date}}") },
				{ label: "Public URL", onClick: () => addTextBox("{{Public_Url}}") },
				...(transcripts && transcripts.length
					? transcripts.map((content) => ({
							label: content.key,
							onClick: () => {
								addTextBox(`{{${snakeCase(content.key)}${content.key?.split(" ").length > 1 ? "" : "_"}}}`, content?.value === "IMAGE_CONTENT");
								setFabricContentVariables((prevValue) => ({
									...prevValue,
									content: {
										...prevValue.content,
										[content.key?.toLowerCase()]: true,
									},
								}));
							},
					  }))
					: []),
			]}
			splitButtonInvert
			noBorder
			color="white">
			Variables
		</Button>
	);
};

export default FabricAddVariable;
