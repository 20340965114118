import styled from "styled-components";
import { CopySimple, Hand, BellRinging, CircleWavyCheck, TreeStructure } from "@phosphor-icons/react";
import Theme from "../theme/theme";
import Button from "../button/button";

const getIcon = (icon) => {
	switch (icon) {
		case "copy":
			return <CopySimple color={Theme.colors.purple} />;
		case "bell":
			return <BellRinging color={Theme.colors.purple} />;
		case "check":
			return <CircleWavyCheck color={Theme.colors.purple} />;
		case "pathway":
			return <TreeStructure color={Theme.colors.purple} />;
		case "no-icon":
			return null;
		default:
			return <Hand color={Theme.colors.purple} />;
	}
};

export const NewCardHeaderAction = ({
	titleTooltip = "",
	title = "copy id",
	onClick = () => {
		console.log("onClickTitle");
	},
	icon = "copy",
	primary = false,
}) => {
	return (
		<Button
			leftIcon={getIcon(icon)}
			{...(titleTooltip ? { popover: titleTooltip } : {})}
			onClick={onClick}
			invert
			buttonStyle={{ fontSize: "0.63rem", border: `1px solid ${Theme.colors.grey}`, padding: "0.2rem", color: primary ? Theme.colors.purple : "black" }}>
			{title}
		</Button>
	);
};

export default NewCardHeaderAction;
