import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { FormattedMessage } from "react-intl";
import { Box } from '@cti-workspace/ui';

export default function HeaderWithLIne({ title, variant, help, idName, className = "" }) {
	return (
		<div style={{ display: "flex", alignItems: "center" }}>
			<Typography variant={variant} padding={2} className={className}>
				<span id={idName}>{title}</span>
			</Typography>
			<Divider style={{ flex: 1, marginLeft: 5 }} />
			{help && (
				<PopupState variant="popover" popupId="demo-popup-popover">
					{(popupState) => (
						<>
							<IconButton {...bindTrigger(popupState)}>
								<Tooltip title="Help" placement="left" arrow>
									<HelpOutlineIcon />
								</Tooltip>
							</IconButton>
							<Popover
								{...bindPopover(popupState)}
								anchorOrigin={{
									vertical: "center",
									horizontal: "left",
								}}
								transformOrigin={{
									vertical: "center",
									horizontal: "right",
								}}
							>
								<Box p={2}>
									<FormattedMessage
										id={`${help}.headerWithLine`}
										values={{
											p: (...chunks) => (
												<Typography component={"span"} display={"block"}>
													{chunks}
												</Typography>
											),
											cta: (...chunks) => (
												<Typography style={{ fontWeight: 600 }} component={"span"} display={"inline"} color={"secondary"}>
													{chunks}
												</Typography>
											),
										}}
									>
										{(txt) => (
											<Typography component={"span"} variant={"body1"}>
												{txt}
											</Typography>
										)}
									</FormattedMessage>
								</Box>
							</Popover>
						</>
					)}
				</PopupState>
			)}
		</div>
	);
}
