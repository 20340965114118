import { Dialog, DialogActions, DialogContent, NewCard, NewCardFooter, NewCardHeaderAction, Button, DialogTitle, Spinner, Icon } from "@cti-workspace/ui";
import { useState } from "react";
import { usePathwaysInvitation } from "../../hooks/usePathwaysInvitationHook";
import styled from "styled-components";
import { updateTabUrl } from "./walletUtils";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const AlignCenterContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	height: 70vh;
`;

const InfoMessage = styled.div`
	width: 100%;
	font-weight: 600;
	text-align: center;
	font-size: 1.25rem;
	color: #737373;
	min-height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const buttonStyle = { fontsize: "1rem", padding: "0.5rem 3.5rem", borderRadius: "5px" };

const PathwayInvitations = ({ email, data, isLoading, isFetching, error }) => {
	const [openModal, setOpenModal] = useState(null);
	const queryClient = useQueryClient();

	const navigate = useNavigate();

	const { rejectInvitation, acceptPathway } = usePathwaysInvitation();

	const handleCloseModal = () => {
		if (isFetching) return;
		setOpenModal(null);
	};

	const onRejectClick = async () => {
		await rejectInvitation({ pathwayId: openModal.selectedPathWay.id, userPathwayId: openModal.selectedPathWay.userPathwayId });
		handleCloseModal(null);
		queryClient.invalidateQueries({ queryKey: [email] });
	};

	const onAcceptClick = async (pathwayId, userPathwayId) => {
		await acceptPathway({ userPathwayId, pathwayId });
		queryClient.invalidateQueries({ queryKey: [email] });
	};

	if (isLoading)
		return (
			<AlignCenterContainer>
				<Spinner strokeWidth={2} />
			</AlignCenterContainer>
		);

	return (
		<>
			{data?.invitedPathways.length ? (
				data.invitedPathways.map((val) => (
					<NewCard
						key={val.id}
						headerActionContent={<NewCardHeaderAction icon="pathway" primary title="pathway" titleTooltip="" />}
						title={val.name}
						imageUrl={val.icon}
						bodyContent={<div />}
						showContentDivider={false}
						footerContent={
							<NewCardFooter
								showRight
								rightButtonPrimary
								leftButtonTitle="reject"
								centerButtonTitle="view"
								rightButtonTitle="accept"
								onRightButtonClick={() => onAcceptClick(val.id, val.userPathwayId)}
								onCenterButtonClick={() => navigate(`/wallet/pathway/${val.id}/${val.userPathwayId}`)}
								onLeftButtonClick={() =>
									setOpenModal({
										type: "reject",
										selectedPathWay: { ...val },
									})
								}
							/>
						}
					/>
				))
			) : (
				<InfoMessage>
					<Icon name={"Info"} size={"2rem"} />
					There are no new Invitations.
				</InfoMessage>
			)}

			<Dialog onClose={handleCloseModal} open={Boolean(openModal)} maxWidth="xs" borderRadius={"10px"}>
				<DialogTitle noBorder style={{ padding: "0.5rem 1rem" }}>
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<Button buttonType="icon" icon="cancel" color="danger" invert iconSize="1rem" noBorder buttonTransparent buttonStyle={{ alignSelf: "right" }} onClick={() => setOpenModal(null)} />
					</div>
				</DialogTitle>
				<DialogContent style={{ padding: "0.5rem 1rem" }}>
					<div style={{ textAlign: "center", fontSize: "1.15rem" }}>
						Are you sure you want to reject the invitation to join the <strong>{openModal?.selectedPathWay?.name}</strong> pathway?
					</div>
				</DialogContent>
				<DialogActions noBorder style={{ justifyContent: "space-between", padding: "1rem" }}>
					<Button color="warning" disabled={isFetching} invert buttonStyle={buttonStyle} onClick={handleCloseModal}>
						Cancel
					</Button>
					<Button disabled={isFetching} color="warning" buttonStyle={buttonStyle} onClick={onRejectClick}>
						<div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
							Confirm{" "}
							{isFetching ? (
								<Icon name={"Spinner"} size={"1rem"}>
									<animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 0 0" to="360 0 0" repeatCount="indefinite" />
								</Icon>
							) : null}
						</div>
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default PathwayInvitations;
