import { makeStyles } from "@material-ui/core/styles";
import { Button, Container, Pagination, Tabs as NFTTabs } from "@cti-workspace/ui";
import { useEffect, useState } from "react";
import SearchNft from "./SearchNft";
import MintNft from "./MintNft";

import "./nftCatalog.css";
import NftList from "./NftList";
import { useNavigate } from "react-router-dom";
import { getErrMsg, useNftHook } from "@cti-workspace/helpers";
import NftCard from "./NftCard";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		marginTop: theme.spacing(2),
	},
	tabSelected: {
		background: "#FFF2E5",
	},
	categoryBar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "1rem 0",
		[theme.breakpoints.between("xs", "sm")]: {
			flexDirection: "column",
		},
	},
	leftBox: {
		flex: "auto",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
	RightBox: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		[theme.breakpoints.between("xs", "sm")]: {
			padding: "1rem 0",
		},
	},
	flatButton: {
		background: "#F6DABE",
		zIndex: 0,
		justifyContent: "space-between",
	},
	iconButton: {
		padding: "0 0.5rem",
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		overflow: "auto",
	},
}));

const NFTCatalog = () => {
	const classes = useStyles();

	const [nfts, setNfts] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [paginationData, setPaginationData] = useState({
		pageSize: 0,
		pageNumber: 0,
		totalItems: 0,
	});

	const { getMintedNfts, retryNFT } = useNftHook();
	const { enqueueSnackbar } = useSnackbar();

	const navigate = useNavigate();

	useEffect(() => {
		getMintedNfts()
			.then((res) => {
				setNfts(res?.mintedNFTs || []);
				setPaginationData({ ...res });
			})
			.catch(console.error);
	}, []);

	const navigateToMintNft = () => {
		navigate("/nft/mint");
	};

	const onClickNFTRetry = async (nftId) => {
		try {
			const res = await retryNFT(nftId);
			const index = nfts.findIndex((item) => item._id == nftId);
			const { [index]: selectedNfts } = nfts;
			const tempNfts = [
				...nfts.slice(0, index),
				{
					...selectedNfts,
					state: "Pending",
				},
				...nfts.slice(index + 1, nfts.length),
			];
			setNfts([...tempNfts]);
		} catch (error) {
			console.error(error);
			enqueueSnackbar(getErrMsg(error), { variant: "error" });
		}
	};

	const handlePageChange = async ({ page = 0, pageSize = 0 }) => {
		try {
			const res = await getMintedNfts(Number(pageSize), Number(page) + 1, searchValue);
			setNfts(res?.mintedNFTs || []);
			setPaginationData({ ...res });
		} catch (error) {
			console.error(error);
			enqueueSnackbar(getErrMsg(error), { variant: "error" });
		}
	};

	const handleSearchValue = async (event) => {
		try {
			setSearchValue(event);
			if (event != searchValue) {
				const res = await getMintedNfts(paginationData.pageSize, paginationData.pageNumber, event);
				setNfts(res?.mintedNFTs || []);
				setPaginationData({ ...res });
			}
		} catch (error) {
			console.error(error);
			enqueueSnackbar(getErrMsg(error), { variant: "error" });
		}
	};

	return (
		<Container className={classes.root}>
			<div className={classes.root}>
				<main className={classes.content + " main-content"}>
					<NFTTabs
						options={[
							{
								title: `NFTs`,
								component: (
									<div className="nft-main-container">
										<SearchNft onSearch={handleSearchValue} />
										<div style={{ marginTop: "1rem", marginBottom: "1rem", display: "flex", justifyContent: "flex-end" }}>
											<Button color="warning" invert leftIcon="plus-circle" fullWidth={false} onClick={navigateToMintNft}>
												MINT NFT
											</Button>
										</div>
										<div className="nft-list">
											{nfts
												.filter((item) => item?.name.toLowerCase().includes(searchValue.toLowerCase()))
												.map((item, index) => (
													<NftCard key={item._id} pending={item?.state === "Pending" ? true : false} data={item} index={index} onClickNFTRetry={onClickNFTRetry} />
												))}
										</div>

										{paginationData.pageNumber > 0 ? (
											<div style={{ marginTop: "1rem" }}>
												<Pagination pageSize={paginationData.pageSize} page={paginationData.pageNumber - 1} totalItems={paginationData.totalItems} onPageChange={handlePageChange} />
											</div>
										) : null}
									</div>
								),
							},
						]}
						tabContainerStyles={{ marginTop: "1rem" }}
					/>
				</main>
			</div>
		</Container>
	);
};

export default NFTCatalog;
