import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	Table,
	TextField,
	Typography
} from "@cti-workspace/ui";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAuthorization, useOnScreenHook } from "@cti-workspace/helpers";
import { format } from "date-fns";
import AuthorizationViewDialog from "./authorizationViewDialog";
import { ToolTip } from "../../issuedCredentials/issuedCredentials";

const TableHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const StatusContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.3rem;
`;

const IconButton = ({ icon, ...rest }) => <Button buttonType="icon" icon={icon} iconSize={17} invert buttonStyle={{ color: "rgba(0,0,0,0.54)", boxShadow: "none" }} {...rest} />;

export default function AuthorizationsTable() {
	const [isLastPage, setIsLastPage] = useState(false);
	const [authorizationData, setAuthorizationData] = useState([]);
	const [pgNumber, setPgNumber] = useState(1);
	const [viewAuthorizationDialog, setViewAuthorizationDialog] = useState(null);
	const [revokeId, setRevokeId] = useState("");
	const [searchText, setSearchText] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	const defaultPageSize = 25;

	const navigate = useNavigate();

	const tableRef = useRef();
	const isVisible = useOnScreenHook(tableRef);
	const { getAuthorizationsList, revokeAuthorization } = useAuthorization();

	useEffect(() => {
		if (isVisible && !isLastPage) {
			getAuthorizations(searchText);
		}
	}, [isVisible, isLastPage]);

	const searchDebounce = (func) => {
		let timer;
		return function (...args) {
			const context = this;
			if (timer) clearTimeout(timer)
			timer = setTimeout(() => {
				timer = null
				func.apply(context, args);
			}, 500);
		}
	}

	const getAuthorizations = async (search = "") => {
		setIsLoading(true);
		try {
			const { items, pageNumber, pageSize, totalItems } = await getAuthorizationsList(search, defaultPageSize, pgNumber, {
				status: "asc",
				expirationTime: "desc",
			});
			if (pgNumber == 1) {
				setAuthorizationData([...items]);
			} else {
				setAuthorizationData((prevState) => [...prevState, ...items]);
			}
			if ((defaultPageSize * pageNumber) >= totalItems) {
				setIsLastPage(true);
			} else {
				setPgNumber(pageNumber + 1);
			}
		} catch (error) {
			console.error(error);
			setIsLastPage(true);
		} finally {
			setIsLoading(false);
		}
	};

	const viewAuthorizationRow = (row) => {
		const cert = row.cert;
		const rawCertificate = cert.rawCertificate;
		const rawJson = JSON.parse(rawCertificate);
		let subjectFirstName = "";
		let subjectLastName = "";
		if (rawJson?.data?.recipient?.firstName) subjectFirstName = rawJson?.data?.recipient?.firstName?.split(":string:").pop();
		if (rawJson?.data?.recipient?.firstName) subjectLastName = rawJson?.data?.recipient?.lastName?.split(":string:").pop();
		setViewAuthorizationDialog({
			...row,
			subjectFirstName,
			subjectLastName,
			recipientFirstName: row.recipient.firstName,
			recipientLastName: row.recipient.lastName,
			recipientEmail: row.recipient.email,
			recipientAuthentication: (row.requiredUserAuthentication || []).reduce((acc, item) => ({ ...acc, [item.name]: true }), {}),
			credential: {
				...cert,
				name: cert.credentialName,
			}
		});
	}

	const handleSearch = useCallback(searchDebounce((value) => {
		setPgNumber(1);
		setSearchText(value);
		getAuthorizations(value);
	}), [])

	const handleRevokeAuthorization = async () => {
		try {
			const index = authorizationData.findIndex(item => item.id === revokeId);
			const authData = authorizationData;
			authData[index] = {
				...authData[index],
				status: "REVOKED"
			}
			setAuthorizationData([...authData]);
			await revokeAuthorization(revokeId);
		} catch (error) {
			console.error(error);
			const index = authorizationData.findIndex(item => item.id === revokeId);
			const authData = authorizationData;
			authData[index] = {
				...authData[index],
				status: "ACTIVE"
			}
			setAuthorizationData([...authData]);
		} finally {
			setRevokeId("");
		}
	}

	const authStatusUI = (status = "active") => {
		switch (status) {
			case "EXPIRED":
				return (
					<StatusContainer>
						<Icon name="expired" />
						<Typography type="body4" style={{ color: "#FC7377" }}>
							Expired
						</Typography>
					</StatusContainer>
				);
			case "REVOKED":
				return (
					<StatusContainer>
						<Icon name="warning-circle" weight="regular" color="#FC7377" />
						<Typography type="body4" style={{ color: "#FC7377" }}>
							Revoked
						</Typography>
					</StatusContainer>
				);
			default:
				return (
					<StatusContainer>
						<Icon name="active" size={15} />
						<Typography type="body4" color="warning">
							Active
						</Typography>
					</StatusContainer>
				);
		}
	};

	return (
		<div>
			<Table
				data={authorizationData}
				noDataView={isLoading ? "" : undefined}
				loading={isLoading}
				loaderCenter
				inTableLoader={false}
				config={{
					headers: [
						{
							label: "Authorization Date",
							cell: (row) => (row?.issuedAt ? format(new Date(row.issuedAt), "PPP h:mm a") : ""),
						},
						{
							label: "Authorization Recipient",
							cell: (row) => (
								<ToolTip
									containerStyle={{
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
									title={`${row?.recipient?.firstName} ${row?.recipient?.lastName}`}>
									{`${row?.recipient?.firstName} ${row?.recipient?.lastName}`}
								</ToolTip>
							),
						},
						{
							label: "Credential Name", prop: "credentialDefinitionName", cell: (row) => (
								<ToolTip
									containerStyle={{
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
									title={row?.credentialDefinitionName}>
									{row?.credentialDefinitionName}
								</ToolTip>
							)
						},
						{
							label: "Credential Subject",
							cell: (row) => (
								<ToolTip
									containerStyle={{
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
									title={row?.cert?.recipientName}>
									{row?.cert?.recipientName}
								</ToolTip>
							),
						},
						{
							label: "Expiration Date",
							cell: (row) => (row?.expirationTime ? format(new Date(row.expirationTime), "PPP h:mm a") : ""),
						},
						{ label: "Status", width: "10rem", cell: (row) => authStatusUI(row?.status || "expired") },
						{
							label: "Actions",
							width: "6rem",
							cell: (row, index) => (
								<div
									style={{ display: "flex", alignItems: "center", width: "3.5rem", justifyContent: "space-between" }}>
									<IconButton icon="eye" popover="View" onClick={() => viewAuthorizationRow(row)}
										popoverPosition={index === authorizationData.length - 1 ? "top" : "bottom"} />
									{row?.status == "ACTIVE" ?
										<IconButton icon="cancel-circle" popover="Revoke" onClick={() => setRevokeId(row.id)}
											popoverPosition={index === authorizationData.length - 1 ? "top" : "bottom"} /> :
										<div />}
								</div>
							),
						},
					],
				}}
				showSearch={false}
				header={() => (
					<TableHeader>
						<div>
							<TextField
								size="small"
								startAdornment={<Icon name="search" weight="regular" />}
								inputWrapperStyle={{
									border: "none",
									boxShadow: "0px 3px 10px rgba(163, 182, 204, 0.2)",
									width: "20rem",
								}}
								placeholder="Start typing to search"
								variant="static"
								onChange={(e) => handleSearch(e.target.value)}
							/>
						</div>
						<Button leftIcon="plus" color="warning" onClick={() => navigate(`/authorizations/authorize`)}>
							Authorize Access
						</Button>
					</TableHeader>
				)}
			/>
			<div ref={tableRef} />
			{viewAuthorizationDialog &&
				<AuthorizationViewDialog data={viewAuthorizationDialog} onClose={() => setViewAuthorizationDialog(null)} />}
			{revokeId && (
				<Dialog open onClose={() => setRevokeId("")} maxWidth="xs">
					<DialogTitle>Alert</DialogTitle>
					<DialogContent>
						<Typography>Please confirm you wish to revoke access. This action can not be undone.</Typography>
					</DialogContent>
					<DialogActions>
						<Button color="warning" onClick={() => handleRevokeAuthorization()}>
							Confirm
						</Button>
						<Button invert onClick={() => setRevokeId("")}>
							Cancel
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
}
