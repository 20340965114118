import { Checkbox, Text, ThemeProvider } from "theme-ui";
import Theme from "../theme/theme";
import styled from "styled-components";
import { Controller } from "react-hook-form";
import { getObjectValue } from "@cti-workspace/helpers";

const CheckboxContainer = styled.label`
	display: inline-flex;
	align-items: center;
	width: ${(props) => (props.fullWidth ? "100%" : "fit-content")};
	${(props) =>
		props.labelPlacement === "left"
			? `   flex-direction: row-reverse;
    `
			: ""}
	& input {
		display: none;
	}
`;

const getLabelMargin = (margin) => {
	switch (margin) {
		case "small": return { margin: "-1rem" };
		case "medium": return { margin: "-0.5rem" };
		default: return {};
	}
}

export function CheckBox({ color = "primary", defaultChecked = false, label = "", labelPlacement = "right", fullWidth = false, onChange = () => { }, value = false, control = null, name = "", disabled = false, checked = false, labelStyle = {}, labelMargin = "large" }) {
	return (
		<CheckboxContainer labelPlacement={labelPlacement} fullWidth={fullWidth}>
			{control ? (
				<ControllerCheckbBox control={control} name={name} defaultChecked={defaultChecked} color={color} disabled={disabled} />
			) : (
				<Checkbox name={name} value={value} defaultChecked={defaultChecked} disabled={disabled} onChange={onChange} sx={{ color: getObjectValue(Theme.colors, color) }} checked={checked} />
			)}
			{label && <Text sx={{ fontSize: "1rem", ...(getLabelMargin(labelMargin)), color: "rgba(0,0,0,0.87)", ...labelStyle }}>{label}</Text>}
		</CheckboxContainer>
	);
}

function ControllerCheckbBox({ control = null, name = "", defaultChecked = false, color = "primary", disabled = false }) {
	return (
		<Controller
			name={name}
			defaultValue={defaultChecked}
			control={control}
			render={(field) => <Checkbox {...field} disabled={disabled} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} sx={{ color: getObjectValue(Theme.colors, color) || Theme.colors.primary }} />}
		/>
	);
}

export default CheckBox;
