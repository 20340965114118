import { useState, useContext, useEffect } from "react";
import WebFont from "webfontloader";
import { FabricContext } from "../../context/FabricContext";
import { getActiveStyle, setActiveProp, setActiveStyle } from "../libs/utils";
import { customFonts, fonts } from "../../../../../../assets/fonts/fonts.js";
import { getObjectValue } from "@cti-workspace/helpers";

const FabricTextBox = ({ id, enableMultiPageCertificate }) => {
	const { activeObject: contextActiveObject, canvas } = useContext(FabricContext);

	const activeObject = enableMultiPageCertificate ? getObjectValue(contextActiveObject, id) : contextActiveObject;

	const [options, setOptions] = useState({
		width: 200,
		top: 10,
		left: 10,
		fontSize: 24,
		fontWeight: "normal",
		fontStyle: "normal",
		textAlign: "left",
		fontFamily: "ABeeZee",
		textDecoration: "normal",
		fill: "#000000",
	});

	var finalFontList = [...fonts, ...Object.keys(customFonts)].sort();

	useEffect(() => {
		if (activeObject) {
			const activeOptions = {
				fontSize: getActiveStyle("fontSize", activeObject),
				fontWeight: getActiveStyle("fontWeight", activeObject),
				fontStyle: getActiveStyle("fontStyle", activeObject),
				textAlign: activeObject["textAlign"],
				fontFamily: getActiveStyle("fontFamily", activeObject),
				textDecoration: getActiveStyle("textDecoration", activeObject),
				fill: getActiveStyle("fill", activeObject),
			};
			setOptions({ ...options, ...activeOptions });
		}
	}, [activeObject]);

	const updateFontSize = (e) => {
		setOptions({
			...options,
			fontSize: e.target.value,
		});
		setActiveStyle("fontSize", parseInt(e.target.value, 10), activeObject);
		activeObject.canvas.fire("object:modified", { target: activeObject });
	};

	const updateFill = (e) => {
		setOptions({
			...options,
			fill: e.target.value,
		});
		setActiveProp("fill", e.target.value, activeObject);
		activeObject.canvas.fire("object:modified", { target: activeObject });
	};

	const updateTextAlign = (e) => {
		setOptions({
			...options,
			textAlign: e.currentTarget.value,
		});
		setActiveStyle("textAlign", e.currentTarget.value.toLowerCase(), activeObject);
		activeObject.canvas.fire("object:modified", { target: activeObject });
	};

	const updateBold = (e) => {
		const value = options.fontWeight === "bold" ? "normal" : "bold";
		setOptions({
			...options,
			fontWeight: value,
		});
		setActiveStyle("fontWeight", value, activeObject);
		activeObject.canvas.fire("object:modified", { target: activeObject });
	};

	const updateItalic = (e) => {
		const value = options.fontStyle === "italic" ? "normal" : "italic";
		setOptions({
			...options,
			fontStyle: value,
		});
		setActiveStyle("fontStyle", value, activeObject);
		activeObject.canvas.fire("object:modified", { target: activeObject });
	};

	const updateUnderline = (e) => {
		const value = options.textDecoration === "underline" ? "" : "underline";

		setOptions({
			...options,
			textDecoration: value,
		});
		setActiveStyle("textDecoration", value, activeObject);
		setActiveStyle("underline", !!value.length, activeObject);
		activeObject.canvas.fire("object:modified", { target: activeObject });
	};

	const updateFontFamily = (e) => {
		const font = e.target.value;
		const { [font]: isCustomFont } = customFonts;
		const fontPayload = isCustomFont
			? {
					custom: {
						families: [font],
					},
			  }
			: {
					google: {
						families: [font],
					},
			  };

		WebFont.load({
			...fontPayload,
			active: function () {
				setOptions({
					...options,
					fontFamily: font,
				});
				setActiveStyle("fontFamily", font, activeObject);
				activeObject.canvas.fire("object:modified", { target: activeObject });
			},
		});
	};

	return (
		<>
			<div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
				<label htmlFor="fontSize">Font Size:</label>
				<input type="number" style={{ width: "40px" }} className="btn-object-action" name="fontSize" min="10" value={options.fontSize} onChange={updateFontSize} />
			</div>
			<div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
				<label htmlFor="color">Fill:</label>
				<input type="color" name="fill" style={{ width: "50px" }} value={options.fill} onChange={updateFill} className="btn-object-action" />
			</div>
			<div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
				<label htmlFor="text-align" style={{ display: "inline-block" }}>
					Text align:
				</label>
				<select name="textAlign" className="btn-object-action" onChange={updateTextAlign} value={options.textAlign}>
					<option value="left">Left</option>
					<option value="center">Center</option>
					<option value="right">Right</option>
				</select>
			</div>
			<div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
				<label htmlFor="font-style" style={{ display: "inline-block" }}>
					Font style:
				</label>
				<select name="fontStyle" className="btn-object-action" onChange={updateFontFamily} value={options.fontFamily}>
					{finalFontList.map((value, i) => (
						<option key={i} value={value}>
							{value}
						</option>
					))}
				</select>
			</div>
			<div id="text-controls-additional">
				<button type="button" style={{ background: options.fontWeight === "bold" ? "white" : "gray" }} onMouseUp={updateBold} className="btn btn-object-action">
					<strong>B</strong>
				</button>
				<button type="button" style={{ background: options.fontStyle === "italic" ? "white" : "gray" }} onMouseUp={updateItalic} className="btn btn-object-action" id="text-cmd-italic">
					<em>I</em>
				</button>
				<button type="button" style={{ background: options.textDecoration === "underline" ? "white" : "gray" }} onMouseUp={updateUnderline} className="btn btn-object-action" id="text-cmd-underline">
					<u>U</u>
				</button>
			</div>
		</>
	);
};

export default FabricTextBox;
