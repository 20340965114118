const getTableRowTooltip = (row) => {
	switch (row.state) {
		case "revoked":
			return "Revoked by Issuer.";
		case "deleted":
			return "Deleted by Holder.";
		default:
			return "";
	}
};

export default getTableRowTooltip;
