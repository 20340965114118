import styled from 'styled-components';

const CardMediaContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export function CardMedia({ children, ...rest }) {
    return <CardMediaContainer {...rest}>{children}</CardMediaContainer>
}