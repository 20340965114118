import { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import ShowOnScroll from "../components/ShowOnScroll";
import cookie from "js-cookie";
import AppMainBar from "./apphome/AppBar";
import SideDrawer from "./apphome/SideDrawer";
import SimpleAppBar from "./apphome/SimpleAppBar";
import { Outlet } from "react-router-dom";
const drawerWidth = 240;

// check against routes obj.type and render sidebar, nav bar

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexGrow: 1,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(1),
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		minHeight: "100vh",
		width: `calc(100% - ${drawerWidth}px)`,
		// marginRight: -drawerWidth,
	},
	menuShift: {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
	helpShift: {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: 0,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
		alignItems: "flex-start",
		overflow: "auto",
	},
	simpleContent: {
		flexGrow: 1,
		padding: theme.spacing(1),
	},
	drawerOpenScrollTopBtn: {
		left: drawerWidth + theme.spacing(1),
		transition: theme.transitions.create("left", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerCloseScrollTopBtn: {
		transition: theme.transitions.create("left", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		left: theme.spacing(8) + 1,
	},
}));

const RouteLayout = ({ allowedRouteArray }) => {
	const DRAWER_STATE = "drawer";
	const classes = useStyles();
	const theme = useTheme();
	const drawerState = cookie.get(DRAWER_STATE) === "false" ? false : true;
	const [menuOpen, setMenuOpen] = useState(drawerState);

	useEffect(() => {
		cookie.set(DRAWER_STATE, menuOpen);
	}, [menuOpen]);

	return (
		<div className={classes.root}>
			{allowedRouteArray[0].type === "route" && (
				<>
					<AppMainBar menuOpen={menuOpen} openMenuClick={() => setMenuOpen(true)} />
					<SideDrawer open={menuOpen} drawerClick={(drawerState) => (drawerState ? setMenuOpen(true) : setMenuOpen(false))} />
					<main
						className={clsx(classes.content, {
							[classes.menuShift]: menuOpen,
						})}
						id="routes-main-el"
						style={{ display: "flex" }}>
						<div style={{ offset: theme.mixins.toolbar }} />
						<Outlet />
					</main>
				</>
			)}
			{allowedRouteArray[0].type === "subRoute" && (
				<>
					<AppMainBar menuOpen={menuOpen} openMenuClick={() => setMenuOpen(true)} />
					<SideDrawer open={menuOpen} drawerClick={(drawerState) => (drawerState ? setMenuOpen(true) : setMenuOpen(false))} />
					<main
						className={clsx(classes.content, {
							[classes.menuShift]: menuOpen,
						})}
						id="routes-main-el"
						style={{ display: "flex" }}>
						<div style={{ offset: theme.mixins.toolbar }} />
						<Outlet />
					</main>
				</>
			)}
			{allowedRouteArray[0].type === "action" && (
				<>
					<SimpleAppBar />
					<div style={{ offset: theme.mixins.toolbar }} />
					<main className={classes.simpleContent}>
						<div style={{ offset: theme.mixins.toolbar }} />
						<Outlet />
					</main>
				</>
			)}
			{allowedRouteArray[0].type === "designer" && (
				<>
					<div style={{ offset: theme.mixins.toolbar }} />
					<main>
						<div style={{ offset: theme.mixins.toolbar }} />
						<Outlet />
					</main>
				</>
			)}
		</div>
	);
};

export default RouteLayout;
