import Style from "./wallet.style";
import { makeStyles } from "@material-ui/core/styles";
import Wallet from "./wallet.route";
import { ISSUER_PREFIX, issuer } from "@cti-workspace/helpers";
import { userStore } from "../../store/userStore";
import { useQuery } from "@tanstack/react-query";

const WalletRoute = ({
	credCount = () => {
		return null;
	},
	primaryColor,
	cardName,
	cardNameVal,
	cardNameRef,
}) => {
	const useStyles = makeStyles(Style);
	const classes = useStyles();

	const baseUrl = `/${ISSUER_PREFIX}`;
	const email = userStore((state) => state.email);

	const { isLoading, error, data } = useQuery({
		queryKey: [baseUrl, email],
		queryFn: () => issuer.getRecipientCerts(baseUrl, email),
	});

	if (isLoading) return "Loading...";
	if (error) return "An error has occurred: " + error.message;

	return <Wallet classes={classes} recipientData={data} credCount={credCount} baseUrl={baseUrl} email={email} primaryColor={primaryColor} cardName={cardName} cardNameVal={cardNameVal} cardNameRef={cardNameRef} />;
};

export default WalletRoute;
