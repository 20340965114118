import styled from "styled-components";
import { Button, Card } from "@cti-workspace/ui";
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { ArrowClockwise, ArrowFatLeft, House } from "@phosphor-icons/react";

const ViewPathwayWrapper = styled.div`
	display: grid;
	grid-template-columns: 300px auto;
	grid-template-rows: auto;
	height: 100vh;
	overflow: hidden;
`;

const ErrorWrapper = styled.div`
	display: grid;
	grid-template-columns: 300px auto;
	grid-template-rows: auto;
	height: 100vh;
	overflow: hidden;
	background-color: #fff;
`;

const PathwayDetailsSection = styled(Card)`
	display: flex;
	flex-direction: column;
	overflow: auto;
	height: 100vh;
`;

const PathwayDetailItem = styled.div`
	padding: ${({ text }) => (text ? "1rem" : "0.5rem")};
	${({ flexBottom }) =>
		flexBottom
			? `
	display: flex;
			flex-grow:1;
        
        flex-direction: column;
        justify-content: flex-end;
    `
			: ""}
`;

const PathwayBuilderSection = styled.div``;

const TopNavBarFixed = styled.div`
	position: fixed;
	margin-top: 1rem;
	padding: 1rem;
	width: calc(100% - 300px);
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 1;
`;

const TopNavHomeTitle = styled.div`
	background-color: white;
	padding: 0.4rem 1rem;
	border-radius: 3px;
	box-shadow: 0px 0px 20px 0px #d6d6d6;
`;

const TopNavHomeButton = styled(Button)`
	border: 0px !important;
	box-shadow: 0px 0px 20px 0px #d6d6d6;
`;

const BtnFullWidth = styled.div`
	width: 100%;
`;

const CredentialCard = styled.div`
	display: flex;
	align-items: center;
	grid-gap: 0.5rem;
	margin-top: 0.6rem;
	background-color: #ecdaff;
	padding: 0.5rem;
	border-radius: 3px;
`;

function TopNavBar({ back = "/pathways" }) {
	const navigate = useNavigate();
	const { id } = useParams();
	return (
		<TopNavBarFixed>
			<TopNavHomeTitle>
				<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
					Pathways Builder
				</Typography>
			</TopNavHomeTitle>
			<div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
				<TopNavHomeButton invert leftIcon={<ArrowFatLeft size={17} weight={"fill"} />} onClick={() => navigate(back)} fullWidth={false}>
					Back
				</TopNavHomeButton>
			</div>
		</TopNavBarFixed>
	);
}

export { ViewPathwayWrapper, ErrorWrapper, PathwayDetailsSection, PathwayDetailItem, PathwayBuilderSection, TopNavBarFixed, TopNavHomeTitle, TopNavHomeButton, BtnFullWidth, CredentialCard, TopNavBar };
