/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import Link from "@material-ui/core/Link";
import { Button } from '@cti-workspace/ui';
import Typography from "@material-ui/core/Typography";
import ActionDialog from "../../components/ActionDialog";
import { ForgotPassword } from "@cti-workspace/helpers";
import { useSnackbar } from "notistack";

export default function ResetPassword({ email }) {
	const { enqueueSnackbar } = useSnackbar();
	const [openAlertDialog, setOpenAlertDialog] = useState(false);

	const resetPassword = async () => {
		try {
			if (email) {
				let val = await ForgotPassword(email);
				if (val) {
					enqueueSnackbar(`Reset Password Success, Please check your mail `, {
						variant: "success",
					});
				}
			} else {
				console.log("Email not found");
			}
		} catch (error) {
			console.error(error);
		}
		setOpenAlertDialog(false);
	};

	return (
		<>
			<ActionDialog
				open={openAlertDialog}
				title="Reset Password?"
				onClose={() => setOpenAlertDialog(false)}
				content={
					<Typography variant="h6" color="secondary">
						Are you sure you want to reset your password?
					</Typography>
				}
				primaryAction={
					<Button
						id="confirm-reset-password-btn"
						onClick={() => {
							resetPassword();
						}}
						color="secondary"
						variant={"contained"}
					>
						Yes
					</Button>
				}
				secondaryAction={
					<Button
						id="confirm-cancel-password-btn"
						onClick={() => {
							setOpenAlertDialog(false);
						}}
						color="white"
					>
						No
					</Button>
				}
			/>
			<Link
				component="button"
				variant="body2"
				id="reset-password-link"
				onClick={() => {
					setOpenAlertDialog(true);
				}}
			>
				Click here
			</Link>
		</>
	);
}
