import { postApi, downloadApi, convertIssuedTimeToLocal } from "@cti-workspace/helpers";

const booleanRenderValues = {
	yes: "Yes",
	no: "No",
};

const getBooleanRenderValue = (value) => {
	if (value) {
		return booleanRenderValues.yes;
	}
	return booleanRenderValues.no;
};

const getRequestBody = (pageNumber, pageSize, startDate, endDate, fileType = null, searchText = null) => ({
	pageNumber: fileType ? undefined : pageNumber,
	pageSize: fileType ? undefined : pageSize,
	startDate: startDate && endDate ? startDate : undefined,
	endDate: startDate && endDate ? endDate : undefined,
	fileType: fileType ? fileType : undefined,
	searchText: fileType ? undefined : searchText || undefined,
});

export const getExploreAnalytics = async (pageNumber, pageSize, startDate, endDate, searchText) => {
	const response = await postApi("/organizations/getAdvancedAnalytics", getRequestBody(pageNumber, pageSize, startDate, endDate, null, searchText));
	response.data = response.data.map((analytics) => ({
		...analytics,
		id: analytics.credentialId,
		key: analytics.credentialId,
		addedToLinkedIn: getBooleanRenderValue(analytics.addedToLinkedIn),
		opened: getBooleanRenderValue(analytics.opened),
		postedOnLinkedIn: getBooleanRenderValue(analytics.postedOnLinkedIn),
		postedOnTwitter: getBooleanRenderValue(analytics.postedOnTwitter),
		walletCreated: getBooleanRenderValue(analytics.walletCreated),
		addedToMobileWallet: getBooleanRenderValue(analytics.addedToMobileWallet),
	}));
	response.data = convertIssuedTimeToLocal(response.data);
	return response;
};

export const downloadToExcel = async (pageNumber, pageSize, startDate, endDate, sortModel) => {
	await downloadApi(
		"/organizations/exportAdvancedAnalytics",
		{
			...getRequestBody(pageNumber, pageSize, startDate, endDate, "XLSX"),
			config: {
				sortBy: sortModel.map((sortField) => ({
					order: sortField.sort,
					field: sortField.field === "id" ? "credentialId" : sortField.field,
				})),
			},
		},
		"POST",
		"analytics.xlsx"
	);
};

export const dateSortComparator = (v1, v2) => new Date(v1).getTime() - new Date(v2).getTime();

export const toolTipCellRenderer = (params) => {
	return (
		<span title={params.value} className="ToolTip-Cell-Renderer">
			{params.value}
		</span>
	);
};
