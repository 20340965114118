import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { grey } from "@material-ui/core/colors";
import useNProgressHook from "../../hooks/useNProgressHook";
import { useSnackbar } from "notistack";
import { MenuItem, Link } from "@material-ui/core";
import { Card, CardActions, CardContent, CardHeader } from "@cti-workspace/ui";

const ImportedModuleItemCard = ({ index, type, item, title, subheaderText, subheaderLink, description, provider, getUniqueCredentialName, createNewCredential, importSupported, credsData, connectedCredsData }) => {
	const { progressStart, progressDone } = useNProgressHook();
	const { enqueueSnackbar } = useSnackbar();

	const getCourseAndCourseWork = () => {
		// Default this is course work
		let courseWork = item;
		let course = provider.selectedCourse;

		if (type === "course") {
			courseWork = {
				id: "ALL",
				title: "ALL",
			};
			course = item;
		}
		return { course, courseWork };
	};

	return (
		<Card
			elevation={1}
			style={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
				width: "100%",
			}}>
			<CardHeader
				avatar={
					<Avatar
						aria-label="number"
						style={{
							backgroundColor: grey[500],
						}}>
						{index}
					</Avatar>
				}
				title={
					<Link component="button" href={subheaderLink} target={"_blank"} rel="noopener noreferrer">
						{title}
					</Link>
				}
			/>
			<CardContent>
				<Typography variant="body2" component="p">
					{description}
				</Typography>
			</CardContent>
			<CardActions style={{ alignItems: "flex-end", height: "100%" }}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						{/* If this course is already connected to a credential */}
						{connectedCredsData &&
						connectedCredsData.length &&
						connectedCredsData.find(
							(cred) =>
								// Connected courses
								(cred.courseId === item.id && cred.courseWorkId === "ALL") ||
								// Connected course work
								(cred.courseId === item.courseId && cred.courseWorkId === item.id)
						) ? (
							<Grid container>
								{/* <Grid item xs={12}>
                                    <Typography variant="subtitle1">Credential</Typography>
                                </Grid> */}
								<Grid item xs={12}>
									<Paper
										elevation={1}
										style={{
											padding: "1.2rem",
											display: "flex",
										}}>
										<VerifiedUserIcon fontSize="small" style={{ color: "green" }} />
										<Typography variant="body2">{connectedCredsData.find((cred) => (cred.courseId === item.id && cred.courseWorkId === "ALL") || (cred.courseId === item.courseId && cred.courseWorkId === item.id)).credentialName}</Typography>
									</Paper>
								</Grid>
							</Grid>
						) : (
							<Grid container spacing={1} alignItems="flex-end">
								<Grid item xs={12}>
									<FormControl
										style={{
											minWidth: 200,
											display: "flex",
											marginTop: 0,
											marginLeft: "auto",
											marginRight: "auto",
										}}
										variant="outlined">
										<InputLabel htmlFor="selected-credential">Select Credential</InputLabel>
										<Select
											onChange={(event) => {
												provider.setSelectedCredential(JSON.parse(event.target.value));
											}}
											inputProps={{
												id: "selected-credential",
											}}
											label="Select Credential">
											<MenuItem value="">None</MenuItem>
											{credsData &&
												credsData.map((cred, i) => (
													<MenuItem key={i} value={JSON.stringify(cred)}>
														{cred.name}
													</MenuItem>
												))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={6}>
									<Button
										variant="contained"
										fullWidth
										size="small"
										color="primary"
										onClick={async () => {
											progressStart();
											const { course, courseWork } = getCourseAndCourseWork();
											if (!Object.keys(provider.selectedCredential).length) {
												enqueueSnackbar(`You must first select a credential.`, {
													variant: "Warning",
												});
											} else {
												await provider.connectItemWithCredential(courseWork, provider.selectedCredential, course);
											}
											progressDone();
										}}>
										Connect
									</Button>
								</Grid>
								<Grid item xs={12} md={6}>
									<Button
										fullWidth
										size="small"
										color="secondary"
										variant="contained"
										onClick={async () => {
											progressStart();
											const credentialName = getUniqueCredentialName(title);
											const description = item.description || "";

											// Build the transcript as the included course work
											let transcriptFieldsArray = [];

											if (importSupported) {
												const courseWork = await provider.getCourseWork(item);
												if (courseWork && courseWork.length) {
													transcriptFieldsArray = courseWork.map((cw) => cw.title);
												}
											}

											const imageUrl = "";
											const resources = [];

											// Add the credential definition
											const data = await createNewCredential(credentialName, description, transcriptFieldsArray, imageUrl, resources);

											// Default if connecting course work
											const { course, courseWork } = getCourseAndCourseWork();
											await provider.connectItemWithCredential(courseWork, data.definition, course);
											progressDone();
										}}>
										Create + Connect
									</Button>
								</Grid>
							</Grid>
						)}
					</Grid>
					{importSupported && (
						<Grid item xs={12}>
							<Button
								fullWidth
								size="small"
								color="primary"
								variant="outlined"
								onClick={async () => {
									progressStart();
									await provider.importCourseWork(item);
									progressDone();
								}}>
								Import Content
							</Button>
						</Grid>
					)}
				</Grid>
			</CardActions>
		</Card>
	);
};

export default ImportedModuleItemCard;
