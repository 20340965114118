import { Fragment, useMemo, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Divider, Grid, TextField, Tooltip } from "@material-ui/core";
import ContactTable from "../GenericTable";
import { useSnackbar } from "notistack";
import CAPABILITIES from "../../capabilities";
import { isCapable } from "../../routes/admin/userAccounts/permissionUtils";
import red from "@material-ui/core/colors/red";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import CapabilityBoundary from "../CapabilityBoundary/CapabilityBoundary";
import { IMAGE_CONTENT, findInvalidTranscriptGrade, findInvalidSectionRows, getObjectValue } from "@cti-workspace/helpers";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Box, Spinner } from "@cti-workspace/ui";
import { userStore } from "../../store/userStore";
import TranscriptValueForm from "./transcriptValueForm";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
		textAlign: "center",
	},
	alignCenter: {
		display: "flex",
		alignItems: "center",
	},
}));

export default function IssueCredential({
	transcripts,
	handleModalClose,
	setSelectedContact,
	selectedContact,
	selectedCred,
	handleCreateIssueCredential,
	settranscriptGrades,
	transcriptGrades,
	putContacts,
	contacts,
	selectAllContacts,
	contactsTags,
	getContacts,
	sections = [],
	setSectionsData = () => {},
	sectionsData = {},
	setExpirationDate = () => {},
	setExpirationDurationStr = () => {},
	setExpirationDurationNum = () => {},
	disableIssueButton = false,
}) {
	const classes = useStyles();
	const dateRef = useRef();
	const [issueClick, setIssueClick] = useState(false);
	const [recipientSelected, setRecipientSelected] = useState(false);
	const capabilities = userStore((state) => state.capabilities);
	const { enqueueSnackbar } = useSnackbar();
	const primaryRed = red[500];
	const { register, control, handleSubmit, errors, watch } = useForm({ mode: "onBlur" });
	const { fields, append, remove } = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name: "newRecipient", // unique name for your Field Array
		// keyName: "id", default to "id", you can change the key name
	});
	const [showHideTable, setShowHideTable] = useState(false);
	const [addCredentialExpire, setAddCredentialExpire] = useState(false);
	const [selectedExpireValue, setSelectedExpireValue] = useState(null);

	const steps = getSteps();

	const allowSearchingContacts = useMemo(() => {
		return isCapable(capabilities, CAPABILITIES.ISSUE_CREDENTIAL_SEARCH_CONTACTS);
	}, [capabilities]);

	const handleShowHideTable = () => {
		setShowHideTable((prev) => !prev);
	};

	const handleShoHideCredentialExpire = () => {
		setAddCredentialExpire((prev) => !prev);
		setSelectedExpireValue(null);
	};

	const handleCredentialExpires = (event) => {
		setSelectedExpireValue(event.target.value);
	};

	const tomorrowDate = () => {
		let today = new Date(Date.now() + 24 * 60 * 60 * 1000);
		let dd = today.getDate();
		let mm = today.getMonth() + 1; //January is 0!
		let yyyy = today.getFullYear();
		if (dd < 10) {
			dd = "0" + dd;
		}
		if (mm < 10) {
			mm = "0" + mm;
		}

		return (today = yyyy + "-" + mm + "-" + dd);
	};

	function contactForm() {
		const filterContactsByTags = (value) => {
			getContacts && getContacts(value);
		};

		return (
			<div>
				<div style={{ marginTop: "1rem", marginBottom: "16px", overflow: "hidden" }}>
					<form className={classes.container}>
						<CapabilityBoundary
							capability={CAPABILITIES.ISSUE_CREDENTIAL_ADD_CONTACT}
							hide
							render={() => (
								<Tooltip arrow placement="left-start" title="Add Recipient">
									<Button style={{ margin: 4, float: "right" }} variant="outlined" color="default" size="small" id="add-contact12" onClick={() => append({ firstName: "", lastName: "", contact: "" })}>
										<AddIcon /> Add
									</Button>
								</Tooltip>
							)}
						/>
						{fields.length > 0 ? null : (
							<CapabilityBoundary
								capability={CAPABILITIES.ISSUE_CREDENTIAL_ADD_CONTACT}
								hide
								render={() => (
									<Typography style={{ clear: "both", textAlign: "center" }} textAlign="center">
										Click + ADD to add a new recipient.
									</Typography>
								)}
							/>
						)}
						{fields.map((item, index) => (
							<Grid container alignItems="flex-start" spacing={3} style={{ marginTop: 0, marginBottom: 0 }} key={item.id}>
								<Grid item xs={12} md={3}>
									<TextField id="first-name" label="First Name" variant="outlined" fullWidth inputRef={register({ required: true })} name={`newRecipient[${index}].firstName`} defaultValue={item.firstName} />
									{getObjectValue(errors, `newRecipient[${index}].firstName.type`) === "required" && <p style={{ color: primaryRed }}>First Name is required</p>}
								</Grid>
								<Grid item xs={12} md={4}>
									<TextField id="last-name" label="Last Name" variant="outlined" fullWidth inputRef={register({ required: true })} name={`newRecipient[${index}].lastName`} defaultValue={item.lastName} />
									{getObjectValue(errors, `newRecipient[${index}].lastName.type`) === "required" && <p style={{ color: primaryRed }}>Last Name is required</p>}
								</Grid>
								<Grid item xs={12} md={4}>
									<TextField
										id="contact"
										label={`Email`}
										inputRef={register({
											required: true,
											pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
										})}
										name={`newRecipient[${index}].contact`}
										variant="outlined"
										fullWidth
										defaultValue={item.contact}
									/>
									{getObjectValue(errors, `newRecipient[${index}].contact.type`) === "required" && <p style={{ color: primaryRed }}>Email is required</p>}
									{getObjectValue(errors, `newRecipient[${index}].contact.type`) === "pattern" && <p style={{ color: primaryRed }}>Please enter a valid email</p>}
									<TextField id="type" label={`type`} inputRef={register()} name={`newRecipient[${index}].type`} defaultValue={"email"} style={{ display: "none" }} />
								</Grid>
								<Grid item xs={12} md={1} container justifyContent="center">
									<Button style={{ minWidth: "auto", marginTop: "12px" }} variant="text" color="default" size="small" onClick={() => remove(index)}>
										<CloseIcon />
									</Button>
								</Grid>
							</Grid>
						))}
					</form>
				</div>
				<Box display="flex" justifyContent="space-between">
					<Typography variant={"h6"} style={{ paddingBottom: ".5rem" }}>
						Past Recipients
					</Typography>
					<Tooltip arrow placement="left-start" title={showHideTable ? "Hide Past Recipients" : "View Past Recipients"}>
						<Button style={{ margin: 4 }} variant="outlined" color="default" size="small" onClick={handleShowHideTable}>
							{showHideTable ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</Button>
					</Tooltip>
				</Box>
				<Divider style={{ marginBottom: "1rem" }} />
				{showHideTable && (
					<ContactTable
						config={{
							headers: [
								{ label: "First Name", prop: "firstName", sort: true },
								{ label: "Last Name", prop: "lastName", sort: true },
								{ label: "Email", prop: "contact", sort: true },
							],
						}}
						data={contacts}
						searchPlaceholder="Search Recipients"
						search={allowSearchingContacts ? ["firstName", "lastName", "contact"] : []}
						dense
						checkbox
						emptyText="No records to display"
						pagination
						onCheckBoxClickedRows={setSelectedContact}
						header={
							selectedContact && selectedContact.length > 0 ? (
								<div style={{ display: "flex", alignItems: "center" }}>
									<Typography variant="subtitle1">
										{selectedContact.length} row{selectedContact.length > 1 ? "'s" : ""} selected
									</Typography>
								</div>
							) : (
								<></>
							)
						}
					/>
				)}
				<Box display="flex" justifyContent="space-between">
					<Typography variant={"h6"} style={{ paddingBottom: ".5rem" }}>
						Credential Expires
					</Typography>
					<Checkbox checked={addCredentialExpire} onChange={handleShoHideCredentialExpire} />
				</Box>
				<Divider style={{ marginBottom: "1rem" }} />
				{addCredentialExpire && (
					<div>
						<div>
							<div className={classes.alignCenter}>
								<Radio checked={selectedExpireValue === "expiryDate"} onChange={handleCredentialExpires} value="expiryDate" name="credential-expires" />
								<Typography>Add Expiry Date</Typography>
							</div>
							{selectedExpireValue === "expiryDate" && (
								<p>
									<TextField inputRef={register({ required: true })} name="expiryDate" variant="outlined" onChange={(e) => setExpirationDate(e.target.value)} type="date" inputProps={{ min: tomorrowDate() }} className="expiryDate" />
								</p>
							)}
							{selectedExpireValue === "expiryDate" && getObjectValue(errors, `expiryDate.type`) === "required" && <p style={{ color: primaryRed }}>Please Select Date</p>}
						</div>
						<div>
							<div className={classes.alignCenter}>
								<Radio checked={selectedExpireValue === "expiryDuration"} onChange={handleCredentialExpires} value="expiryDuration" name="credential-expires" />
								<Typography>Add Expiry Duration</Typography>
							</div>
							{selectedExpireValue === "expiryDuration" && (
								<>
									<Grid item container spacing={1} direction="row" justifyContent="flex-start" alignItems="center" alignContent="center">
										<Grid item xs={2}>
											<TextField variant="outlined" inputRef={register({ required: true })} name="expiryDuration" label="Enter Duration" onChange={(e) => setExpirationDurationNum(e.target.value)} type="text" />
										</Grid>
										<Grid item xs={3}>
											<Controller
												name={"expiryDurationStr"}
												rules={{ required: true }}
												control={control}
												render={({ onChange, value, ref }) => {
													return (
														<FormControl className={classes.formControl} fullWidth variant="outlined">
															<InputLabel id="demo-simple-select-autowidth-label">Select Duration</InputLabel>
															<Select
																labelId="demo-simple-select-autowidth-label"
																ref={ref}
																label="Select Duration"
																value={value}
																onChange={(e) => {
																	setExpirationDurationStr(e.target.value), onChange(e.target.value);
																}}>
																<MenuItem value="days">Days</MenuItem>
																<MenuItem value="months">Months</MenuItem>
																<MenuItem value="years">Years</MenuItem>
															</Select>
														</FormControl>
													);
												}}
											/>
										</Grid>
									</Grid>
									<Grid item container spacing={1} direction="row" justifyContent="flex-start" alignItems="center" alignContent="center">
										<Grid item xs={2}>
											{selectedExpireValue === "expiryDuration" && getObjectValue(errors, `expiryDuration.type`) === "required" && <p style={{ color: primaryRed }}>Please Enter Duration</p>}
										</Grid>
										<Grid item xs={2}>
											{selectedExpireValue === "expiryDuration" && getObjectValue(errors, `expiryDurationStr.type`) === "required" && <p style={{ color: primaryRed }}>Please Select Duration</p>}
										</Grid>
									</Grid>
								</>
							)}
						</div>
					</div>
				)}
			</div>
		);
	}

	function sectionsValueForm() {
		return (
			<div>
				{sections &&
					sections.map((item, i) => (
						<div key={i}>
							<Typography variant="h6">{item.name}</Typography>
							<Typography variant="subtitle">{item?.description || ""}</Typography>
							<Grid container spacing={3}>
								{(item.claims || []).map((claim, index) => (
									<Fragment key={index + claim}>
										<Grid item xs={5}>
											<TextField readOnly disabled fullWidth value={claim} type="text" label="Name" variant="filled" />
										</Grid>
										<Grid item xs={7}>
											<TextField
												id={claim}
												required
												fullWidth
												type="text"
												label="Value"
												value={getObjectValue(sectionsData, `${item.name}.${claim}`, "")}
												placeholder={`Value`}
												onChange={(e) =>
													setSectionsData({
														...sectionsData,
														[item.name]: {
															...getObjectValue(sectionsData, item.name, {}),
															[claim]: e.target.value,
														},
													})
												}
												variant="outlined"
											/>
										</Grid>
									</Fragment>
								))}
							</Grid>
						</div>
					))}
			</div>
		);
	}

	function getSteps() {
		return ["Recipient Details", "Add Content", "Section"];
	}

	function getStepContent(step) {
		switch (step) {
			case 0:
				return contactForm();
			case 1:
				return <TranscriptValueForm transcripts={transcripts} transcriptGrades={transcriptGrades} settranscriptGrades={settranscriptGrades} />;
			case 2:
				return sectionsValueForm();
			default:
				return "Unknown step";
		}
	}

	const handleIssue = (data) => {
		if (disableIssueButton) return;
		if (Object.keys(data).length === 0 && selectedContact.length === 0) {
			setRecipientSelected(
				true,
				setTimeout(() => {
					setRecipientSelected(false);
				}, 5000)
			);
		}

		let newRecipient = data?.newRecipient || [];

		const invalidSectionRows = findInvalidSectionRows(sections, sectionsData);
		const invalidTranscriptGrade = findInvalidTranscriptGrade(transcripts, transcriptGrades);

		if (!invalidTranscriptGrade && (Object.keys(data).length !== 0 || selectedContact.length !== 0) && !invalidSectionRows) {
			setIssueClick(true);
			handleCreateIssueCredential(undefined, newRecipient);
		} else if (!invalidTranscriptGrade == false && Object.keys(transcriptGrades).length !== 0) {
			enqueueSnackbar(`A value was not entered for ${invalidTranscriptGrade}. A value must be entered for every row, please ensure all rows are completed before submitting.`, {
				variant: "error",
			});
		} else if (invalidSectionRows) {
			enqueueSnackbar(`A value was not entered for ${invalidSectionRows}. A value must be entered for every row of sections, please ensure all rows of sections are completed before submitting.`, {
				variant: "error",
			});
		}
	};

	return (
		<div className={classes.root}>
			{steps.map((label, index) => (
				<Fragment key={index}>
					<Typography
						variant={"h6"}
						style={{
							marginTop: index !== 0 ? 20 : "auto",
							paddingBottom: ".5rem",
						}}>
						{label}
					</Typography>
					<Divider />
					{index !== 0 && <div style={{ marginTop: 15 }} />}
					{getStepContent(index)}
				</Fragment>
			))}

			<div className={classes.actionsContainer}>
				{recipientSelected ? (
					<Typography display={"inline"} color="secondary" variant={"caption"}>
						Please add or select at least one recipient.
					</Typography>
				) : null}
				{!issueClick ? (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
							alignItems: "center",
						}}>
						<Button variant="contained" color="primary" type="submit" id="issue-credentials" disabled={disableIssueButton} onClick={handleSubmit((data) => handleIssue(data))} className={classes.button} fullWidth>
							Issue
						</Button>
						{disableIssueButton ? (
							<Typography variant={"h6"} color="error">
								Please update credential with a valid certificate template first
							</Typography>
						) : null}
					</div>
				) : (
					<Spinner />
				)}
			</div>
		</div>
	);
}
