import { useContext, useEffect, useLayoutEffect, useRef, useCallback } from "react";
import { FabricContext } from "../context/FabricContext";
import { useController } from "react-hook-form";
import mergeRefs from "react-merge-refs";
import WebFont from "webfontloader";
import { getObjectValue } from "@cti-workspace/helpers";
import useSettingsHook from "../../../../../hooks/useSettingsHook";
import { useParams } from "react-router-dom";

const FabricCanvas = ({ control, jsonData = null, width, height, id, onPageUpdate, fabricPage, isActive }) => {
	const canvasEl = useRef(null);
	const { canvas, initCanvas, setActiveObject, loadFromJSON, activeObject, snapGrid } = useContext(FabricContext);
	const { canView } = useSettingsHook();

	const params = useParams();
	const { fabricCertDesigner: defaultFabricCertDesigner, designType, rendererConfigId } = control?.defaultValuesRef?.current ?? {};
	const enableMultiPageCertificate = canView("enableMultiPageCertificate", true) && ((params.id && defaultFabricCertDesigner?.isMultiPage) || designType === "template" || !rendererConfigId || !params?.id);

	const {
		field: { ref: fabricCertDesignRef, value, onChange },
	} = useController({ name: enableMultiPageCertificate ? `fabricCertDesigner.${id}` : `fabricCertDesigner`, control });

	const defaultObjectLength = defaultFabricCertDesigner?.objects?.length;

	useLayoutEffect(() => {
		const fabricValue = enableMultiPageCertificate ? fabricPage : defaultFabricCertDesigner;
		if (fabricValue && Object.keys(fabricValue).length > 2) {
			let fonts = [];
			fabricValue?.objects.forEach((element) => {
				if (element.fontFamily) {
					fonts.push(element.fontFamily);
				}
			});
			if (fonts.length > 0) {
				fonts = [...new Set(fonts)];
				WebFont.load({
					google: {
						families: fonts,
					},
				});
			}
			loadFromJSON(canvasEl.current, id, fabricValue, { width, height });
		} else if (!params.id || enableMultiPageCertificate || !canView("enableMultiPageCertificate", true)) {
			initCanvas(canvasEl.current, id, {
				width,
				height,
				backgroundColor: "#ffffff",
			});
		}
	}, [canvasEl, defaultFabricCertDesigner?.backgroundImage, defaultObjectLength, enableMultiPageCertificate]);

	useEffect(() => {
		const cloneCanvas = enableMultiPageCertificate ? getObjectValue(canvas, id) : canvas;
		if (cloneCanvas) {
			cloneCanvas.setWidth(width);
			cloneCanvas.setHeight(height);
			cloneCanvas.clear();
			cloneCanvas.setBackgroundColor("#ffffff");
			cloneCanvas.renderAll();
			snapGrid(cloneCanvas);
			cloneCanvas.renderAll();
			cloneCanvas.fire("object:modified");
		}
	}, [height, width]);

	const updateActiveObject = useCallback(
		(e) => {
			if (!e) {
				return;
			}
			const cloneCanvas = enableMultiPageCertificate ? getObjectValue(canvas, id) : canvas;
			const cloneActiveObject = activeObject ?? [];
			if (enableMultiPageCertificate) {
				setActiveObject([...cloneActiveObject.slice(0, id), cloneCanvas.getActiveObject(), ...cloneActiveObject.slice(id + 1, cloneActiveObject.length)]);
			} else {
				setActiveObject(cloneCanvas.getActiveObject());
			}
			cloneCanvas.renderAll();
			cloneCanvas.fire("object:modified");
		},
		[canvas, activeObject, setActiveObject]
	);

	const canvasModifiedCallback = (e) => {
		if (!e) {
			return;
		}
		const c = enableMultiPageCertificate ? getObjectValue(canvas, id) : canvas;
		if (enableMultiPageCertificate) {
			onPageUpdate(id, { ...fabricPage, ...c?.toJSON() });
		} else {
			onChange(c?.toJSON());
		}
	};

	useEffect(() => {
		const cloneCanvas = enableMultiPageCertificate ? getObjectValue(canvas, id) : canvas;
		if (!cloneCanvas) {
			return;
		}
		cloneCanvas.on("selection:created", updateActiveObject);
		cloneCanvas.on("selection:updated", updateActiveObject);
		cloneCanvas.on("selection:cleared", updateActiveObject);

		cloneCanvas.on("object:added", canvasModifiedCallback);
		cloneCanvas.on("object:removed", canvasModifiedCallback);
		cloneCanvas.on("object:modified", canvasModifiedCallback);

		return () => {
			cloneCanvas.off("selection:created");
			cloneCanvas.off("selection:cleared");
			cloneCanvas.off("selection:updated");
			cloneCanvas.off("object:added");
			cloneCanvas.off("object:removed");
			cloneCanvas.off("object:modified");
		};
	}, [canvas, updateActiveObject, fabricPage]);

	return (
		<div key={(enableMultiPageCertificate ? fabricPage.id : 0) + "_inner"} style={{ borderRadius: "3px", border: isActive ? "2px solid #a65cef" : "2px solid transparent" }}>
			<canvas ref={mergeRefs([canvasEl, fabricCertDesignRef])} id={`fabric-canvas-${enableMultiPageCertificate ? fabricPage?.id : "0"}`} style={{ margin: "0px" }} />
		</div>
	);
};

export default FabricCanvas;
