import { getObjectValue, hasProperty, usePathwaysHook, putApi, PATHWAY_PREFIX, getErrMsg } from "@cti-workspace/helpers";
import { Alert, Box, Button, Card, CheckBox, TextField } from "@cti-workspace/ui";
import { Divider, Grid, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext, useNavigate, useParams, useLocation } from "react-router-dom";

import ipwruLogo from "../../../assets/images/iPWRU_Logo_Med.png";
import useCredentialHook from "../../../hooks/useCredentialHook";
import { ControllerInput, CredentialAsyncComboBox } from "../../definePathway/definePathway";
import { PathwayDesigner } from "../pathwayDesigner";
import { PathwayDetailsSection, PathwayDetailItem, BtnFullWidth } from "../components/pathwayComponents";
import { userStore } from "../../../store/userStore";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function EditPathway() {
	const { id, leafId } = useParams();

	const [childRef, steps] = useOutletContext();

	const getActiveLeaf = steps.filter((item) => item.stepId === leafId)[0];
	const getActiveParent = steps.filter((item) => item.level === 0)[0];
	const activeCard = leafId ? getActiveLeaf : getActiveParent;

	const { enqueueSnackbar } = useSnackbar();

	const {
		formState: { isDirty },
		control,
		handleSubmit,
		errors,
		reset,
	} = useForm({
		defaultValues: {
			name: "",
			description: "",
			credentialId: "",
		},
	});
	const roles = userStore((state) => state.roles);
	const organizations = userStore((state) => state.organizations);
	const [selectedCredentialDetails, setSelectedCredentialDetails] = useState(null);
	const { getCredentialById } = useCredentialHook({ account: { roles, organizations }, loadInitialCredentials: false });
	const navigate = useNavigate();

	useEffect(() => {
		if (activeCard) {
			reset({ credentialId: activeCard?.credentialId, name: activeCard?.name, description: activeCard?.description });

			if (activeCard.credentialId) {
				getCredentialById(activeCard.credentialId).then((res) => setSelectedCredentialDetails(res));
			}
		}
		if (childRef && activeCard) {
			childRef.current.setSelected(activeCard.stepId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [childRef, activeCard]);

	const queryClient = useQueryClient();

	const updateArray = (arr1, data) => {
		const newArr = arr1.map((obj) => {
			if (obj.stepId === leafId) {
				// console.log(obj);
				return { ...data };
			}
			if (obj.stepId === id) {
				// console.log(obj);
				return { ...data };
			}
			return obj;
		});
		return newArr;
	};

	const editStepMutation = useMutation(({ data, id }) => putApi(`/${PATHWAY_PREFIX}/${id}`, { ...data }), {
		// When mutate is called:
		onMutate: async ({ data, id }) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries(["pathway", id]);

			// Snapshot the previous value
			const previousPathways = queryClient.getQueryData(["pathway", id]);

			// Optimistically update to the new value
			if (previousPathways) {
				let temp = updateArray(previousPathways.steps, data);
				console.log(temp);

				queryClient.setQueryData(["pathway", id], {
					...previousPathways,
					steps: [...temp],
				});
			}

			return { previousPathways };
		},

		onSuccess: (res) => {
			enqueueSnackbar("Step edited successfully.", { variant: "success" });
		},
		// If the mutation fails, use the context returned from onMutate to roll back
		onError: (err, variables, context) => {
			if (context?.previousPathways) {
				queryClient.setQueryData(["pathway", id], context.previousPathways);
			}
			console.error(err);
			enqueueSnackbar(`The Step could not be edited. ${err.message || ""}`, { variant: "error" });
		},
		// Always refetch after error or success:
		onSettled: () => {
			queryClient.invalidateQueries(["pathway", id]);
		},
	});

	const onSubmit = async (event) => {
		try {
			const { name, description } = event;
			const more = activeCard;
			const data = { ...more, ...event, name: name.trim(), description: description ? description.trim() : "" };

			console.log(data);
			//TODO: this should be converted to a mutation
			if (isDirty) {
				editStepMutation.mutate({ data, id });
			}
			if (leafId) {
				navigate(`/pathway/${id}/${leafId}`, { replace: true });
			} else {
				navigate(`/pathway/${id}`, { replace: true });
			}
		} catch (error) {
			console.error(error);
			enqueueSnackbar(getErrMsg(error), { variant: "error" });
		}
		childRef.current.clearHighlighting();
	};

	return (
		<PathwayDetailsSection>
			<PathwayDetailItem>
				<img src={ipwruLogo} style={{ width: "13rem" }} alt="iPWRU" />
			</PathwayDetailItem>
			<Divider />
			<PathwayDetailItem text>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<ControllerInput control={control} name="name" errors={errors} label="Name" />
					</Grid>
					<Grid item xs={12}>
						<ControllerInput control={control} name="description" errors={errors} label="Description" required={false} multiline rows={3} />
					</Grid>
					<Grid item xs={12}>
						{selectedCredentialDetails && (
							<Controller
								control={control}
								name="credentialId"
								rules={{
									required: "Credential is required",
								}}
								defaultValue={selectedCredentialDetails}
								render={(render) => (
									<CredentialAsyncComboBox
										label="Credential"
										required
										placeholder="Select your credential"
										defaultValue={selectedCredentialDetails}
										{...render}
										error={hasProperty(errors, "credentialId")}
										helperText={getObjectValue(errors, `${"credentialId"}.message`)}
									/>
								)}
							/>
						)}
					</Grid>
				</Grid>
			</PathwayDetailItem>
			<PathwayDetailItem text flexBottom>
				<div style={{ marginTop: "1rem", display: "flex", width: "100%", gridGap: "0.5rem" }}>
					<BtnFullWidth>
						<Button
							color="white"
							onClick={() => {
								childRef.current.clearHighlighting();
								leafId ? navigate(`/pathway/${id}/${leafId}`, { replace: true }) : navigate(`/pathway/${id}`, { replace: true });
							}}>
							Cancel
						</Button>
					</BtnFullWidth>
					<BtnFullWidth>
						<Button color={"secondary"} disabled={!isDirty} onClick={handleSubmit(onSubmit)}>
							Save
						</Button>
					</BtnFullWidth>
				</div>
			</PathwayDetailItem>
		</PathwayDetailsSection>
	);
}
