import {
	Plus,
	PlusCircle,
	CaretLeft,
	CaretRight,
	MagnifyingGlass,
	DotsThreeVertical,
	X,
	XCircle,
	Pencil,
	CloudArrowDown,
	CloudArrowUp,
	UploadSimple,
	Eye,
	CopySimple,
	Trash,
	ImageSquare,
	QrCode,
	EnvelopeSimple,
	ArrowsLeftRight,
	LinkedinLogo,
	ArrowsCounterClockwise,
	FileArrowUp,
	Fingerprint,
	Warning,
	Upload,
	FileXls,
	FilePdf,
	LinkSimpleHorizontal,
	Info,
	CircleNotch,
	Check,
	CaretUp,
	CaretDown,
	Printer,
	CheckCircle,
	CheckSquare,
	Envelope,
	TwitterLogo,
	Download,
	LinkSimple,
	ArrowsInLineVertical,
	ArrowsOut,
	MicrosoftExcelLogo,
	TextT,
	HashStraight,
	Calendar,
	Timer,
	WarningCircle,
	CalendarBlank,
	FileHtml,
} from "@phosphor-icons/react";
import { getObjectValue } from "@cti-workspace/helpers";
import ActiveIcon from "../../../assets/icons/active.svg";
import ExpiredIcon from "../../../assets/icons/expired.svg";

const IconName = {
	["plus"]: Plus,
	["plus-circle"]: PlusCircle,
	["caret-left"]: CaretLeft,
	["caret-right"]: CaretRight,
	["caret-up"]: CaretUp,
	["caret-down"]: CaretDown,
	["search"]: MagnifyingGlass,
	["dots-three-vertical"]: DotsThreeVertical,
	["cancel"]: X,
	["cancel-circle"]: XCircle,
	["edit"]: Pencil,
	["cloud-download"]: CloudArrowDown,
	["cloud-upload"]: CloudArrowUp,
	["upload"]: UploadSimple,
	["eye"]: Eye,
	["copy"]: CopySimple,
	["delete"]: Trash,
	["image"]: ImageSquare,
	["qr-code"]: QrCode,
	["email"]: EnvelopeSimple,
	["arrow-bidirectional"]: ArrowsLeftRight,
	["linkedin"]: LinkedinLogo,
	["rotate"]: ArrowsCounterClockwise,
	["multiple-credential-upload"]: FileArrowUp,
	["fingerprint"]: Fingerprint,
	["warning"]: Warning,
	["Upload"]: Upload,
	["xls"]: FileXls,
	["pdf"]: FilePdf,
	["link-simple-horizontal"]: LinkSimpleHorizontal,
	["Info"]: Info,
	["Spinner"]: CircleNotch,
	["check"]: Check,
	["printer"]: Printer,
	["checkcircle"]: CheckCircle,
	["envelope"]: Envelope,
	["twitter"]: TwitterLogo,
	["download"]: Download,
	["link"]: LinkSimple,
	["arrowVertical"]: ArrowsInLineVertical,
	["arrowsOut"]: ArrowsOut,
	["microsoftExcelLogo"]: MicrosoftExcelLogo,
	["textT"]: TextT,
	["hashStraight"]: HashStraight,
	["calendar"]: Calendar,
	["check-square"]: CheckSquare,
	["timer"]: Timer,
	["warning-circle"]: WarningCircle,
	["calendarBlank"]: CalendarBlank,
	["html"]: FileHtml
};

const CustomSVGIcons = {
	["active"]: ActiveIcon,
	["expired"]: ExpiredIcon,
};

export const Icon = ({ name, size = 16, weight = "fill", ...props }) => {
	const getIconName = (name) => {
		return getObjectValue(IconName, name);
	};

	const Icon = getIconName(name);
	if (Icon) {
		return <Icon size={size} weight={weight} {...props} />;
	}

	const customIcon = getObjectValue(CustomSVGIcons, name);
	if (customIcon) {
		return <img src={customIcon} width={`${size}px`} height={`${size}px`} style={{ fill: props?.color }} />;
	}

	return null;
};

export default Icon;
