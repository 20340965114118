import styled from "styled-components";

export const DialogTitle = styled.h6`
	margin: 0px;
	font-size: 1.25rem;
	font-weight: 500;
	border-bottom: ${(props) => (props?.noBorder ? "none" : "1px solid rgba(0, 0, 0, 0.1)")};
	padding: 1rem;
`;

export const DialogContent = styled.div`
	overflow: auto;
	padding: 1rem;
`;

export const DialogActions = styled.div`
	padding: 0.5rem;
	border-top: ${(props) => (props?.noBorder ? "none" : "1px solid rgba(0, 0, 0, 0.1)")};
	display: flex;
	align-items: center;
	justify-content: flex-end;
	grid-gap: 0.5rem;
`;