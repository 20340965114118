import { Box, Button, Card, Container, Spinner } from "@cti-workspace/ui";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Tooltip } from "@material-ui/core";
import { ArrowSquareIn, CaretDown } from "@phosphor-icons/react";
import sampleImage from "./img.png";
import ipwruLogoWhite from "../../assets/images/iPWRU_Logo_Med.png";
import ipwruLogo from "../../assets/images/iPWRU_Logo_Med.png";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { getErrMsg, useNftHook } from "@cti-workspace/helpers";
import { useSnackbar } from "notistack";

const PRIMARY_COLOR_LIGHT = "#f1f2f0";
const options = { year: "numeric", month: "long", day: "numeric" };

const defaultNFTData = require("../../assets/json/demoNFT.json");

const PublicPageContainer = styled.div`
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	min-height: 100vh;
	height: 100vh;
	background-color: ${PRIMARY_COLOR_LIGHT};
`;

const AlignCenterContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

const PDescription = styled.div`
	p {
		margin: 0px;
	}
`;

export default function NFTPublicView() {
	const [nftData, setNftData] = useState({});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(true);

	const location = useLocation();
	const { getPublicNftData } = useNftHook();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		const { search } = location;
		const id = new URLSearchParams(search).get("id");

		console.log(id);
		if (id === "test") {
			setLoading(false);
			setNftData(defaultNFTData);
			setError("");
		} else {
			if (id) {
				setError("");
				getPublicNftData(id)
					.then((res) => {
						setNftData(res);
					})
					.catch((error) => {
						console.error(error);
						setError("Unable to load NFT");
						enqueueSnackbar(getErrMsg(error), { variant: "error" });
					})
					.finally(() => {
						setLoading(false);
					});
			}
		}
	}, []);

	return (
		<PublicPageContainer>
			<div style={{ backgroundColor: PRIMARY_COLOR_LIGHT, flex: "0 1 auto" }}>
				<div style={{ backgroundColor: "black", borderBottomLeftRadius: "30px", minHeight: "30px" }}></div>
			</div>
			<div style={{ backgroundColor: "black", display: "flex", flexDirection: "column" }}>
				<div style={{ backgroundColor: "white", borderTopRightRadius: "35px" }}>
					<div style={{ backgroundColor: PRIMARY_COLOR_LIGHT, borderTopRightRadius: "30px", borderBottomLeftRadius: "30px", padding: "0.5rem 1.5rem" }}>
						<Grid container>
							<Grid item xs={12} sm={3} justifyContent="center" style={{ display: "flex", justifyContent: "center" }}>
								<img alt="organization" src={nftData?.orgLogo ? nftData?.orgLogo : ipwruLogo} style={{ maxHeight: "3.5rem", maxWidth: "100%" }} />
							</Grid>
							<Grid sm={5} xs={0} />
							<Grid item xs={12} sm={4} style={{ display: "flex", justifyContent: "center" }}>
								<Typography variant="subtitle1" style={{ display: "inline-flex", alignItems: "center" }}>
									This NFT was minted by{" "}
									<Typography component="span" variant="subtitle1" color="secondary">
										&nbsp;{nftData?.issuerName}&nbsp;
									</Typography>{" "}
									on {new Date(nftData?.createdAt).toLocaleDateString(undefined, options)}
								</Typography>
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
			<div style={{ backgroundColor: PRIMARY_COLOR_LIGHT, flex: "1" }}>
				<div style={{ backgroundColor: "black", height: "100%", borderTopRightRadius: "35px" }}>
					<div style={{ backgroundColor: "white", borderTopRightRadius: "30px", borderBottomLeftRadius: "30px", height: "100%", paddingTop: "1rem", paddingBottom: "1rem" }}>
						{loading || error ? (
							<AlignCenterContainer>
								{error ? (
									<Typography variant="subtitle1" color="error">
										{error}
									</Typography>
								) : (
									<Typography variant="subtitle1">Loading...</Typography>
								)}
							</AlignCenterContainer>
						) : (
							<NFTView data={nftData} />
						)}
					</div>
				</div>
			</div>
			<div style={{ backgroundColor: "white", flex: "0 1 40px" }}>
				<div style={{ backgroundColor: "black", borderTopRightRadius: "30px" }}>
					<VerifierFooter />
				</div>
			</div>
		</PublicPageContainer>
	);
}
export function NFTView({ data = {} }) {
	const [imageLoaded, setImageLoaded] = useState(false);

	const descriptionRef = useRef();

	useEffect(() => {
		if (data?.description) {
			descriptionRef.current.innerHTML = data?.description;
		}
	}, [data]);

	return (
		<Container maxWidth="lg" style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center", height: "100%" }}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6} lg={8}>
					<Box elevation={2} borderRadius={16} p={1} style={{ backgroundColor: "white", borderColor: "#191919" }}>
						{!imageLoaded && (
							<AlignCenterContainer>
								<Spinner strokeWidth={2} />
							</AlignCenterContainer>
						)}
						{data?.fileExt === "mp4" ? (
							<video style={{ display: imageLoaded ? "block" : "none", width: "100%", height: "100%" }} preload="metadata" onLoadedMetadata={() => setImageLoaded(true)} controls playsInline controlsList="nofullscreen">
								<source src={data?.imageUrl} />
							</video>
						) : (
							<img alt="nft" src={data?.imageUrl} style={{ display: imageLoaded ? "block" : "none", width: "100%", height: "100%" }} onLoad={() => setImageLoaded(true)} />
						)}
					</Box>
				</Grid>
				<Grid item container xs={12} sm={6} lg={4} alignContent="center" spacing={2} style={{ paddingLeft: 40 }}>
					<Grid item container style={{ paddingLeft: 22 }} spacing={2}>
						<Grid item xs={12} style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<Typography variant="h4" style={{ fontWeight: "bold", marginRight: "0.5rem" }}>
									{data?.issuerName} NFT
								</Typography>
								<img alt="verified" src={"https://upload.wikimedia.org/wikipedia/commons/e/e4/Twitter_Verified_Badge.svg"} style={{ width: 30 }} />
							</div>
							{/* <ArrowSquareIn size={22} weight="bold" style={{ cursor: 'pointer' }} onClick={() => window.open(data?.transactionUrl, "_blank")} /> */}
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h5" style={{ fontWeight: "bold" }}>
								Name
							</Typography>
							<Typography variant="h6" style={{ color: "#515151" }}>
								{data?.name}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h5" style={{ fontWeight: "bold" }}>
								Owner
							</Typography>
							<Typography variant="h6" style={{ color: "#515151" }}>
								{data?.ownerName}
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Accordion elevation={0}>
							<AccordionSummary expandIcon={<CaretDown />} aria-controls="panel1a-content" id="panel1a-header">
								<Typography variant="h5" style={{ fontWeight: "bold" }}>
									Description
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<PDescription>
									<Typography variant="h6" ref={descriptionRef} style={{ color: "#515151" }} />
								</PDescription>
							</AccordionDetails>
						</Accordion>
					</Grid>
					<Grid item xs={12}>
						<Accordion elevation={0}>
							<AccordionSummary expandIcon={<CaretDown />} aria-controls="panel1a-content" id="panel1a-header">
								<Typography variant="h5" style={{ fontWeight: "bold" }}>
									Details
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container style={{ whiteSpace: "wrap", overflow: "auto", wordBreak: "break-all" }}>
									<Grid item xs={12} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "baseline" }}>
										<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
											Transaction ID
										</Typography>
										<Tooltip title={`${data?.transactionId}`}>
											<Typography style={{ color: "#515151" }} variant="subtitle2">
												{data?.transactionId}
											</Typography>
										</Tooltip>
									</Grid>
									<Grid item xs={12} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "baseline" }}>
										<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
											Blockchain
										</Typography>
										<Typography variant="subtitle2">Polygon</Typography>
									</Grid>
									<Grid item xs={12} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "baseline" }}>
										<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
											Contract Address
										</Typography>
										<Tooltip title={`${data?.contractAddress}`}>
											<Typography style={{ color: "#515151" }} variant="subtitle2">
												{data?.contractAddress}
											</Typography>
										</Tooltip>
									</Grid>
									<Grid item xs={12} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "baseline" }}>
										<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
											Token Standard
										</Typography>
										<Typography style={{ color: "#515151" }} variant="subtitle2">
											ERC-721
										</Typography>
									</Grid>
									<Grid item xs={12} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "baseline" }}>
										<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
											Timestamp
										</Typography>
										<Typography style={{ color: "#515151" }} variant="subtitle2">
											{data?.createdAt}
										</Typography>
									</Grid>
								</Grid>
							</AccordionDetails>
						</Accordion>
						<Button invert style={{ cursor: "pointer", alignItems: "center" }} onClick={() => window.open(data?.transactionUrl, "_blank")} buttonTransparent rightIcon={<ArrowSquareIn weight="bold" />}>
							more info
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
}

function VerifierFooter() {
	return (
		<Container maxWidth="lg" style={{ paddingTop: "2rem", paddingBottom: "3rem" }}>
			<Grid container>
				<Grid item xs={12} md={3}>
					<img src={ipwruLogoWhite} style={{ height: "50px" }} />
					<div style={{ marginLeft: "40px" }}></div>
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography variant="caption" style={{ display: "block", color: "white", marginTop: "17px" }}>
						&copy; Copyright {new Date().getFullYear()} - iPWRU.
					</Typography>
				</Grid>
			</Grid>
		</Container>
	);
}
