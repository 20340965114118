import styled from "styled-components";
import React, { forwardRef } from "react";
import { getObjectValue } from "@cti-workspace/helpers";

const ContainerWrapper = styled.div`
	box-sizing: border-box;
	max-width: ${(props) => props.maxWidth};
	width: 100%;
	margin: 0 auto;
	${(props) =>
		props.disableGutters
			? ``
			: `
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            `}
	display: flex;
	flex-direction: column;
`;

export class Container extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			maxWidthPx: {
				xs: "444px",
				sm: "600px",
				md: "960px",
				lg: "1280px",
				xl: "1920px",
			},
		};
	}
	render() {
		return (
			<ContainerWrapper {...this.props} maxWidth={getObjectValue(this.state.maxWidthPx, `${this.props.maxWidth || "lg"}`)}>
				{this.props.children}
			</ContainerWrapper>
		);
	}
}

// export function Container({ maxWidth = "lg", children, ref, ...rest }) {
// 	const maxWidthPx = {
// 		xs: "444px",
// 		sm: "600px",
// 		md: "960px",
// 		lg: "1280px",
// 		xl: "1920px",
// 	};
// 	return (
// 		<ContainerWrapper maxWidth={maxWidthPx[maxWidth]} {...rest}>
// 			{children}
// 		</ContainerWrapper>
// 	);
// }

export default Container;
