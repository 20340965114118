import { Warning } from "@phosphor-icons/react";
import styled from "styled-components";
import { ThemeProvider } from "theme-ui";
import Theme from "../theme/theme";
import { getObjectValue } from "@cti-workspace/helpers";

const colors = {
	error: "#fecdcf",
};

const AlertDiv = styled.div`
	display: flex;
	align-items: center;
	grid-gap: 0.5rem;
	background-color: ${({ type }) => getObjectValue(colors, type)};
	padding: 0.5rem;
	border-radius: 3px;
`;

export function Alert({ title = "", type = "error", iconSize = 27 }) {
	const alertTypeIcon = (type) => {
		switch (type) {
			case "error":
				return <Warning size={iconSize} weight="fill" color="#fc7377" />;
		}
	};
	return (
		<ThemeProvider theme={Theme}>
			<AlertDiv type={type}>
				{alertTypeIcon(type)}
				<label style={{ color: "#484848" }}>{title}</label>
			</AlertDiv>
		</ThemeProvider>
	);
}

/** @jsxImportSource theme-ui */
export default Alert;
