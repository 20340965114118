import { createRef, Fragment, useState, useEffect } from "react";
import { Hidden, Paper, useTheme, Typography, Grid, Divider, makeStyles, Tooltip, IconButton, Button, Link } from "@material-ui/core";
import HeaderWithLIne from "../HeaderWithLine";
import useCredentialHook from "../../hooks/useCredentialHook";
import { useParams } from "react-router-dom";
import useCertificateHook from "../../hooks/useCertificateHook";
import useNProgressHook from "../../hooks/useNProgressHook";
import GetAppIcon from "@material-ui/icons/GetApp";
import SendIcon from "@material-ui/icons/Send";
import VisibilityIcon from "@material-ui/icons/Visibility";
import he from "he";
import FilterByBatch from "./filterByBatch";
import { downloadBatchFilteredExcelFile, downloadBatchFilteredPDFFile } from "./viewCredentialUtils";
import { useSnackbar } from "notistack";
import { Table, Container, Box, Card, Typography as Text } from "@cti-workspace/ui";
import { userStore } from "../../store/userStore";
import { getErrMsg, disableIssuedCredentialView, getObjectValue, USER_ROLES, notAllowedToRevoke, REVOKED_LIST, convertIssuedTimeToLocal, postApi, ISSUER_PREFIX } from "@cti-workspace/helpers";
import { usePopperTooltip } from "react-popper-tooltip";
import styled from "styled-components";
import { Alarm, Check, CircleNotch, Clock, Warning, X } from "@phosphor-icons/react";
import CapabilityBoundary from "../CapabilityBoundary/CapabilityBoundary";
import CAPABILITIES from "../../capabilities";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { isCapable } from "../../routes/admin/userAccounts/permissionUtils";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import qrImg from "../../assets/images/qr.svg";
import QRCode from "qrcode.react";
import cookie from "js-cookie";
import { formatInTimeZone } from "date-fns-tz";

const useStyles = makeStyles((theme) => ({
	itemFont: {
		fontSize: "18px",
	},
}));

export default function ViewCredential() {
	const styles = useStyles();
	const [credData, setCredData] = useState({});
	const [totalItems, setTotalItems] = useState(0);
	const [searchText, setSearchText] = useState("");
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [selectedBatch, setSelectedBatch] = useState(null);
	const [loadingCredentials, setLoadingCredentials] = useState(false);
	const [publicUrl, setPublicUrl] = useState(null);

	const roles = userStore((state) => state.roles);
	const capabilities = userStore((state) => state.capabilities);
	const email = userStore((state) => state.email);
	const organizations = userStore((state) => state.organizations);
	const { getCredentialById } = useCredentialHook({ account: { roles, organizations } });

	const { getCertificatesByCredDefId, searchCertificatesByCredDefId, resendEmailForIssuedCert, revokeIssuedCert } = useCertificateHook();
	const params = useParams();
	const theme = useTheme();

	const { progressStart, progressDone } = useNProgressHook();
	const { enqueueSnackbar } = useSnackbar();

	const revokingRoleAvailable = roles?.includes(USER_ROLES.ORG_ADMIN);

	const imgExt = {
		"image/png": ".png",
		"image/jpeg": ".jpg",
	};

	useEffect(() => {
		if (params.id) {
			(async () => {
				try {
					progressStart();
					const data = await getCredentialById(params.id);
					data.additionalData = typeof data.additionalData === "object" ? data.additionalData : JSON.parse(data.additionalData);
					data.definition = typeof data.definition === "object" ? data.definition : JSON.parse(data.definition);
					const { transcript } = data.definition;
					const { description, resources = [] } = data.additionalData;
					data.transcript = transcript.map((a) => a.name);
					data.description = createMarkup(description);
					setCredData((pre) => ({ ...pre, resources, ...data }));
					progressDone();
				} catch (error) {
					progressDone();
				}
			})();
		}
	}, []);

	useEffect(() => {
		(async () => {
			try {
				setLoadingCredentials(true);
				if (searchText) {
					const { items, totalItems } = await searchCertificatesByCredDefId(params.id, searchText, pageSize, pageNumber);
					setCredData((pre) => ({
						...pre,
						recipientsList: items || [],
					}));
					setTotalItems(totalItems);
				} else {
					const { items, totalItems } = await getCertificatesByCredDefId(params.id, pageSize, pageNumber, selectedBatch);
					setCredData((pre) => ({
						...pre,
						recipientsList: items || [],
					}));
					setTotalItems(totalItems);
				}
			} catch (err) {
				console.error(err);
			} finally {
				setLoadingCredentials(false);
			}
		})();
	}, [searchText, pageNumber, pageSize, selectedBatch]);

	function createMarkup(txt) {
		let val = he.decode(txt);
		let txtz = document.createElement("textarea");
		txtz.innerHTML = val;
		return { __html: txtz.value };
	}

	const onPaginationChange = async (pageSize, pageNumber) => {
		setPageSize(pageSize);
		setPageNumber(pageNumber);
	};

	const onSearch = async (str) => {
		setSelectedBatch(null);
		setSearchText(str);
		setPageNumber(1);
	};

	const downloadBatchFilteredCredentials = async () => {
		progressStart();
		setLoadingCredentials(true);
		try {
			await downloadBatchFilteredExcelFile(params.id, selectedBatch, credData.name);
		} catch (e) {
			enqueueSnackbar(getErrMsg(null, "Failed to generate Excel file. Please try again."), { variant: "error" });
		} finally {
			progressDone();
			setLoadingCredentials(false);
		}
	};

	const downloadBatchFilteredCredentialsPdf = async () => {
		progressStart();
		setLoadingCredentials(true);
		try {
			const { issuerOrganization, _id } = credData;
			if (!email) {
				enqueueSnackbar("User email is not available.", { variant: "warning" });
				return;
			}
			await downloadBatchFilteredPDFFile([email], selectedBatch, issuerOrganization, _id);
			if (totalItems > 1000) {
				enqueueSnackbar("A maximum batch size of 1,000 credentials may be downloaded.", { variant: "warning" });
			} else {
				enqueueSnackbar("Your download has started. An email will be sent to the address on your profile when the download is complete.", { variant: "success" });
			}
		} catch (e) {
			enqueueSnackbar(getErrMsg(null, "Failed to generate PDF. Please try again."), { variant: "error" });
		} finally {
			progressDone();
			setLoadingCredentials(false);
		}
	};

	const viewIpaPdf = async (credentialId) => {
		enqueueSnackbar("Getting PDF URL. Please wait.", {
			variant: "info",
		});
		try {
			const { url } = await postApi(`/${ISSUER_PREFIX}/generateCredentialPdfLink`, {
				credentialId,
			});
			window.open(url);
		} catch (e) {
			enqueueSnackbar(getErrMsg(e, "Failed to retrieve PDF URL. Please try again"), {
				variant: "error",
			});
		}
	};

	useEffect(() => {
		setPageNumber(1);
	}, [selectedBatch]);

	useEffect(() => {
		if (publicUrl) {
			try {
				const element = document.getElementById("qrCode");
				const canvas = element.querySelector("canvas");
				const base64 = canvas.toDataURL();
				let mimeType = base64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
				const a = document.createElement("a");
				a.href = base64;
				a.download = publicUrl["recipient"] + " - QR Code" + imgExt[mimeType];
				a.click();
				setPublicUrl(null);
			} catch (err) {
				console.error(err);
			}
		}
	}, [publicUrl]);

	const qrDownload = (row) => {
		setPublicUrl(row);
	};

	const addToRevokedCredList = (id) => {
		const val = cookie.get(REVOKED_LIST);
		const revokedList = val ? JSON.parse(val) : [];
		cookie.set(REVOKED_LIST, JSON.stringify([...revokedList, id]));
	};

	const onRevoke = async (val) => {
		try {
			if (revokeIssuedCert) {
				await revokeIssuedCert(val.id);
				addToRevokedCredList(val.id);
				setTimeout(() => {
					setCredData((prevValue) => ({
						...prevValue,
						recipientsList: prevValue.recipientsList.map((event) => ({
							...event,
							...(event.id === val.id ? { state: "revoked" } : {}),
						})),
					}));
				}, 100);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const ToolTipEllipsis = ({ title, children }) => {
		return (
			<ToolTip
				title={title}
				containerStyle={{
					whiteSpace: "nowrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
				}}>
				{children}
			</ToolTip>
		);
	};

	return (
		<>
			<div style={{ minHeight: 56 }} />
			<Container maxWidth="lg">
				<Card hasBorder elevation={6} style={{ padding: "0.9rem", display: "flex", flexDirection: "column", gap: "1rem" }} borderRadius="10px">
					<Text type="subtitle">View Credential Recipients - {credData.name}</Text>
				</Card>
				<Card hasBorder elevation={6} style={{ padding: "0.9rem", display: "flex", flexDirection: "column", gap: "1rem" }} borderRadius="10px">
					<Table
						config={{
							headers: [
								{
									label: "Issued On",
									prop: "issuedOn",
									// width: "12rem",
									cell: (row) => {
										const localeTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
										const issuedOnDate = row.issuedOn && formatInTimeZone(new Date(row.issuedOn), localeTimeZone, "PP hh:mm a");
										return <ToolTipEllipsis title={issuedOnDate}>{issuedOnDate}</ToolTipEllipsis>;
									},
								},
								{
									label: "Batch",
									prop: "batchLabel",
									// width: "5rem",
									cell: (row) => <ToolTipEllipsis title={row.batchLabel}>{row.batchLabel}</ToolTipEllipsis>,
								},
								{
									label: "Recipient Name",
									prop: "recipientName",
									// width: "10rem",
									cell: (row) => <ToolTipEllipsis title={row.recipientName}>{row.recipientName}</ToolTipEllipsis>,
								},
								{
									label: "Identifier",
									prop: "recipient",
									// minWidth: "14rem",
									cell: (row) => <ToolTipEllipsis title={row.recipient}>{row.recipient}</ToolTipEllipsis>,
								},
								{
									label: "Status",
									prop: "",
									sort: false,
									width: "75px",
									cell: (row) => {
										let creWillExp = false;
										let creExpired = false;
										if (row.expireAt) {
											var expiredOn = row.expireAt.match(/(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g);
											let givenDate = new Date(expiredOn[0]);
											let diff = new Date().getTime() - givenDate.getTime();
											if (diff < 0) {
												creWillExp = true;
											}

											if (diff > 0) {
												creExpired = true;
											}
										}
										return row.state == "revoked" ? (
											<ToolTip title="This credential has been revoked.">
												<IconButton style={{ color: "grey" }} size="small">
													<Warning size={25} />
												</IconButton>
											</ToolTip>
										) : row.state == "deleted" ? (
											<ToolTip title="This credential has been deleted by the Holder.">
												<IconButton style={{ color: "rgb(231, 73, 75)" }} size="small">
													<X size={25} />
												</IconButton>
											</ToolTip>
										) : row.state == "pending" ? (
											<ToolTip title="This credential is ready for pickup.">
												<IconButton style={{ color: "#9d49ee" }} size="small">
													<CircleNotch size={25} style={{ position: "absolute" }} />
													<Check size={16} style={{ position: "absolute" }} />
												</IconButton>
											</ToolTip>
										) : creExpired ? (
											<ToolTip title={`This credential expired on ${expiredOn[0]}`}>
												<IconButton style={{ color: "grey" }} size="small">
													<Alarm size={25} />
												</IconButton>
											</ToolTip>
										) : creWillExp ? (
											<ToolTip title={`This credential will expire on ${expiredOn[0]}`}>
												<IconButton style={{ color: "#9d49ee" }} size="small">
													<Clock size={25} />
												</IconButton>
											</ToolTip>
										) : (
											<ToolTip title="This credential is active.">
												<IconButton style={{ color: "#9d49ee" }} size="small">
													<Check size={25} />
												</IconButton>
											</ToolTip>
										);
									},
								},
								{
									label: "Actions",
									prop: "",
									width: "200px",
									cell: (row) => {
										return (
											<>
												<ToolTip title="Download">
													<Link href={`data:text/json;charset=utf-8,${encodeURIComponent(row.rawCertificate)}`} download={`${row.recipient}-${row.credentialName}.json`}>
														<IconButton color="secondary" size="small">
															<GetAppIcon fontSize="small" />
														</IconButton>
													</Link>
												</ToolTip>
												{navigator && (
													<CapabilityBoundary
														render={(disabled) => (
															<ToolTip title="Copy URL">
																<IconButton
																	disabled={disabled || !getObjectValue(row, "publicUrl")}
																	color="secondary"
																	size="small"
																	onClick={() => {
																		navigator && navigator.clipboard.writeText(getObjectValue(row, "shortURLs.publicUrl", getObjectValue(row, "publicUrl")));
																		enqueueSnackbar("Url copied to clipboard", {
																			variant: "success",
																		});
																	}}>
																	<FileCopyIcon fontSize="small" />
																</IconButton>
															</ToolTip>
														)}
														capability={CAPABILITIES.COPY_URL_ISSUED_CREDENTIALS_FOR_ORG}
													/>
												)}
												<CapabilityBoundary
													render={(disabled) => (
														<ToolTip title="View">
															<IconButton disabled={disabled || disableIssuedCredentialView(row)} color="secondary" onClick={row.pdfKey ? () => viewIpaPdf(row.id) : () => window.open(`${row.publicUrl}`)} size="small">
																<VisibilityIcon fontSize="small" />
															</IconButton>
														</ToolTip>
													)}
													capability={CAPABILITIES.VIEW_ISSUED_CREDENTIALS_FOR_ORG}
												/>
												<CapabilityBoundary
													render={(disabled) => (
														<ToolTip title="Resend">
															<IconButton disabled={disabled} color="secondary" onClick={() => resendEmailForIssuedCert && resendEmailForIssuedCert(row.id)} size="small">
																<SendIcon fontSize="small" />
															</IconButton>
														</ToolTip>
													)}
													capability={CAPABILITIES.RESEND_ISSUED_CREDENTIALS_FOR_ORG}
												/>
												<CapabilityBoundary
													render={(disabled) => (
														<span title={!revokingRoleAvailable || !isCapable(capabilities, CAPABILITIES.REVOKE_ISSUED_CREDENTIALS_FOR_ORG) ? "You don’t have permission to revoke" : ""}>
															<ToolTip title="Revoke">
																<IconButton color="secondary" onClick={() => onRevoke(row)} size="small" disabled={!revokingRoleAvailable || notAllowedToRevoke(row) || disabled}>
																	<HighlightOffIcon fontSize="small" />
																</IconButton>
															</ToolTip>
														</span>
													)}
													capability={CAPABILITIES.REVOKE_ISSUED_CREDENTIALS_FOR_ORG}
												/>
												<CapabilityBoundary
													render={(disabled) => (
														<ToolTip title="Download QR">
															<IconButton disabled={disabled} color="secondary" onClick={() => qrDownload(row)} size="small">
																<img src={qrImg} style={{ width: "15px" }} />
															</IconButton>
														</ToolTip>
													)}
													capability={CAPABILITIES.DOWNLOAD_QR_CODE_ISSUED_CREDENTIALS_FOR_ORG}
												/>
											</>
										);
									},
								},
							],
						}}
						data={credData.recipientsList}
						pagination
						showSearch={false}
						leftHeader={() => (
							<div style={{ marginBottom: "10px" }}>
								<FilterByBatch
									batchLabels={credData?.credentialIssueLabels || []}
									setSelectedBatch={setSelectedBatch}
									disabled={loadingCredentials}
									selectedBatch={selectedBatch}
									downloadBatchFilteredCredentials={downloadBatchFilteredCredentials}
									downloadBatchFilteredCredentialsPdf={downloadBatchFilteredCredentialsPdf}
								/>
							</div>
						)}
						totalRows={totalItems}
						onChangePage={(page) => onPaginationChange(pageSize, page)}
						onChangeRowsPerPage={(pageSize) => setPageSize(pageSize)}
						loading={loadingCredentials}
					/>
					<div id="qrCode" style={{ display: "none" }}>
						<QRCode value={(publicUrl && publicUrl?.publicUrl) || ""} />
					</div>
				</Card>
				{/* <Hidden only={["xs", "sm"]}>
					<Paper
						style={{
							padding: theme.spacing(2),
							width: "100%",
							display: "flex",
							justifyContent: "space-between",
						}}>
						<Box>
							<HeaderWithLIne title={"View Credential"} variant={"h6"} />
						</Box>
					</Paper>
				</Hidden>
				<Box mt={1} borderColor="primary" border={8}>
					<Paper style={{ padding: theme.spacing(2) }}>
						<HeaderWithLIne title={"Details"} variant={"h6"} />
						<Grid container spacing={1} style={{ padding: "15px 0px" }}>
							<Grid
								item
								xs={12}
								md={3}
								style={{
									display: "flex",
									justifyContent: "flex-end",
									alignItems: "center",
									paddingRight: "15px",
								}}>
								<Typography className={styles.itemFont}>Badge</Typography>
							</Grid>
							<Grid item xs={12} md={9}>
								<img src={credData.imageUrl} height={60} />
							</Grid>
						</Grid>
						<Divider />
						<Grid container spacing={1} style={{ padding: "15px 0px" }}>
							<Grid
								item
								xs={12}
								md={3}
								style={{
									display: "flex",
									justifyContent: "flex-end",
									alignItems: "center",
									paddingRight: "15px",
								}}>
								<Typography className={styles.itemFont}>Name</Typography>
							</Grid>
							<Grid id="cred-name" item xs={12} md={9}>
								<Typography className={styles.itemFont}>{credData.name}</Typography>
							</Grid>
						</Grid>
						<Divider />
						{credData.version && (
							<>
								<Grid container spacing={1} style={{ padding: "15px 0px" }}>
									<Grid
										item
										xs={12}
										md={3}
										style={{
											display: "flex",
											justifyContent: "flex-end",
											alignItems: "center",
											paddingRight: "15px",
										}}>
										<Typography className={styles.itemFont}>Version</Typography>
									</Grid>
									<Grid id="cred-des" item xs={12} md={9}>
										<Typography className={styles.itemFont}>{credData.version || "1"}</Typography>
									</Grid>
								</Grid>
								<Divider />
							</>
						)}
						{credData.description && (
							<>
								<Grid container spacing={1} style={{ padding: "15px 0px" }}>
									<Grid
										item
										xs={12}
										md={3}
										style={{
											display: "flex",
											justifyContent: "flex-end",
											paddingRight: "15px",
										}}>
										<Typography className={styles.itemFont}>Description</Typography>
									</Grid>
									<Grid id="cred-des" item xs={12} md={9} style={{ paddingTop: ".8rem" }}>
										<div className={(styles.itemFont, "credential-description")} dangerouslySetInnerHTML={credData.description} />
									</Grid>
								</Grid>
								<Divider />
							</>
						)}
						{credData.definition && credData.definition.transcript && credData.definition.transcript.length > 0 && (
							<Box style={{ marginTop: "25px" }}>
								<HeaderWithLIne title="Content" variant={"h6"} />
								{credData.definition.transcript.map((transcriptField, i) => (
									<Grid container spacing={1} key={i}>
										<Grid item xs={12} md={3} />
										<Grid item xs={12} md={9}>
											<Typography className={styles.itemFont}>{transcriptField.name}</Typography>
											{credData.definition.transcript.length - 1 > i && (
												<Divider
													style={{
														marginTop: "10px",
													}}
												/>
											)}
										</Grid>
									</Grid>
								))}
							</Box>
						)}
						{credData.additionalData && (credData.additionalData.sections || []).length > 0 && (
							<Box style={{ marginTop: "25px" }}>
								<HeaderWithLIne title="Sections" variant={"h6"} />
								{(credData.additionalData.sections || []).map((section, i) => (
									<Grid container spacing={1} key={i}>
										<Grid item xs={12}>
											<Box padding={2} elevation={1} marginBottom={2}>
												<Grid container>
													<Grid
														item
														xs={12}
														md={3}
														style={{
															display: "flex",
															justifyContent: "flex-end",
															paddingRight: "15px",
														}}>
														<Typography className={styles.itemFont}>Name</Typography>
													</Grid>
													<Grid item xs={12} md={9}>
														<Typography className={styles.itemFont}>{section.name}</Typography>
													</Grid>
													{section.description && (
														<>
															<Grid
																item
																xs={12}
																md={3}
																style={{
																	display: "flex",
																	justifyContent: "flex-end",
																	paddingRight: "15px",
																}}>
																<Typography className={styles.itemFont}>Description</Typography>
															</Grid>
															<Grid item xs={12} md={9}>
																<Typography className={styles.itemFont}>{section.description}</Typography>
															</Grid>
														</>
													)}
												</Grid>
												<Grid container>
													<Grid item xs={12} md={3} />
													<Grid item xs={12} md={9}>
														<HeaderWithLIne title="Claims" className={styles.itemFont} />
													</Grid>
													{section.claims.map((claim, index) => (
														<Fragment key={index}>
															<Grid item xs={12} md={3} />
															<Grid item xs={12} md={9}>
																<Typography className={styles.itemFont}>{claim}</Typography>
															</Grid>
														</Fragment>
													))}
												</Grid>
											</Box>
										</Grid>
									</Grid>
								))}
							</Box>
						)}
						{credData.resources && credData.resources.length > 0 && (
							<Box style={{ marginTop: "25px" }}>
								<HeaderWithLIne title={"Resources"} variant={"h6"} />
								<i>(Click to download)</i>
								{credData.resources.map((resource, i) => (
									<Grid container spacing={1} key={i}>
										<Grid item xs={12} md={3} />
										<Grid item xs={12} md={9}>
											<URLTextDownload resource={resource} />
											{credData.resources.length - 1 > i && (
												<Divider
													style={{
														marginTop: "10px",
													}}
												/>
											)}
										</Grid>
									</Grid>
								))}
							</Box>
						)}
						<Box style={{ marginTop: "25px" }}>
							<HeaderWithLIne title={"Credential Recipients"} variant={"h6"} />
							<div style={{ marginTop: "15px" }}>
								<Table
									config={{
										headers: [
											{
												label: "ID",
												prop: "id",
												cell: (row) => (
													<a
														href={`data:text/json;charset=utf-8,${encodeURIComponent(row.rawCertificate)}`}
														download={`${row.recipient}-${row.credentialName}.json`}
														style={{ fontSize: "14px", whiteSpace: "normal", textAlign: "center", padding: "5px 3px" }}>
														<b>
															<u>{row.id}</u>
														</b>
													</a>
												),
												width: "10rem",
												center: true,
											},
											{
												label: "Version",
												prop: "credDefVersion",
												width: "5rem",
											},
											{
												label: "Batch",
												prop: "batchLabel",
												width: "5rem",
											},
											{
												label: "Recipient Name",
												prop: "recipientName",
												width: "10rem",
												cell: (row) => <div style={{ textAlign: "center" }}>{row.recipientName}</div>,
											},
											{
												label: "Identifier",
												prop: "recipient",
												minWidth: "14rem",
												cell: (row) => <div>{row.recipient}</div>,
											},
											{
												label: "Timestamp",
												prop: "issuedOn",
												width: "12rem",
											},
											{
												label: "",
												prop: "",
												cell: (row) => {
													return (
														<>
															<Tooltip title="Download">
																<IconButton color="secondary" size="small">
																	<Button
																		style={{
																			padding: "0px",
																			margin: "0px",
																			minWidth: "0px",
																		}}
																		color="secondary"
																		variant="text"
																		href={`data:text/json;charset=utf-8,${encodeURIComponent(row.rawCertificate)}`}
																		download={`${row.recipient}-${row.credentialName}.json`}>
																		<GetAppIcon fontSize="small" />
																	</Button>
																</IconButton>
															</Tooltip>
															<Tooltip title="View">
																<IconButton color="secondary" onClick={row.pdfKey ? () => viewIpaPdf(row.id) : () => window.open(`${row.publicUrl}`)} size="small" disabled={disableIssuedCredentialView(row)}>
																	<VisibilityIcon fontSize="small" />
																</IconButton>
															</Tooltip>
															<Tooltip title="Resend">
																<IconButton color="secondary" onClick={() => resendEmailForIssuedCert && resendEmailForIssuedCert(row.id)} size="small">
																	<SendIcon fontSize="small" />
																</IconButton>
															</Tooltip>
														</>
													);
												},
											},
										],
									}}
									data={credData.recipientsList}
									pagination
									showSearch={false}
									leftHeader={() => (
										<div style={{ marginBottom: "10px" }}>
											<FilterByBatch
												batchLabels={credData?.credentialIssueLabels || []}
												setSelectedBatch={setSelectedBatch}
												disabled={loadingCredentials}
												selectedBatch={selectedBatch}
												downloadBatchFilteredCredentials={downloadBatchFilteredCredentials}
												downloadBatchFilteredCredentialsPdf={downloadBatchFilteredCredentialsPdf}
											/>
										</div>
									)}
									totalRows={totalItems}
									onChangePage={(page) => onPaginationChange(pageSize, page)}
									onChangeRowsPerPage={(pageSize) => setPageSize(pageSize)}
									loading={loadingCredentials}
								/>
							</div>
						</Box>
					</Paper>
				</Box> */}
			</Container>
		</>
	);
}

function URLTextDownload({ resource }) {
	let resourceUrlRef = createRef();
	const styles = useStyles();
	return (
		<>
			<a
				aria-label="Download"
				href={resource.url}
				ref={(ref) => {
					resourceUrlRef = ref;
				}}
				download></a>
			<Typography
				className={styles.itemFont}
				onClick={() => {
					resourceUrlRef.click();
				}}
				style={{
					cursor: "pointer",
					textDecoration: "underline",
					textDecorationColor: "blue",
				}}>
				{resource.id}
			</Typography>
		</>
	);
}

const TipText = styled.span`
	font-family: Roboto;
	font-weight: 400;
	font-size: 0.7rem;
	line-height: 0.9rem;
	color: rgba(255, 255, 255, 0.87);
`;

function ToolTip({ children = null, title = "", containerStyle = {} }) {
	const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({ placement: "top" });
	return (
		<>
			<div ref={setTriggerRef} style={containerStyle}>
				{children}
			</div>
			{visible && (
				<div ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container", style: { backgroundColor: "#606060", borderRadius: "8px" } })}>
					<TipText>{title}</TipText>
				</div>
			)}
		</>
	);
}
