import { IconButton, Tooltip } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CapabilityBoundary from "../../components/CapabilityBoundary/CapabilityBoundary";
import CAPABILITIES from "../../capabilities";

const ExpandAnalytics = ({ id, onClickExplore }) => {
	const handleOnClickExplore = () => {
		onClickExplore();
	};

	return (
		<>
			<Tooltip title="Explore">
				<CapabilityBoundary
					render={(disabled) => (
						<IconButton size="small" onClick={handleOnClickExplore} disabled={disabled}>
							<SearchIcon fontSize="small" />
						</IconButton>
					)}
					capability={CAPABILITIES.ACCESS_ADVANCE_ANALYTICS}
				/>
			</Tooltip>
		</>
	);
};

export default ExpandAnalytics;
