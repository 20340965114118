import { forwardRef, useRef, useImperativeHandle, useLayoutEffect } from "react";
import { PathChart, ChartCard } from "@cti-workspace/ui";
import { OrgChart } from "./newChart";
import * as d3 from "d3";
import * as ReactDOMServer from "react-dom/server";

export const PathwayDesigner = forwardRef(({ data = null, onNodeClick }, ref) => {
	const chartRef = useRef(null);
	let chart = null;

	// console.log("first");

	// #9C49ED

	useLayoutEffect(() => {
		if (data && chartRef.current) {
			chart = new OrgChart();
			chart
				.container(chartRef.current)
				.data(data)
				.layout("right")
				.duration(600)
				.nodeWidth(() => 246)
				.nodeHeight(() => 80)
				.siblingsMargin((d) => 50)
				.childrenMargin((d) => 75)
				.neightbourMargin((n1, n2) => 100)
				.childrenMargin((d) => 60)
				.compactMarginBetween((d) => 35)
				.compactMarginPair((d) => 80)
				.onNodeClick((d, i, arr) => {
					onNodeClick(d);
				})
				.nodeContent((node, ...rest) => ReactDOMServer.renderToStaticMarkup(ChartCard(...node)))
				.nodeUpdate(function (d, i, arr) {
					d3.select(this)
						.select(".node-rect")
						.attr("stroke", (d) => (d.data._highlighted || d.data._upToTheRootHighlighted ? "#9C49ED" : "none"))
						.attr("stroke-width", (d) => (d.data._highlighted || d.data._upToTheRootHighlighted ? 3 : 0));
				})
				.render();
		}
	}, [data, onNodeClick]);

	useImperativeHandle(ref, () => ({
		addNode(val) {
			chart.addNode(val);
		},
		setCentered(val) {
			chart.setCentered(val).expandAll().render();
		},
		setHighlighted(val) {
			chart.setHighlighted(val).render();
		},
		setSelected(val) {
			chart.setSelected(val).render();
		},
		setSelectedWithChildren(val) {
			chart.setSelectedWithChildren(val).render();
		},
		compact() {
			chart.compact(3);
		},
		setUpToTheRootHighlighted(val) {
			// chart.setUpToTheRootHighlighted("O-6162").render().fit();
		},
		clearHighlighting() {
			chart.clearHighlighting();
		},
		removeNode(val) {
			chart.removeNode(val);
		},
		expandAll() {
			chart.expandAll();
		},
		fit() {
			chart.fit();
		},
	}));

	return <PathChart ref={chartRef} />;
});

// nodeContent => card
// buttonContent => purple dot, where you add a child node
