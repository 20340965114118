import { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import HeaderWithLine from "../../components/HeaderWithLine";
import { useSnackbar } from "notistack";
import useNProgressHook from "../../hooks/useNProgressHook";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Box } from "@cti-workspace/ui";
import OrgDropDown from "../../components/OrgDropDown/OrgDropDown";
import { getErrMsg, ORGANIZATIONS_PREFIX, SETTINGS, postApi, getObjectValue, getOrganization } from "@cti-workspace/helpers";

const Settings = () => {
	const { enqueueSnackbar } = useSnackbar();
	const { progressStart, progressDone } = useNProgressHook();
	const [orgs, setOrgs] = useState([]);
	const [orgsName, setOrgsName] = useState([]);
	const [selectedOrgId, setSelectedOrgId] = useState(0);
	const [orgError, setOrgError] = useState(false);
	const orgRef = useRef(null);

	useEffect(() => {
		getOrganizations();
	}, []);
	const getOrganizations = async () => {
		let { items } = await getOrganization();
		setOrgs(items);
		let orgList = [];
		items.map((org) => {
			if (org.name) {
				orgList.push(org);
			}
		});
		setOrgsName(orgList);
	};
	const { register, control, handleSubmit, errors } = useForm({
		defaultValues: {
			settings: JSON.stringify({
				lms: true,
				bulkIssue: true,
				analytics: false,
				whitelabel: {},
				canvasLmsKeys: [{ API_URL: "", API_VERSION: "", APP_REDIRECT_URI: "", CANVAS_URL: "", ACCOUNT_TYPE: "", ACCESS_TYPE: "", ACCESS_TOKEN: "", EMAIL: "" }],
				schoologyKeys: { API_URL: "", APP_URL: "", CLIENT_KEY: "", CLIENT_SECRET: "", EMAIL: "" },
				bridgeLmsKeys: { API_URL: "", APP_URL: "", API_KEY: "", EMAIL: "" },
				tcsIONKeys: { AUTH_URL: "", API_URL: "", USERNAME: "", PASSWORD: "" },
				moodleKeys: { AUTH_URL: "", WEBSERVICE_URL: "", USERNAME: "", PASSWORD: "", SERVICE: "" },
				transcriptWatermark: true,
				nftContract: false,
				enableNft: false,
				enableAuthenticationOptions: false,
				enableAuthorization: false,
				enablePathways: false,
				verifierConfiguration: {
					disablePrint: false,
					mobileDeviceHideRenderer: false,
					mobileDeviceHideRendererMessage: "Please view on larger device",
					hideContent: false,
					hideSections: false,
					hideResources: false,
					disableAddToLinkedin: false,
				},
			}),
		},
	});

	useEffect(() => {
		if (selectedOrgId !== 0) {
			setOrgError(false);
		}
	}, [selectedOrgId]);

	const onSubmit = async (params) => {
		if (params.orgId !== 0) {
			try {
				progressStart();
				let data = await postApi(`/${ORGANIZATIONS_PREFIX}/updateSettings`, params);
				localStorage.setItem(SETTINGS, JSON.stringify(params));
				getOrganizations();
				enqueueSnackbar(`Added: ${JSON.stringify(params)}`, {
					variant: "success",
				});
				progressDone();
			} catch (error) {
				progressDone();
				enqueueSnackbar(getErrMsg(error), {
					variant: "error",
				});
				console.log(error);
			}
		} else {
			setOrgError(true);
		}
	};

	return (
		<>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Box mx="auto" p={-2}>
						<HeaderWithLine title={"Settings"} variant={"h6"} />
					</Box>
				</Grid>
				<Grid item xs={12} md={6}>
					{/* <OrgAutoComplete dataList={orgsName} selectedOrgId={setSelectedOrgId} orgError={orgError}/> */}
					<OrgDropDown onChange={setSelectedOrgId} ref={orgRef} required />
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField inputRef={register()} variant="outlined" name="settings" fullWidth label="Settings" />
				</Grid>
				<Grid item xs={12}>
					<Button
						onClick={handleSubmit(async ({ orgId, settings }) => {
							onSubmit({
								orgId: selectedOrgId,
								settings: JSON.parse(settings),
							});
						})}
						variant="contained"
						color="primary"
						fullWidth>
						Submit
					</Button>
				</Grid>
			</Grid>
			<Box marginTop={2}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell component="th" style={{ fontWeight: "bold" }} width={250}>
								Organization
							</TableCell>
							<TableCell component="th" style={{ fontWeight: "bold" }}>
								Settings
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{orgs.map((item, i) => (
							<TableRow key={`tr-org-settings-${i}`}>
								<TableCell>{getObjectValue(item, "name")}</TableCell>
								<TableCell style={{ wordBreak: "break-word" }}>{JSON.stringify(getObjectValue(item, "settings") || {}, null, 2)}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Box>
		</>
	);
};

Settings.propTypes = {};

export default Settings;
