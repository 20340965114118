function getValueFromStr(str) {
    return str?.split(/:string:|:boolean:|:null:/).pop();
}

export default function transformOldToNewCredResp(credResp) {
    return {
        ...credResp,
        vc: {
            "@context": [],
            "id": getValueFromStr(credResp?.data?.id),
            "name": getValueFromStr(credResp?.data?.name),
            description: getValueFromStr(credResp?.data?.additionalData?.description),
            type: ["VerifiableCredential"],
            issuer: {
                name: getValueFromStr(credResp?.data?.issuers[0]?.name),
                url: getValueFromStr(credResp?.data?.issuers[0]?.url),
                id: "",
            },
            credentialSubject: {
                name: getValueFromStr(credResp?.data?.recipient?.name),
                results: (credResp?.transcript || []).map(item => ({
                    name: getValueFromStr(item.name),
                    value: getValueFromStr(item.grade || ""),
                })),
                id: "",
            },
            additionalData: {
                imageUrl: getValueFromStr(credResp?.data?.additionalData?.imageUrl),
                issuerImageUrl: getValueFromStr(credResp?.data?.additionalData?.issuerImageUrl),
                resources: (credResp?.data?.additionalData?.resources || []).map(item => ({
                    id: getValueFromStr(item.id),
                    url: getValueFromStr(item.url),
                })),
                sections: [],
            },
            credentialStatus: {
                id: "",
                type: "",
                statusListIndex: "",
                statusListCredential: "",
            },
            issuanceDate: getValueFromStr(credResp?.data?.issuedOn),
            expirationDate: getValueFromStr(credResp?.data?.expireAt),
            proof: {
                ...(credResp.proof || {}),
                jws: "",
            },
            recipient: {
                name: getValueFromStr(credResp?.data?.recipient?.name),
                email: getValueFromStr(credResp?.data?.recipient?.email),
            }
        }
    }
}
