import React, { useEffect } from "react";
import styled from "styled-components";
import HeaderWithLine from "../HeaderWithLine";
import { Box, Button, Card } from "@cti-workspace/ui";
import { Grid, Typography } from "@material-ui/core";
import { getErrMsg, getObjectValue, useVerifierRegisterHook } from "@cti-workspace/helpers";
import { useSnackbar } from "notistack";

const Title = styled.div`
	background-color: #9c27b0;
	text-align: center;
	margin: 0;
	border-radius: 4px 4px 0 0;
	color: #fff;
	padding: 5px 0px;
`;

const OrgLogo = styled.img`
	max-height: 100px;
	max-width: 80%;
`;

const ButtonWrapper = styled.div`
	display: flex;
	padding: 1rem;

	> div {
		margin-right: 0.5rem;
	}
`;

const Table = styled.table`
	margin: 1rem;
`;

const TableCell = styled.td`
	padding: 3px;
	width: ${(props) => (props.fullWidth ? "100%" : "auto")};
`;

function CardComponent({ data = {}, onApprove = () => {}, onReject = () => {} }) {
	const orgTypeObj = {
		verifier: "Verifier",
		issuer: "Issuer",
	};

	return (
		<Grid item xs={12} sm={6} md={4}>
			<Card style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", height: "100%" }}>
				<div>
					<Title>
						<Typography variant="h6">{getObjectValue(orgTypeObj, data.orgType)}</Typography>
					</Title>
					<Table>
						<tbody>
							<tr>
								<TableCell>
									<Typography variant="body2" style={{ fontWeight: "bold" }}>
										Name:
									</Typography>
								</TableCell>
								<TableCell fullWidth>
									<Typography variant="body2" style={{ wordBreak: "break-word" }}>
										{data.name}
									</Typography>
								</TableCell>
							</tr>
							<tr>
								<TableCell>
									<Typography variant="body2" style={{ fontWeight: "bold" }}>
										Website:
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body2" style={{ wordBreak: "break-word" }}>
										{data.websiteURL}
									</Typography>
								</TableCell>
							</tr>
							<tr>
								<TableCell>
									<Typography variant="body2" style={{ fontWeight: "bold" }}>
										Email:
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body2" style={{ wordBreak: "break-word" }}>
										{data.orgEmail}
									</Typography>
								</TableCell>
							</tr>
							<tr>
								<TableCell>
									<Typography variant="body2" style={{ fontWeight: "bold" }}>
										Logo:
									</Typography>
								</TableCell>
								<TableCell>
									<OrgLogo src={data.logo} />
								</TableCell>
							</tr>
						</tbody>
					</Table>
				</div>
				<ButtonWrapper>
					<Button color="secondary" fullWidth={false} onClick={onReject} invert>
						Reject
					</Button>
					<Button color="secondary" fullWidth={false} onClick={onApprove}>
						Approve
					</Button>
				</ButtonWrapper>
			</Card>
		</Grid>
	);
}

function PendingApprovals() {
	const { listPendingApprovals, pendingList, totalItems, processApproval } = useVerifierRegisterHook();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		listPendingApprovals().catch((error) => {
			console.error(error);
			enqueueSnackbar(getErrMsg(error), { variant: "error" });
		});
	}, []);

	const handleApproveReject = async (id, action) => {
		try {
			const { message } = await processApproval(id, action);
			await listPendingApprovals();
			enqueueSnackbar(message || "Success!", { variant: "success" });
		} catch (error) {
			console.error(error);
			enqueueSnackbar(getErrMsg(error), { variant: "error" });
		}
	};

	return (
		<div style={{ minHeight: "10rem" }}>
			<Box mx="auto" marginBottom="1rem">
				<HeaderWithLine title={`Pending Approvals ${totalItems ? `(${totalItems})` : ""}`} variant={"h6"} />
			</Box>

			<Grid container spacing={6}>
				{pendingList.map((item) => {
					return <CardComponent key={item._id} data={item} onApprove={() => handleApproveReject(item._id, "approved")} onReject={() => handleApproveReject(item._id, "rejected")} />;
				})}
			</Grid>
		</div>
	);
}

export default PendingApprovals;
