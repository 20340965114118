import { useEffect, useState } from "react";
import { CREDENTIAL_DEFINITIONS, ISSUE_CREDENTIALS, fetchOrgEmailTemplates } from "@cti-workspace/helpers";
import { Card, ComboBox, Typography as Text } from "@cti-workspace/ui";

const SelectEmailTemplate = ({ selectedEmailTemplate, setSelectedEmailTemplate, organizationId, isEditing }) => {
	const [emailTemplates, setEmailTemplates] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const orgAndCredDefTemplates = await Promise.all([
				fetchOrgEmailTemplates(organizationId, ISSUE_CREDENTIALS),
				fetchOrgEmailTemplates(organizationId, ISSUE_CREDENTIALS, CREDENTIAL_DEFINITIONS)
			])
			setEmailTemplates(orgAndCredDefTemplates[0].concat(orgAndCredDefTemplates[1]));
			if (!isEditing) {
				setSelectedEmailTemplate(orgAndCredDefTemplates[0]?.[0]?._id);
			}
		};
		fetchData();
	}, []);

	return (
		emailTemplates?.length > 0 ?
			<Card hasBorder elevation={6} style={{ padding: "0.9rem", display: "flex", flexDirection: "column", gap: "1rem" }} borderRadius="10px">
				<Card hasBorder elevation={0} borderRadius="2px" style={{ backgroundColor: "#FAFAFA", padding: "0.8rem", width: "100%", margin: "0" }}>
					<Text type="body1">
						Select Email Template
					</Text>
					<div style={{ display: "grid", gap: "0.3rem", gridTemplateColumns: "1fr 1fr" }}>
						<ComboBox
							size="small"
							style={{ marginTop: "0.3rem", flexGrow: 1 }}
							placeholder=""
							options={[{ _id: "", name: "None" }, ...emailTemplates].filter(item => item)}
							getOptionValue={(item) => item["_id"]}
							getOptionLabel={(item) => item.name}
							onChange={(e) => setSelectedEmailTemplate(e["_id"])}
							value={[{ _id: "", name: "None" }, ...emailTemplates].find(item => item["_id"] == selectedEmailTemplate || "")}
						/>
						<div />
					</div>
				</Card>
			</Card> : null
	);
};

export default SelectEmailTemplate;
