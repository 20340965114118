import { Routes, Route, Outlet, useParams, useLocation, useNavigate, useRouteError } from "react-router-dom";
import { ViewPathwayWrapper, PathwayDetailsSection, PathwayBuilderSection, TopNavBar, ErrorWrapper } from "./components/pathwayComponents";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useRef, useState } from "react";
import { PathwayDesigner } from "./pathwayDesigner";

import ViewPathway from "./subRoutes/viewPathway";
import EditPathway from "./subRoutes/editPathway";
import DeletePathway from "./subRoutes/deletePathway";
import AddStepPathway from "./subRoutes/addStep";
import { getApi, PATHWAY_PREFIX, useNProgressHook } from "@cti-workspace/helpers";
import { useSnackbar } from "notistack";
import errorBug from "../../assets/images/error_bug.png";
import { Spinner } from "@cti-workspace/ui";
import equal from "fast-deep-equal";

const PathWrapper = ({ childRef, data }) => {
	let arr = [...data.steps];
	const steps = arr.map((o) => ({ ...o, id: o.stepId }));

	let navigate = useNavigate();
	const params = useParams();

	function onNodeClick(nodeId) {
		// childRef.current.setCentered(nodeId);

		var newArray = steps.filter(function (el) {
			return el.id === nodeId;
		});
		let val = [...newArray][0];
		if (val.level >= 1) {
			navigate(`${val.id}`, { replace: true });
			// childRef.current.setSelected(nodeId);
		} else {
			const { id } = params;
			navigate(`/pathway/${id}`, { replace: true });
		}
	}

	return (
		<ViewPathwayWrapper>
			<PathwayDetailsSection>{steps?.length >= 0 && <Outlet context={[childRef, steps]} />}</PathwayDetailsSection>
			<PathwayBuilderSection>
				<TopNavBar />
				{steps?.length >= 0 && <PathwayDesigner ref={childRef} data={steps} onNodeClick={onNodeClick} />}
			</PathwayBuilderSection>
		</ViewPathwayWrapper>
	);
};

const PathwayError = () => {
	let error = useRouteError();
	console.error(error);
	return (
		<ErrorWrapper>
			<div />
			<div
				style={{
					display: "flex",
					alignItems: "center",
					height: "100vh",
					flexDirection: "column",
				}}>
				<TopNavBar />
				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
						height: "100vh",
						flexDirection: "column",
					}}>
					<h1> Unhandled Error! Please contact support@globaledfoundation.com </h1>
					<img style={{ height: "80vh" }} src={errorBug} alt="bug" />
				</div>
			</div>
		</ErrorWrapper>
	);
};

const PathwayRoutes = () => {
	const { progressStart, progressDone } = useNProgressHook();
	const childRef = useRef();
	const { id } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	let navigate = useNavigate();
	const { isLoading, data, isFetching } = useQuery(
		["pathway", id],
		async () => {
			try {
				progressStart();
				const { pathway } = await getApi(`/${PATHWAY_PREFIX}/${id}`);
				return pathway;
			} catch (error) {
				console.error(error);
				throw new Error("Network response was not ok");
			}
		},
		{
			onSuccess: (res) => {
				// console.log(res);
			},
			// If the mutation fails, use the context returned from onMutate to roll back
			onError: (err, variables, context) => {
				console.error(err);
				enqueueSnackbar("Unable to get pathway data", { variant: "error" });
				navigate(`/pathways`, { replace: true });
			},
			// Always refetch after error or success:
			onSettled: () => {
				progressDone();
			},
		}
	);
	// console.log(isLoading);

	if (isLoading)
		return (
			<div
				style={{
					display: "flex",
					width: "100vw",
					height: "100vh",
					alignItems: "center",
					justifyContent: "center",
					flex: 1,
				}}>
				<Spinner />
			</div>
		);

	// if (error) return "An error has occurred: " + error.message;

	return (
		<Routes>
			<Route element={<PathWrapper childRef={childRef} data={data} />} errorElement={<PathwayError />}>
				<Route index element={<ViewPathway />} />
				<Route path=":leafId" element={<ViewPathway />} />
				<Route path="addStep" element={<AddStepPathway />} />
				<Route path=":leafId/addStep" element={<AddStepPathway />} />
				<Route path="edit" element={<EditPathway />} />
				<Route path=":leafId/edit" element={<EditPathway />} />
				<Route path="delete" element={<DeletePathway />} />
				<Route path=":leafId/delete" element={<DeletePathway />} />
			</Route>
		</Routes>
	);
};

const MainPathway = () => {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
			},
		},
	});

	return (
		<QueryClientProvider client={queryClient}>
			<PathwayRoutes />
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
};

export default MainPathway;

// Focus todo
// mark a node on click
// display its details in view menu

// useEffect(() => {
// 	const interval = setInterval(() => {
// let temp = {
// 	credentialId: "62f624bf8443a70042120e1e",
// 	description: "ewgwhvcg",
// 	icon: "https://s3.ca-central-1.amazonaws.com/trybe.public.assets/Trybe.ID/seal.png",
// 	isRequired: true,
// 	level: 1,
// 	name: "Hellvuiuflo",
// 	parentId: "c9740a4f-b440-4863-bb1e-c6cb129c256c",
// 	status: "ACTIVE",
// 	id: "ufufuufuf-b440-4863-bb1e-c6cb129c256c",
// 	type: "ACTIVITY",
// };
// 		childRef.current.addNode(temp);
// 	}, 20000);

// 	return () => clearInterval(interval);
// }, []);
