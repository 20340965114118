import { useEffect, useState } from "react";
import useCredentialHook from "../../hooks/useCredentialHook";
import { useNavigate, useParams } from "react-router-dom";
import IssueCredential from "../IssueCredential";
import useContactHook from "../../hooks/useContactHook";
import useCertificateHook from "../../hooks/useCertificateHook";
import { useSnackbar } from "notistack";
import { useTheme, Paper, Hidden } from "@material-ui/core";
import HeaderWithLIne from "../HeaderWithLine";
import useNProgressHook from "../../hooks/useNProgressHook";
import { Container, Box } from "@cti-workspace/ui";
import { getExpiryDateWithOffset, getIncrementedExpiryDate } from "./expirationDateUtils";
import { userStore } from "../../store/userStore";
import { getErrMsg, checkFabricCertDesigner, getObjectValue } from "@cti-workspace/helpers";

export default function CredentialIssue() {
	const theme = useTheme();

	const [transcripts, setTranscripts] = useState();
	const [selectedCred, setSelectedCred] = useState();
	const [transcriptGrades, settranscriptGrades] = useState({});
	const [selectedContact, setSelectedContact] = useState([]);
	const [sectionsData, setSectionsData] = useState({});
	const [sections, setSections] = useState([]);
	const [expirationDate, setExpirationDate] = useState({});
	const [expirationDurationNum, setExpirationDurationNum] = useState({});
	const [expirationDurationStr, setExpirationDurationStr] = useState({});
	const [disableIssueButton, setDisableIssueButton] = useState(false);

	const { enqueueSnackbar } = useSnackbar();
	const roles = userStore((state) => state.roles);
	const organizations = userStore((state) => state.organizations);
	const { getCredentialById } = useCredentialHook({ account: { roles, organizations } });

	const { contacts, putContacts, deleteContacts, contactLoading, selectAllContacts, contactsTags, getContacts } = useContactHook({
		account: { roles },
	});
	const { certificates, getCertificates, createCertificate } = useCertificateHook();

	const { progressDone } = useNProgressHook();

	const navigate = useNavigate();
	const params = useParams();
	useEffect(() => {
		if (params.id) {
			(async () => {
				try {
					const data = await getCredentialById(params.id);
					let {
						transcript,
						additionalData: { rendererConfigId },
					} = JSON.parse(data.definition);
					transcript = transcript.length
						? transcript.map((transcript) => ({
								...transcript,
								grade: !transcript.grade || transcript.grade === "undefined" ? "" : transcript.grade,
						  }))
						: [];
					setTranscripts(transcript);
					settranscriptGrades(
						(transcript &&
							transcript.reduce(
								(acc, item) => ({
									...acc,
									[item.name]: item.grade || "",
								}),
								{}
							)) ||
							{}
					);
					setSelectedCred(data);
					const sections = JSON.parse(data.additionalData).sections || [];
					setSections(sections || []);
					setSectionsData(
						(sections || []).reduce((acc, item) => {
							return {
								...acc,
								[item.name]: {
									...item.claims.reduce((accClaim, claim) => {
										return {
											...accClaim,
											[claim]: "",
										};
									}, {}),
								},
							};
						}, {})
					);
					if (rendererConfigId) {
						await checkFabricCertDesigner(rendererConfigId, setDisableIssueButton);
					}
				} catch (error) {
					console.error(error);
				}
				progressDone();
			})();
		}
	}, []);

	/**
	 *
	 * @param {Object} data Used for bulk issuance as certs created update after parsing excel sheet
	 */
	const handleCreateIssueCredential = async (certs, newRecipient = []) => {
		let recipients = [...selectedContact, ...newRecipient];
		let expirationDateDuration = {};
		if (Object.keys(expirationDate).length !== 0) {
			expirationDateDuration = { expiryDate: getExpiryDateWithOffset(expirationDate) };
		}

		if (Object.keys(expirationDurationStr).length !== 0 && Object.keys(expirationDurationNum).length !== 0) {
			expirationDateDuration = { expiryDate: getIncrementedExpiryDate(parseFloat(expirationDurationNum), expirationDurationStr) };
		}

		try {
			try {
				let credDef = selectedCred;
				let sectionsMain = [];

				if (sectionsData && Object.keys(sectionsData).length) {
					sectionsMain = Object.keys(sectionsData).map((section, i) => ({
						displayName: section,
						description: sections.find((item) => item.name === section).description || "",
						claims: Object.keys(getObjectValue(sectionsData, section) || []).map((claim) => ({
							name: claim,
							value: getObjectValue(sectionsData, `${section}.${claim}`) || "",
						})),
					}));
				}

				const data = await createCertificate({
					recipients,
					credDef,
					transcriptGrades,
					certs,
					sections: sectionsMain,
					expirationDateDuration,
				});

        if (data && data.issuedCerts && data.issuedCerts.length) {
          // See cypress app.trybe.id-dev\cypress\integration\cti-app\credentialCatalog\issue.spec.js createCertificate for the test
          if (data.issuedCerts.length == 1) {
            enqueueSnackbar(`New Credential Issued.`, { variant: "success" });
          } else {
            enqueueSnackbar(`New Certs Issued. Count: ${data.issuedCerts.length}`, { variant: "success" });
          }
					navigate(-1);
				}
			} catch (error) {
				if (error.code === "BadRequest") {
					enqueueSnackbar(getErrMsg(error, "Error issuing certificates. Please try again later."), {
						variant: "error",
					});
				} else {
          enqueueSnackbar(getErrMsg(null, "There was an error issuing certificates. Please try again later."), {
						variant: "error",
					});
				}
			}

			await putContacts(recipients);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<div style={{ minHeight: 56 }} />
			<Container maxWidth="md">
				<Hidden only={["xs", "sm"]}>
					<Paper
						style={{
							padding: theme.spacing(2),
							width: "100%",
							display: "flex",
							justifyContent: "space-between",
						}}>
						<Box>
							<HeaderWithLIne title={"Issue a Credential"} variant={"h6"} />
						</Box>
					</Paper>
				</Hidden>
				<Box mt={1} borderColor="primary" border={8}>
					<Paper style={{ padding: theme.spacing(2) }}>
						<IssueCredential
							transcriptGrades={transcriptGrades}
							settranscriptGrades={settranscriptGrades}
							contacts={contacts}
							createCertificate={createCertificate}
							deleteContacts={deleteContacts}
							putContacts={putContacts}
							selectedContact={selectedContact}
							selectedCred={selectedCred}
							setSelectedContact={setSelectedContact}
							transcripts={transcripts}
							handleCreateIssueCredential={handleCreateIssueCredential}
							selectAllContacts={selectAllContacts}
							contactsTags={contactsTags}
							getContacts={getContacts}
							sections={sections}
							setSectionsData={setSectionsData}
							sectionsData={sectionsData}
							expirationDate={expirationDate}
							setExpirationDate={setExpirationDate}
							setExpirationDurationNum={setExpirationDurationNum}
							setExpirationDurationStr={setExpirationDurationStr}
							disableIssueButton={disableIssueButton}
						/>
					</Paper>
				</Box>
			</Container>
		</>
	);
}
