import { Component } from "react";
import { AtomicBlockUtils } from "draft-js";

class TextSource extends Component {
	componentDidMount() {
		const { editorState, entityType, onComplete } = this.props;
		const styleString = JSON.parse(window.prompt("style string"));
		const val = window.prompt("text string");

		if (val && styleString) {
			const content = editorState.getCurrentContent();
			const contentWithEntity = content.createEntity(entityType.type, "MUTABLE", {
				val,
				styleString,
			});
			const entityKey = contentWithEntity.getLastCreatedEntityKey();
			const nextState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, " ");

			onComplete(nextState);
		} else {
			onComplete(editorState);
		}
	}

	render() {
		return null;
	}
}
export default TextSource;

// class CredentialName extends Component {
// 	componentDidMount() {
// 		const { editorState, entityType, onComplete, data } = this.props
// 		console.log(data)

// 		if (data && data.name) {
// 			const content = editorState.getCurrentContent()
// 			const contentWithEntity = content.createEntity(
// 				entityType.type,
// 				"MUTABLE",
// 				{
// 					val: data.name,
// 				}
// 			)
// 			const entityKey = contentWithEntity.getLastCreatedEntityKey()
// 			const nextState = RichUtils.toggleLink(
// 				editorState,
// 				editorState.getSelection(),
// 				entityKey
// 			)

// 			onComplete(nextState)
// 		} else {
// 			onComplete(editorState)
// 		}
// 	}

// 	render() {
// 		return null
// 	}
// }
// export default CredentialName
