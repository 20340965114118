export const getExpiryDateWithOffset = (dateString) => {
  const date = new Date(dateString);
  date.setHours(23, 59, 59)
  return date.toISOString();
}

export const getIncrementedExpiryDate = (value, type) => {
  const date = new Date();
  if (type === 'days') {
    date.setDate(date.getDate() + value)
  } else if (type === 'months') {
    date.setMonth(date.getMonth() + value)
  } else if (type === 'years') {
    date.setFullYear(date.getFullYear() + value);
  }
  date.setHours(23, 59, 59)
  return date.toISOString();
}
