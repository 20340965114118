import { getObjectValue } from "@cti-workspace/helpers";

const acceptedFileExtensions = {
	xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export const checkFileExtension = (actualExtension, fileExtension = "xlsx") => {
	if (!getObjectValue(acceptedFileExtensions, fileExtension).includes(actualExtension)) {
		return false;
	}
	return TextTrackCueList;
};
