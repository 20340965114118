import { useEffect, useRef, useState, Fragment } from "react";
import equal from "fast-deep-equal";
import ChooseBadge from "../../components/ChooseBadge";
import Resources from "../../components/Resources";
import Content from "../../components/Content";
import useCredentialHook from "../../hooks/useCredentialHook";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import useNProgressHook from "../../hooks/useNProgressHook";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import useRendererConfigOrgHook from "../../hooks/useRendererConfigOrgHook";
import useGoogleClassroomHook from "../../hooks/useGoogleClassroomHook";
import useSettingsHook from "../../hooks/useSettingsHook";
import Sections from "../../components/Sections";
import FabricCertBuilder from "./FabricCertBuilder";
import RichTextEditor from "./RichTextEditor";
import SelectEmailTemplate from "./SelectEmailTemplate";
import SelectCustomCredential from "./SelectCustomCredential";
import PDFSettings from "./PDFSettings";
import { decompress, compress } from "compress-json";
import CapabilityBoundary from "../CapabilityBoundary/CapabilityBoundary";
import CAPABILITIES from "../../capabilities";
import { Button, Box, RadioButton, RadioGroup, Card, TextField, Typography as Text, Icon, TextFieldCapsule } from "@cti-workspace/ui";
import SharingOptions from "./SharingOptions";
import MobileWalletOptions from "./MobileWalletOptions";
import pin from "../../assets/icons/pin.png";
import unpin from "../../assets/icons/pinned.png";
import AutoCompleteRendererConfigOrg from "../AutoCompleteRendererConfigOrg";
import SelectAuthentication, { BuildAuthenticationObject, DestructureAuthenticationArray } from "./SelectAuthentication";
import { userStore } from "../../store/userStore";
import { getErrMsg, URL, IMAGE, PDF, IMAGE_CONTENT, ISSUE_CREDENTIALS, PUBLIC_PREFIX, isValidHttpUrl, UploadImage, getApi, escapeDoubleQuotes, getObjectValue, FABRIC_DIMENSIONS } from "@cti-workspace/helpers";

const FormDefineCredential = ({ setSelectedSavedIconUrl, getOrgConfigList, isCredReadOnly = () => {} }) => {
	const { register, errors, control, handleSubmit, reset, watch, setValue } = useFormContext();

	const roles = userStore((state) => state.roles);
	const organizations = userStore((state) => state.organizations);

	const { addCreds, credentials, connectedCredsData, updateCredentialDefinition, getCredentialById } = useCredentialHook({ account: { roles, organizations } });
	const { updateConnectedCredential } = useGoogleClassroomHook({ organizations });
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [credData, setCredData] = useState();
	const [credentialDescription, setCredentialDescription] = useState("");

	const watcher = watch(["name", "description", "designType", "sections"]);

	const errorMessages = {
		resources: "Resource name already exists",
	};
	const [simpleDesignImage, setSimpleDesignImage] = useState("");
	const [saveIcon, setSaveIcon] = useState(false);
	const [selectedIconIndex, setSelectedIconIndex] = useState(-1);
	const [defaultIconName, setDefaultIconName] = useState(null);
	const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(null);
	const [selectedCustomCredential, setSelectedCustomCredential] = useState("");
	const [encryptingContent, setEncryptingContent] = useState([]);
	const [selectedDefaultRendererConfig, setSelectedDefaultRendererConfig] = useState(null);
	const [isUsingOldTemplate, setIsUsingOldTemplate] = useState(false);
	const [pdfSettings, setPdfSettings] = useState({
		generatePdf: null,
		pdfTemplate: null,
		encryptContent: null,
	});
	const [sharingOptions, setSharingOptions] = useState({
		publicUrl: false,
		addToLinkedIn: false,
		addToTwitter: false,
		shareToLinkedIn: false,
		addToWallet: false,
	});
	const [enableMobileWallet, setEnableMobileWallet] = useState(false);
	const [pinned, setPinned] = useState(false);
	const [authenticationOptions, setAuthenticationOptions] = useState({ ctiBased: { main: false }, googleBased: { main: false } });
	const certDimensionValue = useRef(null);
	const copyRendererConfigId = useRef(false);
	const fabricDesignerRef = useRef(null);
	const [fabricContentVariables, setFabricContentVariables] = useState({ content: {}, toRemove: {} });
	const [fabricContentChange, setFabricContentChange] = useState({});

	const { progressStart, progressDone } = useNProgressHook();
	const { addOrgRendererConfig } = useRendererConfigOrgHook();
	const { canView, getIcons, updateSavedIcon, getCredentialDefinitionSettings, getPdfSettings, getSharingSettings, getWalletOptions } = useSettingsHook();

	const params = useParams();

	const credentialDefinitionSettings = getCredentialDefinitionSettings();
	const enableSharingOptions = credentialDefinitionSettings?.enableSharingOptions === true;
	const enableCustomCredentials = credentialDefinitionSettings?.enableCustomCredentials === true;
	const enableAuthenticationOptions = credentialDefinitionSettings?.enableAuthenticationOptions === true;
	const enablePdfOptions = credentialDefinitionSettings?.enablePdfOptions === true;
	const enableMobileWalletOptions = credentialDefinitionSettings?.enableMobileWalletOptions === true;
	const credentialSettingsEnabled = enableSharingOptions || enablePdfOptions || enableMobileWalletOptions;

	const { fabricCertDesigner: defaultFabricCertDesigner, designType, rendererConfigId, rendererConfigId: defaultRendererConfigId } = control?.defaultValuesRef?.current ?? {};
	const enableMultiPageCertificate = canView("enableMultiPageCertificate", true) && ((params.id && defaultFabricCertDesigner?.isMultiPage) || designType === "template" || !rendererConfigId || !params?.id);

	useEffect(() => {
		if (selectedIconIndex > 0) {
			const { [selectedIconIndex]: selectedIcon } = getIcons();
			setSelectedSavedIconUrl(selectedIcon.iconURL);
		} else {
			setSelectedSavedIconUrl(null);
		}
	}, [selectedIconIndex]);

	const descriptionRef = useRef();

	const getResourceType = (resType) => {
		let type = URL;
		const fileType = resType.split(".").pop().toLowerCase();
		if (["jpeg", "jpg", "png"].includes(fileType)) {
			type = IMAGE;
		} else if (fileType.indexOf("pdf") >= 0) {
			type = PDF;
		}
		return type;
	};

	useEffect(() => {
		if (params.id && getObjectValue(credentials, "items", []).length) {
			(async () => {
				progressStart();
				const credentialData = await getCredentialById(params.id);
				const { description, imageUrl, emailTemplates, iconData, pdfTemplates } = JSON.parse(credentialData.additionalData);
				setSelectedEmailTemplate(emailTemplates?.ISSUE_CREDENTIALS || null);
				setDefaultIconName(iconData?.iconName || null);
				isCredReadOnly(getObjectValue(credentialData, "readOnly", false));
				const { settings, pinned, customCredentials, requiredUserAuthentications, tags = [] } = credentialData;

				setPinned(pinned);
				setSelectedCustomCredential(customCredentials?.[0] || "");
				if (enableAuthenticationOptions) {
					const destructureAuthenticationArray = DestructureAuthenticationArray(requiredUserAuthentications);
					// console.log(destructureAuthenticationArray);
					setAuthenticationOptions(destructureAuthenticationArray);
				}

				const {
					additionalData: { resources, rendererConfigId, sections = [] },
					transcript,
					emailTemplates: definitionEmailTemplates,
				} = JSON.parse(credentialData.definition);

				if (!definitionEmailTemplates) {
					setSelectedEmailTemplate(getSelectedEmailTemplateId(emailTemplates, definitionEmailTemplates));
				}

				if (credentialSettingsEnabled) {
					setSharingOptions({
						publicUrl: !settings?.sharingOptions?.disablePublicURL,
						addToLinkedIn: !settings?.sharingOptions?.disableAddToLinkedIn,
						addToTwitter: !settings?.sharingOptions?.disablePostToTwitter,
						shareToLinkedIn: !settings?.sharingOptions?.disablePostToLinkedIn,
						addToWallet: !settings?.sharingOptions?.disableAddToWallet,
					});
					setEncryptingContent(
						(transcript || [])
							.filter((content) => content?.grade !== IMAGE_CONTENT)
							.map((content) => ({
								key: content.name,
							}))
					);
					setPdfSettings({
						pdfTemplate: getObjectValue(pdfTemplates, ISSUE_CREDENTIALS, null),
						generatePdf: settings?.pdfOptions?.generatePDF === true ? "Yes" : "No",
						encryptContent: settings?.pdfOptions?.encryptOptions?.encryptKey || null,
					});
					setEnableMobileWallet(settings?.walletOptions?.enableMobileWallet || false);
				}

				setCredData(null);
				setCredentialDescription("");
				let designType = "fabricDesign";
				let designData = null;
				// default credential size of old certificates
				let credDimension = "us_letter_landscape";
				if (rendererConfigId) {
					const { simpleDesign, data, name } = await getApi(`/${PUBLIC_PREFIX}/rendererConfig/${rendererConfigId}`, { cachePolicy: "no-cache" }, false);
					setSelectedDefaultRendererConfig({ name: name || "Previously selected", _id: rendererConfigId });
					if (simpleDesign) {
						setIsUsingOldTemplate(true);
						designType = "simpleDesign";
						designData = JSON.parse(data);
						const { text1, text2, bgImage } = designData;
						if (designData.bgImage) {
							setSimpleDesignImage(bgImage);
							designData.bgImage = [{ value: bgImage, key: "default", type: "file" }];
						}
						designData.text1 = text1 && JSON.parse(text1).color;
						designData.text2 = text2 && JSON.parse(text2).color;
						// console.log(designData);
					} else {
						let val = JSON.parse(data);
						designType = "template";
						if (val.type == "fabricDesign" && val.data) {
							designType = "fabricDesign";
							if (val.credDimension) {
								const credDimensionObj = val.credDimension.split("X");
								const dimensionRatio = Number((Number(credDimensionObj[0]) / Number(credDimensionObj[1])).toFixed(2));
								if (Number(credDimensionObj[0]) < Number(credDimensionObj[1])) {
									if (dimensionRatio <= FABRIC_DIMENSIONS.A4_PORTRAIT.toFixed(2) || dimensionRatio <= FABRIC_DIMENSIONS.A4_PORTRAIT_OLD.toFixed(2)) {
										credDimension = "a4_portrait";
									} else {
										credDimension = "us_letter_portrait";
									}
								} else {
									if (dimensionRatio >= FABRIC_DIMENSIONS.A4_LANDSCAPE.toFixed(2) || dimensionRatio >= FABRIC_DIMENSIONS.A4_LANDSCAPE_OLD.toFixed(2)) {
										credDimension = "a4_landscape";
									} else {
										credDimension = "us_letter_landscape";
									}
								}
							}
							try {
								designData = { fabricCertDesigner: decompress(val.data) };
							} catch (error) {
								designData = { fabricCertDesigner: null };
							}
						} else {
							// check for the draft design
							if (!val?.type) {
								setIsUsingOldTemplate(true);
							}
						}
					}
				}
				const toUpdate = {
					name: credentialData.name,
					description,
					resources: resources
						? resources.map((item) => ({
								key: item.id,
								value: item.url,
								type: getResourceType(item.url),
								originalFileName: item.originalFileName,
						  }))
						: [],
					images: [{ key: "default", value: imageUrl, type: "file" }],
					transcripts: transcript ? transcript.map((item) => ({ key: item.name, value: item.grade || "", required: item.required || false, type: item.transcriptType })) : [],
					sections: sections.map?.((section) => ({
						...section,
						claims: section.claims.map((claim) => ({
							value: claim,
						})),
					})),
					rendererConfigId,
					designType,
					credDimension,
					tags: [...tags.map((tag) => ({ key: tag }))],
					...designData,
				};

				console.log(toUpdate);

				reset(toUpdate);
				setCredData(credentialData);
				setCredentialDescription(description);
				progressDone();
			})();
		} else {
			// setting organization pdf/sharing/wallet settings
			if (enablePdfOptions && !params.id) {
				const orgPdfSettings = getPdfSettings();
				const encryptKey = orgPdfSettings?.encryptPdf ? orgPdfSettings?.encryptOptions?.encryptKey || null : null;
				setPdfSettings({
					...pdfSettings,
					generatePdf: orgPdfSettings?.generatePDF === true ? "Yes" : "No",
					encryptContent: encryptKey,
				});
				setEncryptingContent([{ key: encryptKey }]);
				if (encryptKey) {
					reset({
						transcripts: [{ key: encryptKey, value: "" }],
						designType: "fabricDesign",
						credDimension: "us_letter_landscape",
					});
				}
			}
			if (enableSharingOptions) {
				const orgSharingSettings = getSharingSettings();
				setSharingOptions({
					publicUrl: !orgSharingSettings?.disablePublicURL,
					addToLinkedIn: !orgSharingSettings?.disableAddToLinkedIn,
					addToTwitter: !orgSharingSettings?.disablePostToTwitter,
					shareToLinkedIn: !orgSharingSettings?.disablePostToLinkedIn,
					addToWallet: !orgSharingSettings?.disableAddToWallet,
				});
			}
			if (enableMobileWalletOptions) {
				const walletOptions = getWalletOptions();
				setEnableMobileWallet(walletOptions?.enableMobileWallet ?? false);
			}
			if (!params.id) {
				setValue("designType", "fabricDesign");
			}
		}
	}, [credentials]);

	useEffect(() => {
		try {
			const editor = descriptionRef.current && descriptionRef.current.getEditor && descriptionRef.current.getEditor();
			const descriptionVal = editor && editor.getText();
			if (!(descriptionVal && descriptionVal.trim())) {
				editor && editor.pasteHTML(0, credentialDescription);
				editor && editor.blur();
			}
		} catch (error) {}
	}, [descriptionRef.current, credentialDescription]);

	const getSelectedEmailTemplateId = (str1, str2) => {
		if (str1?.ISSUE_CREDENTIALS) {
			return str1?.ISSUE_CREDENTIALS;
		} else if (str2?.ISSUE_CREDENTIALS) {
			return str2?.ISSUE_CREDENTIALS;
		} else {
			return str2 || null;
		}
	};

	const getImgUpload = (fabricCertDesigner) => {
		return Promise.allSettled(
			fabricCertDesigner.objects.map(async (element) => {
				if (element.type === "image" && !isValidHttpUrl(element.src)) {
					return (element.src = await UploadImage(element.src));
				}
			})
		);
	};

	const createCredentialSubmit = async (event) => {
		if (errors && Object.keys(errors).length === 0) {
			progressStart();
			try {
				// get the rendererConfigID
				let rendererConfigData = null;
				let updateRenderConfig = false;
				const { images, resources, transcripts, rendererConfigId, sections: sectionsData = [], fabricCertDesigner, credDimension, tags, designType } = event;

				const name = escapeDoubleQuotes(event.name);
				let description = escapeDoubleQuotes(event.description || "");

				if (description) {
					description = description
						.split("<p><br></p>")
						.filter((item) => item)
						.join("<p><br></p>");
				}

				if (designType === "fabricDesign") {
					let fabricPayload;
					if (enableMultiPageCertificate) {
						const payload = fabricDesignerRef.current.getFabricObject();
						const { payload: defaultPayload } = defaultFabricCertDesigner || {};
						if (!equal(defaultPayload, payload)) {
							updateRenderConfig = true;
							if (payload?.error) {
								progressDone();
								return;
							}
							for (const key in payload) {
								const { [key]: page } = payload;
								if (typeof page === "string") continue;

								let bgImageUrl = page?.backgroundImage?.src;
								if (bgImageUrl && !isValidHttpUrl(bgImageUrl)) {
									// upload the large bg Images to get S3 url
									bgImageUrl = await UploadImage(page.backgroundImage.src);
									page.backgroundImage.src = bgImageUrl;
								}
								// upload canvas large Images to get s3 bucket url
								page?.objects?.length > 0 && (await getImgUpload(page));
							}
							fabricPayload = { payload, isMultiPage: true };
						}
					} else {
						if (!equal(defaultFabricCertDesigner, fabricCertDesigner)) {
							updateRenderConfig = true;
							if (!credDimension) {
								enqueueSnackbar(getErrMsg(null, "Please select Certificate Orientation"), { variant: "error" });
								progressDone();
								return;
							}
							if (!fabricCertDesigner || (!fabricCertDesigner?.backgroundImage && !fabricCertDesigner?.objects?.length)) {
								enqueueSnackbar(getErrMsg(null, "The Credential Definition can not be saved yet, please first add a Certificate Design or select a Certificate Template to use."), { variant: "error" });
								progressDone();
								return;
							}
							let bgImageUrl = fabricCertDesigner?.backgroundImage?.src;
							if (bgImageUrl && !isValidHttpUrl(bgImageUrl)) {
								// upload the large bg Images to get S3 url
								bgImageUrl = await UploadImage(fabricCertDesigner.backgroundImage.src);
								fabricCertDesigner.backgroundImage.src = bgImageUrl;
							}
							// upload canvas large Images to get s3 bucket url
							fabricCertDesigner?.objects?.length > 0 && (await getImgUpload(fabricCertDesigner));
							fabricPayload = fabricCertDesigner;
						}
					}

					if (updateRenderConfig) {
						const dateStr = new Date().toISOString();
						const body = {
							type: "fabricDesign",
							data: compress(fabricPayload),
							credDimension: certDimensionValue.current ? `${certDimensionValue.current.width}X${certDimensionValue.current.height}` : null,
						};
						rendererConfigData = await addOrgRendererConfig(body, name + " " + dateStr, getObjectValue(organizations, "default._id"), "", false, false);
						if (copyRendererConfigId.current === true) {
							try {
								await navigator.clipboard.writeText(rendererConfigData.rendererConfigId);
								enqueueSnackbar(`New renderer configuration Id ${rendererConfigData.rendererConfigId} copied to clipboard.`, { variant: "success" });
							} catch (e) {
								enqueueSnackbar(`Failed to copy Renderer configuration Id ${rendererConfigData.rendererConfigId}. Please try again.`, { variant: "error" });
							}
						}
					}
				}

				if (updateRenderConfig && designType !== "default" && !(rendererConfigData?.rendererConfigId || rendererConfigId)) {
					enqueueSnackbar(getErrMsg(null, "Please select certificate design"), { variant: "error" });
					progressDone();
					return;
				}

				// transform fields as required
				const transcriptsKeys = transcripts || [];
				// get image url
				let imageUrl = "";
				let iconName = "";
				if (selectedIconIndex > 0) {
					const { [selectedIconIndex]: selectedIcon } = getIcons();
					imageUrl = selectedIcon.iconURL;
					iconName = selectedIcon.iconName;
				}
				if (images && images.length && selectedIconIndex <= 0) {
					iconName = images[0].key;
					if (!isValidHttpUrl(images[0].value)) {
						imageUrl = await UploadImage(images[0].value);
					} else {
						imageUrl = images[0].value;
					}
				}
				if (saveIcon) {
					updateSavedIcon({
						iconName,
						iconURL: imageUrl,
					});
				}
				const uploadedResources = await uploadResources(resources);
				const sections =
					sectionsData && sectionsData.length > 0
						? sectionsData.map((sectionItem) => ({
								...sectionItem,
								claims: sectionItem.claims.map((claim) => claim.value),
						  }))
						: [] || [];

				let settings = undefined;
				if (credentialSettingsEnabled) {
					settings = {};
					if (enableSharingOptions) {
						settings.sharingOptions = {
							disablePublicURL: !sharingOptions.publicUrl,
							disableAddToLinkedIn: !sharingOptions.addToLinkedIn,
							disablePostToLinkedIn: !sharingOptions.shareToLinkedIn,
							disablePostToTwitter: !sharingOptions.addToTwitter,
							disableAddToWallet: !sharingOptions.addToWallet,
						};
					}
					if (enablePdfOptions) {
						settings.pdfOptions = {
							generatePDF: pdfSettings.generatePdf === "Yes",
							encryptPDF: !!pdfSettings.encryptContent,
							encryptOptions: {
								encryptKey: pdfSettings.encryptContent,
							},
							pdfTemplate: pdfSettings.pdfTemplate,
						};
					}
					if (enableMobileWalletOptions) {
						settings.walletOptions = {
							enableMobileWallet: enableMobileWallet,
						};
					}
				}

				let requiredUserAuthentications = BuildAuthenticationObject(authenticationOptions);
				if (params.id) {
					const connectedCredFound = connectedCredsData.find((cred) => cred.credentialName === credData.name);
					// Connected creds only store the name and image url so just udpate if the name changed
					if (connectedCredFound && (name !== credData.name || imageUrl !== credData.imageUrl)) {
						await updateConnectedCredential(connectedCredFound._id, name, imageUrl);
					}
					await updateCredentialDefinition(
						credData._id,
						name,
						description,
						transcriptsKeys,
						imageUrl,
						uploadedResources,
						getObjectValue(rendererConfigData, "rendererConfigId", designType === "default" ? "" : rendererConfigId || defaultRendererConfigId),
						sections,
						saveIcon,
						iconName,
						selectedEmailTemplate,
						settings,
						pinned,
						selectedCustomCredential,
						requiredUserAuthentications,
						(tags || []).reduce((acc, item) => [...acc, item.key], [])
					);
					enqueueSnackbar("Credential Updated Successfully", {
						variant: "success",
					});
				} else {
					await addCreds(
						name,
						description,
						transcriptsKeys,
						imageUrl,
						uploadedResources,
						getObjectValue(rendererConfigData, "rendererConfigId", rendererConfigId),
						sections,
						saveIcon,
						iconName,
						selectedEmailTemplate,
						settings,
						pinned,
						selectedCustomCredential,
						requiredUserAuthentications,
						(tags || []).reduce((acc, item) => [...acc, item.key], [])
					);
					enqueueSnackbar("Credential Created successfully", {
						variant: "success",
					});
				}
				progressDone();
				navigate(-1);
			} catch (err) {
				progressDone();
				console.error(err);
				enqueueSnackbar(getErrMsg(err, "Oops!! Something went wrong. Please try again after sometime."), { variant: "error" });
			}
		}
	};

	const uploadResources = async (resources) => {
		// Upload each of the resources and store the url
		const uploadedResources = [];
		if (resources && resources.length) {
			for (let i = 0; i < resources.length; i++) {
				const { [i]: resource } = resources;
				if (isValidHttpUrl(resource.value)) {
					uploadedResources.push({
						id: resource.key,
						url: resource.value,
						originalFileName: resource.originalFileName,
					});
				} else {
					let splitFileName = resources[i].key.split(".");
					let originalFileNameArr = resources[i].originalFileName.split(".");

					if (originalFileNameArr.length > 1) originalFileNameArr.pop();
					if (splitFileName.length > 1) splitFileName.pop();

					const keyName = splitFileName.join(".");
					const originalFileName = originalFileNameArr.join(".");
					const url = await UploadImage(resource.value, keyName, undefined, undefined, originalFileName);
					uploadedResources.push({ id: resource.key, url, originalFileName });
				}
			}
		}
		return uploadedResources;
	};

	const removeField = (fieldName, index, remove) => {
		remove(index);
	};

	const setCopyRendererConfigId = () => {
		enqueueSnackbar("After the new renderer config is saved with the credential, the Id will be copied to the clipboard.", {
			variant: "info",
		});
		copyRendererConfigId.current = true;
	};

	return (
		<>
			<form onSubmit={handleSubmit((event) => createCredentialSubmit(event))}>
				<Card hasBorder elevation={6} style={{ padding: "0.9rem", display: "flex", flexDirection: "column", gap: "1rem" }} borderRadius="10px">
					<ChooseBadge
						name="images"
						control={control}
						errors={errors}
						register={register}
						useInitialKeyName
						saveIcon={saveIcon}
						setSaveIcon={setSaveIcon}
						selectedIconIndex={selectedIconIndex}
						setSelectedIconIndex={setSelectedIconIndex}
						defaultIconName={defaultIconName}
						iconData={getIcons()}
					/>
					<Card hasBorder elevation={0} borderRadius="2px" style={{ backgroundColor: "#FAFAFA", padding: "0.8rem", margin: "0" }}>
						<TextField
							name="name"
							label="Credential Name"
							inputRef={register({
								required: {
									value: true,
									message: "Credential Name is required",
								},
							})}
							variant="static"
							{...(errors?.name?.message && {
								helperText: errors.name?.message,
								error: true,
							})}
							endAdornment={
								<Button buttonType="icon" invert icon={<img src={pinned ? unpin : pin} alt="Pin to Top Icon" />} popover={!pinned ? "Pin to Top of Credential Catalog" : "Unpin from Top of Credential Catalog"} onClick={() => setPinned(!pinned)} />
							}
							size="small"
							showRequired
						/>
						<div style={{ marginTop: "16px" }}>
							<IssuerTagArray control={control} register={register} name="tags" errors={errors} />
						</div>
						<div style={{ marginTop: "16px" }}>
							<Text type="body1" style={{ lineHeight: "1.7rem" }}>
								Description
							</Text>
							<Controller control={control} name="description" render={({ onChange, value }) => <RichTextEditor ref={descriptionRef} onChange={onChange} defaultValue={value} />} />
						</div>
					</Card>
					<Content
						register={register}
						control={control}
						errors={errors}
						name="transcripts"
						setEncryptingContent={setEncryptingContent}
						enableImageContentRow={credentialDefinitionSettings?.enableImageContent === true || true}
						setFabricContentVariables={setFabricContentVariables}
						fabricContentVariables={fabricContentVariables}
						setFabricContentChange={setFabricContentChange}
						fabricContentChange={fabricContentChange}
					/>
					<Resources
						displayName="Select Resources"
						name="resources"
						control={control}
						errors={errors}
						register={register}
						useInitialKeyName
						validationErrorMessage={errorMessages["resources"]}
						addTypes={[
							{
								displayName: "Add Image",
								type: ["image/jpeg", "image/png", "image/bmp"],
								leftIcon: "image",
							},
							{
								displayName: "Add PDF",
								type: [".pdf"],
								leftIcon: "pdf",
							},
							{
								displayName: "Add URL",
								type: URL,
								leftIcon: "link-simple-horizontal",
							},
						]}
					/>
				</Card>
				{canView("credentialDefinitions.enableSections", true) || (params.id && watcher.sections && watcher.sections.length > 0) ? (
					<Card
						hasBorder
						elevation={6}
						style={{
							padding: "0.9rem",
							display: "flex",
							flexDirection: "column",
							gap: "1rem",
						}}
						borderRadius="10px">
						<Sections register={register} control={control} name="sections" errors={errors} />
					</Card>
				) : null}
				{enableCustomCredentials || enableSharingOptions || enableMobileWalletOptions ? (
					<Card hasBorder elevation={6} style={{ padding: "0.9rem", display: "flex", flexDirection: "column", gap: "1rem" }} borderRadius="10px">
						{enableCustomCredentials && (
							<>
								<div style={{ display: "flex", gap: "0.8rem" }}>
									<SelectCustomCredential selectedCustomCredential={selectedCustomCredential} setSelectedCustomCredential={setSelectedCustomCredential} organizationId={getObjectValue(organizations, "default._id")} />
									<Card hasBorder elevation={0} borderRadius="2px" style={{ backgroundColor: "#FAFAFA", padding: "0.8rem", width: "100%", margin: "0" }} />
								</div>
								{enableAuthenticationOptions && !!selectedCustomCredential && (
									<Card hasBorder elevation={0} borderRadius="2px" style={{ backgroundColor: "#FAFAFA", padding: "0.8rem", width: "100%", margin: "0" }}>
										<SelectAuthentication authenticationOptions={authenticationOptions} selectedCustomCredential={selectedCustomCredential} setAuthenticationOptions={setAuthenticationOptions} isEdit={params.id} />
									</Card>
								)}
							</>
						)}
						{enableSharingOptions || enableMobileWalletOptions ? (
							<Card hasBorder elevation={0} borderRadius="2px" style={{ backgroundColor: "#FAFAFA", padding: "0.8rem", display: "flex", flexDirection: "column", gap: "1rem", margin: "0" }}>
								{enableSharingOptions && <SharingOptions sharingOptions={sharingOptions} setSharingOptions={setSharingOptions} />}
								{enableMobileWalletOptions && <MobileWalletOptions enableMobileWallet={enableMobileWallet} setEnableMobileWallet={setEnableMobileWallet} />}
							</Card>
						) : null}
						{enablePdfOptions && (
							<Card hasBorder elevation={0} borderRadius="2px" style={{ backgroundColor: "#FAFAFA", padding: "0.8rem", width: "100%", margin: "0" }}>
								<PDFSettings encryptingContent={encryptingContent} pdfSettings={pdfSettings} setPdfSettings={setPdfSettings} isEditing={!!params.id} organizationId={getObjectValue(organizations, "default._id")} />
							</Card>
						)}
					</Card>
				) : null}
				<CapabilityBoundary
					render={() => <SelectEmailTemplate selectedEmailTemplate={selectedEmailTemplate} setSelectedEmailTemplate={setSelectedEmailTemplate} organizationId={getObjectValue(organizations, "default._id")} isEditing={!!params.id} />}
					capability={CAPABILITIES.ATTACH_EMAIL_TEMPLATE_TO_CREDENTIAL_DEFINITIONS}
					hide
				/>
				<Card hasBorder elevation={6} style={{ padding: "0.9rem", display: "flex", flexDirection: "column", gap: "1rem" }} borderRadius="10px">
					<Card hasBorder elevation={0} borderRadius="2px" style={{ backgroundColor: "#FAFAFA", padding: "0.8rem", width: "100%", margin: "0" }}>
						<Text type="body1">Certificate Design</Text>
						<Controller
							control={control}
							name="designType"
							defaultValue="fabricDesign"
							render={({ value, onChange, name }) => {
								return (
									<RadioGroup
										aria-label="gender"
										value={value}
										name={name}
										style={{ gap: "0.3rem", marginTop: "0.5rem" }}
										onChange={(value) => {
											if (fabricDesignerRef?.current) {
												const { handleDimensionChangeModal } = fabricDesignerRef?.current;
												if (enableMultiPageCertificate) {
													const payload = fabricDesignerRef.current.getFabricObject(true);
													const { payload: defaultPayload } = defaultFabricCertDesigner || {};
													const defaultPage = payload[payload.defaultPage];
													if (!!defaultPage.objects && !equal(payload, defaultPayload)) {
														handleDimensionChangeModal(true, onChange, value, false);
														return;
													}
												} else {
													const { fabricCertDesigner: defaultFabricCertDesigner } = control?.defaultValuesRef?.current;
													const fabricCertDesigner = control.getValues("fabricCertDesigner");
													if (!equal(fabricCertDesigner, defaultFabricCertDesigner)) {
														handleDimensionChangeModal(true, onChange, value, false);
														return;
													}
												}
												onChange(value);
											}
											onChange(value);
										}}>
										{/* <RadioButton value="default" label="Default Design" /> */}
										{<RadioButton value="fabricDesign" label="Design a New Certificate" />}
										{getOrgConfigList.length > 0 ? <RadioButton value="template" label="Use an Existing Design" /> : null}
									</RadioGroup>
								);
							}}
						/>

						{watcher.designType === "template" ? (
							<div style={{ marginTop: "0.5rem", paddingLeft: "1.5rem", display: "grid", gap: "0.3rem", gridTemplateColumns: "1fr 1fr" }}>
								{getOrgConfigList.length && (
									<Controller
										control={control}
										name="rendererConfigId"
										render={({ onChange, value, ref }) => <AutoCompleteRendererConfigOrg initialOptions={getOrgConfigList} onChange={onChange} defaultValue={selectedDefaultRendererConfig} ref={ref} size="small" />}
									/>
								)}
								<div />
							</div>
						) : watcher.designType === "fabricDesign" ? (
							<FabricCertBuilder
								setFabricContentVariables={setFabricContentVariables}
								fabricContentVariables={fabricContentVariables}
								ref={fabricDesignerRef}
								control={control}
								setCopyRendererConfigId={setCopyRendererConfigId}
								getCertDimensions={(e) => (certDimensionValue.current = e)}
								enableMultiPageCertificate={enableMultiPageCertificate}
								setFabricContentChange={setFabricContentChange}
								fabricContentChange={fabricContentChange}
							/>
						) : null}
					</Card>
				</Card>

				<div style={{ display: "grid", gridTemplateColumns: "0.5fr 1fr 0.5fr", marginBottom: "1rem" }}>
					<div />
					<Button type="submit" color="warning" disabled={getObjectValue(credData, "readOnly", false)}>
						Submit
					</Button>
					<div />
				</div>
			</form>

			{/* <Card
				style={{
					display: "flex",
					padding: theme.spacing(1),
					width: "100%",
				}}
				elevation={2}>
				<form onSubmit={handleSubmit((event) => createCredentialSubmit(event))}>
					<Grid container spacing={2} justifyContent="flex-start" alignContent="flex-start">
						<Grid item xs={12}>
							<Card elevation={0} style={{ backgroundColor: "#fafafa", marginBottom: "0px" }}>
								<ChooseBadge
									name="images"
									control={control}
									errors={errors}
									register={register}
									useInitialKeyName
									saveIcon={saveIcon}
									setSaveIcon={setSaveIcon}
									selectedIconIndex={selectedIconIndex}
									setSelectedIconIndex={setSelectedIconIndex}
									defaultIconName={defaultIconName}
									iconData={getIcons()}
								/>
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Card elevation={0} style={{ backgroundColor: "#fafafa", marginBottom: "0px" }}>
								<TextField
									name="name"
									label="Credential Name"
									inputRef={register({
										required: {
											value: true,
											message: "Credential Name is required",
										},
									})}
									variant="static"
									{...(errors?.name?.message && {
										helperText: errors.name?.message,
										error: true,
									})}
									endAdornment={
										<InputAdornment position="end">
											<IconButton title={!pinned ? "Pin to Top of Credential Catalog" : "Unpin from Top of Credential Catalog"} onClick={() => setPinned(!pinned)}>
												<img src={pinned ? unpin : pin} alt="Pin to Top Icon" />
											</IconButton>
										</InputAdornment>
									}
								/>
								<div style={{ marginTop: "16px" }}>
									<IssuerTagArray control={control} register={register} name="tags" errors={errors} />{" "}
								</div>
								<div style={{ marginTop: "16px" }}>
									<label
										style={{
											fontSize: "0.9rem",
											fontWeight: "bold",
											lineHeight: "1.7rem",
										}}>
										Description
									</label>

									<Controller control={control} name="description" render={({ onChange, value }) => <RichTextEditor ref={descriptionRef} onChange={onChange} defaultValue={value} />} />
								</div>
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Card elevation={0} style={{ backgroundColor: "#fafafa", marginBottom: "0px" }}>
								<Resources
									displayName="Select Resources"
									name="resources"
									control={control}
									errors={errors}
									register={register}
									useInitialKeyName
									validationErrorMessage={errorMessages["resources"]}
									addTypes={[
										{
											displayName: "ADD IMAGE",
											type: ["image/jpeg", "image/png", "image/bmp"],
										},
										{
											displayName: "ADD PDF",
											type: [".pdf"],
										},
										{
											displayName: "ADD URL",
											type: URL,
										},
									]}
								/>
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Card elevation={0} style={{ backgroundColor: "#fafafa", marginBottom: "0px" }}>
								<Content register={register} control={control} errors={errors} name="transcripts" setEncryptingContent={setEncryptingContent} enableImageContentRow={credentialDefinitionSettings?.enableImageContent === true || true} />
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Card elevation={0} style={{ backgroundColor: "#fafafa", marginBottom: "0px" }}>
								<Sections register={register} control={control} name="sections" errors={errors} />
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Card elevation={0} style={{ backgroundColor: "#fafafa", marginBottom: "0px" }}>
								<CapabilityBoundary
									render={() => (
										<Grid item xs={12}>
											<SelectEmailTemplate selectedEmailTemplate={selectedEmailTemplate} setSelectedEmailTemplate={setSelectedEmailTemplate} organizationId={getObjectValue(organizations, "default._id")} isEditing={!!params.id} />
										</Grid>
									)}
									capability={CAPABILITIES.ATTACH_EMAIL_TEMPLATE_TO_CREDENTIAL_DEFINITIONS}
									hide
								/>
								{enableSharingOptions && <SharingOptions sharingOptions={sharingOptions} setSharingOptions={setSharingOptions} />}

								{enablePdfOptions && <PDFSettings encryptingContent={encryptingContent} pdfSettings={pdfSettings} setPdfSettings={setPdfSettings} isEditing={!!params.id} organizationId={getObjectValue(organizations, "default._id")} />}
								{enableMobileWalletOptions && <MobileWalletOptions enableMobileWallet={enableMobileWallet} setEnableMobileWallet={setEnableMobileWallet} />}
								{enableCustomCredentials && (
									<>
										<Grid item xs={12}>
											<SelectCustomCredential selectedCustomCredential={selectedCustomCredential} setSelectedCustomCredential={setSelectedCustomCredential} organizationId={getObjectValue(organizations, "default._id")} />
										</Grid>
										{enableAuthenticationOptions && !!selectedCustomCredential && (
											<Grid item xs={12}>
												<SelectAuthentication authenticationOptions={authenticationOptions} selectedCustomCredential={selectedCustomCredential} setAuthenticationOptions={setAuthenticationOptions} isEdit={params.id} />
											</Grid>
										)}
									</>
								)}
							</Card>
						</Grid>

						<Box elevation={0} style={{ padding: "0.5rem", width: "100%" }}>
							<Card elevation={0} style={{ backgroundColor: "#fafafa", padding: "1rem" }}>
								<Typography variant="h6">Certificate Design</Typography>
								<Controller
									control={control}
									name="designType"
									defaultValue="fabricDesign"
									render={({ value, onChange, name }) => {
										return (
											<RadioGroup aria-label="gender" value={value} name={name} style={{ marginLeft: "8px" }} onChange={onChange}>
												{canView("fabricDesign", true) && <RadioButton value="fabricDesign" label="Design a New Certificate" />}
												{getOrgConfigList.length > 0 ? <RadioButton value="template" label="Use an Existing Design" /> : null}
											</RadioGroup>
										);
									}}
								/>

								{watcher.designType === "template" ? (
									<div style={{ marginTop: "1rem", paddingLeft: "2rem", paddingRight: "2rem" }}>
										<HeaderWithLIne title="Choose Template" variant="h6" />
										<Grid item xs={12} style={{ marginTop: "0.5rem" }}>
											{getOrgConfigList.length && (
												<Controller
													control={control}
													name="rendererConfigId"
													render={({ onChange, value, ref }) => <AutoCompleteRendererConfigOrg initialOptions={getOrgConfigList} onChange={onChange} defaultValue={selectedDefaultRendererConfig} ref={ref} />}
												/>
											)}
										</Grid>
									</div>
								) : watcher.designType === "fabricDesign" ? (
									<FabricCertBuilder control={control} setCopyRendererConfigId={setCopyRendererConfigId} getCertDimensions={(e) => (certDimensionValue.current = e)} />
								) : null}
							</Card>
						</Box>
						<Grid item xs={12} style={{ marginTop: theme.spacing(1) }}>
							<Button type="submit" color="warning" disabled={getObjectValue(credData, "readOnly", false)}>
								Submit
							</Button>
						</Grid>
					</Grid>
				</form>
			</Card> */}
		</>
	);
};

function IssuerTagArray({ control, register, name = "", errors = {} }) {
	const { fields, append, remove, insert } = useFieldArray({
		control,
		name,
	});

	const [error, setError] = useState("");

	return (
		<>
			<Text type="body1">Issuer Tag</Text>
			<TextFieldCapsule
				capsules={fields}
				capsuleValue="key"
				onEnter={(e) => {
					if (e.length > 12) {
						setError("Tag Name should be less than 12 characters");
					} else {
						append({ key: e });
						setError("");
					}
				}}
				onCapsuleCancel={(event, index) => remove(index)}
				helperText={
					error ? (
						error
					) : (
						<div>
							Type the <b>name of the tag</b> and then <b>press enter</b> to save it. You can add up to 3 tags.
						</div>
					)
				}
				maxCapsules={3}
				error={error ? true : false}
			/>
			{fields.map((item, index) => (
				<input style={{ display: "none" }} name={`${name}[${index}].key`} defaultValue={`${item.key}`} ref={register({})} />
			))}
		</>
	);
}

FormDefineCredential.propTypes = {};

export default FormDefineCredential;
