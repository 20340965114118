import { useEffect, useState } from "react";
import { Drawer, makeStyles, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton, useScrollTrigger, Divider, ListItemIcon, Tooltip, Typography, Collapse, Select, MenuItem, FormControl, InputLabel, List } from "@material-ui/core";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import useSettingsHook from "../../hooks/useSettingsHook";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ActionDialog from "../../components/ActionDialog";
import ipwruLogo from "../../assets/images/iPWRU_Logo_Med.png";
import ipwruIcon from "../../assets/images/iPwrU_Icon_Light_L.png";
import { getObjectValue, MULTI_ORGS } from "@cti-workspace/helpers";
import { useDarkMode } from "../../hooks/useDarkModeHook";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { getActiveIndex, getAdminSubMenuItems, isAdminRouteItem } from "./utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@cti-workspace/ui";
import tfsLogo from "../../assets/images/tfs_logo.png";
import { userStore } from "../../store/userStore";
import { routesPerAccountType } from "../RoutesObject";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	hide: {
		display: "none",
	},
	orgContainer: {
		textAlign: "center",
	},
	questContainer: {
		textAlign: "center",
		margin: "2px",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: "hidden",
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing(7) + 1,
	},
	drawerzIndex: {
		zIndex: theme.zIndex.drawer + 1,
	},
	userDetails: {
		width: drawerWidth - 50,
	},
	logoutButton: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		height: "100%",
	},
	helpButton: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
	},
	menuHeader: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: "flex-end",
	},
	submenuItem: {
		padding: "0",
	},
}));

export default (props) => {
	const { open, drawerClick } = props;
	const classes = useStyles();
	
	const [drawerVariant, setDrawerVariant] = useState("permanent");
	const [logoutAlert, setLogoutAlert] = useState(false);
	let uri = document.documentURI;
	let urlValue = new URL(uri);
	let whiteLabelID = urlValue.hostname.split(".")[0];
	const [isAdminOpen, setIsAdminOpen] = useState(false);
	let locationParams = useLocation();
	const navigate = useNavigate();

	const _id = userStore((state) => state._id);
	const organizations = userStore((state) => state.organizations);
	const lastName = userStore((state) => state.lastName);
	const firstName = userStore((state) => state.firstName);
	const email = userStore((state) => state.email);

	const trigger = useScrollTrigger({
		threshold: 51,
	});

	const userLogOut = userStore((state) => state.userLogOut);
	const roles = userStore((state) => state.roles);
	const capabilities = userStore((state) => state.capabilities);
	const { allowedPRoutes } = routesPerAccountType({ roles, capabilities });

	const { getWhiteLabelOptions } = useSettingsHook();

	const largeLogo = getWhiteLabelOptions()?.sideBarConfig?.largeLogoURL || ipwruLogo;
	const largeLogoWidth = getWhiteLabelOptions()?.sideBarConfig?.largeLogoWidth || 210;

	const smallLogo = getWhiteLabelOptions()?.sideBarConfig?.smallLogoURL || ipwruIcon;
	const smallLogoWidth = getWhiteLabelOptions()?.sideBarConfig?.smallLogoWidth || 55;

	const primaryColor = getWhiteLabelOptions()?.walletConfig?.theme?.default?.primaryColor;

	const onMenuItemClick = (route) => {
		if (isAdminRouteItem(route)) {
			setIsAdminOpen(!isAdminOpen);
		} else {
			navigate(route.path);
		}
	};

	const onAdminSubMenuItemClick = (e, index) => {
		e.stopPropagation();
		navigate(`/admin?view=${index}`);
	};

	useEffect(() => {
		//if (width <= 1024) {
		//	setDrawerVariant("temporary");
    //}
    // Retrieve browser window dimensions
    function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height,
      };
    }
    // Handler to call on window resize - this is to fix the drawer graying out issue if initial load is to a small window
    let handleResize = function () {
      const { width } = getWindowDimensions();
      // Set window width/height to state
      if (width <= 1024) {
        setDrawerVariant("temporary");
      }
      else {
        setDrawerVariant("permanent");
      }
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    }
	}, []);

	useEffect(() => {
		const activeIndex = getActiveIndex(locationParams);
		if (activeIndex >= 0 && !isAdminOpen) {
			setIsAdminOpen(true);
		}
	}, [locationParams]);

	const matchParentRoute = (x, y) => {
		const myArray = x.split("/");
		const myArr = y.split("/");
		return myArr[1] === myArray[1];
	};

	return (
		<Drawer
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
				[classes.drawerzIndex]: trigger,
			})}
			variant={drawerVariant}
			anchor="left"
			open={open}
			classes={{
				paper: clsx({
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}),
			}}>
			<div className={classes.menuHeader}>
				{open ? (
					<>
						<UserProfileCard userDetails={{ email, firstName, lastName }} />
						<IconButton id="drawer-click" onClick={() => drawerClick && drawerClick(false)}>
							<ChevronLeftIcon />
						</IconButton>
					</>
				) : trigger ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							width: "100%",
						}}>
						<IconButton color="inherit" aria-label="open drawer" onClick={() => drawerClick && drawerClick(true)} edge="start" style={{ marginLeft: "0px" }} className={clsx(open && classes.hide)}>
							<MenuIcon />
						</IconButton>
					</div>
				) : null}
			</div>
			<Divider />
			<div>
				<Collapse in={open}>
					<OrganizationCard whiteLabelID={whiteLabelID} organizations={organizations} accountId={_id} />
				</Collapse>
			</div>
			{/*  route.path === window.location.pathname need to change */}
			{/*<p style={{ margin: "5px 2px", fontWeight: "bold", color: "darkred" }}>
				There will be intermittent service
				<br />
				over the next two days as we
				<br />
				perform scheduled maintenance.
				<br />
				We apologize for any inconvenience.
			</p>*/}
			<Divider />
			{allowedPRoutes
				.filter((allowedRoute) => allowedRoute.type == "route")
				.map((route, i) => (
					<ListItem
						style={isAdminRouteItem(route) ? { flexWrap: "wrap" } : {}}
						button
						key={route.name}
						color="primary"
						onClick={() => onMenuItemClick(route)}
						disabled={!isAdminRouteItem(route) && matchParentRoute(locationParams.pathname, route.path)}>
						<Tooltip title={open ? "" : route.name} placement="right">
							<ListItemIcon>{route.icon}</ListItemIcon>
						</Tooltip>
						{open && <ListItemText primary={route.name} />}
						{isAdminRouteItem(route) && (isAdminOpen ? <ExpandLess /> : <ExpandMore />)}
						{isAdminRouteItem(route) && (
							<Collapse in={isAdminOpen} timeout="auto" unmountOnExit style={{ width: "100%" }}>
								<List component="div" disablePadding>
									{getAdminSubMenuItems(classes.submenuItem, onAdminSubMenuItemClick, locationParams, open)}
								</List>
							</Collapse>
						)}
					</ListItem>
				))}

			{/* add menu here */}
			<div className={classes.logoutButton}>
				<Divider />
				<ListItem
					button
					color="primary"
					id="logout-button"
					onClick={() => {
						setLogoutAlert(true);
					}}>
					<Tooltip title={open ? "" : "Logout"} placement="right">
						<ListItemIcon>
							<ExitToAppIcon />
						</ListItemIcon>
					</Tooltip>
					<ListItemText primary={"Logout"} />
				</ListItem>
				<Divider />
				<div style={{ maxHeight: "3.5rem" }}>
					<picture>
						<source srcSet={largeLogo} />
						<source srcSet={smallLogo} />
					</picture>
					{window.location.host.includes("tfs.convergence.tech") || window.location.host.includes("tfs.ca") ? (
						<img src={tfsLogo} alt="Trybe.id" style={{ marginLeft: "auto", marginRight: "auto", padding: open ? 2 : 10, maxHeight: "3.5rem" }} />
					) : open ? (
						<img src={largeLogo} alt="Trybe.id" width={largeLogoWidth} style={{ marginLeft: "auto", marginRight: "auto", padding: 1, height: "auto" }} />
					) : (
						<img src={smallLogo} alt="Trybe.id" width={smallLogoWidth} style={{ marginLeft: "auto", marginRight: "auto", padding: 5, height: "auto" }} />
					)}
				</div>
			</div>
			<ActionDialog
				open={logoutAlert}
				title="Logout"
				content={
					primaryColor ? (
						<Typography variant="h6" style={{ color: primaryColor }}>
							Are you sure you want to Logout?
						</Typography>
					) : (
						<Typography variant="h6" color="secondary">
							Are you sure you want to Logout?
						</Typography>
					)
				}
				primaryAction={
					primaryColor ? (
						<Button
							id="logout"
							onClick={() => {
								setLogoutAlert(false);
								userLogOut();
								navigate("/login");
								window.location.reload();
							}}
							buttonStyle={{ backgroundColor: primaryColor, borderColor: primaryColor }}
							variant={"contained"}>
							Logout
						</Button>
					) : (
						<Button
							id="logout"
							onClick={() => {
								setLogoutAlert(false);
								userLogOut();
								navigate("/login");
								window.location.reload();
							}}
							color="warning"
							variant={"contained"}>
							Logout
						</Button>
					)
				}
				secondaryAction={
					<Button color="danger" invert onClick={() => setLogoutAlert(false)}>
						Cancel
					</Button>
				}
				onClose={() => setLogoutAlert(false)}
			/>
		</Drawer>
	);
};

function UserProfileCard(props) {
	const {
		userDetails: { email, firstName, lastName },
	} = props;
	const classes = useStyles();
	return (
		<ListItem className={classes.userDetails}>
			<ListItemAvatar>
				<Avatar>
					{firstName && firstName[0].toUpperCase()}
					{lastName && lastName[0].toUpperCase()}
				</Avatar>
			</ListItemAvatar>
			<ListItemText
				primary={
					<Tooltip title={`${firstName} ${lastName}`} arrow>
						<Typography variant="subtitle1">{sliceData(`${firstName} ${lastName}`, 18)}</Typography>
					</Tooltip>
				}
				secondary={
					<Tooltip title={email} arrow>
						<Typography component="span" variant="subtitle2">
							{sliceData(email, 18)}
						</Typography>
					</Tooltip>
				}
			/>
		</ListItem>
	);
}

function OrganizationCard(props) {
	const { whiteLabelID, accountId, ...rest } = props;
	const classes = useStyles();
	const [multiOrgs, setMultiOrgs] = useState({});
	const orgLogoUrl = window.location.host.includes("tfs.convergence.tech") || window.location.host.includes("tfs.ca") ? tfsLogo : props.organizations?.default?.imageUrls?.default;
	let val = whiteLabelID === "iqcert" ? "" : <img src={orgLogoUrl} style={{ maxWidth: "200px", maxHeight: "150px" }} />;

	// const { setDefaultOrg } = useUserHook();

	const setDefaultOrg = userStore((state) => state.setDefaultOrg);

	const [theme] = useDarkMode();

	useEffect(() => {
		const hasmultiorgs = localStorage.getItem(MULTI_ORGS);
		if (hasmultiorgs) {
			setMultiOrgs(JSON.parse(hasmultiorgs));
		}
	}, []);

	const multiOrgSelect = async (event) => {
		try {
			const { value } = event.target;
			await setDefaultOrg({ orgId: value, accountId: accountId });
			window.location.reload(true);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<div {...rest}>
			<ListItem style={{ backgroundColor: "#ffffff" }}>
				<ListItemText
					className={classes.orgContainer}
					primary={val}
					secondary={
						<Tooltip title={props.organizations?.default?.name} arrow>
							<Typography component="span" variant="subtitle2" style={{ color: theme === "light" ? "#0000008a" : "#324353" }}>
								{sliceData(props.organizations?.default?.name, 18)}
							</Typography>
						</Tooltip>
					}
				/>
			</ListItem>
			{/* select organization if the user has */}
			{Object.keys(multiOrgs || {}).length > 0 ? (
				<div style={{ margin: "1rem" }}>
					<FormControl fullWidth variant="outlined" size="small">
						<InputLabel>Select Organization</InputLabel>
						<Select label="Select Organization" defaultValue={props.organizations?.default?._id} onChange={multiOrgSelect}>
							{Object.keys(multiOrgs || {}).map((item, index) => (
								<MenuItem key={index} value={getObjectValue(multiOrgs, item)}>
									{item}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			) : null}
			<Divider />
		</div>
	);
}

function sliceData(str = "", len = 0) {
	const strLen = str.length;
	return str.slice(0, len > 0 ? len : strLen) + (len < strLen && len > 0 ? "..." : "");
}
