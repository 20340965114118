import { Fragment, useEffect, useState } from "react";
import { SETTINGS, getObjectValue } from "@cti-workspace/helpers";

export default function ViewWrapper({ prop = "", value = false, children, props }) {
	const [settings, setSettings] = useState({});
	useEffect(() => {
		const settingsData = localStorage.getItem(SETTINGS);
		if (settingsData) {
			const settingsObj = JSON.parse(settingsData);
			setSettings(settingsObj);
		}
	}, []);
	return getObjectValue(settings, prop) === value ? children : null;
}
