import { Component } from "react";
import { AtomicBlockUtils } from "draft-js";

class ImageSource extends Component {
	componentDidMount() {
		const { editorState, entityType, onComplete, data } = this.props;
		// console.log(data);

		if (data && data.imageUrl) {
			const styleString = window.prompt("Image styleString");
			const content = editorState.getCurrentContent();
			const contentWithEntity = content.createEntity(entityType.type, "IMMUTABLE", {
				src: "https://s3.ca-central-1.amazonaws.com/trybe.public.assets/local/Convergence.tech/4GCKkis5NsGTdmxZaPcpyU.png",
				styleString,
			});
			const entityKey = contentWithEntity.getLastCreatedEntityKey();
			const nextState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, " ");

			onComplete(nextState);
		} else {
			const src = window.prompt("Image URL");
			const styleString = window.prompt("Image styleString");
			if (src) {
				const content = editorState.getCurrentContent();
				const contentWithEntity = content.createEntity(entityType.type, "IMMUTABLE", { src, styleString });
				const entityKey = contentWithEntity.getLastCreatedEntityKey();
				const nextState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, " ");

				onComplete(nextState);
			} else {
				onComplete(editorState);
			}
		}
	}

	render() {
		return null;
	}
}
export default ImageSource;
