import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LoginRoute from "./login.route";
import Style from "./login.style";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import CAPABILITIES from "../../capabilities";
import { userStore } from "../../store/userStore";
import { getErrMsg, getObjectValue, PREV_ROUTE } from "@cti-workspace/helpers";

const navigateBasedOnCapabilities = (capabilities, navigate, prevRoute = "") => {
	if (prevRoute) {
		sessionStorage.removeItem(PREV_ROUTE);
		navigate(prevRoute);
	} else {
		const { [CAPABILITIES.ACCESS_CREDENTIAL_CATALOG]: ACCESS_CREDENTIAL_CATALOG, [CAPABILITIES.ACCESS_ISSUED_CREDENTIALS]: ACCESS_ISSUED_CREDENTIALS, [CAPABILITIES.ACCESS_ANALYTICS]: ACCESS_ANALYTICS } = capabilities ?? {};
		if (ACCESS_CREDENTIAL_CATALOG) {
			navigate("/home");
		} else if (ACCESS_ISSUED_CREDENTIALS) {
			navigate("/issuedcredentials");
		} else if (ACCESS_ANALYTICS) {
			navigate("/analytics");
		} else {
			navigate("/profile");
		}
	}
};

export default function LoginContainer() {
	const useStyles = makeStyles(Style);
	const classes = useStyles();
	const [values, setValues] = useState({
		email: "",
		password: "",
		showPassword: false,
	});
	const [orgs, setOrgs] = useState([]);
	const [selectedOrg, setSelectedOrg] = useState("");
	const [accountId, setAccountId] = useState("");
	const { enqueueSnackbar } = useSnackbar();

	const setIsLoggedIn = userStore((state) => state.userLogIn);
	const roles = userStore((state) => state.roles);
	const capabilities = userStore((state) => state.capabilities);
	const hasToken = userStore((state) => state.hasToken);

	let uri = document.documentURI;
	// let uri = "https://wallet.acn.edu.au/wallet";
	let urlValue = new URL(uri);
	let whiteLabelID = "";

	const substrings = ["certificates", "wallet"];
	let str = urlValue.hostname.split(".")[0];

	// Setup
	console.log(`Substrings: ${substrings}`);

	// Try it where we expect a match
	if (substrings.some((v) => str.includes(v))) {
		whiteLabelID = urlValue.hostname.split(".")[1];
	} else {
		whiteLabelID = urlValue.hostname.split(".")[0];
	}

	// const getHostname = (url) => {
	// 	// use URL constructor and return hostname
	// 	return new URL(url).hostname.split(".")[0];
	// };

	// // tests
	// console.log(getHostname("http://wallet.acn.edu.au/"));

	let navigate = useNavigate();
	let location = useLocation();
	const prevRoute = sessionStorage.getItem(PREV_ROUTE);
	let { from } = location.state || { from: { pathname: prevRoute || "/" } };

	useEffect(() => {
		const jwtToken = localStorage.getItem("jwtToken");
		if (hasToken && jwtToken) {
			if (roles.length === 1 && roles.includes("verifier")) {
				navigate("/presentation-catalog", { replace: true });
			} else {
				navigateBasedOnCapabilities(capabilities, navigate, prevRoute);
			}
		}
	}, []);

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	function validateForm() {
		let { email, password } = values;
		if (!email || !password) {
			return false;
		}
		return true;
	}

	const completeLogin = (value) => {
		sessionStorage.setItem("whiteLabelID", whiteLabelID);
		let { dbAccount } = value;
		if (dbAccount) {
			setAccountId(dbAccount._id);

			const capabilities = dbAccount.capabilities || null;
			// console.log(capabilities);

			if (dbAccount && dbAccount.roles.length === 1 && dbAccount.roles.includes("holder")) {
				enqueueSnackbar("Login Success", { variant: "success" });
				if (!capabilities) {
					navigate("/wallet", { replace: true });
				} else {
					navigateBasedOnCapabilities(capabilities, navigate, prevRoute);
				}
			} else if (dbAccount && dbAccount.roles.length === 1 && dbAccount.roles.includes("verifier")) {
				navigate("/presentation-catalog", { replace: true });
			} else {
				if (dbAccount?.organizations?.default) {
					enqueueSnackbar("Login Success", { variant: "success" });
					if (!capabilities) {
						sessionStorage.removeItem(PREV_ROUTE);
						navigate(from, { replace: 1 });
					} else {
						navigateBasedOnCapabilities(capabilities, navigate, prevRoute);
					}
				} else {
					var keys = [];
					for (const property in dbAccount.organizations) {
						const { [property]: propertyValue } = dbAccount.organizations;
						keys.push({ name: property, value: propertyValue });
					}
					setOrgs([...keys]);
				}
			}
		} else {
			enqueueSnackbar("Login failed", { variant: "error" });
		}
	};

	let onSubmit = async () => {
		try {
			if (selectedOrg) {
				try {
					let value = await setIsLoggedIn({ organization: { orgId: selectedOrg, accountId: accountId } });
					completeLogin(value);
				} catch (err) {
					console.error(err);
					enqueueSnackbar(getErrMsg(err), { variant: "error" });
				}
			} else if (validateForm()) {
				// enqueueSnackbar("Validation Success", { variant: "success" });
				try {
					let { email, password } = values;
					let value = await setIsLoggedIn({ email, password });
					completeLogin(value);
				} catch (err) {
					console.error(err);
					enqueueSnackbar(getErrMsg(err), { variant: "error" });
					// window.location.reload();
				}
			} else {
				enqueueSnackbar("Validation failed", { variant: "error" });
			}
		} catch (err) {
			enqueueSnackbar("Login failed", { variant: "error" });
			console.error("err on submit", err);
		}
	};

	let onGoogleSubmit = async (googleData) => {
		try {
			let value = await setIsLoggedIn({ email: "", password: "", organization: "" }, null, false, true, googleData);
			completeLogin(value);
		} catch (error) {
			enqueueSnackbar("Google Login failed", { variant: "error" });
			console.error("err on submit", error);
		}
	};

	return (
		<LoginRoute
			classes={classes}
			onSubmit={onSubmit}
			values={values}
			handleChange={handleChange}
			handleClickShowPassword={handleClickShowPassword}
			handleMouseDownPassword={handleMouseDownPassword}
			whiteLabelID={whiteLabelID}
			onGoogleSubmit={onGoogleSubmit}
			orgs={orgs}
			selectedOrg={setSelectedOrg}
		/>
	);
}
