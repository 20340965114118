import { useState } from "react";
import CreateNewEmailTemplate from "./CreateNewEmailTemplate";
import UpdateOrgEmailTemplate from "./UpdateOrgEmailTemplate";
import { useSnackbar } from "notistack";
import { getErrMsg, fetchEmailTemplates } from "@cti-workspace/helpers";

const EmailTemplates = () => {
	const { enqueueSnackbar } = useSnackbar();
	const [emailTemplates, setEmailTemplates] = useState([]);

	const getEmailTemplates = async (type = "SEND_GRID", category = "ISSUE_CREDENTIALS") => {
		let data = [];
		try {
			data = await fetchEmailTemplates(type, category);
		} catch (e) {
			enqueueSnackbar(getErrMsg(null, `Failed to retrieve Email Templates.`), { variant: "error" });
		} finally {
			setEmailTemplates(data);
		}
	};

	return (
		<div>
			<CreateNewEmailTemplate />
			<br />
			<UpdateOrgEmailTemplate emailTemplates={emailTemplates} getEmailTemplates={getEmailTemplates} />
		</div>
	);
};

export default EmailTemplates;
