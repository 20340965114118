import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useEffect, useState } from "react";
import { getApi, getObjectValue, ORGANIZATIONS_PREFIX } from "@cti-workspace/helpers";
import { CaretDoubleRight } from "@phosphor-icons/react";
import { CheckBox, Typography as Text } from "@cti-workspace/ui";

/**
 * [{
	identityProvider: 'CTI',
	claims: [
	  {
		  'property': 'firstName',
		  'idpClaimName': 'firstName', // Always the same for C.TI, will use the user entered mapping for other IDPs
		  'name': 'First Name',
		  'description': 'First Name', // use .name
		  'acceptedValues': [], // populated during issuance
		  'reason': 'authentication' // static
	  },
	  {
		  'property': 'lastName',
		  'idpClaimName': 'lastName',
		  'name': 'Last Name',
		  'description': 'Last Name',
		  'acceptedValues': [],
		  'reason': 'authentication'
	  },
	]
  }],
 */

export const DestructureAuthenticationArray = (val) => {
	let result = { ctiBased: { main: false }, googleBased: { main: false } };
	val?.map((item) => {
		if (item?.identityProvider === "CTI") {
			result["ctiBased"].main = true;
			item?.claims?.map((temp) => {
				if (!temp) return;
				result["ctiBased"] = {
					...result["ctiBased"],
					[temp.property]: { ...temp },
				};
			});
		}
		if (item?.identityProvider === "Google") {
			result["googleBased"].main = true;
			item?.claims?.map((temp) => {
				result["googleBased"] = {
					...result["googleBased"],
					[temp.property]: { ...temp },
				};
			});
		}
	});
	return result;
};

export const BuildAuthenticationObject = (data) => {
	// console.log(data);

	let result = [];
	if (data?.ctiBased?.main) {
		let obj = { identityProvider: "CTI", claims: [] };
		const { main, ...rest } = data.ctiBased;
		// console.log(rest);
		for (let key in rest) {
			const { [key]: restValue } = rest;
			obj.claims.push(restValue);
		}
		//   console.log(obj)
		result.push(obj);
	}
	if (data?.googleBased?.main) {
		let obj = { identityProvider: "Google", claims: [] };
		const { main, ...rest } = data.googleBased;
		// console.log(rest);
		for (let key in rest) {
			const { [key]: restValue } = rest;
			obj.claims.push(restValue);
		}
		//   console.log(obj)
		result.push(obj);
	}
	// console.log(result)
	// function is called on submit
	return result;
};

function SelectAuthentication({ authenticationOptions, selectedCustomCredential, setAuthenticationOptions, isEdit }) {
	const [options, setoptions] = useState([]);
	useEffect(() => {
		console.log(selectedCustomCredential);
		let isSubscribed = true;

		const fetchData = async () => {
			// additional headers does not work here
			const { customCredentials } = await getApi(`/${ORGANIZATIONS_PREFIX}/getCustomCredentials`, { customCredentialId: selectedCustomCredential });

			if (isSubscribed) {
				const filterCustomCredentials = customCredentials.filter((x) => x["_id"] === selectedCustomCredential);
				console.log(filterCustomCredentials);
				setoptions(filterCustomCredentials[0].credentialMappings);
			}
		};
		if (selectedCustomCredential) {
			fetchData().catch(console.error);
		} else {
			setoptions([]);
			setAuthenticationOptions({ ctiBased: { main: false }, googleBased: { main: false } });
		}

		return () => {
			isSubscribed = false;
			setAuthenticationOptions({ ctiBased: { main: false }, googleBased: { main: false } });
		};
	}, [selectedCustomCredential, isEdit]);

	return (
		<div>
			<Text type="body1">Select Authentication Options</Text>
			<div style={{ display: "flex", alignItems: "center", gap: "2rem", marginTop: "0.5rem" }}>
				<Text type="body2">Identity Provider (OIDC)</Text>
				<div style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}>
					<CheckBox
						label="iPWRU"
						name="ctiBased"
						labelMargin="medium"
						checked={authenticationOptions?.ctiBased?.main}
						onChange={(e) => {
							setAuthenticationOptions({ ...authenticationOptions, ctiBased: { main: e.target.checked } });
						}}
						labelStyle={{ fontSize: "0.8rem" }}
					/>
					<CheckBox
						label="Google"
						name="googleBased"
						labelMargin="medium"
						checked={authenticationOptions?.googleBased?.main}
						onChange={(e) => {
							setAuthenticationOptions({ ...authenticationOptions, googleBased: { main: e.target.checked } });
						}}
						labelStyle={{ fontSize: "0.8rem" }}
					/>
				</div>
			</div>
			<div style={{ marginTop: "1rem", display: "flex", flexWrap: "nowrap", gap: "1rem" }}>
				{authenticationOptions?.ctiBased?.main && (
					<div style={{ width: "100%" }}>
						<Text type="body2">iPWRU Mapping Options</Text>
						<div style={{ display: "flex", flexDirection: "column", marginTop: "1rem", gap: "0.5rem" }}>
							{options.map((item, index) => (
								<CheckBox
									key={index}
									label={`${item.credDefContentName}`}
									name={`${item.credDefContentName}`}
									labelMargin="medium"
									checked={!!getObjectValue(authenticationOptions.ctiBased, item.credSubjectKey)}
									onChange={(e) => {
										if (e.target.checked) {
											setAuthenticationOptions({
												...authenticationOptions,
												ctiBased: {
													...authenticationOptions.ctiBased,
													[`${item.credSubjectKey}`]: {
														property: item.credSubjectKey,
														idpClaimName: item.credSubjectKey, // Always the same for C.TI, will use the user entered mapping for other IDPs
														name: item.credDefContentName,
														description: item.credDefContentName, // use .name
														acceptedValues: [], // populated during issuance
														reason: "authentication", // static
													},
												},
											});
										} else {
											let val = authenticationOptions.ctiBased;
											val = { ...val, [`${item.credSubjectKey}`]: undefined };
											setAuthenticationOptions({ ...authenticationOptions, ctiBased: { ...val } });
										}
									}}
									labelStyle={{ fontSize: "0.8rem" }}
								/>
							))}
						</div>
					</div>
				)}
				{authenticationOptions?.googleBased?.main && (
					<div style={{ width: "100%" }}>
						<Text type="body2">Google Mapping Options</Text>
						<div style={{ display: "flex", flexDirection: "column", marginTop: "1rem", gap: "0.5rem" }}>
							{options.map((item, index) => (
								<div key={index} style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
									<CheckBox
										name={`${item.credDefContentName}`}
										label={
											<div style={{ display: "flex", alignItems: "center" }}>
												{item.credDefContentName}
												<div style={{ width: 10 }} />
												<CaretDoubleRight />
											</div>
										}
										labelMargin="medium"
										checked={!!getObjectValue(authenticationOptions.googleBased, item.credSubjectKey)}
										onChange={(e) => {
											if (e.target.checked) {
												setAuthenticationOptions({
													...authenticationOptions,
													googleBased: {
														...authenticationOptions.googleBased,
														[`${item.credSubjectKey}`]: {
															property: item.credSubjectKey,
															// Always the same for C.TI, will use the user entered mapping for other IDPs
															name: item.credDefContentName,
															description: item.credDefContentName, // use .name
															acceptedValues: [], // populated during issuance
															reason: "authentication", // static
														},
													},
												});
											} else {
												let val = authenticationOptions.googleBased;
												val = { ...val, [`${item.credSubjectKey}`]: undefined };
												setAuthenticationOptions({ ...authenticationOptions, googleBased: { ...val } });
											}
										}}
										labelStyle={{ fontSize: "0.8rem" }}
									/>
									<input
										disabled={!getObjectValue(authenticationOptions.googleBased, item.credSubjectKey)}
										required
										value={getObjectValue(authenticationOptions.googleBased, `${item.credSubjectKey}.idpClaimName`, "")}
										onChange={({ target }) => {
											// console.log(authenticationOptions);
											const { [item.credSubjectKey]: googleBased } = authenticationOptions.googleBased;
											setAuthenticationOptions({
												...authenticationOptions,
												googleBased: {
													...authenticationOptions.googleBased,
													[`${item.credSubjectKey}`]: {
														...googleBased,
														idpClaimName: target.value,
													},
												},
											});
										}}
									/>
								</div>
							))}
						</div>
					</div>
				)}
			</div>

			{/* for future reference another child */}
			{/* <Grid container item direction="column" justifyContent="space-between" spacing={1} xs={6}>
				<Grid item>
					<Typography variant="body1" color="initial">
						Google Mapping Options
					</Typography>
				</Grid>
				<Grid item>
					<FormControlLabel
						control={
							<Checkbox
								name="ctiBased"
								checked={authenticationOptions.ctiBased}

								onChange={(e) => {
									setAuthenticationOptions({...authenticationOptions,ctiBased:e.target.checked})
								}}
							/>
						}
						label="C.TI"
					/>
				</Grid>
			</Grid> */}
		</div>
	);
}

export default SelectAuthentication;
