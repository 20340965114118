import Fade from "@material-ui/core/Fade";

import useScrollTrigger from "@material-ui/core/useScrollTrigger";

const HideOnsScroll = (props) => {
	const { children } = props;

	const trigger = useScrollTrigger({
		threshold: 50,
	});

	return (
		<Fade appear={false} in={!trigger}>
			{children}
		</Fade>
	);
};

export default HideOnsScroll;
