import { ISSUE_CREDENTIALS, ORGANIZATIONS_PREFIX, ORG } from "@cti-workspace/helpers";
import { getApi } from "./api";

export default async function fetchOrgPDFTemplates(organizationId, category = ISSUE_CREDENTIALS, templateFor = ORG) {
	const pdfTemplates = await getApi(`/${ORGANIZATIONS_PREFIX}/getPdfTemplates?organizationId=${organizationId}&category=${category}&templateFor=${templateFor}`);
	return pdfTemplates.pdfTemplates.map((pdfTemplate) => ({
		...pdfTemplate,
		default: pdfTemplate.categoryDefault,
		id: pdfTemplate.templateId,
	}));
}
