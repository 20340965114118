import { ADMIN_PREFIX, getApi } from "@cti-workspace/helpers";
import { CAPABILITIES_ERROR_MESSAGES } from "../../../capabilities";

export const getRoles = async () => {
	return getApi(`/${ADMIN_PREFIX}/listRoles`);
};

export const getCapabilities = async () => {
	return getApi(`/${ADMIN_PREFIX}/listCapabilities`);
};

export const isCapable = (capabilities, capability) => {
	if (capabilities && capability) {
		return capability in capabilities;
	}
	return false;
};

export const isPermissionUnauthorizedError = (type, message) => {
	return type === CAPABILITIES_ERROR_MESSAGES.UNAUTHORIZED && message === CAPABILITIES_ERROR_MESSAGES.NOT_ALLOWED;
};
