import Typography from "../typography/Typography";
import styled from "styled-components";
import Theme from "../theme/theme";
import { TextField } from "../textField/textField";
import Button from "../button/button";
import { useState } from "react";

const CapsuleContainer = styled.div`
    background-color: ${Theme.colors.warning};
    display: flex;
    align-items: center;
    padding: 0.2rem 0.6rem;
    gap: 0.3rem;
    border-radius: 15px;
    flex-wrap: nowrap;
`;

const TextFieldCapsuleContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
`;

export function TextFieldCapsule({ onEnter = () => { }, capsules = [], capsuleValue = "", onCapsuleCancel = () => { }, maxCapsules = 0, disabled = false, ...rest }) {
    const [value, setValue] = useState("");
    return <TextField
        size="small"
        startAdornment={capsules.length > 0 ? <TextFieldCapsuleContainer>
            {
                capsules.map((item, index) => <Capsule key={item[capsuleValue]} label={item[capsuleValue]} onCancel={() => onCapsuleCancel(item, index)} />)
            }
        </TextFieldCapsuleContainer> : null}
        onChange={(e) => {
            setValue(e.target.value);
        }}
        onKeyDown={(e) => {
            const { key, keyCode, type } = e;
            if (key === "Enter" && type === "keydown") {
                e.preventDefault();
                onEnter(value);
                setValue("");
            }
        }}
        value={value}
        disabled={disabled || (maxCapsules > 0 && maxCapsules === capsules.length ? true : false)}
        {...rest}
    />
}

function Capsule({ label = "", onCancel = () => { } }) {
    return <CapsuleContainer>
        <Typography type="body6" color="white">{label}</Typography>
        <Button buttonType="icon" icon="cancel" invert iconSize={10} color="white" buttonTransparent buttonStyle={{ boxShadow: "none", padding: "0px" }} onClick={onCancel} />
    </CapsuleContainer>
}

export default TextFieldCapsule;