import { PATHWAY_PREFIX, ORGANIZATIONS_PREFIX } from "../lib/Constants";
import { useNProgressHook, useWidthHook, postApi, getApi, delApi, putApi } from "@cti-workspace/helpers";

export default function usePathwaysHook() {
	const { progressStart, progressDone } = useNProgressHook();
	const currentWidth = useWidthHook();

	const definePathway = async (data = {}) => {
		progressStart();
		try {
			const res = await postApi(`/${PATHWAY_PREFIX}/addPathway`, { ...data });
			return res;
		} catch (error) {
			throw error?.message || "Something went wrong, please try again";
		} finally {
			progressDone();
		}
	};

	const editPathway = async (data = {}, id) => {
		progressStart();
		try {
			const res = await putApi(`/${PATHWAY_PREFIX}/${id}`, { ...data });
			return res;
		} catch (error) {
			throw error?.message || "Something went wrong, please try again";
		} finally {
			progressDone();
		}
	};

	const getPathways = async (pageSize = 10, pageNumber = 1, search = "") => {
		progressStart();
		try {
			switch (currentWidth) {
				case "xs":
					pageSize = 10;
					break;
				case "sm":
				case "md":
					pageSize = 20;
					break;
				case "lg":
					pageSize = 9;
					break;
				default:
					pageSize = 9;
			}
			const res = await getApi(`/${PATHWAY_PREFIX}/getAll?pageSize=${pageSize}&pageNumber=${pageNumber}&search=${search || ""}`);
			return res;
		} catch (error) {
			throw error?.message || "Something went wrong, please try again";
		} finally {
			progressDone();
		}
	};

	const getPathway = async (id = "") => {
		progressStart();
		try {
			const { pathway } = await getApi(`/${PATHWAY_PREFIX}/${id}`);
			return pathway;
		} catch (error) {
			throw error?.message || "Something went wrong, please try again";
		} finally {
			progressDone();
		}
	};

	const addPathwayStep = async (data = {}, id) => {
		// "parentId": "65dbd7d8-4280-4405-acee-10130f0144ee",
		// "name": "My First Pathway - Chaild",
		// "description": "My First Pathway - Chaild",
		// "icon": "https://s3.ca-central-1.amazonaws.com/trybe.public.assets/Trybe.ID/seal.png",
		// "type": "ACTIVITY",
		// "credentialId": "62fc73bfa51f44002c0b4917",
		// "level": 1,
		// "isRequired": true,
		// "status": "ACTIVE"

		progressStart();
		try {
			const res = await putApi(`/${PATHWAY_PREFIX}/addPathwayStep/${id}`, { ...data });
			return res;
		} catch (error) {
			throw error?.message || "Something went wrong, please try again";
		} finally {
			progressDone();
		}
	};

	const deletePathway = async (id = "") => {
		progressStart();
		try {
			const res = await delApi(`/${PATHWAY_PREFIX}/${id}`);
			return res;
		} catch (error) {
			throw error?.message || "Something went wrong, please try again";
		} finally {
			progressDone();
		}
	};

	const deletePathwayStep = async (id = "", stepId) => {
		progressStart();
		try {
			const res = await putApi(`/${PATHWAY_PREFIX}/deletePathwayStep/${id}`, { stepId });
			return res;
		} catch (error) {
			throw error?.message || "Something went wrong, please try again";
		} finally {
			progressDone();
		}
	};

	const copyPathway = async (id) => {
		progressStart();
		try {
			const { _id } = await postApi(`/${PATHWAY_PREFIX}/copyPathway/${id}`);
			return _id;
		} catch (error) {
			throw error;
		} finally {
			progressDone();
		}
	};

	const getPathwayPastRecipients = async () => {
		progressStart();
		try {
			const organizationContacts = await getApi(`/${ORGANIZATIONS_PREFIX}/getContacts`);
			if (organizationContacts.length) {
				return organizationContacts
					.filter((contact) => contact.type === "email")
					.map((contact) => ({
						...contact,
						email: contact.contact,
					}));
			}
			return [];
		} catch (error) {
			throw error;
		} finally {
			progressDone();
		}
	};

	const inviteToPathway = async (pathwayId, allRecipients) => {
		progressStart();

		try {
			const inviteToPathwayRequest = {
				pathwayId,
				userData: allRecipients.map((recipient) => ({
					firstName: recipient.firstName.trim(),
					lastName: recipient.lastName.trim(),
					email: recipient.email.trim(),
					pathwayId,
				})),
			};

			await postApi(`/${PATHWAY_PREFIX}/inviteOne`, inviteToPathwayRequest);

			const newRecipients = allRecipients.filter((recipient) => !recipient.type);
			if (newRecipients.length) {
				try {
					const newContactsRequest = {
						contacts: newRecipients.map((recipient) => [recipient.firstName.trim(), recipient.lastName.trim(), recipient.email.trim(), "email"]),
					};
					await putApi(`/${ORGANIZATIONS_PREFIX}/addContacts`, newContactsRequest);
				} catch (error) {
					console.error(error);
				}
			}
		} catch (error) {
			throw error;
		} finally {
			progressDone();
		}
	};

	return { definePathway, getPathways, getPathway, deletePathway, deletePathwayStep, editPathway, addPathwayStep, copyPathway, getPathwayPastRecipients, inviteToPathway };
}
