import { useState, useEffect, useMemo, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import IntegrationConnectionCard from "../IntegrationConnectionCard/IntegrationConnectionCard";
import { FormattedMessage } from "react-intl";
import { useSnackbar } from "notistack";
import useCredentialHook from "../../hooks/useCredentialHook";
import useCertificateHook from "../../hooks/useCertificateHook";
import useGoogleClassroomHook from "../../hooks/useGoogleClassroomHook";
import useCanvasHook from "../../hooks/useCanvasHook";
import useSchoologyHook from "../../hooks/useSchoologyHook";
import useBridgeHook from "../../hooks/useBridgeHook";
import { useLocation, useNavigate } from "react-router-dom";
import cookie from "js-cookie";
import googleClassroomImage from "../../assets/images/google-classroom.png";
import canvasImage from "../../assets/images/canvas.png";
import schoologyImage from "../../assets/images/schoology.png";
import bridgeImage from "../../assets/images/bridge.jpg";
import brightspaceImage from "../../assets/images/brightspace.png";
import useBrightspaceHook from "../../hooks/useBrightspaceHook";
import tcsIONImage from "../../assets/images/tcs_ion_logo.png"
import useTCSIONHook from "../../hooks/useTCSIONHook";
import moodleImage from "../../assets/images/moodle.png"
import useMoodleHook from "../../hooks/useMoodleHook";
import Grid from "@material-ui/core/Grid";
import ViewWrapper from "../ViewWrapper/ViewWrapper";
import useSettingsHook from "../../hooks/useSettingsHook";
import useNProgressHook from "../../hooks/useNProgressHook";
import CAPABILITIES from "../../capabilities";
import { isCapable } from "../../routes/admin/userAccounts/permissionUtils";
import { Credentials, Integrations } from "../CredentialCatalog";
import { userStore } from "../../store/userStore";
import { Tabs as CredTabs } from "@cti-workspace/ui";
import { getErrMsg, bulkIssueCredDefsFromXlsx, getObjectValue } from "@cti-workspace/helpers";
// import { Box } from '@cti-workspace/ui';

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		marginTop: theme.spacing(2),
	},
	tabSelected: {
		background: "#FFF2E5",
	},
	categoryBar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "1rem 0",
		[theme.breakpoints.between("xs", "sm")]: {
			flexDirection: "column",
		},
	},
	leftBox: {
		flex: "auto",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
	RightBox: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		[theme.breakpoints.between("xs", "sm")]: {
			padding: "1rem 0",
		},
	},
	flatButton: {
		background: "#F6DABE",
		zIndex: 0,
		justifyContent: "space-between",
	},
	iconButton: {
		padding: "0 0.5rem",
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		overflow: "auto",
	},
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<Typography component="div" role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
			{value === index && <Box marginTop={3}>{children}</Box>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	};
}

const Body = () => {
	const classes = useStyles();
	const theme = useTheme();
	const [value, setValue] = useState(0);
	const [selectedIntegrationProvider, setSelectedIntegrationProvider] = useState("");
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const JOY_HELP_STATE = "JOY_HELP_STATE";
	const [searchResult, setSearchResult] = useState([]);
	const { progressStart, progressDone } = useNProgressHook();
	const [showAddCredDefExtendedOptions, setShowAddCredDefExtendedOptions] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [lmsSearchValue, setLmsSearchValue] = useState("");

	let location = useLocation();
	let email = userStore((state) => state.email);
	let organizations = userStore((state) => state.organizations);
	let capabilities = userStore((state) => state.capabilities);

	const roles = userStore((state) => state.roles);

	const { certificates } = useCertificateHook({});
	const { connectedCredsData, setConnectedCredsData, credsData, setCredsData, addCreds, deleteCredentialDefinition, copyCredentialDefinition, archiveCredentialDefinition, getCredsPerPage, credentials, setCredentials, bulkAddCreds } =
		useCredentialHook({
			account: { roles, organizations },
			location,
		});

	const googleClassroomHook = useGoogleClassroomHook({
		organizations,
		connectedCredsData,
		setConnectedCredsData,
	});

	const canvasHook = useCanvasHook({
		organizations,
		email,
		connectedCredsData,
		setConnectedCredsData,
	});

	const schoologyHook = useSchoologyHook({
		organizations,
		connectedCredsData,
		setConnectedCredsData,
	});

	const bridgeHook = useBridgeHook({
		organizations,
		connectedCredsData,
		setConnectedCredsData,
	});

	const brightspaceHook = useBrightspaceHook({
		organizations,
		connectedCredsData,
		setConnectedCredsData,
	});

	const tCSIONHook = useTCSIONHook({
		organizations,
		connectedCredsData,
		setConnectedCredsData,
	});

	const moodleHook = useMoodleHook({
		organizations,
		connectedCredsData,
		setConnectedCredsData,
	});

	const { canView } = useSettingsHook();

	let getSearchValue = "";

	useEffect(() => {
		// Check if there is a query param, meaning result of an integration auth flow
		if (window.location.search) {
			const params = new URLSearchParams(window.location.search);
			const code = params.get("code");
			const oauth_token = params.get("oauth_token");
			const state = params.get("state");

			console.log("oauth_token - ", oauth_token);

			if (code && !state) {
				//Only applicable for canvas redirect
				// Send this down to the adapter, will get token and manage access
				if (window.location.href.indexOf("canvas") > -1) {
					canvasHook.saveAuthorizationCredentials(window.location.search);
				} else {
					googleClassroomHook.saveAuthorizationCredentials(window.location.search);
				}

				// And remove from search from URL
				window.history.replaceState({}, window.location.origin, "/");

				// Switch to integrations tap
				handleChangeIndex(1);
			} else if (oauth_token) {
				//Only applicable for schoology redirect
				schoologyHook.saveAuthorizationCredentials(oauth_token);

				// And remove from search from URL
				window.history.replaceState({}, window.location.origin, "/");

				// Switch to integrations tap
				handleChangeIndex(1);
			} else if (code && state) {
				//Only applicable for brightspace redirect
				brightspaceHook.saveAuthorizationCredentials(code, state);

				// And remove from search from URL
				window.history.replaceState({}, window.location.origin, "/");

				// Switch to integrations tap
				handleChangeIndex(1);
			}
		}

		// try {
		// 	setSearchResult([...credsData]);
		// } catch (error) {
		// 	console.log(error);
		// }
	}, []);

	const getProvider = (lmsType) => {
		switch (lmsType || selectedIntegrationProvider) {
			case "googleClassroom":
				return googleClassroomHook;
			case "canvas":
				return canvasHook;
			case "schoology":
				return schoologyHook;
			case "bridge":
				return bridgeHook;
			case "brightspace":
				return brightspaceHook;
			case "tcs_ion":
				return tCSIONHook;	
			case "moodle":
				return moodleHook;		
			default:
				return {};
		}
	};
	const getProviderImage = (lmsType) => {
		switch (lmsType) {
			case "googleClassroom":
				return googleClassroomImage;
			case "schoology":
				return schoologyImage;
			case "canvas":
				return canvasImage;
			case "bridge":
				return bridgeImage;
			case "brightspace":
				return brightspaceImage;
			case "tcs_ion":
				return tcsIONImage;	
			case "moodle": 
			    return moodleImage;	
			default:
				return;
		}
	};
	const createNewCredential = async (credentialName, description, transcriptFieldsArray, imageUrl, resources) => {
		const data = await addCreds(credentialName, description, transcriptFieldsArray, imageUrl, resources);
		// setSearchResult([...searchResult, data.definition]);
		// setCredsData([...credsData, data.definition]);
		return data;
	};

	const getNewName = (currentName) => `(copy) ${currentName}`;

	const getUniqueCredentialName = (credentialName) => {
		const duplicateCred = searchResult.find((cred) => cred.name === credentialName);
		if (duplicateCred) {
			const newCredentialName = getNewName(credentialName);
			enqueueSnackbar(`Credential name exists, will prepend (copy)... ${newCredentialName}`, {
				variant: "Warning",
			});
			return getUniqueCredentialName(newCredentialName);
		} else {
			return credentialName;
		}
	};

	const handleChangeIndex = (index) => {
		setValue(index);
	};

	const handleIssueCredentialButton = (value) => {
		try {
			navigate({
				pathname: `/credentialIssue/${value._id}/new`,
				state: { background: location },
			});
		} catch (error) {
			console.error(error);
		}
	};

	const bulkImportCredentialDefinitions = async (event) => {
		try {
			if (!event.target.files) throw new Error("No file found...");
			const excelSheet = event.target.files[0];

			// Clear the submission
			event.target.value = null;

			return await bulkIssueCredDefsFromXlsx({ excelSheet });
		} catch (err) {
			enqueueSnackbar(getErrMsg(err), {
				variant: "error",
			});
		}
	};

	const loadMoreCredentialData = async (defaultPageNumber = 0, emptySearch = false) => {
		setSearchValue(getSearchValue);
		const { currentPageNumber, currentPageSize } = credentials;
		getCredsPerPage(currentPageSize, defaultPageNumber > 0 ? defaultPageNumber : currentPageNumber + 1, emptySearch ? "" : getSearchValue);
	};

	const onClickLoadMoreCredentials = async (defaultPageNumber = 0, searchTxt = "", clearCreds = false, sortBy = "") => {
		const { currentPageNumber, currentPageSize } = credentials;
		getCredsPerPage(currentPageSize, defaultPageNumber > 0 ? defaultPageNumber : currentPageNumber + 1, searchTxt, false, sortBy);
	};

	const loadMoreLMSCourses = async (defaultPageNumber = 0, emptySearch = false) => {
		if (selectedIntegrationProvider == "canvas") {
			const { currentPageNumber, currentPageSize } = canvasHook.showLoadMore;
			canvasHook.getCoursesPerPage(currentPageSize, defaultPageNumber > 0 ? defaultPageNumber : currentPageNumber + 1, emptySearch ? "" : lmsSearchValue, defaultPageNumber > 0 && (emptySearch || lmsSearchValue) ? true : false);
		} else if (selectedIntegrationProvider == "schoology") {
			const { currentPageNumber, currentPageSize } = schoologyHook.showLoadMore;
			schoologyHook.getCoursesPerPage(currentPageSize, defaultPageNumber > 0 ? defaultPageNumber : currentPageNumber + 1, emptySearch ? "" : lmsSearchValue, defaultPageNumber > 0 && (emptySearch || lmsSearchValue) ? true : false);
		} else if (selectedIntegrationProvider == "bridge") {
			const { currentPageNumber, currentPageSize } = bridgeHook.showLoadMore;
			bridgeHook.getCoursesPerPage(currentPageSize, defaultPageNumber > 0 ? defaultPageNumber : currentPageNumber + 1, emptySearch ? "" : lmsSearchValue, defaultPageNumber > 0 && (emptySearch || lmsSearchValue) ? true : false);
		} else if (selectedIntegrationProvider == "googleClassroom") {
			const { currentPageNumber, currentPageSize } = googleClassroomHook.showLoadMore;
			googleClassroomHook.getCoursesPerPage(currentPageSize, defaultPageNumber > 0 ? defaultPageNumber : currentPageNumber + 1, emptySearch ? "" : lmsSearchValue, defaultPageNumber > 0 && (emptySearch || lmsSearchValue) ? true : false);
		} else if (selectedIntegrationProvider == "brightspace") {
			const { currentPageNumber, currentPageSize } = brightspaceHook.showLoadMore;
			brightspaceHook.getCoursesPerPage(currentPageSize, defaultPageNumber > 0 ? defaultPageNumber : currentPageNumber + 1, emptySearch ? "" : lmsSearchValue, defaultPageNumber > 0 && (emptySearch || lmsSearchValue) ? true : false);
		} else if (selectedIntegrationProvider == "tcs_ion") {
			const { currentPageNumber, currentPageSize } = tCSIONHook.showLoadMore;
			tCSIONHook.getCoursesPerPage(currentPageSize, defaultPageNumber > 0 ? defaultPageNumber : currentPageNumber + 1, emptySearch ? "" : lmsSearchValue, defaultPageNumber > 0 && (emptySearch || lmsSearchValue) ? true : false);
		} else if (selectedIntegrationProvider == "moodle") {
			const { currentPageNumber, currentPageSize } = moodleHook.showLoadMore;
			moodleHook.getCoursesPerPage(currentPageSize, defaultPageNumber > 0 ? defaultPageNumber : currentPageNumber + 1, emptySearch ? "" : lmsSearchValue, defaultPageNumber > 0 && (emptySearch || lmsSearchValue) ? true : false);
		}
	};

	const reimportCourses = async () => {
		console.log(selectedIntegrationProvider);
		progressStart();
		if (selectedIntegrationProvider == "canvas") {
			canvasHook.reimportCourses();
		} else if (selectedIntegrationProvider == "schoology") {
			schoologyHook.reimportCourses();
		} else if (selectedIntegrationProvider == "bridge") {
			bridgeHook.reimportCourses();
		} else if (selectedIntegrationProvider == "googleClassroom") {
			googleClassroomHook.reimportCourses();
		} else if (selectedIntegrationProvider == "brightspace") {
			brightspaceHook.reimportCourses();
		}
		progressDone();
	};

	//Joyride

	const enableDefineCredential = useMemo(() => {
		return isCapable(capabilities, CAPABILITIES.CREATE_CREDENTIAL_DEFINITION);
	}, [capabilities]);

	const TOUR_STEPS = [
		{
			target: `.main-content`,
			content: <FormattedMessage id="home.walkthrough.welcome" />,
		},
		{
			target: `.tab-1`,
			content: <FormattedMessage id="home.walkthrough.credential" />,
		},
		{
			target: `.tab-2`,
			content: <FormattedMessage id="home.walkthrough.pathways" />,
		},
		{
			target: `.${classes.RightBox} div`,
			content: <FormattedMessage id="home.walkthrough.credDefinition" />,
		},
	];

	const hideJoyHelp = (event) => {
		const { status } = event;
		if (status === "ready") {
			cookie.set(JOY_HELP_STATE);
		}
	};

	return (
		<div className={classes.root}>
			<main className={classes.content + " main-content"}>
				{/* <div className={classes.toolbar} /> */}
				<CredTabs
					options={[
						{
							title: `Credentials (${getObjectValue(credentials, "totalItems", "...")})`,
							component: (
								<Credentials
									onClickLoadMoreCredentials={onClickLoadMoreCredentials}
									credentials={credentials || {}}
									handleIssueCredentialButton={handleIssueCredentialButton}
									copyCredentialDefinition={copyCredentialDefinition}
									certificates={certificates}
									googleClassroomHook={googleClassroomHook}
									setCredentials={setCredentials}
									connectedCredsData={connectedCredsData}
									archiveCredentialDefinition={archiveCredentialDefinition}
									deleteCredentialDefinition={deleteCredentialDefinition}
									bulkAddCreds={bulkAddCreds}
									getCredsPerPage={getCredsPerPage}
								/>
							),
						},
						{
							title: canView("lms", true) && "Integrations",
							component: (
								<ViewWrapper prop="lms" value={true}>
									<Integrations
										selectedIntegrationProvider={selectedIntegrationProvider}
										googleClassroomHook={googleClassroomHook}
										googleClassroomImage={googleClassroomImage}
										setSelectedIntegrationProvider={setSelectedIntegrationProvider}
										canvasImage={canvasImage}
										canvasHook={canvasHook}
										schoologyImage={schoologyImage}
										schoologyHook={schoologyHook}
										bridgeImage={bridgeImage}
										bridgeHook={bridgeHook}
										brightspaceImage={brightspaceImage}
										brightspaceHook={brightspaceHook}
										tcsIONImage={tcsIONImage}
										useTCSIONHook={tCSIONHook}
										moodleImage={moodleImage}
										useMoodleHook={moodleHook}
										getProvider={getProvider}
										reimportCourses={reimportCourses}
										lmsSearchValue={lmsSearchValue}
										setLmsSearchValue={setLmsSearchValue}
										loadMoreLMSCourses={loadMoreLMSCourses}
										connectedCredsData={connectedCredsData}
										createNewCredential={createNewCredential}
										getUniqueCredentialName={getUniqueCredentialName}
										credsData={credsData}
									/>
								</ViewWrapper>
							),
						},
						{
							title: canView("lms", true) && `Connections (${connectedCredsData ? connectedCredsData.length : "..."})`,
							component: (
								<ViewWrapper prop={"lms"} value={true}>
									{connectedCredsData && connectedCredsData.length > 0 && (
										<Grid container spacing={3} alignItems={"stretch"}>
											{connectedCredsData.map((credential, i) => (
												<Grid key={i} item xs={12} sm={6} md={4}>
													<IntegrationConnectionCard index={i} providerImage={getProviderImage(credential.lmsType)} credential={credential} provider={getProvider(credential.lmsType)} />
												</Grid>
											))}
										</Grid>
									)}
								</ViewWrapper>
							),
						},
					]}
					activeStep={value}
					tabContainerStyles={{ marginTop: "1rem" }}
				/>
			</main>
		</div>
	);
};

Body.defaultProps = {
	// center prop,
	// returns a tab component
};

Body.propTypes = {};

export default Body;
