import Typography from "../typography/Typography";
import styled from 'styled-components';

const BreadcrumbsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

const CrumbText = styled(Typography)`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.125rem;
    letter-spacing: 0.015em;
    font-weight: 700;
    /* color: ${({ active }) => active ? "#424242" : "#BDBDBD"};
    cursor: ${({ active }) => active ? "auto" : "pointer"}; */
    ${({ active }) => active ? `` :
        `::after {
            content: "/";
            cursor: auto;
            font-size: 10px;
        }`
    }
`

const CrumbContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    * {
        color: ${({ active }) => active ? "#424242" : "#BDBDBD"};
        cursor: ${({ active }) => active ? "auto" : "pointer"};
    }
`;

/**
 * 
 * @param {Object} props
 * @param {Object[]} props.options
 * @param {string} props.options[].label
 * @param {string} props.options[].icon
 * @function props.onCrumbClick
 * @returns 
 */

export function Breadcrumbs({ options = [], onCrumbClick = () => { } }) {
    return <BreadcrumbsContainer>
        {
            options.map((item, index) => (
                <CrumbContainer active={index == options.length - 1} onClick={() => {
                    if (index == options.length - 1) return;
                    onCrumbClick(item);
                }}>
                    {item.icon}
                    <CrumbText active={index == options.length - 1}>{item.label}</CrumbText>
                </CrumbContainer>
            ))
        }
    </BreadcrumbsContainer>
}

export default Breadcrumbs;