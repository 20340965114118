import { ASSETS_PREFIX } from "@cti-workspace/helpers";
import { getApi } from "./api";

/**
 *
 * @param {String} key The s3 key you wish to download.
 */
export default async function getAssetUrl(key) {
	const url = `/${ASSETS_PREFIX}/download?key=${key}`;
	return getApi(url);
}
