import { useEffect, useState } from "react";
import styled from "styled-components";
import { ThemeProvider } from "theme-ui";
import Theme from "../theme/theme";
import { getObjectValue } from "@cti-workspace/helpers";

const elevationBoxShadow = {
	1: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
	2: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
	3: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
	4: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
	5: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)",
};
const BoxContainer = styled.div`
	width: "100%";
	${(props) =>
		props.elevation
			? `
		box-shadow: ${getObjectValue(elevationBoxShadow, props.elevation)};
		border-radius: 3px;
	`
			: ""}
	${(props) =>
		props.displayCenter
			? `
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
			`
			: ""}
`;

export function Box({ children, ...rest }) {
	return (
		<ThemeProvider theme={Theme}>
			<BoxContainer
				sx={{
					...(rest.border && {
						border: rest.border,
						borderStyle: rest.borderStyle || "solid",
					}),
					...rest,
				}}
				{...rest}>
				{children}
			</BoxContainer>
		</ThemeProvider>
	);
}

/** @jsxImportSource theme-ui */
export default Box;
