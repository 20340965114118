import { Typography, useTheme } from "@material-ui/core";
import { Card, CardContent } from "@cti-workspace/ui";

const AnalyticCard = ({ title = "", count = 0, children }) => {
	const theme = useTheme();

	return (
		<Card>
			<CardContent style={{ paddingBottom: theme.spacing(2) }}>
				<Typography variant="h5">{Number(count).toLocaleString()}</Typography>
				<div style={{ width: theme.spacing(3), height: "3px", marginTop: "5px", backgroundColor: theme.palette.secondary.main }} />
				<div style={{ display: "flex" }}>
					<Typography variant="subtitle1" style={{ marginRight: "auto" }}>
						{title}
					</Typography>
					<div className="Explore-Analytics-Children">{children}</div>
				</div>
			</CardContent>
		</Card>
	);
};

export default AnalyticCard;
