import { getObjectValue, isValidEmail, parseExcelSheetToJson } from "@cti-workspace/helpers";

export const getRecipient = () => ({
	firstName: "",
	lastName: "",
	email: "",
});

export const addRecipient = (recipients) => recipients.concat(getRecipient());

export const handleRecipientInformationChange = (recipients, index, key, value) => {
	const { [index]: recipientValue } = recipients;
	return [
		...recipients.slice(0, index),
		{
			...recipientValue,
			[key]: value,
		},
		...recipients.slice(index + 1, recipients.length),
	];
};

export const removeRecipient = (recipients, index) => {
	const updatedRecipients = [...recipients];
	updatedRecipients.splice(index, 1);
	return updatedRecipients;
};

export const getRecipientInformationFieldErrors = (recipients) => {
	const fieldErrors = recipients.map((r) => ({}));
	recipients.forEach((recipient, index) => {
		fieldErrors.push({
			firstName: recipient.firstName.trim() ? false : true,
			lastName: recipient.lastName.trim() ? false : true,
			email: !isValidEmail(recipient.email.trim()),
		});
	});
	const fieldErrorValues = fieldErrors.map((r) => Object.values(r)).flat();
	if (fieldErrorValues.some((x) => x === true)) {
		return fieldErrors;
	}
	return [];
};

export const fileUploadStates = {
	validated: 2,
	notUploaded: 0,
	validationFailed: 1,
	uploading: 3,
	uploadingFailed: 4,
};

export const fileUploadStatesColors = {
	2: "#009944",
	0: "#ffffff",
	1: "#cf000f",
	4: "#cf000f",
	3: "#63c0df",
};

const columnNames = ["first name", "last name", "contact"];

export const getUploadedFileValidation = async (e) => {
	try {
		const file = e?.target?.files?.[0];
		if (!file) {
			return "The selected file could not be recognized. Please try again.";
		}
		if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
			return "Only Microsoft Excel (.xlsx) files are accepted.";
		}

		const { headers, data } = await parseExcelSheetToJson(file, true, true);

		if (headers.length !== 3 || headers[0] !== "first name" || headers[1] !== "last name" || headers[2] !== "contact") {
			return "The first row of the file must have First Name, Last Name and Contact columns.";
		}

		const requestData = data.map((record) => ({
			firstName: record["first name"] || "",
			lastName: record["last name"] || "",
			email: record["contact"] || "",
		}));

		const fieldErrors = getRecipientInformationFieldErrors(requestData);
		if (!fieldErrors.length) {
			return requestData;
		}

		const invalidRow = fieldErrors.findIndex((errors) => Object.values(errors).some((error) => error === true));
		const invalidColumn = Object.values(fieldErrors.find((errors) => Object.values(errors).some((error) => error === true))).findIndex((errorValidation) => errorValidation === true);

		return `Record ${invalidRow + 1}: No valid value for ${getObjectValue(columnNames, invalidColumn)}.`;
	} catch (e) {
		console.error(e);
		return "The file could not be validated. Please try again.";
	}
};
