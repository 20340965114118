import { Button, Container, Pagination, Tabs as PathwayTabs } from "@cti-workspace/ui";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField } from "@cti-workspace/ui";
import { getErrMsg, usePathwaysHook } from "@cti-workspace/helpers";
import { useSnackbar } from "notistack";
import PathwayCard from "./components/pathwayCard";
import InviteOne from "./components/inviteOne";
import InviteMany from "./components/inviteMany";
import styled from "styled-components";

export default function Pathways() {
	const [searchValue, setSearchValue] = useState("");
	const [pathways, setPathways] = useState({
		pageSize: 10,
		pageNumber: 0,
		totalItems: 0,
		items: [],
	});
	const [issuingOnePathwayId, setIssuingOnePathwayId] = useState(null);
	const [issuingManyPathwayId, setIssuingManyPathwayId] = useState(null);
	const [pastRecipients, setPastRecipients] = useState([]);
	const [copiedPathwayId, setCopiedPathwayId] = useState(null);

	const navigate = useNavigate();
	const { getPathways, deletePathway, copyPathway, getPathwayPastRecipients } = usePathwaysHook();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		getPathwaysItems({ page: 0, pageSize: 10 });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue]);

	const getPathwaysItems = async ({ page = 0, pageSize = 10 }) => {
		try {
			const res = await getPathways(pageSize, page + 1, searchValue);
			console.log(res);
			if (res) {
				setPathways(res);
			}
		} catch (error) {
			console.log(error);
			enqueueSnackbar(getErrMsg(error), { variant: "error" });
		}
	};

	const onDeletePathway = async (id) => {
		try {
			const res = await deletePathway(id);
			enqueueSnackbar(res?.message || "Successfully deleted pathway", { variant: "success" });
			const pageNum = pathways.pageNumber - 2 >= 0 ? pathways.pageNumber - 2 : 0;
			getPathwaysItems({ page: pageNum, pageSize: pathways.pageSize });
		} catch (error) {
			console.log(error);
			enqueueSnackbar(getErrMsg(error, "Something went wrong while deleting the pathway"), { variant: "error" });
		}
	};

	const onCopyPathway = async (id) => {
		try {
			await copyPathway(id);
			const pageNum = pathways.pageNumber - 2 >= 0 ? pathways.pageNumber - 2 : 0;
			getPathwaysItems({ page: pageNum, pageSize: pathways.pageSize });
			enqueueSnackbar(`Copied Pathway.`, { variant: "success" });
			setTimeout(() => {
				setCopiedPathwayId(null);
			}, 2000);
		} catch (e) {
			console.error(e);
			enqueueSnackbar(`Failed to copy Pathway${e.message ? `. ${e.message}` : ""}. Please try again.`, { variant: "error" });
		}
	};

	const fetchPathwayPastRecipients = async () => {
		try {
			setPastRecipients(await getPathwayPastRecipients());
		} catch (e) {
			console.error(e);
			enqueueSnackbar(`Failed to retrieve past recipients${e.message ? `. ${e.message}` : ""}. Please try again.`, { variant: "error" });
		}
	};

	useEffect(() => {
		fetchPathwayPastRecipients();
	}, []);

	const PathwaysContainer = styled.div`
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(3, auto);
		margin-top: 1rem;
		grid-gap: 0.5rem;
		@media (max-width: 1024px) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(9, auto);
		}
		@media (max-width: 768px) {
			grid-template-columns: 1fr;
			grid-template-rows: repeat(10, auto);
		}
	`;

	return (
		<Container maxWidth="lg">
			<div style={{ height: "64px" }} />
			<PathwayTabs
				options={[
					{
						title: `Pathways (${pathways.totalItems})`,
						component: (
							<div>
								<SearchBar onSearch={setSearchValue} />
								<div style={{ marginTop: "1rem", display: "flex", justifyContent: "flex-end" }}>
									<Button color="warning" fullWidth={false} leftIcon="plus-circle" invert onClick={() => navigate("/pathways/new")}>
										Define Pathway
									</Button>
								</div>
								<div>
									<PathwaysContainer>
										{pathways?.items?.map((item) => (
											<PathwayCard data={item} onDelete={() => onDeletePathway(item._id)} onCopy={() => onCopyPathway(item._id)} onInviteOne={() => setIssuingOnePathwayId(item._id)} onInviteMany={() => setIssuingManyPathwayId(item._id)} />
										))}
									</PathwaysContainer>
									{pathways.totalItems > pathways.pageSize && <Pagination pageSize={pathways.pageSize} page={pathways.pageNumber - 1} totalItems={pathways.totalItems} onPageChange={getPathwaysItems} />}
								</div>
							</div>
						),
					},
				]}
				tabContainerStyles={{ marginTop: "1rem" }}
			/>
			<InviteOne issuingPathwayId={issuingOnePathwayId} setIssuingPathwayId={setIssuingOnePathwayId} pastRecipients={pastRecipients} fetchPathwayPastRecipients={fetchPathwayPastRecipients} />
			<InviteMany issuingPathwayId={issuingManyPathwayId} setIssuingPathwayId={setIssuingManyPathwayId} />
		</Container>
	);
}

function SearchBar({ onSearch = () => {} }) {
	const [searchValue, setSearchValue] = useState("");

	return (
		<TextField
			size={"small"}
			labelSpacing={"2.5rem"}
			startAdornment={<MagnifyingGlass size="1.3rem" weight="bold" />}
			endAdornment={
				<>
					{searchValue && (
						<Button
							buttonType="icon"
							icon="cancel"
							invert
							color="danger"
							iconSize="1.3rem"
							buttonStyle={{ marginRight: "0.5rem" }}
							onClick={() => {
								setSearchValue("");
								onSearch("");
							}}
						/>
					)}
					<Button color="warning" invert={searchValue ? false : true} onClick={() => onSearch(searchValue)}>
						Search
					</Button>
				</>
			}
			placeholder={"Search"}
			value={searchValue}
			onChange={(e) => setSearchValue(e.target.value)}
		/>
	);
}
