import { Component } from "react";

class ImageBlock extends Component {
	render() {
		const { blockProps } = this.props;
		const { entity } = blockProps;
		const { src, styleString, hAlign, vAlign } = entity.getData();
		return (
			<span
				style={{
					display: "flex",
					justifyContent: hAlign || "center",
					alignItems: vAlign || "center",
				}}
			>
				<img src={src} style={styleString} />
			</span>
		);
	}
}

export default ImageBlock;
