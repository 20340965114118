import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

import "@fontsource/source-sans-pro";
import "@fontsource/lato";

let lato = ["Lato", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"].join(",");

let sourceSansPro = ["Source Sans Pro", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"].join(",");
let robotoFont = ["Roboto", "sans-serif"].join(",");

// Create a theme instance.
let theme = createTheme({
	palette: {
		primary: {
			light: "#5b6875",
			main: "#324353",
			dark: "#232e3a",
		},
		secondary: {
			main: "#9c49ed",
			light: "#fff2e5",
			dark: "#b25900",
		},
		type: "light",
	},
	typography: {
		htmlFontSize: 14,
		fontSize: 12,
		fontFamily: lato,
		body2: {
			fontFamily: robotoFont,
		},
		body1: {
			fontFamily: robotoFont,
			fontWeight: 400,
			fontSize: "1rem",
		},
		subtitle2: {
			fontFamily: robotoFont,
		},
		subtitle1: {
			fontFamily: robotoFont,
			fontWeight: 400,
			fontSize: "1rem",
		},
		button: {
			fontFamily: robotoFont,
			fontWeight: "bolder",
			fontSize: "1rem",
			letterSpacing: "1.5px",
			lineHeight: "19px",
		},
	},
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#9c49ed',
        '&:hover': {
          backgroundColor: '#9c49ed',
        }
      }
    },
    MuiFab: {
      secondary: {
        '&:hover': {
          backgroundColor: '#9c49ed',
        }
      }
    }
  }
});

theme = responsiveFontSizes(theme);

export default theme;

// overrides: {
//   MuiCssBaseline: {
//     "@global": {
//       "@font-face": [lato],
//     },
//   },
//   MuiAppBar: {
//     root: {
//       elevation: 0,
//     },
//   },
//   MuiStepIcon: {
//     root: {
//       "&$active": {
//         color: "#9c49ed",
//       },
//     },
//   },
// },
