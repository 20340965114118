import { AppBar, makeStyles, useTheme, Toolbar, IconButton } from "@material-ui/core";
import clsx from "clsx";
import HideOnScroll from "../../components/HideOnScroll";
import MenuIcon from "@material-ui/icons/Menu";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	hide: {
		display: "none",
	},
}));

const AppMainBar = (props) => {
	const classes = useStyles();
	const {
		openMenuClick = () => {
			return null;
		},
		menuOpen,
	} = props;

	return (
		<AppBar
			position="fixed"
			color="transparent"
			elevation={0}
			className={clsx(classes.appBar, {
				[classes.appBarShift]: menuOpen,
			})}>
			<HideOnScroll>
				<Toolbar style={{ paddingLeft: "1rem" }}>
					<IconButton color="inherit" aria-label="open drawer" onClick={() => openMenuClick(true)} edge="start" className={clsx(classes.menuButton, menuOpen && classes.hide)}>
						<MenuIcon />
					</IconButton>
				</Toolbar>
			</HideOnScroll>
		</AppBar>
	);
};

export default AppMainBar;
