import Style from "./admin.style";
import { makeStyles } from "@material-ui/core/styles";
import FormProvider from "../../providers/form-provider";
import AdminRoute from "./AdminRoute";

export default function AdminContainer() {
	const useStyles = makeStyles(Style);
	const classes = useStyles();

	return (
		<FormProvider>
			<AdminRoute classes={classes} />
		</FormProvider>
	);
}
