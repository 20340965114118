import { ORGANIZATIONS_PREFIX, getApi, postApi, getObjectValue } from "@cti-workspace/helpers";
import useNProgressHook from "./useNProgressHook";

export default function useOrgranizationHook({ organizations }) {
	const { progressStart, progressDone } = useNProgressHook();
	const getOrganization = async () => {
		progressStart();
		try {
			const orgId = getObjectValue(organizations, "default._id");
			if (!orgId) throw new Error("Organization Id is not available");
			const data = await getApi(`/${ORGANIZATIONS_PREFIX}/${orgId}`);
			progressDone();
			return data;
		} catch (err) {
			progressDone();
			throw err;
		}
	};

	const getOrganizationUsage = async () => {
		progressStart();
		try {
			const orgId = getObjectValue(organizations, "default._id");
			if (!orgId) throw new Error("Organization Id is not available");
			const data = await getApi(`/${ORGANIZATIONS_PREFIX}/getOrganizationUsage?organizationId=${orgId}`);
			progressDone();
			return data;
		} catch (err) {
			progressDone();
			throw err;
		}
	};

	const updateOrganization = async (data) => {
		progressStart();
		try {
			const dataRes = await postApi(`/${ORGANIZATIONS_PREFIX}/updateOrganization`, data);
			progressDone();
			return dataRes;
		} catch (err) {
			progressDone();
			throw err;
		}
	};
	return {
		getOrganization,
		updateOrganization,
		getOrganizationUsage,
	};
}
