import { ORGANIZATIONS_PREFIX, postApi, getPaginatedResponseItems } from "@cti-workspace/helpers";
import useNProgressHook from "../useNProgressHook";

export default function useRendererConfigOrgHook() {
	const { progressStart, progressDone } = useNProgressHook();

	const addOrgRendererConfig = async (data, name, orgId = "", rendererConfigId = "", isDefault = false, simpleDesign = false) => {
		try {
			progressStart();
			const response = await postApi(`/${ORGANIZATIONS_PREFIX}/rendererConfigs/addEditRendererConfig`, {
				data,
				name,
				orgId,
				rendererConfigId,
				simpleDesign,
				isDefault,
			});
			progressDone();
			return response;
		} catch (err) {
			progressDone();
			return err;
		}
	};

	const getAllOrgsRendererConfigList = async (pageSize = null, pageNumber = null, search = null, returnItems = true) => {
		try {
			progressStart();
			const response = await getPaginatedResponseItems(`/${ORGANIZATIONS_PREFIX}/rendererConfigs/getOrgRendererConfigs`, pageSize, pageNumber, search, returnItems);
			progressDone();
			return response;
		} catch (err) {
			progressDone();
			throw err;
		}
	};

	const deleteOrgRendererConfig = async (rendererConfigId) => {
		try {
			progressStart();
			const response = await postApi(`/${ORGANIZATIONS_PREFIX}/rendererConfigs/deleteOrgRendererConfig`, { rendererConfigId });
			progressDone();
			return response;
		} catch (err) {
			progressDone();
			throw err;
		}
	};

	return {
		addOrgRendererConfig,
		getAllOrgsRendererConfigList,
		deleteOrgRendererConfig,
	};
}
