const API_ERR_CODES = {
	4003: {
		message: "Invalid credentials",
	},
	4004: {
		message: "Oops! Something went wrong, we are working on it.",
	},
};

export const getErrMsg = (err, defaultMsg) => {
	// Can add default messages depending on the error status sent from API
	console.error(err);
	return typeof err === "string" ? err || defaultMsg : err?.message || defaultMsg || "Oops! Something went wrong. Please try later";
};
