import { useEffect, useState } from "react";
import { Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import useRendererConfigOrgHook from "../../hooks/useRendererConfigOrgHook";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { getObjectValue, getOrganization } from "@cti-workspace/helpers";
import { Box } from "@cti-workspace/ui";
import TablePagination from "@material-ui/core/TablePagination";
import { Button as Btn, Dialog, DialogTitle, DialogContent, DialogActions } from "@cti-workspace/ui";

export default function RendererConfigOrg() {
	const [orgs, setOrgs] = useState([]);
	const [configList, setConfigList] = useState([]);
	const [open, setOpen] = useState(false);
	const [orgsObj, setOrgsObj] = useState({});
	const [dataSelected, setDataSelected] = useState({});
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(0);
	const [search, setSearch] = useState("");
	const { getAllOrgsRendererConfigList, deleteOrgRendererConfig } = useRendererConfigOrgHook();

	useEffect(() => {
		(async function iife() {
			let { items } = await getOrganization();
			setOrgs(items);
			setOrgsObj(items.reduce((acc, item) => ({ ...acc, [item._id]: item.name }), {}));
		})();
	}, []);

	const getList = async (pageSize = null, pageNumber = null, search = null) => {
		const { items, totalItems } = await getAllOrgsRendererConfigList(pageSize, pageNumber, search, false);
		if (count !== totalItems) {
			setCount(totalItems);
		}
		setConfigList((items && items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))) || []);
	};

	const deleteOrgRenderer = async (data) => {
		const { _id } = data;
		try {
			await deleteOrgRendererConfig(_id);
			await getList(rowsPerPage, page);
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		getList(rowsPerPage, page + 1, search.trim() !== null ? search : null);
	}, [page, rowsPerPage]);

	const onSearch = () => {
		setRowsPerPage(10);
		setPage(0);
		getList(10, 1, search.trim() !== null ? search : null);
	};

	return (
		<Box padding={2}>
			<div style={{ display: "flex", justifyContent: "space-between", marginBottom: 30 }}>
				<TextField
					placeholder="Search"
					variant="outlined"
					size="small"
					value={search}
					InputProps={{
						endAdornment: (
							<InputAdornment position="">
								<Btn buttonType="icon" icon="search" onClick={onSearch} />
							</InputAdornment>
						),
					}}
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Button
					startIcon={<AddIcon />}
					onClick={() => {
						setOpen(true);
						setDataSelected({});
					}}>
					Add Config
				</Button>
			</div>
			<RendererConfigTable
				list={configList}
				orgsObj={orgsObj}
				onEdit={(data) => {
					setDataSelected(data);
					setOpen(true);
				}}
				onDelete={deleteOrgRenderer}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(event, newPage) => setPage(newPage)}
				handleChangeRowsPerPage={(event) => {
					setRowsPerPage(parseInt(event.target.value, 10));
					setPage(0);
				}}
				count={count}
			/>
			{open && (
				<AddUpdateRendererConfigForm
					orgs={orgs}
					open={open}
					onClose={(event) => {
						setOpen(false);
						if (event) {
							getList(rowsPerPage, page);
						}
					}}
					data={dataSelected}
				/>
			)}
		</Box>
	);
}

function AddUpdateRendererConfigForm({ orgs = [], open = false, onClose = () => {}, data = {} }) {
	const { register, control, handleSubmit, errors, reset, getValues } = useForm();
	const { addOrgRendererConfig } = useRendererConfigOrgHook();
	const [setDefault, setSetDefault] = useState(false);
	useEffect(() => {
		if (Object.keys(data).length) {
			if (getObjectValue(data, "default")) {
				setSetDefault(true);
			}
			reset(data);
		}
	}, [data]);

	const submitRendererConfigOrg = async (event) => {
		if (Object.keys(errors).length <= 0) {
			try {
				const { message } = await addOrgRendererConfig(JSON.parse(event.data), event.name, event.orgId, getObjectValue(data, "_id"), event.default);
				onClose(true);
			} catch (err) {
				console.error(err);
			}
		}
	};
	return (
		<Dialog open={open} maxWidth="sm" fullWidth onClose={onClose} disableBackdropClick>
			<DialogTitle>{Object.keys(data).length ? "Edit" : "Add"} Renderer Config to Organization</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12} md={12}>
						<Controller
							control={control}
							name="orgId"
							as={(propsData) => (
								<FormControl fullWidth variant="outlined">
									<InputLabel required>Select Organization</InputLabel>
									<Select {...propsData} fullWidth disabled={Object.keys(data).length > 0} label="Select Organization *" required>
										{orgs.map((item) => (
											<MenuItem key={item._id} value={item._id}>
												{item.name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<TextField inputRef={register({ required: true })} name="name" required variant="outlined" label="Config Name" fullWidth />
					</Grid>
					<Grid item xs={12} md={12}>
						<TextField inputRef={register({ required: true })} name="data" required variant="outlined" label="Config Data" fullWidth />
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							name="default"
							control={<Checkbox name="default" color="secondary" inputRef={register()} checked={setDefault} onChange={(event) => setSetDefault(event.target.checked)} />}
							style={{ justifyContent: "space-between", width: "100%", marginLeft: "8px" }}
							labelPlacement="start"
							label="Make Default"
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={handleSubmit(submitRendererConfigOrg)}>
					{Object.keys(data).length ? "Update" : "Save"}
				</Button>
				<Button color="default" onClick={onClose}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function RendererConfigTable({ list = [], orgsObj = {}, onEdit = () => {}, onDelete = () => {}, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, count }) {
	return (
		<Box>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell component="th" style={{ fontWeight: "bold" }} width={250}>
							Organization
						</TableCell>
						<TableCell component="th" style={{ fontWeight: "bold" }} width={70}></TableCell>
						<TableCell component="th" style={{ fontWeight: "bold" }} width={250}>
							Name
						</TableCell>
						<TableCell component="th" style={{ fontWeight: "bold" }}>
							Config
						</TableCell>
						<TableCell component="th" width={150} style={{ fontWeight: "bold" }}></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{list.map((item) => (
						<TableRow>
							<TableCell>{getObjectValue(orgsObj, item.orgId)}</TableCell>
							<TableCell>{item?.default ? "(Default)" : ""}</TableCell>
							<TableCell>{item.name}</TableCell>
							<TableCell>
								<Button
									color={"secondary"}
									size={"small"}
									onClick={() => {
										const tempInput = document.createElement("input");
										tempInput.value = item.data;
										document.body.appendChild(tempInput);
										tempInput.select();
										document.execCommand("copy");
										document.body.removeChild(tempInput);
									}}>
									Copy Config
								</Button>
							</TableCell>
							<TableCell align="center">
								<IconButton
									onClick={() => {
										onEdit(item);
									}}>
									<EditIcon color="secondary" />
								</IconButton>
								<IconButton
									onClick={() => {
										onDelete(item);
									}}>
									<DeleteIcon color="error" />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<Grid spacing={3}>
				<TablePagination component="div" count={count} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
			</Grid>
		</Box>
	);
}
