import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@cti-workspace/ui";

export default function AlertDialog({ open, title, body, continueToAdd, cleanup, disableContinue = false, autoCleanupAfterContinue = true, maxWidth = "xs", continueLabel = "Continue", primaryColor }) {
	const handleContinue = () => {
		continueToAdd();
		if (autoCleanupAfterContinue) {
			cleanup();
		}
	};

	return (
		<Dialog open={open} onClose={cleanup} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth={maxWidth} fullWidth>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<DialogContent>{body}</DialogContent>
			<DialogActions>
				{primaryColor ? (
					<Button id="continue-dialog" buttonStyle={{ backgroundColor: primaryColor }} onClick={handleContinue} disabled={disableContinue}>
						{continueLabel}
					</Button>
				) : (
					<Button id="continue-dialog" color="warning" onClick={handleContinue} disabled={disableContinue}>
						{continueLabel}
					</Button>
				)}
				<Button color="danger" invert onClick={cleanup} autoFocus>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
