import { useEffect, useState } from "react";
import HeaderWithLine from "../HeaderWithLine";
import AdvFormControl from "../AdvFormControl";
import { Controller, useForm } from "react-hook-form";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useNProgressHook from "../../hooks/useNProgressHook";
import { useSnackbar } from "notistack";
import { Box, Button } from "@cti-workspace/ui";
import OrgDropDown from "../OrgDropDown/OrgDropDown";
import { getErrMsg, updateOrganizationEmailTemplate } from "@cti-workspace/helpers";

const UpdateOrgEmailTemplate = ({ emailTemplates, getEmailTemplates }) => {
	const { handleSubmit, control, errors, reset } = useForm();
	const { progressStart, progressDone } = useNProgressHook();
	const { enqueueSnackbar } = useSnackbar();
	const [type, setType] = useState(null);
	const [category, setCategory] = useState(null);

	const updateOrgEmailTemplate = async (form) => {
		progressStart();
		if (form.organizations !== 0) {
			try {
				const request = {
					orgId: form.organizations,
					category: form.Category,
					emailTemplateId: form["Email Template"],
					templateFor: form["Template For"],
				};
				await updateOrganizationEmailTemplate(request);
				enqueueSnackbar(`Email template updated.`, { variant: "success" });
				reset();
			} catch (error) {
				enqueueSnackbar(getErrMsg(error, "Failed to update email template."), {
					variant: "error",
				});
			} finally {
				progressDone();
			}
		}
	};

	useEffect(() => {
		if (type && category) {
			getEmailTemplates(type, category);
		}
	}, [type, category]);

	return (
		<Box mx="auto">
			<HeaderWithLine title={"Add Organization Email Template"} variant={"h6"} help={"admin.updateEmailTemplate"} />
			<Box mx="auto" p={1}>
				<form onSubmit={handleSubmit(updateOrgEmailTemplate)}>
					<Controller name="organizations" control={control} rules={{ required: true }} render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => <OrgDropDown ref={ref} onChange={onChange} required />} />
					{/* <OrgAutoComplete dataList={orgsName} selectedOrgId={setSelectedOrgId} orgError={orgError} resetOrg={resetOrg}/> */}

					<AdvFormControl errors={errors} name={"Type"} margin={"normal"}>
						<Controller
							as={
								<Select id="email-template-type" fullWidth>
									<MenuItem key="SEND_GRID" value="SEND_GRID" onClick={() => setType("SEND_GRID")}>
										Send Grid
									</MenuItem>
								</Select>
							}
							name="Type"
							rules={{ required: "Email template type is required" }}
							control={control}
							defaultValue={null}
						/>
					</AdvFormControl>
					<AdvFormControl errors={errors} name={"Category"} margin={"normal"}>
						<Controller
							as={
								<Select id="email-template-category" fullWidth>
									<MenuItem key="ISSUE_CREDENTIALS" value="ISSUE_CREDENTIALS" onClick={() => setCategory("ISSUE_CREDENTIALS")}>
										Issue Credentials
									</MenuItem>
								</Select>
							}
							name="Category"
							rules={{ required: "Email template category is required" }}
							control={control}
							defaultValue={null}
						/>
					</AdvFormControl>
					<AdvFormControl errors={errors} name={"Template For"} margin={"normal"}>
						<Controller
							as={
								<Select id="email-template-for" fullWidth>
									<MenuItem key="ORG" value="ORG">
										Organization
									</MenuItem>
									<MenuItem key="CREDENTIAL_DEFINITIONS" value="CREDENTIAL_DEFINITIONS">
										Credential Definitions
									</MenuItem>
								</Select>
							}
							name="Template For"
							rules={{ required: "Email template for is required" }}
							control={control}
							defaultValue={null}
						/>
					</AdvFormControl>
					<AdvFormControl errors={errors} name={"Email Template"}>
						<Controller
							as={
								<Select id="email-template" fullWidth>
									{emailTemplates.map((item) => (
										<MenuItem key={item._id} value={item._id}>
											{item.name}
										</MenuItem>
									))}
									{!emailTemplates.length && (
										<MenuItem>
											<i>No Email Templates</i>
										</MenuItem>
									)}
								</Select>
							}
							name="Email Template"
							rules={{ required: "Email template is required" }}
							control={control}
							defaultValue={null}
						/>
					</AdvFormControl>
					<Box mx="auto" p={1} />
					<Button color="warning" id="updateEmailTemplate" type="submit" fullWidth={true}>
						Add Template
					</Button>
				</form>
			</Box>
		</Box>
	);
};

export default UpdateOrgEmailTemplate;
