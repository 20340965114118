import create from "zustand";
import jwt from "jsonwebtoken";
import { persist } from "zustand/middleware";

import {
	JWT_TOKEN,
	JWT_PUB,
	ALGORITHM,
	AUTH_PREFIX,
	ACCOUNTS_PREFIX,
	DEFAULT_ORG,
	LOGIN_ROUTE,
	GOOGLE_CLASSROOM_EMAIL,
	MULTI_ORGS,
	SETTINGS,
	BATCH_LABELS,
	ICONS,
	CANVAS_EMAIL,
	BRIDGE_EMAIL,
	SCHOOLOGY_EMAIL,
	BRIGHTSPACE_USER,
	DEFAULT_EMAIL,
	TCSION_EMAIL,
	USER_ALTERNATE_EMAILS,
  PDF_EMAIL_TEMPLATES,
  SSO_PAYLOAD_VERIFICATION_KEY,
  API_URL,
	postApi,
	getSettings,
} from "@cti-workspace/helpers";

const algorithms = [ALGORITHM];
const pub = JWT_PUB.replace(/\\n/g, "\n");

const clearLocalStorage = async () => {
	await clearStorageLMS();
	localStorage.removeItem(SETTINGS);
	localStorage.removeItem(BATCH_LABELS);
	localStorage.removeItem(ICONS);
	localStorage.removeItem(DEFAULT_EMAIL);
	localStorage.removeItem(USER_ALTERNATE_EMAILS);
	localStorage.removeItem(PDF_EMAIL_TEMPLATES);
	localStorage.removeItem(MULTI_ORGS);
	sessionStorage.clear();
};

const clearStorageLMS = async () => {
	localStorage.removeItem(CANVAS_EMAIL);
	localStorage.removeItem(BRIDGE_EMAIL);
	localStorage.removeItem(BRIGHTSPACE_USER);
	localStorage.removeItem(GOOGLE_CLASSROOM_EMAIL);
	localStorage.removeItem(SCHOOLOGY_EMAIL);
	localStorage.removeItem(TCSION_EMAIL);
};

export const userStore = create(
	persist(
		(set) => ({
			hasToken: false,
			validToken: false,
			isLoggedIn: false,
			jwtToken: false,

			// LMS stuff
			loggedInProvider: "",
			loggedInEmail: "",
			loggedInBridgeEmail: "",
			loggedInBrightspaceUser: "",
			loggedInCanvasEmail: "",
			loggedInSchoologyEmail: "",
			loggedInTCSIONEmail: "",
			// Functions
			setState: (data) => set((state) => ({ ...state, ...data })),
			userLogIn: ({ email, password, organization }, passwordResetData, globalEdSSO, googleSSO, googleSSOData) => userLogInFn({ email, password, organization }, passwordResetData, globalEdSSO, googleSSO, googleSSOData, set),
			setDefaultOrg: (organization) => setDefaultOrgFn(organization, set),
			userLogOut: () => userLogOutFn(set),
		}),
		{
			name: "user-storage", // name of item in the storage (must be unique)
		}
	)
);

const userLogInFn = async ({ email, password, organization }, passwordResetData = null, globalEdSSO = false, googleSSO = false, googleSSOData = null, set) => {
	// dbAccount._id, accountID, orgid

	if (!organization) {
		try {
			let data = null;

			if (globalEdSSO) {
				//GlobalEd SSO login
				data =
					passwordResetData || (await postApi(`/${AUTH_PREFIX}/globalEd/login`, {
              email, "payloadkey": SSO_PAYLOAD_VERIFICATION_KEY
            }));
			} else if (googleSSO) {
				//Google SSO login
				const tokenId = googleSSOData.tokenId;
				data =
					passwordResetData ||
					(await postApi(`/${AUTH_PREFIX}/oAuthGoogleLogin`, {
						tokenId,
					}));
			} else {
				data =
					passwordResetData ||
					(await postApi(`/${AUTH_PREFIX}/${LOGIN_ROUTE}/`, {
						email,
						password,
					}));
			}

			// TODO: remove this check once the passwordReset endpoint is migrated to new response format
			if (!passwordResetData) {
				data = data.payload;
			}
			if (!data) throw new Error("User data not found");
			let { dbAccount } = data;
			let { roles, sub } = dbAccount;
			if (sub === "issuer") {
				roles = [...roles, "holder"];
			}
			localStorage.setItem(JWT_TOKEN, data.token);
			if (dbAccount?.organizations?.default || (dbAccount.roles.length === 1 && dbAccount.roles.includes("holder"))) {
				// TODO
				await getSettings({ ...dbAccount });
				// call this after organization is set
				set((state) => ({
					...state,
					hasToken: true,
					validToken: true,
					isLoggedIn: true,
					jwtToken: data.token,
					...dbAccount,
					roles: Object.keys([...roles].filter((item) => item).reduce((acc, item) => ({ ...acc, [item]: true }), {})),
				}));
			} else {
				// store the organizations data in the sessionStorage
				localStorage.setItem(MULTI_ORGS, JSON.stringify(dbAccount?.organizations));
			}

			return data;
		} catch (error) {
			clearLocalStorage();
			console.log(error);
			throw error;
		}
	} else {
		try {
			let data = await postApi(`/${ACCOUNTS_PREFIX}/${DEFAULT_ORG}/`, {
				...organization,
			});
			if (!data) throw new Error("User data not found");
			localStorage.setItem(JWT_TOKEN, data.token);

			const { dbAccount } = data;
			let { roles, sub } = dbAccount;

			await getSettings({ ...dbAccount });
			// call this after organization is set

			set((state) => ({
				...state,
				hasToken: true,
				validToken: true,
				isLoggedIn: true,
				jwtToken: data.token,
				...dbAccount,
				roles: Object.keys([...roles].filter((item) => item).reduce((acc, item) => ({ ...acc, [item]: true }), {})),
			}));

			return data;
		} catch (error) {
			clearLocalStorage();
			console.log(error);
			throw error;
		}
	}
};

const setDefaultOrgFn = async (organization, set) => {
	try {
		let data = await postApi(`/${ACCOUNTS_PREFIX}/${DEFAULT_ORG}/`, {
			...organization,
		});
		if (!data) throw new Error("User data not found");
		localStorage.setItem(JWT_TOKEN, data.token);

		const { dbAccount } = data;
		let { roles, sub } = dbAccount;

		await getSettings({ ...dbAccount });
		// call this after organization is set

		set((state) => ({
			...state,
			hasToken: true,
			validToken: true,
			isLoggedIn: true,
			jwtToken: data.token,
			...dbAccount,
			roles: Object.keys([...roles].filter((item) => item).reduce((acc, item) => ({ ...acc, [item]: true }), {})),
		}));

		clearStorageLMS();

		return data;
	} catch (error) {
		clearLocalStorage();
		console.log(error);
		throw error;
	}
};

const userLogOutFn = async (set) => {
	set(
		({ capabilities, dbConnections, email, emails, firstName, lastName, organizations, roles, _id, ...rest }) => ({
			...rest,
			hasToken: false,
			validToken: false,
			isLoggedIn: false,
			jwtToken: false,
			loggedInProvider: "",
			loggedInEmail: "",
			loggedInBridgeEmail: "",
			loggedInBrightspaceUser: "",
			loggedInCanvasEmail: "",
			loggedInSchoologyEmail: "",
			loggedInTCSIONEmail: "",
		}),
		true
	);
	clearLocalStorage();
};
