import { AppBar, Toolbar, makeStyles, Button } from "@material-ui/core";
import useUserHook from "../../hooks/useUserHook";
import ipwruLogo from "../../assets/images/iPWRU_Logo_Med.png";
import HomeIcon from "@material-ui/icons/Home";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HideOnsScroll from "../../components/HideOnScroll";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	menuButton: {
		marginRight: theme.spacing(2),
	},
	simpleAppBar: {
		zIndex: theme.zIndex.drawer + 2,
		width: "100%",
		pointerEvents: "none",
	},
}));

export default ({ showClose = true }) => {
	const classes = useStyles();
	let { userLogOut } = useUserHook();
	const navigate = useNavigate();
	return (
		<AppBar position="fixed" color="transparent" elevation={0} className={classes.simpleAppBar}>
			<HideOnsScroll>
				<Toolbar>
					<img
						style={{ width: "15rem" }}
						src={ipwruLogo}
						alt="Trybe.id"
						onDoubleClick={() => {
							userLogOut();
							navigate("/login");
							window.location.reload();
						}}
					/>
					<Button
						color="inherit"
						aria-label="open drawer"
						onClick={() => {
							navigate(-1);
						}}
						style={{ marginLeft: "auto", pointerEvents: "auto" }}
						id="app-bar-open"
						className={classes.menuButton}
						startIcon={showClose ? <ArrowBackIosIcon /> : <HomeIcon />}>
						{showClose ? "Back" : "Home"}
					</Button>
				</Toolbar>
			</HideOnsScroll>
		</AppBar>
	);
};
