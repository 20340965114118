import { Component } from "react";

class ImageBlock extends Component {
	render() {
		const { blockProps } = this.props;
		const { entity } = blockProps;
		const { src, alt, styleString, hAlign, vAlign, fWidth } = entity.getData();

		return (
			<span
				style={{
					display: "flex",
					justifyContent: hAlign || "center",
					alignItems: vAlign || "center",
					width: fWidth || "90%",
				}}
			>
				<img className="element" src={src} alt={alt} width="100%" style={styleString} />
			</span>
		);
	}
}

export default ImageBlock;
