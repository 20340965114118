import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import HeaderWithLine from "../../../components/HeaderWithLine";
import { useForm, Controller } from "react-hook-form";
import AdvTextField from "../../../components/AdvTextField";
import { useSnackbar } from "notistack";
import useNProgressHook from "../../../hooks/useNProgressHook";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import AdminFormFieldSeparator from "../../../components/EmailTemplates/AdminFormFieldSeparator";
import UserRoles from "./userRoles";
import { Box, Button } from "@cti-workspace/ui";
import OrgDropDown from "../../../components/OrgDropDown/OrgDropDown";
import { getErrMsg, accounts } from "@cti-workspace/helpers";

const AddUser = () => {
	const { register, handleSubmit, control, errors, reset } = useForm();
	const [noEmail, setNoEmail] = useState(true);
	const [selectedRole, setSelectedRole] = useState(null);
	const [selectedCapabilities, setSelectedCapabilities] = useState({});
	const [capabilitiesValidationFailed, setCapabilitiesValidationFailed] = useState(false);

	const { enqueueSnackbar } = useSnackbar();
	const { progressStart, progressDone } = useNProgressHook();

	const createAccount = async (form) => {
		const { organizations, ...rest } = form;

		setCapabilitiesValidationFailed(false);
		let checkedCapabilitiesCount = 0;
		let capabilities = {};
		for (let capability in selectedCapabilities) {
			const { [capability]: selected } = selectedCapabilities;
			if (selected?.checked === true) {
				const { [capability]: currentCapabilities } = capabilities;
				capabilities = {
					...capabilities,
					[capability]: { ...currentCapabilities, checked: undefined },
				};
				checkedCapabilitiesCount++;
			}
		}

		if (checkedCapabilitiesCount === 0) {
			setCapabilitiesValidationFailed(true);
			return;
		}

		progressStart();

		const userRoles = [];
		// get the index of the org
		const { issuers } = organizations;

		if (issuers && issuers.length > 0 && issuers.indexOf("issuer") > -1) {
			userRoles.push("holder", "issuer");
		}
		if (issuers && issuers.length > 0 && issuers.indexOf("verifier") > -1) {
			userRoles.push("verifier");
		}
		if (selectedRole === "OrgAdmin") {
			userRoles.push("orgAdmin");
		}

		const data = {
			...rest,
			["organizations"]: { default: organizations.name },
			["roles"]: userRoles,
			capabilities,
		};

		// console.log(organization);

		try {
			let accountData = await accounts.createAccount(data, !noEmail);

			if (accountData) {
				enqueueSnackbar(`User added to organization.`, {
					variant: "success",
				});
			}
			reset();
			setSelectedCapabilities({});
			setSelectedRole(null);
		} catch (error) {
			enqueueSnackbar(getErrMsg(error), {
				variant: "error",
			});
		} finally {
			progressDone();
		}
	};

	return (
		<>
			<Box mx="auto" p={-2}>
				<HeaderWithLine title={"New User Account"} variant={"h6"} help={"admin.addUser"} />
			</Box>
			<Grid>
				<Box mx="auto" p={1}>
					<form onSubmit={handleSubmit(createAccount)}>
						<Controller
							name="organizations"
							control={control}
							rules={{ required: true }}
							render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
								// <OrgAutoComplete ref={ref} onChange={onChange} invalid={invalid}/>
								<OrgDropDown ref={ref} onChange={onChange} required returnValue />
							)}
						/>
						<AdminFormFieldSeparator />
						<AdvTextField
							name="email"
							errors={errors}
							inputRef={register({
								required: "Email is required",
							})}
							placeholder={"user@ipwru.com"}
						/>
						<AdminFormFieldSeparator />
						<AdvTextField
							name="password"
							errors={errors}
							inputRef={register({
								required: "Password is required",
							})}
							type={"password"}
							placeholder={"Password"}
						/>
						<AdminFormFieldSeparator />
						<AdvTextField
							name="firstName"
							errors={errors}
							inputRef={register({
								required: "FirstName is required",
							})}
							placeholder={"First Name"}
						/>
						<AdminFormFieldSeparator />
						<AdvTextField
							name="lastName"
							errors={errors}
							inputRef={register({
								required: "LastName is required",
							})}
							placeholder={"Last Name"}
						/>
						<AdminFormFieldSeparator />
						<FormControlLabel
							control={
								<Checkbox
									checked={noEmail}
									onChange={(event) => {
										setNoEmail(event.target.checked);
									}}
									name="noEmail"
									color="secondary"
								/>
							}
							label="Send Activation Email?"
							style={{ marginBottom: "0.5rem" }}
						/>
						<UserRoles selectedCapabilities={selectedCapabilities} setSelectedCapabilities={setSelectedCapabilities} capabilitiesValidationFailed={capabilitiesValidationFailed} selectedRole={selectedRole} setSelectedRole={setSelectedRole} />
						<Box mx="auto" p={1} />
						<Button color="warning" id="login" type="submit" fullWidth={true}>
							Register Account
						</Button>
					</form>
				</Box>
			</Grid>
		</>
	);
};

export default AddUser;
