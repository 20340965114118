import { useEffect, useState } from "react";
import { getExploreAnalytics } from "./analyticsUtils";
import { useSnackbar } from "notistack";

import "./analytics.css";
import { Button, Table } from "@cti-workspace/ui";
import { isValidHttpUrl } from "@cti-workspace/helpers";

const columns = [
	{
		prop: "id",
		label: "Credential ID",
		center: false,
		width: "19rem",
		sort: true,
		cell: (row) => <div title={row.id}>{row.id}</div>,
	},
	{
		prop: "credentialName",
		label: "Credential Name",
		center: false,
		width: "20rem",
		sort: true,
		cell: (row) => <div title={row.credentialName}>{row.credentialName}</div>,
	},
	{
		prop: "issuedOn",
		label: "Issued At",
		width: "13rem",
		sort: true,
		cell: (row) => <div title={row.issuedOn}>{row.issuedOn}</div>,
	},
	{
		prop: "recipientEmail",
		label: "Recipient Email",
		width: "15rem",
		cell: (row) => <div title={row.recipientEmail}>{row.recipientEmail}</div>,
	},
	{
		prop: "recipientName",
		label: "Recipient Name",
		width: "15rem",
		cell: (row) => <div title={row.recipientName}>{row.recipientName}</div>,
	},
	{
		prop: "opened",
		label: "Opened",
	},
	{
		prop: "walletCreated",
		label: "Web Wallet Created",
		width: "9rem",
	},
	{
		prop: "addedToMobileWallet",
		label: "Added to Mobile Wallet",
		width: "10rem",
	},
	{
		prop: "addedToLinkedIn",
		label: "Added to LinkedIn",
		width: "10rem",
	},
	{
		prop: "postedOnLinkedIn",
		label: "Posted on LinkedIn",
		width: "10rem",
	},
	{
		prop: "postedOnTwitter",
		label: "Posted on Twitter",
		width: "8rem",
	},
	{
		prop: "publicUrl",
		label: "View",
		width: "6rem",
		cell: (row) => <Button buttonType="icon" color="secondary" invert icon="eye" onClick={() => window.open(row.publicUrl, "_blank")} disabled={!isValidHttpUrl(row.publicUrl)} />
	}
];

const ExploreAnalyticsTable = ({ startDate, endDate, setPage, pageSize, setPageSize, setSortModel, searchText }) => {
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [rowCount, setRowCount] = useState(0);

	const getData = async (pageNumber, pageSize, search = null) => {
		setData([]);
		setLoading(true);
		try {
			const exploreAnalyticsData = await getExploreAnalytics(pageNumber, pageSize, startDate, endDate, search);
			setData(exploreAnalyticsData.data);
			setRowCount(exploreAnalyticsData.totalItems);
		} catch (e) {
			enqueueSnackbar(`Failed to retrieve analytics data. Please try again.`, { variant: "error" });
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData(1, pageSize);
	}, []);

	const handleOnPageChange = (pageData) => {
		setPage(pageData.page);
		getData(pageData.page, pageSize, searchText);
	};

	const handleOnPageSizeChange = (pageData) => {
		setPageSize(pageData.pageSize);
		getData(1, pageData.pageSize, searchText);
	};

	useEffect(() => {
		setPage(1);
		setPageSize(10);
		if (!!searchText?.trim()) {
			getData(1, 10, searchText);
		} else {
			getData(1, 10, null);
		}
	}, [searchText]);

	return (
		<Table
			config={{ headers: columns }}
			data={data}
			pagination
			totalRows={rowCount}
			onChangePage={(page) => handleOnPageChange({ page })}
			onChangeRowsPerPage={(event) => handleOnPageSizeChange({ pageSize: event })}
			onSort={(sortCol, sortDirection) => setSortModel([{ field: sortCol.prop, sort: sortDirection }])}
			showSearch={false}
			loading={loading}
			loaderCenter
		/>
	);
};

export default ExploreAnalyticsTable;
