import { useState } from "react";
import HeaderWithLine from "../HeaderWithLine";
import AdvTextField from "../AdvTextField";
import { Controller, useForm } from "react-hook-form";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useNProgressHook from "../../hooks/useNProgressHook";
import { useSnackbar } from "notistack";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AdvFormControl from "../AdvFormControl";
import AdminFormFieldSeparator from "./AdminFormFieldSeparator";
import { Box, Button } from "@cti-workspace/ui";
import { getErrMsg, addEmailTemplate } from "@cti-workspace/helpers";

const CreateNewEmailTemplate = () => {
	const { register, handleSubmit, errors, reset, control } = useForm();
	const { progressStart, progressDone } = useNProgressHook();
	const { enqueueSnackbar } = useSnackbar();
	const [isDefault, setIsDefault] = useState(false);
	const [isActive, setIsActive] = useState(true);

	const createEmailTemplate = async (form) => {
		progressStart();
		const data = {
			type: form.Type,
			category: form.Category,
			name: form.templateName,
			active: isActive,
			categoryDefault: isDefault,
			templateId: form.templateId,
		};
		try {
			await addEmailTemplate(data);
			enqueueSnackbar(`New Email Template added.`, { variant: "success" });
			reset();
			setIsActive(true);
			setIsDefault(false);
		} catch (error) {
			enqueueSnackbar(getErrMsg(null, "Failed to add new email template"), {
				variant: "error",
			});
		} finally {
			progressDone();
		}
	};

	return (
		<>
			<Box mx="auto">
				<HeaderWithLine title={"Add New Email Template"} variant={"h6"} help={"admin.addNewEmailTemplate"} />
				<Box mx="auto" p={1}>
					<form onSubmit={handleSubmit(createEmailTemplate)}>
						<AdvFormControl errors={errors} name={"Type"} margin={"normal"}>
							<Controller
								as={
									<Select id="email-template-type" fullWidth>
										<MenuItem key="SEND_GRID" value="SEND_GRID">
											Send Grid
										</MenuItem>
									</Select>
								}
								name="Type"
								rules={{ required: "Email template type is required" }}
								control={control}
							/>
						</AdvFormControl>
						<AdvFormControl errors={errors} name={"Category"}>
							<Controller
								as={
									<Select id="email-template-category" fullWidth>
										<MenuItem key="ISSUE_CREDENTIALS" value="ISSUE_CREDENTIALS">
											Issue Credentials
										</MenuItem>
									</Select>
								}
								name="Category"
								rules={{ required: "Email template category is required" }}
								control={control}
							/>
						</AdvFormControl>
						<AdvTextField
							name="templateId"
							errors={errors}
							inputRef={register({
								required: "Template ID  is required",
							})}
							placeholder={"Template ID"}
						/>
						<AdminFormFieldSeparator />
						<AdvTextField
							name="templateName"
							errors={errors}
							inputRef={register({
								required: "Template Name  is required",
							})}
							placeholder={"Template Name"}
						/>
						<FormControlLabel value="default" control={<Switch checked={isDefault} color="primary" onChange={(e) => setIsDefault(e.target.checked)} />} label="Default" labelPlacement="start" />
						<FormControlLabel value="active" control={<Switch checked={isActive} color="primary" onChange={(e) => setIsActive(e.target.checked)} />} label="Active" labelPlacement="start" />
						<Box mx="auto" p={1} />
						<Button color="warning" id="createNewEmailTemplate" type="submit" fullWidth={true}>
							Add Template
						</Button>
					</form>
				</Box>
			</Box>
		</>
	);
};

export default CreateNewEmailTemplate;
