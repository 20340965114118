import { AsyncPaginate } from "react-select-async-paginate";
import styled from 'styled-components';
import { ThemeProvider } from "theme-ui";
import Theme from "../theme/theme";

const StyledSelect = styled(AsyncPaginate)`
.AsyncSelect__control {
  height: ${({ size }) => size === "small" ? "40px" : "50px"};
  width: 100%;
  border: 1px solid ${props => props.error ? Theme.colors.error : "rgb(192, 192, 192)"};
  border-radius: 4px;
  cursor: auto;
  ${({ style }) => ({ ...style })}
}

.AsyncSelect__control:hover {
  border-color: #324353;
}

.AsyncSelect__control--is-focused {
  box-shadow: 0 0 0 1px black;
  outline: none;
}

.AsyncSelect__indicator-separator {
  display: none;
}

.AsyncSelect__menu {
  color: #000;
  background-color: #fff;
  z-index: 1000;
}

.AsyncSelect__option--is-selected {
  background-color: rgba(156, 73, 237, 1)
}

.AsyncSelect__option--is-focused {
   background-color: rgba(156, 73, 237, 0.5) ;
   &:active{
        background-color: rgba(156, 73, 237, 0.3);
    }
}

`;

const astrickStyles = `
	::after {
		content: " *";
		font-weight:normal;
		font-size:.8em;
		vertical-align: top;
	}
`;

const TextLabel = styled.label`
	font-size: 0.9rem;
	font-weight: bold;
  line-height: 1.7rem;
	${(props) => (props.required ? astrickStyles : "")}
`;

const HelperText = styled.p`
	color: ${(props) => (props.error ? Theme.colors.error : Theme.colors.text)};
	font-size: 0.75rem;
	line-height: 1.2rem;
	margin: 3px 14px 0;
`;

export function AsyncComboBox({ children, label = "", required = false, error = false, helperText = "", style={}, size="", ...rest }) {
  return (
    <ThemeProvider theme={Theme}>
      {label && <TextLabel required={required}>{label}</TextLabel>}
      <StyledSelect label={label} required={required} error={error} style={style} size={size} {...rest} classNamePrefix={"AsyncSelect"} />
      {helperText && <HelperText error={error}>{helperText}</HelperText>}
    </ThemeProvider>
  );
}

/** @jsxImportSource theme-ui */
export default AsyncComboBox;