import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

import "@fontsource/source-sans-pro";
import "@fontsource/lato";
import "@fontsource/roboto";

let lato = ["Lato", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"].join(",");

let sourceSansPro = ["Source Sans Pro", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"].join(",");

let robotoFont = ["Roboto", "sans-serif"].join(",");

// Create a theme instance.
let theme = createTheme({
	palette: {
		primary: {
			light: "#fffff",
			main: "#fafafa",
			dark: "#c7c7c7",
			contrastText: "#000",
		},
		secondary: {
			main: "#9c49ed",
			light: "#ff9933",
			dark: "#b25900",
			contrastText: "#000",
		},
		type: "dark",
		background: {
			default: "#232e3a",
			paper: "#324353",
		},
	},
	typography: {
		htmlFontSize: 14,
		fontSize: 12,
		fontFamily: lato,
		body2: {
			fontFamily: robotoFont,
		},
		body1: {
			fontFamily: robotoFont,
			fontWeight: 400,
			fontSize: "1rem",
		},
		subtitle2: {
			fontFamily: robotoFont,
		},
		subtitle1: {
			fontFamily: robotoFont,
			fontWeight: 400,
			fontSize: "1rem",
		},
		button: {
			fontFamily: robotoFont,
			fontWeight: "bolder",
			fontSize: "1rem",
			letterSpacing: "1.5px",
			lineHeight: "19px",
		},
	},
});

theme = responsiveFontSizes(theme);

export default theme;
