import { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useNProgressHook from "../../hooks/useNProgressHook";
import { Divider, Grid, Paper } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Card, CardActions, CardContent, CardMedia } from '@cti-workspace/ui';

const IntegrationOptionCard = ({ image, bodyText, buttonText, provider, setSelectedIntegrationProvider, getCreds = () => {} }) => {
	const { progressStart, progressDone } = useNProgressHook();

	useEffect(() => {
		console.log(provider);
	}, [provider]);

	const importCourses = async () => {
		try {
			progressStart();
			await getCreds();
			const imported = await provider.importCourses();
			if (imported) {
				setSelectedIntegrationProvider(provider.type);
			}
			progressDone();
		} catch (err) {
			console.error(err);
			progressDone();
		}
	};

	return (
		<Card>
			<CardMedia>
				<img
					src={image}
					style={{
						maxHeight: "10rem",
						marginLeft: "auto",
						marginRight: "auto",
						display: "block",
						padding: "1rem",
					}}
				/>
			</CardMedia>
			<CardContent style={{ paddingBottom: "0px" }}>
				<Grid container>
					<Grid item xs={12}>
						<Typography gutterBottom variant="h5" component="h2">
							{provider.name}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body2" color="textSecondary" component="p">
							{bodyText}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
			<CardActions style={{ alignItems: "flex-end", height: "100%" }}>
				{provider && !provider.disabled && provider.loggedInSchoologyEmail ? ( // Schoology Integration
					<div style={{ width: "100%" }}>
						<Divider />
						<Grid container spacing={1} style={{ marginTop: "5px" }}>
							<Grid item xs={12} style={{ marginTop: "5px" }}>
								<Typography variant="body2" color="textSecondary" component="p">
									{/* Commented out
										Logged in as <b>{provider.loggedInSchoologyEmail}</b> 
									*/}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Button fullWidth variant="contained" size="small" color="primary" onClick={importCourses}>
									Import Courses
								</Button>
							</Grid>
							<Grid item xs={12}>
								<Button fullWidth size="small" color="primary" onClick={provider.logout}>
									Logout
								</Button>
							</Grid>
						</Grid>
					</div>
				) : (
					<div style={{ width: "100%" }}>
						{provider?.disabled ? (
							<Typography align="center" variant="subtitle2" color="textSecondary">
								{buttonText}
							</Typography>
						) : (
							<Button fullWidth size="small" color="primary" variant="outlined" onClick={provider.login}>
								{buttonText}
							</Button>
						)}
					</div>
				)}
			</CardActions>
		</Card>
	);
};

export default IntegrationOptionCard;
