import { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import debounce from "lodash/debounce";
import { flextree } from "d3-flextree";
import { hierarchy, tree } from "d3-hierarchy";

const PADDING = 30;

function useResize(ref) {
	const [state, setState] = useState();
	useEffect(() => {
		const getSize = debounce(() => {
			if (!ref || !ref.current) {
				return;
			}

			const width = ref.current.offsetWidth;
			const height = ref.current.offsetHeight;
			setState({
				width,
				height,
			});
		}, 1000);

		window.addEventListener("resize", getSize);
		getSize();
		return () => window.removeEventListener("resize", getSize);
	}, [ref]);

	return state;
}

function addNode(enter, text) {
	const labelWidth = 226;
	const labelHeight = 64;
	const labelPadding = 10;
	const node = enter.append("g").attr("user-select", "none").attr("class", "node").style("cursor", "move");

	node.append("rect").attr("rx", 14).attr("ry", 14).attr("width", labelWidth).attr("height", labelHeight).attr("fill", "#404040").attr("stroke", "#707070");

	node
		.append("foreignObject")
		.attr("width", labelWidth - labelPadding * 2)
		.attr("height", 22)
		.attr("x", labelPadding)
		.attr("y", labelPadding)
		.html((d) => {
			return `<div>
            <form>
              <input type='text' class='' value=${text} />
              <button type='button' onclick='alert("The form was submitted")'>Ok</button>
            </form>
            <div class='circle'></div>
          </div>`;
		});
}

const Chart = ({ data }) => {
	const rootRef = useRef(null);
	const ref = useRef();
	const size = useResize(rootRef);

	useEffect(() => {
		// eslint-disable-next-line no-extra-boolean-cast
		if (!!size) {
			draw(data, size);
		}
	}, [size, data]);

	const draw = (data, { width, height }) => {
		// d3 selects the svg
		if (Array.isArray(data)) {
			console.log(data);
			const node = d3.select(ref.current).classed("line-chart", true).attr("width", width).attr("height", height);

			const treemap = flextree().spacing(15);
			const root = treemap.hierarchy(data, function (d) {
				return d.children;
			});

			// this is to clear out old drawings
			const everything = node.selectAll("*");
			everything.remove();

			const container = node.append("g").classed("container", true);

			const update = node.append("g").selectAll("circle").attr("transform", `translate(${50}, ${50})`).data(data);

			update
				.enter()
				.append("circle")
				.merge(update)
				.attr("r", (d) => d)
				.attr("cx", (_, i) => width * (i + 1))
				.attr("cy", () => Math.random() * 100)
				.attr("stroke", (_, i) => (i % 2 === 0 ? "#f80" : "#aaf"))
				.attr("fill", (_, i) => (i % 2 === 0 ? "orange" : "#44f"));

			update.exit().remove();
		}
		// addNode(container, "hello");
	};

	return (
		<div style={{ height: "100%", width: "100%", backgroundColor: "#F1F4F9" }} ref={rootRef}>
			{size && <svg width={size.width} height={size.height} ref={ref}></svg>}
		</div>
	);
};

export default Chart;
