import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import useNProgressHook from "../../hooks/useNProgressHook";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { Card, CardActions, CardHeader, CardMedia } from '@cti-workspace/ui';

const IntegrationConnectionCard = ({ providerImage, credential, provider }) => {
	const { progressStart, progressDone } = useNProgressHook();
	return (
		<Card
			elevation={1}
			style={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<div>
				<CardHeader avatar={<Avatar aria-label="number" src={credential.imageUrl} />} title={credential.courseName} subheader={credential.courseWorkName} />
				<CardMedia>
					<img
						alt={"Provider"}
						src={providerImage}
						style={{
							maxHeight: "10rem",
							marginLeft: "auto",
							marginRight: "auto",
							display: "block",
							padding: "1rem",
						}}
					/>
				</CardMedia>
			</div>
			<CardActions>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
						<Paper
							elevation={1}
							style={{
								padding: "1.2rem",
								display: "flex",
							}}
						>
							<VerifiedUserIcon fontSize="small" style={{ color: "green", marginRight: "5px" }} />
							<Typography variant="body2">{credential.credentialName}</Typography>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
						<Button
							fullWidth
							size="small"
							color="primary"
							variant="contained"
							onClick={async () => {
								progressStart();
								await provider.deleteConnectedCredential(credential.courseId, credential.courseWorkId);
								progressDone();
							}}
						>
							Disconnect
						</Button>
					</Grid>
				</Grid>
			</CardActions>
		</Card>
	);
};

export default IntegrationConnectionCard;
