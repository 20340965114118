// Core Functions
export const getActiveStyle = (styleName, activeObject) => {
	if (!activeObject) {
		return "";
	}
	const { [styleName]: activeObjectStyleName } = activeObject;
	const updatedGetSelectionStyles = activeObject.getSelectionStyles ? activeObject.getSelectionStyles() : {};
	const { [styleName]: selectedStyleName } = updatedGetSelectionStyles;
	return activeObject.getSelectionStyles && activeObject.isEditing ? selectedStyleName || "" : activeObjectStyleName || "";
};

export const setActiveStyle = (styleName, value, activeObject) => {
	if (!activeObject) {
		return;
	}

	if (activeObject.setSelectionStyles && activeObject.isEditing) {
		let style = {
			[styleName]: value,
		};
		activeObject.setSelectionStyles(style);
		activeObject.setCoords();
	}

	activeObject.set(styleName, value).setCoords();
	activeObject.canvas.renderAll();
};

export const getActiveProp = (name, activeObject) => {
	if (!activeObject) {
		return "";
	}
	const { [name]: activeObjectName } = activeObject;
	return activeObjectName || "";
};

export const setActiveProp = (name, value, activeObject) => {
	if (!activeObject) {
		return;
	}
	activeObject.set(name, value).setCoords();
	activeObject.canvas.renderAll();
};
