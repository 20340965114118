import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CredentialCard from "../CredentialCard";
import ActionDialog from "../../components/ActionDialog";
import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from "@cti-workspace/ui";
import RevokeManyDialog from "../CredentialCard/revokeMany/revokeManyDialog";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: "left",
		color: theme.palette.text.secondary,
		height: "100%",
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		overflow: "auto",
	},
}));

export default function FlexGrid({ handleIssueCredentialButton, credDefs, archiveCredentialDefinition, copyCredentialDefinition, deleteCredentialDefinition, deleteConnectedCredential, setSelectedCredToEdit, reloadCreds, connectedCredsData }) {
	const classes = useStyles();
	const [connectedCred, setConnectedCred] = useState(null);
	const [deleteCred, setDeleteCred] = useState(null);
	const [revokeCredDialog, setRevokeCredDialog] = useState(null);

	const handlerCopyDefinition = async ({ _id }) => {
		await copyCredentialDefinition(_id);
	};

	const handleDeleteDefinitionDialog = async (cred) => {
		console.timeLog("answer time");
		setDeleteCred({ ...cred });
		console.timeLog("answer time");
	};

	useEffect(() => {
		if (deleteCred) {
			console.timeLog("answer time");
		}
	}, [deleteCred]);

	const handlerDeleteDefinition = async ({ _id }) => {
		// Check if there are connected creds first
		const connectedCredFound = connectedCredsData.find((cred) => cred._id === _id);
		if (connectedCredFound) {
			setConnectedCred(connectedCredFound);
		} else {
			await deleteCredentialDefinition(_id);
		}
		setDeleteCred(null);
	};

	const handlerEditDefinition = (credentialDefinition) => {
		setSelectedCredToEdit(credentialDefinition);
	};

	const getCredentialEditOptions = (value) => {
		const options = [
			{
				name: "Copy",
				handler: handlerCopyDefinition,
				id: "copy-credentials",
			},
			{
				name: "Edit",
				handler: handlerEditDefinition,
				id: "edit-credentials",
			},
			{
				name: "Delete",
				handler: handleDeleteDefinitionDialog,
				id: "delete-credentials",
			},
		];
		return options;
	};

	return (
		<div className={classes.root}>
			<CredentialCard credDefs={credDefs} getCredentialEditOptions={getCredentialEditOptions} handleIssueCredentialButton={handleIssueCredentialButton} reloadCreds={reloadCreds} setRevokeCredDialog={setRevokeCredDialog} />
			<ActionDialog
				open={connectedCred !== null}
				title="Delete Connected Credential"
				content={
					connectedCred && (
						<>
							<Typography variant="h6" color="secondary">
								This Credential is connected!
							</Typography>
							<Typography variant="h6" color="primary">
								The Connected Credential will also be removed.
							</Typography>
							<Typography variant="body2" color="primary">
								Course: {connectedCred.courseName}
							</Typography>
							<Typography variant="body2" color="primary">
								Course Work: {connectedCred.courseWorkName}
							</Typography>
						</>
					)
				}
				primaryAction={
					<Button
						onClick={async () => {
							await deleteConnectedCredential(connectedCred.courseId, connectedCred.courseWorkId);
							await deleteCredentialDefinition(connectedCred.credentialName);
							setConnectedCred(null);
						}}
						color="secondary"
						variant={"contained"}>
						Continue
					</Button>
				}
				secondaryAction={
					<Button onClick={() => setConnectedCred(null)} variant={"text"}>
						Cancel
					</Button>
				}
				onClose={() => setConnectedCred(null)}
			/>
			{
				revokeCredDialog &&
				<RevokeManyDialog data={revokeCredDialog} onClose={() => setRevokeCredDialog(null)} />
			}

			<Dialog open={Boolean(deleteCred)} maxWidth="sm" onClose={() => setDeleteCred(null)}>
				<DialogTitle>Delete Credential Definition</DialogTitle>
				<DialogContent>
					<Typography color="secondary" variant="h5">
						Are you sure you want to delete?
					</Typography>
					<Typography color="textSecondary">(Issued certificates will not be deleted).</Typography>
				</DialogContent>
				<DialogActions>
					<Button
						color="primary"
						invert
						onClick={() => {
							console.timeEnd("answer time");
							setDeleteCred(null);
						}}>
						Cancel
					</Button>
					<Button
						data-testid="deleteCredentialConfirm"
						color="secondary"
						onClick={() => {
							console.timeEnd("answer time");
							handlerDeleteDefinition(deleteCred);
						}}>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
