import React from 'react';
import styled from 'styled-components';
import { ThemeProvider, Spinner as Circle } from "theme-ui";
import Theme from "../theme/theme";

export const Spinner = ({ strokeWidth = 4, color = "", duration = 750, title = "" }) => (
  <ThemeProvider theme={Theme}>
    <Circle variant='styles.spinner' color={color} strokeWidth={strokeWidth} duration={duration} title={title} />
  </ThemeProvider>
);

export default Spinner;