import { makeStyles } from "@material-ui/core/styles";
import ProfileRoute from "./ProfileRoute";
import Style from "./profile.style";

export default function ProfileContainer() {
	const useStyles = makeStyles(Style);
	const classes = useStyles();

	return <ProfileRoute classes={classes} />;
}
