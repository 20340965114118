import { read, utils } from "xlsx";

const parseExcelSheetToJson = (file, returnRawArray = false, detailedObject = false, checkDateFormat = true) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = (e) => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = read(bstr, { type: rABS ? "binary" : "array" });
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			const arrayData = utils.sheet_to_json(ws, { header: 1, raw: false, blankrows: false });
			let dateColumnIndex = [];
			let dateError = false;

			const headerArray = arrayData[0].map((val, index) => {
				const value = val.toLowerCase().trim();
				if (value?.includes("date")) dateColumnIndex = [...dateColumnIndex, index];
				return value;
			});

			if (checkDateFormat) {
				// We don't need this check any more because of new implementation in CT-3250
				/*if (dateColumnIndex.length > 0) {
					const arrayDataWithoutHeader = arrayData.slice(1, arrayData.length);
					for (const dataValue of arrayDataWithoutHeader) {
						for (const value of dateColumnIndex) {
							const { [value]: dateValue } = dataValue;
							if (!/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(dateValue)) {
								dateError = true;
								break;
							}
						}
						if (dateError) break;
					}
				}
				if (dateError) {
					resolve({
						headers: null,
						data: null,
						error: "The Date is incorrectly formatted. Please ensure you are using the Date Number type (Format > Number > Date) and following the MM/DD/YYYY format.",
					});
					return;
				}*/
			}

			if (returnRawArray) {
				if (detailedObject) {
					let val = {};
					if (headerArray.length !== new Set(headerArray).size) {
						val = { headers: headerArray, data: null, error: "has duplicate column" };
					} else {
						const objectData = utils.sheet_to_json(ws, { header: headerArray, raw: false, blankrows: false });
						let dump = objectData.shift();
						val = { headers: headerArray, arr: arrayData, data: objectData };
					}
					resolve(val);
				} else {
					resolve(arrayData.filter((dataArray) => dataArray.length > 0));
				}
			} else {
				/* Update state */
				const arrMap = new Set(arrayData);
				let arr = [];
				for (let row of arrMap) {
					arr.push(row);
				}
				resolve(arr);
			}
		};
		if (rABS) reader.readAsBinaryString(file);
		else reader.readAsArrayBuffer(file);
	});
};

export default parseExcelSheetToJson;
