import { useEffect, useState } from "react";
import { CREDENTIAL_DEFINITIONS, ISSUE_CREDENTIALS, fetchOrgPDFTemplates } from "@cti-workspace/helpers";
import { ComboBox, Typography as Text } from '@cti-workspace/ui';

const PDFSettings = ({ encryptingContent, pdfSettings, setPdfSettings, organizationId, isEditing }) => {
  const [pdfTemplates, setPdfTemplates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const orgAndCredDefTemplates = await Promise.all([
        fetchOrgPDFTemplates(organizationId, ISSUE_CREDENTIALS),
        fetchOrgPDFTemplates(organizationId, ISSUE_CREDENTIALS, CREDENTIAL_DEFINITIONS),
      ]);
      setPdfTemplates(orgAndCredDefTemplates[0].concat(orgAndCredDefTemplates[1]));
      if (!isEditing) {
        setPdfSettings({
          ...pdfSettings,
          pdfTemplate: orgAndCredDefTemplates[0]?.[0]?._id,
        });
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <Text type="body1">
        PDF Settings
      </Text>
      <div style={{ display: "flex", alignItems: "center", gap: "0.8rem", width: "100%", marginTop: "0.5rem" }}>
        <div style={{ width: "100%" }}>
          <Text type="body2">
            Generate PDF
          </Text>
          <ComboBox
            size="small"
            placeholder=""
            options={[{ _id: "Yes", value: "Yes" }, { _id: "No", value: "No" }]}
            getOptionValue={(item) => item["_id"]}
            getOptionLabel={(item) => item.value}
            onChange={(e) => setPdfSettings({
              ...pdfSettings,
              generatePdf: e["_id"],
            })}
            value={[{ _id: "Yes", value: "Yes" }, { _id: "No", value: "No" }].find(item => item["_id"] == pdfSettings.generatePdf)}
          />
        </div>
        <div style={{ width: "100%" }}>
          <Text type="body2">
            PDF Template
          </Text>
          <ComboBox
            size="small"
            placeholder=""
            options={[{ _id: "", name: "None" }, ...pdfTemplates].filter(item => item)}
            getOptionValue={(item) => item["_id"]}
            getOptionLabel={(item) => item.name}
            onChange={(e) => setPdfSettings({
              ...pdfSettings,
              pdfTemplate: e["_id"],
            })}
            value={[{ _id: "", name: "None" }, ...pdfTemplates].find(item => item["_id"] == pdfSettings.pdfTemplate || "")}
          />
        </div>
        <div style={{ width: "100%" }}>
          <Text type="body2">
            Encrypt Content
          </Text>
          <ComboBox
            size="small"
            placeholder=""
            options={[{ key: "" }, ...((encryptingContent || []).filter(item => item?.key))]}
            getOptionValue={(item) => item["key"]}
            getOptionLabel={(item) => item.key || "None"}
            value={[{ key: "" }, ...((encryptingContent || []).filter(item => item?.key))].find(item => item["key"] == pdfSettings.encryptContent || "")}
            onChange={(e) => setPdfSettings({
              ...pdfSettings,
              encryptContent: e["key"],
            })}
          />
        </div>
      </div>
      {/* <Grid item container spacing={1} direction="column">
        <Grid item xs>
          <Typography variant="h6" color="initial">
          </Typography>
        </Grid>
        <Grid item container direction="row" justify="space-between">
          <div>
            <FormControl style={{ width: SELECT_WIDTH }}>
              <InputLabel shrink={pdfSettings.generatePdf !== null} id="select-gen-pdf">Generate PDF</InputLabel>
              <Select
                placeholder="Generate PDF"
                fullWidth
                labelId="select-gen-pdf"
                onChange={(e) => setPdfSettings({
                  ...pdfSettings,
                  generatePdf: e.target.value,
                })}
                value={pdfSettings.generatePdf}
              >
                <MenuItem value={"Yes"}>Yes</MenuItem>
                <MenuItem value={"No"}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl style={{ width: SELECT_WIDTH }}>
              <InputLabel shrink={pdfSettings.pdfTemplate !== null} id="select-pdf-template">PDF Template</InputLabel>
              <Select
                placeholder="PDF Template"
                fullWidth
                labelId="select-pdf-template"
                onChange={(e) => setPdfSettings({
                  ...pdfSettings,
                  pdfTemplate: e.target.value,
                })}
                value={pdfSettings.pdfTemplate}
              >
                <MenuItem value={null}>
                  <em>None</em>
                </MenuItem>
                {pdfTemplates.map((item) => (
                  <MenuItem key={`pdfTemp_${item._id}`} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl style={{ width: SELECT_WIDTH }}>
              <InputLabel shrink={pdfSettings.encryptContent !== null} id="select-enc-content">Encrypt
                Content</InputLabel>
              <Select
                placeholder="Encrypt Content"
                labelId="select-enc-content"
                value={pdfSettings.encryptContent}
                onChange={(e) => setPdfSettings({
                  ...pdfSettings,
                  encryptContent: e.target.value,
                })}
              >
                <MenuItem value={null}>
                  <em>None</em>
                </MenuItem>
                {encryptingContent.map((content) => (
                  <MenuItem value={content.key} key={`encOption_${content.key}`}>
                    {content.key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
      </Grid> */}
    </div>
  );
};

export default PDFSettings;
