import { Card, CheckBox, Typography } from "@cti-workspace/ui";

const SharingOptions = ({ sharingOptions, setSharingOptions }) => {
  return (
    <div>
      <Typography type="body1">
        Sharing Options
      </Typography>
      <div style={{ display: "flex", alignItems: "center", gap: "1.5rem", flexWrap: "nowrap", marginTop: "0.3rem" }}>
        <CheckBox label="Public URL" name="publicUrl" labelMargin="medium" checked={sharingOptions.publicUrl} onChange={(e) => {
          setSharingOptions({
            ...sharingOptions,
            publicUrl: e.target.checked,
            addToLinkedIn: !e.target.checked ? false : sharingOptions.addToLinkedIn,
            addToTwitter: !e.target.checked ? false : sharingOptions.addToTwitter,
            shareToLinkedIn: !e.target.checked ? false : sharingOptions.shareToLinkedIn,
          });
        }} labelStyle={{ fontSize: "0.8rem" }} />
        <CheckBox label="Add to LinkedIn" name="addToLinkedIn" labelMargin="medium" checked={!sharingOptions.publicUrl ? false : sharingOptions.addToLinkedIn}
          disabled={!sharingOptions.publicUrl} onChange={(e) => {
            setSharingOptions({
              ...sharingOptions,
              addToLinkedIn: e.target.checked,
            });
          }} labelStyle={{ fontSize: "0.8rem" }} />
        <CheckBox label="Add to Twitter" name="addToTwitter" labelMargin="medium" checked={!sharingOptions.publicUrl ? false : sharingOptions.addToTwitter}
          disabled={!sharingOptions.publicUrl} onChange={(e) => {
            setSharingOptions({
              ...sharingOptions,
              addToTwitter: e.target.checked,
            });
          }} labelStyle={{ fontSize: "0.8rem" }} />
        <CheckBox label="Share to LinkedIn" name="shareToLinkedIn" labelMargin="medium" checked={!sharingOptions.publicUrl ? false : sharingOptions.shareToLinkedIn}
          disabled={!sharingOptions.publicUrl} onChange={(e) => {
            setSharingOptions({
              ...sharingOptions,
              shareToLinkedIn: e.target.checked,
            });
          }} labelStyle={{ fontSize: "0.8rem" }} />
        <CheckBox label="Add to Wallet" name="addToWallet" labelMargin="medium" checked={sharingOptions.addToWallet} onChange={(e) => {
          setSharingOptions({
            ...sharingOptions,
            addToWallet: e.target.checked,
          });
        }} labelStyle={{ fontSize: "0.8rem" }} />
      </div>
    </div>
  );
};

export default SharingOptions;
