import { useContext, useState } from "react";
import { fabric } from "fabric-pure-browser";
import { FabricContext } from "../../context/FabricContext";
import WallpaperIcon from "@material-ui/icons/Wallpaper";
import { Image } from "@phosphor-icons/react";
import { getObjectValue } from "@cti-workspace/helpers";

const supportedImageTypes = ["image/png", "image/apng", "image/bmp", "image/gif", "image/x-icon", "image/jpeg"];

function FabricAddBG({ id, enableMultiPageCertificate }) {
	const { canvas, activeObject } = useContext(FabricContext);

	const addImageBox = (e) => {
		document.getElementById(`fabric-bg-upload`).click();
	};

	const onImageUpload = (e) => {
		const file = e.target.files[0];
		let fileType = e.target.files[0].type;
		const reader = new FileReader();

		reader.addEventListener(
			"load",
			function () {
				if (supportedImageTypes.indexOf(fileType) !== -1) {
					const activeCanvas = enableMultiPageCertificate ? getObjectValue(canvas, id) : canvas;
					fabric.Image.fromURL(reader.result, function (img) {
						img.toObject = (function (toObject) {
							return function () {
								return fabric.util.object.extend(toObject.call(this), {
									scaleX: this.scaleX,
									scaleY: this.scaleY,
								});
							};
						})(img.toObject);
						activeCanvas.setBackgroundImage(img, activeCanvas.renderAll.bind(activeCanvas), {
							scaleX: activeCanvas.width / img.width,
							scaleY: activeCanvas.height / img.height,
							originX: "left",
							originY: "top",
						});
						activeCanvas.fire("object:modified");
					});
				}
			},
			false
		);

		if (file) {
			reader.readAsDataURL(file);
			document.getElementById(`fabric-bg-upload`).value = null;
		}
	};

	return (
		<>
			<Image weight={"duotone"} onClick={addImageBox} size={17} />
			<input type="file" id={`fabric-bg-upload`} accept="image/*" onChange={onImageUpload} style={{ display: "none" }} />
		</>
	);
}

export default FabricAddBG;
