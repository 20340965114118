/* eslint-disable @typescript-eslint/no-empty-function */
import HeaderWithLine from "../HeaderWithLine";
import ResetPassword from "./ResetPassword";
import CapabilityBoundary from "../CapabilityBoundary/CapabilityBoundary";
import CAPABILITIES from "../../capabilities";
import ActionDialog from "../../components/ActionDialog";
import { Grid, Typography } from "@material-ui/core";
import { Button, Card, Container, Dialog, DialogContent, DialogActions, DialogTitle } from "@cti-workspace/ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import AdvTextField from "../AdvTextField";
import styled from "styled-components";
import { getErrMsg, useProfileHook } from "@cti-workspace/helpers";
import { useSnackbar } from "notistack";
import { VERIFICATION_CODE_STATUS, DEFAULT_EMAIL, USER_ALTERNATE_EMAILS, ALLOW_USER_DELETION, getObjectValue } from "@cti-workspace/helpers";
import { userStore } from "../../store/userStore";

const SpaceBetweenContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

const ContainerFlexEnd = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
`;

export default function UserProfileComponent({ classes, numCerts, numCreds, numRevoked, organizationEmail }) {
	const [enableEdit, setEnableEdit] = useState(false);
	const emails = userStore((state) => state.emails);
	const firstName = userStore((state) => state.firstName);
	const lastName = userStore((state) => state.lastName);
	const email = userStore((state) => state.email);
	const roles = userStore((state) => state.roles);
	const setUserState = userStore((state) => state.setState);
	const organizations = userStore((state) => state.organizations);
	const account = { emails, firstName, lastName, email, roles, organizations };
	const [alternateEmailFormIndex, setAlternateEmailFormIndex] = useState([]);
	const [emailDelete, setEmailDelete] = useState(false);
  const [primaryEmailData, setPrimaryEmailData] = useState(null);
  const [openDelete1, setOpenDelete1] = useState(false);
  const [openDelete2, setOpenDelete2] = useState(false);
  const [deleteInputValue, setDeleteInputValue] = useState(false);
	const {
		register,
		handleSubmit,
		errors,
		reset,
		formState: { isDirty },
  } = useForm();

  const orgEmail = (organizationEmail !== "" ? organizationEmail : "-");

  const { addAlternateEmail, resendVerificationCode, updatePrimaryEmail, verifyEmail, updateProfile, deleteAccount } = useProfileHook();

	const { enqueueSnackbar } = useSnackbar();

	const handleOnEditClick = () => {
		setEnableEdit(true);
		reset({
			firstName: getObjectValue(account, "firstName", ""),
			lastName: getObjectValue(account, "lastName", ""),
		});
		if (emails) {
			setAlternateEmailFormIndex(emails.filter((item) => item.status === VERIFICATION_CODE_STATUS.PENDING) || []);
		}
	};

	const handleOnEditCancel = () => {
		setEnableEdit(false);
		setAlternateEmailFormIndex([]);
	};

	const handleAddAnotherEmail = () => {
		setAlternateEmailFormIndex([...alternateEmailFormIndex, { email: "", status: "PENDING" }]);
	};

	const handleMakeEmailPrimary = async () => {
		try {
			const { email } = primaryEmailData;
			if (!email) throw new Error("Email is required");
			const res = await updatePrimaryEmail(email);
			setPrimaryEmailData(null);
			const emails = getObjectValue(account, "emails", []).filter((item) => item.email !== email);
			emails.push({
				email: getObjectValue(account, "email", ""),
				status: VERIFICATION_CODE_STATUS.VERIFIED,
			});

			localStorage.setItem(DEFAULT_EMAIL, email);
			localStorage.setItem(USER_ALTERNATE_EMAILS, JSON.stringify(emails || []));

			setUserState({
				email,
				emails,
			});
			enqueueSnackbar(`${email} was set to your primary email`, { variant: "success" });
		} catch (error) {
			enqueueSnackbar(getErrMsg(error, "Something went wrong, please try again"), { variant: "error" });
		}
	};

	const removeAddEmailForm = (i, verifiedEmail) => {
		const emailData = alternateEmailFormIndex;
		emailData.splice(i, 1);
		setAlternateEmailFormIndex([...emailData]);

		let emails = getObjectValue(account, "emails", []);
		const index = emails.findIndex((item) => item.email === verifiedEmail);
		if (index > -1) {
			emails[index] = {
				...emails[index],
				status: VERIFICATION_CODE_STATUS.VERIFIED,
			};
		} else {
			emails.push({
				email: verifiedEmail,
				status: VERIFICATION_CODE_STATUS.VERIFIED,
			});
		}

		enqueueSnackbar(`Your email ${verifiedEmail}, verified successfully`, { variant: "success" });

		localStorage.setItem(USER_ALTERNATE_EMAILS, JSON.stringify(emails || []));

		setUserState({
			...account,
			emails,
		});
	};

	const handleUpdateProfile = async (event) => {
		try {
			await updateProfile(event);
			setUserState({
				...account,
				...event,
			});
			setEnableEdit(false);
			setAlternateEmailFormIndex([]);
			enqueueSnackbar("Successfully updated profile", { variant: "success" });
		} catch (error) {
			enqueueSnackbar(getErrMsg(error), { variant: "error" });
		}
	};

	const handleVerificationCodeSent = (email) => {
		const emails = getObjectValue(account, "emails", []);
		emails.push({
			email,
			status: VERIFICATION_CODE_STATUS.PENDING,
		});
		localStorage.setItem(USER_ALTERNATE_EMAILS, JSON.stringify(emails || []));
  };

  const logOut = userStore((state) => state.userLogOut);

  const handleDeleteInputChange = (event) => {
    setDeleteInputValue(event.target.value);
  }

  function handleDeleteAccountConfirmation1() {
    return <ActionDialog
      open={openDelete1}
      title="Delete Account"
      onClose={() => setOpenDelete1(false)}
      content={
          <div>
            <Typography variant="h6" color="secondary">
              Are you sure you wish to delete your account?
            </Typography>
            <Typography variant="h6" style={{ color: "red" }}>
              Warning: All your certificates will be deleted!
            </Typography>
          </div>
        }
        primaryAction={
          <Button
            id="confirm-account-delete1-cancel-btn"
            onClick={() => {
              setOpenDelete1(false);
            }}
            color="secondary"
            variant={"contained"}
          >
            Cancel
          </Button>
        }
        secondaryAction={
          <Button
            id="confirm-account-delete1-btn"
            onClick={() => {
              setOpenDelete1(false);
              setOpenDelete2(true);
            }}
            color="white"
          >
            Delete
          </Button>
        }
      maxWidth="sm"
    />
  }

  function handleDeleteAccountConfirmation2() {
    return <ActionDialog
      open={openDelete2}
      title="Delete Account Confirmation"
      onClose={() => setOpenDelete2(false)}
      content={
        <div >
          <Typography variant="h6" style={{ color: "red", lineHeight: "64px" }}>
            Warning: This action is irreversible. Your account and data we be unrecoverable!
          </Typography>
          <Typography variant="h6" style={{ color: "red" }}>
            Your credentials will not be verifiable on our site!
          </Typography >
          <Typography variant="h6" color="secondary" style={{ lineHeight: "84px" }}>
            Are you absolutely sure you wish to delete your account and all your data?
          </Typography>
          <Typography variant="h6" color="secondary">
            To confirm that you want all your data destroyed, please type "DELETE" in the box below.
          </Typography>
          <AdvTextField variant="outlined" size="small" fullWidth inputRef={register({ required: "Please type DELETE to confirm" })} name="delete" errors={errors} onChange={handleDeleteInputChange} />
        </div>
      }
      primaryAction={
        <Button
          id="confirm-account-delete2-cancel-btn"
          onClick={() => {
            setOpenDelete2(false);
          }}
          color="secondary"
          variant={"contained"}
        >
          Cancel
        </Button>
      }
      secondaryAction={
        <Button
          id="confirm-account-delete2-btn"
          onClick={(event) => {
            const deleteStr = deleteInputValue;
            console.log( deleteStr );
            if (deleteStr === "DELETE") {
              handleDeleteAccount();
            }
            else {
              enqueueSnackbar("Please type DELETE to confirm or cancel", { variant: "error" });
             }
          }}
          color="white"
        >
          Delete
        </Button>
      }
      maxWidth="md"
    />
  }

  const handleDeleteAccount = async (event) => {
    try {
      await deleteAccount(event);
      setEnableEdit(false);
      setAlternateEmailFormIndex([]);
      enqueueSnackbar("Successfully deleted account", { variant: "success" });
      setTimeout(() => {
        logOut();
      }, 1500); 
    } catch (error) {
      enqueueSnackbar(getErrMsg(error), { variant: "error" });
    }
    setOpenDelete2(false);
  };

	return (
		<div className={classes.root}>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				<HeaderWithLine idName="profile-headline" title={"Profile"} variant={"h4"} />
				<br />

				<SpaceBetweenContainer>
					<Typography variant="h5">Personal Details</Typography>
					{!enableEdit && <Button buttonType="icon" icon="edit" invert color="secondary" iconSize={"1.2rem"} onClick={handleOnEditClick} />}
				</SpaceBetweenContainer>

				<Card elevation={1} style={{ padding: "1rem", marginTop: "0.5rem" }}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={4} md={2} container alignItems="center">
							<Typography>First Name</Typography>
						</Grid>
						<Grid item xs={12} sm={8} md={10}>
							{enableEdit ? <AdvTextField variant="outlined" size="small" fullWidth inputRef={register({ required: "First name is required" })} name="firstName" errors={errors} /> : <Typography>{getObjectValue(account, "firstName")}</Typography>}
						</Grid>
						<Grid item xs={12} sm={4} md={2} container alignItems="center">
							<Typography>Last Name</Typography>
						</Grid>
						<Grid item xs={12} sm={8} md={10}>
							{enableEdit ? <AdvTextField variant="outlined" size="small" fullWidth inputRef={register({ required: "Last name is required" })} name="lastName" errors={errors} /> : <Typography>{getObjectValue(account, "lastName")}</Typography>}
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Typography>Account Type</Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={10}>
              <Typography>{account.roles.join(", ")}</Typography>
            </Grid>
						<Grid item xs={12} sm={4} md={2}>
							<Typography>Email</Typography>
						</Grid>
						<Grid item xs={12} sm={8} md={10}>
							<Typography {...(enableEdit ? { color: "textSecondary" } : {})}>{getObjectValue(account, "email")}</Typography>
						</Grid>
						{getObjectValue(account, "emails", []).filter((item) => item.status === VERIFICATION_CODE_STATUS.VERIFIED).length > 0 ? (
							<Grid item xs={12} sm={4} md={2}>
								<Typography>Alternate Emails</Typography>
							</Grid>
						) : null}
						<Grid item xs={12} sm={8} md={10} container spacing={1}>
							{getObjectValue(account, "emails", [])
								.filter((item) => item.status === VERIFICATION_CODE_STATUS.VERIFIED)
								.map((email, index) => (
									<Grid item xs={12} key={email?.email + index}>
										{enableEdit ? <AlternateEmailItemEdit onDelete={() => setEmailDelete(true)} onMakeDefault={() => setPrimaryEmailData(email)} data={email} /> : <Typography>{email?.email}</Typography>}
									</Grid>
								))}
						</Grid>
						{alternateEmailFormIndex.map((item, index) => (
							<Grid item xs={12} key={item.email + index}>
								<AlternateEmailForm
									addAlternateEmail={addAlternateEmail}
									resendVerificationCode={resendVerificationCode}
									verifyEmail={verifyEmail}
									defaultValue={item}
									onClose={(email) => removeAddEmailForm(index, email)}
									verificationCodeSent={handleVerificationCodeSent}
								/>
							</Grid>
						))}
						{enableEdit && (
							<Grid item xs={12} alignContent="center" container justifyContent="center">
								<Button leftIcon="plus" invert color="secondary" noBorder onClick={() => handleAddAnotherEmail()}>
									Add another email address
								</Button>
							</Grid>
						)}
					</Grid>
          {enableEdit && ALLOW_USER_DELETION && (
            <ContainerFlexEnd>
              {handleDeleteAccountConfirmation1()}              
              {handleDeleteAccountConfirmation2()}
              <Button color="secondary" onClick={() => {
                setOpenDelete1(true);
              }} style={{ marginLeft: "auto" }}>
                Delete
              </Button>
              <div style={{ margin: "1.25rem" }} />
							<Button color="secondary" invert onClick={handleSubmit(handleUpdateProfile)} disabled={!isDirty}>
								Update
							</Button>
							<div style={{ margin: "0.25rem" }} />
							<Button color="danger" onClick={handleOnEditCancel}>
								Cancel
							</Button>
						</ContainerFlexEnd>
          )}
          {enableEdit && !ALLOW_USER_DELETION && (
            <ContainerFlexEnd>
              <Button color="secondary" invert onClick={handleSubmit(handleUpdateProfile)} disabled={!isDirty}>
                Update
              </Button>
              <div style={{ margin: "0.25rem" }} />
              <Button color="danger" onClick={handleOnEditCancel}>
                Cancel
              </Button>
            </ContainerFlexEnd>
          )}
				</Card>

				<CapabilityBoundary
					render={() => (
						<>
							<br />
							<Typography variant="h5">User Options</Typography>
							<Card elevation={1} style={{ padding: "1rem", marginTop: "0.5rem" }}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6}>
										<Typography>Reset Password</Typography>
									</Grid>
									<Grid item xs={12} sm={6} container justifyContent="flex-end">
										<ResetPassword email={account.email} />
									</Grid>
								</Grid>
							</Card>
						</>
					)}
					capability={CAPABILITIES.VIEW_PROFILE_USER_OPTIONS}
					hide
				/>

				<CapabilityBoundary
					render={() => (
						<>
							<br />
							<Typography variant="h5">Organization Details</Typography>
							<Card elevation={1} style={{ padding: "1rem", marginTop: "0.5rem" }}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={4} md={3}>
										<Typography>Organization Name</Typography>
									</Grid>
									<Grid item xs={12} sm={8} md={9}>
										<Typography>{getObjectValue(account, "organizations.default.name", "")}</Typography>
									</Grid>
									<Grid item xs={12} sm={4} md={3}>
										<Typography>Admin Email</Typography>
									</Grid>
									<Grid item xs={12} sm={8} md={9}>
                    <Typography>{orgEmail}</Typography>
									</Grid>
									<Grid item xs={12} sm={4} md={3}>
										<Typography>Credentials Created</Typography>
									</Grid>
									<Grid item xs={12} sm={8} md={9}>
										<Typography>{numCreds ? numCreds : 0}</Typography>
									</Grid>
									<Grid item xs={12} sm={4} md={3}>
										<Typography>Credentials Issued</Typography>
									</Grid>
									<Grid item xs={12} sm={8} md={9}>
										<Typography>
											{numCerts ? numCerts : 0} {getObjectValue(account, "credentialLimit") && `/ ${parseInt(getObjectValue(account, "credentialLimit")).toLocaleString()}`}
										</Typography>
									</Grid>
									<Grid item xs={12} sm={4} md={3}>
										<Typography>Credentials Revoked</Typography>
									</Grid>
									<Grid item xs={12} sm={8} md={9}>
										<Typography>{numRevoked}</Typography>
									</Grid>
								</Grid>
							</Card>
						</>
					)}
					capability={CAPABILITIES.VIEW_PROFILE_BASIC_INFO}
					hide
				/>

				{/* commenting for now as this can be reuse, we can remove the commented code later */}
				{/* <CapabilityBoundary
					render={() => (
						<>
							<div className={classes.toolbar} />
							<HeaderWithLine idName="contact-list-title" title={`Contact List`} variant={"h5"} />
						</>
					)}
					capability={CAPABILITIES.VIEW_PROFILE_CONTACT_LIST}
					hide
				/> */}
			</main>
			{emailDelete && (
				<Dialog open={true} maxWidth="md" fullWidth>
					<DialogTitle>
						<SpaceBetweenContainer>
							<span>Delete Email Address</span>
							<Button buttonType="icon" icon="cancel" invert onClick={() => setEmailDelete(false)} />
						</SpaceBetweenContainer>
					</DialogTitle>
					<DialogContent>
						<Typography variant="h6" color="secondary">
							Transfer credentials?
						</Typography>
						<div style={{ margin: "0.5rem" }} />
						<Typography>We noticed that you have credentials associated with this email address.</Typography>
						<div style={{ margin: "0.25rem" }} />
						<Typography>Would you like to transfer ownership of all credentials associated with this email address to an alternative email address?</Typography>
					</DialogContent>
					<DialogActions>
						<Button color="secondary" invert>
							No, delete this email address and all associated credentials.
						</Button>
						<Button color="secondary">Yes, please transfer all credentials to my alertnate email address.</Button>
					</DialogActions>
				</Dialog>
			)}

			{Boolean(primaryEmailData) && (
				<Dialog open>
					<DialogTitle>Confirm Primary Email Address</DialogTitle>
					<DialogContent>
						<Typography variant="h6" color="secondary">
							Update primary email address?
						</Typography>
						<div style={{ margin: "0.5rem" }} />
						<Typography>By changing your primary email address you will only be able to login and reset your password using this email address.</Typography>
						<div style={{ margin: "0.25rem" }} />
						<Typography>Please ensure this email address is secure and solely in your control.</Typography>
					</DialogContent>
					<DialogActions>
						<Button color="secondary" invert onClick={() => setPrimaryEmailData(null)}>
							Cancel
						</Button>
						<Button color="secondary" onClick={handleMakeEmailPrimary}>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
}

function AlternateEmailForm({ addAlternateEmail = () => { }, resendVerificationCode = () => { }, verifyEmail = () => { }, onClose = () => { }, defaultValue = {}, verificationCodeSent = () => { } }) {
	const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(defaultValue.email ? true : false);
	const [disableBtn, setDisableBtn] = useState(false);
	const [emailExistsError, setEmailExistsError] = useState("");

	const { control, register, handleSubmit, errors, getValues } = useForm({
		defaultValues: { email: defaultValue.email, code: defaultValue.code },
	});

	const statusStr = {
		[VERIFICATION_CODE_STATUS.PENDING]: "Pending",
	};

	const { enqueueSnackbar } = useSnackbar();

	const handleVerifyBtn = async (event) => {
		try {
			setDisableBtn(true);
			setEmailExistsError("");
			if (!isVerificationCodeSent) {
				const { email } = event;
				try {
					const res = await addAlternateEmail(email);
					setIsVerificationCodeSent(true);
					verificationCodeSent(email);
					// enqueueSnackbar(`Verification code sent to ${email}`, { variant: "success" });
				} catch (error) {
					setEmailExistsError(error?.message || "Something went wrong");
				}
			} else {
				const { email, code } = getValues(["email", "code"]);
				if (!email || !code) throw new Error("Please enter valid email and verification code");
				const res = await verifyEmail(email, code);
				setIsVerificationCodeSent(false);
				onClose(email);
			}
		} catch (error) {
			console.error(error);
			enqueueSnackbar(getErrMsg(error, "Something went wrong, please try again"), { variant: "error" });
		} finally {
			setDisableBtn(false);
		}
	};

	const handleResendCode = async () => {
		try {
			setDisableBtn(true);
			const { email } = getValues(["email"]);
			if (!email) throw new Error("Email is required");
			const res = await resendVerificationCode(email);
			enqueueSnackbar("Verification code sent successfully", { variant: "success" });
		} catch (error) {
			console.error(error);
			enqueueSnackbar(getErrMsg(error, "Something went wrong, please try again"), { variant: "error" });
		} finally {
			setDisableBtn(false);
		}
	};

	return (
		<Container maxWidth="md">
			<Card elevation={1} style={{ padding: "1rem" }}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4} md={2} container alignItems="center">
						<Typography style={{ textTransform: "capitalize" }}>{getObjectValue(statusStr, defaultValue.status, defaultValue.status)}</Typography>
					</Grid>
					<Grid item xs={12} sm={8} md={10}>
						<AdvTextField
							size="small"
							name="email"
							label="Email"
							placeholder="Enter valid email address"
							inputRef={register({
								required: "Email is required",
								validate: (value) => (value ? (isValidEmail(value) ? true : "Enter a valid email") : true),
							})}
							disabled={isVerificationCodeSent}
							errors={errors}
							required
							{...(emailExistsError ? { helperText: emailExistsError, error: true } : {})}
						/>
						{isVerificationCodeSent && (
							<>
								<div style={{ margin: "1rem" }} />
								<AdvTextField
									size="small"
									name="code"
									label="Code"
									placeholder="Please enter the verification code sent to this email"
									inputRef={register({
										required: "Verification code is required",
									})}
									errors={errors}
									required
								/>
							</>
						)}
						<ContainerFlexEnd style={{ marginTop: "0.5rem" }}>
							{isVerificationCodeSent ? (
								<>
									<Button color="secondary" invert onClick={handleResendCode} disabled={disableBtn}>
										Resend Code
									</Button>
									<div style={{ margin: "0.25rem" }} />
								</>
							) : null}
							<Button color="secondary" onClick={handleSubmit(handleVerifyBtn)} disabled={disableBtn}>
								{isVerificationCodeSent ? "Verify" : "Send Verification Code"}
							</Button>
						</ContainerFlexEnd>
					</Grid>
				</Grid>
			</Card>
		</Container>
	);
}

function AlternateEmailItemEdit({ onDelete = () => { }, onMakeDefault = () => { }, data = {} }) {
	return (
		<Card elevation={1} style={{ padding: "0.5rem" }}>
			<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
				<Typography>{data?.email}</Typography>
				<div style={{ display: "flex", alignItems: "center" }}>
					<Button color="secondary" invert popover="Make default. This will become the default email address you will use to login to your wallet." onClick={onMakeDefault}>
						Make Default
					</Button>
					{/* <div style={{ margin: "0.25rem" }} /> */}
					{/* <Button buttonType="icon" icon="cancel" iconSize="0.7rem" color="primary" onClick={onDelete} /> */}
				</div>
			</div>
		</Card>
	);
}

function isValidEmail(string) {
	const res = string.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
	return res !== null;
}
