import { useState, useEffect } from "react";
import { PathChart } from "@cti-workspace/ui";
import * as d3 from "d3";

export const CreatePathway = () => {
	const [data, setData] = useState(null);
	let addNodeChildFunc = null;

	function addNode() {
		const node = {
			nodeId: "new Node",
			parentNodeId: "O-6066",
		};

		addNodeChildFunc(node);
	}

	function onNodeClick(nodeId) {
		alert("clicked " + nodeId);
	}

	useEffect(() => {
		d3.csv("https://raw.githubusercontent.com/bumbeishvili/sample-data/main/org.csv").then((data) => {
			console.log(typeof data);
		});
		let arr = [
			{
				name: "Ian Devling",
				id: "O-6066",
				parentId: "",
			},
			{
				name: "Davolio Nancy",
				id: "O-6067",
				parentId: "O-6066",
			},
		];
		setData(arr);
	}, []);

	return (
		<div>
			Click node to trigger action in parent or &nbsp;
			<button onClick={() => addNode()}>add node as root's child</button>
			<PathChart setClick={(click) => (addNodeChildFunc = click)} onNodeClick={onNodeClick} data={data} />
		</div>
	);
};

// nodeContent => card
// buttonContent => purple dot, where you add a child node
