import { useNavigate, useLocation } from "react-router-dom";
import CAPABILITIES from "../../capabilities";
import { CredentialBody } from "@cti-workspace/ui";
import { getObjectValue } from "@cti-workspace/helpers";
import { isCapable } from "../../routes/admin/userAccounts/permissionUtils";

const CardButtonGroup = ({ getCredentialEditOptions, definition, capabilities }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const onIconButtonClick = (index) => {
		console.time("answer time");
		const { [index]: options } = getCredentialEditOptions();
		options.handler(definition);
		console.timeLog("answer time");
	};

	const isEditable = getObjectValue(definition, "readOnly", false) === false;

	const showView = isCapable(capabilities, CAPABILITIES.VIEW_CREDENTIAL_DEFINITIONS);
	const showEdit = isCapable(capabilities, CAPABILITIES.EDIT_CREDENTIAL_DEFINITION) && isEditable;
	const showCopy = isCapable(capabilities, CAPABILITIES.COPY_CREDENTIAL_DEFINITION) && isEditable;
	const showDelete = isCapable(capabilities, CAPABILITIES.DELETE_CREDENTIAL_DEFINITION);

	return (
		<CredentialBody
			id={definition._id}
			showView={showView}
			onView={() => {
				navigate({
					pathname: `/credentialDefinition/${definition._id}/view`,
					state: { background: location },
				});
			}}
			showEdit={showEdit}
			onEdit={() => onIconButtonClick(1)}
			showCopy={showCopy}
			onCopy={() => onIconButtonClick(0)}
			showDelete={showDelete}
			onDelete={() => onIconButtonClick(2)}
		/>
	);
};

export default CardButtonGroup;
