import { useEffect, useState } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AdvFormControl from "../../../components/AdvFormControl";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import AdminFormFieldSeparator from "../../../components/EmailTemplates/AdminFormFieldSeparator";
import { getRoles, getCapabilities } from "./permissionUtils";
import { useSnackbar } from "notistack";
import Grid from "@material-ui/core/Grid";

import "./userAccounts.css";
import { getObjectValue } from "@cti-workspace/helpers";

let firstHalf = [];
let secondHalf = [];

const CapabilityCheckBox = ({ capability, selectedCapabilities, onCapabilityChange }) => (
	<div>
		<FormControlLabel
			key={capability}
			className="user-roles-checkbox-label"
			control={<Checkbox checked={getObjectValue(selectedCapabilities, `${capability}.checked`) === true} onChange={onCapabilityChange} name={capability} />}
			label={capability.replaceAll("_", " ").toLowerCase()}
		/>
	</div>
);

const UserRoles = ({ selectedCapabilities, setSelectedCapabilities, capabilitiesValidationFailed, selectedRole, setSelectedRole }) => {
	const [roles, setRoles] = useState([]);
	const [capabilities, setCapabilities] = useState([]);

	const { enqueueSnackbar } = useSnackbar();

	const onRoleChange = async (e) => {
		const changedRole = e.target.value;
		const roleCapabilities = roles
			.find((role) => role.name === changedRole)
			.capabilities.filter((capability) => capability !== null)
			.reduce((a, v) => {
				const { [v]: capabilitiesV } = capabilities;
				return {
					...a,
					[v]: {
						checked: true,
						...(capabilitiesV.allowedCredDefs && {
							allowedCredDefs: {},
						}),
					},
				};
			}, {});
		setSelectedRole(changedRole);
		setSelectedCapabilities(roleCapabilities);
	};

	const onCapabilityChange = (event) => {
		const { name, checked } = event.target;
		const { [name]: selectedCapabilitiesName } = selectedCapabilities;
		setSelectedCapabilities({
			...selectedCapabilities,
			[name]: {
				...selectedCapabilitiesName,
				checked,
			},
		});
	};

	useEffect(() => {
		Promise.all([getRoles(), getCapabilities()])
			.then(([{ roles }, { capabilities }]) => {
				setRoles(roles);
				setCapabilities(capabilities);
				const capabilityKeys = Object.keys(capabilities);
				const middleIndex = Math.ceil(capabilityKeys.length / 2);
				firstHalf = capabilityKeys.splice(0, middleIndex);
				secondHalf = capabilityKeys.splice(-middleIndex);
			})
			.catch(() => {
				enqueueSnackbar("Failed to retrieve roles and capabilities. Please try again.", {
					variant: "error",
				});
			});
	}, []);

	return (
		<>
			<AdvFormControl name="Role">
				<Select id="organizations-simple-select" fullWidth value={selectedRole} onChange={onRoleChange} error={capabilitiesValidationFailed} placeholder="User Role">
					{roles.map((role) => (
						<MenuItem key={role.name} value={role.name}>
							{role.name}
						</MenuItem>
					))}
				</Select>
			</AdvFormControl>
			<AdminFormFieldSeparator />
			<FormControl sx={{ m: 3 }} component="fieldset" variant="standard" error={capabilitiesValidationFailed} className="checkbox-container">
				{selectedRole && (
					<FormGroup>
						<Grid container>
							<Grid item xs={6}>
								{firstHalf.map((capability, index) => (
									<CapabilityCheckBox key={`capability_Z${index}`} onCapabilityChange={onCapabilityChange} capability={capability} selectedCapabilities={selectedCapabilities} />
								))}
							</Grid>
							<Grid item xs={6}>
								{secondHalf.map((capability, index) => (
									<CapabilityCheckBox key={`capability_Z${index}`} onCapabilityChange={onCapabilityChange} capability={capability} selectedCapabilities={selectedCapabilities} />
								))}
							</Grid>
						</Grid>
					</FormGroup>
				)}
			</FormControl>
			{capabilitiesValidationFailed && <p className="MuiFormHelperText-root Mui-error">A user must have a role and at least one capability selected.</p>}
		</>
	);
};

export default UserRoles;
