import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import certificate_background from "../../assets/images/certificate_background.png";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: "10vh",
		"& > *": {
			margin: theme.spacing(1),
			width: "90%",
		},
	},
}));

export default function BasicTextFields() {
	const classes = useStyles();
	const initial = JSON.parse(sessionStorage.getItem("config"));
	// console.log(initial);
	const [content, setcontent] = useState(initial?.content || "");
	const [qr, setQR] = useState(initial?.qr || "");
	const [parameters, setParameters] = useState(initial?.parameters || "");
	const [style, setStyle] = useState(
		initial?.style ||
			JSON.stringify({
				width: "92%",
				paddingTop: "100%",
				borderColor: "red",
				borderWidth: "1",
				position: "relative",
				backgroundImage: `url(${certificate_background})`,
				backgroundSize: "100%",
				backgroundPosition: "top",
				backgroundRepeat: "no-repeat",
				margin: "auto",
				marginTop: "2vw",
			})
	);
	return (
		<form
			className={classes.root}
			onSubmit={() => {
				sessionStorage.setItem("config", JSON.stringify({ content, style, qr, parameters }));
			}}
		>
			<TextField id="style" label="Style" variant="outlined" value={style} onChange={(e) => setStyle(e.target.value)} />
			<TextField id="qrLocation" label="QR Location" variant="outlined" value={qr} onChange={(e) => setQR(e.target.value)} />
			<TextField id="parameters" multiline label="Cert Parameters" variant="outlined" value={parameters} onChange={(e) => setParameters(e.target.value)} />
			<TextField id="draft" multiline label="Draft" variant="outlined" value={content} onChange={(e) => setcontent(e.target.value)} />
			<Button type="submit">Save</Button>
			<TextareaAutosize aria-label="minimum height" rowsMin={3} value={sessionStorage.getItem("config")} />
		</form>
	);
}
