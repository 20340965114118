import { useEffect, useState } from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import format from "date-fns/format";
import AnalyticCard from "./analyticCard";
import ExpandAnalytics from "./expandAnalytics";
import { ISSUED, getObjectValue } from "@cti-workspace/helpers";
import useAnalyticsHook from "../../hooks/useAnalyticsHook";
import CapabilityBoundary from "../../components/CapabilityBoundary/CapabilityBoundary";
import CAPABILITIES from "../../capabilities";
import { Table, Box } from "@cti-workspace/ui";

const MainAnalytics = ({ setExploreAnalytics, startDate, endDate, setStartDate, setEndDate }) => {
	const [initialData, setInitialData] = useState({});
	const [credCountData, setCredCountData] = useState([]);

	const { analytics, getAnalytics, loading } = useAnalyticsHook();

	useEffect(() => {
		if (startDate && endDate) {
			getAnalyticsFromDates();
		} else {
			getAnalytics();
		}
	}, []);

	useEffect(() => {
		if (getObjectValue(analytics, "analyticsData", []).length) {
			setCredCountData(analytics.analyticsData.sort((a, b) => (a._id.toLowerCase() > b._id.toLowerCase() ? 1 : b._id.toLowerCase() > a._id.toLowerCase() ? -1 : 0)));
			const groupedData = analytics.analyticsData.reduce((acc, item) => {
				return {
					...acc,
					addLinkedIn: (acc.addLinkedIn || 0) + item.addLinkedIn,
					postLinkedIn: (acc.postLinkedIn || 0) + item.postLinkedIn,
					postTwitter: (acc.postTwitter || 0) + item.postTwitter,
					issued: (acc.issued || 0) + item.issued,
					verifier: (acc.verifier || 0) + item.verifier,
					wallet: (acc.wallet || 0) + item.wallet,
				};
			}, {});
			setInitialData(groupedData);
		} else {
			setInitialData({});
			setCredCountData([]);
		}
	}, [analytics]);

	const getAnalyticsFromDates = async () => {
		try {
			if (startDate && endDate) {
				await getAnalytics(startDate, endDate);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const onResetButtonClick = () => {
		setStartDate("");
		setEndDate("");
		getAnalytics();
	};

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Box display="flex" alignItems="center" flexWrap="wrap" fontStyle="italic">
					<InfoIcon color="action" fontSize="small" />
					<Typography color="textSecondary">This information will not update in real time. Please refresh the page to load the latest information.</Typography>
				</Box>
				<CapabilityBoundary
					render={() => (
						<Box display="flex" alignItems="center">
							<TextField
								type="date"
								inputProps={{
									// ...(startDate ? { min: startDate } : {}),
									...(endDate ? { max: endDate } : { max: format(new Date(), "yyyy-MM-dd") }),
								}}
								variant="outlined"
								size="small"
								label="Choose From Date"
								onChange={(event) => setStartDate(event.target.value)}
								InputLabelProps={{ shrink: true }}
								value={startDate}
							/>
							<TextField
								type="date"
								inputProps={{
									...(startDate ? { min: startDate } : {}),
									max: format(new Date(), "yyyy-MM-dd"),
								}}
								variant="outlined"
								size="small"
								label="Choose To Date"
								onChange={(event) => setEndDate(event.target.value)}
								InputLabelProps={{ shrink: true }}
								style={{ marginLeft: "1rem", marginRight: "1rem" }}
								value={endDate}
							/>
							<Button size="small" onClick={() => getAnalyticsFromDates()} color="primary" disabled={!startDate || !endDate}>
								Get Data
							</Button>
							<Button size="small" color="secondary" onClick={onResetButtonClick} disabled={!startDate && !endDate}>
								Reset
							</Button>
						</Box>
					)}
					capability={CAPABILITIES.ANALYTICS_FILTER_BY_DATE_RANGE}
					hide
				/>
			</Box>
			<Grid container spacing={3} style={{ marginTop: "1rem" }}>
				<Grid item xs={12} sm={3} md={2}>
					<AnalyticCard count={getObjectValue(initialData, "issued")} title={ISSUED}>
						<ExpandAnalytics id={ISSUED} onClickExplore={() => setExploreAnalytics(ISSUED)} />
					</AnalyticCard>
				</Grid>
				<Grid item xs={12} sm={3} md={2}>
					<AnalyticCard count={getObjectValue(initialData, "verifier")} title="Opened" />
				</Grid>
				<Grid item xs={12} sm={3} md={2}>
					<AnalyticCard count={getObjectValue(initialData, "wallet")} title="Wallet Created" />
				</Grid>
				<Grid item xs={12} sm={3} md={2}>
					<AnalyticCard count={getObjectValue(initialData, "addLinkedIn")} title="Added to LinkedIn" />
				</Grid>
				<Grid item xs={12} sm={3} md={2}>
					<AnalyticCard count={getObjectValue(initialData, "postLinkedIn")} title="Posted on LinkedIn" />
				</Grid>
				<Grid item xs={12} sm={3} md={2}>
					<AnalyticCard count={getObjectValue(initialData, "postTwitter")} title="Posted on X" />
				</Grid>
			</Grid>
			<Box elevation={1} marginTop={2} style={{ height: "100%" }}>
				<Table
					config={{
						headers: [
							{
								label: "Credential Name",
								prop: "_id",
								center: false,
							},
							{ label: "Issued", prop: "issued", align: "center" },
							{ label: "Opened", prop: "verifier", align: "center" },
							{ label: "Web Wallet Created", prop: "wallet", align: "center" },
							{ label: "Added to LinkedIn", prop: "addLinkedIn", align: "center" },
							{ label: "Posted on LinkedIn", prop: "postLinkedIn", align: "center" },
							{ label: "Posted on X", prop: "postTwitter", align: "center" },
							{ label: "Added to Mobile Wallet", prop: "addedToMobileWallet", align: "center" },
							{ label: "Email Opened", prop: "emailOpened", align: "center" },
						],
					}}
					data={credCountData}
					dense
					showSearch={false}
					loading={loading}
					loaderCenter
				/>
			</Box>
		</>
	);
};

export default MainAnalytics;
