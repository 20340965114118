import { useState } from "react";
import HeaderWithLIne from "../../components/HeaderWithLine";
import MainAnalytics from "./mainAnalytics";
import ExploreAnalytics from "./exploreAnalytics";
import { Container } from '@cti-workspace/ui';

export default function Analytics() {
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const [exploreAnalytics, setExploreAnalytics] = useState(null);

	return (
		<Container maxWidth="lg">
			<div style={{ marginTop: "70px" }} />
			<HeaderWithLIne title="Analytics" variant="h4" />
			{!exploreAnalytics ? (
				<MainAnalytics setExploreAnalytics={setExploreAnalytics} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
			) : (
				<ExploreAnalytics id={exploreAnalytics} onClickGoBack={() => setExploreAnalytics(null)} startDate={startDate} endDate={endDate} />
			)}
		</Container>
	);
}
