import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import logo from "../../assets/images/iPWRU_Logo_Med.png";
import questLogo from "../../assets/images/LatestQuest.png";
import altura from "../../assets/images/altura.png";
import acn from "../../assets/images/acn.png";
import tfs from "../../assets/images/tfs_logo.png";
import ForgotPassword from "./forgot.password";
import { FormattedMessage } from "react-intl";
import PasswordField from "../../components/PasswordField";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Button, Box } from "@cti-workspace/ui";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import { useSnackbar } from "notistack";
import { GOOGLE_CLIENT_ID } from "@cti-workspace/helpers";
import useUserHook from "../../hooks/useUserHook";
import styled from "styled-components";

function LoginComponent({ classes, handleChange, onSubmit, values, onGoogleSubmit, handleClickShowPassword, handleMouseDownPassword, whiteLabelID, orgs = [], selectedOrg = () => {} }) {
	const { enqueueSnackbar } = useSnackbar();
	const { userLogIn } = useUserHook();
	const [ready, setReady] = useState(false);

	const GoogleWrap = styled.div`
		width: 100%;

		button {
			padding: 0 1rem !important;
		}
	`;

	const Seperator = styled.span`
		display: block;
		position: relative;
		margin: 1rem 0;

		&:after {
			content: " ";
			background-color: #ccc;
			height: 1px;
			width: 45%;
			position: absolute;
			top: 50%;
			right: 3px;
		}

		&:before {
			content: " ";
			background-color: #ccc;
			height: 1px;
			width: 45%;
			position: absolute;
			top: 50%;
			left: 3px;
		}
	`;

	/*
    Start Handle Google Oauth Flow Login
  **/
	const handleFailure = (error) => {
		console.error(error);
		enqueueSnackbar("An Error Occurred, Please try again Later.", {
			variant: "error",
		});
	};

	useEffect(() => {
		function start() {
			gapi.client
				.init({
					clientId: GOOGLE_CLIENT_ID,
					scope: "",
				})
				.then(setReady(true));
		}
		if (GOOGLE_CLIENT_ID) gapi.load("client:auth2", start);
	}, []);

	/*
    End Handle Google Oauth Flow Login
  **/

	return (
		<div className={classes.center}>
			<Grid container spacing={2}>
				<Box mx="auto" borderColor="primary" border={8} p={1} width="100%">
					{whiteLabelID == "acn" ? (
						<img className={classes.logo} src={acn} alt="acn.edu.au" />
					) : whiteLabelID == "iqcert" ? (
						<img className={classes.questLogo} src={questLogo} alt="iPWRU Logo" />
					) : whiteLabelID == "alturalearning" ? (
						<img className={classes.logo} src={altura} alt="certificates.alturalearning.com" />
					) : window.location.host.includes("tfs.convergence.tech") || window.location.host.includes("tfs.ca") ? (
						<img className={classes.logo} src={tfs} alt="tfs.convergence.tech" />
					) : (
						<img className={classes.logo} src={logo} alt="iPWRU Logo" />
					)}

					<hr style={{ width: "50%" }} />
					{/* <NewButton/> */}
					<Box mx="auto" p={1}>
						{orgs.length <= 0 ? (
							<>
								<TextField required fullWidth={true} value={values.email} id="email" label="Email" placeholder={"your.name@gmail.com"} onChange={handleChange("email")} variant="outlined" />
                <PasswordField id={"outlined-adornment-password"} handleChange={handleChange("password")} label={"Password"} onKeyDown={(e) => {
                  if (e.key === "Enter" || e.keyCode === 13) {
                    onSubmit();
                  }
                }} required />
								<Box p={1} display="flex" flexDirection="row-reverse">
									<ForgotPassword whiteLabelID={whiteLabelID} />
								</Box>
							</>
						) : (
							<FormControl fullWidth variant="outlined" style={{ marginBottom: "1rem" }}>
								<InputLabel>Select Organization</InputLabel>
								<Select label="Select Organization" onChange={(event) => selectedOrg(event.target.value)}>
									{orgs.map((org, i) => (
										<MenuItem key={i} value={org.value}>
											{org.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
						{/* <Button variant="contained" color="primary" id="login" type="submit" fullWidth={true} onClick={onSubmit}>
							{orgs.length <= 0 ? "Login" : "Continue"}
						</Button> */}

						{whiteLabelID == "acn" ? (
							<Button buttonStyle={{ fontSize: "1rem", backgroundColor: "#004E78", borderColor: "#004E78" }} id="login" type="submit" onClick={onSubmit}>
								{orgs.length <= 0 ? "Login" : "Continue"}
							</Button>
						) : (
							<Button buttonStyle={{ fontSize: "1rem" }} id="login" color="warning" type="submit" onClick={onSubmit}>
								{orgs.length <= 0 ? "Login" : "Continue"}
							</Button>
						)}

						{(orgs.length <= 0 && window.location.host.includes("tfs.convergence.tech")) ||
							(window.location.host.includes("tfs.ca") && (
								<GoogleWrap>
									<Seperator>or</Seperator>
									{ready && <GoogleLogin clientId={GOOGLE_CLIENT_ID} buttonText="Login with Google" onSuccess={onGoogleSubmit} onFailure={handleFailure} cookiePolicy="single_host_origin"></GoogleLogin>}
								</GoogleWrap>
							))}
					</Box>

					{/* <img className={classes.logo} src={logo} alt="Logo" /> */}
				</Box>
			</Grid>

			<br />
			<hr />
			{/*<p style={{ fontSize: "1.2em", fontWeight: "bold" }}>
				<FormattedMessage id="login.route.warningMessage"></FormattedMessage>
			</p>*/}
			<p>
				<i>{whiteLabelID == "acn" ? <FormattedMessage id="acn.login.route.text" /> : <FormattedMessage id="login.route.text" />}</i>
			</p>
		</div>
	);
}

LoginComponent.propTypes = {
	classes: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default LoginComponent;
