import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import { SetPassword, ForgotPassword } from "@cti-workspace/helpers";
import ipwruLogo from "../../assets/images/iPWRU_Logo_Med.png";
import { useSnackbar } from "notistack";
import PasswordField from "../../components/PasswordField";
import questLogo from "../../assets/images/LatestQuest.png";
import { Box } from "@cti-workspace/ui";
import { userStore } from "../../store/userStore";
import acn from "../../assets/images/acn.png";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const SetPasswordComponent = () => {
	let token = useQuery().get("token");
	// console.log(token);
	let navigate = useNavigate();

	let uri = document.documentURI;
	let urlValue = new URL(uri);
	let whiteLabelID = urlValue.hostname.split(".")[1];

	const [password, setPassword] = useState("");
	const [confirm, setConfirm] = useState("");
	const [email, setEmail] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [passwordMatchError, setPasswordMatchError] = useState("");
	const [resendPwdSuccessMsg, setResendPwdSuccessMsg] = useState("");

	const { enqueueSnackbar } = useSnackbar();

	const setIsLoggedIn = userStore((state) => state.userLogIn);

	// TODO: remember to log out user

	// useEffect(() => {
	// 	(async function iife() {
	// 		await setUserLogout();
	// 	})();
	// }, []);

	const handleSubmit = async () => {
		try {
			if (password === confirm && password !== "") {
				let res = await SetPassword(password, token);
				const loginData = await setIsLoggedIn({ email: "", password: "", organization: "" }, res);
				if (res)
					enqueueSnackbar("Set Password Success", {
						variant: "success",
					});
				let { from } = { from: { pathname: "/" } };
				if (loginData) {
					let { dbAccount } = loginData;
					if (dbAccount && dbAccount.roles.length === 1 && dbAccount.roles.includes("holder")) {
						enqueueSnackbar("Login Success", { variant: "success" });
						navigate("/wallet", { replace: true });
					} else {
						if (dbAccount?.organizations?.default) {
							enqueueSnackbar("Login Success", { variant: "success" });
						}
						navigate(from, { replace: true });
					}
				} else {
					enqueueSnackbar("Login failed", { variant: "error" });
				}
			} else {
				setPasswordMatchError("Password did not match");
			}
		} catch (error) {
			console.error(error);
			setErrorMessage(error.message);
		}
	};

	const handleResendPassword = async () => {
		try {
			if (email) {
				let val = await ForgotPassword(email);
				if (val) {
					enqueueSnackbar(`Password Reset Success `, {
						variant: "success",
					});
					setResendPwdSuccessMsg("Reset successful. Check your email");
					setErrorMessage("");
				}
			} else {
				console.error("Email is required");
			}
		} catch (error) {
			console.error(error);
			setErrorMessage(error.message);
		}
	};

	return (
		<Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "100vh" }}>
			<Grid item lg={3}>
				{errorMessage && (
					<Alert severity="error">
						<Typography variant="h6">{errorMessage}</Typography>
					</Alert>
				)}
				{!errorMessage && passwordMatchError && (
					<Alert severity="error">
						<Typography variant="h6">{passwordMatchError}</Typography>
					</Alert>
				)}
				<Box mx="auto" borderColor="primary.main" border={8} p={1}>
					<Box display="flex" flexDirection="column" mx="auto" alignItems="center" justifyContent="center" p={1}>
						<img
							style={{
								paddingTop: 2,
								paddingBottom: 2,
								width: 300,
							}}
							src={whiteLabelID == "acn" ? acn : ipwruLogo}
							alt="iPWRU"
						/>
						<hr style={{ width: "100%" }} />
						{!errorMessage && !resendPwdSuccessMsg ? (
							whiteLabelID == "acn" ? (
								<Typography style={{ color: "#004E78" }} variant="h6">
									Set New Password
								</Typography>
							) : (
								<Typography color="secondary" variant="h6">
									Set New Password
								</Typography>
							)
						) : null}
					</Box>
					{!errorMessage && !resendPwdSuccessMsg ? (
						<Box mx="auto" p={1}>
							<PasswordField
								id="outlined-adornment-setpassword"
								handleChange={(event) => {
									setPassword(event.target.value);
								}}
								label={"New Password"}
								placeholder={"9djsk$827s0sj"}
								required
								labelWidth={105}
							/>
							<PasswordField
								id="outlined-adornment-setpassword"
								handleChange={(event) => {
									setConfirm(event.target.value);
								}}
								label={"Confirm Password"}
								placeholder={"9djsk$827s0sj"}
								required
								labelWidth={130}
							/>
							{whiteLabelID == "acn" ? (
								<Button variant="contained" style={{ backgroundColor: "#004E78", borderColor: "#004E78", color: "white" }} id="setPassword" type="submit" fullWidth={true} onClick={handleSubmit}>
									Set Password
								</Button>
							) : (
								<Button variant="contained" color="primary" id="setPassword" type="submit" fullWidth={true} onClick={handleSubmit}>
									Set Password
								</Button>
							)}
						</Box>
					) : !resendPwdSuccessMsg ? (
						<Box mx="auto" p={1}>
							<TextField required fullWidth={true} id="resend-email" label="Email" placeholder={"your.name@gmail.com"} margin="normal" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
							<Button variant="contained" color="primary" id="resendPassword" type="submit" fullWidth={true} onClick={handleResendPassword}>
								Resend Password
							</Button>
						</Box>
					) : (
						<Box mx="auto" p={1}>
							<Alert severity="success">
								<Typography variant="h6">{resendPwdSuccessMsg}</Typography>
							</Alert>
						</Box>
					)}
					{/* <br /> */}
					{/* <hr /> */}
				</Box>
			</Grid>
		</Grid>
	);
};

export default SetPasswordComponent;
