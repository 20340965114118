import { Component } from "react";
import { AtomicBlockUtils } from "draft-js";

class LinkSource extends Component {
	componentDidMount() {
		const { editorState, entityType, onComplete } = this.props;

		const href = window.prompt("Link URL");
		const val = window.prompt("Text");
		const styleString = window.prompt("Link styleString");
		if (href) {
			const content = editorState.getCurrentContent();
			const contentWithEntity = content.createEntity(entityType.type, "IMMUTABLE", { href, val, styleString });
			const entityKey = contentWithEntity.getLastCreatedEntityKey();
			const nextState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, " ");

			onComplete(nextState);
		} else {
			onComplete(editorState);
		}
	}

	render() {
		return null;
	}
}
export default LinkSource;
