import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useLocation, useNavigate } from "react-router-dom";
import Slide from "@material-ui/core/Slide";
import Grow from "@material-ui/core/Grow";
import { useEffect } from "react";
import { userStore } from "../../store/userStore";

const DeniedAccess = () => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (location.pathname === "/") {
			navigate("/home", { replace: true });
		}
	}, [location, navigate]);

	const logOut = userStore((state) => state.userLogOut);

	return (
		<Grid container component={Box} height={"100vh"} bgcolor={"action.selected"} spacing={1} direction="row" justifyContent="center" alignItems="center" alignContent="center" wrap="nowrap">
			<Grid item>
				<Slide direction="right" in={true} mountOnEnter unmountOnExit>
					<Card variant="elevation">
						<CardContent>
							<Typography variant="subtitle2" color="textSecondary" gutterBottom>
								What went wrong
							</Typography>
							<Grow in={true} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 2000 } : {})}>
								<Typography variant="h4" component="h2" color="secondary">
									Access Denied
								</Typography>
							</Grow>
							<Typography variant="subtitle2" color="textSecondary" gutterBottom>
								permission
							</Typography>
							<Typography variant="body1" component="p">
								if you need access.
								<br />
								<i>{'"contact your admin"'}</i>
							</Typography>
						</CardContent>
						<CardActions justifyContent="center">
							<Button onClick={() => navigate("/home")} color="secondary" size="small">
								Home
							</Button>
							<Button
								onClick={() => {
									logOut();
									navigate("/login");
								}}
								color="secondary"
								size="small">
								Change Account
							</Button>
						</CardActions>
					</Card>
				</Slide>
			</Grid>
		</Grid>
	);
};

export default DeniedAccess;
