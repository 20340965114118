import { useEffect, useState } from "react";
import CreateNewCustomCredential from "./CreateNewCustomCredential";
import AddOrgCustomCredential from "./AddOrgCustomCredential";
import { useSnackbar } from "notistack";
import { getErrMsg, fetchCustomCredentials, getOrganization } from "@cti-workspace/helpers";

const CustomCredentials = () => {
	const { enqueueSnackbar } = useSnackbar();

	const [organizations, setOrganizations] = useState([]);
	const [customCredentials, setCustomCredentials] = useState([]);

	const getCustomCredentials = async () => {
		let data = [];
		try {
			const response = await fetchCustomCredentials();
			data = response.customCredentials;
		} catch (e) {
			enqueueSnackbar(getErrMsg(null, `Failed to retrieve Custom Credentials.`), { variant: "error" });
		} finally {
			setCustomCredentials(data);
		}
	};

	const getOrganizations = async () => {
		try {
			const { items } = await getOrganization();
			setOrganizations(items);
		} catch (e) {
			setOrganizations([]);
			enqueueSnackbar(getErrMsg(null, `Failed to retrieve Organizations.`), { variant: "error" });
		}
	};

	useEffect(() => {
		getOrganizations();
		getCustomCredentials();
	}, []);

	return (
		<>
			<CreateNewCustomCredential getCustomCredentials={getCustomCredentials} />
			<br />
			<AddOrgCustomCredential organizations={organizations} customCredentials={customCredentials} getOrganizations={getOrganizations} />
		</>
	);
};

export default CustomCredentials;
