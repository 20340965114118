import Zoom from "@material-ui/core/Zoom";
import clsx from "clsx";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "fixed",
		bottom: theme.spacing(2),
		left: theme.spacing(2),
	},
}));

const ShowOnScroll = (props) => {
	const classes = useStyles();
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 50,
	});

	const handleClick = () => {
		window.scrollTo(0, 0);
	};

	return (
		<Zoom in={trigger}>
			<Fab label="Up" size="small" color="secondary" onClick={handleClick} className={clsx(classes.root, props.className)}>
				<KeyboardArrowUpIcon />
			</Fab>
		</Zoom>
	);
};

export default ShowOnScroll;
