import { ACCOUNTS_PREFIX } from "../lib/Constants";
import { postApi } from "../lib/Utils";

export default function useProfileHook() {

    const addAlternateEmail = async (email) => {
        try {
            const res = await postApi(`/${ACCOUNTS_PREFIX}/addEmail`, { email, type: "SECONDARY" });
            return res;
        } catch (error) {
            throw error;
        }
    }

    const updatePrimaryEmail = async (email) => {
        try {
            const res = await postApi(`/${ACCOUNTS_PREFIX}/setPrimaryEmail`, { email });
            return res;
        } catch (error) {
            throw error;
        }
    }

    const verifyEmail = async (email, code) => {
        try {
            const res = await postApi(`/${ACCOUNTS_PREFIX}/email/verify`, { email, code, type: "EMAIL_VERIFICATION" });
            return res;
        } catch (error) {
            throw error;
        }
    }

    const resendVerificationCode = async (email) => {
        try {
            const res = await postApi(`/${ACCOUNTS_PREFIX}/resendEmailVerificationEmail`, { email });
            return res;
        } catch (error) {
            throw error;
        }
    }

    const updateProfile = async (data = {}) => {
        try {
            const res = await postApi(`/${ACCOUNTS_PREFIX}/updateProfile`, data);
            return res;
        } catch (error) {
            throw error;
        }
    }

    const deleteAccount = async (data = {}) => {
      try {
        const res = await postApi(`/${ACCOUNTS_PREFIX}/deleteMyself`, data);
        return res;
      } catch (error) {
        throw error;
      }
    }

    return {
        addAlternateEmail,
        updatePrimaryEmail,
        verifyEmail,
        resendVerificationCode,
        updateProfile,
        deleteAccount
    };
}
