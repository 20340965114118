import { useEffect, useState } from "react";
import HeaderWithLine from "../HeaderWithLine";
import AdvFormControl from "../AdvFormControl";
import { Controller, useForm } from "react-hook-form";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useNProgressHook from "../../hooks/useNProgressHook";
import { useSnackbar } from "notistack";
import { Box, Button } from "@cti-workspace/ui";
import OrgDropDown from "../OrgDropDown/OrgDropDown";
import { getErrMsg, ISSUE_CREDENTIALS, ORG, CREDENTIAL_DEFINITIONS, updateOrganizationPDFTemplate } from "@cti-workspace/helpers";

const UpdateOrgPDFTemplate = ({ pDFTemplates, getPDFTemplates }) => {
	const { handleSubmit, control, errors, reset } = useForm();
	const { progressStart, progressDone } = useNProgressHook();
	const { enqueueSnackbar } = useSnackbar();
	const [category, setCategory] = useState(null);

	const updateOrgPDFTemplate = async (form) => {
		progressStart();
		if (form.organizations !== 0) {
			try {
				const request = {
					orgId: form.organizations,
					category: form.Category,
					pdfTemplateId: form["PDF Template"],
					templateFor: form["Template For"],
				};
				await updateOrganizationPDFTemplate(request);
				enqueueSnackbar(`PDF template updated.`, { variant: "success" });
				reset();
			} catch (error) {
				reset();
				console.error(error);
				enqueueSnackbar(getErrMsg(error, "Failed to update PDF template."), {
					variant: "error",
				});
			} finally {
				progressDone();
			}
		}
	};

	useEffect(() => {
		if (category) {
			getPDFTemplates(category);
		}
	}, [category]);

	return (
		<Box mx="auto" p={-2}>
			<HeaderWithLine title={"Add Organization PDF Template"} variant={"h6"} help={"admin.updatePDFTemplate"} />
			<Box mx="auto" p={1}>
				<form onSubmit={handleSubmit(updateOrgPDFTemplate)}>
					<div className="org-db-connection">
						{/* <OrgAutoComplete dataList={orgsName} selectedOrgId={setSelectedOrgId} orgError={orgError} resetOrg={resetOrg} /> */}
						<Controller name="organizations" control={control} rules={{ required: true }} render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => <OrgDropDown ref={ref} onChange={onChange} required />} />
						{/* <OrgDropDown onChange={setSelectedOrgId} ref={orgRef} required /> */}
					</div>
					<AdvFormControl errors={errors} name={"Category"} margin={"normal"}>
						<Controller
							as={
								<Select id="pdf-template-category" fullWidth>
									<MenuItem key={ISSUE_CREDENTIALS} value={ISSUE_CREDENTIALS} onClick={() => setCategory(ISSUE_CREDENTIALS)}>
										Issue Credentials
									</MenuItem>
								</Select>
							}
							name="Category"
							rules={{ required: "PDF template category is required" }}
							control={control}
							defaultValue={null}
						/>
					</AdvFormControl>
					<AdvFormControl errors={errors} name={"Template For"} margin={"normal"}>
						<Controller
							as={
								<Select id="pdf-template-for" fullWidth>
									<MenuItem key={ORG} value={ORG}>
										Organization
									</MenuItem>
									<MenuItem key={CREDENTIAL_DEFINITIONS} value={CREDENTIAL_DEFINITIONS}>
										Credential Definitions
									</MenuItem>
								</Select>
							}
							name="Template For"
							rules={{ required: "PDF template for is required" }}
							control={control}
							defaultValue={null}
						/>
					</AdvFormControl>
					<AdvFormControl errors={errors} name={"PDF Template"}>
						<Controller
							as={
								<Select id="pdf-template" fullWidth>
									{pDFTemplates.map((item) => (
										<MenuItem key={item._id} value={item._id}>
											{item.name}
										</MenuItem>
									))}
									{!pDFTemplates.length && (
										<MenuItem>
											<i>No PDF Templates</i>
										</MenuItem>
									)}
								</Select>
							}
							name="PDF Template"
							rules={{ required: "PDF template is required" }}
							control={control}
							defaultValue={null}
						/>
					</AdvFormControl>
					<Box mx="auto" p={1} />
					<Button color="warning" id="updatePDFTemplate" type="submit" fullWidth={true}>
						Add Template
					</Button>
				</form>
			</Box>
		</Box>
	);
};

export default UpdateOrgPDFTemplate;
