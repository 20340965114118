import { ADMIN_PREFIX } from "@cti-workspace/helpers";
import { getApi } from "./api";

export default async function fetchEmailTemplates(type = "SEND_GRID", category = "ISSUE_CREDENTIALS") {
	const emailTemplates = await getApi(`/${ADMIN_PREFIX}/getAllEmailTemplates?type=${type}&category=${category}`);
	return emailTemplates.emailTemplates.map((emailTemplate) => ({
		...emailTemplate,
		default: emailTemplate.categoryDefault,
		id: emailTemplate.templateId,
	}));
}
