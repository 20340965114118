import { TypeScale, TypeStyle, ColorPalette } from '@theme-ui/style-guide'
import { ThemeProvider,Themed } from 'theme-ui'
import Theme from './theme/theme'

export const Ui = (props) => (
	<ThemeProvider theme={Theme}>
    <Themed.h1>Style Guide</Themed.h1>
    <ColorPalette />
    <TypeScale />
	<Themed.h1>Roboto Font</Themed.h1>
    <TypeStyle fontFamily="heading" fontWeight="heading" lineHeight="heading"> Heading </TypeStyle>
	<TypeStyle fontFamily="body" fontWeight="bold" lineHeight="body" > Bold Body</TypeStyle>
    <TypeStyle fontFamily="body" fontWeight="body" lineHeight="body" > Body </TypeStyle>
	<TypeStyle fontFamily="monospace" fontWeight="body" lineHeight="body" > Monospace </TypeStyle>
  </ThemeProvider>
)

export default Ui