import { useState } from "react";
import Box from "@material-ui/core/Box";
import HeaderWithLine from "../HeaderWithLine";
import AdvFormControl from "../AdvFormControl";
import { Controller, useForm, useWatch } from "react-hook-form";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "@cti-workspace/ui";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useNProgressHook from "../../hooks/useNProgressHook";
import { useSnackbar } from "notistack";
import CredentialDefineModal from "../CredentialDefineModal";
import jsonParse from "json-parse-default";
import OrgDropDown from "../OrgDropDown/OrgDropDown";
import { getErrMsg, ISSUER_PREFIX, updateOrganizationCustomCredential, postApi } from "@cti-workspace/helpers";

const AddOrgCustomCredential = ({ organizations, customCredentials }) => {
	const { handleSubmit, control, register, errors, reset, getValues, setValue } = useForm();
	const { progressStart, progressDone } = useNProgressHook();
	const { enqueueSnackbar } = useSnackbar();
	const [orgsName, setOrgsName] = useState([]);
	const [orgError, setOrgError] = useState(false);
	const [createCredential, setCreateCredential] = useState(false);

	const watchValues = useWatch({ control });

	const createCredentialSubmit = async ({ credentialName, selectedOrgId, readOnly, customCredentialId, settings, selectedEmailTemplate, transcriptFieldsArray = [{}] }) => {
		try {
			const transcriptFields = transcriptFieldsArray.map((item, i) => ({
				name: item.key,
				courseCode: String(i),
				grade: item.value || "",
				required: item.required || false,
			}));

			const credDef = {
				id: "",
				name: credentialName,
				issuers: [],
				recipient: {},
				transcript: transcriptFields,
				additionalData: {
					description: "",
					imageUrl: "https://ipwru.s3.amazonaws.com/prod/seal.png",
					issuerImageUrl: "",
					resources: [],
					rendererConfigId: null,
					sections: null,
					iconData: {
						iconURL: "https://ipwru.s3.amazonaws.com/prod/seal.png",
						persist: null,
						iconName: null,
					},
					emailTemplates: selectedEmailTemplate,
				},
				settings,
				pinned: null,
				orgId: selectedOrgId,
				customCredentials: customCredentialId ? [customCredentialId] : [],
				emailTemplates: selectedEmailTemplate,
				readOnly,
			};

			const data = await postApi(`/${ISSUER_PREFIX}/addCredentialDefinition`, credDef);
			return data;
		} catch (err) {
			console.error(getErrMsg(err, "Oops!! Something went wrong. Please try again after sometime."));
		}
	};

	const updateOrgCustomCredential = async (submitData) => {
		progressStart();
		if (submitData.organizations) {
			const { credentialName, transcripts, readOnly, settings, selectedEmailTemplate } = jsonParse(submitData["credDef"], null, {}, (e) => console.log(e));
			try {
				const request = {
					orgId: submitData.organizations["_id"],
					customCredentialId: submitData["Custom Credential"],
					customCredentialsFor: submitData["Credential For"],
				};
				let val = await updateOrganizationCustomCredential(request);

				if (val && !(submitData.organizations.issuers.length === 1 && submitData.organizations.issuers[0] === "verifier")) {
					const customCredentialId = submitData["Custom Credential"];
					const selectedOrgId = submitData.organizations["_id"];
					if (credentialName) {
						const data = await createCredentialSubmit({ credentialName, selectedOrgId, transcriptFieldsArray: transcripts, readOnly, customCredentialId, settings, selectedEmailTemplate });
						enqueueSnackbar(`Credential Definition added.`, { variant: "success" });
					}
				}
				reset();
				enqueueSnackbar(`Custom Credential updated.`, { variant: "success" });
			} catch (error) {
				console.log(error);
				if (error.code == "NotFound") {
					if (credentialName) {
						enqueueSnackbar("Credential Definiton was also not created also", {
							variant: "error",
						});
					}
					enqueueSnackbar(`Failed to update custom credential. ${getErrMsg(error)}`, {
						variant: "error",
					});
				}
			} finally {
				progressDone();
			}
		}
	};

	return (
		<Box p={-2}>
			<HeaderWithLine title={"Add Organization Custom Credential"} variant={"h6"} help={"admin.updateCustomCredential"} />
			<Box mx="auto" p={1}>
				<form onSubmit={handleSubmit(updateOrgCustomCredential)}>
					<div className="org-db-connection">
						<Controller
							control={control}
							name="organizations"
							rules={{
								required: true,
							}}
							render={({ onChange, value, name, ref }, { _ }) => <OrgDropDown onChange={onChange} ref={ref} required returnValue />}
						/>

						{/* <OrgAutoComplete dataList={orgsName} selectedOrgId={setSelectedOrgId} orgError={orgError} resetOrg={resetOrg} /> */}
					</div>
					<Box mx="auto" p={1} />
					<AdvFormControl errors={errors} name={"Custom Credential"}>
						<Controller
							as={
								<Select id="custom-credential" fullWidth>
									<MenuItem value={null}>
										<em>None</em>
									</MenuItem>
									{customCredentials.map((item) => (
										<MenuItem key={item._id} value={item._id}>
											{item?.name}
										</MenuItem>
									))}
								</Select>
							}
							name="Custom Credential"
							rules={{ required: "Custom Credential is required" }}
							control={control}
							defaultValue={""}
						/>
					</AdvFormControl>
					<Box mx="auto" p={1} />
					<AdvFormControl errors={errors} name={"Credential For"} margin={"normal"}>
						<Controller
							as={
								<Select id="custom-credential-for" fullWidth>
									<MenuItem key="ORG" value="ORG">
										Organization
									</MenuItem>
									{watchValues["organizations"] && !(watchValues["organizations"]?.issuers.length === 1 && watchValues["organizations"]?.issuers[0] === "verifier") && (
										<MenuItem key="CREDENTIAL_DEFINITIONS" value="CREDENTIAL_DEFINITIONS">
											Credential Definitions
										</MenuItem>
									)}
								</Select>
							}
							name="Credential For"
							rules={{ required: "Custom Credential for is required" }}
							control={control}
							defaultValue={""}
						/>
					</AdvFormControl>
					<Box mx="auto" p={1} />

					<FormControlLabel
						value="defa3ult"
						control={<Switch checked={createCredential} color="primary" onChange={(e) => setCreateCredential(e.target.checked)} />}
						label="Create Credential Definition"
						labelPlacement="start"
						disabled={!(watchValues["organizations"] && !(watchValues["organizations"]?.issuers.length === 1 && watchValues["organizations"]?.issuers[0] === "verifier") && watchValues["Custom Credential"] && watchValues["Credential For"])}
					/>
					<input name="credDef" ref={register} hidden />
					{createCredential && <CredentialDefineModal setValue={setValue} isOpen={createCredential} toggleModal={setCreateCredential} data={{ selectedOrgId: getValues("organizations")["_id"], customCredentials, ...getValues() }} />}

					<Button color="warning" id="updateCustomCredential" type="submit" fullWidth={true}>
						Add Custom Credential
					</Button>
				</form>
			</Box>
		</Box>
	);
};

export default AddOrgCustomCredential;
