import JSON5 from "json5";

export default function JSONParse(text, reviver) {
	try {
		return {
			value: JSON5.parse(text, reviver),
		};
	} catch (ex) {
		console.error(ex);
		console.log(text);
		return {
			error: ex,
		};
	}
}
