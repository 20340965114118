import { PathwayDetailsSection, PathwayDetailItem, BtnFullWidth, CredentialCard } from "../pathway/components/pathwayComponents";
import ipwruLogo from "../../assets/images/iPWRU_Logo_Med.png";
import { Divider, Typography } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useCredentialHook from "../../hooks/useCredentialHook";
import { usePathwaysInvitation } from "../../hooks/usePathwaysInvitationHook";
import { Dialog, DialogActions, DialogContent, Button, DialogTitle, Icon } from "@cti-workspace/ui";
import { useQueryClient } from "@tanstack/react-query";
import { getApi, ISSUER_PREFIX } from "@cti-workspace/helpers";
import { useSnackbar } from "notistack";

const buttonStyle = { fontsize: "1rem", padding: "0.5rem 3.5rem", borderRadius: "5px" };

const PathwaySideBar = ({ childRef, data, leafId }) => {
	const navigate = useNavigate();
	const [credentialData, setCredentialData] = useState({});
	const { rejectInvitation, acceptPathway } = usePathwaysInvitation();
	const [openModal, setOpenModal] = useState(null);
	const queryClient = useQueryClient();

	const { enqueueSnackbar } = useSnackbar();

	const handleCloseModal = () => {
		setOpenModal(null);
	};

	const onRejectClick = async () => {
		await rejectInvitation({ pathwayId: openModal.selectedPathWay.id, userPathwayId: openModal.selectedPathWay.userPathwayId });
		handleCloseModal(null);
		navigate("/wallet/pathways");
		queryClient.invalidateQueries({ queryKey: [data.email] });
	};

	const onAcceptClick = async (pathwayId, userPathwayId) => {
		await acceptPathway({ userPathwayId, pathwayId });
		navigate("/wallet/pathways");
		queryClient.invalidateQueries({ queryKey: [data.email] });
	};

	const { steps } = data;

	const getActiveLeaf = steps.filter((item) => item.stepId === leafId)[0];
	const getActiveParent = steps.filter((item) => item.level === 0)[0];
	const activeCard = leafId ? getActiveLeaf : getActiveParent;

	// const { getCredentialById } = useCredentialHook({ account: { roles: [], organizations: {} } }, null, false);

	useEffect(() => {
		// console.log(activeCard);
		const getCredentialById = async (id) => {
			try {
				return await getApi(`/${ISSUER_PREFIX}/getCredentialDefinitionById/${id}`);
			} catch (error) {
				enqueueSnackbar(`Failed to retrieve credential definition`, { variant: "error" });
				throw error;
			}
		};

		(async () => {
			if (activeCard) {
				const { credentialId } = activeCard;
				if (credentialId) {
					const credData = await getCredentialById(credentialId);
					setCredentialData(credData);
				}
			}
		})();

		childRef.current.setSelected(activeCard.stepId);
	}, [activeCard, childRef]);

	return (
		<>
			<PathwayDetailsSection>
				<PathwayDetailItem>
					<img src={ipwruLogo} alt="iPWRU" style={{ width: "13rem" }} />
				</PathwayDetailItem>
				<Divider />

				<>
					<PathwayDetailItem text>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div>
								<Typography variant="subtitle1" color="secondary" style={{ fontWeight: "bold", lineHeight: "1.5rem" }}>
									{activeCard?.name}
								</Typography>
								<Typography variant="body2" style={{ marginTop: "0.5rem" }} dangerouslySetInnerHTML={{ __html: activeCard?.description?.replace(/(\r\n|\n|\r)/gm, "<br />") }} />
							</div>
						</div>
					</PathwayDetailItem>
					<Divider />
				</>

				{/* Completion Credential view */}
				{credentialData?.name &&
					(!activeCard?.level ? (
						<PathwayDetailItem text>
							<Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
								Completion Credential
							</Typography>
							<CredentialCard>
								<img src={credentialData?.imageUrl || "https://ipwru.s3.amazonaws.com/prod/seal.png"} style={{ maxWidth: "2rem" }} alt="logo" />
								<Typography variant="body2">{credentialData?.name}</Typography>
							</CredentialCard>
						</PathwayDetailItem>
					) : (
						<PathwayDetailItem text>
							<Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
								Required Credential
							</Typography>
							<CredentialCard>
								<img src={credentialData?.imageUrl || "https://ipwru.s3.amazonaws.com/prod/seal.png"} style={{ maxWidth: "2rem" }} alt="logo" />
								<Typography variant="body2"> {credentialData?.name}</Typography>
							</CredentialCard>
						</PathwayDetailItem>
					))}

				<PathwayDetailItem flexBottom>
					<Divider />
					<div style={{ marginTop: "1rem", display: "flex", width: "100%", gridGap: "0.5rem" }}>
						{data.userPathwayData.status === "INVITED" && (
							<>
								<BtnFullWidth>
									<Button
										color={"secondary"}
										invert
										onClick={() =>
											setOpenModal({
												type: "reject",
												selectedPathWay: { id: data._id, name: data.name, userPathwayId: data.userPathwayId },
											})
										}>
										Reject
									</Button>
									{/* navigate to edit */}
								</BtnFullWidth>
								<BtnFullWidth>
									<Button color={"secondary"} onClick={() => onAcceptClick(data._id, data.userPathwayId)}>
										Accept
									</Button>
								</BtnFullWidth>
							</>
						)}
					</div>
				</PathwayDetailItem>
			</PathwayDetailsSection>

			<Dialog onClose={handleCloseModal} open={Boolean(openModal)} maxWidth="xs" borderRadius={"10px"}>
				<DialogTitle noBorder style={{ padding: "0.5rem 1rem" }}>
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<Button buttonType="icon" icon="cancel" color="danger" invert iconSize="1rem" noBorder buttonTransparent buttonStyle={{ alignSelf: "right" }} onClick={() => setOpenModal(null)} />
					</div>
				</DialogTitle>
				<DialogContent style={{ padding: "0.5rem 1rem" }}>
					<div style={{ textAlign: "center", fontSize: "1.15rem" }}>
						Are you sure you want to reject the invitation to join the <strong>{openModal?.selectedPathWay?.name}</strong> pathway?
					</div>
				</DialogContent>
				<DialogActions noBorder style={{ justifyContent: "space-between", padding: "1rem" }}>
					<Button color="warning" invert buttonStyle={buttonStyle} onClick={handleCloseModal}>
						Cancel
					</Button>
					<Button color="warning" buttonStyle={buttonStyle} onClick={onRejectClick}>
						<div style={{ display: "flex", gap: "3px", alignItems: "center" }}>Confirm</div>
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default PathwaySideBar;
