import { Component } from "react";

class LinkBlock extends Component {
	render() {
		const { blockProps } = this.props;
		const { entity } = blockProps;
		const { val, styleString, href, hAlign, vAlign } = entity.getData();
		return (
			<span
				style={{
					display: "flex",
					justifyContent: hAlign || "center",
					alignItems: vAlign || "center",
				}}
			>
				<a style={styleString} href={href}>
					{val}
				</a>
			</span>
		);
	}
}

export default LinkBlock;
