import { Component } from "react";
import { AtomicBlockUtils } from "draft-js";

class IssuedDate extends Component {
	componentDidMount() {
		const { editorState, entityType, onComplete, data } = this.props;
		const styleString = JSON.parse(window.prompt("style string"));
		if (data) {
			const content = editorState.getCurrentContent();
			const contentWithEntity = content.createEntity(entityType.type, "MUTABLE", {
				val: data,
				styleString: styleString,
			});
			const entityKey = contentWithEntity.getLastCreatedEntityKey();
			const nextState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, " ");

			onComplete(nextState);
		} else {
			onComplete(editorState);
		}
	}

	render() {
		return null;
	}
}
export default IssuedDate;
