import { useState } from "react";
import HeaderWithLine from "../HeaderWithLine";
import AdvTextField from "../AdvTextField";
import { useForm } from "react-hook-form";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useNProgressHook from "../../hooks/useNProgressHook";
import { useSnackbar } from "notistack";
import { Box, Button } from "@cti-workspace/ui";
import { getErrMsg, addDatabaseConnection } from "@cti-workspace/helpers";

const CreateNewConnection = ({ reloadDbConnections }) => {
	const { register, handleSubmit, errors, reset } = useForm();
	const { progressStart, progressDone } = useNProgressHook();
	const { enqueueSnackbar } = useSnackbar();
	const [connectionStatus, setConnectionStatus] = useState(true);

	const createDatabaseConnection = async (form) => {
		progressStart();
		const data = {
			...form,
			active: connectionStatus,
		};
		try {
			await addDatabaseConnection(data);
			enqueueSnackbar(`New Database connection added.`, { variant: "success" });
			reset({
				connectionName: "",
				connectionURL: "",
				dbl: "",
			});
			setConnectionStatus(true);
			reloadDbConnections();
			progressDone();
		} catch (error) {
			progressDone();
			enqueueSnackbar(getErrMsg(error), {
				variant: "error",
			});
		}
	};

	return (
		<>
			<Box mx="auto">
				<HeaderWithLine title={"New Database Connection"} variant={"h6"} help={"admin.addNewDbConnection"} />
				<Box mx="auto" p={1}>
					<form onSubmit={handleSubmit(createDatabaseConnection)}>
						<AdvTextField
							name="connectionName"
							errors={errors}
							inputRef={register({
								required: "Connection name is required",
							})}
							placeholder={"US-Region"}
						/>
						<AdvTextField
							name="connectionURL"
							errors={errors}
							inputRef={register({
								required: "Connection URL is required",
							})}
							margin="normal"
							placeholder={"mongodb://mongodb0.example.com:27017"}
						/>
						<AdvTextField
							name="dbl"
							errors={errors}
							inputRef={register({
								required: "DBL is required",
							})}
							margin="normal"
							placeholder={"dbl"}
						/>
						<FormControlLabel value="active" control={<Switch checked={connectionStatus} color="primary" onChange={(e) => setConnectionStatus(e.target.checked)} />} label="Active" labelPlacement="start" />
						<Box mx="auto" p={1} />
						<Button color="warning" id="createNewConnection" type="submit" fullWidth={true}>
							Create Connection
						</Button>
					</form>
				</Box>
			</Box>
		</>
	);
};

export default CreateNewConnection;
