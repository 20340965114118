import { DraftailEditor, BLOCK_TYPE, INLINE_STYLE, ENTITY_TYPE } from "draftail";

import ImageBlock from "./Blocks/ImageBlock";
import FullImageBlock from "./Blocks/FullWidthImageBlock";
import ImageSource from "./Sources/ImageSource";
import FullImageSource from "./Sources/FullWidthImageSource";
import CredentialNameSource from "./Sources/CredentialNameSource";
import RecipientNameSource from "./Sources/RecipientNameSource";
import IssuedDateSource from "./Sources/IssuedDateSource";
import TextSource from "./Sources/TextSource";
import TextBlock from "./Blocks/TextBlock";

import "draft-js/dist/Draft.css";
import "draftail/dist/draftail.css";
import "./overwrite.css";
import QrSource from "./Sources/QrSource";
import QrBlock from "./Blocks/QrBlock";
import LinkSource from "./Sources/LinkSource";
import LinkBlock from "./Blocks/LinkBlock";

let initial = null;
try {
	if (window.sessionStorage) {
		initial = JSON.parse(sessionStorage.getItem("config"));
	}
} catch (err) {}
// console.log(initial);
let renderVal;
if (initial) {
	renderVal = JSON.parse(initial.content);
}
const onSave = (val) => {
	let content = JSON.stringify(val);
	sessionStorage.setItem("config", JSON.stringify({ ...initial, content }));
};

// const singleLine = singleLinePlugin()
// const plugins = [singleLine]

// Custom Blocks
// 1) Credential Image Block x
// 2) Organization Image Block x
// 3) Recipient Name
// 4) Credential Name
// 5) Credential Description
// 6) Issued On Date

// 7) General Image Block x

export default function NewCred({ data, placeHolder, dimensions }) {
	const WFullImageSource = (props) => (
		<>
			<FullImageSource data={data} {...props} />
		</>
	);
	const WImageSource = (props) => (
		<>
			<ImageSource data={data} {...props} />
		</>
	);
	const WCredentialNameSource = (props) => (
		<>
			<CredentialNameSource data={data.name} {...props} />
		</>
	);
	const WRecipientNameSource = (props) => (
		<>
			<RecipientNameSource data={placeHolder.recipientName} {...props} />
		</>
	);
	const WIssuedDateSource = (props) => (
		<>
			<IssuedDateSource data={placeHolder.issuedOn} {...props} />
		</>
	);

	let styleVal = initial?.style ? JSON.parse(initial?.style) : {};
	let qrVal = initial?.qr ? JSON.parse(initial?.qr) : {};
	return (
		<>
			<div style={qrVal}>QR</div>
			<div style={styleVal}>
				<DraftailEditor
					rawContentState={renderVal || null}
					onSave={onSave}
					enableHorizontalRule
					enableLineBreak
					textAlignment={"center"}
					entityTypes={[
						{
							type: ENTITY_TYPE.IMAGE,
							label: "Sqr Img",
							description: "Add Square Image",
							attributes: ["src", "styleString"],
							source: ImageSource,
							block: ImageBlock,
							whitelist: {
								src: "^http",
							},
						},
						{
							type: ENTITY_TYPE.LINK,
							label: "Link",
							description: "Add anchor tag",
							attributes: ["href", "val", "styleString"],
							source: LinkSource,
							block: LinkBlock,
							whitelist: {
								src: "^http",
							},
						},
						{
							type: "WIDE_IMAGE",
							label: "wide Img",
							description: "Add Wide Image",
							attributes: ["src", "alt"],
							source: FullImageSource,
							block: FullImageBlock,
							whitelist: {
								src: "^http",
							},
						},
						{
							type: "ISSUER_IMG",
							label: "Issuer Img",
							description: "Add Wide Image",
							attributes: ["src", "alt"],
							source: WFullImageSource,
							block: FullImageBlock,
							whitelist: {
								src: "^http",
							},
						},
						{
							type: "CRED_IMG",
							label: "Cred Img",
							description: "Add Cred Image",
							attributes: ["src", "alt"],
							source: WImageSource,
							block: ImageBlock,
							whitelist: {
								src: "^http",
							},
						},
						{
							type: "TextBlock",
							label: "T",
							description: "Add Cred Name",
							attributes: ["val", "styleString"],
							source: TextSource,
							block: TextBlock,
						},
						{
							type: "QrBlock",
							label: "Qr",
							description: "Add Qr",
							attributes: ["src", "styleString"],
							source: QrSource,
							block: QrBlock,
						},
						{
							type: "CRED_NAME",
							label: "Cred Name",
							description: "Add Cred Name",
							attributes: ["val", "styleString"],
							source: WCredentialNameSource,
							block: TextBlock,
						},
						{
							type: "RESP_NAME",
							label: "Resp Name",
							description: "Add Resp Name",
							attributes: ["val", "styleString"],
							source: WRecipientNameSource,
							block: TextBlock,
						},
						{
							type: "ISD_NAME",
							label: "ISD Name",
							description: "Add Issued Date",
							attributes: ["val", "styleString"],
							source: WIssuedDateSource,
							block: TextBlock,
						},
					]}
					// topToolbar={null}
				/>
			</div>
		</>
	);
}
