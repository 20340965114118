import { createStyles, Theme } from "@material-ui/core/styles";

const Styles = (theme) =>
	createStyles({
		formContainer: {
			padding: 20,
			margin: 20,
		},
		root: {
			flex: "1 1 100%",
		},
		header: {
			color: "#fff",
			fontFamily: "Roboto",
			fontWeight: 600,
			letterSpacing: "4.2px",
		},

		logo: {
			paddingTop: 10,
			paddingBottom: 10,
			width: 150,
		},
		connectionDiv: {
			margin: 0,
			paddingTop: 20,
		},
		connectLink: {
			color: "#3A506B",
		},
		center: {
			maxWidth: 400,
			minWidth: 300,
			marginTop: 50,
			marginLeft: "auto",
			marginRight: "auto",
			textAlign: "center",
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
		},
		toolbar: theme.mixins.toolbar,
		emptyBlock: {
			padding: theme.spacing(2),
		},
		walletCardRow: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			width: "100%",
		},
		credName: {
			minHeight: "75px",
		},
	});

export default Styles;
