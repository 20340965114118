import { useEffect, useState } from "react";
import { Checkbox, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography, Tooltip } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Replay from "@material-ui/icons/Replay";
import { getObjectValue } from "@cti-workspace/helpers";
import { Box } from "@cti-workspace/ui";

function descendingComparator(a, b, orderBy) {
	const { [orderBy]: aOrderBy } = a;
	const { [orderBy]: bOrderBy } = b;
	if (bOrderBy < aOrderBy) {
		return -1;
	}
	if (bOrderBy > aOrderBy) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function TableRowWrapper({ getTableRowTooltip, children, row }) {
	if (getTableRowTooltip) {
		return <Tooltip title={getTableRowTooltip(row)}>{children}</Tooltip>;
	}
	return <>{children}</>;
}

export default function TableComponent({
	config = {},
	data = [],
	pagination = false,
	dense = false,
	checkbox = false,
	onRowClick = () => {},
	onCheckboxClick = () => {},
	search = [],
	searchPlaceholder = "",
	alignTitle = "left",
	title = "",
	titleVariant = "h5",
	onCheckBoxAllClick = () => {},
	showBottomSelection = false,
	stickyColumnRight = null,
	keyProp = null,
	emptyText = "",
	header = null,
	onCheckBoxClickedRows,
	selectAllRows = false,
	forceUpdate = false,
	defaultPageSize = 10,
	apiSearchFlag = false,
	apiPaginationFlag = false,
	paginationCount = 0,
	onSearch = (search = "") => {},
	onPaginationChange = (pageSize = 0, pageNumber = 0) => {},
	getTableRowTooltip = null,
	leftControls = null,
	marginBottom = 2,
	resetSearch = null,
	controlledPage = null,
}) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);
	const [rows, setRows] = useState([]);
	const [checkboxIndex, setCheckboxIndex] = useState({});
	const [order, setOrder] = useState({});
	const [searchInput, setSearchInput] = useState("");
	useEffect(() => {
		if (!pagination) {
			setRowsPerPage(data.length);
		}
		if (!apiSearchFlag && searchInput) {
			handleSearchChange({ target: { value: searchInput } });
		} else {
			if (config && config.headers && config.headers.length) {
				config.headers.map((item) => {
					if (item.sort && item.sortOrder === "desc") {
						setRows(data.sort((a, b) => descendingComparator(a, b, item.prop)));
					} else {
						setRows(data);
					}
				});
			} else {
				setRows(data);
			}

			setCheckboxIndex({});
		}
	}, [data, forceUpdate]);
	useEffect(() => {
		if (selectAllRows) {
			const length = rows.length;
			let toSet = {};
			for (let x = 0; x < length; x++) {
				const { [x]: row } = rows;
				toSet = { ...toSet, [x]: row };
			}
			onCheckBoxAllClick(rows);
			onCheckBoxClickedRows && onCheckBoxClickedRows(rows);
			setTimeout(() => {
				setCheckboxIndex(toSet);
			}, 0);
		} else {
			setCheckboxIndex({});
			onCheckBoxAllClick([]);
			onCheckBoxClickedRows && onCheckBoxClickedRows([]);
		}
	}, [selectAllRows]);
	useEffect(() => {
		if (resetSearch) setSearchInput("");
	}, [resetSearch]);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		onPaginationChange(rowsPerPage, newPage);
	};
	const descendingComparator = (a, b, orderBy) => {
		const { [orderBy]: aOrderBy } = a;
		const { [orderBy]: bOrderBy } = b;
		if (bOrderBy < aOrderBy) {
			return -1;
		}
		if (bOrderBy > aOrderBy) {
			return 1;
		}
		return 0;
	};
	const sortChange = (prop, index) => {
		setRows(rows.sort(getObjectValue(order, index) === "desc" ? (a, b) => descendingComparator(a, b, prop) : (a, b) => -descendingComparator(a, b, prop)));
	};
	const handleChangeRowsPerPage = (event) => {
		onPaginationChange(parseInt(event.target.value, 10), 0);
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSearchChange = (event) => {
		if (!apiSearchFlag) {
			const value = searchInput;
			const filter = data.filter((item) => {
				let count = 0;
				for (let x = 0; x < search.length; x++) {
					const { [x]: searchValue } = search;
					if (getObjectValue(item, searchValue, "").toString().toLowerCase().includes(value)) {
						count++;
					}
				}
				if (count) return true;
			});
			setSearchInput(value);
			setPage(0);
			setRows(filter);
			onCheckBoxClickedRows && onCheckBoxClickedRows([]);
			setCheckboxIndex({});
		} else {
			if (searchInput) {
				onSearch(searchInput);
				setPage(0);
			}
		}
	};

	const reload = () => {
		setSearchInput("");
		setPage(0);
		onSearch("");

		if (!apiSearchFlag) {
			setRows(data);
		}
	};

	return (
		<>
			{title && (
				<Typography variant={titleVariant} align={alignTitle}>
					{title}
				</Typography>
			)}
			<Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={marginBottom}>
				{leftControls ? leftControls : <div style={{ width: "100%" }}>{header}</div>}
				{search.length ? (
					<Box display="flex" justifyContent="flex-end" alignItems="center">
						<TextField
							variant="outlined"
							size={dense ? "small" : "medium"}
							value={searchInput}
							defaultValue={searchInput}
							style={{ minWidth: "14rem" }}
							InputProps={{
								endAdornment: (
									<IconButton
										size={dense ? "small" : "medium"}
										onClick={handleSearchChange}
										onMouseDown={(event) => {
											event.preventDefault();
										}}>
										<SearchIcon fontSize={dense ? "small" : "medium"} />
									</IconButton>
								),
							}}
							placeholder={searchPlaceholder || "Search"}
							onChange={(e) => {
								setSearchInput(e.target.value.toLowerCase());
							}}
							onKeyDown={(e) => {
								if (e.key === "Enter" || e.keyCode === 13) {
									setSearchInput(e.target.value.toLowerCase());
									handleSearchChange();
								}
							}}
						/>
						<div style={{ marginLeft: "10px" }}>
							<IconButton
								size={dense ? "small" : "medium"}
								onClick={reload}
								onMouseDown={(event) => {
									event.preventDefault();
								}}>
								<Replay aria-label="Reload Data" fontSize={dense ? "small" : "medium"} />
							</IconButton>
						</div>
					</Box>
				) : null}
			</Box>
			<TableContainer>
				<Table size={dense ? "small" : "medium"}>
					<TableHead>
						<TableRow>
							{checkbox ? (
								<TableCell align="center" component={Grid} container justifyContent="center" alignItems="center" style={{ width: "20px", padding: "0px" }}>
									<Checkbox
										style={{ color: "#9c49ed" }}
										size="small"
										checked={!!Object.keys(checkboxIndex).length}
										indeterminate={Object.keys(checkboxIndex).length > 0 ? Object.keys(checkboxIndex).length !== rows.length : false}
										onChange={(event) => {
											const length = rows.length;
											let toSet = {};
											if (event.target.checked) {
												for (let x = 0; x < length; x++) {
													const { [x]: row } = rows;
													toSet = {
														...toSet,
														[x]: row,
													};
												}
												onCheckBoxAllClick(rows);
												onCheckBoxClickedRows && onCheckBoxClickedRows(rows);
											} else {
												onCheckBoxAllClick([]);
												onCheckBoxClickedRows && onCheckBoxClickedRows([]);
											}
											setCheckboxIndex(toSet);
										}}
									/>
								</TableCell>
							) : null}
							{config?.headers.map((item, index) => (
								<TableCell
									key={index}
									style={{ fontWeight: "bold", ...(item?.style ? item.style : {}), ...(stickyColumnRight >= 0 && stickyColumnRight === index ? { position: "sticky", right: 0, backgroundColor: "white" } : {}) }}
									align={item?.align || "left"}>
									{item.sort ? (
										<TableSortLabel
											active
											direction={getObjectValue(order, index) || item.sortOrder || "asc"}
											onClick={() => {
												const orderData = getObjectValue(order, index) === "desc" ? "asc" : "desc";
												setOrder({
													[index]: orderData,
												});
												sortChange(item.prop, index);
											}}>
											{item.label}
											{item?.order}
										</TableSortLabel>
									) : (
										item.label
									)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{(apiPaginationFlag ? rows : rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)).map((item, index) => (
							<TableRowWrapper key={`trw-${index}`} getTableRowTooltip={getTableRowTooltip} row={item}>
								<TableRow
									style={{
										cursor: "pointer",
										backgroundColor: item.state === "revoked" ? "#d3d3d3" : item.state === "deleted" ? "#FFB6C1" : "",
									}}
									key={index}
									onClick={(event) => {
										if (event.target.type !== "checkbox") {
											onRowClick(item);
										}
									}}>
									{checkbox ? (
										<TableCell align="center" component={Grid} container justifyContent="center" alignItems="center" style={{ width: "20px", padding: "0px" }}>
											<Checkbox
												size="small"
												style={{ color: "#9c49ed" }}
												checked={Boolean(getObjectValue(checkboxIndex, index)) || false}
												onChange={(event) => {
													let indexVals = checkboxIndex;
													indexVals = { ...indexVals, [index]: undefined };
													if (event.target.checked) {
														indexVals = { ...indexVals, [index]: item };
														setCheckboxIndex(indexVals);
													} else {
														setCheckboxIndex({ ...indexVals });
													}
													onCheckboxClick(event.target.checked, item);
													if (onCheckBoxClickedRows) {
														onCheckBoxClickedRows(Object.values(indexVals));
													}
												}}
											/>
										</TableCell>
									) : null}
									{config?.headers.map((column, i) => (
										<TableCell
											key={keyProp ? `${index} ${getObjectValue(item, keyProp)} ${i}` : i}
											align={column?.align || "left"}
											style={{ ...(column?.style ? column.style : {}), ...(stickyColumnRight >= 0 && stickyColumnRight === i ? { position: "sticky", right: 0, backgroundColor: "white" } : {}) }}>
											<Typography noWrap style={{ ...(column?.typographyStyle ? column.typographyStyle : {}) }}>
												{column?.func ? (
													column.func(item, column, page * rowsPerPage + index)
												) : column?.type === "date" ? (
													new Date(getObjectValue(item, column.prop)).toLocaleDateString("en-in")
												) : column?.type === "image" ? (
													<img src={"/" + getObjectValue(item, column.prop)} style={{ maxHeight: "50px" }} />
												) : (
													getObjectValue(item, column.prop)
												)}
											</Typography>
										</TableCell>
									))}
								</TableRow>
							</TableRowWrapper>
						))}
						{rows.length === 0 ? (
							<TableRow>
								<TableCell colSpan={config?.headers?.length + (checkbox ? 1 : 0) || 1}>
									<Typography variant="caption">{emptyText}</Typography>
								</TableCell>
							</TableRow>
						) : null}
					</TableBody>
				</Table>
			</TableContainer>
			<Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
				<Typography>{showBottomSelection && Object.keys(checkboxIndex).length > 0 && `${Object.keys(checkboxIndex).length} row${Object.keys(checkboxIndex).length > 1 ? "'s" : ""} selected`}</Typography>
				{pagination && (
					<TablePagination
						rowsPerPageOptions={[10, 25, 50, 100]}
						component="div"
						count={apiPaginationFlag ? paginationCount : rows.length}
						rowsPerPage={rowsPerPage}
						page={controlledPage ?? page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
			</Box>
		</>
	);
}
