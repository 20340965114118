import { useEffect, useState } from "react";
import { Grid, Paper, Table, TableRow, TableContainer, TableCell, Typography, Button, IconButton, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import CreateOrganization from "../createOrganization/createOrganization";
import HeaderWithLIne from "../../components/HeaderWithLine";
import AddButton from "../../components/NewImageButton";
import useUserHook from "../../hooks/useUserHook";
import useOrgranizationHook from "../../hooks/useOrganizationHook";
import EditIcon from "@material-ui/icons/Edit";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Container, Box } from "@cti-workspace/ui";
import { userStore } from "../../store/userStore";
import { getErrMsg, isValidHttpUrl, UploadImage, getObjectValue } from "@cti-workspace/helpers";

export default function OrganizationAdmin() {
	const orgDetailsProps = [
		{ label: "Name", prop: "name" },
		{ label: "Description", prop: "description" },
		{ label: "Website", prop: "domains.default" },
		{ label: "LinkedIn ID", prop: "linkedinOrganizationId" },
		{ label: "Email", prop: "admin" },
	];
	const [orgData, setOrgData] = useState({});
	const [open, setOpen] = useState(false);
	const [image, setImage] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const organizations = userStore((state) => state.organizations);
	const userLogOut = userStore((state) => state.userLogOut);
	const { getOrganization, updateOrganization } = useOrgranizationHook({ organizations });
	const { control, register, errors, handleSubmit, reset, watch, setError, clearErrors } = useForm({ defaultValues: { images: [] } });
	useEffect(() => {
		getOrganization()
			.then((res) => {
				reset({
					...res,
					images: [{ key: "default", value: getObjectValue(res, "imageUrls.default"), type: "file" }],
				});
				setOrgData(res);
			})
			.catch((err) => console.error(err));
	}, []);

	const updateOrgDetails = async (event) => {
		try {
			let imageUrl = "";
			if (event.linkedinOrganizationId && isNaN(event.linkedinOrganizationId)) throw new Error("LinkedIn ID is not valid");
			let imgValue = getObjectValue(event, "images[0].value");
			const imgKey = getObjectValue(event, "images[0].key", "default");
			if (!imgValue) {
				imgValue = getObjectValue(image, "[0].value");
			}
			if (imgValue && !isValidHttpUrl(imgValue)) {
				imageUrl = await UploadImage(imgValue, imgKey);
			} else {
				imageUrl = imgValue || getObjectValue(orgData, "imageUrls.default");
			}
			delete event.images;
			await updateOrganization({
				name: event.name.trim(),
				description: event.description.trim(),
				linkedinOrganizationId: event.linkedinOrganizationId.trim(),
				imageUrl,
			});
			setOpen(false);
			userLogOut();
			window.location.href = "/";
		} catch (err) {
			console.error(err);
			enqueueSnackbar(getErrMsg(err, "Something went wrong"), {
				variant: "error",
			});
		}
	};

	const watchResources = watch("images");
	// const watchIssuers = watch("issuers");

	const validateFields = async (fieldName, removedIndex) => {
		if (watchResources?.length > 0) {
			const values = watchResources.map((value, index) => value.key !== "" && index !== removedIndex && value.key);
			values.forEach((value, index) => {
				if (value && value !== "" && values.indexOf(value) !== index)
					setError(`${fieldName}[${index}].key`, {
						type: "manual",
						message: "Resource key already exsists",
					});
				else clearErrors(`${fieldName}[${index}].key`);
			});
		}
	};

	const removeField = (fieldName, index, remove) => {
		validateFields(fieldName, index);
		remove(index);
	};

	return (
		<Container>
			<div style={{ marginTop: "70px" }} />
			<HeaderWithLIne variant="h4" title="Organization Details" />
			<Box padding={2} elevation={1} marginTop={2}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={4} style={{ padding: "1rem" }}>
						<img src={getObjectValue(orgData, "imageUrls.default")} style={{ width: "100%" }} />
					</Grid>
					<Grid item xs={12} sm={12} md={8}>
						<div style={{ display: "flex", justifyContent: "flex-end" }}>
							<IconButton color="secondary" size="small" onClick={() => setOpen(true)}>
								<EditIcon color="secondary" size="small" />
							</IconButton>
						</div>
						<TableContainer>
							<Table>
								{orgDetailsProps.map((item, i) => (
									<TableRow key={i}>
										<TableCell style={{ width: "8rem" }}>
											<Typography>{item.label}</Typography>
										</TableCell>
										<TableCell>
											<Typography>{getObjectValue(orgData, item.prop)}</Typography>
										</TableCell>
									</TableRow>
								))}
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			</Box>
			<Dialog open={open} fullWidth maxWidth="sm">
				<DialogTitle>Edit Organization Details</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12} style={{ marginBottom: "1rem" }}>
							<Typography variant="caption" color="error">
								You will be logged out when organization details are updated.
							</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextInput label="Name" inputRef={register({ required: true })} name="name" defaultValue={getObjectValue(orgData, "name")} />
						</Grid>
						<Grid item xs={12}>
							<TextInput label="Description" inputRef={register({ required: true })} name="description" defaultValue={getObjectValue(orgData, "description")} />
						</Grid>
						<Grid item xs={12}>
							<TextInput
								label="LinkedIn Id"
								helperText="Enter only the ID of the LinkedIn Company URL you see when you logged in as admin, ie. 123456789 from https://www.linkedin.com/company/123456789/admin/"
								inputRef={register()}
								name="linkedinOrganizationId"
								defaultValue={getObjectValue(orgData, "linkedinOrganizationId")}
							/>
						</Grid>
						<Grid item xs={12}>
							<AddButton
								getResources={setImage}
								displayName="Organization Logo"
								name="images"
								control={control}
								errors={errors}
								register={register}
								entries={1}
								validate={validateFields}
								removeField={removeField}
								showKey={false}
								addTypes={[
									{
										displayName: "Upload an Image",
										type: ["image/jpeg", "image/png", "image/bmp"],
									},
								]}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button color="secondary" onClick={handleSubmit(updateOrgDetails)}>
						Update
					</Button>
					<Button color="primary" variant="contained" onClick={() => setOpen(false)}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</Container>
	);
}

function TextInput({ label = "", placeholder = "", defaultValue = "", ...props }) {
	return <TextField fullWidth variant="outlined" label={label} placeholder={placeholder} defaultValue={defaultValue} {...props} />;
}
