import { makeStyles } from "@material-ui/core/styles";
import Body from "../../components/Body";
import Style from "./home.style";
import { Container } from "@cti-workspace/ui";

const HomeRoute = () => {
	const useStyles = makeStyles(Style);
	const classes = useStyles();
	return (
		<Container className={classes.root}>
			<Body />
		</Container>
	);
};

export default HomeRoute;
