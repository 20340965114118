import QrScanner from "qr-scanner";
import { useEffect, useRef } from "react";
import { FACING_MODES } from "@cti-workspace/helpers";

const Scanner = ({
	onResult,
	onError = (e) => {
		console.error(e);
	},
	preferredCamera = FACING_MODES.ENVIRONMENT,
}) => {
	const videoRef = useRef(null);
	console.log(preferredCamera);

	useEffect(() => {
		let qrScanner = null;
		if (!videoRef.current) return;

		qrScanner = new QrScanner(videoRef.current, onResult, {
			onDecodeError: onError,
			returnDetailedScanResult: true,
			maxScansPerSecond: 2,
			preferredCamera,
			highlightScanRegion: true,
			highlightCodeOutline: true,
		})

		qrScanner
			.start()
			.then(() => {
				console.log("started");
			})
			.catch(console.error);

		return () => {
			if (qrScanner) qrScanner.destroy();
		};
	}, []);
	

	return (
		<video
			style={{ width: "100%", height: "100%" }}
			ref={videoRef}
			onCanPlay={() => {
				const playPromise = videoRef.current.play();

				if (playPromise !== undefined) {
					playPromise
						.then(() => {
							videoRef.current.pause();
							videoRef.current.play();
						})
						.catch((error) => {
							console.error(error);
						});
				}
			}}
			playsInline
			muted
		/>
	);
};

export default Scanner;
