import { useSnackbar } from "notistack";
import { AUTHORIZATIONS_PREFIX, CREDENTIAL_PREFIX } from "../lib/Constants";
import { getErrMsg, postApi } from "../lib/Utils";
import useNProgressHook from "./useNProgressHook";

export default function useAuthorization() {
	const { progressStart, progressDone } = useNProgressHook();
	const { enqueueSnackbar } = useSnackbar();

	const authorizeCredential = async (payload, callback) => {
		try {
			progressStart();
			const data = await postApi(`/${CREDENTIAL_PREFIX}/${AUTHORIZATIONS_PREFIX}/authorize`, payload);
			callback();
			enqueueSnackbar("Authorization created successfully", { variant: "success" });
			return data;
		} catch (error) {
			enqueueSnackbar(getErrMsg(error, `Something went wrong`, { variant: "error" }));
			callback(true);
			throw error;
		} finally {
			progressDone();
		}
	};

	const getAuthorizationsList = async (search = "", pageSize = 15, pageNumber = 1, sortBy = {}) => {
		try {
			progressStart();
			const data = await postApi(`/${CREDENTIAL_PREFIX}/${AUTHORIZATIONS_PREFIX}/search`, {
				...(search
					? {
							query: {
								search: {
									authorization: {
										credentialDefinitionName: search,
										recipient: {
											firstName: search,
											lastName: search,
											fullName: search
										},
										credentialRecipientName: search,
									},
								},
							},
					  }
					: {}),
				sortBy,
				pageSize,
				pageNumber,
			});
			return data;
		} catch (error) {
			throw error;
		} finally {
			progressDone();
		}
	};

	const revokeAuthorization = async (id) => {
		progressStart();
		try {
			const data = await postApi(`/${CREDENTIAL_PREFIX}/${AUTHORIZATIONS_PREFIX}/${id}/revoke`);
			enqueueSnackbar("Authorization revoked successfully", { variant: "success" });
			return data;
		} catch (error) {
			enqueueSnackbar(getErrMsg(error, `Something went wrong`, { variant: "error" }));
			throw error;
		} finally {
			progressDone();
		}
	};

	return {
		authorizeCredential,
		getAuthorizationsList,
		revokeAuthorization,
	};
}
