import { CREDENTIAL_DEFINITIONS, ORG, ORGANIZATIONS_PREFIX } from "@cti-workspace/helpers";
import { getApi } from "./api";

export default async function fetchOrgCustomCredentials(organizationId, customCredentialsFor) {
  const customCredentials = await getApi(`/${ORGANIZATIONS_PREFIX}/getCustomCredentials${organizationId || customCredentialsFor ? `?organizationId=${organizationId}&customCredentialsFor=${customCredentialsFor}` : ""}`);
  return customCredentials.customCredentials;
}

export async function fetchOrgAndCredDefCustomCredentials(organizationId) {
  const orgAndCredDefCustomCredentials = await Promise.all([
    fetchOrgCustomCredentials(organizationId, ORG),
    fetchOrgCustomCredentials(organizationId, CREDENTIAL_DEFINITIONS),
  ]);
  return orgAndCredDefCustomCredentials[0].concat(orgAndCredDefCustomCredentials[1]);
}
