import FabricCanvas from "./components/FabricCanvas";
import FabricToolbar from "./components/FabricToolbar";
import { FabricContextProvider } from "./context/FabricContext";
import { useWatch, useFormContext } from "react-hook-form";
import { Controller } from "react-hook-form";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button, ComboBox, Dialog, DialogActions, DialogContent, DialogTitle, Typography as Text } from "@cti-workspace/ui";
import { FABRIC_DIMENSIONS } from "@cti-workspace/helpers";
import PageOrientation from "./components/PageOrientation";

const FabricBuilder = forwardRef(({ control, register, setCopyRendererConfigId, getCertDimensions = () => { }, setFabricContentVariables = () => { }, fabricContentVariables = {}, setFabricContentChange = {}, fabricContentChange = {} }, ref) => {
	const { setValue } = useFormContext({ control });

	const fabricCanvasContainerRef = useRef(null);
	const [canvasDimensions, setCanvasDimensions] = useState({ width: 0, height: 0 });
	const [dimensionModal, setDimensionModal] = useState({
		isOpen: false,
		onChange: () => {},
		changedDimension: "",
	});

	const orientationTypes = {
		us_letter_landscape: "US Letter Landscape",
		us_letter_portrait: "US Letter Portrait",
		a4_landscape: "A4 Landscape",
		a4_portrait: "A4 Portrait",
	};

	const credDimensions = useWatch({
		control,
		name: "credDimension",
	});

	const fabricCertDesigner = useWatch({
		control,
		name: "fabricCertDesigner"
	})

	useEffect(() => {
		if (credDimensions) {
			const width = fabricCanvasContainerRef?.current?.offsetWidth;
			let canvDimensions = {
				width: 0,
				height: 0,
			};
			switch (credDimensions) {
				case "us_letter_portrait":
					canvDimensions = {
						width: width,
						height: Math.floor(width / FABRIC_DIMENSIONS.US_LETTER_PORTRAIT),
					};
					break;
				case "a4_landscape":
					canvDimensions = {
						width: width,
						height: Math.floor(width / FABRIC_DIMENSIONS.A4_LANDSCAPE),
					};
					break;
				case "a4_portrait":
					canvDimensions = {
						width: width,
						height: Math.floor(width / FABRIC_DIMENSIONS.A4_PORTRAIT),
					};
					break;
				case "us_letter_landscape":
					canvDimensions = {
						width: width,
						height: Math.floor(width / FABRIC_DIMENSIONS.US_LETTER_LANDSCAPE),
					};
					break;

				default:
					// us_letter_landscape
					canvDimensions = {
						width: width,
						height: Math.floor(width / FabricBuilder.US_LETTER_LANDSCAPE),
					};
					break;
			}
			setCanvasDimensions(canvDimensions);
			getCertDimensions(canvDimensions);
		}
	}, [fabricCanvasContainerRef?.current?.offsetWidth, credDimensions]);

  useImperativeHandle(ref, () => ({ handleDimensionChangeModal }));

	const handleDimensionChangeModal = (open, onChange, changedDimension, noObjects = false) => {
		setDimensionModal({ isOpen: open, onChange, changedDimension: changedDimension });
		if (noObjects) {
			onChange && onChange(changedDimension);
		}
	};

	return (
		<div style={{ width: "100%", marginTop: "1rem" }}>
			<Text type="body2">Select certificate orientation</Text>
			<div style={{ display: "grid", gap: "0.3rem", gridTemplateColumns: "1fr 1fr" }}>
				<Controller
					defaultValue={credDimensions || "us_letter_landscape"}
					render={({ onChange, value }) => (
						<PageOrientation
							value={value}
							onChange={(updatedValue) => {
								if (updatedValue === value) return true;
								const { objects = [], backgroundImage } = fabricCertDesigner ?? {};
								if (objects.length > 0 || backgroundImage) {
									handleDimensionChangeModal(true, onChange, updatedValue);
								} else {
									handleDimensionChangeModal(false, onChange, updatedValue, true);
								}
							}}
						/>
					)}
					name="credDimension"
					control={control}
					register={register}
				/>
				<div />
			</div>
			<div ref={fabricCanvasContainerRef} style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "1rem" }}>
				{canvasDimensions.width > 0 ? (
					<FabricContextProvider control={control}>
						<FabricToolbar control={control} register={register} setCopyRendererConfigId={setCopyRendererConfigId} setFabricContentVariables={setFabricContentVariables} fabricContentVariables={fabricContentVariables} setFabricContentChange={setFabricContentChange} fabricContentChange={fabricContentChange} />
						<FabricCanvas control={control} {...canvasDimensions} />
					</FabricContextProvider>
				) : null}
			</div>
			<Dialog onClose={() => handleDimensionChangeModal(false)} open={Boolean(dimensionModal.isOpen)} maxWidth="xs" borderRadius={"10px"}>
				<DialogTitle noBorder style={{ padding: "0.5rem 1rem" }}>
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<Button buttonType="icon" icon="cancel" color="danger" invert iconSize="1rem" noBorder buttonTransparent onClick={() => handleDimensionChangeModal(false)} />
					</div>
				</DialogTitle>
				<DialogContent style={{ padding: "0.5rem 1rem" }}>
					<div style={{ textAlign: "center", fontSize: "1.15rem" }}>Are you sure you would like to change the certificate orientation? With this action your current design will be lost.</div>
				</DialogContent>
				<DialogActions>
					<Button color="primary" invert onClick={() => handleDimensionChangeModal(false)}>
						Cancel
					</Button>
					<Button
						color="warning"
						onClick={() => {
							dimensionModal?.onChange(dimensionModal.changedDimension);
							handleDimensionChangeModal(false);
						}}>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
})

export default FabricBuilder;
