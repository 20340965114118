import { TextField, Grid, Button } from "@material-ui/core";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { getErrMsg, IMAGE_CONTENT, getEncodedFileContent, getFileValidationMessage } from "@cti-workspace/helpers";

const DataKeyValueFileField = ({ name, transcriptGrades, settranscriptGrades, required = false }) => {
	const [fileValidationMessage, setFileValidationMessage] = useState("");
	const [isFileSelected, setIsFileSelected] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const onFileChange = async (e) => {
		const file = e.target.files[0];
		let onChangeValidation = getFileValidationMessage(file);

		if (!onChangeValidation) {
			setIsFileSelected(true);
			await setEncodedFileContent(file);
		}

		setFileValidationMessage(onChangeValidation);
	};

	const setEncodedFileContent = async (file) => {
		try {
			const encodedFileContent = await getEncodedFileContent(file);
			settranscriptGrades({ ...transcriptGrades, [name]: encodedFileContent });
		} catch (e) {
			enqueueSnackbar(getErrMsg(null, "Failed to encode file. Please try again"), { variant: "error" });
		}
	};

	const getHelperText = () => {
		if (fileValidationMessage) {
			return fileValidationMessage;
		} else if (required && !isFileSelected) {
			return "This file is required. .jpg and .png files are supported. Must be <=1MB";
		}
		return ".jpg and .png files are supported. Must be <=1MB";
	};

	const onClearFile = () => {
		document.getElementById(name).value = [];
		setIsFileSelected(false);
		settranscriptGrades({ ...transcriptGrades, [name]: IMAGE_CONTENT });
	};

	return (
		<Grid container spacing={3}>
			<Grid item xs={2}>
				<TextField readOnly disabled fullWidth={true} value={"IMAGE"} type="text" label="Type" variant="filled" />
			</Grid>
			<Grid item xs={3}>
				<TextField readOnly disabled fullWidth={true} value={name} type="text" label="Name" variant="filled" />
			</Grid>
			<Grid item xs={7}>
				<TextField inputProps={{ accept: "image/png, image/jpeg" }} required={required} error={fileValidationMessage !== ""} helperText={getHelperText()} fullWidth={true} type="file" id={name} onChange={onFileChange} variant="outlined" />
				{isFileSelected && <Button onClick={onClearFile}>Clear File</Button>}
			</Grid>
		</Grid>
	);
};

export default DataKeyValueFileField;
