import NftCard from "./NftCard";

const NftList = ({ nfts = [] }) => {
  return (
    <div className="nft-list">
      {
        nfts.map((item, index) => (
          <NftCard key={item._id} pending={item?.state === "Pending" ? true : false} data={item} index={index} />
        ))
      }
    </div>
  );
};

export default NftList;
