import { useEffect, useState } from "react";
import HeaderWithLine from "../HeaderWithLine";
import { Controller, useForm } from "react-hook-form";
import useNProgressHook from "../../hooks/useNProgressHook";
import { useSnackbar } from "notistack";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import AdvTextField from "../AdvTextField";
import { Box, Button } from "@cti-workspace/ui";
import OrgDropDown from "../OrgDropDown/OrgDropDown";
import { getErrMsg, ORGANIZATIONS_PREFIX, postApi } from "@cti-workspace/helpers";

const UpdateOrgPDFConfig = () => {
	const { handleSubmit, control, errors, reset } = useForm();
	const { progressStart, progressDone } = useNProgressHook();
	const { enqueueSnackbar } = useSnackbar();
	const [encryptPdf, setEncryptPdf] = useState(false);
	const [encryptKey, setEncryptKey] = useState(null);
	const [enablePdfOptions, setEnablePdfOptions] = useState(false);
	const [generatePdf, setGeneratePdf] = useState(false);

	const updateOrgPDFConfig = async (form) => {
		if (encryptPdf && !encryptKey) {
			enqueueSnackbar("Please select Encryption Key or deselect Encrypt PDF", { variant: "error" });
			return;
		}
		progressStart();
		const orgId = form.organizations["_id"];
		const currentSettings = form.organizations["settings"];
		const credentialDefinitions = currentSettings?.credentialDefinitions || {};
		const pdfOptions = currentSettings?.pdfOptions || {};
		const settings = {
			...currentSettings,
			credentialDefinitions: {
				...credentialDefinitions,
				enablePdfOptions: enablePdfOptions,
			},
			pdfOptions: {
				...pdfOptions,
				generatePDF: generatePdf,
				encryptPdf: encryptPdf,
				encryptOptions: encryptPdf
					? {
							encryptKey: encryptKey,
					  }
					: undefined,
			},
		};

		if (form.organizations) {
			try {
				await postApi(`/${ORGANIZATIONS_PREFIX}/updateSettings`, { orgId, settings });
				enqueueSnackbar("Successfully updated Organization PDF Configuration.", { variant: "success" });
				reset();
				setGeneratePdf(false);
				setEncryptPdf(false);
				setEnablePdfOptions(false);
				setEncryptKey(null);
			} catch (e) {
				enqueueSnackbar(getErrMsg(e, "Failed to update Organization PDF Configuration. Please Try Again"), { variant: "error" });
			} finally {
				progressDone();
			}
		}
	};

	useEffect(() => {
		if (!encryptPdf) {
			setEncryptKey("");
		}
	}, [encryptPdf]);

	return (
		<Box mx="auto" p={-2}>
			<HeaderWithLine title={"Update Organization PDF Configuration"} variant={"h6"} help={"admin.updatePDFTemplate"} />
			<Box mx="auto" p={1}>
				<form onSubmit={handleSubmit(updateOrgPDFConfig)}>
					<div className="org-db-connection">
						<Controller name="organizations" control={control} rules={{ required: true }} render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => <OrgDropDown ref={ref} onChange={onChange} required returnValue />} />
						{/* <OrgAutoComplete dataList={orgsName} selectedOrgId={setSelectedOrgId} orgError={orgError} resetOrg={resetOrg}/> */}
					</div>
					<FormControlLabel value="active" control={<Switch checked={generatePdf} color="primary" onChange={(e) => setGeneratePdf(e.target.checked)} />} label="Generate PDF" labelPlacement="start" />
					<FormControlLabel value="active" control={<Switch checked={encryptPdf} color="primary" onChange={(e) => setEncryptPdf(e.target.checked)} />} label="Encrypt PDF" labelPlacement="start" />
					<FormControlLabel value="active" control={<Switch checked={enablePdfOptions} color="primary" onChange={(e) => setEnablePdfOptions(e.target.checked)} />} label="Enable PDF Options" labelPlacement="start" />
					{encryptPdf && <AdvTextField value={encryptKey} onChange={(e) => setEncryptKey(e.target.value)} name="Encrypt Key" errors={errors} placeholder={"Encrypt Key"} />}
					<Box mx="auto" p={1} />
					<Button color="warning" id="updatePDFTemplate" type="submit" fullWidth={true}>
						Update Configuration
					</Button>
				</form>
			</Box>
		</Box>
	);
};

export default UpdateOrgPDFConfig;
