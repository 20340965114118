import { useState } from "react";
import * as React from "react";
import { Checkbox, FormControlLabel, FormHelperText, IconButton, Menu, MenuItem } from "@material-ui/core";
// import { DropzoneDialog } from "material-ui-dropzone";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DropzoneDialog } from "@cti-workspace/ui";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { useFieldArray, useWatch } from "react-hook-form";
import { FilePreviewerThumbnail } from "react-file-previewer";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import "./newImageButton.css";
import { useSnackbar } from "notistack";
import pdfIcon from "../../assets/images/pdf_icon.png";
import ArrowDropDownCircle from "@material-ui/icons/ArrowDropDownCircle";
import { getErrMsg, CHOOSE_BADGE, getObjectValue, isValidURL, isBase64, resourceTypesText } from "@cti-workspace/helpers";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexWrap: "wrap",
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 240,
	},
}));

const NewImageButton = ({ showKey = true, getResources = () => {}, ...props }) => {
	const classes = useStyles();
	const [showUploadDialog, setShowUploadDialog] = useState(false);
	const [dialogProps, setdialogProps] = useState({});
	const [urlOpen, setUrlOpen] = useState(false);
	const [urlKey, setUrlKey] = useState("");
	const [urlValue, setUrlValue] = useState("");
	const [resources, setResources] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const values = useWatch({
		control: props?.control,
	});

	const onAddBtnClick = (params) => {
		if (params?.type === "url") {
			setUrlOpen(true);
			setUrlKey("");
		} else {
			setdialogProps(params);
			setShowUploadDialog(true);
		}
	};
	const { fields, append, remove, insert } = useFieldArray({
		control: props?.control,
		name: props?.name,
		keyName: "id",
	});
	const [allFields, setAllFields] = useState(() => fields.map((item) => ({ ...item, disabled: true })) || []);
	const theme = useTheme();

	const removeResources = (index, item) => {
		// remove value from exsisting values array
		const updateFilesValue = resources.filter((resource) => resource.key !== item.key && resource.value !== item.value);
		// updating the state and remove the image
		setResources(updateFilesValue);
		setUrlKey("");
		// removing field
		props.removeField(props.name, index, remove);
	};

	const isDuplicateResource = (key, value) => {
		return resources.some((url) => url.key === key || url.value === value);
	};

	const onUpload = async (file) => {
		if (Object.keys(file).length) {
			try {
				if (file.size > 5120000) throw new Error("File size too large, should be less than 5MB");
				const fileName = file.name;
				const url = file.src;
				const key = props.useInitialKeyName ? fileName : fields.length === 0 ? "default" : fileName;
				if (isDuplicateResource(key, url)) throw new Error(`${getObjectValue(resourceTypesText, file?.type) || "File"} already exsists`);
				append({ key: key, type: "file", value: url });
				setResources([...resources, { key: key, type: "file", value: url }]);
				getResources([...resources, { key: key, type: "file", value: url }]);
			} catch (err) {
				enqueueSnackbar(getErrMsg(err), { variant: "error" });
			}
		}
		setShowUploadDialog(false);
	};

	const onError = (err) => {
		enqueueSnackbar(getErrMsg(err), { variant: "error" });
	};

	let e = getObjectValue(props?.errors, props?.name);

	const showImgIfPdf = (link) => {
		if (link.split(".").pop().toLowerCase() === "pdf") {
			return pdfIcon;
		}
		return link;
	};

	const onURLSaveClick = () => {
		try {
			if (!isValidURL(urlValue)) throw new Error("Invalid Url");
			if (isDuplicateResource(urlKey, urlValue)) throw new Error("URL already exsists");
			append({
				key: urlKey,
				type: "url",
				value: urlValue,
			});
			setUrlKey("");
			setUrlValue("");
			setUrlOpen(false);
			setResources([
				...resources,
				{
					key: urlKey,
					type: "url",
					value: urlValue,
				},
			]);
			getResources([
				...resources,
				{
					key: urlKey,
					type: "url",
					value: urlValue,
				},
			]);
		} catch (err) {
			enqueueSnackbar(getErrMsg(err), {
				variant: "error",
			});
		}
	};

	return (
		<>
			<Grid item container spacing={1} direction="row" justifyContent="center" alignItems="center" alignContent="center">
				<Grid item xs>
					<Typography variant="h6" color="initial">
						{props?.displayName}
						{props?.displayName === "Select Resources" ? <FormHelperText>Add supporting material, links or resources to better explain the credential</FormHelperText> : null}
					</Typography>
				</Grid>
				<Grid item container direction="row" alignItems="center" justifyContent="flex-end" xs>
					{props?.selectTypes?.map(({ displayName, type }, index) => (
						<Tooltip title={`Add ${displayName}`} arrow key={index}>
							<Button variant="outlined" color="default" size="small" style={{ margin: 2 }} onClick={() => onAddBtnClick({ displayName, type })}>
								{displayName}
							</Button>
						</Tooltip>
					))}
					{props?.addTypes?.map(({ displayName, type }) => (
						<Button
							key={displayName}
							color="white"
							leftSpacing
							disabled={(props?.entries ? props?.entries <= fields.length : e?.length > 0 ? true : false) || (props.displayName === CHOOSE_BADGE && props.selectedIconIndex !== -1)}
							onClick={() => onAddBtnClick({ displayName, type })}>
							{displayName}
						</Button>
					))}
					{props.displayName === CHOOSE_BADGE && (
						<span>
							{props.selectedIconIndex !== -1 && <IconButton aria-controls="simple-menu" variant="outlined" color="default" size="small" aria-haspopup="true" style={{ margin: 2 }} />}
							<Tooltip title="Select Saved Badge">
								<IconButton data-testid="selectSavedBadge" disabled={fields.length} aria-controls="simple-menu" variant="outlined" color="default" size="small" aria-haspopup="true" style={{ margin: 2 }} onClick={handleClick}>
									<ArrowDropDownCircle />
								</IconButton>
							</Tooltip>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleClose}
								PaperProps={{
									style: {
										maxHeight: 266,
									},
								}}>
								<MenuItem
									onClick={() => {
										handleClose();
										props.setSelectedIconIndex(-1);
									}}>
									<em>None</em>
								</MenuItem>
								{props.iconData?.length ? (
									props.iconData.map((icon, index) => (
										<MenuItem
											key={`icon_${index}`}
											selected={props.selectedIconIndex === index}
											onClick={() => {
												props.setSelectedIconIndex(index);
												handleClose();
											}}>
											<img width={64} height={64} src={icon.iconURL} alt={`Saved Icon ${icon.iconName}`} />
											<Typography variant="caption" display="block" style={{ margin: 2 }}>
												{icon.iconName}
											</Typography>
										</MenuItem>
									))
								) : (
									<MenuItem onClick={handleClose}>No Saved Icons</MenuItem>
								)}
							</Menu>
						</span>
					)}
				</Grid>
			</Grid>
			{props.displayName === CHOOSE_BADGE && props.selectedIconIndex !== -1 && (
				<div style={{ marginTop: 6, textAlign: "center" }}>
					<img width={64} src={getObjectValue(props.iconData, props.selectedIconIndex).iconURL} alt="Selected Icon" />
					<Typography>{getObjectValue(props.iconData, props.selectedIconIndex).iconName}</Typography>
				</div>
			)}

			<Grid item container spacing={1}>
				{fields.map((item, index) => (
					<Grid item container key={item.id} spacing={1} style={{ marginTop: theme.spacing(1) }}>
						<Grid item xs={5} style={{ display: showKey ? "unset" : "none" }}>
							<TextField
								inputRef={props?.register({
									required: "Name is required",
									validate: (value) => {
										if (props.validationErrorMessage) {
											const { [props.name]: valueProp = [] } = values;
											let errorVal = "";
											valueProp.forEach((field, i) => {
												if (field.key === value && index !== i) {
													errorVal = props.validationErrorMessage;
												}
											});
											if (errorVal) {
												return props.validationErrorMessage;
											}
										}
									},
								})}
								variant="outlined"
								name={`${props?.name}[${index}].key`}
								id={`${props?.name}${index}`}
								// error={e?.length === index + 1}
								error={e?.length && getObjectValue(e, index).key ? true : false}
								defaultValue={item.key === "default" && props.defaultIconName ? props.defaultIconName : item.key}
								fullWidth
								label="Name"
								helperText={e?.length && getObjectValue(e, `${index}.key.message`, "")}
							/>
							{props.displayName === CHOOSE_BADGE && <FormControlLabel control={<Checkbox checked={props.saveIcon} onChange={(e) => props.setSaveIcon(e.target.checked)} name="checkedB" color="primary" />} label="Save" />}
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: item.type === "file" ? "none" : "unset",
							}}>
							<TextField
								inputRef={props?.register({
									required: "Value is required",
								})}
								variant="outlined"
								id={`${props?.name}${index}-value`}
								name={`${props?.name}[${index}].value`}
								// error={e?.length === index + 1}
								defaultValue={item.value}
								disabled={getObjectValue(allFields, index)?.disabled}
								fullWidth
								label="Value"
								helperText={e?.length === index + 1 ? getObjectValue(e, `${index}.value.message`, "") : ""}
								hidden={item.type !== "url"}
							/>
						</Grid>
						{item.type === "file" && (
							<Grid item xs={showKey ? 6 : 11} className="create-org-file-thumbnail">
								<FilePreviewerThumbnail
									file={{
										url: isBase64(item.value) ? item.value : showImgIfPdf(item.value),
									}}
									hideControls
									style={{
										// aspectRatio: 1,
										overflow: "hidden",
										border: "2px solid",
										padding: 10,
									}}
								/>
							</Grid>
						)}
						{/* add edit button */}
						<Grid container item xs={1} justifyContent="center">
							<Button buttonType="icon" icon="cancel" iconSize="1.3rem" color="danger" invert onClick={() => removeResources(index, item)} />
						</Grid>
					</Grid>
				))}
			</Grid>

			<Dialog disableBackdropClick disableEscapeKeyDown open={urlOpen} onClose={() => setUrlOpen(false)}>
				<DialogTitle>Enter Url</DialogTitle>
				<DialogContent>
					<form className={classes.container}>
						<FormControl className={classes.formControl}>
							<TextField id="key" label="Name" variant="outlined" value={urlKey} onChange={(e) => setUrlKey(e?.target?.value)} />
							<FormHelperText>Display Name of Url</FormHelperText>
						</FormControl>

						<FormControl className={classes.formControl}>
							<TextField id="value" label="Value" variant="outlined" value={urlValue} onChange={(e) => setUrlValue(e?.target?.value)} />
							<FormHelperText>https:// based url </FormHelperText>
						</FormControl>
					</form>
				</DialogContent>
				<DialogActions>
					<Button
						color="danger"
						invert
						onClick={() => {
							setUrlKey("");
							setUrlValue("");
							setUrlOpen(false);
						}}>
						Cancel
					</Button>
					<Button color="warning" id="add-url-button" onClick={onURLSaveClick}>
						Ok
					</Button>
				</DialogActions>
			</Dialog>

			<DropzoneDialog
				submitButtonText="Upload"
				open={showUploadDialog}
				onSave={onUpload}
				acceptedFiles={dialogProps?.type}
				dialogTitle={`${dialogProps.displayName}`}
				dropzoneText={"Drag and Drop / Select"}
				showPreviews
				clearOnUnmount
				maxFileSize={5000000}
				onError={onError}
				onClose={() => setShowUploadDialog(false)}
			/>
			{/* Old drop done */}
			{/* <DropzoneDialog
				submitButtonText="Upload"
				open={showUploadDialog}
				onSave={onUpload}
				acceptedFiles={dialogProps.type}
				dialogTitle={`${dialogProps.displayName}`}
				dropzoneText={"Drag and Drop / Select"}
				showPreviews
				showAlerts
				clearOnUnmount
				filesLimit={1}
				maxFileSize={5000000}
				onClose={() => setShowUploadDialog(false)}
			/> */}
		</>
	);
};

NewImageButton.propTypes = {};

export default NewImageButton;
