import { FormControl, Grid, IconButton, TextField, Tooltip, Typography } from "@material-ui/core";
import IntegrationOptionCard from "../IntegrationOptionCard/IntegrationOptionCard";
import CanvasIntegrationOptionCard from "../IntegrationOptionCard/CanvasIntegrationOptionCard";
import SchoologyIntegrationOptionCard from "../IntegrationOptionCard/SchoologyIntegrationOptionCard";
import BridgeIntegrationOptionCard from "../IntegrationOptionCard/BridgeIntegrationOptionCard";
import BrightSpaceIntegrationOptionCard from "../IntegrationOptionCard/BrightSpaceIntegrationOptionCard";
import TCSIONIntegrationOptionCard from "../IntegrationOptionCard/TCSIONIntegrationOptionCard"
import MoodleIntegrationOptionCard from "../IntegrationOptionCard/MoodleIntegrationOptionCard"
import ChevronLeftIcon from "@material-ui/icons/ArrowBack";
import Autorenew from "@material-ui/icons/Autorenew";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Box } from "@cti-workspace/ui";
import ImportedItemCard from "../ImportedItemCard/ImportedItemCard";
import { getObjectValue } from "@cti-workspace/helpers";
import ImportedModuleItemCard from "../ImportedItemCard/ImportedModuleItemCard";
import ImportedSectionItemCard from "../ImportedItemCard/ImportedSectionItemCard";
import useCredentialHook from "../../hooks/useCredentialHook";
import { useEffect } from "react";
import { userStore } from "../../store/userStore";

export default function Integrations({
	selectedIntegrationProvider = "",
	googleClassroomImage,
	googleClassroomHook,
	setSelectedIntegrationProvider,
	canvasImage,
	canvasHook,
	schoologyImage,
	schoologyHook,
	bridgeImage,
	brightspaceImage,
	brightspaceHook,
	bridgeHook,
	tcsIONImage,
	useTCSIONHook,
	moodleImage,
	useMoodleHook,
	getProvider = () => {},
	reimportCourses = () => {},
	lmsSearchValue = "",
	setLmsSearchValue = () => {},
	loadMoreLMSCourses = () => {},
	getUniqueCredentialName = "",
	createNewCredential,
	connectedCredsData,
}) {
	const roles = userStore((state) => state.roles);
	const organizations = userStore((state) => state.organizations);
	const { credsData, getCreds } = useCredentialHook({ account: { roles, organizations } });

	return (
		<div style={{ padding: "24px" }}>
			{!selectedIntegrationProvider ? (
				<Grid container spacing={3} alignItems={"stretch"}>
					<Grid item xs={12} sm={6} md={4}>
						<IntegrationOptionCard
							image={googleClassroomImage}
							provider={googleClassroomHook}
							bodyText="Connect credentials with your existing Courses and Course Work."
							buttonText="Login"
							setSelectedIntegrationProvider={setSelectedIntegrationProvider}
							getCreds={getCreds}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<CanvasIntegrationOptionCard
							image={canvasImage}
							provider={canvasHook}
							bodyText="Connect credentials with your existing Courses and Modules."
							buttonText="Login"
							setSelectedIntegrationProvider={setSelectedIntegrationProvider}
							getCreds={getCreds}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<SchoologyIntegrationOptionCard
							image={schoologyImage}
							provider={schoologyHook}
							bodyText="Connect credentials with your existing Courses and Course Sections"
							buttonText="Login"
							setSelectedIntegrationProvider={setSelectedIntegrationProvider}
							getCreds={getCreds}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<BridgeIntegrationOptionCard 
							image={bridgeImage} 
							provider={bridgeHook} 
							bodyText="Connect credentials with your existing Courses" 
							buttonText="Login" 
							setSelectedIntegrationProvider={setSelectedIntegrationProvider} 
							getCreds={getCreds} 
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<BrightSpaceIntegrationOptionCard
							image={brightspaceImage}
							provider={brightspaceHook}
							bodyText="Connect credentials with your existing Courses"
							buttonText="Login"
							setSelectedIntegrationProvider={setSelectedIntegrationProvider}
							getCreds={getCreds}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TCSIONIntegrationOptionCard
							image={tcsIONImage}
							provider={useTCSIONHook}
							bodyText="Connect credentials with your existing Courses"
							buttonText="Login"
							setSelectedIntegrationProvider={setSelectedIntegrationProvider}
							getCreds={getCreds}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<MoodleIntegrationOptionCard
							image={moodleImage}
							provider={useMoodleHook}
							bodyText="Connect credentials with your existing Courses"
							buttonText="Login"
							setSelectedIntegrationProvider={setSelectedIntegrationProvider}
							getCreds={getCreds}
						/>
					</Grid>
				</Grid>
			) : (
				<Grid container spacing={12}>
					<Grid item container alignItems="center" lg={4} md={4} sm={4} xs={4}>
						<IconButton style={{ width: "auto" }}>
							<ChevronLeftIcon
								color="action"
								onClick={() => {
									if (getProvider(selectedIntegrationProvider).selectedCourse && getProvider(selectedIntegrationProvider).selectedCourse.name) {
										getProvider(selectedIntegrationProvider).setSelectedCourse({});
										if (getProvider(selectedIntegrationProvider).name == "Google Classroom") {
											getProvider(selectedIntegrationProvider).setImportedCourseWork(null);
										} else if (getProvider(selectedIntegrationProvider).name == "Canvas") {
											getProvider(selectedIntegrationProvider).setImportedModules(null);
										} else if (getProvider(selectedIntegrationProvider).name == "Schoology") {
											getProvider(selectedIntegrationProvider).setImportedSections(null);
										}
									} else {
										setSelectedIntegrationProvider("");
										const provider = getProvider();
										provider.setImportedCourses(null);
										provider.setSelectedCourse({});
									}
								}}
							/>
						</IconButton>
						<Typography variant="h6" component="h6">
							{getProvider(selectedIntegrationProvider).name}
							{getProvider(selectedIntegrationProvider).importedCourses !== null ? ` (${getProvider(selectedIntegrationProvider).importedCourses.length} courses)` : ""}
						</Typography>
					</Grid>
					<Grid item lg={8} md={8} sm={8} xs={8} style={{ display: "flex", "justifyContent-content": "right" }}>
						{selectedIntegrationProvider
							? !getProvider(selectedIntegrationProvider).selectedCourse.name &&
							  !getProvider(selectedIntegrationProvider).selectedCourse.course_title &&
							  !getProvider(selectedIntegrationProvider).selectedCourse.title &&
							  (["canvas", "schoology", "googleClassroom"].includes(selectedIntegrationProvider) ? (
									<IconButton size="small" style={{ "align-content": "flex-end" }}>
										<Tooltip title="Refresh imported courses" placement="right" arrow>
											<Autorenew
												color="secondary"
												onClick={() => {
													reimportCourses();
												}}
											/>
										</Tooltip>
									</IconButton>
							  ) : (
									""
							  ))
							: ""}
					</Grid>
				</Grid>
			)}

			{selectedIntegrationProvider ? (
				["schoology", "canvas", "bridge", "googleClassroom", "brightspace", "tcs_ion", "moodle"].includes(selectedIntegrationProvider) ? (
					<Grid container spacing={3} style={{ paddingBottom: "1.5rem", paddingTop: "1rem" }}>
						<FormControl fullWidth>
							<TextField
								id="standard-adornment-amount"
								fullWidth
								color="secondary"
								InputProps={{
									startAdornment: <SearchIcon color="primary" />,
									endAdornment: (
										<>
											{lmsSearchValue && (
												<IconButton size="small" style={{ marginRight: "0.5rem" }}>
													<CloseIcon
														color="primary"
														onClick={() => {
															setLmsSearchValue("");
															loadMoreLMSCourses(1, true);
														}}
													/>
												</IconButton>
											)}
											<Button variant={lmsSearchValue ? "contained" : "outlined"} color="secondary" disabled={lmsSearchValue ? false : true} onClick={() => loadMoreLMSCourses(1)}>
												Search
											</Button>
										</>
									),
								}}
								variant="outlined"
								size="small"
								value={lmsSearchValue}
								onChange={(e) => {
									setLmsSearchValue(e.target.value);
									if (!e.target.value && lmsSearchValue !== "") {
										loadMoreLMSCourses(1, true);
									}
								}}
							/>
						</FormControl>
					</Grid>
				) : (
					""
				)
			) : (
				""
			)}

			{getProvider(selectedIntegrationProvider).importedCourses &&
				getProvider(selectedIntegrationProvider).importedCourses.length &&
				!getProvider(selectedIntegrationProvider).selectedCourse.name &&
				!getProvider(selectedIntegrationProvider).selectedCourse.Name &&
				!getProvider(selectedIntegrationProvider).selectedCourse.course_title &&
				!getProvider(selectedIntegrationProvider).selectedCourse.title && (
					<Grid container spacing={3} alignItems={"stretch"}>
						{getProvider(selectedIntegrationProvider).importedCourses.map((course, i) => (
							<Grid key={i} item xs={12} sm={6} md={4}>
								<ImportedItemCard
									index={i}
									type="course"
									item={course}
									title={course.name || course.course_title || course.title || course.Name}
									subheaderText={course.id}
									subheaderLink={course.alternateLink || ""}
									description={course.description || ""}
									importSupported={true}
									provider={getProvider(selectedIntegrationProvider)}
									getUniqueCredentialName={getUniqueCredentialName}
									createNewCredential={createNewCredential}
									credsData={credsData}
									connectedCredsData={connectedCredsData}
								/>
							</Grid>
						))}
					</Grid>
				)}

			{selectedIntegrationProvider
				? !getProvider(selectedIntegrationProvider).selectedCourse.name &&
				  !getProvider(selectedIntegrationProvider).selectedCourse.course_title &&
				  !getProvider(selectedIntegrationProvider).selectedCourse.title &&
				  (["schoology"].includes(selectedIntegrationProvider) && getObjectValue(schoologyHook.showLoadMore, "totalItems", 0) > getObjectValue(schoologyHook.showLoadMore, "items", []).length ? (
						<Box marginTop={5}>
							<Button color="secondary" fullWidth onClick={loadMoreLMSCourses}>
								Load More
							</Button>
						</Box>
				  ) : (
						""
				  ))
				: ""}

			{selectedIntegrationProvider
				? !getProvider(selectedIntegrationProvider).selectedCourse.name &&
				  !getProvider(selectedIntegrationProvider).selectedCourse.course_title &&
				  !getProvider(selectedIntegrationProvider).selectedCourse.title &&
				  (["canvas"].includes(selectedIntegrationProvider) && getObjectValue(canvasHook.showLoadMore, "totalItems", 0) > getObjectValue(canvasHook.showLoadMore, "items", []).length ? (
						<Box marginTop={5}>
							<Button color="secondary" fullWidth onClick={loadMoreLMSCourses}>
								Load More
							</Button>
						</Box>
				  ) : (
						""
				  ))
				: ""}

			{selectedIntegrationProvider
				? !getProvider(selectedIntegrationProvider).selectedCourse.name &&
				  !getProvider(selectedIntegrationProvider).selectedCourse.course_title &&
				  !getProvider(selectedIntegrationProvider).selectedCourse.title &&
				  (["bridge"].includes(selectedIntegrationProvider) && getObjectValue(bridgeHook.showLoadMore, "totalItems", 0) > getObjectValue(bridgeHook.showLoadMore, "items", []).length ? (
						<Box marginTop={5}>
							<Button color="secondary" fullWidth onClick={loadMoreLMSCourses}>
								Load More
							</Button>
						</Box>
				  ) : (
						""
				  ))
				: ""}

			{selectedIntegrationProvider
				? !getProvider(selectedIntegrationProvider).selectedCourse.name &&
				  !getProvider(selectedIntegrationProvider).selectedCourse.course_title &&
				  !getProvider(selectedIntegrationProvider).selectedCourse.title &&
				  (["googleClassroom"].includes(selectedIntegrationProvider) && getObjectValue(googleClassroomHook.showLoadMore, "totalItems", 0) > getObjectValue(googleClassroomHook.showLoadMore, "items", []).length ? (
						<Box marginTop={5}>
							<Button color="secondary" fullWidth onClick={loadMoreLMSCourses}>
								Load More
							</Button>
						</Box>
				  ) : (
						""
				  ))
				: ""}

			{selectedIntegrationProvider
				? !getProvider(selectedIntegrationProvider).selectedCourse.name &&
				  !getProvider(selectedIntegrationProvider).selectedCourse.course_title &&
				  !getProvider(selectedIntegrationProvider).selectedCourse.title &&
				  (["brightspace"].includes(selectedIntegrationProvider) && getObjectValue(brightspaceHook.showLoadMore, "totalItems", 0) > getObjectValue(brightspaceHook.showLoadMore, "items", []).length ? (
						<Box marginTop={5}>
							<Button color="secondary" fullWidth onClick={loadMoreLMSCourses}>
								Load More
							</Button>
						</Box>
				  ) : (
						""
				  ))
				: ""}

			{selectedIntegrationProvider
				? !getProvider(selectedIntegrationProvider).selectedCourse.name &&
				  !getProvider(selectedIntegrationProvider).selectedCourse.course_title &&
				  !getProvider(selectedIntegrationProvider).selectedCourse.title &&
				  (["tcs_ion"].includes(selectedIntegrationProvider) && getObjectValue(useTCSIONHook.showLoadMore, "totalItems", 0) > getObjectValue(useTCSIONHook.showLoadMore, "items", []).length ? (
						<Box marginTop={5}>
							<Button color="secondary" fullWidth onClick={loadMoreLMSCourses}>
								Load More
							</Button>
						</Box>
				  ) : (
						""
				  ))
				: ""}
			{selectedIntegrationProvider
				? !getProvider(selectedIntegrationProvider).selectedCourse.name &&
				  !getProvider(selectedIntegrationProvider).selectedCourse.course_title &&
				  !getProvider(selectedIntegrationProvider).selectedCourse.title &&
				  (["moodle"].includes(selectedIntegrationProvider) && getObjectValue(useMoodleHook.showLoadMore, "totalItems", 0) > getObjectValue(useMoodleHook.showLoadMore, "items", []).length ? (
						<Box marginTop={5}>
							<Button color="secondary" fullWidth onClick={loadMoreLMSCourses}>
								Load More
							</Button>
						</Box>
				  ) : (
						""
				  ))
				: ""}	
			{getProvider(selectedIntegrationProvider).selectedCourse && getProvider(selectedIntegrationProvider).selectedCourse.name && (
				<Grid container spacing={1}>
					{/* <Grid item>
                            <DeleteForeverIcon
                                onClick={() => {
                                    getProvider(selectedIntegrationProvider).setSelectedCourse({})
                                    getProvider(selectedIntegrationProvider).setImportedCourseWork(null)
                                }}
                            />
                        </Grid> */}
					<Grid item>
						<Typography component="h4">
							{getProvider(selectedIntegrationProvider).selectedCourse.name}
							{getProvider(selectedIntegrationProvider).importedCourseWork ? ` (${getProvider(selectedIntegrationProvider).importedCourseWork.length} items)` : ""}
							{getProvider(selectedIntegrationProvider).importedModules ? ` (${getProvider(selectedIntegrationProvider).importedModules.length} items)` : ""}
							{getProvider(selectedIntegrationProvider).importedSections ? ` (${getProvider(selectedIntegrationProvider).importedSections.length} items)` : ""}
						</Typography>
					</Grid>
				</Grid>
			)}

			{getProvider(selectedIntegrationProvider).importedCourseWork && getProvider(selectedIntegrationProvider).importedCourseWork.length && (
				<Grid container spacing={3} alignItems={"stretch"}>
					{getProvider(selectedIntegrationProvider).importedCourseWork.map((courseWork, i) => (
						<Grid key={i} item xs={12} sm={6} md={4}>
							<ImportedItemCard
								index={i}
								type="courseWork"
								item={courseWork}
								title={courseWork.title}
								subheaderText={courseWork.id}
								subheaderLink={courseWork.alternateLink}
								description={courseWork.description}
								provider={getProvider(selectedIntegrationProvider)}
								getUniqueCredentialName={getUniqueCredentialName}
								createNewCredential={createNewCredential}
								credsData={credsData}
								connectedCredsData={connectedCredsData}
							/>
						</Grid>
					))}
				</Grid>
			)}

			{getProvider(selectedIntegrationProvider).importedModules && getProvider(selectedIntegrationProvider).importedModules.length && (
				<Grid container spacing={3} alignItems={"stretch"}>
					{getProvider(selectedIntegrationProvider).importedModules.map((courseWork, i) => (
						<Grid key={i} item xs={12} sm={6} md={4}>
							<ImportedModuleItemCard
								index={i}
								type="courseWork"
								item={courseWork}
								title={courseWork.name}
								subheaderText={courseWork.id}
								subheaderLink={courseWork.alternateLink}
								description={courseWork.description}
								provider={getProvider(selectedIntegrationProvider)}
								getUniqueCredentialName={getUniqueCredentialName}
								createNewCredential={createNewCredential}
								credsData={credsData}
								connectedCredsData={connectedCredsData}
							/>
						</Grid>
					))}
				</Grid>
			)}

			{getProvider(selectedIntegrationProvider).importedSections && getProvider(selectedIntegrationProvider).importedSections.length && (
				<Grid container spacing={3} alignItems={"stretch"}>
					{getProvider(selectedIntegrationProvider).importedSections.map((courseWork, i) => (
						<Grid key={i} item xs={12} sm={6} md={4}>
							<ImportedSectionItemCard
								index={i}
								type="courseWork"
								item={courseWork}
								title={courseWork.section_title}
								subheaderText={courseWork.id}
								subheaderLink={courseWork.alternateLink}
								description={courseWork.description}
								provider={getProvider(selectedIntegrationProvider)}
								getUniqueCredentialName={getUniqueCredentialName}
								createNewCredential={createNewCredential}
								credsData={credsData}
								connectedCredsData={connectedCredsData}
							/>
						</Grid>
					))}
				</Grid>
			)}
		</div>
	);
}
