import { Button, Grid, Paper } from "@material-ui/core";
import HeaderWithLIne from "../../components/HeaderWithLine";
import AdvTextField from "../../components/AdvTextField";
import AddButton from "../../components/NewImageButton";
import { Container }  from '@cti-workspace/ui';

export default function CreateOrgForm({ register, control, handleSubmit, errors, watch, setError, clearErrors, email }) {
	const watchResources = watch("images");
	// const watchIssuers = watch("issuers");

	const validateFields = async (fieldName, removedIndex) => {
		if (watchResources?.length > 0) {
			const values = watchResources.map((value, index) => value.key !== "" && index !== removedIndex && value.key);
			values.forEach((value, index) => {
				if (value && value !== "" && values.indexOf(value) !== index)
					setError(`${fieldName}[${index}].key`, {
						type: "manual",
						message: "Resource key already exsists",
					});
				else clearErrors(`${fieldName}[${index}].key`);
			});
		}
	};
	const removeField = (fieldName, index, remove) => {
		validateFields(fieldName, index);
		remove(index);
	};
	// component={Paper}
	return (
		<Container maxWidth="md" style={{ paddingTop: "1rem", paddingBottom: "1rem", marginBottom: "1rem" }}>
			<form onSubmit={handleSubmit}>
				<HeaderWithLIne title={"Organization Details"} variant={"h5"} />
				<Grid container spacing={2} style={{ marginTop: "0.5rem", marginBottom: "1rem" }}>
					<Grid item xs={12} md={12}>
						<AdvTextField
							name="name"
							displayName="Organization Name"
							errors={errors}
							inputRef={register({
								required: "Organization Name is required",
							})}
							placeholder={"Example Organization"}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<AdvTextField
							name="description"
							displayName="Organization Description"
							errors={errors}
							inputRef={register({
								required: "Organization Description is required",
							})}
							placeholder={"Example Organization, located in Canada."}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<AdvTextField
							name="website"
							displayName="Website"
							errors={errors}
							inputRef={register({
								required: "Website is required",
							})}
							placeholder={"https://example.com"}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<AdvTextField
							name="linkedinOrganizationId"
							displayName="LinkedIn Organization ID"
							errors={errors}
							inputRef={register()}
							placeholder={"12345678"}
							helperText=" Found in the address bar on your company's LinkedIn page when logged in as an admin, ie. https://www.linkedin.com/company/123456789/admin/"
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<div style={{ border: "0.5px solid #8c8c8c", borderRadius: "5px", padding: "8px", paddingBottom: "12px" }}>
							<AddButton
								displayName="Organization Logo"
								name="images"
								control={control}
								errors={errors}
								register={register}
								validate={validateFields}
								removeField={removeField}
								addTypes={[
									{
										displayName: "Upload an Image",
										type: ["image/jpeg", "image/png", "image/bmp"],
									},
								]}
							/>
						</div>
					</Grid>
				</Grid>
				<HeaderWithLIne title={"Account Details"} variant={"h5"} />
				<Grid container spacing={2} style={{ marginTop: "0.5rem", marginBottom: "1rem" }}>
					<Grid item xs={12}>
						<AdvTextField
							name="email"
							displayName="Email"
							errors={errors}
							inputRef={register({
								required: "Email is required",
							})}
							disabled={true}
							value={email}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<AdvTextField
							name="firstName"
							displayName="First Name"
							errors={errors}
							inputRef={register({
								required: "First Name is required",
							})}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<AdvTextField
							name="lastName"
							displayName="Last Name"
							errors={errors}
							inputRef={register({
								required: "Last Name is required",
							})}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<AdvTextField
							name="password"
							displayName="Password"
							errors={errors}
							inputRef={register({
								required: "Password is required",
							})}
						/>
					</Grid>
				</Grid>
				<div style={{ textAlign: "center", marginTop: "1rem" }}>
					<Button type="submit" variant="contained" color="secondary">
						Submit
					</Button>
				</div>
			</form>
		</Container>
	);
}
