import { useState, useEffect } from "react";
import * as React from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { GLOBALED_URL, GLOBALED_ORG_TOKEN, MENA_URL, MENA_ORG_TOKEN/*, AUTH_PREFIX, JWT_TOKEN, SETTINGS*/, postApi } from "@cti-workspace/helpers";
//import jwt_decode from "jwt-decode";
import { useSnackbar } from "notistack";
import { userStore } from "../../store/userStore";

function GlobalEdRedirect() {
  GlobalEdStyleRedirect( GLOBALED_URL, GLOBALED_ORG_TOKEN );
}

function MenaRedirect() {
  GlobalEdStyleRedirect( MENA_URL, MENA_ORG_TOKEN );
}

function GlobalEdStyleRedirect( redirectURL, orgToken ) {
	const userLogIn = userStore((state) => state.userLogIn);
	const { enqueueSnackbar } = useSnackbar();
	const [accountId, setaccountId] = useState("");

	useEffect(() => {
		let code = null;

		if (window.location.search) {
			//Check Query String
			const params = new URLSearchParams(window.location.search);
			code = params.get("code");
		} else {
			//Check Redirect URL
			const url = window.location.href;
			code = url.split("#code=").pop();
		}

		(async () => {
			// Check if there is a query param, meaning result of an proper redirect auth flow from GlobalEd
			if (code) {
				const request = {
          org_token: orgToken,
					auth_token: code,
        };
        var data;
        try { 
				  data = await postApi(
					  `/wp-json/mmo_ventures_plugin/v1/sso_token`,
					  request,
					  {
						  "Access-Control-Allow-Origin": "*",
					  },
					  true,
            redirectURL
          );
        } catch (error) {
          //Redirect back to GlobalEd with Error (The user email is not included or recognized)
          window.location.href = `${redirectURL}/mydashboard/wallet?token=${code}&code=407`;
          console.log(error);
          return;
        }
				/*
					SAMPLE RESPONSE
					{
						"success": 1,
						"response_code": 200,
						"err_msg": "",
						"id_token": "eyJhbGciOiJTSEEyNTYiLCJ0eXAiOiJKV1QifQ . eyJpc3MiOiJodHRwczpcL1wvZ2xvYmFsZWRmb3VuZGF0aW9uLmNvbSIsInN1YiI6Ijk3OTkxMTExMTExMDc1NTU1NTA0ODY0MTAzMTA5OTcxMDUxMDg0Njk5MTExMTA5IiwiYXVkIjoienV0ZDBvRnYtekw0dC1oNW9HLVJwbzUteUs3V1hLUG9DdGlZIiwibm9uY2UiOiJhY29vazc3MjBAZ21haWwuY29tXzE2NDE5OTI5NDAiLCJleHAiOjE2NDE5OTMwMDAsImlhdCI6MTY0MTk5Mjk0MCwiZW1haWwiOiJhY29vazc3MjBAZ21haWwuY29tIn0 . 05wzNnep41eWdQawCX60W1MM6Ntc7pmS-BC2CVvyViM"
					}
				*/
				// const data ={
				// 	success: 1,
				// 	response_code: 200,
				// 	err_msg: "",
				// 	id_token: "eyJhbGciOiJTSEEyNTYiLCJ0eXAiOiJKV1QifQ . eyJpc3MiOiJodHRwczpcL1wvZ2xvYmFsZWRmb3VuZGF0aW9uLmNvbSIsInN1YiI6Ijk3OTkxMTExMTExMDc1NTU1NTA0ODY0MTAzMTA5OTcxMDUxMDg0Njk5MTExMTA5IiwiYXVkIjoienV0ZDBvRnYtekw0dC1oNW9HLVJwbzUteUs3V1hLUG9DdGlZIiwibm9uY2UiOiJhY29vazc3MjBAZ21haWwuY29tXzE2NDE5OTI5NDAiLCJleHAiOjE2NDE5OTMwMDAsImlhdCI6MTY0MTk5Mjk0MCwiZW1haWwiOiJhY29vazc3MjBAZ21haWwuY29tIn0 . 05wzNnep41eWdQawCX60W1MM6Ntc7pmS-BC2CVvyViM"
				// };

				console.log(data);

				if (data.response_code == 200) {
					console.log(data.id_token);
					let decodedData = JSON.parse(atob(data.id_token.split(".")[1]));
					/* Sample Decoded JWT
						{
						"iss": "https://globaledfoundation.com",
						"sub": "97991111111075555504864103109971051084699111109",
						"aud": "zutd0oFv-zL4t-h5oG-Rpo5-yK7WXKPoCtiY",
						"nonce": "acook7720@gmail.com_1641992940",
						"exp": 1641993000,
						"iat": 1641992940,
						"email": "acook7720@gmail.com"
						}
					*/

					let email = decodedData.email;
					// let email = "kasun.dealwis@convergence.tech";
					// let email = "kasun.kulathunge@gmail.com";
					console.log(email);
					try {
						const value = await userLogIn({ email, password: "", organization: "" }, null, true);
						console.log({ value });
						if (value) {
							let { dbAccount } = value;
							setaccountId(dbAccount._id);

							const capabilities = dbAccount.capabilities || null;
							console.log(capabilities);

							if (dbAccount && dbAccount.roles.length === 1 && dbAccount.roles.includes("holder")) {
								enqueueSnackbar("Login Success", { variant: "success" });
								if (!capabilities) {
									window.location.href = "/wallet";
								}
							}
						}
					} catch (error) {
						//Redirect back to GlobalEd with Error (The user email is not included or recognized)
            window.location.href = `${redirectURL}/mydashboard/wallet?token=${code}&code=406`;
						console.log(error);
					}
				}
			} else {
				//Redirect back to GlobalEd with Error
				console.log("No Code Present in URL");
			}
		})();
	}, []);
	return (
		<div
			style={{
				height: "100vh",
				width: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}>
			<CircularProgress size={60} />
			<div style={{ textAlign: "center", marginTop: "10px" }}>
				<Typography variant="subtitle1" component="div" gutterBottom>
					The page is being loaded.
				</Typography>
				{/* <Button variant="outlined" onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>
						Report
					</Button> */}
			</div>
		</div>
	);
}

//function decryptCode(code) {}
export { GlobalEdRedirect, MenaRedirect };
export default { GlobalEdRedirect, MenaRedirect };
