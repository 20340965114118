import { ORGANIZATIONS_PREFIX } from "@cti-workspace/helpers";
import { postApi } from "./api";

async function registerOrganization(formData, account) {
	const url = `/${ORGANIZATIONS_PREFIX}/register/`;
	try {
		const { _id } = await postApi(url, { ...formData });
		return _id;
	} catch (err) {
		throw err;
	}
}

export default registerOrganization;
