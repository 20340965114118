import { Button } from "@cti-workspace/ui";
import { useSnackbar } from "notistack";
import useRendererConfigOrgHook from "../../hooks/useRendererConfigOrgHook";
import { AsyncComboBox } from "@cti-workspace/ui";
import { forwardRef, useState } from "react";
import differenceBy from "lodash/differenceBy";
import { components } from "react-select";
import { getErrMsg } from "@cti-workspace/helpers";

const Option = (props) => {
	const { deleteOrgRendererConfig } = useRendererConfigOrgHook();
	const { enqueueSnackbar } = useSnackbar();

	const update = async (e) => {
		try {
			await deleteOrgRendererConfig(props.value);
			let arr = [...props.selectProps.options];
			let reducedArr = arr.filter((val) => val["_id"] != props.value);
			props.selectProps.loadOptions("", reducedArr, { ...props.selectProps.additional, onDelete: true });
			props.selectProps.onMenuClose();
			enqueueSnackbar(`Deleted Template`, { variant: "success" });
		} catch (err) {
			enqueueSnackbar(getErrMsg(err), {
				variant: "error",
			});
		}
	};
	return (
		<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
			<components.Option {...props} />
			<Button invert buttonType="icon" icon="delete" onClick={(e) => update(e)} />
		</div>
	);
};

const AutoCompleteRendererConfigOrg = forwardRef(({ onChange, defaultValue, initialOptions = [], size = "" }, ref) => {
	const { getAllOrgsRendererConfigList } = useRendererConfigOrgHook();

	const [resetCache, setResetCache] = useState(false);
	async function loadOptions(search, loadedOptions, { page, onDelete }) {
		if (onDelete) {
			setResetCache((prev) => !prev);
			return {
				options: loadedOptions,
				hasMore: true,
				additional: {
					page: page + 1,
					onDelete: false,
				},
			};
		} else {
			const response = await getAllOrgsRendererConfigList(10, page, search, false);
			return {
				options: differenceBy(response.items, loadedOptions, "_id"),
				hasMore: response.totalItems >= response.pageNumber * response.pageSize,
				additional: {
					page: page + 1,
				},
			};
		}
	}

	return (
		<AsyncComboBox
			selectRef={ref}
			components={{ Option }}
			options={initialOptions}
			defaultValue={defaultValue}
			getOptionValue={(item) => item["_id"]}
			getOptionLabel={(item) => item.name}
			loadOptions={loadOptions}
			onChange={(e) => onChange(e["_id"])}
			cacheUniqs={[1, !!resetCache]}
			size={size}
			additional={{
				page: 1,
			}}
		/>
	);
});

export default AutoCompleteRendererConfigOrg;

/**
 * Note : Change in display scenario,
 * Previously, data was loaded of 50+ configs then options were created.
 * Now only 10 are requested at a time, reducing the api load
 *
 * Search is now handled
 *
 * Need to handle delete render Config
 */
