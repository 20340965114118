import { Box, Button, Card, Container } from "@cti-workspace/ui";
import { Grid, Typography } from "@material-ui/core";
import { CloudArrowUp, XCircle } from "@phosphor-icons/react";
import AdvTextField from "../../components/AdvTextField";
import RichTextEditor from "../../components/VisualDefineCredential/RichTextEditor";
import sampleImage from "./img.png";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { getErrMsg, useNftHook } from "@cti-workspace/helpers";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

const ImageCloseIconContainer = styled.div`
	position: relative;
	width: 100%;
	z-index: 99999;
`;
const ImageCloseIcon = styled.div`
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding: 0.3rem;
`;

const MintNft = () => {
	return (
		<Container maxWidth="md">
			<div style={{ height: "64px" }} />
			<Card elevation={2} style={{ padding: "1rem" }}>
				<Typography variant="h6">Mint NFT</Typography>
			</Card>
			<Box borderColor="primary" border={8} style={{ marginTop: "1rem", padding: "1rem", backgroundColor: "white" }}>
				<MintNftForm />
			</Box>
		</Container>
	);
};

export function MintNftForm() {
	const [media, setMedia] = useState("");
	const [mediaError, setMediaError] = useState("");
	const [mediaType, setMediaType] = useState("image");

	const { control, register, handleSubmit, errors } = useForm();

	const { mintNft } = useNftHook();

	const { enqueueSnackbar } = useSnackbar();

	const navigate = useNavigate();

	const handleMintNft = async (e) => {
		try {
			if (!media) {
				setMediaError("Please add a media");
				return;
			}
			const res = await mintNft({
				...e,
				media,
			});
			console.log(res);
			enqueueSnackbar(`NFT Minted Successfully`, { variant: "success" });
			navigate(-1);
		} catch (error) {
			console.error(error);
			enqueueSnackbar(getErrMsg(error, "Something went wrong, please try again."), { variant: "error" });
		}
	};

	const convertToBase64 = (e) => {
		try {
			setMediaError("");
			const file = e.target.files[0];
			if (file) {
				const { size, type } = file;
				if (size / 1024 / 1000 > 20) {
					setMediaError("File size should be less than 20mb");
					return;
				}
				if (type.includes("video")) setMediaType("video");
				else setMediaType("image");
				const reader = new FileReader();
				reader.onloadend = (event) => {
					setMedia(event.target.result);
				};
				reader.readAsDataURL(file);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const removeMedia = () => {
		setMedia("");
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<AdvTextField
					name="name"
					label="Name"
					required
					errors={errors}
					inputRef={register({
						required: {
							message: "Name is required",
							value: true,
						},
					})}
				/>
			</Grid>
			<Grid item xs={12}>
				<Controller
					control={control}
					rules={{ required: { value: true, message: "Description is required" } }}
					name="description"
					render={({ onChange, value }) => <RichTextEditor placeholder="Description *" onChange={onChange} defaultValue={value} />}
				/>
			</Grid>
			<Grid item xs={12}>
				<Card style={{ padding: "0.5rem" }}>
					<Typography variant="subtitle1">Media *</Typography>
					<Grid container spacing={2} style={{ marginTop: 0 }}>
						<Grid item xs={12}>
							<Box border={2} style={{ borderStyle: "dashed", padding: "3rem 0", position: "relative" }} display="flex" flexDirection="column" alignItems="center">
								<Typography variant="h5">Drag and Drop/Select</Typography>
								<Typography variant="caption">Image or video file (.jpg, .png, .gif, .svg, .mp4. Max size: 20MB)</Typography>
								<CloudArrowUp size={50} />
								<input
									style={{ opacity: "0", position: "absolute", width: "100%", height: "100%", left: "0", right: "0", top: "0", zIndex: "9" }}
									id="nft-media-upload"
									type="file"
									accept="image/x-png,image/gif,image/jpeg,video/mp4"
									onChange={convertToBase64}
								/>
							</Box>
							{mediaError && (
								<Typography variant="caption" color="error">
									{mediaError}
								</Typography>
							)}
						</Grid>
						{media && (
							<Grid item xs={12} sm={2} container style={{ display: "flex", alignItems: "flex-start", justifyContent: "center", minHeight: "100px" }}>
								<ImageCloseIconContainer>
									<ImageCloseIcon>
										<div style={{ display: "flex", backgroundColor: "white", borderRadius: "50px" }}>
											<XCircle size={25} style={{ cursor: "pointer" }} color="red" onClick={removeMedia} />
										</div>
									</ImageCloseIcon>
								</ImageCloseIconContainer>
								{mediaType === "image" ? <img src={media} className="nft-card-image" style={{ height: "auto" }} /> : <video src={media} className="nft-card-image" style={{ height: "auto" }} />}
							</Grid>
						)}
					</Grid>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card style={{ padding: "0.5rem" }}>
					<Typography variant="subtitle1">Owner *</Typography>
					<Grid container spacing={2} style={{ marginTop: 0 }}>
						<Grid item xs={12}>
							<AdvTextField
								name="owner"
								label="Email"
								placeholder="Enter a valid email address"
								required
								errors={errors}
								inputRef={register({
									required: {
										message: "Owner email is required",
										value: true,
									},
									validate: (value) => (value ? (isValidEmail(value) ? true : "Enter a valid email") : true),
								})}
							/>
						</Grid>
						<Grid item xs={12}>
							<AdvTextField
								name="ownerName"
								label="Name"
								placeholder="Enter a valid name"
								required
								errors={errors}
								inputRef={register({
									required: {
										message: "Owner name is required",
										value: true,
									},
								})}
							/>
						</Grid>
					</Grid>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Button color="warning" onClick={handleSubmit(handleMintNft)}>
					Mint
				</Button>
			</Grid>
		</Grid>
	);
}

function isValidEmail(string) {
	const res = string.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
	return res !== null;
}

export default MintNft;
