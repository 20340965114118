import HeaderWithLine from "../HeaderWithLine";
import AdvFormControl from "../AdvFormControl";
import { Controller, useForm, useWatch } from "react-hook-form";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useNProgressHook from "../../hooks/useNProgressHook";
import { useSnackbar } from "notistack";
import ListOrgDbConnections from "./ListOrgDbConnections";
import { Box, Button } from "@cti-workspace/ui";
import OrgDropDown from "../OrgDropDown/OrgDropDown";
import { getErrMsg, updateOrganizationDatabaseConnection } from "@cti-workspace/helpers";

const UpdateOrgDatabaseConnection = ({ orgs, existingDbConnections, reloadOrgData }) => {
	const { handleSubmit, control, errors, reset } = useForm();
	const { progressStart, progressDone } = useNProgressHook();
	const { enqueueSnackbar } = useSnackbar();

	const updateOrgDatabaseConnection = async (form) => {
		progressStart();

		if (form.organizations !== 0) {
			try {
				await updateOrganizationDatabaseConnection(form);
				enqueueSnackbar(`Database connection updated.`, { variant: "success" });
				reset();
				progressDone();
				reloadOrgData();
			} catch (error) {
				progressDone();
				enqueueSnackbar(getErrMsg(error, "Failed Database connection update"), {
					variant: "error",
				});
			}
		}
	};

	const selectedOrdId = useWatch({
		control,
		name: "organizations",
		defaultValue: "",
	});

	return (
		<Box mx="auto">
			<HeaderWithLine title={"Add Organization Database Connection"} variant={"h6"} help={"admin.updateOrgDbConnection"} />
			<Box mx="auto" p={1}>
				<form onSubmit={handleSubmit(updateOrgDatabaseConnection)}>
					<div className="org-db-connection">
						<Controller name="organizations" control={control} rules={{ required: true }} render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => <OrgDropDown ref={ref} onChange={onChange} required />} />
						{/* <OrgAutoComplete dataList={orgsName} selectedOrgId={setSelectedOrgId} orgError={orgError} resetOrg={resetOrg}/> */}
					</div>
					<div style={{ height: 16 }} />
					<AdvFormControl errors={errors} name={"connections"}>
						<Controller
							as={
								<Select id="organizations-simple-select" fullWidth>
									{existingDbConnections.map((item) => (
										<MenuItem key={item._id} value={item._id}>
											{item.connectionName}
										</MenuItem>
									))}
								</Select>
							}
							name="connections"
							rules={{ required: "DB connection is required" }}
							control={control}
							defaultValue=""
						/>
					</AdvFormControl>
					<Box mx="auto" p={1} />
					<Button color="warning" id="updateDbConnection" type="submit" fullWidth={true}>
						Add Connection
					</Button>
				</form>
			</Box>
			<ListOrgDbConnections orgs={orgs} existingDbConnections={existingDbConnections} selectedOrdId={selectedOrdId} />
		</Box>
	);
};

export default UpdateOrgDatabaseConnection;
