import { createStyles, Theme } from "@material-ui/core/styles";

const Styles = (theme) =>
	createStyles({
		formContainer: {
			padding: 20,
			margin: 20,
		},
		tabSelected: {
			background: "#FFF2E5",
		},

		header: {
			color: "#fff",
			fontFamily: "Roboto",
			fontWeight: 600,
			letterSpacing: "4.2px",
		},

		logo: {
			paddingTop: 10,
			paddingBottom: 10,
			width: 150,
		},
		connectionDiv: {
			margin: 0,
			paddingTop: 20,
		},
		connectLink: {
			color: "#3A506B",
		},
		center: {
			marginTop: 70,
			// marginLeft: 'auto',
			// marginRight: 'auto',
			textAlign: "left",
			padding: theme.spacing(3),
			width: "100%"
		},
	});

export default Styles;
