// import AdvancedOptions from "./AdvancedOptions";
import FabricBuilder from "./FabricBuilder";
import MultiPageFabricDesigner from "./FabricBuilder/MultiPageFabricDesigner";
import { forwardRef, useEffect } from "react";
import { getObjectValue } from "@cti-workspace/helpers";
import snakeCase from "lodash/snakeCase";

const FabricCertBuilder = forwardRef(({ enableMultiPageCertificate, ...props }, ref) => {

	const { fabricCertDesigner: defaultFabricCertDesigner, rendererConfigId, transcripts = [] } = props?.control?.defaultValuesRef?.current ?? {};

	useEffect(() => {
		if (!rendererConfigId) return;
		const objectList = enableMultiPageCertificate ? Object.values(getObjectValue(defaultFabricCertDesigner, "payload", {})).filter(item => typeof item !== "string") : [defaultFabricCertDesigner];
		if (objectList.length <= 0) return;
		let variablesList = {};
		const transcriptsObj = (transcripts || []).reduce((acc, item) => ({
			...acc,
			[`${snakeCase(item.key)}${item.key?.split(" ").length > 1 ? "" : "_"}`]: item?.key?.toLowerCase()
		}), {});
		console.log(transcriptsObj);
		objectList.map((obj) => {
			getObjectValue(obj, "objects", []).map(item => {
				if (item.text?.startsWith("{{")) {
					const txt = item.text.replace(/[\])}[{(]/g, "");
					const val = transcriptsObj[txt];
					Object.assign(variablesList, { [val?.toLowerCase()]: true });
				} else if (item.type === "group") {
					getObjectValue(item, "objects", []).map(grp => {
						if (grp.text?.startsWith("{{")) {
							const txt = grp.text.replace(/[\])}[{(]/g, "");
							const val = transcriptsObj[txt];
							Object.assign(variablesList, { [val?.toLowerCase()]: true });
						}
					})
				}
			})
		})
		if (Object.keys(variablesList).length <= 0) return;
		props?.setFabricContentVariables((prevValue) => {
			return {
				...prevValue,
				content: {
					...(prevValue.content || {}),
					...variablesList
				}
			}
		});
	}, [rendererConfigId]);

	return (
		<div>
			{/* <AdvancedOptions /> */}
			{enableMultiPageCertificate ? <MultiPageFabricDesigner ref={ref} {...props} /> : <FabricBuilder ref={ref} {...props} />}
		</div>
	);
});

export default FabricCertBuilder;
