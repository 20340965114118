import { Button, Spinner } from "@cti-workspace/ui";
import { useState } from "react";
import styled from "styled-components";

const AlignCenterContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

const NftCard = ({ pending = false, data = {}, index = 0, onClickNFTRetry = () => {} }) => {
	const [imageLoaded, setImageLoaded] = useState(false);

	const handleClick = () => {
		window.open(`/nft/view?id=${data._id}`, "_blank");
	};

	const btnView = (status) => {
		status = status?.trim();
		switch (status) {
			case "Pending":
				return (
					<div className="nft-card-footer-btn-progress-container" title="Pending">
						<div className="progress-bar">
							<div className="progress-bar-value"></div>
						</div>
					</div>
				);
			case "Failed":
				return (
					<button className="nft-card-footer-btn-failed" onClick={() => onClickNFTRetry(data._id)}>
						RETRY
					</button>
				);
			default:
				return (
					<button className="nft-card-footer-btn" onClick={handleClick}>
						VIEW
					</button>
				);
		}
	};

	return (
		<div className="nft-card">
			<div className="nft-card-box">
				<div className="nft-card-header">
					<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
						#{index + 1} {data?.name}
						{data?.state?.trim() === "Failed" ? (
							<Button
								buttonType="icon"
								icon="warning"
								invert
								buttonTransparent
								noBorder
								iconSize="1.5rem"
								color="error"
								popover={
									<div>
										This NFT failed to be created. Please click “RETRY” below to give it another shot.
										<br />
										If the issue continues please reach out to support@globaledfoundation.com.
									</div>
								}
							/>
						) : null}
					</div>
				</div>
				<div className="nft-card-image-container">
					{!imageLoaded && (
						<AlignCenterContainer>
							<Spinner strokeWidth={2} />
						</AlignCenterContainer>
					)}
					{data?.fileExt === "mp4" ? (
						<video src={data?.imageUrl} className="nft-card-image" style={{ display: imageLoaded ? "block" : "none" }} preload="metadata" onLoadedMetadata={() => setImageLoaded(true)} playsInline />
					) : (
						<img src={data?.imageUrl} alt="sample image" className="nft-card-image" style={{ display: imageLoaded ? "block" : "none" }} onLoad={() => setImageLoaded(true)} />
					)}
				</div>
			</div>
			<div className="nft-card-footer">
				{btnView(data?.state)}
				{/* {!pending ? <button className="nft-card-footer-btn" onClick={handleClick}>VIEW</button> :
          <div className="nft-card-footer-btn-progress-container" title="Pending">
            <div className="progress-bar">
              <div className="progress-bar-value"></div>
            </div>
          </div>} */}
			</div>
		</div>
	);
};

export default NftCard;
