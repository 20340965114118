import { useEffect, useState } from "react";
import { fetchOrgAndCredDefCustomCredentials } from "@cti-workspace/helpers";
import { Card, ComboBox, Typography as Text } from "@cti-workspace/ui";

const SelectCustomCredential = ({ selectedCustomCredential, setSelectedCustomCredential, organizationId, readOnly = false, data = {} }) => {
  const [customCredentials, setCustomCredentials] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const allCustomCredentials = await fetchOrgAndCredDefCustomCredentials(organizationId);
      setCustomCredentials(allCustomCredentials);
    };
    if (organizationId != "") {
      fetchData();
    }
    if (readOnly) {
      console.log(data);
      setCustomCredentials(data);
    }
  }, [readOnly]);

  return (
    <Card hasBorder elevation={0} borderRadius="2px" style={{ backgroundColor: "#FAFAFA", padding: "0.8rem", width: "100%", margin: "0" }}>
      <Text type="body1">
        Select Custom Credential
      </Text>
      <ComboBox
        size="small"
        style={{ marginTop: "0.5rem" }}
        placeholder="None"
        options={[(!readOnly && { _id: "", name: "None" }), ...customCredentials].filter(item => item)}
        getOptionValue={(item) => item["_id"]}
        getOptionLabel={(item) => item.name}
        onChange={(e) => setSelectedCustomCredential(e["_id"])}
        value={[(!readOnly && { _id: "", name: "None" }), ...customCredentials].find(item => item["_id"] == selectedCustomCredential)}
      />
    </Card>
  );
};

export default SelectCustomCredential;
