import Button from "../button/button";
import Theme from "../theme/theme";
import styled, { StyleSheetManager } from "styled-components";
import Typography from "../typography/Typography";

const Wrapper = styled.div`
	border: 1px solid ${Theme.colors.gray1};
	border-radius: 2px;
	background-color: ${(props) => (props.isSecondary ? Theme.colors.backgroundSecondary : Theme.colors.background)};
`;

const TitleWrap = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${(props) => (props.isSecondary ? Theme.colors.gray2 : Theme.colors.gray)};
	padding: 10px;
	border: 1px solid ${Theme.colors.gray1};
	position: relative;
	width: calc(100% + 2px);
	margin-left: -1px;
	margin-top: -1px;
	border-radius: 2px;
`;

const Content = styled.div`
	padding: 10px;
`;

export const FieldWrapper = ({ title = "Set Title", onClose = () => {}, children = "Empty children", style = {}, contentStyle = {}, isSecondary = false, disableClosebtn, hideTitle = false }) => {
	return (
		<Wrapper style={style}>
			<TitleWrap style={{ display: hideTitle ? "none" : "flex" }} isSecondary={isSecondary}>
				<Typography type="body1">{title}</Typography>
				<Button disabled={disableClosebtn} buttonType="icon" icon="cancel" iconSize="0.8rem" buttonStyle={{ padding: "3px" }} onClick={onClose} />
			</TitleWrap>
			<Content isSecondary={isSecondary} style={contentStyle}>
				{children}
			</Content>
		</Wrapper>
	);
};

export default FieldWrapper;
