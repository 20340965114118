import { ADMIN_PREFIX } from "@cti-workspace/helpers";
import { getApi } from "./api";

export default async function fetchCustomCredentials(type = null, format = null) {
  let requestUrl = `/${ADMIN_PREFIX}/getAllCustomCredentials`;

  const queryParameters = {
    type,
    format,
  };
  let queryString;

  queryString = Object.entries(queryParameters)
    .filter((parameter) => parameter[1] !== null && parameter[1] !== "")
    .map(pair => pair.map(encodeURIComponent).join("="))
    .join("&");

  if (queryString) {
    requestUrl = `${requestUrl}?${queryString}`;
  }

  return await getApi(requestUrl);
}
