export const isContactValid = (contact) => {
	const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regExp.test(String(contact).toLowerCase());
};

export const isRowEmpty = (row) => row.every((cell) => cell === "" || cell === null || cell === undefined);

export const getParsedTranscriptValue = (transcriptValue, credential, key, index) => {
	const transcriptValueType = typeof transcriptValue;
	if (transcriptValueType === "undefined" || transcriptValue === null) {
		return null;
	} else if (transcriptValueType === "number" || transcriptValueType === "boolean") {
		return transcriptValue.toString();
	} else if (transcriptValueType === "string") {
		return transcriptValue.trim();
	} else {
		throw new Error(`${key} is an unsupported data type (${transcriptValueType}) in Row ${index + 2}. ${JSON.stringify(credential, null, 1)}`);
	}
};
