import { Dialog, DialogActions, DialogContent, NewCard, NewCardFooter, NewCardHeaderAction, Button as Btn, DialogTitle, Spinner, Icon } from "@cti-workspace/ui";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const AlignCenterContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	height: 70vh;
`;

const InfoMessage = styled.div`
	width: 100%;
	font-weight: 600;
	text-align: center;
	font-size: 1.25rem;
	color: #737373;
	min-height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Pathways = ({ email, data, isLoading, isFetching, error }) => {
	const navigate = useNavigate();

	const onCenterButtonClick = (id, userPathwayId) => {
		// console.log(userPathwayId);
		navigate(`/wallet/pathway/${id}/${userPathwayId}`, { replace: true });
	};

	if (isLoading)
		return (
			<AlignCenterContainer>
				<Spinner strokeWidth={2} />
			</AlignCenterContainer>
		);

	if (data?.acceptedPathways.length) {
		return data.acceptedPathways.map((val) => (
			<NewCard
				headerActionContent={<NewCardHeaderAction icon="no-icon" primary title="active" titleTooltip="Active Pathway" />}
				title={val.name}
				imageUrl={val.icon}
				bodyContent={<div />}
				showContentDivider={false}
				footerContent={<NewCardFooter leftButtonTitle="Delete" showLeft={false} centerButtonTitle="view" centerButtonPrimary onCenterButtonClick={() => onCenterButtonClick(val.id, val.userPathwayId)} />}
			/>
		));
	}

	return (
		<InfoMessage>
			<Icon name={"Info"} size={"2rem"} />
			There are no accepted Pathways.
		</InfoMessage>
	);
};

export default Pathways;
