import { createStyles, Theme } from "@material-ui/core/styles";

const Styles = (theme) =>
	createStyles({
		root: {
			flex: "1 1 100%",
		},
		toolbar: theme.mixins.toolbar,
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
		},
		table: {
			minWidth: 650,
		},
	});

export default Styles;
