/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import Link from "@material-ui/core/Link";
import { Button } from "@cti-workspace/ui";
import TextField from "@material-ui/core/TextField";

import ActionDialog from "../../components/ActionDialog";
import { ForgotPassword } from "@cti-workspace/helpers";

import { useSnackbar } from "notistack";

export default function ButtonLink({ whiteLabelID }) {
	const { enqueueSnackbar } = useSnackbar();

	const [openAlertDialog, setOpenAlertDialog] = useState(false);

	const [email, setemail] = useState("");

	const createAccount = async (whiteLabelID) => {
    if (email != "" && email != null) {
      try {
       await ForgotPassword(email, whiteLabelID);
       enqueueSnackbar(`Password Reset triggered. You will receive an email shortly `, {
          variant: "success",
        });
        setOpenAlertDialog(false);
     } catch (error) {
        if (error.type == 'RESOURCE_NOT_FOUND_ERROR') {
          enqueueSnackbar("Email not found", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        }
       // console.error(error.message);
      }
    }
    else {
      enqueueSnackbar(`Please enter a valid email address`, {
        variant: "error",
      });
    }
	};

	return (
		<>
			<ActionDialog
				open={openAlertDialog}
				title="Forgot Password"
				onClose={() => setOpenAlertDialog(false)}
				content={<TextField required style={{ width: "300px" }} id="forgot-email" label="Email" placeholder={"your.name@gmail.com"} onChange={(e) => setemail(e.target.value)} variant="outlined" />}
				primaryAction={
					whiteLabelID == "acn" ? (
						<Button
							onClick={() => {
								// forgot password fn
								createAccount(whiteLabelID);
							}}
							buttonStyle={{ backgroundColor: "#004E78", borderColor: "#004E78" }}
							variant={"contained"}>
							Submit
						</Button>
					) : (
						<Button
							onClick={() => {
								// forgot password fn
								createAccount();
							}}
							color="secondary"
							variant={"contained"}>
							Submit
						</Button>
					)
				}
				secondaryAction={
					<Button
						onClick={() => {
							setOpenAlertDialog(false);
							setemail("");
						}}
						color="white">
						No
					</Button>
				}
			/>
			{whiteLabelID == "acn" ? (
				<Link
					component="button"
					variant="body2"
					style={{ color: "#004E78" }}
					onClick={() => {
						setOpenAlertDialog(true);
					}}>
					Forgot Password
				</Link>
			) : (
				<Link
					component="button"
					variant="body2"
					color="secondary"
					onClick={() => {
						setOpenAlertDialog(true);
					}}>
					Forgot Password
				</Link>
			)}
		</>
	);
}
