import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import UserProfile from "../../components/UserProfile";
import useContactHook from "../../hooks/useContactHook";
import CAPABILITIES from "../../capabilities";
import { isCapable } from "../admin/userAccounts/permissionUtils";
import { Container } from "@cti-workspace/ui";

const ProfileRoute = ({ classes, organizations }) => {
	return (
		<Container className={classes.root}>
			<UserProfile organizations={organizations} />
		</Container>
	);
};

ProfileRoute.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default ProfileRoute;
