import styled from "styled-components";
import { Typography } from "@cti-workspace/ui";
import Theme from "../theme/theme";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const TabsContainer = styled.div``;

const TabsHeaderContainer = styled.div`
	display: flex;
	border-bottom: 1px solid ${Theme.colors.gray1};
`;

const TabHeader = styled.div`
	padding: 0.6rem 1.1rem;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	margin-bottom: -1px;
	border-color: ${Theme.colors.gray1} !important;
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
	opacity: ${({ disabled }) => (disabled ? "0.7" : "1")};
	& * {
		cursor: pointer;
		font-weight: bold;
		text-transform: uppercase;
		font-size: 0.9rem;
		${({ active, primaryColor }) => (active ? (primaryColor ? `color: ${primaryColor}` : `color: ${Theme.colors.purple}`) : `color: ${Theme.colors.gray1};`)}
	}
	${({ active, primaryColor }) =>
		active
			? primaryColor
				? `
		
        border-color: ${primaryColor} !important;
        background-color: ${primaryColor}10;
    `
				: `
		
        border-color: ${Theme.colors.purple} !important;
        background-color: ${Theme.colors.purple}10;
    `
			: ""}
`;

export function WalletTabs({ options = [], tabContainerStyles = {}, showHeader = true, primaryColor }) {
	const navigate = useNavigate();
	// loop through and extract pathname from each object
	// check current location this.
	// if matched highlight it.

	const location = useLocation();

	const matchParentRoute = (x, y) => {
		const myArray = x.split("/");
		const myArr = y.split("/");

		if (myArr.length === 2) {
			return myArr[1] === myArray[1];
		}
		return myArr[2] === myArray[0];
	};

	return (
		<TabsContainer>
			{showHeader && (
				<TabsHeaderContainer>
					{options.map((item, index) => (
						<TabHeader
							key={item.title}
							active={matchParentRoute(item.route, location.pathname)}
							disabled={item.disabled}
							primaryColor={primaryColor}
							style={{
								border: `1px solid`,
								...(options.length !== 1 && index > 0 && index < options.length
									? {
											borderLeft: `0.5px solid`,
											borderRight: `0.5px solid`,
									  }
									: {}),
								...(options.length !== 1 && index === 0 ? { borderRight: `0.5px solid` } : options.length !== 1 && index === options.length - 1 ? { borderLeft: `0.5px solid`, borderRight: `1px solid` } : {}),
							}}
							onClick={() => {
								if (item.disabled) return;
								if (matchParentRoute(item.route, location.pathname)) return;
								navigate(item.route, { replace: true });
							}}>
							<Typography type="subtitle" style={{ cursor: item.disabled ? "not-allowed" : "pointer" }}>
								{item.title}
							</Typography>
						</TabHeader>
					))}
				</TabsHeaderContainer>
			)}
			<div style={{ overflow: "hidden", display: "flex", width: "100%" }}>
				<Outlet />
			</div>
		</TabsContainer>
	);
}

export default WalletTabs;
