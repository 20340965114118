import styled from 'styled-components';

const CardHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
`;


const CardHeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
`;

const CardHeaderAvatar = styled.div``;

const CardHeaderTitle = styled.div`
    font-size: 0.8754rem;
    font-weight: 500;
    line-height: 1.43;
`;

const CardHeaderSubtitle = styled.div`
    font-size: 0.8754rem;
    font-weight: 500;
    line-height: 1.43;
    color: rgba(0, 0, 0, 0.54);
`;

export function CardHeader({ avatar, title, titleStyle = {}, subheader, subHeaderStyle = {}, ...props }) {
    return <CardHeaderContainer {...props}>
        {avatar && <CardHeaderAvatar>{avatar}</CardHeaderAvatar>}
        <CardHeaderContent>
            {title && <CardHeaderTitle style={titleStyle}>{title}</CardHeaderTitle>}
            {subheader && <CardHeaderSubtitle style={subHeaderStyle}>{subheader}</CardHeaderSubtitle>}
        </CardHeaderContent>
    </CardHeaderContainer>
}