import { cloneElement, Children, isValidElement } from "react";
import Theme from "../theme/theme";
import styled from "styled-components";

const RadioItem = styled.div`
	display: inline-block;
	position: relative;

	& input {
		&:checked + label:after {
			border-radius: 11px;
			width: 12px;
			height: 12px;
			position: absolute;
			top: 9px;
			left: 4px;
			content: " ";
			display: block;
			background: ${Theme.colors.purple};
		}

		&:checked + label:before {
			border: 2px solid ${Theme.colors.purple};
		}
	}
	& label {
		font-size: 0.9rem;
		&:before {
			content: " ";
			display: inline-block;
			position: relative;
			top: 5px;
			margin: 0 5px 0 0;
			width: 20px;
			height: 20px;
			border-radius: 11px;
			border: 2px solid ${Theme.colors.grey};
			background-color: transparent;
		}
	}
`;

export const RadioButton = ({ value, margin, label, name, selectedValue, onChange, labelStyle = {} }) => {
	return (
		<RadioItem margin={margin}>
			<input style={{ position: "absolute", opacity: "0", width: "30px", height: "30px", top: "-4px", left: "-4px", zIndex: "1" }} type="radio" name={name} checked={selectedValue === value} value={value} onClick={onChange}></input>
			<label style={labelStyle}>{label}</label>
		</RadioItem>
	);
};

export const RadioGroup = ({ style, name, children, value, onChange, direction = "column" }) => {
	const childrenWithProps = Children.map(children, (child) => {
		if (isValidElement(child)) {
			return cloneElement(child, { name, selectedValue: value, onChange });
		}
		return child;
	});

	return <div style={{ display: "flex", flexDirection: `${direction == "row" ? "row" : "column"}`, ...style }}>{childrenWithProps}</div>;
};
