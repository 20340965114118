import { useState } from "react";
import styled from "styled-components";
import { X, Upload } from "@phosphor-icons/react";
import { useSnackbar } from "notistack";
import { Button, Dialog, DialogContent, Typography } from "@cti-workspace/ui";
import { usePathwaysHook, useNProgressHook, getObjectValue } from "@cti-workspace/helpers";
import { fileUploadStates, getUploadedFileValidation, fileUploadStatesColors } from "../utils/inviteUtils";

const DialogTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const DialogContentWrapper = styled.div`
	padding: 0.5rem;
	min-width: 0;
	overflow-y: auto;
`;

const FooterButtonsContainer = styled.div`
	margin-top: 1rem;
	display: flex;
	justify-content: space-between;
`;

const FooterRightButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
`;

const FileUploadContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 0.6rem;
	height: 180px;
	border: ${(props) => (props.fileUploadState === 0 ? "2px dashed #757575" : "none")};
	border-radius: 0.3rem;
	align-items: center;
	justify-content: center;
	gap: 0.7rem;
	position: relative;
	background-color: ${(props) => getObjectValue(fileUploadStatesColors, props.fileUploadState)};
`;

const FileValidationMessage = styled.div`
	color: white;
	font-weight: bold;
	font-size: 1.2rem;
`;

const InviteMany = ({ issuingPathwayId, setIssuingPathwayId }) => {
	const [recipients, setRecipients] = useState([]);
	const [recipientFieldErrors, setRecipientFieldErrors] = useState([]);
	const [fileUploadState, setFileUploadState] = useState(fileUploadStates.notUploaded);

	const { enqueueSnackbar } = useSnackbar();
	const { progressStart, progressDone } = useNProgressHook();

	const { inviteToPathway } = usePathwaysHook();

	const onCloseModal = () => {
		setRecipientFieldErrors([]);
		setIssuingPathwayId(null);
		setFileUploadState(fileUploadStates.notUploaded);
	};

	const onFileUpload = async (e) => {
		progressStart();
		try {
			const fileValidation = await getUploadedFileValidation(e);
			if (typeof fileValidation === "string") {
				enqueueSnackbar(fileValidation, { variant: "error" });
				setFileUploadState(fileUploadStates.validationFailed);
			} else {
				setFileUploadState(fileUploadStates.validated);
				setRecipients(fileValidation);
			}
		} catch (e) {
			console.error(e);
		} finally {
			progressDone();
		}
	};

	const onUpload = async () => {
		setFileUploadState(fileUploadStates.uploading);
		try {
			await inviteToPathway(issuingPathwayId, recipients);
			enqueueSnackbar(`${recipients.length} recipients were successfully invited to the pathway.`, { variant: "success" });
			onCloseModal();
		} catch (e) {
			setFileUploadState(fileUploadStates.uploadingFailed);
			enqueueSnackbar(`Failed to invite the selected recipients${e.message ? `. ${e.message}` : ""}. Please try again.`, { variant: "error" });
		}
	};

	return (
		<Dialog onClose={onCloseModal} open={!!issuingPathwayId} maxWidth="sm" borderRadius="0.8rem">
			<DialogContentWrapper>
				<DialogContent>
					<DialogTitle>
						<Typography type="subtitle">Invite Many</Typography>
						<X size={20} color="#757575" style={{ cursor: "pointer" }} onClick={onCloseModal} />
					</DialogTitle>
					<Typography type="secondary">Supported file types: .xlsx</Typography>
					<FileUploadContainer fileUploadState={fileUploadState}>
						<input
							style={{
								opacity: "0",
								position: "absolute",
								width: "100%",
								height: "100%",
								left: "0",
								right: "0",
								top: "0",
								zIndex: "9",
							}}
							id="invite-many-xls-upload"
							multiple
							type="file"
							onChange={onFileUpload}
							accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						/>
						{fileUploadState === fileUploadStates.notUploaded && <Typography type="secondary">Drag n Drop / Select from Device</Typography>}
						{fileUploadState === fileUploadStates.validated && <FileValidationMessage>File validation successful. Please upload.</FileValidationMessage>}
						{fileUploadState === fileUploadStates.validationFailed && <FileValidationMessage>File validation failed. Please check for errors.</FileValidationMessage>}
						{fileUploadState === fileUploadStates.uploading && <FileValidationMessage>The file is being uploaded.</FileValidationMessage>}
						{fileUploadState === fileUploadStates.uploadingFailed && <FileValidationMessage>File upload failed.</FileValidationMessage>}
						<div>
							<Upload size={36} weight="fill" color={fileUploadState === fileUploadStates.notUploaded ? "#757575" : "#FFFFFF"} />
						</div>
					</FileUploadContainer>
					<FooterButtonsContainer>
						<div>
							<a href="https://s3.ca-central-1.amazonaws.com/trybe.public.assets/Trybe.ID/Pathway+Invites+-+Template.xlsx">
								<Button leftIcon="xls" color="secondary" size="small" invert buttonStyle={{ width: "160px", height: "34px", borderRadius: "0.4rem" }}>
									Template
								</Button>
							</a>
						</div>
						<FooterRightButtonsContainer>
							<Button invert color="borderedBlack" onClick={onCloseModal} buttonStyle={{ height: "34px", borderRadius: "0.4rem" }}>
								Cancel
							</Button>
							<Button onClick={onUpload} disabled={fileUploadState !== fileUploadStates.validated && fileUploadState !== fileUploadStates.uploadingFailed} color="grey" buttonStyle={{ height: "34px", borderRadius: "0.4rem" }}>
								Upload
							</Button>
						</FooterRightButtonsContainer>
					</FooterButtonsContainer>
				</DialogContent>
			</DialogContentWrapper>
		</Dialog>
	);
};

export default InviteMany;
