import { useState } from "react";
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export default function PasswordField({ handleChange, value, id, label, required, labelWidth, placeholder }) {
	const [showPassword, setShowPassword] = useState(false);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	const handleClickShowPassword = () => {
		if (!showPassword) {
			setShowPassword(true);
		} else {
			setShowPassword(false);
		}
	};
	return (
		<FormControl fullWidth margin="normal" variant="outlined">
			<InputLabel htmlFor={id}>
				{label} {required ? "*" : ""}
			</InputLabel>
			<OutlinedInput
				id={id}
				type={showPassword ? "text" : "password"}
				value={value}
				onChange={handleChange}
				placeholder={placeholder}
				endAdornment={
					<InputAdornment position="end">
						<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
							{showPassword ? <Visibility /> : <VisibilityOff />}
						</IconButton>
					</InputAdornment>
				}
				labelWidth={labelWidth || 70}
			/>
		</FormControl>
	);
}
