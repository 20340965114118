import pathwayIcon from "./pathway-icon.svg";
import styled from "styled-components";

const PathwayImg = styled.img`
	max-width: 100%;
`;

const PathwayIcon = () => {
	return <PathwayImg src={pathwayIcon} alt="Pathway Icon" />;
};

export default PathwayIcon;
