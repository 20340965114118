import { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import HeaderWithLine from "../HeaderWithLine";
import AdvTextField from "../AdvTextField";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Button } from "@cti-workspace/ui";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useNProgressHook from "../../hooks/useNProgressHook";
import { useSnackbar } from "notistack";
import { Editor, Button as Btn, Container } from "@cti-workspace/ui";
import { CaretRight } from "@phosphor-icons/react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";
import { getErrMsg, getObjectValue, hasProperty, addCustomCredential } from "@cti-workspace/helpers";

const CreateNewCustomCredential = ({ getCustomCredentials }) => {
	const { register, handleSubmit, errors, reset, control } = useForm({
		defaultValues: {
			context: "{}",
		},
	});
	const { progressStart, progressDone } = useNProgressHook();
	const { enqueueSnackbar } = useSnackbar();
	const [enabled, setEnabled] = useState(true);
	const [resetCredentialMappingArray, setResetCredentialMappingArray] = useState(false);

	const onCreateCustomCredential = async (form) => {
		// console.log(form);
		progressStart();
		let data = {
			name: (form.name || "").trim(),
			contextURL: (form.contextURL || "").trim(),
			type: (form.type || "").trim(),
			format: form.format || undefined,
			enabled: enabled,
		};
		try {
			try {
				const context = form.context;
				const str = context.replace(/(\r\n|\n|\r|\t)/gm, "");
				data.context = JSON.parse(str);
			} catch (err) {
				enqueueSnackbar(getErrMsg(null, "Invalid Context JSON Format"), {
					variant: "error",
				});
				progressDone();
				return;
			}
			data.credentialMappings = form.credentialMappings || [];
			await addCustomCredential(data);
			getCustomCredentials();
			enqueueSnackbar(`New Custom Credential added.`, { variant: "success" });
			reset({
				type: "",
			});
			setResetCredentialMappingArray(true);
			setEnabled(true);
		} catch (error) {
			enqueueSnackbar("Failed to add new Custom Credential", {
				variant: "error",
			});
		} finally {
			progressDone();
		}
	};

	return (
		<Box mx="auto" p={-2}>
			<HeaderWithLine title={"Add New Custom Credential"} variant={"h6"} help={"admin.addNewEmailTemplate"} />
			<Box mx="auto" p={1}>
				<form onSubmit={handleSubmit(onCreateCustomCredential)}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<AdvTextField
								name="name"
								errors={errors}
								inputRef={register({
									required: "Custom Credential Name  is required",
								})}
								placeholder={"Custom Credential Name"}
								label="Custom Credential Name"
							/>
						</Grid>
						<Grid item xs={12}>
							<AdvTextField
								name="contextURL"
								errors={errors}
								inputRef={register({
									required: "Custom Credential Context URL is required",
								})}
								placeholder={"Custom Credential URL Context"}
								label="Custom Credential URL Context"
							/>
						</Grid>
						<Grid item xs={12}>
							<AdvTextField
								name="type"
								errors={errors}
								inputRef={register({
									required: "Custom Credential Type is required",
								})}
								placeholder={"Custom Credential Type"}
								label="Custom Credential Type"
							/>
						</Grid>
						<Grid item xs={12}>
							<ControllerSelectOption
								control={control}
								name="format"
								placeholder="Custom Credential Format"
								label="Custom Credential Format"
								size="large"
								options={[
									{ label: "w3cvc-jsonld", value: "w3cvc-jsonld" },
									{ label: "w3cvc-cborld", value: "w3cvc-cborld" },
								]}
								required
								errors={errors}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller control={control} name="context" render={(field) => <Editor language="application/json" displayName="Custom Credential Context" {...field} />} />
						</Grid>
						<Grid item xs={12}>
							<CustomCredentialMapping control={control} errors={errors} resetCredentialMappingArray={resetCredentialMappingArray} setResetCredentialMappingArray={setResetCredentialMappingArray} />
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel value="defa3ult" control={<Switch checked={enabled} color="primary" onChange={(e) => setEnabled(e.target.checked)} />} label="Enabled" labelPlacement="start" />
						</Grid>
						<Grid item xs={12}>
							<Button color="warning" id="createNewEmailTemplate" type="submit" fullWidth={true}>
								Create Custom Credential
							</Button>
						</Grid>
					</Grid>
				</form>
			</Box>
		</Box>
	);
};

function CustomCredentialMapping({ control = null, errors = {}, resetCredentialMappingArray = false, setResetCredentialMappingArray = () => {} }) {
	const { fields, append, remove } = useFieldArray({ name: "credentialMappings", control });

	useEffect(() => {
		if (resetCredentialMappingArray) {
			remove();
			setResetCredentialMappingArray(false);
		}
	}, [resetCredentialMappingArray]);

	const addNewRow = () => {
		append({
			credDefContentName: "",
			credDefContentDefaultValue: "",
			credSubjectKey: "",
			credSubjectType: "string",
			required: false,
		});
	};

	return (
		<div>
			<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "1rem" }}>
				<Typography variant="subtitle1">Custom Credential Content Mapping</Typography>
				<Btn size="small" leftIcon="plus" color="white" fullWidth={false} onClick={addNewRow}>
					Add
				</Btn>
			</div>
			<Container maxWidth="md">
				<Grid container spacing={2}>
					<Grid item xs={12} sm={3}>
						Content Name
					</Grid>
					<Grid item xs={12} sm={1}></Grid>
					<Grid item xs={12} sm={3}>
						Subject key
					</Grid>
					{/* Content key is used for form building */}
					<Grid item xs={12} sm={2}>
						Subject Type
					</Grid>
					<Grid item xs={12} sm={2} container justifyContent="center">
						Required
					</Grid>
					<Grid item xs={12} sm={1}></Grid>
					{fields.map((item, i) => (
						<CustomCredentialMappingInputs key={item.id} name="credentialMappings" index={i} control={control} {...item} onClickCancel={() => remove(i)} errors={errors} />
					))}
				</Grid>
			</Container>
		</div>
	);
}

function CustomCredentialMappingInputs({ onClickCancel = () => {}, control, name = "", index, errors = {} }) {
	const credMappingSubjectType = [
		{ label: "String", value: "string" },
		{ label: "Image", value: "image" },
	];
	return (
		<>
			<Grid item xs={12} sm={3}>
				<ControllerTextField control={control} name={`${name}[${index}].credDefContentName`} errors={errors} placeholder="Content Name" required errorMessage="Value Required" />
			</Grid>
			<Grid item xs={12} sm={1} container alignItems="flex-start" justifyContent="center" style={{ paddingTop: "20px" }}>
				<ControllerTextField control={control} name={`${name}[${index}].credDefContentDefaultValue`} defaultValue={""} visible={false} />
				<CaretRight />
				<CaretRight />
				<CaretRight />
			</Grid>
			<Grid item xs={12} sm={3}>
				<ControllerTextField control={control} name={`${name}[${index}].credSubjectKey`} errors={errors} placeholder="Content Key" required errorMessage="Value Required" />
			</Grid>
			<Grid item xs={12} sm={2}>
				<ControllerSelectOption name={`${name}[${index}].credSubjectType`} options={credMappingSubjectType} control={control} placeholder="Type" defaultValue="string" />
			</Grid>
			<Grid item xs={12} sm={2} container alignItems="center" justifyContent="center">
				<Controller control={control} name={`${name}[${index}].required`} defaultValue={false} render={(field) => <Switch {...field} onChange={(e) => field.onChange(e.target.checked)} checked={field.value} color="primary" />} />
			</Grid>
			<Grid item xs={12} sm={1} container alignItems="center" justifyContent="center">
				<Btn buttonType="icon" icon="cancel" color="danger" onClick={onClickCancel} />
			</Grid>
		</>
	);
}

export function ControllerTextField({ control, name, errors, placeholder, label, required = false, defaultValue = "", errorMessage = "", visible = true, ...props }) {
	return (
		<Controller
			control={control}
			name={name}
			rules={{ required: { value: required, message: "Value Required" } }}
			defaultValue={defaultValue}
			render={(field) => <AdvTextField {...(!visible ? { style: { display: "none" } } : {})} size="small" {...field} name={name} errors={errors} placeholder={placeholder} label={label} {...props} />}
		/>
	);
}

export function ControllerSelectOption({ control, name, defaultValue = "", placeholder, options = [], label = "", size = "small", required = false, errors = {} }) {
	return (
		<Controller
			control={control}
			name={name}
			defaultValue={defaultValue}
			rules={{ required: { value: required, message: label ? `${label} is required` : "Value Required" } }}
			render={(field) => (
				<FormControl fullWidth size={size} variant="outlined" error={hasProperty(errors, name)}>
					{label ? <InputLabel>{label}</InputLabel> : null}
					<Select {...field} placeholder={placeholder} fullWidth {...(label ? { label } : {})}>
						{options.map((item, i) => (
							<MenuItem key={i} value={item.value}>
								{item.label}
							</MenuItem>
						))}
					</Select>
					{getObjectValue(errors, `${name}.message`) && <FormHelperText>{getObjectValue(errors, `${name}.message`)}</FormHelperText>}
				</FormControl>
			)}
		/>
	);
}

export default CreateNewCustomCredential;
