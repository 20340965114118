module.exports = {
	Drawer_Width: 240,

	ADAPTER_URL: process.env.NX_ADAPTER_URL || "http://localhost:8000",
	API_URL: process.env.NX_API_URL || "http://localhost:3002",
	DEPLOYED_ENV: process.env.NX_DEPLOYED_ENV || "",
	VCX_API_KEY: process.env.NX_VCX_API_KEY || "f8bafaff-0b5b-4995-b3a1-8cc321068747",
	ADAPTER_AUTHORIZATION_HEADER: "adapter-authorization",
  GOOGLE_CLIENT_ID: process.env.NX_GOOGLE_CLIENT_ID || null,

  GLOBALED_URL: process.env.NX_GLOBALED_URL || "https://globaledfoundation.com",
	GLOBALED_ORG_TOKEN: "zutd0oFv-zL4t-h5oG-Rpo5-yK7WXKPoCtiY",
  GLOBALED_PAYLOAD_VERIFICATION_KEY: "convergence_GX4USGnx4IuSmyND0kuwHUzI", // Not currently used in this app. But is used in the GlobalEd plug-in when generating JWT

  MENA_URL: process.env.NX_MENA_URL || "https://globaledmena.com",
  MENA_ORG_TOKEN: "018eed91-a550-7328-ac29-d827b5f3ede8",
  MENA_PAYLOAD_VERIFICATION_KEY: "ipwru_KpQSNLgWBQzFrRPmXHbvGEKU", // Not currently used
  ALLOW_USER_DELETION : process.env.NX_ALLOW_USER_DELETION || true,

  SSO_PAYLOAD_VERIFICATION_KEY: "ipwru_KpQSNLgWBQzFrRPmXHbvGEKU-yK7WXKPoCtiY",

	// ROUTES
	ADMIN_PREFIX: "admin",
	AUTH_PREFIX: "auth",
	ACCOUNTS_PREFIX: "accounts",
	ASSETS_PREFIX: "assets",
	PUBLIC_PREFIX: "public",
	ORGANIZATIONS_PREFIX: "organizations",
	LOGIN_ROUTE: "login",
	REGISTER_ROUTE: "register",
	ISSUER_PREFIX: "issuer",
	ADAPTER_PREFIX: "adapter",
	INDY_PREFIX: "agent",
	ADD_CREDENTIAL_ROUTE: "addCredentialDefinition",
	REVOKE_CERT_ROUTE: "revokeCert",
	CREATE_CONNECTION_INVITATION_ROUTE: "createConnectionInvitation",
	ISSUE_AND_PUBLISH_CERTIFICATES_ROUTE: "issueAndPublishCertificates",
	PUBLIC_CERT_URL: "https://vc.convergence.tech?",
	GOOGLE_CLASSROOM_EMAIL: "googleClassroomEmail",
	CANVAS_EMAIL: "canvasEmail",
	TCSION_EMAIL: "tcsIONEmail",
	MOODLE_EMAIL: "moodleEmail",
	SCHOOLOGY_EMAIL: "schoologyEmail",
	BRIDGE_EMAIL: "bridgeEmail",
	BRIGHTSPACE_USER: "brightspaceUser",
	PRESENTATION_PREFIX: "presentation",
	PATHWAY_PREFIX: "pathway",
	CREDENTIAL_PREFIX: "credential",
	AUTHORIZATIONS_PREFIX: "authorization", 

	// CLOUDINARY
	IMAGE_FETCH_PREFIX: "https://res.cloudinary.com/dadlqfryt/image/fetch/w_60/",
	DEFAULT_CREDENTIAL_ICON: "https://trybe.id/icon",
	GOLD_BADGE_URL: "https://i.imgur.com/lPhPYVh.png",
	DEFAULT_ICON_NAME: "Default Gold Badge",

	// SESSION STORAGE
	JWT_TOKEN: "jwtToken",
	ACCOUNT: "account",
	EMAIL: "email",
	IS_VERIFIED_TEACHER: "verifiedTeacher",
	DEFAULT_ORG: "setDefaultOrg",
	MULTI_ORGS: "multiOrgs",
	SCHOOLOGYTOKEN: "",
	SCHOOLOGYSECRET: "",
	ICONS: "icons",
	SETTINGS: "settings",
	BATCH_LABELS: "batchLabels",
	PDF_EMAIL_TEMPLATES: "pdf_email_templates",
	REVOKED_LIST: "revokedList",
	DEFAULT_EMAIL: "defaultEmail",
	USER_ALTERNATE_EMAILS: "alternateEmails",
	PREV_ROUTE: "prevRoute",
	SORT_OPTION: "sortOption",

	// PAGES
	PROFILE_PAGE: "/profile",
	LOGIN_PAGE: "/login",

	EMAIL_TEMPLATE_ID: "template_v3Vfl4iK",
	EMAIL_USER_ID: "user_iCSzqx9VWkkdGOT8bugWT",

	JWT_PUB:
		process.env.NX_JWT_PUB ||
		`-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvdxJWmRAWz/XffOyp13R\nj6ZkMGwGsCg8s40hx8lmZs6fP05WaxHwzdkEJtq5W25c3Ll21Zg8CI5bpk6VAf/f\npR3BEbTsCcOFOjuO0+6JOy1vpJxYNkqhP4sYxmY8DNf0TifMlSsGGsnLIuD4u415\nrt6Vr2dVXFGC19iGMoijrIT7n7oa7zT280W2A0+qpKllATZO+Tz+rEZW1e+1CC23\n46xtxW7otAaAJbzSQKdkzLGbq9nWCVxv8O8JCib14sxlGU1CPrK5QhTd5d0md32B\n2KeElwGds8r6NYSfNvvkzDg4Lvkm2dadvXTP2NxX242yN4xurujwSQtxIHBuQ0qm\nGQIDAQAB\n-----END PUBLIC KEY-----`,
	ALGORITHM: "RS512",

	// CREDENTIALS
	URL: "url",
	IMAGE: "image",
	PDF: "pdf",
	CHOOSE_BADGE: "Choose Badge",
	IMAGE_CONTENT: "IMAGE_CONTENT",
	MAX_FILE_UPLOAD_SIZE_IN_KB: 1024,

	// SHARING PDF SETTINGS
	ISSUE_CREDENTIALS: "ISSUE_CREDENTIALS",
	CREDENTIAL_DEFINITIONS: "CREDENTIAL_DEFINITIONS",
	ORG: "ORG",

	// ANALYTICS
	ISSUED: "Issued",

	// QR SCANNER
	FACING_MODES: {
		USER: "user",
		ENVIRONMENT: "environment",
	},

	// USER ROLES
	USER_ROLES: {
		HOLDER: "holder",
		ISSUER: "issuer",
		ORG_ADMIN: "orgAdmin",
	},

	// BULK ISSUE LIMITS
	BULK_ISSUE_LIMITS: {
		WITH_IMAGES: process.env?.NX_BULK_ISSUE_IMAGE_LIMIT ? parseFloat(process.env.NX_BULK_ISSUE_IMAGE_LIMIT) : 99,
		WITHOUT_IMAGES: process.env?.NX_BULK_ISSUE_WIHTOUT_IMAGE_LIMIT ? parseFloat(process.env.NX_BULK_ISSUE_WIHTOUT_IMAGE_LIMIT) : 99,
	},

	// PROFILE
	VERIFICATION_CODE_STATUS: Object.freeze({
		PENDING: "PENDING",
		VERIFIED: "VERIFIED",
		EXPIRED: "EXPIRED",
	}),
	FABRIC_PORTRAIT: 0.7727,
	FABRIC_LANDSCAPE: 1.294,

	FABRIC_DIMENSIONS: Object.freeze({
		US_LETTER_PORTRAIT: 0.7727,
		US_LETTER_LANDSCAPE: 1.294,
		A4_PORTRAIT: 0.707,
		A4_LANDSCAPE: 1.414516129,
		A4_PORTRAIT_OLD: 0.752,
		A4_LANDSCAPE_OLD: 1.328,
	}),

	SORT_QUERY_OPTIONS: Object.freeze({
		atoz: "%5B%7B%22field%22%3A%22pinned%22%2C%20%22order%22%3A%22desc%22%7D,%7B%22field%22%3A%22name%22%2C%20%22order%22%3A%22asc%22%7D%5D",
		ztoa: "%5B%7B%22field%22%3A%22pinned%22%2C%20%22order%22%3A%22desc%22%7D,%7B%22field%22%3A%22name%22%2C%20%22order%22%3A%22desc%22%7D%5D",
		modifyFirst: "%5B%7B%22field%22%3A%22pinned%22%2C%20%22order%22%3A%22desc%22%7D,%7B%22field%22%3A%22updatedOn%22%2C%20%22order%22%3A%22desc%22%7D%5D",
		modifyLast: "%5B%7B%22field%22%3A%22pinned%22%2C%20%22order%22%3A%22desc%22%7D,%7B%22field%22%3A%22updatedOn%22%2C%20%22order%22%3A%22asc%22%7D%5D",
		default: "%5B%7B%22field%22%3A%22pinned%22%2C%20%22order%22%3A%22desc%22%7D,%7B%22field%22%3A%22_id%22%2C%20%22order%22%3A%22desc%22%7D%5D",
	}),

	FABRIC_PAGE_TYPES: Object.freeze({
		us_letter: "US Letter",
		a4: "A4",
	}),
	FABRIC_PAGE_ORIENTATION_TYPES: Object.freeze({
		landscape: "Landscape",
		portrait: "Portrait",
	}),
	CONTENT_TYPE: Object.freeze({
		TEXT: "TEXT",
		NUMBER: "NUMBER",
		DATE: "DATE",
		IMAGE: "IMAGE",
		HTML: "HTML",
	}),
	TIMEZONE_LIST: Object.freeze([
		"Africa/Abidjan",
		"Africa/Accra",
		"Africa/Addis_Ababa",
		"Africa/Algiers",
		"Africa/Asmara",
		"Africa/Asmera",
		"Africa/Bamako",
		"Africa/Bangui",
		"Africa/Banjul",
		"Africa/Bissau",
		"Africa/Blantyre",
		"Africa/Brazzaville",
		"Africa/Bujumbura",
		"Africa/Cairo",
		"Africa/Casablanca",
		"Africa/Ceuta",
		"Africa/Conakry",
		"Africa/Dakar",
		"Africa/Dar_es_Salaam",
		"Africa/Djibouti",
		"Africa/Douala",
		"Africa/El_Aaiun",
		"Africa/Freetown",
		"Africa/Gaborone",
		"Africa/Harare",
		"Africa/Johannesburg",
		"Africa/Juba",
		"Africa/Kampala",
		"Africa/Khartoum",
		"Africa/Kigali",
		"Africa/Kinshasa",
		"Africa/Lagos",
		"Africa/Libreville",
		"Africa/Lome",
		"Africa/Luanda",
		"Africa/Lubumbashi",
		"Africa/Lusaka",
		"Africa/Malabo",
		"Africa/Maputo",
		"Africa/Maseru",
		"Africa/Mbabane",
		"Africa/Mogadishu",
		"Africa/Monrovia",
		"Africa/Nairobi",
		"Africa/Ndjamena",
		"Africa/Niamey",
		"Africa/Nouakchott",
		"Africa/Ouagadougou",
		"Africa/Porto-Novo",
		"Africa/Sao_Tome",
		"Africa/Timbuktu",
		"Africa/Tripoli",
		"Africa/Tunis",
		"Africa/Windhoek",
		"America/Adak",
		"America/Anchorage",
		"America/Anguilla",
		"America/Antigua",
		"America/Araguaina",
		"America/Argentina/Buenos_Aires",
		"America/Argentina/Catamarca",
		"America/Argentina/ComodRivadavia",
		"America/Argentina/Cordoba",
		"America/Argentina/Jujuy",
		"America/Argentina/La_Rioja",
		"America/Argentina/Mendoza",
		"America/Argentina/Rio_Gallegos",
		"America/Argentina/Salta",
		"America/Argentina/San_Juan",
		"America/Argentina/San_Luis",
		"America/Argentina/Tucuman",
		"America/Argentina/Ushuaia",
		"America/Aruba",
		"America/Asuncion",
		"America/Atikokan",
		"America/Atka",
		"America/Bahia",
		"America/Bahia_Banderas",
		"America/Barbados",
		"America/Belem",
		"America/Belize",
		"America/Blanc-Sablon",
		"America/Boa_Vista",
		"America/Bogota",
		"America/Boise",
		"America/Buenos_Aires",
		"America/Cambridge_Bay",
		"America/Campo_Grande",
		"America/Cancun",
		"America/Caracas",
		"America/Catamarca",
		"America/Cayenne",
		"America/Cayman",
		"America/Chicago",
		"America/Chihuahua",
		"America/Ciudad_Juarez",
		"America/Coral_Harbour",
		"America/Cordoba",
		"America/Costa_Rica",
		"America/Creston",
		"America/Cuiaba",
		"America/Curacao",
		"America/Danmarkshavn",
		"America/Dawson",
		"America/Dawson_Creek",
		"America/Denver",
		"America/Detroit",
		"America/Dominica",
		"America/Edmonton",
		"America/Eirunepe",
		"America/El_Salvador",
		"America/Ensenada",
		"America/Fort_Nelson",
		"America/Fort_Wayne",
		"America/Fortaleza",
		"America/Glace_Bay",
		"America/Godthab",
		"America/Goose_Bay",
		"America/Grand_Turk",
		"America/Grenada",
		"America/Guadeloupe",
		"America/Guatemala",
		"America/Guayaquil",
		"America/Guyana",
		"America/Halifax",
		"America/Havana",
		"America/Hermosillo",
		"America/Indiana/Indianapolis",
		"America/Indiana/Knox",
		"America/Indiana/Marengo",
		"America/Indiana/Petersburg",
		"America/Indiana/Tell_City",
		"America/Indiana/Vevay",
		"America/Indiana/Vincennes",
		"America/Indiana/Winamac",
		"America/Indianapolis",
		"America/Inuvik",
		"America/Iqaluit",
		"America/Jamaica",
		"America/Jujuy",
		"America/Juneau",
		"America/Kentucky/Louisville",
		"America/Kentucky/Monticello",
		"America/Knox_IN",
		"America/Kralendijk",
		"America/La_Paz",
		"America/Lima",
		"America/Los_Angeles",
		"America/Louisville",
		"America/Lower_Princes",
		"America/Maceio",
		"America/Managua",
		"America/Manaus",
		"America/Marigot",
		"America/Martinique",
		"America/Matamoros",
		"America/Mazatlan",
		"America/Mendoza",
		"America/Menominee",
		"America/Merida",
		"America/Metlakatla",
		"America/Mexico_City",
		"America/Miquelon",
		"America/Moncton",
		"America/Monterrey",
		"America/Montevideo",
		"America/Montreal",
		"America/Montserrat",
		"America/Nassau",
		"America/New_York",
		"America/Nipigon",
		"America/Nome",
		"America/Noronha",
		"America/North_Dakota/Beulah",
		"America/North_Dakota/Center",
		"America/North_Dakota/New_Salem",
		"America/Nuuk",
		"America/Ojinaga",
		"America/Panama",
		"America/Pangnirtung",
		"America/Paramaribo",
		"America/Phoenix",
		"America/Port-au-Prince",
		"America/Port_of_Spain",
		"America/Porto_Acre",
		"America/Porto_Velho",
		"America/Puerto_Rico",
		"America/Punta_Arenas",
		"America/Rainy_River",
		"America/Rankin_Inlet",
		"America/Recife",
		"America/Regina",
		"America/Resolute",
		"America/Rio_Branco",
		"America/Rosario",
		"America/Santa_Isabel",
		"America/Santarem",
		"America/Santiago",
		"America/Santo_Domingo",
		"America/Sao_Paulo",
		"America/Scoresbysund",
		"America/Shiprock",
		"America/Sitka",
		"America/St_Barthelemy",
		"America/St_Johns",
		"America/St_Kitts",
		"America/St_Lucia",
		"America/St_Thomas",
		"America/St_Vincent",
		"America/Swift_Current",
		"America/Tegucigalpa",
		"America/Thule",
		"America/Thunder_Bay",
		"America/Tijuana",
		"America/Toronto",
		"America/Tortola",
		"America/Vancouver",
		"America/Virgin",
		"America/Whitehorse",
		"America/Winnipeg",
		"America/Yakutat",
		"America/Yellowknife",
		"Antarctica/Casey",
		"Antarctica/Davis",
		"Antarctica/DumontDUrville",
		"Antarctica/Macquarie",
		"Antarctica/Mawson",
		"Antarctica/McMurdo",
		"Antarctica/Palmer",
		"Antarctica/Rothera",
		"Antarctica/South_Pole",
		"Antarctica/Syowa",
		"Antarctica/Troll",
		"Antarctica/Vostok",
		"Arctic/Longyearbyen",
		"Asia/Aden",
		"Asia/Almaty",
		"Asia/Amman",
		"Asia/Anadyr",
		"Asia/Aqtau",
		"Asia/Aqtobe",
		"Asia/Ashgabat",
		"Asia/Ashkhabad",
		"Asia/Atyrau",
		"Asia/Baghdad",
		"Asia/Bahrain",
		"Asia/Baku",
		"Asia/Bangkok",
		"Asia/Barnaul",
		"Asia/Beirut",
		"Asia/Bishkek",
		"Asia/Brunei",
		"Asia/Calcutta",
		"Asia/Chita",
		"Asia/Choibalsan",
		"Asia/Chongqing",
		"Asia/Chungking",
		"Asia/Colombo",
		"Asia/Dacca",
		"Asia/Damascus",
		"Asia/Dhaka",
		"Asia/Dili",
		"Asia/Dubai",
		"Asia/Dushanbe",
		"Asia/Famagusta",
		"Asia/Gaza",
		"Asia/Harbin",
		"Asia/Hebron",
		"Asia/Ho_Chi_Minh",
		"Asia/Hong_Kong",
		"Asia/Hovd",
		"Asia/Irkutsk",
		"Asia/Istanbul",
		"Asia/Jakarta",
		"Asia/Jayapura",
		"Asia/Jerusalem",
		"Asia/Kabul",
		"Asia/Kamchatka",
		"Asia/Karachi",
		"Asia/Kashgar",
		"Asia/Kathmandu",
		"Asia/Katmandu",
		"Asia/Khandyga",
		"Asia/Kolkata",
		"Asia/Krasnoyarsk",
		"Asia/Kuala_Lumpur",
		"Asia/Kuching",
		"Asia/Kuwait",
		"Asia/Macao",
		"Asia/Macau",
		"Asia/Magadan",
		"Asia/Makassar",
		"Asia/Manila",
		"Asia/Muscat",
		"Asia/Nicosia",
		"Asia/Novokuznetsk",
		"Asia/Novosibirsk",
		"Asia/Omsk",
		"Asia/Oral",
		"Asia/Phnom_Penh",
		"Asia/Pontianak",
		"Asia/Pyongyang",
		"Asia/Qatar",
		"Asia/Qostanay",
		"Asia/Qyzylorda",
		"Asia/Rangoon",
		"Asia/Riyadh",
		"Asia/Saigon",
		"Asia/Sakhalin",
		"Asia/Samarkand",
		"Asia/Seoul",
		"Asia/Shanghai",
		"Asia/Singapore",
		"Asia/Srednekolymsk",
		"Asia/Taipei",
		"Asia/Tashkent",
		"Asia/Tbilisi",
		"Asia/Tehran",
		"Asia/Tel_Aviv",
		"Asia/Thimbu",
		"Asia/Thimphu",
		"Asia/Tokyo",
		"Asia/Tomsk",
		"Asia/Ujung_Pandang",
		"Asia/Ulaanbaatar",
		"Asia/Ulan_Bator",
		"Asia/Urumqi",
		"Asia/Ust-Nera",
		"Asia/Vientiane",
		"Asia/Vladivostok",
		"Asia/Yakutsk",
		"Asia/Yangon",
		"Asia/Yekaterinburg",
		"Asia/Yerevan",
		"Atlantic/Azores",
		"Atlantic/Bermuda",
		"Atlantic/Canary",
		"Atlantic/Cape_Verde",
		"Atlantic/Faeroe",
		"Atlantic/Faroe",
		"Atlantic/Jan_Mayen",
		"Atlantic/Madeira",
		"Atlantic/Reykjavik",
		"Atlantic/South_Georgia",
		"Atlantic/St_Helena",
		"Atlantic/Stanley",
		"Australia/ACT",
		"Australia/Adelaide",
		"Australia/Brisbane",
		"Australia/Broken_Hill",
		"Australia/Canberra",
		"Australia/Currie",
		"Australia/Darwin",
		"Australia/Eucla",
		"Australia/Hobart",
		"Australia/LHI",
		"Australia/Lindeman",
		"Australia/Lord_Howe",
		"Australia/Melbourne",
		"Australia/NSW",
		"Australia/North",
		"Australia/Perth",
		"Australia/Queensland",
		"Australia/South",
		"Australia/Sydney",
		"Australia/Tasmania",
		"Australia/Victoria",
		"Australia/West",
		"Australia/Yancowinna",
		"Brazil/Acre",
		"Brazil/DeNoronha",
		"Brazil/East",
		"Brazil/West",
		"CET",
		"CST6CDT",
		"Canada/Atlantic",
		"Canada/Central",
		"Canada/Eastern",
		"Canada/Mountain",
		"Canada/Newfoundland",
		"Canada/Pacific",
		"Canada/Saskatchewan",
		"Canada/Yukon",
		"Chile/Continental",
		"Chile/EasterIsland",
		"Cuba",
		"EET",
		"EST",
		"EST5EDT",
		"Egypt",
		"Eire",
		"Etc/GMT",
		"Etc/GMT+0",
		"Etc/GMT+1",
		"Etc/GMT+10",
		"Etc/GMT+11",
		"Etc/GMT+12",
		"Etc/GMT+2",
		"Etc/GMT+3",
		"Etc/GMT+4",
		"Etc/GMT+5",
		"Etc/GMT+6",
		"Etc/GMT+7",
		"Etc/GMT+8",
		"Etc/GMT+9",
		"Etc/GMT-0",
		"Etc/GMT-1",
		"Etc/GMT-10",
		"Etc/GMT-11",
		"Etc/GMT-12",
		"Etc/GMT-13",
		"Etc/GMT-14",
		"Etc/GMT-2",
		"Etc/GMT-3",
		"Etc/GMT-4",
		"Etc/GMT-5",
		"Etc/GMT-6",
		"Etc/GMT-7",
		"Etc/GMT-8",
		"Etc/GMT-9",
		"Etc/GMT0",
		"Etc/Greenwich",
		"Etc/UCT",
		"Etc/UTC",
		"Etc/Universal",
		"Etc/Zulu",
		"Europe/Amsterdam",
		"Europe/Andorra",
		"Europe/Astrakhan",
		"Europe/Athens",
		"Europe/Belfast",
		"Europe/Belgrade",
		"Europe/Berlin",
		"Europe/Bratislava",
		"Europe/Brussels",
		"Europe/Bucharest",
		"Europe/Budapest",
		"Europe/Busingen",
		"Europe/Chisinau",
		"Europe/Copenhagen",
		"Europe/Dublin",
		"Europe/Gibraltar",
		"Europe/Guernsey",
		"Europe/Helsinki",
		"Europe/Isle_of_Man",
		"Europe/Istanbul",
		"Europe/Jersey",
		"Europe/Kaliningrad",
		"Europe/Kiev",
		"Europe/Kirov",
		"Europe/Kyiv",
		"Europe/Lisbon",
		"Europe/Ljubljana",
		"Europe/London",
		"Europe/Luxembourg",
		"Europe/Madrid",
		"Europe/Malta",
		"Europe/Mariehamn",
		"Europe/Minsk",
		"Europe/Monaco",
		"Europe/Moscow",
		"Europe/Nicosia",
		"Europe/Oslo",
		"Europe/Paris",
		"Europe/Podgorica",
		"Europe/Prague",
		"Europe/Riga",
		"Europe/Rome",
		"Europe/Samara",
		"Europe/San_Marino",
		"Europe/Sarajevo",
		"Europe/Saratov",
		"Europe/Simferopol",
		"Europe/Skopje",
		"Europe/Sofia",
		"Europe/Stockholm",
		"Europe/Tallinn",
		"Europe/Tirane",
		"Europe/Tiraspol",
		"Europe/Ulyanovsk",
		"Europe/Uzhgorod",
		"Europe/Vaduz",
		"Europe/Vatican",
		"Europe/Vienna",
		"Europe/Vilnius",
		"Europe/Volgograd",
		"Europe/Warsaw",
		"Europe/Zagreb",
		"Europe/Zaporozhye",
		"Europe/Zurich",
		"GB",
		"GB-Eire",
		"GMT",
		"GMT+0",
		"GMT-0",
		"GMT0",
		"Greenwich",
		"HST",
		"Hongkong",
		"Iceland",
		"Indian/Antananarivo",
		"Indian/Chagos",
		"Indian/Christmas",
		"Indian/Cocos",
		"Indian/Comoro",
		"Indian/Kerguelen",
		"Indian/Mahe",
		"Indian/Maldives",
		"Indian/Mauritius",
		"Indian/Mayotte",
		"Indian/Reunion",
		"Iran",
		"Israel",
		"Jamaica",
		"Japan",
		"Kwajalein",
		"Libya",
		"MET",
		"MST",
		"MST7MDT",
		"Mexico/BajaNorte",
		"Mexico/BajaSur",
		"Mexico/General",
		"NZ",
		"NZ-CHAT",
		"Navajo",
		"PRC",
		"PST8PDT",
		"Pacific/Apia",
		"Pacific/Auckland",
		"Pacific/Bougainville",
		"Pacific/Chatham",
		"Pacific/Chuuk",
		"Pacific/Easter",
		"Pacific/Efate",
		"Pacific/Enderbury",
		"Pacific/Fakaofo",
		"Pacific/Fiji",
		"Pacific/Funafuti",
		"Pacific/Galapagos",
		"Pacific/Gambier",
		"Pacific/Guadalcanal",
		"Pacific/Guam",
		"Pacific/Honolulu",
		"Pacific/Johnston",
		"Pacific/Kanton",
		"Pacific/Kiritimati",
		"Pacific/Kosrae",
		"Pacific/Kwajalein",
		"Pacific/Majuro",
		"Pacific/Marquesas",
		"Pacific/Midway",
		"Pacific/Nauru",
		"Pacific/Niue",
		"Pacific/Norfolk",
		"Pacific/Noumea",
		"Pacific/Pago_Pago",
		"Pacific/Palau",
		"Pacific/Pitcairn",
		"Pacific/Pohnpei",
		"Pacific/Ponape",
		"Pacific/Port_Moresby",
		"Pacific/Rarotonga",
		"Pacific/Saipan",
		"Pacific/Samoa",
		"Pacific/Tahiti",
		"Pacific/Tarawa",
		"Pacific/Tongatapu",
		"Pacific/Truk",
		"Pacific/Wake",
		"Pacific/Wallis",
		"Pacific/Yap",
		"Poland",
		"Portugal",
		"ROC",
		"ROK",
		"Singapore",
		"Turkey",
		"UCT",
		"US/Alaska",
		"US/Aleutian",
		"US/Arizona",
		"US/Central",
		"US/East-Indiana",
		"US/Eastern",
		"US/Hawaii",
		"US/Indiana-Starke",
		"US/Michigan",
		"US/Mountain",
		"US/Pacific",
		"US/Samoa",
		"UTC",
		"Universal",
		"W-SU",
		"WET",
		"Zulu",
	]),
};

// US Letter (216 by 279mm) and A4 (210 by 297mm)

// Default is US Letter,

// US Letter PORTRAIT 0.7727
// US Letter Landscape 1.294

// A4 PORTRAIT 0.752
// A4 Landscape 1.328
