import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import HeaderWithLine from "../../components/HeaderWithLine";
import { Box, Button } from "@cti-workspace/ui";
import { useSnackbar } from "notistack";
import useNProgressHook from "../../hooks/useNProgressHook";
import { getErrMsg, CreateSetPasswordTokenAndSendEmail } from "@cti-workspace/helpers";

const TriggerNewPasswordComponent = () => {
	const [email, setEmail] = useState("");

	const { enqueueSnackbar } = useSnackbar();

	const { progressStart, progressDone } = useNProgressHook();

	const handleChange = (e) => {
		const { value } = e.target;
		setEmail(value);
	};

	const createAccount = async () => {
		try {
			progressStart();
			let val = await CreateSetPasswordTokenAndSendEmail(email);
			if (val && val.token) {
				enqueueSnackbar(`Password Reset triggered `, {
					variant: "success",
				});
			}
			progressDone();
		} catch (error) {
			progressDone();
			console.error(error);
			enqueueSnackbar(getErrMsg(error), {
				variant: "error",
			});
		}
	};

	return (
		<>
			<Box mx="auto" p={-2}>
				<HeaderWithLine title={"Set New Password"} variant={"h6"} />
			</Box>
			<Grid>
				<Box mx="auto" p={1}>
					<TextField required fullWidth={true} id="email" label="Email" placeholder={"user@ipwru.com"} onChange={handleChange} variant="outlined" color={"primary"} value={email} />

					<Box mx="auto" p={1} />

					<Button disabled={!email} color="warning" id="login" type="submit" fullWidth={true} onClick={createAccount}>
						Trigger New Password
					</Button>
				</Box>
			</Grid>
		</>
	);
};

export default TriggerNewPasswordComponent;
