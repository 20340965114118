import styled from "styled-components";

const CardActionsContainer = styled.div`
	display: flex;
	flex-direction: row;
	padding: 0.5rem;
	width: "100%";
	${(props) => (props.justifyContent ? `justify-content: ${props.justifyContent};` : "")}
`;

export function CardActions({ children, ...rest }) {
	return <CardActionsContainer {...rest}>{children}</CardActionsContainer>;
}
