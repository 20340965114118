import { ISSUER_PREFIX, downloadApi } from "@cti-workspace/helpers";
import { createBatch } from "../../../../../libs/helpers/src/lib/Utils/api";

export const downloadBatchFilteredExcelFile = async (credDefId, batchLabel, credentialName, fileType = "XLSX") => {
	const requestBody = {
		fileType,
		config: {
			filterBy: [
				{
					field: "credDefId",
					values: [credDefId],
				},
			],
		},
	};
	if (batchLabel) {
		requestBody.config.filterBy.push({
			field: "batchLabel",
			values: [batchLabel],
		});
	}

	return downloadApi(`/${ISSUER_PREFIX}/exportIssuedCertsBatchByOrg`, requestBody, "POST", `${credentialName}_${batchLabel}.xlsx`);
};

export const downloadBatchFilteredPDFFile = async (emailIDs, batchLabel, orgID, credDefId) => {
	const requestBody = {
		options: {},
		destination: {
			emailIds: emailIDs,
		},
		data: {
			batchLabel: batchLabel,
			orgId: orgID,
			credDefId,
		},
		jobType: "BULK_PDF_DOWNLOAD",
	};
	return createBatch(`/batch-job`, requestBody, "POST");
};
