import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Icon from "../icon/icon";
import { ThemeProvider } from "theme-ui";
import Theme from "../theme/theme";
import { getObjectValue } from "@cti-workspace/helpers";

const ButtonContainer = styled.div`
	display: grid;
	grid-template-columns: ${(props) => (["split", "dropdown"].includes(props.buttonType) ? "auto 1.4rem" : "auto")};
	grid-template-rows: auto;
	width: ${(props) => (props.fullWidth ? "100%" : "auto")};
`;

const textTypeObj = {
	uppercase: "uppercase",
	text: "none",
	capitalize: "capitalize",
};

const ButtonMain = styled.button`
	width: ${(props) => (props.fullWidth && props.buttonType !== "icon" ? "100%" : "auto")};
	background: ${(props) => props.bgColor};
	border-color: ${(props) => props.borderColor};
	/* border: none; */
	border-radius: ${({ buttonType, borderRadius }) => (borderRadius ? borderRadius : ["split", "dropdown"].includes(buttonType) ? "3px 0px 0px 3px" : "3px")};
	padding: 6px 15px;
	text-transform: ${(props) => textTypeObj[props.textType]};
	letter-spacing: 0.3px;
	font-size: 0.8rem;
	cursor: pointer;
	opacity: 0.9;
	${(props) =>
		props.buttonType === "icon"
			? `
		border: none;
		border-radius: ${props.borderRadius ? props.borderRadius : "100%"};
		padding: 0px;
	`
			: ""}
	${(props) => (props.disabled ? "opacity: 0.5; cursor: not-allowed;" : "")}
	:hover {
		opacity: 1;
		${(props) => (props.disabled ? "opacity: 0.5; cursor: not-allowed;" : "")}
		${(props) => (props.buttonType === "icon" ? (!props.buttonHover ? "box-shadow: 0px 0px 1px 1px #ebebeb;" : "") : "")}
	}
`;

const ButtonText = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ButtonIcon = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ButtonLeftIcon = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 0.25rem;
`;

const ButtonRightIcon = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 0.25rem;
`;

const ButtonSplit = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px;
	border: none;
	border-radius: 0px 3px 3px 0px;
	cursor: pointer;
	opacity: 0.9;
	${(props) => (props.splitDisabled ? "opacity: 0.5; cursor: not-allowed;" : "")}
	:hover {
		opacity: 1;
		${(props) => (props.splitDisabled ? "opacity: 0.5; cursor: not-allowed;" : "")}
	}
	:hover ~ #button_split_popover_container {
		display: flex !important;
	}
`;

const ButtonMainContainer = styled.div`
	position: relative;
	:hover #button_popover_container {
		display: flex !important;
	}
`;

const ButtonPopOverContainer = styled.div`
	width: 100%;
	position: absolute;
	display: none;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin-top: 3px;
	z-index: 9999;
`;

const ButtonSplitPopOverContainerMain = styled.div`
	position: relative;
	display: none;
	width: 100%;
`;

const ButtonSplitPopOverContainer = styled.div`
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin-top: 3px;
	z-index: 9999;
`;

const ButtonPopOverNotch = styled.div`
	background-color: #3c3c3e;
	width: 8px;
	height: 8px;
	position: relative;
	margin-bottom: -5px;
	transform: rotate(45deg);
`;

const ButtonPopOver = styled.div`
	background-color: #3c3c3e;
	color: white;
	display: inline-block;
	font-size: 0.65rem;
	padding: 3px 5px;
	border-radius: 3px;
	width: max-content;
`;

const SplitButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-end;
`;

const SplitOptionsContainer = styled.div`
	position: absolute;
	background-color: white;
	box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
	border-radius: 3px;
	z-index: 9999;
	width: max-content;
	margin-top: ${(props) => props.marginTop || "28px"};
`;

const SplitOption = styled.button`
	font-size: 0.9rem;
	padding: 8px 14px;
	cursor: pointer;
	display: flex;
	align-items: center;
	background-color: transparent;
	border: none;
	width: 100%;
	gap: 0.25rem;
	:hover {
		background-color: #eeeeee;
		border-radius: 3px;
	}
`;

const SplitOptionIcon = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 0.25rem;
`;

export const Button = ({
	children = "",
	fullWidth = true,
	color = "primary",
	uppercase = true,
	disabled = false,
	buttonStyle = {},
	invert = false,
	leftIcon = "",
	leftIconSize = "1rem",
	leftIconWeight = "bold",
	leftIconColor = "",
	rightIcon = "",
	rightIconSize = "1rem",
	rightIconWeight = "bold",
	buttonType = "button",
	splitButtonColor = "primary",
	splitButtonInvert = false,
	splitButtonIcon = "",
	splitButtonOptions = [],
	onClick = () => { },
	popover = "",
	icon = "",
	iconSize = "1rem",
	iconWeight = "bold",
	noBorder = false,
	leftSpacing = false,
	type = "button",
	splitPopover = "",
	splitDisabled = false,
	groupOptions = [],
	href = "",
	target = "_blank",
	download = "",
	buttonTransparent = false,
	style = {},
	borderRadius = false,
	textType = "capitalize",
	iconStyle = {},
	popoverPosition = "bottom",
	mainDivContainerProps = {},
	splitButtonStyle = {},
	...rest
}) => {
	const [splitOptionsDisplay, setSplitOptionsDisplay] = useState(false);
	const splitRef = useRef(null);
	const buttonRef = useRef(null);
	const linkButtonRef = useRef(null);

	useEffect(() => {
		function handleClickOutside(event, splitRef) {
			if (splitRef.current && !splitRef.current.contains(event.target)) {
				setSplitOptionsDisplay(false);
			}
		}

		if (splitRef && splitRef.current) {
			document.addEventListener("mousedown", (event) => handleClickOutside(event, splitRef));
			return () => {
				document.removeEventListener("mousedown", (event) => handleClickOutside(event, splitRef));
			};
		}
	}, [splitRef]);

	const buttonTheme = {
		primary: {
			color: "white",
			bg: "primary",
			...(noBorder ? { border: "none" } : { border: `2px solid ${Theme.colors.primary}` }),
		},
		secondary: {
			color: "white",
			bg: "secondary",
			border: `2px solid ${Theme.colors.secondary}`,
		},
		white: {
			color: "text",
			...(buttonTransparent ? { bg: "transparent" } : { bg: "white" }),
			...(noBorder ? { border: "none" } : { border: `2px solid ${Theme.colors.text}` }),
		},
		danger: {
			color: "white",
			bg: "danger",
			...(noBorder ? {} : { border: `2px solid ${Theme.colors.danger}` }),
		},
		warning: {
			color: "white",
			bg: "warning",
			...(noBorder ? {} : { border: `2px solid ${Theme.colors.warning}` }),
		},
		error: {
			color: "white",
			bg: "error",
			...(noBorder ? {} : { border: `2px solid ${Theme.colors.error}` }),
		},
		grey: {
			color: "white",
			bg: "#757575",
			...(noBorder ? {} : { border: `2px solid #757575` }),
		},
	};

	const buttonThemeInvert = {
		primary: {
			color: "primary",
			...(buttonTransparent ? { bg: "transparent" } : { bg: "white" }),
			...(buttonType !== "icon" && !noBorder ? { border: `2px solid ${Theme.colors.primary}` } : {}),
			...(noBorder ? { border: "none" } : {}),
		},
		secondary: {
			color: "secondary",
			...(buttonTransparent ? { bg: "transparent" } : { bg: "white" }),
			...(buttonType !== "icon" && !noBorder ? { border: `2px solid ${Theme.colors.secondary}` } : {}),
			...(noBorder ? { border: "none" } : {}),
		},
		white: {
			color: "white",
			...(buttonTransparent ? { bg: "transparent" } : { bg: "white" }),
			...(buttonType !== "icon" && !noBorder ? { border: `2px solid ${Theme.colors.text}` } : {}),
		},
		danger: {
			color: "danger",
			...(buttonTransparent ? { bg: "transparent" } : { bg: "white" }),
			...(buttonType !== "icon" && !noBorder ? { border: `2px solid ${Theme.colors.danger}` } : {}),
		},
		warning: {
			color: "warning",
			...(buttonTransparent ? { bg: "transparent" } : { bg: "white" }),
			...(buttonType !== "icon" && !noBorder ? { border: `2px solid ${Theme.colors.warning}` } : {}),
		},
		error: {
			color: "error",
			...(buttonTransparent ? { bg: "transparent" } : { bg: "white" }),
			...(buttonType !== "icon" && !noBorder ? { border: `2px solid ${Theme.colors.error}` } : {}),
		},
		grey: {
			color: Theme.colors.grey,
			...(buttonTransparent ? { bg: "transparent" } : { bg: "white" }),
			...(buttonType !== "icon" && !noBorder ? { border: `2px solid ${Theme.colors.primary}` } : {}),
		},
		borderedGrey: {
			color: "#757575",
			...(buttonTransparent ? { bg: "transparent" } : { bg: "white" }),
			...(buttonType !== "icon" && !noBorder ? { border: `2px solid #757575` } : {}),
		},
		borderedBlack: {
			color: "#000000",
			...(buttonTransparent ? { bg: "transparent" } : { bg: "white" }),
			...(buttonType !== "icon" && !noBorder ? { border: `2px solid #000000` } : {}),
		},
	};

	return (
		<ThemeProvider theme={Theme}>
			<div style={{ display: "flex", ...style, ...(typeof leftSpacing === "boolean" ? (leftSpacing && { marginLeft: "0.5rem" }) || {} : { marginLeft: leftSpacing || "0px" }) }} {...mainDivContainerProps}>
				<ButtonContainer fullWidth={fullWidth} buttonType={buttonType}>
					<ButtonMainContainer popover={popover ? true : false}>
						{popover && popoverPosition === "top" && (
							<ButtonPopOverContainer id="button_popover_container" style={{ bottom: "25px" }}>
								<ButtonPopOver>{popover}</ButtonPopOver>
								<ButtonPopOverNotch style={{ bottom: "5px" }} />
							</ButtonPopOverContainer>
						)}
						{buttonType !== "group" ? (
							<ButtonMain
								borderRadius={borderRadius}
								fullWidth={fullWidth}
								sx={
									!rest.disableTheme && {
										...(invert ? getObjectValue(buttonThemeInvert, color) : getObjectValue(buttonTheme, color)),
										fontWeight: "bold",
									}
								}
								textType={textType}
								disabled={disabled}
								style={icon ? { display: "flex", padding: "5px", ...buttonStyle } : buttonStyle}
								onClick={(e) => {
									if (buttonType !== "dropdown") {
										href && linkButtonRef.current && linkButtonRef.current.click();
										onClick(e);
									} else {
										!splitDisabled && setSplitOptionsDisplay(!splitOptionsDisplay);
									}
								}}
								buttonType={buttonType}
								ref={buttonRef}
								type={type}
								{...rest}>
								{buttonType === "icon" ? (
									<ButtonIcon>{typeof icon === "string" ? <Icon name={icon} size={iconSize} weight={iconWeight} {...iconStyle} /> : icon}</ButtonIcon>
								) : (
									<ButtonText>
										{leftIcon && <ButtonLeftIcon>{typeof leftIcon === "string" ? <Icon name={leftIcon} size={leftIconSize} weight={leftIconWeight} {...(leftIconColor ? { color: leftIconColor } : {})} {...iconStyle} /> : leftIcon}</ButtonLeftIcon>}
										<span style={{ display: "flex" }}>{children}</span>
										{rightIcon && <ButtonRightIcon>{typeof leftIcon === "string" ? <Icon name={rightIcon} size={rightIconSize} weight={rightIconWeight} {...iconStyle} /> : rightIcon} </ButtonRightIcon>}
									</ButtonText>
								)}
							</ButtonMain>
						) : (
							groupOptions.map(({ invert: groupInvert, color: groupColor = "primary", label: groupLabel, uppercase: groupUppercase = true, buttonStyle: groupButtonStyle, ...groupProps }, index) => (
								<ButtonMain
									key={index}
									fullWidth={false}
									borderRadius={borderRadius}
									uppercase={groupUppercase}
									textType={textType}
									style={{
										...(groupOptions.length > 1
											? {
												borderRadius: 0,
												...(index === 0 && {
													borderTopLeftRadius: "3px",
													borderBottomLeftRadius: "3px",
												}),
												...(index === groupOptions.length - 1
													? {
														borderTopRightRadius: "3px",
														borderBottomRightRadius: "3px",
													}
													: {
														borderRight: "none",
													}),
											}
											: {}),
										...groupButtonStyle,
									}}
									sx={{
										...(groupInvert ? getObjectValue(buttonThemeInvert, groupColor) : getObjectValue(buttonTheme, groupColor)),
										fontWeight: "bold",
									}}
									{...groupProps}>
									{groupLabel}
								</ButtonMain>
							))
						)}
						{popover && popoverPosition === "bottom" && (
							<ButtonPopOverContainer id="button_popover_container">
								<ButtonPopOverNotch />
								<ButtonPopOver>{popover}</ButtonPopOver>
							</ButtonPopOverContainer>
						)}
						{href && <a style={{ display: "none" }} href={href} target={target} download={download} ref={linkButtonRef} rel={"noopener noreferrer"} />}
					</ButtonMainContainer>
					{["split", "dropdown"].includes(buttonType) && (
						<SplitButtonContainer ref={splitRef}>
							<ButtonSplit
								sx={{
									...(splitButtonInvert ? getObjectValue(buttonThemeInvert, splitButtonColor) : getObjectValue(buttonTheme, splitButtonColor)),
								}}
								style={{
									...(splitButtonInvert ? { borderLeft: "none" } : {}),
									width: "100%",
									height: "100%",
									...(buttonType === "dropdown" ? {} : { minHeight: `${buttonRef && buttonRef.current && buttonRef.current.offsetHeight ? buttonRef.current.offsetHeight + "px" : "100%"}` }),
									...splitButtonStyle
								}}
								type={"button"}
								splitDisabled={splitDisabled}
								ref={splitRef}
								onClick={() => !splitDisabled && setSplitOptionsDisplay(!splitOptionsDisplay)}>
								{splitButtonIcon || <Icon name="dots-three-vertical" size={16} weight="bold" />}
							</ButtonSplit>
							{splitOptionsDisplay && (
								<SplitOptionsContainer marginTop={buttonRef && buttonRef.current && buttonRef.current.offsetHeight ? buttonRef.current.offsetHeight + 1 + "px" : "28px"}>
									{splitButtonOptions.map((option, index) => (
										<SplitOption
											key={index}
											sx={{ color: "text" }}
											onClick={(event) => {
												option.onClick && option.onClick(event);
												setSplitOptionsDisplay(false);
											}}
											style={option?.style || {}}
										>
											{option.icon && <SplitOptionIcon>{<Icon name={option.icon} />}</SplitOptionIcon>}
											{typeof option.label === "string " ? <span>{option.label}</span> : option.label}
											{option.iconRight && <SplitOptionIcon>{<Icon name={option.iconRight} size={option.iconSize} color={option?.iconColor || "warning"} weight={option?.iconWeight || "fill"} />}</SplitOptionIcon>}
										</SplitOption>
									))}
								</SplitOptionsContainer>
							)}
							{splitPopover && (
								<ButtonSplitPopOverContainerMain id="button_split_popover_container">
									<ButtonSplitPopOverContainer>
										<ButtonPopOverNotch />
										<ButtonPopOver>{splitPopover}</ButtonPopOver>
									</ButtonSplitPopOverContainer>
								</ButtonSplitPopOverContainerMain>
							)}
						</SplitButtonContainer>
					)}
				</ButtonContainer>
			</div>
		</ThemeProvider>
	);
};

/** @jsxImportSource theme-ui */
export default Button;
