import { useQuery } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { userStore } from "../../store/userStore";
import { ViewPathwayWrapper, PathwayDetailsSection, PathwayBuilderSection, TopNavBar, ErrorWrapper } from "../pathway/components/pathwayComponents";
import { PathwayDesigner } from "../pathway/pathwayDesigner";

import { useNavigate, useParams } from "react-router-dom";
import { getApi, API_URL, VCX_API_KEY } from "@cti-workspace/helpers";
import PathwaySideBar from "./pathwaySidebar";

const WalletPathway = () => {
	const childRef = useRef();
	const email = userStore((state) => state.email);
	const jwtToken = userStore((state) => state.jwtToken);
	const { id, userPathwayId } = useParams();
	const [selectedNode, setSelectedNode] = useState("");

	const { isLoading, error, data, isFetching } = useQuery({
		queryKey: ["holder", id],
		queryFn: async () => {
			const { pathway } = await getApi(`/holder/${id}`, {}, false, API_URL, { "vcx-api-key": VCX_API_KEY, authorization: `Bearer ${jwtToken}` });

			const userPathwayArray = await getApi(`/holder/getActivePathways?email=${email}`, {}, false, API_URL, { "vcx-api-key": VCX_API_KEY, authorization: `Bearer ${jwtToken}` });
			let pathwayData = {};
			for (const userPathwayData of userPathwayArray) {
				if (userPathwayData._id === userPathwayId) {
					const { _id, pathways, ...rest } = userPathwayData;
					const { [id]: userData } = pathways;
					let arr = [...pathway.steps];
					const steps = arr.map((o) => ({ ...o, id: o.stepId }));
					pathwayData = { ...pathway, userPathwayId, userPathwayData: userData, steps };
					return pathwayData;
				}
			}

			throw new Error({ code: "Wallet-01", message: "unable to retrieve pathway related data" });
		},
	});

	const onNodeClick = (nodeId) => {
		// console.log(nodeId);
		setSelectedNode(nodeId);
		childRef.current.setSelected(nodeId);
		return null;
	};

	if (isLoading) {
		return (
			<ViewPathwayWrapper>
				<div>...loading</div>
			</ViewPathwayWrapper>
		);
	}

	if (error) {
		return (
			<ViewPathwayWrapper>
				<div>{JSON.stringify(error)}</div>
			</ViewPathwayWrapper>
		);
	}

	const { steps } = data;

	return (
		<ViewPathwayWrapper>
			<PathwayDetailsSection>
				<PathwaySideBar childRef={childRef} leafId={selectedNode} data={data} />
			</PathwayDetailsSection>
			<PathwayBuilderSection>
				<TopNavBar back={"/wallet"} />
				<PathwayDesigner ref={childRef} data={steps} onNodeClick={onNodeClick} />
			</PathwayBuilderSection>
		</ViewPathwayWrapper>
	);
};

export default WalletPathway;
