import { getErrMsg, isValidURL, useNProgressHook, getObjectValue, hasProperty, fetchOrgCustomCredentials, API_URL } from "@cti-workspace/helpers";
import { Box, Button, Card, Container } from "@cti-workspace/ui";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import AdvTextField from "../../components/AdvTextField";
import usePresentationHook from "../../hooks/usePresentationHook";
import useUserHook from "../../hooks/useUserHook";
import { userStore } from "../../store/userStore";

export default function DefinePresentation() {
	const { control, handleSubmit, errors, register, reset } = useForm();
	const [customCredentialsObj, setCustomCredentialsObj] = useState({});

	const { enqueueSnackbar } = useSnackbar();
	const organizations = userStore((state) => state.organizations);
	const { addPresentationDefinition, updatePresentationDefinition } = usePresentationHook();
	const { progressStart, progressDone } = useNProgressHook();
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();

	useEffect(() => {
		(async () => {
			progressStart();
			const { id } = params;
			try {
				const creds = await fetchOrgCustomCredentials();
				const customCredIdObj = (creds || []).reduce(
					(acc, item) => ({
						...acc,
						[item.name]: item,
					}),
					{}
				);
				setCustomCredentialsObj(customCredIdObj);
				if (id) {
					const { state } = location;
					if (!state) throw "No presentation definition found";
					// console.log(state);
					reset({
						...state,
						credentialTypes:
							(state.credentialTypes &&
								state.credentialTypes.reduce((acc, item) => {
									if (getObjectValue(customCredIdObj, item.name)) acc.push(item.name);
									return acc;
								}, [])) ||
							[],
						issuerUrls: (state.issuerUrls && state.issuerUrls.join(", ")) || "",
					});
				}
			} catch (error) {
				console.error(error);
			} finally {
				progressDone();
			}
		})();
	}, []);

	const handleDefinePresentationCredential = async (event) => {
		// console.log(event);
		try {
			progressStart();
			const payload = {
				...(params.id && location.state ? location.state : {}),
				...event,
				name: event.name,
				credentialTypes: (event.credentialTypes || []).map((item) => ({
					id: getObjectValue(customCredentialsObj, `${item}._id`),
					type: item,
					...getObjectValue(customCredentialsObj, item),
				})),
				issuerUrls: (event.issuerUrls || "").split(/[, ]+/).filter((item) => item),
				orgId: getObjectValue(organizations, "default._id", ""),
				pinned: false,
				...(event.crossDeviceCheck ? { response_mode: "post" } : {}),
			};
			if (params.id) {
				await updatePresentationDefinition(payload);
				enqueueSnackbar("Presentation definition updated successfully", { variant: "success" });
			} else {
				await addPresentationDefinition(payload);
				enqueueSnackbar("Presentation definition added successfully", { variant: "success" });
			}
			navigate(-1);
		} catch (error) {
			enqueueSnackbar(getErrMsg(error), { variant: "error" });
		} finally {
			progressDone();
		}
	};

	return (
		<Container maxWidth="md">
			<div style={{ height: "64px" }} />
			<Card elevation={2} style={{ padding: "1rem" }}>
				<Typography variant="h6">{params.id ? "Edit" : "New"} Presentation Definition</Typography>
			</Card>
			<Box borderColor="primary" border={8} style={{ marginTop: "1rem", padding: "1rem", backgroundColor: "white" }}>
				<DefineCredentialForm
					orgId={getObjectValue(organizations, "default._id", "")}
					control={control}
					register={register}
					errors={errors}
					handleSubmit={handleSubmit(handleDefinePresentationCredential)}
					customCredentials={Object.keys(customCredentialsObj || {})}
					params={params}
				/>
			</Box>
		</Container>
	);
}

function DefineCredentialForm({ control = null, errors = {}, orgId, handleSubmit = () => {}, register = null, customCredentials = [], params = {} }) {
	const watchValues = useWatch({
		control,
	});
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<AdvTextField
					name="name"
					errors={errors}
					inputRef={register({
						required: "Name is required",
					})}
					label="Name"
					required
					InputLabelProps={params.id ? { shrink: true } : {}}
				/>
			</Grid>
			<Grid item xs={12}>
				<ControllerSelectOption
					name="credentialTypes"
					control={control}
					defaultValue={[]}
					errors={errors}
					label="Credential Type(s)"
					placeholder="Select credentials"
					required
					helperText="Select the credential types you would like to request"
					multiple
					rules={{
						validate: (value = []) => (value.length > 0 ? null : "Select at least one credential type"),
					}}
					options={customCredentials}
					labelProp="name"
				/>
			</Grid>
			<Grid item xs={12}>
				&nbsp;&nbsp;{" "}
				<Controller
					name="allCredentialTypesRequired"
					control={control}
					defaultValue={false}
					render={(field) => <FormControlLabel control={<Checkbox onChange={(e) => field.onChange(e.target.checked)} checked={field.value} />} label="All credential types required" />}
				/>
			</Grid>
			{/* <Grid item xs={12}>
				<ControllerSelectOption
					control={control}
					name="claims"
					label="Claim(s)"
					placeholder="Select claims"
					multiple
					options={[
						{ label: "Claim 1", value: "claim-1" },
						{ label: "Claim 2", value: "claim-2" },
						{ label: "Claim 3", value: "claim-3" },
					]}
				/>
			</Grid> */}
			<Grid item xs={12}>
				<AdvTextField
					name="issuerUrls"
					inputRef={register({
						validate: (value) => (value ? (value.split(/[, ]+/).every((url) => isValidURL(url)) ? true : "Enter valid URL(s)") : true),
					})}
					label="Issuer URL(s)"
					errors={errors}
					InputLabelProps={watchValues["issuerUrls"] ? { shrink: true } : {}}
					helperText={getObjectValue(errors, "issuerUrls.message", "") || "Enter the complete Issuer URL(s) you will accept credentials issued by."}
					placeholder="Enter URLs (ie., https://example.com"
				/>
			</Grid>
			<Grid item xs={12}>
				<AdvTextField
					name="redirectUri"
					inputRef={register({
						validate: (value) => (value ? (isValidURL(value) ? true : "Enter a valid https url") : true),
					})}
					label="Redirect URI"
					errors={errors}
					helperText={getObjectValue(errors, "redirectUri.message", "") || "The location where you would like the response to be submitted."}
					placeholder="https://nsw.org/oidc/cb"
					defaultValue={`${API_URL}/verifier/${orgId}/vpToken`}
				/>
			</Grid>
			<Grid item xs={12}>
				&nbsp;&nbsp;{" "}
				<Controller
					name="crossDeviceCheck"
					control={control}
					defaultValue={false}
					render={(field) => <FormControlLabel control={<Checkbox onChange={(e) => field.onChange(e.target.checked)} checked={field.value} />} label="Cross device presentation" />}
				/>
			</Grid>
			<Grid item xs={12}>
				<Button color="warning" onClick={handleSubmit}>
					Submit
				</Button>
			</Grid>
		</Grid>
	);
}

function ControllerSelectOption({ control, name, defaultValue = "", placeholder, options = [], label = "", size = "large", required = false, errors = {}, helperText = "", rules = {}, labelProp = "label" }) {
	return (
		<Controller
			control={control}
			name={name}
			defaultValue={defaultValue}
			rules={{ required: { value: required, message: label ? `${label} is required` : "Value Required" }, ...rules }}
			render={(field) => (
				<FormControl fullWidth size={size} variant="outlined" error={hasProperty(errors, name)} required={required}>
					{label ? <InputLabel>{label}</InputLabel> : null}
					<Select {...field} value={field.value || []} placeholder={placeholder} fullWidth {...(label ? { label } : {})} multiple renderValue={(selected) => selected.join(", ")}>
						{options.length === 0 ? (
							<MenuItem key={"no options"} value="">
								no options
							</MenuItem>
						) : (
							options.map((item, i) => {
								const { [labelProp]: currentItem } = item;
								return (
									<MenuItem key={i} value={typeof item === "string" ? item : currentItem}>
										<Checkbox checked={field.value.indexOf(typeof item === "string" ? item : currentItem) > -1} />
										<ListItemText primary={typeof item === "string" ? item : currentItem} />
									</MenuItem>
								);
							})
						)}
					</Select>
					{helperText && <FormHelperText>{helperText}</FormHelperText>}
					{getObjectValue(errors, `${name}.message`) && <FormHelperText>{getObjectValue(errors, `${name}.message`)}</FormHelperText>}
				</FormControl>
			)}
		/>
	);
}
