import { useFieldArray, useWatch } from "react-hook-form";
import { Button, Card, FieldWrapper, TextField, Typography as Text } from "@cti-workspace/ui";
import { getObjectValue } from "@cti-workspace/helpers";
import { Claims } from "./Claims";

export default function CredentialSections({ register, control, name = "", errors = {} }) {
	const { append, fields, remove } = useFieldArray({
		name,
		control,
	});

	const values = useWatch({ control }).sections;

	const hasErrorCheck = (errTxtMsg = "", index) => {
		if (getObjectValue(errors, `sections[${index}].name`, "")) {
			return {
				helperText: getObjectValue(errors, `sections[${index}].name.message`) ? getObjectValue(errors, `sections[${index}].name.message`) : errTxtMsg,
				error: true,
			};
		}
		return {};
	};

	const isDuplicate = (value, index) => {
		let errorVal;
		values.forEach((field, i) => {
			if (field.name.trim() === value.trim() && index !== i) {
				errorVal = "Section name exists";
			}
		});

		return errorVal;
	};

	return (
		<Card hasBorder elevation={0} borderRadius="2px" style={{ backgroundColor: "#FAFAFA", padding: "0.8rem", margin: "0" }}>
			<Text type="body1">
				Sections
			</Text>
			{/* <p>Sections are helpful for adding content that you want to add to certificate</p> */}
			{fields?.length < 1 && (
				<div style={{ display: "flex", justifyContent: "left", marginTop: "0.5rem" }}>
					<Button leftIcon="plus" color="warning" invert leftIconColor="black" onClick={() => append({ name: "", description: "" })}>
						Add
					</Button>
				</div>
			)}

			{fields.map((item, index) => (
				<FieldWrapper style={{ marginTop: "1rem" }} contentStyle={{ display: "flex", flexDirection: "column", gap: "1rem" }} title="New Section" onClose={() => remove(index)}>
					<TextField
						fullWidth
						size="small"
						variant="static"
						value={getObjectValue(values, `[${index}].name`, getObjectValue(item, "name", ""))}
						label="Section Name"
						inputRef={register({
							required: true,
							validate: (value) => isDuplicate(value, index),
						})}
						name={`${name}[${index}].name`}
						showRequired
						{...hasErrorCheck("Section name is required", index)}
					/>

					<TextField fullWidth size="small" variant="static" label="Description" multiline rows={1} defaultValue={item.description} inputRef={register()} name={`${name}[${index}].description`} />

					<Claims control={control} name={`sections[${index}].claims`} register={register} errors={errors} index={index} />
				</FieldWrapper>
			))}
			{fields?.length > 0 && (
				<div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
					<Button leftIcon="plus" color="warning" invert leftIconColor="black" onClick={() => append({ name: "", description: "" })}>
						Add Section
					</Button>
				</div>
			)}
		</Card>
	);
}
