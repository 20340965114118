import { useContext, useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { FabricContext } from "../context/FabricContext";
import FabricTextBox from "./objects/FabricTextBox";
import { makeStyles } from "@material-ui/core/styles";
import FabricDelete from "./functions/FabricDelete";
import FabricCopyId from "./functions/FabricCopyId";
import FabricAddText from "./functions/FabricAddText";
import FabricAddBG from "./functions/FabricAddBG";
import FabricAddImage from "./functions/FabricAddImage";
import FabricAddBadge from "./functions/FabricAddBadge";
import FabricAddVariable from "./functions/FabricAddVariable";
import { Card, Button as MainBtn, RadioGroup, RadioButton, ComboBox } from "@cti-workspace/ui";
import { getObjectValue } from "@cti-workspace/helpers";
import FormControl from "@material-ui/core/FormControl";
import useSettingsHook from "../../../../../hooks/useSettingsHook";
import { useParams } from "react-router-dom";
import PageOrientation from "./PageOrientation";
import snakeCase from 'lodash/snakeCase';
import { fabric } from "fabric-pure-browser";
import ImageVariable from "../../../../../assets/images/ImageVariableRectangle.jpg";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
}));

const FabricToolbarButton = ({ children, popover = "", borderRight = true, paddingRight = true, ...rest }) => {
	return (
		<MainBtn
			color="white"
			noBorder
			buttonStyle={{ padding: "0px", display: "flex", alignItems: "center" }}
			style={{ borderRight: borderRight ? "0.5px solid black" : "none", padding: "5px 10px", ...(!paddingRight ? { paddingRight: "0px" } : {}) }}
			popover={popover}
			{...rest}>
			{children}
		</MainBtn>
	);
};

const FabricToolbar = ({ control, register, setCopyRendererConfigId, id, changeDimension, credDimension, setFabricContentVariables = () => { }, fabricContentVariables = {}, setFabricContentChange = {}, fabricContentChange = {} }) => {
	const classes = useStyles();
	const { activeObject, canvas } = useContext(FabricContext);
	const { canView } = useSettingsHook();

	const params = useParams();
	const { fabricCertDesigner: defaultFabricCertDesigner, designType, rendererConfigId } = control?.defaultValuesRef?.current ?? {};
	const enableMultiPageCertificate = canView("enableMultiPageCertificate", true) && ((params.id && defaultFabricCertDesigner?.isMultiPage) || designType === "template" || !rendererConfigId || !params?.id);

	const cloneActiveObject = enableMultiPageCertificate ? getObjectValue(activeObject, `${id}`) : activeObject;

	const badges = useWatch({
		control,
		name: "images",
	});

	useEffect(() => {
		if (fabricContentVariables && Object.keys(fabricContentVariables.toRemove || {}).length > 0) {
			const contentVar = Object.keys(fabricContentVariables.toRemove).map(item => {
				return `{{${snakeCase(item)}${item?.split(" ").length > 1 ? "" : "_"}}}`;
			})
			if (!canvas) return;
			const canvasList = (enableMultiPageCertificate ? [...canvas] : [canvas]).filter(item => item);
			if (contentVar.length <= 0 || canvasList.length <= 0) return;
			canvasList.forEach((activeCanvas) => {
				activeCanvas.getObjects().forEach(function (o) {
					if (contentVar.indexOf(o.text) > -1) {
						activeCanvas.remove(o);
					} else if (o.type === "group") {
						o.getObjects().forEach(function (obj) {
							if (contentVar.indexOf(obj.text) > -1) {
								activeCanvas.remove(o);
							}
						})
					}
				});
				activeCanvas.discardActiveObject().renderAll();
			})
			setFabricContentVariables((prevValue) => ({
				...prevValue,
				toRemove: {}
			}))
		}
	}, [fabricContentVariables.toRemove])

	useEffect(() => {
		const fabricContentChangeKeysArr = Object.keys(fabricContentChange);
		const fabricContentChangeValArr = Object.values(fabricContentChange);
		if (fabricContentChangeValArr[0] && (fabricContentChangeKeysArr[0] !== fabricContentChangeValArr[0])) {
			const contentVar = `{{${snakeCase(fabricContentChangeValArr[0])}${fabricContentChangeValArr[0]?.split(" ").length > 1 ? "" : "_"}}}`;
			const oldContentVar = `{{${snakeCase(fabricContentChangeKeysArr[0])}${fabricContentChangeKeysArr[0]?.split(" ").length > 1 ? "" : "_"}}}`;
			if (!canvas) return;
			const canvasList = (enableMultiPageCertificate ? [...canvas] : [canvas]).filter(item => item);
			if (contentVar.length <= 0 || canvasList.length <= 0) return;
			canvasList.forEach((activeCanvas) => {
				activeCanvas.getObjects().forEach(function (o) {
					if (oldContentVar == o.text) {
						activeCanvas.remove(o);
						const { width, top, left, fontSize, fontWeight, fontStyle, textAlign, fontFamily, textDecoration, fill } = o;
						const textBox = new fabric.Textbox(contentVar, {
							width, top, left, fontSize, fontWeight, fontStyle, textAlign, fontFamily, textDecoration, fill
						});
						activeCanvas.add(textBox);
					} else if (o.type === "group") {
						let imgData = {};
						o.getObjects().forEach(function (obj) {
							if (obj.type === "image") {
								imgData = obj;
							}
						})
						o.getObjects().forEach(function (obj) {
							if (oldContentVar == obj.text) {
								const { width: imgWidth, top: imgTop, left: imgLeft, height: imgHeight } = o;
								fabric.Image.fromURL(ImageVariable, function (img) {
									activeCanvas.remove(o);
									const { width, top, left, fontSize, fontWeight, fontStyle, textAlign, fontFamily, textDecoration, fill } = obj;
									delete obj.text;
									const textBox = new fabric.Textbox(contentVar, {
										...obj, width, top: 0, left: 0, fontSize, fontWeight, fontStyle, textAlign, fontFamily, textDecoration, fill
									});
									let group = new fabric.Group([img, textBox], { top: imgData.group.top, left: imgData.group.left, scaleX: o.scaleX, scaleY: o.scaleY });
									group.toObject = (function (toObject) {
										return function () {
											return fabric.util.object.extend(toObject.call(this), {
												scaleX: this.scaleX,
												scaleY: this.scaleY,
											});
										};
									})(group.toObject);
									activeCanvas.add(group);
									// activeCanvas.renderAll();
								});
							}
						});
					}
				});
				activeCanvas.renderAll();
			})
			setFabricContentVariables((prevValue) => {
				delete prevValue.content[fabricContentChangeKeysArr[0].toLowerCase()];
				return {
					...prevValue,
					content: {
						[fabricContentChangeValArr[0].toLowerCase()]: true,
						...prevValue.content,
					}
				}
			})
			setFabricContentChange({});
		}
	}, [fabricContentChange]);

	return (
		<>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					width: "100%",
					borderRadius: "4px",
					backgroundColor: "white",
					padding: "10px 5px",
				}}>
				<div style={{ display: "flex", alignItems: "center" }}>
					<FabricToolbarButton popover="Text">
						<FabricAddText id={id} enableMultiPageCertificate={enableMultiPageCertificate} />
					</FabricToolbarButton>
					<FabricToolbarButton popover="Background Image">
						<FabricAddBG id={id} enableMultiPageCertificate={enableMultiPageCertificate} />
					</FabricToolbarButton>
					<FabricToolbarButton popover="Image">
						<FabricAddImage id={id} badges={badges} enableMultiPageCertificate={enableMultiPageCertificate} />
					</FabricToolbarButton>
					<FabricAddBadge id={id} badges={badges} enableMultiPageCertificate={enableMultiPageCertificate} />
					<FabricAddVariable control={control} id={id} enableMultiPageCertificate={enableMultiPageCertificate} setFabricContentVariables={setFabricContentVariables} />
				</div>
				<div style={{ display: "flex", alignItems: "center" }}>
					{/*<FabricToolbarButton popover="Get Template ID">*/}
					{enableMultiPageCertificate ? <PageOrientation defaultValue={credDimension} value={credDimension} onChange={changeDimension} hasBorderRight /> : null}
					{/*</FabricToolbarButton>*/}
					<FabricToolbarButton popover="Get Template ID">
						<FabricCopyId setCopyRendererConfigId={setCopyRendererConfigId} />
					</FabricToolbarButton>
					<FabricToolbarButton popover="Delete" borderRight={false}>
						<FabricDelete id={id} enableMultiPageCertificate={enableMultiPageCertificate} />
					</FabricToolbarButton>
				</div>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					width: "100%",
					borderRadius: "4px",
					height: "40px",
					backgroundColor: cloneActiveObject && cloneActiveObject.get("type") === "textbox" ? "white" : "#fafafa",
					padding: "0px 10px",
					justifyContent: "space-between",
					borderBottomLeftRadius: "0px",
					borderBottomRightRadius: "0px",
				}}>
				{cloneActiveObject && cloneActiveObject.get("type") === "textbox" && <FabricTextBox id={id} enableMultiPageCertificate={enableMultiPageCertificate} />}
			</div>
		</>
	);
};
export default FabricToolbar;
