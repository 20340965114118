import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AdvTextField from "../../components/AdvTextField";
import HeaderWithLine from "../../components/HeaderWithLine";
import AddButton from "../../components/NewImageButton";
import useUserHook from "../../hooks/useUserHook";
import { useSnackbar } from "notistack";
import useNProgressHook from "../../hooks/useNProgressHook";
import { AppBar, Toolbar } from "@material-ui/core";
import CreateOrgForm from "./createOrgForm";
import ipwruLogo from "../../assets/images/iPWRU_Logo_Med.png";
import { Container } from "@cti-workspace/ui";
import { getErrMsg, UploadImage, createOrganization } from "@cti-workspace/helpers";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const CreateOrganization = (props) => {
	let token = useQuery().get("token");
	let email = useQuery().get("email");
	let navigate = useNavigate();

	// console.log({ email });

	const { register, control, handleSubmit, errors, reset, watch, setError, clearErrors, getValues } = useForm({
		defaultValues: {
			domains: [{ key: "default", value: "https://www.ipwru.com" }],
			aliases: [{ key: "iPWRU" }],
			renderers: [{ key: "default", value: "IPWRU_DEFAULT" }],
			certificateStores: [{ key: "default", value: "" }],
			images: [],
		},
	});

	const { enqueueSnackbar } = useSnackbar();
	const { progressStart, progressDone } = useNProgressHook();
	const watchResources = watch("images");
	// const watchIssuers = watch("issuers");

	const validateFields = async (fieldName, removedIndex) => {
		if (watchResources?.length > 0) {
			const values = watchResources.map((value, index) => value.key !== "" && index !== removedIndex && value.key);
			values.forEach((value, index) => {
				if (value && value !== "" && values.indexOf(value) !== index)
					setError(`${fieldName}[${index}].key`, {
						type: "manual",
						message: "Resource key already exsists",
					});
				else clearErrors(`${fieldName}[${index}].key`);
			});
		}
	};

	const handleCreateOrganizationSubmit = async (event) => {
		if (errors && Object.keys(errors).length === 0) {
			let imageUrlsObj = {};
			try {
				progressStart();
				const formImages = event.images;
				if (formImages && formImages.length > 0) {
					for (const image of formImages) {
						let imageUrl = await UploadImage(image.value, undefined, "public-read", token);
						imageUrlsObj = {
							...imageUrlsObj,
							[image.key]: imageUrl,
						};
					}
					event["imageUrls"] = imageUrlsObj;
					delete event.images;
				}
				// put the default image, if the user have not selected
				if (!formImages) {
					event["imageUrls"] = {
						default: "https://res.cloudinary.com/dadlqfryt/image/upload/v1571451072/convergence-logos/Convergence_Logo_Final-01_tq5wtd.png",
					};
				}
				// convert the form to required format
				// const issuersArr = Object.keys(event?.issuers);
				// event["issuers"] = issuersArr.map((issuer) => (event["issuers"][issuer] ? issuer : null)).filter((item) => item);
				// event["aliases"] = (event["aliases"] && event["aliases"].map((item) => item.key)) || [];
				// if (event["certificateStores"]) {
				// 	event["certificateStores"].forEach((item) => {
				// 		event["certificateStores"] = {
				// 			[item.key]: item.value,
				// 		};
				// 	});
				// } else {
				// 	event["certificateStores"] = [];
				// }
				// event["domains"].forEach((item) => {
				// 	event["domains"] = {
				// 		[item.key]: item.value,
				// 	};
				// });

				event["admin"] = email;
				event["domains"] = {
					default: event["website"],
				};
				delete event["website"];

				event["renderers"] = {
					default: "TRYBE_DEFAULT",
				};
				// Default settings
				event["settings"] = {
					lms: false,
					bulkIssue: true,
					verifierConfiguration: {
						disablePrint: false,
						mobileDeviceHideRenderer: false,
						mobileDeviceHideRendererMessage: "Please view on larger device",
						hideContent: false,
						hideSections: false,
					},
				};

				// Setup the account
				const { firstName, lastName, password } = event;
				event["account"] = {
					email,
					roles: ["holder", "issuer"], // default for now, allow healthcerts too
					firstName,
					lastName,
					password,
				};
				delete event["email"];
				delete event["firstName"];
				delete event["lastName"];
				delete event["password"];
				let newOrg = await createOrganization(event, token);

				if (newOrg) {
					enqueueSnackbar(`Your organization was created! Please proceed to login.`, {
						variant: "success",
					});
					navigate("/login");
				}
				progressDone();
			} catch (err) {
				progressDone();
				enqueueSnackbar(getErrMsg(err), {
					variant: "error",
				});
			}
		}
	};

	const removeField = (fieldName, index, remove) => {
		validateFields(fieldName, index);
		remove(index);
	};

	return (
		<Container maxWidth="lg">
			<AppBar position="fixed" color="default">
				<Toolbar>
					<img src={ipwruLogo} style={{ width: "300px" }} />
				</Toolbar>
			</AppBar>
			<div style={{ marginTop: "75px" }} />
			<CreateOrgForm control={control} register={register} handleSubmit={handleSubmit(handleCreateOrganizationSubmit)} errors={errors} email={email} watch={watch} setError={setError} clearErrors={clearErrors} />
		</Container>
	);
};

CreateOrganization.propTypes = {};

export default CreateOrganization;
