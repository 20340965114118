import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { forwardRef } from "react";

const RichTextEditor = forwardRef(({ onChange, value, defaultValue, placeholder }, ref) => {
	const decodeHTML = (html) => {
		if (typeof html === "undefined") return "";
		let txt = document.createElement("textarea");
		txt.innerHTML = html;
		return txt.value;
	};

	let modules = {
		clipboard: {
			matchVisual: false,
		},
	};

	const isHTML = (text) => {
		try {
			const fragment = new DOMParser().parseFromString(text, "text/html");
			return fragment.body.children.length > 0;
		} catch (error) { }
		return false;
	};

	return (
		<>
			{/* <InputLabel id={name} variant={variant} error={has(errors, name)}>{name}</InputLabel> */}
			{/* <ReactQuill theme="snow" modules={modules} defaultValue={value} placeholder={placeholder} value={isHTML(value) ? value : decodeHTML(value)} onChange={(html) => onChange(html)} style={{ minHeight: "30px" }} /> */}
			<style>
				{
					`
						.quill .ql-toolbar {
							border-radius: 4px;
							border-bottom-left-radius: 0px;
							border-bottom-right-radius: 0px;
						}	
						.quill .ql-editor {
							min-height: 100px;
						}
						.quill .ql-container {
							border-radius: 4px;
							border-top-left-radius: 0px;
							border-top-right-radius: 0px;
						}
					`
				}
			</style>
			<ReactQuill
				theme="snow"
				modules={modules}
				ref={ref}
				defaultValue={isHTML(defaultValue) ? defaultValue : decodeHTML(defaultValue)}
				{...(value ? value : {})}
				placeholder={placeholder}
				onChange={(html) => onChange(html)}
				style={{ minHeight: "30px", backgroundColor: "white", borderRadius: "4px" }}
			/>
		</>
	);
});

export default RichTextEditor;
