import { useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import AlertDialog from "../AlertDialog";
import { useSnackbar } from "notistack";
import { getErrMsg, ISSUER_PREFIX, putApi, isContactValid } from "@cti-workspace/helpers";

const disableContinue = (sharingEmail, sharingCredential) => {
	return sharingEmail === "" || sharingCredential || !isContactValid(sharingEmail);
};

const TransferCredential = ({ primaryColor, showShareCredential, setShowShareCredential, credentialId, setSharedCredentialIds, publicUrl }) => {
	const { enqueueSnackbar } = useSnackbar();

	const [sharingEmail, setSharingEmail] = useState("");
	const [sharingCredential, setSharingCredential] = useState(false);

	const onClickContinue = async () => {
		try {
			setSharingCredential(true);
			const request = {
				credentialId,
				newRecipient: sharingEmail,
				dbl: new URL(publicUrl).searchParams.get("dbl"),
			};
			await putApi(`/${ISSUER_PREFIX}/transferCredential`, request);
			enqueueSnackbar(`Credential ${credentialId} successfully transfered to ${sharingEmail}`, {
				variant: "success",
			});
			setSharedCredentialIds(credentialId);
			setShowShareCredential(false);
		} catch (e) {
			enqueueSnackbar(getErrMsg(e, `Failed to transfer credential. Please try again`), {
				variant: "error",
			});
		} finally {
			setSharingCredential(false);
		}
	};

	const onCleanup = () => {
		setSharingEmail("");
		setShowShareCredential(false);
	};

	return (
		<AlertDialog
			primaryColor={primaryColor}
			title="Transfer Credential"
			open={showShareCredential}
			cleanup={onCleanup}
			continueToAdd={onClickContinue}
			disableContinue={disableContinue(sharingEmail, sharingCredential)}
			autoCleanupAfterContinue={false}
			body={
				<Grid container>
					<Grid item xs={12}>
						Please enter a valid email address.
					</Grid>
					<Grid item xs={12}>
						<TextField fullWidth={true} onChange={(event) => setSharingEmail(event.target.value)} value={sharingEmail} required />
					</Grid>
				</Grid>
			}
		/>
	);
};

export default TransferCredential;
