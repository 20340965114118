import { useState, useCallback } from "react";
import jwt from "jsonwebtoken";
import { AdminRoute } from "../../routes/admin";
import { HomeRoute } from "../../routes/home";
import { ProfileRoute } from "../../routes/profile";
import { WalletRoute } from "../../routes/wallet";
import { NewCredDesign } from "../../routes/newCredDesign";
import { NewCredConfig } from "../../routes/newCredConfig";
import IssuedCredentials from "../../routes/issuedCredentials/issuedCredentials";
import VisualDefineCredential from "../../components/VisualDefineCredential";

import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import EqualizerIcon from "@material-ui/icons/Equalizer";

import CredentialIssue from "../../components/CredentialIssue";
import ViewCredential from "../../components/ViewCredential";
import {
	JWT_TOKEN,
  JWT_PUB,
  API_URL,
	ALGORITHM,
	AUTH_PREFIX,
	ACCOUNTS_PREFIX,
	DEFAULT_ORG,
	LOGIN_ROUTE,
	GOOGLE_CLASSROOM_EMAIL,
	MULTI_ORGS,
	SETTINGS,
	BATCH_LABELS,
	ICONS,
	CANVAS_EMAIL,
	BRIDGE_EMAIL,
	TCSION_EMAIL,
	SCHOOLOGY_EMAIL,
	BRIGHTSPACE_USER,
	DEFAULT_EMAIL,
  USER_ALTERNATE_EMAILS,
  SSO_PAYLOAD_VERIFICATION_KEY,
	postApi,
} from "@cti-workspace/helpers";

import CardMembershipIcon from "@material-ui/icons/CardMembership";
import Analytics from "../../routes/analytics";
import useSettingsHook from "../useSettingsHook";
import OrganizationAdmin from "../../routes/orgAdmin";
import { isCapable } from "../../routes/admin/userAccounts/permissionUtils";
import CAPABILITIES from "../../capabilities";
import PresentationCatalog from "../../routes/presentationCatalog";
import { Presentation, QrCode } from "@phosphor-icons/react";
import DefinePresentation from "../../routes/presentationCatalog/definePresentation";
import VerifyCredentials from "../../routes/verifyCredentials";
import PresentationCardVerifier from "../../routes/presentationCatalog/presentationCardVerifier";
import NFTCatalog from "../../routes/nft";
import NFTIcon from "../../routes/nft/NFTIcon";
import MintNft from "../../routes/nft/MintNft";
import Pathways from "../../routes/pathways";
import PathwayIcon from "../../routes/pathways/components/PathwayIcon";
import DefinePathway from "../../routes/definePathway/definePathway";
import MainPathway from "../../routes/pathway";
import { CreatePathway } from "../../routes/createPathway/createPathway";

const clearLocalStorage = () => {
	localStorage.removeItem(JWT_TOKEN);
	localStorage.removeItem(BATCH_LABELS);
	localStorage.removeItem(ICONS);
	localStorage.removeItem(GOOGLE_CLASSROOM_EMAIL);
	localStorage.removeItem(BRIDGE_EMAIL);
	localStorage.removeItem(CANVAS_EMAIL);
	localStorage.removeItem(SCHOOLOGY_EMAIL);
	localStorage.removeItem(BRIGHTSPACE_USER);
	localStorage.removeItem(TCSION_EMAIL);
	localStorage.removeItem(MULTI_ORGS);
	localStorage.removeItem(SETTINGS);
	localStorage.removeItem(DEFAULT_EMAIL);
	localStorage.removeItem(USER_ALTERNATE_EMAILS);
};

const UserHook = () => {
	const pub = JWT_PUB.replace(/\\n/g, "\n");
	const algorithms = [ALGORITHM];

	const { canView, getSettings } = useSettingsHook();
	const [loggedInProvider, setLoggedInProvider] = useState("");
	const [loggedInEmail, setLoggedInEmail] = useState("");
	const [loggedInBridgeEmail, setloggedInBridgeEmail] = useState("");
	const [loggedInBrightspaceUser, setloggedInBrightspaceUser] = useState("");
	const [loggedInCanvasEmail, setloggedInCanvasEmail] = useState("");
	const [loggedInSchoologyEmail, setLoggedInSchoologyEmail] = useState("");
	const [loggedInTCSIONEmail, setloggedInTCSIONEmail] = useState("");

	const privateRoutes = [
		{
			level: 2,
			path: "/admin",
			name: "SuperAdmin",
			component: AdminRoute,
			type: "route",
			icon: <SupervisorAccountIcon />,
			roles: ["admin"],
		},
		{
			level: 1,
			path: "/orgAdmin",
			name: "Admin",
			component: OrganizationAdmin,
			type: "route",
			icon: <SupervisorAccountIcon />,
			roles: ["orgAdmin", "admin"],
		},
		{
			level: 1,
			path: "/home",
			name: "Credential Catalog",
			component: HomeRoute,
			type: "route",
			icon: <HomeIcon />,
			capability: CAPABILITIES.ACCESS_CREDENTIAL_CATALOG,
			roles: ["issuer", "verifier", "admin"],
		},
		{
			level: 3,
			path: "/presentation-catalog",
			component: PresentationCatalog,
			type: "route",
			name: "Presentation Catalog",
			icon: <Presentation size={24} />,
			roles: ["verifier", "admin"],
		},
		{
			level: 3,
			path: "/verify-credentials",
			component: VerifyCredentials,
			type: "route",
			name: "Verify Credentials",
			icon: <QrCode size={24} />,
			roles: ["verifier", "admin"],
		},
		{
			level: 1,
			path: "/issuedcredentials",
			name: "Issued Credentials",
			component: IssuedCredentials,
			type: "route",
			icon: <CardMembershipIcon />,
			capability: CAPABILITIES.ACCESS_ISSUED_CREDENTIALS,
			roles: ["issuer", "admin"],
		},
		{
			level: 1,
			path: "/pathways",
			component: Pathways,
			type: "route",
			name: "Pathways",
			icon: <PathwayIcon />,
			roles: ["issuer", "admin"],
		},
		{
			level: 1,
			path: "/pathways/new",
			component: DefinePathway,
			type: "action",
			roles: ["issuer", "admin"],
		},
		{
			level: 1,
			path: "/pathway/:id/*",
			component: MainPathway,
			type: "action",
			roles: ["issuer", "admin"],
			showAppBar: false,
		},
		{
			level: 0,
			path: "/wallet",
			name: "Wallet",
			component: WalletRoute,
			type: "route",
			icon: <AccountBalanceWalletIcon />,
			roles: ["holder", "admin"],
		},
		{
			level: 1,
			path: "/profile",
			name: "Profile",
			component: ProfileRoute,
			type: "route",
			icon: <PersonIcon />,
			// capability: CAPABILITIES.ACCESS_PROFILE,
			roles: ["admin", "orgAdmin", "issuer", "holder", "verifier"],
		},
		{
			level: 0,
			path: "/analytics",
			name: "Analytics",
			component: Analytics,
			type: "route",
			icon: <EqualizerIcon />,
			roles: ["issuer", "admin"],
		},
		{
			level: 1,
			path: "/credentialDefinition/new",
			component: VisualDefineCredential,
			type: "action",
			roles: ["issuer", "admin"],
		},
		{
			level: 1,
			path: "/credentialDefinition/:id/edit",
			component: VisualDefineCredential,
			type: "action",
			roles: ["issuer", "admin"],
		},
		{
			level: 1,
			path: "/credentialDefinition/:id/view",
			component: ViewCredential,
			type: "action",
			roles: ["issuer", "admin"],
		},
		{
			level: 1,
			path: "/credentialDefinition/:id/design",
			component: NewCredDesign,
			type: "action",
			roles: ["issuer", "admin"],
		},
		{
			level: 1,
			path: "/credentialDefinition/:id/config",
			component: NewCredConfig,
			type: "action",
			roles: ["issuer", "admin"],
		},
		{
			level: 1,
			path: "/credentialIssue/:id/new",
			component: CredentialIssue,
			type: "action",
			roles: ["issuer", "admin"],
		},
		{
			level: 3,
			path: "/define-presentation/new",
			component: DefinePresentation,
			type: "action",
			roles: ["verifier", "admin"],
		},
		{
			level: 3,
			path: "/define-presentation/:id/edit",
			component: DefinePresentation,
			type: "action",
			roles: ["verifier", "admin"],
		},
		{
			level: 3,
			path: "verify-presentation",
			component: PresentationCardVerifier,
			type: "action",
			roles: ["verifier", "admin"],
		},
		{
			level: 0,
			path: "/nft/",
			name: "NFT Catalog",
			component: NFTCatalog,
			type: "route",
			icon: <NFTIcon />,
			roles: ["issuer", "admin"],
		},
		{
			level: 0,
			path: "/nft/mint",
			component: MintNft,
			type: "action",
			roles: ["issuer", "admin"],
		},
	];

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const initialState = {
		hasToken: false,
		validToken: false,
		isLoggedIn: false,
		jwtToken: {},
		account: {},
		routeState: {
			allowedPRoutes: [{}],
			deniedPRoutes: privateRoutes,
		},
	};

	const routesPerAccountType = ({ roles, capabilities }) => {
		const canViewAnalytics = canView("analytics", true) && isCapable(capabilities, CAPABILITIES.ACCESS_ANALYTICS);
		const canViewNFT = canView("enableNft", true);
		const canViewPathways = canView("enablePathways", true);
		let privateRoutesToView = [];
		privateRoutes.forEach((privateRoute) => {
			if (!privateRoute.capability || isCapable(capabilities, privateRoute.capability)) {
				privateRoutesToView.push(privateRoute);
			}
		});
		if (!canViewAnalytics) {
			privateRoutesToView = privateRoutesToView.filter((item) => item.path !== "/analytics");
		}

		if (!canViewNFT) {
			privateRoutesToView = privateRoutesToView.filter((item) => !item.path.includes("/nft/"));
		}

		if (!canViewPathways) {
			privateRoutesToView = privateRoutesToView.filter((item) => !item.path.includes("/pathways"));
		}

		const allowedPRoutes = privateRoutesToView.filter((item) => (item.roles || []).filter((item) => roles.includes(item)).length > 0);
		const deniedPRoutes = privateRoutesToView.filter((item) => !(item.roles || []).filter((item) => roles.includes(item)).length > 0);

		return { allowedPRoutes, deniedPRoutes };

		// all the code bellow is unreachable [NAGA - why is this here] - removed old code
	};

	const [userState, setState] = useState(() => {
		let value = { ...initialState };
		try {
			let jwtToken = localStorage.getItem(JWT_TOKEN);
			if (jwtToken) {
				value.hasToken = true;
				value.isLoggedIn = true;
				value.jwtToken = true;
				value.jwtToken = jwtToken;
				try {
          let account = jwt.verify(jwtToken, pub, { algorithms });
					value.account = account;
					const defaultEmail = localStorage.getItem(DEFAULT_EMAIL);
					if (defaultEmail) {
						value.account.email = defaultEmail;
					}
					const alternateEmails = localStorage.getItem(USER_ALTERNATE_EMAILS);
					if (alternateEmails) {
						value.account.emails = JSON.parse(alternateEmails);
					}
					value.routeState = routesPerAccountType(account);
				} catch (err) {
					// JWT is expired
          console.log(`useUserHook: JWT is expired : ${JSON.stringify(err)} ${err}`);
					clearLocalStorage();
					window.location.href = "/login";
				}
			}
		} catch (error) {
			value = {
				hasToken: true,
				validToken: false,
				isLoggedIn: false,
				jwtToken: {},
				account: {},
				routeState: {
					allowedPRoutes: [],
					deniedPRoutes: privateRoutes,
				},
			};
			console.error(error);
		}

		return value;
	});

	const userLogIn = async ({ email, password, organization }, passwordResetData = null, globalEdSSO = false, googleSSO = false, googleSSOData = null) => {
		// dbAccount._id, accountID, orgid

		if (!organization) {
			try {
				let data = null;

				if (globalEdSSO) {
					//GlobalEd SSO login - we can use this for any Global Ed Style logins including MENA
					data =
            passwordResetData || (await postApi(`/${AUTH_PREFIX}/globalEd/login`, {
              email, "payloadkey": SSO_PAYLOAD_VERIFICATION_KEY
            }));
				} else if (googleSSO) {
					//Google SSO login
					const tokenId = googleSSOData.tokenId;
					data =
						passwordResetData ||
						(await postApi(`/${AUTH_PREFIX}/oAuthGoogleLogin`, {
							tokenId,
						}));
				} else {
					data =
						passwordResetData ||
						(await postApi(`/${AUTH_PREFIX}/${LOGIN_ROUTE}/`, {
							email,
							password,
						}));
				}

				// TODO: remove this check once the passwordReset endpoint is migrated to new response format
				if (!passwordResetData) {
					data = data.payload;
				}
				if (!data) throw new Error("User data not found");
				let { dbAccount } = data;
				localStorage.setItem(JWT_TOKEN, data.token);
				if (dbAccount?.organizations?.default || (dbAccount.roles.length === 1 && dbAccount.roles.includes("holder"))) {
					// cookie.set(JWT_TOKEN, data.token);
					await getSettings(data.dbAccount);

					// call this after organization is set
					setState({
						hasToken: true,
						validToken: true,
						isLoggedIn: true,
						jwtToken: data.token,
						account: data.dbAccount,
						routeState: routesPerAccountType(data.dbAccount),
					});
				} else {
					// store the organizations data in the sessionStorage
					localStorage.setItem(MULTI_ORGS, JSON.stringify(dbAccount?.organizations));
				}

				return data;
			} catch (error) {
				clearLocalStorage();
				setState({ ...initialState });
				console.log(error);
				throw error;
			}
		} else {
			try {
				let data = await postApi(`/${ACCOUNTS_PREFIX}/${DEFAULT_ORG}/`, {
					...organization,
				});
				if (!data) throw new Error("User data not found");

				localStorage.setItem(JWT_TOKEN, data.token);

				await getSettings(data.dbAccount);
				// call this after organization is set

				setState({
					hasToken: true,
					validToken: true,
					isLoggedIn: true,
					jwtToken: data.token,
					account: data.dbAccount,
					routeState: routesPerAccountType(data.dbAccount),
				});

				return data;
			} catch (error) {
				clearLocalStorage();
				setState({ ...initialState });
				console.log(error);
				throw error;
			}
		}
	};

	const setDefaultOrg = async (organization) => {
		try {
			let data = await postApi(`/${ACCOUNTS_PREFIX}/${DEFAULT_ORG}/`, {
				...organization,
			});
			if (!data) throw new Error("User data not found");

			localStorage.setItem(JWT_TOKEN, data.token);

			await getSettings(data.dbAccount);

			// logout from LMS
			logoutLMS();

			// call this after organization is set
			setState({
				hasToken: true,
				validToken: true,
				isLoggedIn: true,
				jwtToken: data.token,
				account: data.dbAccount,
				routeState: routesPerAccountType(data.dbAccount),
			});

			return data;
		} catch (error) {
			clearLocalStorage();
			setState({ ...initialState });
			console.log(error);
			throw error;
		}
	};

	const logoutLMS = async () => {
		setLoggedInProvider("");

		setloggedInCanvasEmail("");
		localStorage.removeItem(CANVAS_EMAIL);

		setloggedInBridgeEmail("");
		localStorage.removeItem(BRIDGE_EMAIL);

		setloggedInBrightspaceUser("");
		localStorage.removeItem(BRIGHTSPACE_USER);

		setLoggedInEmail("");
		localStorage.removeItem(GOOGLE_CLASSROOM_EMAIL);

		setLoggedInSchoologyEmail("");
		localStorage.removeItem(SCHOOLOGY_EMAIL);

		setloggedInTCSIONEmail("");
		localStorage.removeItem(TCSION_EMAIL);
	};

	const userLogOut = useCallback(async () => {
		clearLocalStorage();
		setState({ ...initialState });
	}, [initialState]);

	const setUserState = (data) => {
		setState({ ...data });
	};

	return {
		userState,
		loggedInProvider,
		loggedInEmail,
		loggedInBridgeEmail,
		loggedInBrightspaceUser,
		loggedInCanvasEmail,
		loggedInSchoologyEmail,
		loggedInTCSIONEmail,
		userLogIn,
		userLogOut,
		setDefaultOrg,
		setUserState,
	};
};

export default UserHook;
