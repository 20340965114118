import { getApi } from "../api";

async function getRecipientCerts(baseUrl, email) {
	// eslint-disable-next-line no-useless-catch
	try {
		let url = `${baseUrl}/getRecipientCerts/${email}`;
		const recipientCerts = await getApi(url);
		return recipientCerts;
	} catch (err) {
		throw err;
	}
}

export default getRecipientCerts;
