import { CaretDoubleLeft, CaretDoubleRight, CaretLeft, CaretRight } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import styled from "styled-components";

const PaginationContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

const ContentText = styled.div`
	font-size: 0.9rem;
`;

const PaginationIconsContainer = styled.div`
	margin-left: 1.5rem;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	align-items: center;
	& > div {
		display: flex;
		padding: 0.5rem 1rem;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
`;

export function Pagination({ size = 20, labelStyle = {}, showLabel = true, appendLabelText = "Showing", totalItems = 100, pageSize = 10, page = 1, onPageChange = () => {}, iconColor = "black" }) {
	const [currentPage, setCurrentPage] = useState(1);
	const [firstPageBtnDisable, setFirstPageBtnDisable] = useState(false);
	const [prevPageBtnDisable, setPrevPageBtnDisable] = useState(false);
	const [nextPageBtnDisable, setNextPageBtnDisable] = useState(false);
	const [lastPageBtnDisable, setLastPageBtnDisable] = useState(false);

	useEffect(() => {
		setCurrentPage(page);
	}, [page]);

	useEffect(() => {
		setFirstPageBtnDisable(currentPage === 1 ? true : false);
		setPrevPageBtnDisable(currentPage === 1 ? true : false);
		setNextPageBtnDisable(currentPage * pageSize >= totalItems ? true : false);
		setLastPageBtnDisable(currentPage * pageSize >= totalItems ? true : false);
	}, [currentPage]);

	const onClickFirstPage = () => {
		if (firstPageBtnDisable) return;
		setCurrentPage(1);
		setFirstPageBtnDisable(true);
		setPrevPageBtnDisable(true);
		onPageChange && onPageChange({ page: 1, pageSize });
	};

	const onClickPreviousPage = () => {
		if (prevPageBtnDisable) return;
		setCurrentPage(currentPage - 1);
		onPageChange && onPageChange({ page: currentPage - 1, pageSize });
	};

	const onClickNextPage = () => {
		if (nextPageBtnDisable) return;
		setCurrentPage(currentPage + 1);
		onPageChange && onPageChange({ page: currentPage + 1, pageSize });
	};

	const onClickLastPage = () => {
		if (lastPageBtnDisable) return;
		const pageNum = Math.ceil(totalItems / pageSize);
		setNextPageBtnDisable(true);
		setLastPageBtnDisable(true);
		setCurrentPage(pageNum);
		onPageChange && onPageChange({ page: pageNum, pageSize });
	};

	return (
		<PaginationContainer>
			{appendLabelText && <ContentText style={labelStyle}>{appendLabelText}&nbsp;</ContentText>}
			{showLabel && (
				<ContentText style={labelStyle}>
					{currentPage * pageSize >= totalItems ? totalItems - pageSize : currentPage === 1 ? 1 : (currentPage - 1) * pageSize} - {currentPage * pageSize >= totalItems ? totalItems : currentPage * pageSize} of {totalItems}
				</ContentText>
			)}
			<PaginationIconsContainer>
				<div onClick={onClickFirstPage}>
					<CaretDoubleLeft size={size} color={firstPageBtnDisable ? "gray" : iconColor} />
				</div>
				<div onClick={onClickPreviousPage}>
					<CaretLeft size={size} color={prevPageBtnDisable ? "gray" : iconColor} />
				</div>
				<div onClick={onClickNextPage}>
					<CaretRight size={size} color={nextPageBtnDisable ? "gray" : iconColor} />
				</div>
				<div onClick={onClickLastPage}>
					<CaretDoubleRight size={size} color={lastPageBtnDisable ? "gray" : iconColor} />
				</div>
			</PaginationIconsContainer>
		</PaginationContainer>
	);
}

export default Pagination;
