import { getErrMsg, getObjectValue, hasProperty, PATHWAY_PREFIX, putApi } from "@cti-workspace/helpers";
import { Button, CheckBox, RadioButton, RadioGroup } from "@cti-workspace/ui";
import { Divider, Grid, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import ipwruLogo from "../../../assets/images/iPWRU_Logo_Med.png";
import { ControllerInput, CredentialAsyncComboBox } from "../../definePathway/definePathway";
import { PathwayDetailsSection, PathwayDetailItem, BtnFullWidth } from "../components/pathwayComponents";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function AddStepPathway() {
	const { id, leafId } = useParams();

	const [childRef, steps] = useOutletContext();

	const getActiveLeaf = steps.filter((item) => item.stepId === leafId)[0];
	const getActiveParent = steps.filter((item) => item.level === 0)[0];
	const activeCard = leafId ? getActiveLeaf : getActiveParent;

	const { enqueueSnackbar } = useSnackbar();

	const { control, handleSubmit, errors } = useForm({
		defaultValues: {
			name: "",
			description: "",
			credentialId: "",
			stepType: "ACTIVITY",
			required: false,
		},
	});

	const navigate = useNavigate();

	useEffect(() => {
		if (childRef && activeCard) {
			childRef.current.setHighlighted(activeCard.stepId);
		}
	}, []);

	const queryClient = useQueryClient();

	const addStepMutation = useMutation(({ data, id }) => putApi(`/${PATHWAY_PREFIX}/addPathwayStep/${id}`, { ...data }), {
		// When mutate is called:
		onMutate: async ({ data, id }) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries(["pathway", id]);

			// Snapshot the previous value
			const previousPathways = queryClient.getQueryData(["pathway", id]);

			// Optimistically update to the new value
			if (previousPathways) {
				queryClient.setQueryData(["pathway", id], {
					...previousPathways,
					steps: [...previousPathways.steps, { stepId: Math.random().toString(), ...data }],
				});
			}

			return { previousPathways };
		},
		onSuccess: (res) => {
			enqueueSnackbar("Step added successfully.", { variant: "success" });
		},
		// If the mutation fails, use the context returned from onMutate to roll back
		onError: (err, variables, context) => {
			if (context?.previousPathways) {
				queryClient.setQueryData(["pathway", id], context.previousPathways);
			}
			console.error(err);
			enqueueSnackbar(`The Step could not be added. ${err?.message}`, { variant: "error" });
		},
		// Always refetch after error or success:
		onSettled: () => {
			queryClient.invalidateQueries(["pathway", id]);
		},
	});

	const onSubmit = async (event) => {
		// TODO: check if leafId, then call different API
		const { name, description, stepType, required } = event;
		const level = activeCard.level + 1;
		const parentId = activeCard.stepId;
		const type = stepType; // "MILESTONE" or "ACTIVITY"
		const isRequired = required;
		const status = "ACTIVE";

		const data = { ...event, name: name.trim(), description: description ? description.trim() : "", level, parentId, type, isRequired, status };
		try {
			addStepMutation.mutate({ data, id });
			// enqueueSnackbar(res?.message || "Successfully updated pathway", { variant: "success" });
			if (leafId) {
				navigate(`/pathway/${id}/${leafId}`, { replace: true });
			} else {
				navigate(`/pathway/${id}`, { replace: true });
			}
		} catch (error) {
			console.error(error);
			enqueueSnackbar(getErrMsg(error), { variant: "error" });
		}
		childRef.current.clearHighlighting();
	};

	return (
		<PathwayDetailsSection>
			<PathwayDetailItem>
				<img src={ipwruLogo} style={{ width: "13rem" }} alt="iPWRU" />
			</PathwayDetailItem>
			<Divider />
			<PathwayDetailItem text>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="subtitle1" style={{ fontWeight: "800" }}>
							Add a Step
						</Typography>
						<Controller
							control={control}
							name="stepType"
							render={({ value, onChange, name }) => {
								return (
									<RadioGroup aria-label="Step Type" value={value} name={name} style={{ marginLeft: "-4px" }} onChange={onChange}>
										<RadioButton value="ACTIVITY" label="Activity" />
										{/* <RadioButton value="MILESTONE" label="Milestone" /> */}
									</RadioGroup>
								);
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<CheckBox color="secondary" name={"required"} control={control} defaultChecked={false} label="Required" />
						</div>
					</Grid>

					<Grid item xs={12}>
						<ControllerInput control={control} name="name" errors={errors} label="Name" />
					</Grid>
					<Grid item xs={12}>
						<ControllerInput control={control} name="description" errors={errors} label="Description" required={false} multiline rows={3} />
					</Grid>
					<Grid item xs={12}>
						<Controller
							control={control}
							name="credentialId"
							rules={{
								required: "Credential is required",
							}}
							render={(render) => <CredentialAsyncComboBox label="Credential" required placeholder="Select your credential" {...render} error={hasProperty(errors, "credentialId")} helperText={getObjectValue(errors, `${"credentialId"}.message`)} />}
						/>
					</Grid>
				</Grid>
			</PathwayDetailItem>
			<PathwayDetailItem text flexBottom>
				<div style={{ marginTop: "1rem", display: "flex", width: "100%", gridGap: "0.5rem" }}>
					<BtnFullWidth>
						<Button
							color="white"
							onClick={() => {
								leafId ? navigate(`/pathway/${id}/${leafId}`, { replace: true }) : navigate(`/pathway/${id}`, { replace: true });
								childRef.current.clearHighlighting();
							}}>
							Cancel
						</Button>
					</BtnFullWidth>
					<BtnFullWidth>
						<Button color={"secondary"} onClick={handleSubmit(onSubmit)}>
							Add
						</Button>
					</BtnFullWidth>
				</div>
			</PathwayDetailItem>
		</PathwayDetailsSection>
	);
}
