import { FormControl, TextField } from "@material-ui/core";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { Button } from "@cti-workspace/ui";
import { useState } from "react";

const SearchNft = ({ onSearch = () => {} }) => {
	const [searchValue, setSearchValue] = useState("");

	const onClickClearSearch = () => {
		setSearchValue("");
		onSearch("");
	};

	return (
		<div>
			<FormControl fullWidth>
				<TextField
					id="standard-adornment-amount"
					fullWidth
					color="secondary"
					InputProps={{
						startAdornment: <MagnifyingGlass size="1.3rem" weight="bold" style={{ marginRight: "1rem" }} />,
						endAdornment: (
							<>
								{searchValue && <Button buttonType="icon" icon="cancel" invert color="danger" iconSize="1.3rem" buttonStyle={{ marginRight: "0.5rem" }} onClick={onClickClearSearch} />}
								<Button color="warning" invert={!searchValue} onClick={() => onSearch(searchValue)}>
									Search
								</Button>
							</>
						),
					}}
					variant="outlined"
					size="small"
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
				/>
			</FormControl>
		</div>
	);
};

export default SearchNft;
