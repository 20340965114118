import { ISSUER_PREFIX } from "../lib/Constants";
import { postApi } from "../lib/Utils";

export default function useRevokeHook() {
    const searchRevokeByIdentifier = async (data = {}) => {
        try {
            const res = await postApi(`/${ISSUER_PREFIX}/bulkCredentialStatus`, data);
            return res;
        } catch (error) {
            throw error;
        }
    }
    const revokeBulkCredentials = async (data = {}) => {
        try {
            const res = await postApi(`/${ISSUER_PREFIX}/bulkRevokeCredentials`, data);
            return res;
        } catch (error) {
            throw error;
        }
    }
    return {
        searchRevokeByIdentifier,
        revokeBulkCredentials
    }
}