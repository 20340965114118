import { useState } from "react";
import { Button, DropzoneDialog, TextField, Icon, Card, Typography as Text, CheckBox } from "@cti-workspace/ui";
import Typography from "@material-ui/core/Typography";
import { useFieldArray, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import { getErrMsg, CHOOSE_BADGE, getObjectValue } from "@cti-workspace/helpers";

const DropZone = (props) => {
	const { setSaveIcon, name, control, register, iconData } = props;
	const fileTypes = ["image/jpeg", "image/png", "image/bmp"];
	const [showUploadDialog, setShowUploadDialog] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const arrayField = useFieldArray({
		control: control,
		name,
		keyName: "id",
	});

	const { fields, remove, insert } = arrayField;

	const onUpload = async (file) => {
		if (Object.keys(file).length) {
			try {
				if (file.size > 5120000) throw new Error("File size too large, should be less than 5MB");
				const fileName = file.name;
				const url = file.src;
				const key = props.useInitialKeyName ? fileName : fields.length === 0 ? "default" : fileName;
				insert(0, { key: key, type: "file", value: url });
				remove(1);
			} catch (err) {
				enqueueSnackbar(getErrMsg(err), { variant: "error" });
			}
		}
		setShowUploadDialog(false);
	};

	const onError = (err) => {
		enqueueSnackbar(getErrMsg(err), { variant: "error" });
	};

	const setBadge = (icon) => {
		insert(0, { key: icon.iconName, type: "file", value: icon.iconURL });
		remove(1);
	};

	let errored = getObjectValue(props?.errors, props?.name);

	return (
		<Card hasBorder elevation={0} borderRadius="2px" style={{ backgroundColor: "#FAFAFA", padding: "0.8rem", margin: "0" }}>
			{fields.length === 0 ? (
				<div style={{ display: "flex", gap: "0.8rem" }}>
					<div style={{ height: "96px", width: "100px", backgroundColor: "#E0E0E0", borderRadius: "4px", display: "flex", alignItems: "center", justifyContent: "center" }}>
						<Icon name="image" size={32} />
					</div>
					<div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
						<Text type="body1">{CHOOSE_BADGE}</Text>
						<Button onClick={() => setShowUploadDialog(true)} leftIcon="image" color="warning" invert buttonStyle={{ padding: "6px" }} leftIconColor="black" borderRadius="4px">
							Add Image
						</Button>
					</div>
				</div>) : (
				<div>
					{fields.map((item, index) => (
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ display: "flex", gap: "0.8rem" }}>
								<div style={{ width: "100px", height: "96px", backgroundColor: "#E0E0E0", padding: "0.25rem", borderRadius: "4px", display: "flex", justifyContent: "center", alignItems: "center" }}>
									<img style={{ maxWidth: "100%", maxHeight: "100%" }} src={item.value} />
								</div>
								<div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
									<Controller
										control={control}
										name={`${props?.name}[${index}].key`}
										rules={{
											required: "Name is required",
										}}
										defaultValue={item.key === "default" && props.defaultIconName ? props.defaultIconName : item.key}
										render={(propsField) => {
											const { value, onChange, name } = propsField;
											return (
												<TextField
													name={name}
													id={`${props?.name}${index}`}
													error={errored?.length && errored[index] && errored[index].key ? true : false}
													onChange={onChange}
													value={value}
													label="Name"
													variant="static"
													size="small"
													helperText={errored?.length && errored[index] ? getObjectValue(errored[index], "key.message", "") : ""}
													labelStyle={{ lineHeight: "0px" }}
												/>
											);
										}}
									/>
									<TextField
										style={{ display: "none" }}
										inputRef={props?.register({
											required: "Value is required",
										})}
										id={`${props?.name}${index}-value`}
										name={`${props?.name}[${index}].value`}
										value={item.value}
										fullWidth
										label="Value"
										hidden
									/>
									<CheckBox register={register} checked={props.saveIcon} onChange={(e) => setSaveIcon(e.target.checked)} name="checkedB" color="primary" label="Save image" labelStyle={{ fontSize: "0.8rem", marginLeft: "-10px" }} />
								</div>
							</div>
							<div style={{ display: "flex", gap: "0.5rem" }}>
								<Button invert leftIconColor="black" borderRadius="4px" leftIcon="delete" onClick={() => remove(index)}>Delete</Button>
								<Button color="warning" invert leftIcon="image" leftIconColor="black" borderRadius="4px" onClick={() => setShowUploadDialog(true)}>Change</Button>
							</div>
						</div>
					))}
				</div>
			)}

			{props.selectedIconIndex !== -1 && (
				<div style={{ marginTop: 6, textAlign: "center" }}>
					<img width={64} src={getObjectValue(iconData, props.selectedIconIndex).iconURL} alt="Selected Icon" />
					<Typography>{getObjectValue(iconData, props.selectedIconIndex).iconName}</Typography>
				</div>
			)}

			<DropzoneDialog
				submitButtonText="Upload"
				open={showUploadDialog}
				onSave={onUpload}
				acceptedFiles={fileTypes}
				dialogTitle="Add Image"
				// dropzoneText={"Drag and Drop / Select"}
				dropzoneText="Drag n Drop/Select from Device"
				showPreviews
				clearOnUnmount
				maxFileSize={5000000}
				displaySavedFiles={iconData && iconData.length > 0 ? <div style={{ marginTop: "1rem" }}>
					<Text type="body3">Saved images</Text>
					<div style={{ display: "flex", gap: "0.4rem", flexWrap: "wrap", marginTop: "0.4rem" }}>
						{iconData.map((icon) => (
							<div style={{ border: "1px solid #FAFAFA", borderRadius: "4px", width: "50px", height: "50px", display: "flex", alignItems: "center", justifyContent: "center", padding: "0.25rem", cursor: "pointer" }}
								onClick={() => {
									setBadge(icon);
									setShowUploadDialog(false);
								}}>
								<img
									src={icon.iconURL}
									style={{ maxWidth: "100%", maxHeight: "100%" }}
									alt="SelectedIcon"

								/>
							</div>
						))}
					</div>
				</div> : null
				}
				onError={onError}
				onClose={() => setShowUploadDialog(false)}
			/>
		</Card>
	);
};

export default DropZone;
