const disableIssuedCredentialView = (issuedCredential) => {
  if (issuedCredential.pdfKey) {
    return false;
  }
  if (issuedCredential.shortURLs) {
    //  !undefined => true
    //  !value => false
    return !issuedCredential.shortURLs?.publicUrl;
  }
  return !issuedCredential.publicUrl;
};

// The function should return False , so as to not disable the view
// True will disable the view

export default disableIssuedCredentialView;
