import { useContext } from "react";
import { FabricContext } from "../../context/FabricContext";
import { Button } from "@cti-workspace/ui";
import { TrashSimple } from "@phosphor-icons/react";
import { getObjectValue } from "@cti-workspace/helpers";

const FabricDelete = ({ id, enableMultiPageCertificate }) => {
	const { canvas } = useContext(FabricContext);

	const removeObjects = () => {
		const activeCanvas = enableMultiPageCertificate ? getObjectValue(canvas, id) : canvas;
		activeCanvas.getActiveObjects().forEach((obj) => {
			activeCanvas.remove(obj);
		});
		activeCanvas.discardActiveObject().renderAll();
	};

	return <TrashSimple weight="fill" onClick={removeObjects} size={17} />;
};

export default FabricDelete;
