import { useCallback, createContext, useState, useImperativeHandle, forwardRef } from "react";
import { fabric } from "fabric-pure-browser";
import { initAligningGuidelines, initCenterAlign } from "../components/handlers/aligning_guidelines";
import useSettingsHook from "../../../../../hooks/useSettingsHook";
import { useParams } from "react-router-dom";

export const FabricContext = createContext([]);

export const FabricContextProvider = forwardRef(({ children, total, control }, ref) => {
	const [canvas, setCanvas] = useState(null);
	const [activeObject, setActiveObject] = useState(null);

	const canvasLengthCount = Array.isArray(canvas) ? canvas.length : !!canvas;

	const params = useParams();
	const { canView } = useSettingsHook();
	const { fabricCertDesigner: defaultFabricCertDesigner, designType, rendererConfigId } = control?.defaultValuesRef?.current ?? {};
	const enableMultiPageCertificate = canView("enableMultiPageCertificate", true) && ((params.id && defaultFabricCertDesigner?.isMultiPage) || designType === "template" || !rendererConfigId || !params?.id);

	const removeCanvas = (index) => {
		if (canvas) setCanvas([...canvas.slice(0, index), ...canvas.slice(index + 1, canvas.length)]);
		if (activeObject) setActiveObject([...activeObject.slice(0, index), ...activeObject.slice(index + 1, activeObject.length)]);
	};

	useImperativeHandle(ref, () => ({
		removeCanvas,
	}));

	const snapGrid = useCallback((c) => {
		initCenterAlign(c);
		initAligningGuidelines(c);
	}, []);

	const initCanvas = useCallback(
		(el, id, options) => {
			(function () {
				var defaultOnTouchStartHandler = fabric.Canvas.prototype._onTouchStart;
				fabric.util.object.extend(fabric.Canvas.prototype, {
					_onTouchStart: function (e) {
						var target = this.findTarget(e);
						// if allowTouchScrolling is enabled, canvas not created
						// the touch position and in drawing mode,
						// need to skip touchevent by fabric canvas d
						if (this.allowTouchScrolling && !target && !this.isDrawingMode) {
							return;
						}
						// call the default behavior if above condition not satisfied
						defaultOnTouchStartHandler.call(this, e);
					},
				});
			})();

			const canvasOptions = {
				preserveObjectStacking: true,
				selection: true,
				defaultCursor: "default",
				backgroundColor: "#f3f3f3",
				enableRetinaScaling: true,
				allowTouchScrolling: true,
				...options,
			};
			let c = new fabric.Canvas(el, canvasOptions);
			snapGrid(c);
			c.renderAll();
			const allCanvas = canvas ?? [];
			if (enableMultiPageCertificate) {
				setCanvas([...(allCanvas?.slice?.(0, id) ?? []), c, ...(allCanvas?.slice?.(id + 1, allCanvas.length) ?? [])]);
			} else {
				setCanvas(c);
			}
		},
		[total, canvasLengthCount]
	);

	const loadFromJSON = useCallback(
		(el, id, json, config) => {
			// Fix summary:
			// When the canvas is already initialized with initCanvas method and due to the late response form API loadFromJson method gets triggered so at that time new fabric.Canvas is being called and it is creating an overlap issue.
			// So to solve that instead of creating new fabric. canvas we should use the existing one and update it
			let c = id && canvas?.[id] ? canvas?.[id] : canvas && !Array.isArray(canvas) ? canvas : new fabric.Canvas(el);
			c.loadFromJSON(
				json,
				() => {
					let width = 640;
					let height = 437;

					if (config.width && config.height) {
						width = config.width;
						height = config.height;
					}

					if (c.backgroundImage) {
						c.backgroundImage.toObject = (function (toObject) {
              return function () {
                try {
                  return fabric.util.object.extend(toObject.call(this), {
                    scaleX: this.scaleX,
                    scaleY: this.scaleY,
                  });
                } catch (e) {
                  return null;
                }
							};
						})(c.backgroundImage?.toObject);
					}

					c._objects?.forEach((value) => {
						if (value.type === "group") {
							value.toObject = (function (toObject) {
                return function () {
                  try {
                    return fabric.util.object.extend(toObject.call(this), {
                      scaleX: this.scaleX,
                      scaleY: this.scaleY,
                    });
                  } catch (e) {
                    return null;
                  }
								};
							})(value.toObject);
						}
					});

					c.renderAll.bind(c);
					c.setWidth(width);
					c.setHeight(height);
					snapGrid(c);
					c.renderAll();
					c.fire("object:modified");
				},
				function (o, object) {
					fabric.log(o, object); // console log
				}
			);
			c.renderAll();
			if (enableMultiPageCertificate) {
				setCanvas((prevCanvas) => {
					const allCanvas = prevCanvas ?? [];
					return [...(allCanvas?.slice?.(0, id) ?? []), c, ...(allCanvas?.slice?.(id + 1, allCanvas.length) ?? [])];
				});
			} else {
				setCanvas(c);
			}
		},
		[total, canvasLengthCount]
	);

	return (
		<FabricContext.Provider
			value={{
				canvas,
				initCanvas,
				loadFromJSON,
				activeObject,
				setActiveObject,
				setCanvas,
				snapGrid,
			}}>
			{children}
		</FabricContext.Provider>
	);
});
