import { ASSETS_PREFIX } from "@cti-workspace/helpers";
import { postApi } from "./api";

/**
 *
 * @param {String} imageLocalUri Base64 local data uri for the image
 */
export default async function uploadImage(imageLocalUri, key, ACL, token, originalFileName) {
	try {
		const [prefix, data] = imageLocalUri.split(",");
		// pull mime type from prefix (data:image/png;base64)
		const type = prefix.split(";")[0].replace("data:", "");
		const url = `/${ASSETS_PREFIX}/upload`;
		const { Location } = await postApi(url, { type, data, key, ACL, token, originalFileName });
		return Location;
	} catch (err) {
		throw err;
	}
}

// if you loose internet connectivity, you will get response status 500.
