import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import HeaderWithLine from "../../components/HeaderWithLine";
import AddUser from "./userAccounts/addUser";
import ConfigRenderer from "./configRenderer";
import Settings from "./settings";
import TriggerNewPasswordComponent from "./triggerNewPassword";
import CreateOrganization from "./createOrganization";
import RendererConfigOrg from "../../components/RendererConfigOrg/RendererConfigOrg";
import DatabaseConfig from "../../components/DatabaseConfig";
import EmailTemplates from "../../components/EmailTemplates";
import PDFTemplates from "../../components/PDFTemplates";
import CustomCredentials from "../../components/CustomCredentials";
import NFTContract from "../../components/NFTContract";
import PendingApprovals from "../../components/PendingApprovals";
import { useLocation } from "react-router-dom";
import { getActiveIndex } from "../apphome/utils";
import { Container, Tabs as AdminTabs } from "@cti-workspace/ui";

function AdminRoute({ classes }) {
	const [value, setValue] = useState(0);

	const location = useLocation();

	function a11yProps(index) {
		return {
			id: `full-width-tab-${index}`,
			"aria-controls": `full-width-tabpanel-${index}`,
		};
	}

	useEffect(() => {
		setValue(getActiveIndex(location));
	}, [location]);

	return (
		<div className={classes.center} style={{ width: "100%" }}>
			<Container maxWidth="lg">
				<Box width="100%" mx="auto" p={2} />
				<HeaderWithLine title={"Admin"} variant={"h4"} />
				<AdminTabs
					options={[
						{ title: "", component: <CreateOrganization /> },
						{ title: "", component: <PendingApprovals /> },
						{ title: "", component: <ConfigRenderer /> },
						{ title: "", component: <AddUser /> },
						{ title: "", component: <TriggerNewPasswordComponent /> },
						{ title: "", component: <Settings /> },
						{ title: "", component: <RendererConfigOrg /> },
						{ title: "", component: <DatabaseConfig /> },
						{ title: "", component: <EmailTemplates /> },
						{ title: "", component: <PDFTemplates /> },
						{ title: "", component: <CustomCredentials /> },
						{ title: "", component: <NFTContract /> },
					]}
					activeStep={value}
					showHeader={false}
				/>
			</Container>
		</div>
	);
}

AdminRoute.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default AdminRoute;
