import { getErrMsg, useNProgressHook, getObjectValue } from "@cti-workspace/helpers";
import { Button } from "@cti-workspace/ui";
import { Grid, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ActionDialog from "../../components/ActionDialog";
import usePresentationHook from "../../hooks/usePresentationHook";
import PresentationCard from "./presentationCard";

export default function Presentations({ search = "" }) {
	const [presentationAlertDialog, setPresentationAlertDialog] = useState(null);

	const { getPresentationDefinitions, presentationDefinitions, deletePresentationDefinition } = usePresentationHook();
	const { progressStart, progressDone } = useNProgressHook();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		progressStart();
		getPresentationDefinitions()
			.catch((err) => {
				enqueueSnackbar(getErrMsg(err), { variant: "error" });
			})
			.finally(() => {
				progressDone();
			});
	}, []);

	const handleOnEditPresentationCard = async (item) => {
		navigate(`/define-presentation/${getObjectValue(item, "presentationDefinition.id", "")}/edit`, {
			state: item,
		});
	};

	const handleOnDeletePresentationCard = async () => {
		try {
			progressStart();
			if (presentationAlertDialog) {
				await deletePresentationDefinition({ id: getObjectValue(presentationAlertDialog, "id", "") });
				enqueueSnackbar("Presentation deleted successfully", { variant: "success" });
				setPresentationAlertDialog(null);
				await getPresentationDefinitions();
			}
		} catch (error) {
			enqueueSnackbar(getErrMsg(error), { variant: "error" });
		} finally {
			progressDone();
		}
	};

	const onClickLaunch = (data) => {
		window.open(`${window.location.origin}/verify-presentation?id=${data.id}`);
	};

    return <Grid container spacing={3}>
        {
            presentationDefinitions.filter(item => item.name.includes(search)).map((item) => (
                <Grid item xs={12} sm={6} md={4}>
                    <PresentationCard data={item} onClickLaunch={() => onClickLaunch(item)} onClickDelete={() => setPresentationAlertDialog(item)} onClickEdit={() => handleOnEditPresentationCard(item)} />
                </Grid>
            ))
        }
        <ActionDialog
            open={Boolean(presentationAlertDialog)}
            title="Delete Presentation Definition"
            content={
                <Typography color="secondary" variant="h5">
                    Are you sure you want to delete?
                </Typography>
            }
            primaryAction={
                <Button color="warning" onClick={handleOnDeletePresentationCard}>
                    Delete
                </Button>
            }
            secondaryAction={
                <Button variant="text" invert onClick={() => setPresentationAlertDialog(null)}>
                    Cancel
                </Button>
            }
            onClose={() => setPresentationAlertDialog(null)}
        />
    </Grid>
}
