import { ComboBox, RadioButton, RadioGroup } from "@cti-workspace/ui";
import { FABRIC_PAGE_TYPES, FABRIC_PAGE_ORIENTATION_TYPES } from "@cti-workspace/helpers";
import { useEffect, useState } from "react";

export default function PageOrientation({ defaultValue = "", value = "", onChange = () => {}, name = "", hasBorderRight = false }) {
	const [comboBoxValue, setComboBoxValue] = useState("us_letter");
	const [radioBtnValue, setRadioBtnValue] = useState("landscape");

	const updatePageOrientation = (value) => {
		const arr = value.split("_");
		const radioBtnVal = arr.pop();
		const comboBoxVal = arr.join("_");
		setComboBoxValue(comboBoxVal);
		setRadioBtnValue(radioBtnVal);
	};

	useEffect(() => {
		const val = value || defaultValue;
		//onChange(val);
		if (val) {
			updatePageOrientation(val);
		}
	}, [value]);

	const onChangeComboBox = (val) => {
		const isUpdated = onChange(`${val}_${radioBtnValue}`);
		if (isUpdated) setComboBoxValue(val);
	};

	const onChangeRadioButton = (e) => {
		const isUpdated = onChange(`${comboBoxValue}_${e.target.value}`);
		if (isUpdated) setRadioBtnValue(e.target.value);
	};

	return (
		<div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
			<ComboBox
				size="small"
				style={{ marginTop: "0.3rem", flexGrow: 1, minWidth: "100px" }}
				placeholder=""
				options={Object.keys(FABRIC_PAGE_TYPES).map((item) => ({ _id: item, value: FABRIC_PAGE_TYPES[item] }))}
				getOptionValue={(item) => item["_id"]}
				getOptionLabel={(item) => item.value}
				defaultValue={{ _id: comboBoxValue, value: FABRIC_PAGE_TYPES[comboBoxValue] }}
				value={{ _id: comboBoxValue, value: FABRIC_PAGE_TYPES[comboBoxValue] }}
				onChange={(e) => onChangeComboBox(e["_id"])}
			/>
			<RadioGroup aria-label="orientation" direction={"row"} style={{ ...(hasBorderRight ? { borderRight: "0.5px solid black" } : {}), gap: "5px", padding: "0px 10px" }} value={radioBtnValue} onChange={onChangeRadioButton}>
				{Object.keys(FABRIC_PAGE_ORIENTATION_TYPES).map((item) => (
					<RadioButton key={item} value={item} name="page_orientation" label={FABRIC_PAGE_ORIENTATION_TYPES[item]} margin={0} />
				))}
			</RadioGroup>
		</div>
	);
}
