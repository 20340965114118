import { fabric } from "fabric-pure-browser";

// Example: https://jsfiddle.net/lvlal2ch/hd2kj6tz/271/
export function initAligningGuidelines(canvas) {
	let ctx = canvas.getSelectionContext(),
		aligningLineOffset = 5,
		aligningLineMargin = 4,
		aligningLineWidth = 1,
		aligningLineColor = "red",
		lineDash = [5, 5],
		viewportTransform,
		zoom = 1;

	function drawVerticalLine(coords) {
		drawLine(coords.x + 0.5, coords.y1 > coords.y2 ? coords.y2 : coords.y1, coords.x + 0.5, coords.y2 > coords.y1 ? coords.y2 : coords.y1);
	}

	function drawHorizontalLine(coords) {
		drawLine(coords.x1 > coords.x2 ? coords.x2 : coords.x1, coords.y + 0.5, coords.x2 > coords.x1 ? coords.x2 : coords.x1, coords.y + 0.5);
	}

	function drawLine(x1, y1, x2, y2) {
		ctx.save();
		ctx.lineWidth = aligningLineWidth;
		ctx.strokeStyle = aligningLineColor;
		ctx.beginPath();
		ctx.setLineDash(lineDash);
		ctx.moveTo((x1 + viewportTransform[4]) * zoom, (y1 + viewportTransform[5]) * zoom);
		ctx.lineTo((x2 + viewportTransform[4]) * zoom, (y2 + viewportTransform[5]) * zoom);
		ctx.stroke();
		ctx.restore();
		//console.log('x1: ' + Math.round(x1) + ' |y1: ' + Math.round(y1) + ' |x2: ' + Math.round(x2) + ' |y2: ' + Math.round(y2));
	}

	function isInRange(value1, value2) {
		value1 = Math.round(value1);
		value2 = Math.round(value2);
		for (var i = value1 - aligningLineMargin, len = value1 + aligningLineMargin; i <= len; i++) {
			if (i === value2) {
				return true;
			}
		}
		return false;
	}

	function isInRangeInvert(value1, value2) {
		value1 = Math.round(value1);
		value2 = Math.round(value2);
		for (var i = value1 - aligningLineMargin, len = value1 + aligningLineMargin; i >= len; i--) {
			if (i === value2) {
				return true;
			}
		}
		return false;
	}

	function getABoundingRect(object) {
		let matrix = object.calcTransformMatrix();
		let options = fabric.util.qrDecompose(matrix);
		let translateMatrix = [1, 0, 0, 1, options.translateX, options.translateY];
		let rotateMatrix = fabric.iMatrix.concat();
		if (options.angle) {
			var theta = fabric.util.degreesToRadians(options.angle),
				cos = Math.cos(theta),
				sin = Math.sin(theta);
			rotateMatrix = [cos, sin, -sin, cos, 0, 0];
		}

		let finalMatrix = fabric.util.multiplyTransformMatrices(translateMatrix, rotateMatrix);

		let transformPoint = fabric.util.transformPoint;
		let p = object._getNonTransformedDimensions(),
			dim = transformPoint(p, matrix),
			w = dim.x / 2,
			h = dim.y / 2,
			tl = transformPoint(
				{
					x: -w,
					y: -h,
				},
				finalMatrix
			),
			tr = transformPoint(
				{
					x: w,
					y: -h,
				},
				finalMatrix
			),
			bl = transformPoint(
				{
					x: -w,
					y: h,
				},
				finalMatrix
			),
			br = transformPoint(
				{
					x: w,
					y: h,
				},
				finalMatrix
			);
		matrix = fabric.util.customTransformMatrix(options.scaleX, options.scaleY, options.skewX);
		// corners
		let points = [new fabric.Point(tl.x, tl.y), new fabric.Point(tr.x, tr.y), new fabric.Point(br.x, br.y), new fabric.Point(bl.x, bl.y)];

		return fabric.util.makeBoundingBoxFromPoints(points);
	}

	function getNewlineSin(angle, Hypotenuse) {
		let radians = Math.sin((angle * Math.PI) / 180);
		let result = Hypotenuse * radians;
		return Math.abs(result);
	}

	function getNewlineCos(angle, Hypotenuse) {
		let radians = Math.cos((angle * Math.PI) / 180);
		let result = Hypotenuse * radians;
		return result;
	}

	let verticalLines = [],
		horizontalLines = [];

	canvas.on("mouse:down", function () {
		viewportTransform = canvas.viewportTransform;
		zoom = canvas.getZoom();
	});

	canvas.on("object:moving", function (e) {
		let activeObject = e.target,
			canvasObjects = canvas.getObjects(),
			activeObjectCenter = activeObject.getCenterPoint(),
			activeObjectLeft = activeObjectCenter.x,
			activeObjectTop = activeObjectCenter.y,
			activeObjectBoundingRect = activeObject.getBoundingRect(),
			activeObjectHeight = activeObjectBoundingRect.height / viewportTransform[3],
			activeObjectWidth = activeObjectBoundingRect.width / viewportTransform[0],
			horizontalInTheRange = false,
			verticalInTheRange = false,
			transform = canvas._currentTransform;

		if (!transform) return;

		// It should be trivial to DRY this up by encapsulating (repeating) creation of x1, x2, y1, and y2 into functions,
		// but we're not doing it here for perf. reasons -- as this a function that's invoked on every mouse move

		for (let i = canvasObjects.length; i--; ) {
			if (canvasObjects[i] === activeObject) continue;

			let objectCenter = canvasObjects[i].getCenterPoint(),
				objectLeft = objectCenter.x,
				objectTop = objectCenter.y,
				objectBoundingRect = canvasObjects[i].getBoundingRect(),
				objectHeight = objectBoundingRect.height / viewportTransform[3],
				objectWidth = objectBoundingRect.width / viewportTransform[0],
				posTop = 0,
				posLeft = 0,
				snapLeft = false,
				snapLeftRevers = false,
				snapRight = false,
				snapRightRevers = false,
				snapTop = false,
				snapTopRevers = false,
				snapBottom = false,
				snapBottomRevers = false,
				snapHorizontal = false,
				snapVertical = false;

			// snap by the horizontal center line
			// console.log("i", { i, objectLeft, activeObjectLeft });
			if (isInRange(objectLeft, activeObjectLeft)) {
				// if (verticalInTheRange) return;
				verticalLines.push({
					x: objectLeft,
					y1: objectTop < activeObjectTop ? objectTop - objectHeight / 2 - aligningLineOffset : objectTop + objectHeight / 2 + aligningLineOffset,
					y2: activeObjectTop > objectTop ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset,
				});

				// activeObject.setPositionByOrigin(new fabric.Point(objectLeft, activeObjectTop), "center", "center");
				// posLeft = objectLeft;
				snapHorizontal = true;
				verticalInTheRange = true;
			}

			// snap by the left edge
			if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
				// if (verticalInTheRange) return;
				verticalLines.push({
					x: objectLeft - objectWidth / 2,
					/* y1: (objectTop < activeObjectTop) ? (objectTop - objectHeight / 2 - aligningLineOffset) : (objectTop + objectHeight / 2 + aligningLineOffset),
          y2: (activeObjectTop > objectTop) ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset) : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
                          }); */
					//
					y1: objectTop - objectHeight / 2 < activeObjectTop - activeObjectHeight / 2 ? objectTop - objectHeight / 2 : activeObjectTop - activeObjectHeight / 2,
					y2: activeObjectTop + activeObjectHeight / 2 > objectTop + objectHeight / 2 ? activeObjectTop + activeObjectHeight / 2 : objectTop + objectHeight / 2,
				});

				//console.log('objTop: ' + (objectTop - objectHeight / 2) + ' || actTop: ' + (activeObjectTop - activeObjectHeight/2));
				// activeObject.setPositionByOrigin(new fabric.Point(objectLeft - objectWidth / 2 + activeObjectWidth / 2, activeObjectTop), 'center', 'center');
				// posLeft = (objectLeft - objectWidth / 2 + activeObjectWidth / 2);
				snapLeft = true;
				verticalInTheRange = true;
			}

			// snap by the right edge
			if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {
				// if (verticalInTheRange) return;
				verticalLines.push({
					x: objectLeft + objectWidth / 2,
					y1: objectTop < activeObjectTop ? objectTop - objectHeight / 2 - aligningLineOffset : objectTop + objectHeight / 2 + aligningLineOffset,
					y2: activeObjectTop > objectTop ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset,
				});
				// activeObject.setPositionByOrigin(new fabric.Point(objectLeft + objectWidth / 2 - activeObjectWidth / 2, activeObjectTop), 'center', 'center');
				// posLeft = (objectLeft + objectWidth / 2 - activeObjectWidth / 2)
				snapRight = true;
				verticalInTheRange = true;
			}

			// snap by the left edge revers
			if (isInRange(activeObjectLeft + activeObjectWidth / 2, objectLeft - objectWidth / 2)) {
				// if (verticalInTheRange) return;
				verticalLines.push({
					x: objectLeft - objectWidth / 2,
					y1: objectTop - objectHeight / 2 < activeObjectTop - activeObjectHeight / 2 ? objectTop - objectHeight / 2 : activeObjectTop - activeObjectHeight / 2,
					y2: activeObjectTop + activeObjectHeight / 2 > objectTop + objectHeight / 2 ? activeObjectTop + activeObjectHeight / 2 : objectTop + objectHeight / 2,
				});
				snapLeftRevers = true;
				verticalInTheRange = true;
			}

			// snap by the right edge revers
			if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
				// if (verticalInTheRange) return;
				verticalLines.push({
					x: objectLeft + objectWidth / 2,
					y1: objectTop < activeObjectTop ? objectTop - objectHeight / 2 - aligningLineOffset : objectTop + objectHeight / 2 + aligningLineOffset,
					y2: activeObjectTop > objectTop ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset,
				});
				snapRightRevers = true;
				verticalInTheRange = true;
			}

			// snap by the vertical center line
			if (isInRange(objectTop, activeObjectTop)) {
				// if (horizontalInTheRange) return;
				horizontalLines.push({
					y: objectTop,
					x1: objectLeft < activeObjectLeft ? objectLeft - objectWidth / 2 - aligningLineOffset : objectLeft + objectWidth / 2 + aligningLineOffset,
					x2: activeObjectLeft > objectLeft ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
				});
				// activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop), 'center', 'center');
				// posTop = objectTop;
				snapVertical = true;
				horizontalInTheRange = true;
			}

			// snap by the top edge
			if (isInRange(objectTop - objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
				// if (horizontalInTheRange) return;
				horizontalLines.push({
					y: objectTop - objectHeight / 2,
					x1: objectLeft < activeObjectLeft ? objectLeft - objectWidth / 2 - aligningLineOffset : objectLeft + objectWidth / 2 + aligningLineOffset,
					x2: activeObjectLeft > objectLeft ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
				});
				// activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop - objectHeight / 2 + activeObjectHeight / 2), 'center', 'center');
				// posTop = (objectTop - objectHeight / 2 + activeObjectHeight / 2);
				snapTop = true;
				horizontalInTheRange = true;
			}

			// snap by the bottom edge
			if (isInRange(objectTop + objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
				// if (horizontalInTheRange) return;
				horizontalLines.push({
					y: objectTop + objectHeight / 2,
					x1: objectLeft < activeObjectLeft ? objectLeft - objectWidth / 2 - aligningLineOffset : objectLeft + objectWidth / 2 + aligningLineOffset,
					x2: activeObjectLeft > objectLeft ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
				});
				// activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop + objectHeight / 2 - activeObjectHeight / 2), 'center', 'center');
				// posTop = (objectTop + objectHeight / 2 - activeObjectHeight / 2);
				snapBottom = true;
				horizontalInTheRange = true;
			}

			// nap by the bottom edge reverse
			if (isInRange(objectTop + objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
				// if (horizontalInTheRange) return;
				horizontalLines.push({
					y: objectTop + objectHeight / 2,
					x1: objectLeft < activeObjectLeft ? objectLeft - objectWidth / 2 - aligningLineOffset : objectLeft + objectWidth / 2 + aligningLineOffset,
					x2: activeObjectLeft > objectLeft ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
				});
				snapBottomRevers = true;
				horizontalInTheRange = true;
			}

			// snap by the top edge reverse
			if (isInRange(activeObjectTop + activeObjectHeight / 2, objectTop - objectHeight / 2)) {
				// if (horizontalInTheRange) return;
				horizontalLines.push({
					y: objectTop - objectHeight / 2,
					x1: objectLeft < activeObjectLeft ? objectLeft - objectWidth / 2 - aligningLineOffset : objectLeft + objectWidth / 2 + aligningLineOffset,
					x2: activeObjectLeft > objectLeft ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
				});

				snapTopRevers = true;
				horizontalInTheRange = true;
			}

			if (snapLeft || snapRight || snapTop || snapBottom || snapHorizontal || snapVertical || snapLeftRevers || snapRightRevers || snapBottomRevers || snapTopRevers) {
				if (snapHorizontal || snapLeft || snapRight || snapLeftRevers || snapRightRevers) {
					posTop = activeObjectTop;
				}
				if (snapVertical || snapTop || snapBottom || snapBottomRevers || snapTopRevers) {
					posLeft = activeObjectLeft;
				}

				//Snap center
				if (snapHorizontal && snapVertical) {
					activeObject.setPositionByOrigin(new fabric.Point(objectLeft, objectTop), "center", "center");
				} else {
					if (snapLeft) posLeft = objectLeft - objectWidth / 2 + activeObjectWidth / 2;
					if (snapRight) posLeft = objectLeft + objectWidth / 2 - activeObjectWidth / 2;
					if (snapTop) posTop = objectTop - objectHeight / 2 + activeObjectHeight / 2;
					if (snapBottom) posTop = objectTop + objectHeight / 2 - activeObjectHeight / 2;
					if (snapHorizontal) posLeft = objectLeft;
					if (snapVertical) posTop = objectTop;
					if (snapLeftRevers) posLeft = objectLeft - objectWidth / 2 - activeObjectWidth / 2;
					if (snapRightRevers) posLeft = objectLeft + objectWidth / 2 + activeObjectWidth / 2;
					if (snapTopRevers) posTop = objectTop - objectHeight / 2 - activeObjectHeight / 2;
					if (snapBottomRevers) posTop = objectTop + objectHeight / 2 + activeObjectHeight / 2;
					activeObject.setPositionByOrigin(new fabric.Point(posLeft, posTop), "center", "center");
				}
			}
		}

		if (!horizontalInTheRange) {
			horizontalLines.length = 0;
		}

		if (!verticalInTheRange) {
			verticalLines.length = 0;
		}
	});

	canvas.on("object:scaling", function (e) {
		var activeObject = e.target,
			canvasObjects = canvas.getObjects(),
			activeObjectCenter = activeObject.getCenterPoint(),
			activeObjectLeft = activeObjectCenter.x,
			activeObjectTop = activeObjectCenter.y,
			activeObjectBoundingRect = activeObject.getBoundingRect(),
			/* activeObjectHeight = activeObjectBoundingRect.height / viewportTransform[3],
      activeObjectWidth = activeObjectBoundingRect.width / viewportTransform[0], */
			activeObjectHeight = ((activeObject.height + activeObject.strokeWidth) * activeObject.scaleY) / viewportTransform[3],
			activeObjectWidth = ((activeObject.width + activeObject.strokeWidth) * activeObject.scaleX) / viewportTransform[0],
			horizontalInTheRange = false,
			verticalInTheRange = false,
			transform = canvas._currentTransform;

		var eCoords = activeObject.calcCoords(true),
			eTop = Math.min(eCoords.tl.y, eCoords.tr.y, eCoords.bl.y, eCoords.br.y),
			eLeft = Math.min(eCoords.tl.x, eCoords.tr.x, eCoords.bl.x, eCoords.br.x),
			eRight = Math.max(eCoords.tl.y, eCoords.tr.y, eCoords.bl.y, eCoords.br.y),
			eBottom = Math.max(eCoords.tl.x, eCoords.tr.x, eCoords.bl.x, eCoords.br.x);

		// console.log("%ceTop: " + eTop + " |eBottom: " + eBottom + " |eLeft: " + eLeft + " |eRight: " + eRight, "color: #E74C3C;");
		// console.log("%c eCoords.tl.x: " + eCoords.tl.x + " |eCoords.tl.y: " + eCoords.tl.y, "color: #E74CFF;");
		// console.log("actTop: " + activeObjectTop + " |actLeft: " + activeObjectLeft);

		//toFixed(2)
		if (!transform) return;

		// It should be trivial to DRY this up by encapsulating (repeating) creation of x1, x2, y1, and y2 into functions,
		// but we're not doing it here for perf. reasons -- as this a function that's invoked on every mouse move

		for (var i = canvasObjects.length; i--; ) {
			if (canvasObjects[i] === activeObject) continue;

			var objectCenter = canvasObjects[i].getCenterPoint(),
				objectLeft = objectCenter.x,
				objectTop = objectCenter.y,
				objectBoundingRect = canvasObjects[i].getBoundingRect(),
				objectHeight = objectBoundingRect.height / viewportTransform[3],
				objectWidth = objectBoundingRect.width / viewportTransform[0],
				newWidth = 0,
				newHeight = 0,
				posTop = 0,
				posLeft = 0,
				snapLeft = false,
				snapRight = false,
				snapTop = false,
				snapBottom = false,
				snapHorizontal = false,
				snapVertical = false;

			// snap by the horizontal center line
			/* if (isInRange(objectLeft, activeObjectLeft)) {
          verticalInTheRange = true;
          verticalLines.push({
              x: objectLeft,
              y1: (objectTop < activeObjectTop) ? (objectTop - objectHeight / 2 - aligningLineOffset) : (objectTop + objectHeight / 2 + aligningLineOffset),
              y2: (activeObjectTop > objectTop) ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset) : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
                              });

          //activeObject.setPositionByOrigin(new fabric.Point(objectLeft, activeObjectTop), 'center', 'center');
          posLeft = objectLeft;
          snapHorizontal = true;
      } */

			// snap by the left edge
			if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
				verticalInTheRange = true;
				verticalLines.push({
					x: objectLeft - objectWidth / 2,
					y1: objectTop < activeObjectTop ? objectTop - objectHeight / 2 - aligningLineOffset : objectTop + objectHeight / 2 + aligningLineOffset,
					y2: activeObjectTop > objectTop ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset,
				});
				/* console.log('obj Left: ' + (activeObjectLeft - activeObjectWidth/2) + ' | activeObjectWidth: ' + activeObjectWidth);
        newWidth = ((activeObjectLeft - activeObjectWidth/2) + activeObjectWidth) - (activeObjectLeft - activeObjectWidth/2); */

				/* console.log('obj Left: ' + (objectLeft - objectHeight/2) + ' | actObj Right: ' + ((activeObjectLeft - (activeObjectWidth*activeObject.scaleX)/2) +  (activeObjectWidth*activeObject.scaleX))); */
				//console.log('scaleX: ' + activeObject.scaleX + ' |activeObjectWidth: ' + activeObjectWidth);
				/* console.log('activeObjectLeft: ' + activeObjectLeft); */

				/*  console.log('obj L: ' + (objectLeft - objectWidth/2));
         console.log('act L: ' + (activeObjectLeft - activeObjectWidth/2)); */
				// console.log("act L: " + activeObjectLeft);
				/* var objL = (objectLeft - objectWidth/2);
        var actL = (activeObjectLeft - activeObjectWidth/2); */
				/*  if(objL <= actL-5 || objL >= actL+5) */

				snapLeft = true;
				posLeft = objectLeft - objectWidth / 2 + activeObjectWidth / 2;
				if (activeObject.compareWith != i) {
					newWidth = activeObjectLeft + activeObjectWidth / 2 - (objectLeft - objectWidth / 2);
					// console.log("newWidth: " + newWidth + " |actWidth before change: " + activeObjectWidth);
					/* console.log('obj[i]: ' + (objectLeft - objectWidth / 2) + ' | center obj: ' + (activeObjectLeft + activeObjectWidth / 2) + ' || objectLeft: ' + objectLeft); */
					//objectLeft
					activeObject.set("newWidth", newWidth);
					activeObject.set("compareWith", i);
					//activeObject.set('snapL',true);
					// Please delete newWidth and campreWith when mouse down
					// console.dir(activeObject);
				}
			}

			// snap by the right edge
			if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {
				verticalInTheRange = true;
				verticalLines.push({
					x: objectLeft + objectWidth / 2,
					y1: objectTop < activeObjectTop ? objectTop - objectHeight / 2 - aligningLineOffset : objectTop + objectHeight / 2 + aligningLineOffset,
					y2: activeObjectTop > objectTop ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset,
				});
				//activeObject.setPositionByOrigin(new fabric.Point(objectLeft + objectWidth / 2 - activeObjectWidth / 2, activeObjectTop), 'center', 'center');
				posLeft = objectLeft + objectWidth / 2 - activeObjectWidth / 2;
				snapRight = true;
				// console.log("snapRight");
			}

			// snap by the vertical center line
			if (isInRange(objectTop, activeObjectTop)) {
				horizontalInTheRange = true;
				horizontalLines.push({
					y: objectTop,
					x1: objectLeft < activeObjectLeft ? objectLeft - objectWidth / 2 - aligningLineOffset : objectLeft + objectWidth / 2 + aligningLineOffset,
					x2: activeObjectLeft > objectLeft ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
				});
				//activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop), 'center', 'center');
				posTop = objectTop;
				snapVertical = true;
			}

			// snap by the top edge
			if (isInRange(objectTop - objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
				horizontalInTheRange = true;
				horizontalLines.push({
					y: objectTop - objectHeight / 2,
					x1: objectLeft < activeObjectLeft ? objectLeft - objectWidth / 2 - aligningLineOffset : objectLeft + objectWidth / 2 + aligningLineOffset,
					x2: activeObjectLeft > objectLeft ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
				});
				//activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop - objectHeight / 2 + activeObjectHeight / 2), 'center', 'center');
				posTop = objectTop - objectHeight / 2 + activeObjectHeight / 2;
				snapTop = true;
				// console.log("snapTop");
			}

			// snap by the bottom edge
			if (isInRange(objectTop + objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
				horizontalInTheRange = true;
				horizontalLines.push({
					y: objectTop + objectHeight / 2,
					x1: objectLeft < activeObjectLeft ? objectLeft - objectWidth / 2 - aligningLineOffset : objectLeft + objectWidth / 2 + aligningLineOffset,
					x2: activeObjectLeft > objectLeft ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
				});
				//activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop + objectHeight / 2 - activeObjectHeight / 2), 'center', 'center');
				posTop = objectTop + objectHeight / 2 - activeObjectHeight / 2;
				snapBottom = true;
				// console.log("snapBottom");
			}

			if (snapLeft || snapRight || snapTop || snapBottom || snapHorizontal || snapVertical) {
				var scaleXN = 1,
					scaleYN = 1,
					scaleXO = 1,
					scaleYO = 1;

				if (snapHorizontal || snapLeft || snapRight) {
					posTop = activeObjectTop;
				}
				if (snapVertical || snapTop || snapBottom) {
					posLeft = activeObjectLeft;
				}

				//Snap center
				if (snapHorizontal && snapVertical) {
					//activeObject.setPositionByOrigin(new fabric.Point(objectLeft, objectTop), 'center', 'center');
				} else {
					//newWidth  activeObject.width
					if (snapLeft) {
						scaleXN = activeObject.newWidth / (activeObject.width + activeObject.strokeWidth);
						// console.log("%cscaleXN: " + scaleXN, "color: #bada55");
						/* scaleXO = activeObject.scaleX;
            scaleYO = activeObject.scaleY; */

						var newX = objectLeft - objectWidth / 2;

						var xCenter = 0,
							yCenter = 0;

						var newCal = activeObject.calcCoords(true),
							oldCenterY = 0,
							newCenterY = 0,
							newY = 0,
							newObjH = newCal.tl.y;

						var newY = newObjH;

						/*  var newCalc = getABoundingRect(activeObject);
             console.log('newCalc');
             console.dir(newCalc); */
						scaleXO = activeObject.scaleX;

						// console.log("scaleXO: " + scaleXO + " |scaleXN: " + scaleXN + " |activeObjectTop: " + activeObjectTop);

						if (activeObject.angle) {
							//oldCenterY = getNewlineSin(activeObject.angle, activeObject.width*scaleXO);
							newCenterY = getNewlineCos(activeObject.angle % 90, activeObject.width * scaleXN) / 2;
							// console.log("%c angle: " + (activeObject.angle % 90) + "width: " + activeObject.width * scaleXN, "color: #FFda55");
							//newCenterY = getNewlineCos(activeObject.angle, activeObject.width*scaleXN)/2;
							//newY = activeObjectTop + (newCenterY - oldCenterY);
							newY = eCoords.tl.y + newCenterY;
							// console.log("%cnewY: " + newY + " |activeObjectTop: " + activeObjectTop + " |newCenterY: " + newCenterY, "color: #F18DF0");
							//activeObject.set('center', activeObjectTop + (newCenterY - oldCenterY));
						} else newY = activeObjectTop;
						//console.log('%c activeObjectTop: ' + activeObjectTop, 'color: #BBFC95')
						// Calc center x,y
						/* if(activeObject.angle){
              xCenter = eRight - eLeft;
              yCenter = eBottom - eLeft;
            } */

						activeObject.scaleX = scaleXN;

						/* var xyz = new fabric.Point(newX, newY);
            console.log('xyz: ' + xyz); */

						/*    console.log('newX: ' + newX);
               console.log('%cxCenter: ' + xCenter + ' |yCenter: ' + yCenter , 'color: #F0B27A'); */
						activeObject.set("left", newX);
						/* activeObject.setPositionByOrigin(new fabric.Point(newX, newY), 'left', 'top'); */
						//activeObject.setPositionByOrigin(new fabric.Point(newX + xCenter, eTop + yCenter), 'center', 'center');
					}
				}
			}
		}

		if (!horizontalInTheRange) {
			horizontalLines.length = 0;
		}

		if (!verticalInTheRange) {
			verticalLines.length = 0;
		}
	});

	canvas.on("before:render", function () {
		canvas.clearContext(canvas.contextTop);
	});

	canvas.on("after:render", function () {
		for (let i = verticalLines.length; i--; ) {
			drawVerticalLine(verticalLines[i]);
		}
		for (let i = horizontalLines.length; i--; ) {
			drawHorizontalLine(horizontalLines[i]);
		}

		verticalLines.length = horizontalLines.length = 0;
	});

	canvas.on("mouse:up", function () {
		verticalLines.length = horizontalLines.length = 0;
		for (let jj = canvas._objects.length; jj--; ) {
			canvas._objects[jj].set("newWidth", 0);
			canvas._objects[jj].set("compareWith", 9999);
		}
		canvas.renderAll();
		// console.dir(canvas);
	});
}

export function initCenterAlign(canvas) {
	let canvasWidth = canvas.getWidth(),
		canvasHeight = canvas.getHeight(),
		canvasWidthCenter = canvasWidth / 2,
		canvasHeightCenter = canvasHeight / 2,
		canvasWidthCenterMap = {},
		canvasHeightCenterMap = {},
		centerLineMargin = 4,
		centerLineColor = "red",
		lineDash = [5, 5],
		centerLineWidth = 1,
		ctx = canvas.getSelectionContext(),
		viewportTransform;

	for (let i = canvasWidthCenter - centerLineMargin, len = canvasWidthCenter + centerLineMargin; i <= len; i++) {
		canvasWidthCenterMap[Math.round(i)] = true;
	}
	for (let i = canvasHeightCenter - centerLineMargin, len = canvasHeightCenter + centerLineMargin; i <= len; i++) {
		canvasHeightCenterMap[Math.round(i)] = true;
	}

	function showVerticalCenterLine() {
		showCenterLine(canvasWidthCenter + 0.5, 0, canvasWidthCenter + 0.5, canvasHeight);
	}

	function showHorizontalCenterLine() {
		showCenterLine(0, canvasHeightCenter + 0.5, canvasWidth, canvasHeightCenter + 0.5);
	}

	function showCenterLine(x1, y1, x2, y2) {
		ctx.save();
		ctx.strokeStyle = centerLineColor;
		ctx.lineWidth = centerLineWidth;
		ctx.beginPath();
		ctx.setLineDash(lineDash);
		ctx.moveTo(x1 * viewportTransform[0], y1 * viewportTransform[3]);
		ctx.lineTo(x2 * viewportTransform[0], y2 * viewportTransform[3]);
		ctx.stroke();
		ctx.restore();
	}

	let afterRenderActions = [],
		isInVerticalCenter,
		isInHorizontalCenter,
		isRightAligned,
		isLeftAligned,
		isTopAligned,
		isBottomAligned;

	canvas.off("mouse:down");
	canvas.on("mouse:down", function () {
		viewportTransform = canvas.viewportTransform;
	});

	canvas.off("object:moving");
	canvas.on("object:moving", function (e) {
		let object = e.target,
			objectCenter = object.getCenterPoint(),
			transform = canvas._currentTransform,
			objectBoundingRect = object.getBoundingRect(),
			objectHeight = objectBoundingRect.height / viewportTransform[3],
			objectWidth = objectBoundingRect.width / viewportTransform[0];
		if (!transform) return;

		isInVerticalCenter = Math.round(objectCenter.x) in canvasWidthCenterMap;
		isInHorizontalCenter = Math.round(objectCenter.y) in canvasHeightCenterMap;
		isRightAligned = Math.round(objectCenter.x + objectWidth / 2) in canvasWidthCenterMap;
		isLeftAligned = Math.round(objectCenter.x - objectWidth / 2) in canvasWidthCenterMap;
		isTopAligned = Math.round(objectCenter.y - objectHeight / 2) in canvasHeightCenterMap;
		isBottomAligned = Math.round(objectCenter.y + objectHeight / 2) in canvasHeightCenterMap;

		let x = objectCenter.x,
			y = objectCenter.y;

		/*
		 * center center
		 * left top
		 * left bottom
		 * right top
		 * right bottom
		 * left center
		 * right center
		 * center top
		 * center bottom
		 * */

		if (isLeftAligned) {
			x = canvasWidthCenter + objectWidth / 2;
		}
		if (isRightAligned) {
			x = canvasWidthCenter - objectWidth / 2;
		}
		if (isTopAligned) {
			y = canvasHeightCenter + objectHeight / 2;
		}
		if (isBottomAligned) {
			y = canvasHeightCenter - objectHeight / 2;
		}
		if (isInVerticalCenter) {
			x = canvasWidthCenter;
		}
		if (isInHorizontalCenter) {
			y = canvasHeightCenter;
		}

		if (isInHorizontalCenter || isInVerticalCenter || isRightAligned || isLeftAligned || isTopAligned || isBottomAligned) {
			object.setPositionByOrigin(new fabric.Point(x, y), "center", "center");
		}
	});

	canvas.off("before:render");
	canvas.on("before:render", function () {
		canvas.clearContext(canvas.contextTop);
	});

	canvas.off("after:render");
	canvas.on("after:render", function () {
		if (isInVerticalCenter || isRightAligned || isLeftAligned) {
			showVerticalCenterLine();
		}
		if (isInHorizontalCenter || isTopAligned || isBottomAligned) {
			showHorizontalCenterLine();
		}
	});

	canvas.on("mouse:up", function () {
		// clear these values, to stop drawing guidelines once mouse is up
		isInVerticalCenter = isInHorizontalCenter = isRightAligned = isLeftAligned = isTopAligned = isBottomAligned = null;
		canvas.renderAll();
	});
}
