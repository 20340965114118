import { checkFileExtension } from "./checkFileExtension";
import { parseExcelSheetToArray } from ".";
import { getObjectValue } from "@cti-workspace/helpers";

export default async function bulkIssueCredDefsFromXslx({ excelSheet }) {
	if (!excelSheet) throw new Error("No file found...");
	if (!checkFileExtension(excelSheet.type, "xlsx")) {
		throw new Error("Please select valid file format");
	}

	const jsonExcelSheet = await parseExcelSheetToArray(excelSheet);

	if (jsonExcelSheet.length < 2) {
		throw new Error(`The file must contain column headers and at least one row of data. ${jsonExcelSheet.length - 1} rows found.`);
	}

	const columns = jsonExcelSheet.splice(0, 1)[0];

	let hasCredName = false;
	let actualIndexes = [];

	// [credentialName, description, transcriptFieldsString, imageUrl, resourceString, course id] = [0,1,2,3,4,5]

	const columnIndexes = columns
		.map((column, index) => {
			if (column.toLowerCase().includes("credential name")) {
				hasCredName = true;
				actualIndexes.push(index);
				return 0;
			}
			if (column.toLowerCase().includes("course name")) {
				hasCredName = true;
				actualIndexes.push(index);
				return 0;
			}
			if (column.toLowerCase().includes("description")) {
				actualIndexes.push(index);
				return 1;
			}
			if (column.toLowerCase().includes("content")) {
				actualIndexes.push(index);
				return 2;
			}
			if (column.toLowerCase().includes("icon")) {
				actualIndexes.push(index);
				return 3;
			}
			if (column.toLowerCase().includes("resources")) {
				actualIndexes.push(index);
				return 4;
			}
			if (column.toLowerCase().includes("course id")) {
				actualIndexes.push(index);
				return 5;
			}
			if (column.toLowerCase().includes("credential id")) {
				actualIndexes.push(index);
				return 5;
			}
		})
		.filter((index) => index !== undefined);

	if (!hasCredName) {
		throw new Error("Column Header Credential Name is required");
	}

	const rows = jsonExcelSheet.map((row) => {
		let rowData = {};
		let currentIndex = -1;
		for (let x = 0; x < 6; x++) {
			if (columnIndexes.includes(x)) {
				const { [++currentIndex]: currentColumnValue } = columnIndexes;
				const { [currentIndex]: currentActualIndexes } = actualIndexes;
				rowData = {
					...rowData,
					[currentColumnValue]: getObjectValue(row, currentActualIndexes),
				};
			} else {
				rowData = {
					...rowData,
					[x]: undefined,
				};
			}
		}
		return Object.values(rowData);
	});

	return rows;
}
