import { ISSUER_PREFIX, REVOKE_CERT_ROUTE } from "@cti-workspace/helpers";
import { putApi } from "../api";

async function revokeCert(jwtToken, id) {
	try {
		const url = `/${ISSUER_PREFIX}/${REVOKE_CERT_ROUTE}/`;
		await putApi(url, { id });
		return true;
	} catch (err) {
		throw err;
	}
}

export default revokeCert;
