import { useEffect, useLayoutEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Switch, TextField, Button, HelperText } from "@cti-workspace/ui";
import styled from "styled-components";
import FabricCanvas from "./components/FabricCanvas";
import FabricToolbar from "./components/FabricToolbar";
import { FabricContextProvider } from "./context/FabricContext";
import { getErrMsg, getObjectValue, FABRIC_DIMENSIONS } from "@cti-workspace/helpers";
import { useSnackbar } from "notistack";

const CustomScroll = styled.div`
	height: ${(props) => props.height};
	overflow: auto;
	padding: 10px;
	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: #f5f5f5;
	}

	::-webkit-scrollbar {
		width: 2px;
		background-color: #f5f5f5;
	}

	::-webkit-scrollbar-thumb {
		background-color: #000000;
	}
`;

const uniquePageName = [];

// getCertDimension should take the default designs dimensions

const FabricBuilder = forwardRef(({ control, register, setCopyRendererConfigId, getCertDimensions = () => {}, setFabricContentVariables = () => {}, fabricContentVariables = {}, setFabricContentChange = {}, fabricContentChange = {} }, ref) => {
	const staticId = `ID_${Math.floor(Math.random() * 1000000)}`;
	const [fabricPages, setFabricPages] = useState([{ id: staticId, credDimensions: "us_letter_landscape" }]);
	const [activePage, setActivePage] = useState(0);
	const [pageNameEdit, setPageNameEdit] = useState(-1);
	const [pageNameError, setPageNameError] = useState([]);
	const [defaultPage, setDefaultPage] = useState(staticId);
	const fabricCertPageRef = useRef([]);
	const fabricProviderRef = useRef(null);
	const wrapperRef = useRef(null);
	const fabricCanvasContainerRef = useRef(null);
	const [deletePageIndex, setDeletePage] = useState(null);
	const canvasDimensionsRef = useRef([
		{
			width: 0,
			height: 0,
		},
	]);

	const [dimensionModal, setDimensionModal] = useState({
		isOpen: false,
		onChange: () => {},
		changedDimension: "",
	});

	const oldCertDimensions = {
		landscape: "us_letter_landscape",
		portrait: "us_letter_portrait",
	};

	// YASH TODO : check the RHF documentation, there should be better ways to get the fabricCertDesigner, check useWatch
	const { fabricCertDesigner: fabricCertDesignerContext } = control.defaultValuesRef.current;

	useEffect(() => {
		if (fabricCertDesignerContext?.isMultiPage) {
			let fabricDefaultPage = 0;
			let allPages = [];
			// YASh TODO: improve the below destructuring -DONE
			const keyObject = Object.keys(fabricCertDesignerContext?.payload);
			const defaultPageKey = getObjectValue(fabricCertDesignerContext, "payload.defaultPage");
			const { [defaultPageKey]: defaultPage } = fabricCertDesignerContext?.payload;

			for (let i = 0; i < keyObject?.length; i++) {
				const { [i]: pageKey } = keyObject;
				const { [pageKey]: page } = fabricCertDesignerContext?.payload;
				if (typeof page === "string") continue;
				allPages = [...allPages, page];

				const credDimensionObj = page.credDimension.split("X");

				if (defaultPage === pageKey) {
					fabricDefaultPage = i;
				}

				canvasDimensionsRef.current[i] = {
					width: Number(credDimensionObj[0]),
					height: Number(credDimensionObj[1]),
				};
			}

			setActivePage(fabricDefaultPage);
			setDefaultPage(defaultPage?.id);
			setFabricPages(allPages);
		}
	}, [fabricCertDesignerContext?.isMultiPage]);

	const { enqueueSnackbar } = useSnackbar();

	// YASH TODO: move the below ratios to constants file -DONE
	useLayoutEffect(() => {
		const width = fabricCanvasContainerRef?.current?.offsetWidth - 25;
		let height = 0;
		switch (fabricPages[0].credDimensions) {
			case "us_letter_landscape":
				height = Math.floor(width / FABRIC_DIMENSIONS.US_LETTER_LANDSCAPE);
				break;
			case "us_letter_portrait":
				height = Math.floor(width / FABRIC_DIMENSIONS.US_LETTER_PORTRAIT);
				break;
			case "a4_landscape":
				height = Math.floor(width / FABRIC_DIMENSIONS.A4_LANDSCAPE);
				break;
			case "a4_portrait":
				height = Math.floor(width / FABRIC_DIMENSIONS.A4_PORTRAIT);
				break;
			default:
				height = Math.floor(width / FABRIC_DIMENSIONS.US_LETTER_LANDSCAPE);
		}
		canvasDimensionsRef.current[0] = {
			width,
			height,
		};
	}, []);

	const getFabricObject = (ignoreError = false) => {
		let error = false;
		let transformedObject = {};
		let orientationError = [];
		let elementError = [];
		let pageNameError = [];
		let defaultPageName = "";
		for (let i = 0; i < fabricPages.length; i++) {
			const { [i]: page } = fabricPages;
			const { [i]: currentCanvasDimension } = canvasDimensionsRef.current;
			if (!page.credDimensions) {
				orientationError.push(`page ${i + 1}`);
				error = true;
			}

			if (page.fabricCertDesigner || (!page?.backgroundImage && !page?.objects?.length)) {
				elementError.push(`page ${i + 1}`);
				error = true;
			}

			if (!page.pageName?.trim()) {
				pageNameError.push(`page ${i + 1}`);
				error = true;
			}
			if (defaultPage === page.id) defaultPageName = page.pageName;
			transformedObject = {
				...transformedObject,
				[page.pageName]: { ...page, credDimension: `${currentCanvasDimension.width}X${currentCanvasDimension.height}` },
			};
		}

		if (error) {
			if (orientationError.length && !ignoreError) {
				enqueueSnackbar(getErrMsg(null, `Please select certificate orientation ${orientationError.slice(0, orientationError.length - 1).join(", ")}${(orientationError.length === 1 ? "" : "and ") + orientationError[orientationError.length - 1]}.`), {
					variant: "error",
				});
			}
			if (elementError.length && !ignoreError) {
				enqueueSnackbar(
					getErrMsg(
						null,
						`The Credential Definition can not be saved yet, please first add a Certificate Design or select a Certificate Template to use on ${elementError.slice(0, elementError.length - 1).join(", ")}${
							(elementError.length === 1 ? "" : " and ") + elementError[elementError.length - 1]
						}.`
					),
					{ variant: "error" }
				);
			}
			if (pageNameError.length && !ignoreError) {
				enqueueSnackbar(getErrMsg(null, `Please add a page name on ${pageNameError.slice(0, pageNameError.length - 1).join(", ")}${(pageNameError.length === 1 ? "" : " and ") + pageNameError[pageNameError.length - 1]}.`), { variant: "error" });
			}
			return { error: true, ...transformedObject, defaultPage: defaultPageName };
		}
		return { ...transformedObject, defaultPage: defaultPageName };
	};

	useImperativeHandle(ref, () => ({ getFabricObject, handleDimensionChangeModal }));

	const onChangeName = (index, event) => {
		const { [index]: page } = fabricPages;
		const pageName = fabricCertPageRef.current[index].value?.trim();
		const pageID = pageName?.toLowerCase()?.replace(/\s+/g, "");
		const pageNameIndex = uniquePageName.findIndex((value) => value === pageID);

		if (pageNameIndex !== -1 && pageNameIndex !== index) {
			if (event !== "blur") {
				const pageNameErrorCopy = [...pageNameError];
				pageNameErrorCopy[index] = true;
				setPageNameError([...pageNameErrorCopy]);
			}
			return;
		}
		uniquePageName[index] = pageID;
		setFabricPages([
			...fabricPages.slice(0, index),
			{
				...page,
				pageName: pageName?.trim(),
			},
			...fabricPages.slice(index + 1, fabricPages.length),
		]);
		if (pageNameError[index]) setPageNameError([...pageNameError.slice(0, index), false, ...pageNameError.slice(index + 1, pageNameError.length)]);
		setPageNameEdit(-1);
	};

	const enablePageEdit = (value) => {
		setTimeout(() => {
			fabricCertPageRef.current[value].value = fabricPages?.[value]?.pageName ?? "";
			fabricCertPageRef.current[value]?.focus();
		}, 0);
		setPageNameEdit(value);
	};

	const deletePage = () => {
		const index = deletePageIndex;
		fabricProviderRef.current.removeCanvas(index);
		setFabricPages([...fabricPages.slice(0, index), ...fabricPages.slice(index + 1, fabricPages.length)]);
		setActivePage(index === 0 ? index : index - 1);
		canvasDimensionsRef.current = [...canvasDimensionsRef.current.slice(0, index), ...canvasDimensionsRef.current.slice(index + 1, canvasDimensionsRef.current.length)];
		setDeletePage(null);
	};

	const onPageUpdate = (index, value) => {
		setFabricPages((prevFabricPages) => [...prevFabricPages.slice(0, index), { ...prevFabricPages[index], ...value }, ...prevFabricPages.slice(index + 1, prevFabricPages.length)]);
	};

	useEffect(() => {
		scrollToElement(activePage);
	}, [activePage]);

	const scrollToElement = (index) => {
		document.getElementById(fabricPages[index].id)?.scrollIntoView({
			behavior: "smooth",
		});
	};

	const changeDimension = (value) => {
		const currentActivePage = fabricPages[activePage];
		const updatedID = `ID_${Math.floor(Math.random() * 1000000)}`;

		if (currentActivePage.id === defaultPage) {
			setDefaultPage(updatedID);
		}

		setFabricPages([
			...fabricPages.slice(0, activePage),
			{
				id: updatedID,
				credDimensions: value,
			},
			...fabricPages.slice(activePage + 1, fabricPages.length),
		]);

		const width = canvasDimensionsRef.current[0].width;
		let height = 0;

		switch (value) {
			case "us_letter_landscape":
				height = Math.floor(width / FABRIC_DIMENSIONS.US_LETTER_LANDSCAPE);
				break;
			case "us_letter_portrait":
				height = Math.floor(width / FABRIC_DIMENSIONS.US_LETTER_PORTRAIT);
				break;
			case "a4_landscape":
				height = Math.floor(width / FABRIC_DIMENSIONS.A4_LANDSCAPE);
				break;
			case "a4_portrait":
				height = Math.floor(width / FABRIC_DIMENSIONS.A4_PORTRAIT);
				break;
			default:
				height = Math.floor(width / FABRIC_DIMENSIONS.US_LETTER_LANDSCAPE);
		}
		canvasDimensionsRef.current[activePage] = {
			width,
			height,
		};
	};

	const handleDimensionChangeModal = (open, onChange, changedDimension, noObjects = false) => {
		setDimensionModal({ isOpen: open, onChange, changedDimension: changedDimension });
		if (noObjects) {
			onChange && onChange(changedDimension);
		}
	};

	const credDimension = oldCertDimensions[fabricPages?.[activePage]?.credDimensions] || fabricPages?.[activePage]?.credDimensions || "us_letter_landscape";

	return (
		<div style={{ width: "100%", padding: "2% 0" }}>
			<div ref={fabricCanvasContainerRef} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
				{canvasDimensionsRef.current[0].width > 0 ? (
					<FabricContextProvider total={fabricPages?.length ?? 0} ref={fabricProviderRef} control={control}>
						<FabricToolbar
							id={activePage}
							control={control}
							register={register}
							setCopyRendererConfigId={setCopyRendererConfigId}
							credDimension={credDimension}
							changeDimension={(updatedValue) => {
								if (updatedValue === credDimension) return true;
								const { objects = [], backgroundImage } = fabricPages[activePage] ?? {};
								if (objects.length > 0 || backgroundImage) {
									handleDimensionChangeModal(true, changeDimension, updatedValue);
								} else {
									handleDimensionChangeModal(false, changeDimension, updatedValue, true);
								}
							}}
							setFabricContentVariables={setFabricContentVariables}
							fabricContentVariables={fabricContentVariables}
							setFabricContentChange={setFabricContentChange}
							fabricContentChange={fabricContentChange}
						/>
						<CustomScroll height={Number(canvasDimensionsRef.current[0].height) + 85 + "px"} ref={wrapperRef}>
							{fabricPages.length
								? fabricPages.map((value, index) => (
										<div
											id={value?.id}
											key={value?.id}
											onClick={(e) => {
												if (activePage !== index) {
													setActivePage(index);
													e.currentTarget.scrollIntoView({ behavior: "smooth" });
												}
											}}>
											<p
												style={{
													display: "flex",
													alignItems: "center",
													minHeight: "26px",
													marginBottom: "5px",
													marginTop: !!index ? "1em" : 0,
												}}>
												<b style={{ marginRight: "5px" }}>
													Page {index + 1}/{fabricPages.length} -
												</b>
												{pageNameEdit === index ? (
													<>
														<TextField
															size="v-small"
															inputRef={(me) => (fabricCertPageRef.current[index] = me)}
															onBlur={(e) => {
																const cancelButtonId = "cancel-pageName-edit-" + value?.id;
																if (e?.nativeEvent?.relatedTarget?.id === cancelButtonId) return;
																onChangeName(index, "blur");
															}}
															borderRadiusNone={true}
															style={{ marginRight: "5px" }}
															error={pageNameError[index]}
														/>
														<Button buttonType="icon" noBorder={true} borderRadius={"5px"} color={"white"} icon="check" iconSize="0.8rem" style={{ marginRight: "2px" }} onClick={() => onChangeName(index)} />
														<Button id={"cancel-pageName-edit-" + value?.id} buttonType="icon" noBorder={true} borderRadius={"5px"} color={"white"} icon="cancel" iconSize="0.8rem" onClick={() => setPageNameEdit(-1)} />
														{pageNameError[index] ? <HelperText error={true}>Page name must be unique</HelperText> : ""}
													</>
												) : (
													<u style={{ cursor: "pointer" }} onClick={() => enablePageEdit(index)}>
														{!!fabricPages?.[index]?.pageName?.trim() ? fabricPages?.[index]?.pageName?.trim() : "Add page name"}
													</u>
												)}
												<div
													style={{
														marginLeft: "auto",
														display: "flex",
														alignItems: "center",
														gap: "5px",
														justifyContent: "center",
													}}>
													{fabricPages.length > 1 ? (
														<>
															<Button
																buttonType="icon"
																color={"white"}
																icon="caret-up"
																noBorder={true}
																disabled={index === 0}
																borderRadius={"5px"}
																iconSize="0.8rem"
																onClick={(e) => {
																	e?.stopPropagation();
																	setActivePage(index - 1);
																	scrollToElement(index - 1);
																}}
															/>
															<Button
																buttonType="icon"
																color={"white"}
																icon="caret-down"
																borderRadius={"5px"}
																noBorder={true}
																disabled={index === fabricPages.length - 1}
																iconSize="0.8rem"
																onClick={(e) => {
																	e?.stopPropagation();
																	setActivePage(index + 1);
																	scrollToElement(index + 1);
																}}
															/>
														</>
													) : null}
													<Switch
														color="secondary"
														size="small"
														checked={defaultPage === value?.id}
														onChange={() => {
															if (defaultPage === value?.id) return;
															setDefaultPage(value?.id);
														}}
														label={"Default"}
														labelPlacement="left"
													/>{" "}
													{fabricPages.length > 1 && defaultPage !== value?.id ? <Button buttonType="icon" color={"white"} noBorder={true} borderRadius={"5px"} icon="delete" iconSize="0.8rem" onClick={() => setDeletePage(index)} /> : null}
												</div>
											</p>
											<FabricCanvas
												control={control}
												id={index}
												isActive={activePage === index}
												onPageUpdate={onPageUpdate}
												fabricPage={fabricPages[index]}
												width={canvasDimensionsRef.current[index].width}
												height={canvasDimensionsRef.current[index].height}
											/>
										</div>
								  ))
								: null}
						</CustomScroll>
						<div style={{ marginTop: "10px", width: "100%" }}>
							{fabricPages.length < 10 ? (
								<Button
									color={"white"}
									noBorder={true}
									style={{ padding: "0px 30px" }}
									onClick={() => {
										const currentDefaultPage = fabricPages.filter((value) => value.id === defaultPage);
										setFabricPages([
											...fabricPages,
											{
												id: `ID_${Math.floor(Math.random() * 1000000)}`,
												credDimensions: currentDefaultPage?.[0]?.credDimensions || "us_letter_landscape",
											},
										]);

										const width = canvasDimensionsRef.current[0].width;
										let height = 0;

										switch (currentDefaultPage?.[0]?.credDimensions) {
											case "us_letter_landscape":
												height = Math.floor(width / FABRIC_DIMENSIONS.US_LETTER_LANDSCAPE);
												break;
											case "us_letter_portrait":
												height = Math.floor(width / FABRIC_DIMENSIONS.US_LETTER_PORTRAIT);
												break;
											case "a4_landscape":
												height = Math.floor(width / FABRIC_DIMENSIONS.A4_LANDSCAPE);
												break;
											case "a4_portrait":
												height = Math.floor(width / FABRIC_DIMENSIONS.A4_PORTRAIT);
												break;
											default:
												height = Math.floor(width / FABRIC_DIMENSIONS.US_LETTER_LANDSCAPE);
										}

										canvasDimensionsRef.current[fabricPages.length] = {
											width,
											height,
										};
										setActivePage(fabricPages.length);
									}}>
									+ Add page
								</Button>
							) : null}
						</div>
					</FabricContextProvider>
				) : null}
			</div>
			<Dialog onClose={() => setDeletePage(null)} open={Boolean(deletePageIndex === 0 || deletePageIndex)} maxWidth="xs" borderRadius={"10px"}>
				<DialogTitle noBorder style={{ padding: "0.5rem 1rem" }}>
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<Button buttonType="icon" icon="cancel" color="danger" invert iconSize="1rem" noBorder buttonTransparent onClick={() => setDeletePage(null)} />
					</div>
				</DialogTitle>
				<DialogContent style={{ padding: "0.5rem 1rem" }}>
					<div style={{ textAlign: "center", fontSize: "1.15rem" }}>Are you sure you would like to delete this page? This action cannot be undone.</div>
				</DialogContent>
				<DialogActions>
					<Button color="primary" invert onClick={() => setDeletePage(null)}>
						Cancel
					</Button>
					<Button color="warning" onClick={deletePage}>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog onClose={() => handleDimensionChangeModal(false)} open={Boolean(dimensionModal.isOpen)} maxWidth="xs" borderRadius={"10px"}>
				<DialogTitle noBorder style={{ padding: "0.5rem 1rem" }}>
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<Button buttonType="icon" icon="cancel" color="danger" invert iconSize="1rem" noBorder buttonTransparent onClick={() => handleDimensionChangeModal(false)} />
					</div>
				</DialogTitle>
				<DialogContent style={{ padding: "0.5rem 1rem" }}>
					<div style={{ textAlign: "center", fontSize: "1.15rem" }}>Are you sure you would like to change the certificate orientation? With this action your current design will be lost.</div>
				</DialogContent>
				<DialogActions>
					<Button color="primary" invert onClick={() => handleDimensionChangeModal(false)}>
						Cancel
					</Button>
					<Button
						color="warning"
						onClick={() => {
							dimensionModal?.onChange(dimensionModal.changedDimension);
							handleDimensionChangeModal(false);
						}}>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
});

export default FabricBuilder;
