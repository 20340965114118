import { useEffect, useState } from "react";
import Chart from "./Chart";

const dataset = {
	_id: "631c6468c3797400393dd4cd",
	orgId: "62dacd4b6d71d30039ddcf89",
	accountId: "62dacd536d71d30039ddcf8d",
	createdAt: 1662805096,
	status: "ACTIVE",
	steps: [
		{
			stepId: "340dcfb6-4e8d-4cb6-9ff6-a5b731cd3e27",
			name: "Test",
			description: "wge4gweg",
      icon: "https://ipwru.s3.us-east-1.amazonaws.com/prod/ipwru_logo.png",
			type: "ACTIVITY",
			parentId: "",
			credentialId: "630e34c98f35cf002e05b8d6",
			level: 0,
			isRequired: false,
			status: "ACTIVE",
		},
		{
			stepId: "0bd8ad25-0688-4dc7-a85c-b8d9c8717317",
			name: "gigig",
			description: "hhlh",
      icon: "https://ipwru.s3.us-east-1.amazonaws.com/prod/ipwru_logo.png",
			type: "ACTIVITY",
			parentId: "340dcfb6-4e8d-4cb6-9ff6-a5b731cd3e27",
			credentialId: "630e34c98f35cf002e05b8d6",
			level: 1,
			isRequired: false,
			status: "ACTIVE",
		},
		{
			stepId: "85b0c7af-a67d-4761-bbac-f1e528becba7",
			name: "hjkhl",
			description: "hkh",
			icon: "https://ipwru.s3.amazonaws.com/prod/seal.png",
			type: "ACTIVITY",
			parentId: "0bd8ad25-0688-4dc7-a85c-b8d9c8717317",
			credentialId: "62f624bf8443a70042120e1e",
			level: 2,
			isRequired: false,
			status: "ACTIVE",
		},
		{
			stepId: "01bacfcc-390f-497e-a975-9177caee788b",
			name: "wee",
			description: "ewgew",
      icon: "https://ipwru.s3.us-east-1.amazonaws.com/prod/ipwru_logo.png",
			type: "ACTIVITY",
			parentId: "340dcfb6-4e8d-4cb6-9ff6-a5b731cd3e27",
			credentialId: "630e34c98f35cf002e05b8d6",
			level: 1,
			isRequired: false,
			status: "ACTIVE",
		},
		{
			stepId: "28351ace-457f-4c2e-8876-d80a69f3bf48",
			name: "ewvwe",
			description: "wevwe",
			icon: "https://ipwru.s3.amazonaws.com/prod/seal.png",
			type: "ACTIVITY",
			parentId: "01bacfcc-390f-497e-a975-9177caee788b",
			credentialId: "631b73aec3797400393dd4c9",
			level: 2,
			isRequired: false,
			status: "ACTIVE",
		},
	],
};

const Newpathway = () => {
	const [data, setData] = useState();

	useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {
		setData(dataset.steps);
	};

	return (
		<div style={{ height: "100vh", width: "100vw", display: "flex" }}>
			<Chart data={data} />
		</div>
	);
};

export default Newpathway;
