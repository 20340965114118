import { MAX_FILE_UPLOAD_SIZE_IN_KB } from "@cti-workspace/helpers";

export const getFileValidationMessage = (file, isBulkUpload = false) => {
	let onChangeValidation = "";
	if (!file.type.startsWith("image/")) {
		onChangeValidation = `Only image files are allowed.`;
	} else if (file.size / 1024 > MAX_FILE_UPLOAD_SIZE_IN_KB) {
		onChangeValidation = `File size cannot exceed ${MAX_FILE_UPLOAD_SIZE_IN_KB} KBs.`;
	}
	if (isBulkUpload && onChangeValidation) {
		onChangeValidation = onChangeValidation.concat(` Please check the file ${file.name}`);
	}
	return onChangeValidation;
};

export const getEncodedFileContent = (file) => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			resolve(reader.result);
		};
	});
};

export const isStartsWith = (target, matchedString) => typeof target === "string" && target.indexOf(matchedString) === 0;

export const isEncodedContent = (content) => isStartsWith(content, "data:");

export const getFileName = (file) => {
	return file.name.split(".").slice(0, -1).join(".");
};
