import { useState, useEffect } from "react";
import * as React from "react";
import useCredentialHook from "../../hooks/useCredentialHook";
import useUserHook from "../../hooks/useUserHook";
import { useParams } from "react-router-dom";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { makeStyles } from "@material-ui/core/styles";
import TvIcon from "@material-ui/icons/Tv";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import CredDesign from "./NewCred";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		overflow: "hidden",
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.secondary,
	},
	toggleContainer: {
		margin: theme.spacing(2, 0),
	},
}));

function ToggleButtonNotEmpty() {
	const [formats, setFormats] = React.useState(() => "tv");

	const handleFormat = (event, newFormats) => {
		if (!!newFormats) {
			setFormats(newFormats);
		}
	};

	const classes = useStyles();

	return (
		<Grid container spacing={2} style={{ float: "right" }}>
			<Grid item sm={12} md={6}>
				<div className={classes.toggleContainer}>
					<ToggleButtonGroup value={formats} onChange={handleFormat} aria-label="device">
						<ToggleButton value="tv" aria-label="tv">
							<TvIcon />
						</ToggleButton>
						<ToggleButton value="phone" aria-label="phone">
							<PhoneAndroidIcon />
						</ToggleButton>
					</ToggleButtonGroup>
				</div>
			</Grid>
		</Grid>
	);
}

export default function DesignContainer() {
	// const { credentials } = useCredentialHook();
	// const params = useParams();
	const classes = useStyles();
	const [credData, setCredData] = useState({});
	// const dummy = {
	// 	recipientName: "<RecipientName>",
	// 	issuedOn: "<IssuedOnDate>",
	// };
	const dimensions = useWindowDimensions();

	// useEffect(() => {
	// 	if (!params.id) return;
	// 	// progressStart();
	// 	const credFilteredData = getObjectValue(credentials, "items", []).filter((item) => item._id === params.id);
	// 	let data = {};
	// 	if (credFilteredData.length) {
	// 		data = credFilteredData[0];
	// 		data.additionalData = typeof data.additionalData === "object" ? data.additionalData : JSON.parse(data.additionalData);
	// 		data.definition = typeof data.definition === "object" ? data.definition : JSON.parse(data.definition);
	// 		const { transcript } = data.definition;
	// 		const { description } = data.additionalData;
	// 		data.transcript = transcript.map((a) => a.name);
	// 		data.description = description;
	// 		setCredData({ ...credData, ...data });
	// 		// progressDone();
	// 	}
	// }, [credentials]);
	return <div className={classes.root}>{credData.definition && <CredDesign data={credData} placeHolder={dummy} dimensions={dimensions} />}</div>;
}
