import { useEffect, useState } from "react";
import { useTheme, Grid, Paper, Switch, Hidden, Typography } from "@material-ui/core";
import { useForm, useWatch, FormProvider } from "react-hook-form";
import FormDefineCredential from "./FormDefineCredential";
import PreviewDefineCredential from "./PreviewDefineCredential";
import HeaderWithLine from "../../components/HeaderWithLine";
import { useParams } from "react-router-dom";
import useRendererConfigOrgHook from "../../hooks/useRendererConfigOrgHook";
import { getObjectValue } from "@cti-workspace/helpers";
import { Container, Box, Card, Typography as Text } from "@cti-workspace/ui";

function VisualDefineCredential({ defaultValues = { rendererConfigId: "", designType: "default" } }) {
	const theme = useTheme();
	const methods = useForm({
		mode: "onChange",
		shouldFocusError: false,
		criteriaMode: "all",
		defaultValues,
	});
	const [displayPreview, setDisplayPreview] = useState(false);
	const [selectedSavedIconUrl, setSelectedSavedIconUrl] = useState(null);
	const { getAllOrgsRendererConfigList } = useRendererConfigOrgHook();
	const [getOrgConfigList, setGetOrgConfigList] = useState([]);
	const [credReadOnly, setCredReadOnly] = useState(false);

	const params = useParams();
	const values = useWatch({
		control: methods.control,
	});

	useEffect(() => {
		// validations for duplicate input keys
		values["resources"] &&
			values["resources"].forEach((val, i) => {
				methods.trigger(`resources[${i}].key`);
			});
		values["transcripts"] &&
			values["transcripts"].forEach((val, i) => {
				methods.trigger(`transcripts[${i}].key`);
			});
	}, [values]);

	useEffect(() => {
		getAllOrgsRendererConfigList(10, 1, null, false)
			.then((response) => {
				console.log("first", response);
				let { items } = response;
				setGetOrgConfigList(items || []);
			})
			.catch((error) => {
				setGetOrgConfigList([]);
			});
	}, []);

	return (
		<FormProvider {...methods}>
			<div style={{ minHeight: 56 }} />
			<Container maxWidth={"md"}>
				<Box>
					<Card hasBorder borderRadius={"10px"} elevation={6} style={{ padding: "0.7rem 0.9rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<Text type="subtitle">{`${params.id ? "Edit" : "New"} Credential Definition`}</Text>
						{/* {getObjectValue(values, "designType", "") !== "fabricDesign" && (
							<Box style={{ display: "flex", alignItems: "center" }}>
								<Text type="subtitle">
									Display Preview
								</Text>
								<Switch
									value="on"
									checked={displayPreview}
									disabled={getObjectValue(values, "designType", "") == "fabricDesign"}
									onChange={() =>
										setDisplayPreview((pre) => {
											return !pre;
										})
									}
								/>
							</Box>
						)} */}
					</Card>
					<FormDefineCredential setSelectedSavedIconUrl={setSelectedSavedIconUrl} getOrgConfigList={getOrgConfigList} isCredReadOnly={setCredReadOnly} />
					{getObjectValue(values, "designType", "") !== "fabricDesign" && getObjectValue(values, "rendererConfigId", "") && (
						<Hidden only={["xs", "sm"]}>
							<Box>
								<PreviewDefineCredential value={values} selectedSaveIconUrl={selectedSavedIconUrl} />
							</Box>
						</Hidden>
					)}
				</Box>
				<Grid container justifyContent="center" alignItems="stretch">
					{/* <Hidden only={["xs", "sm"]}>
						<Grid item container xs={12} spacing={2} style={{ marginBottom: theme.spacing(1) }}>
							<Card
								elevation={2}
								style={{
									padding: theme.spacing(2),
									width: "100%",
									display: "flex",
									justifyContent: "space-between",
								}}>
								<Box>
									<HeaderWithLine title={`${params.id ? "Edit" : "New"} Credential Definition`} variant={"h6"} />
									{credReadOnly && (
										<Typography variant="caption" color="error">
											This is a read only credential
										</Typography>
									)}
								</Box>
								{getObjectValue(values, "designType", "") !== "fabricDesign" && (
									<Box display="flex" justifyContent="center">
										<Typography variant="h6" color="initial">
											{" "}
											Display Preview
										</Typography>{" "}
										<Switch
											value="on"
											checked={displayPreview}
											disabled={getObjectValue(values, "designType", "") == "fabricDesign"}
											onChange={() =>
												setDisplayPreview((pre) => {
													return !pre;
												})
											}
										/>
									</Box>
								)}
							</Card>
						</Grid>
					</Hidden> */}
					{/* <Grid container spacing={1}>
						<Grid item xs={12}>
							<FormDefineCredential setSelectedSavedIconUrl={setSelectedSavedIconUrl} getOrgConfigList={getOrgConfigList} isCredReadOnly={setCredReadOnly} />
						</Grid>
					</Grid>
					{displayPreview && (
						<Grid container spacing={1} style={{ marginTop: theme.spacing(2), zIndex: 0 }}>
							<Hidden only={["xs", "sm"]}>
								<Grid item md={12}>
									{getObjectValue(values, "designType", "") != "fabricDesign" && <PreviewDefineCredential value={values} selectedSaveIconUrl={selectedSavedIconUrl} />}
								</Grid>
							</Hidden>
						</Grid>
					)} */}
				</Grid>
			</Container>
		</FormProvider >
	);
}

VisualDefineCredential.propTypes = {};

export default VisualDefineCredential;
