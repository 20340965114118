function buildCredentialDefinition(account, credentialName, description, transcriptFieldsArray, imageUrl) {
	const transcriptFields = transcriptFieldsArray.map((item, i) => ({
		name: item,
		courseCode: String(i),
		grade: "undefined",
	}));

	return {
		id: "",
		$template: {
			name: "GOVTECH_DEMO", // will need to define this on render for each account
			type: "EMBEDDED_RENDERER",
			url: "https://certificate-renderer.now.sh",
		},
		name: credentialName,
		issuedOn: "",
		issuers: [
			{
				name: account.organizations.default,
				url: "https://www.ipwru.com ",
				certificateStore: "0x733a6F54869De9dCEA8CEEb4650BDa651E285e94", // Think this will always be the same?  Issue behind our cert store
				identityProof: {
					type: "DNS-TXT",
					location: "ipwru",
				},
			},
		],
		recipient: {
			name: "",
			email: "",
		},
		transcript: transcriptFields,
		additionalData: {
			description: description,
			imageUrl: imageUrl,
			issuerImageUrl: "",
		},
	};
}

export default buildCredentialDefinition;
