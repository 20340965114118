import { ORGANIZATIONS_PREFIX } from "@cti-workspace/helpers";
import { postApi } from "./api";

async function createOrganization(formData, tokenValue) {
	const admin = formData.account;
	delete formData.account;
	const organization = {
		...formData,
	};
	const payload = {
		organization,
		admin,
		tokenValue,
	};
	const url = `/${ORGANIZATIONS_PREFIX}/create`;
	try {
		const org = await postApi(url, payload);
		return org;
	} catch (err) {
		throw err;
	}
}

export default createOrganization;
