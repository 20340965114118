import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import { Button } from "@cti-workspace/ui";
import AdvTextField from "../../components/AdvTextField";
import HeaderWithLine from "../../components/HeaderWithLine";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ArrayField from "./arrayField";
import AddButton from "../../components/NewImageButton";
import { useSnackbar } from "notistack";
import ConnectedCheckbox from "../../components/ConnectedCheckbox";
import useNProgressHook from "../../hooks/useNProgressHook";
import { ORGANIZATIONS_PREFIX, UploadImage, RegisterOrganization, getDatabaseConnections, fetchEmailTemplates, postApi, orderBy, getObjectValue } from "@cti-workspace/helpers";
import AdvFormControl from "../../components/AdvFormControl";
import { Box } from "@cti-workspace/ui";

const CreateOrganization = (props) => {
	const { register, control, handleSubmit, errors, reset, watch, setError, clearErrors, getValues } = useForm({
		defaultValues: {
			domains: [{ key: "default", value: "https://www.ipwru.com" }],
			aliases: [{ key: "iPWRU" }],
			renderers: [{ key: "default", value: "IPWRU_DEFAULT" }],
			certificateStores: [{ key: "default", value: "" }],
			images: [],
			issuers: {
				issuer: true,
				verifier: false,
			},
		},
	});
	const [existingDbConnections, setExistingDbConnections] = useState([]);
	const [emailTemplates, setEmailTemplates] = useState([]);
	const [reloadCountForDbs] = useState(1);

	useEffect(() => {
		async function fetchData() {
			const dbConnections = await getDatabaseConnections();
			if (dbConnections) {
				setExistingDbConnections(orderBy(dbConnections.databaseConnections, "connectionName", "asc"));
			}
		}
		fetchData();
	}, [reloadCountForDbs]);

	useEffect(() => {
		const fetchData = async () => {
			setEmailTemplates(await fetchEmailTemplates());
		};
		fetchData();
	}, []);

	const { enqueueSnackbar } = useSnackbar();
	const { progressStart, progressDone } = useNProgressHook();
	const watchResources = watch("images");
	const watchIssuers = watch("issuers");

	const validateFields = async (fieldName, removedIndex) => {
		if (watchResources?.length > 0) {
			const values = watchResources.map((value, index) => value.key !== "" && index !== removedIndex && value.key);
			values.forEach((value, index) => {
				if (value && value !== "" && values.indexOf(value) !== index)
					setError(`${fieldName}[${index}].key`, {
						type: "manual",
						message: "Resource key already exsists",
					});
				else clearErrors(`${fieldName}[${index}].key`);
			});
		}
	};

	const handleCreateOrganizationSubmit = async (event) => {
		console.log(event);
		if (errors && Object.keys(errors).length === 0) {
			let imageUrlsObj = {};
			try {
				progressStart();
				const formImages = event.images;
				if (formImages && formImages.length > 0) {
					for (const image of formImages) {
						let imageUrl = await UploadImage(image.value, undefined, "public-read");
						imageUrlsObj = {
							...imageUrlsObj,
							[image.key]: imageUrl,
						};
					}
					event["imageUrls"] = imageUrlsObj;
					delete event.images;
				}
				// put the default image, if the user have not selected
				if (!formImages) {
					event["imageUrls"] = {
						default: "https://res.cloudinary.com/dadlqfryt/image/upload/v1571451072/convergence-logos/Convergence_Logo_Final-01_tq5wtd.png",
					};
				}
				// convert the form to required format
				const issuersArr = Object.keys(event?.issuers);
				event["issuers"] = issuersArr.map((issuer) => (getObjectValue(event["issuers"], issuer) ? issuer : null)).filter((item) => item);
				// default is issuer;
				if (event["issuers"].length === 0) event["issuers"] = ["issuer"];
				event["aliases"] = (event["aliases"] && event["aliases"].map((item) => item.key)) || [];
				if (event["certificateStores"]) {
					event["certificateStores"].forEach((item) => {
						event["certificateStores"] = {
							[item.key]: item.value,
						};
					});
				} else {
					event["certificateStores"] = [];
				}
				event["domains"].forEach((item) => {
					event["domains"] = {
						[item.key]: item.value,
					};
				});
				event["renderers"].forEach((item) => {
					event["renderers"] = {
						[item.key]: item.value,
					};
				});
				event["dbConnections"] = [event["dbConnections"]];
				event.resources = event.emailTemplates
					? {
							emailTemplates: {
								["ISSUE_CREDENTIALS"]: event.emailTemplates,
							},
					  }
					: undefined;
				event.emailTemplates = undefined;
				let newOrg = await RegisterOrganization(event);
				if (newOrg) {
					// default the bulk issue for the organization should be enabled
					await postApi(`/${ORGANIZATIONS_PREFIX}/updateSettings`, { orgId: newOrg, settings: { lms: false, bulkIssue: true, richTextDescription: true, fabricDesign: true, analytics: true } });
					enqueueSnackbar(`New Org with ID:${newOrg} was created `, {
						variant: "success",
					});
					reset({
						domains: [
							{
								key: "default",
								value: "https://www.ipwru.com",
							},
						],
						aliases: [{ key: "iPWRU" }],
						renderers: [{ key: "default", value: "TRYBE_DEFAULT" }],
						certificateStores: [{ key: "default", value: "" }],
						images: [],
						dbConnections: [],
						issuers: {
							issuer: true,
							verifier: false,
						},
					});
					// set default bgImage to the organization
					const params = {
						orgId: newOrg,
						id: "TRYBE_DEFAULT",
						config: {
              bgImage: "https://ipwru.s3.us-east-1.amazonaws.com/prod/blue-rays.png",
						},
					};
					await postApi(`/${ORGANIZATIONS_PREFIX}/renderers`, params);
					enqueueSnackbar(`Added bgImage to the renderer for the organization`, {
						variant: "success",
					});
				}
				progressDone();
			} catch (err) {
				progressDone();
				enqueueSnackbar(`${err.message}`, {
					variant: "error",
				});
			}
		}
	};

	const removeField = (fieldName, index, remove) => {
		validateFields(fieldName, index);
		remove(index);
	};

	return (
		<form onSubmit={handleSubmit((event) => handleCreateOrganizationSubmit(event))}>
			<Grid container spacing={1} direction="column" justifyContent="center" alignItems="center" alignContent="center" wrap="nowrap">
				<Grid item container spacing={1} direction="row" justifyContent="flex-start" alignItems="center" alignContent="center">
					<Grid item xs={12}>
						<Box mx="auto" p={-2}>
							<HeaderWithLine title={"New Organization"} variant={"h6"} />
						</Box>
					</Grid>
					<Grid item xs={11}>
						<AdvTextField
							name="name"
							displayName="Organization Name"
							errors={errors}
							inputRef={register({
								required: "Organization Name is required",
							})}
							placeholder={"iPWRU"}
						/>
					</Grid>
					<Grid item xs={11}>
						<AdvTextField
							name="description"
							displayName="Organization Description"
							errors={errors}
							inputRef={register({
								required: "Organization Description is required",
							})}
							placeholder={"iPWRU"}
							multiline
							minRows={5}
							maxRows={10}
						/>
						{/* id="description" */}
					</Grid>
					<Grid item xs={11}>
						<AdvTextField
							name="admin"
							displayName="Organization Email"
							errors={errors}
							inputRef={register({
								required: "Organization Email is required",
								pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
							})}
							placeholder={"email@mail.com"}
						/>
						{/* id="description" */}
					</Grid>
					<Grid item xs={11}>
						<AdvTextField name="contact" displayName="Contact" errors={errors} inputRef={register()} placeholder={""} />
					</Grid>
					<Grid item xs={11}>
						<AdvTextField name="address" displayName="Address" errors={errors} inputRef={register()} placeholder={""} />
					</Grid>
					<Grid item xs={12}>
						<HeaderWithLine title={"Database Connection"} variant={"h6"} />
					</Grid>
					<Grid item xs={11}>
						<AdvFormControl errors={errors} name={"dbConnections"}>
							<Controller
								as={
									<Select id="organizations-simple-select-on-create" fullWidth>
										{existingDbConnections.map((item) => (
											<MenuItem key={item._id} value={item._id}>
												{item.connectionName}
											</MenuItem>
										))}
									</Select>
								}
								name="dbConnections"
								rules={{ required: "DB connection is required" }}
								control={control}
								defaultValue=""
							/>
						</AdvFormControl>
					</Grid>
					<Grid item xs={12}>
						<HeaderWithLine title={"Email Template"} variant={"h6"} />
					</Grid>
					<Grid item xs={11}>
						<AdvFormControl errors={errors} name={"emailTemplates"}>
							<Controller
								as={
									<Select id="email-template" fullWidth>
										{emailTemplates.map((item) => (
											<MenuItem key={item._id} value={item._id}>
												{item.name}
											</MenuItem>
										))}
										{!emailTemplates.length && (
											<MenuItem>
												<i>No Email Templates</i>
											</MenuItem>
										)}
									</Select>
								}
								name="emailTemplates"
								control={control}
							/>
						</AdvFormControl>
					</Grid>
					<Grid item xs={12}>
						<HeaderWithLine title={"Role"} variant={"h6"} />
					</Grid>
					<Grid item xs={12}>
						<ConnectedCheckbox name="issuers.issuer" defaultChecked inputRef={register()} label="Issuer" />
						<ConnectedCheckbox name="issuers.verifier" inputRef={register()} label="Verifier" />
						<ConnectedCheckbox name="issuers.endorser" inputRef={register()} label="Endorser" />
					</Grid>

					{watchIssuers?.verifier && (
						<Grid item xs={12}>
							<AdvTextField
								name="verifierId"
								displayName="Enter Verifier ID"
								errors={errors}
								inputRef={register({
									required: "Verifier ID is required",
								})}
								placeholder={"Verifier ID"}
							/>
						</Grid>
					)}
					<Grid item xs={12}>
						<ArrayField register={register} control={control} errors={errors} displayName="Organization Domains" name="domains" fieldStructure={{ key: "", value: "" }} help="what" removeField={removeField} validate={() => {}} disableEntries={1} />
					</Grid>
					<Grid item xs={12}>
						<ArrayField register={register} control={control} errors={errors} displayName="Organization aliases" name="aliases" fieldStructure={{ key: "" }} removeField={removeField} validate={() => {}} />
					</Grid>
					<Grid item xs={12}>
						<ArrayField register={register} control={control} errors={errors} displayName="Organization renderers" name="renderers" fieldStructure={{ key: "", value: "" }} removeField={removeField} validate={() => {}} disableEntries={1} />
					</Grid>
					<Grid item xs={12}>
						<ArrayField register={register} control={control} errors={errors} displayName="Organization Certificate Stores" name="certificateStores" fieldStructure={{ key: "", value: "" }} removeField={removeField} required={false} />
					</Grid>
					<Grid item xs={12}>
						<AddButton
							displayName="Select Images"
							name="images"
							control={control}
							errors={errors}
							register={register}
							validate={validateFields}
							removeField={removeField}
							addTypes={[
								{
									displayName: "New Image",
									type: ["image/jpeg", "image/png", "image/bmp"],
								},
								{
									displayName: "New  pdf file",
									type: [".pdf"],
								},
								{
									displayName: "Add Url",
									type: "url",
								},
							]}
						/>
					</Grid>

					<Grid item xs={12}>
						<Button type="submit" color="warning" fullWidth>
							ok
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
};

CreateOrganization.propTypes = {};

export default CreateOrganization;
