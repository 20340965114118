import { forwardRef } from "react";
import styled from "styled-components";

const ChartBackground = styled.div`
	margin: 0;
	position: fixed;
	margin-top: 1rem;
	padding: 1rem;
	width: calc(100% - 300px);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	background-image: radial-gradient(circle, #afafaf 1px, rgba(0, 0, 0, 0) 1px);
	background-size: 40px 40px;
`;
const Chart = styled.div`
	width: 100%;
	height: 100%;
	margin: auto;
`;

export const PathChart = forwardRef((_, ref) => {
	return (
		<ChartBackground>
			<Chart ref={ref} />
		</ChartBackground>
	);
});
