import { ADMIN_PREFIX, ISSUE_CREDENTIALS } from "@cti-workspace/helpers";
import { getApi } from "./api";

export default async function fetchPDFTemplates(category = ISSUE_CREDENTIALS) {
  const pDfTemplates = await getApi(`/${ADMIN_PREFIX}/getPdfTemplates?category=${category}`);
  return pDfTemplates.pdfTemplates.map((pdfTemplate) => ({
    ...pdfTemplate,
    default: pdfTemplate.categoryDefault,
    id: pdfTemplate.templateId,
  }));
}
