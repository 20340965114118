import styled from "styled-components";
import Theme from "../theme/theme";
import { useState } from "react";
import { Text } from "theme-ui";

const Wrapper = styled.div`
	pointer-events: ${(props) => (props.disabled ? "none" : "all")};
`;

const astrickStyles = `
	::after {
		content: " *";
		font-weight:normal;
		font-size:.8em;
		vertical-align: top;
	}
`;
const TextFieldWrapper = styled.div`
	width: 100%;
	position: relative;
	display: grid;
	grid-template-columns: ${({ startAdornment }) => startAdornment && "auto"} 1fr ${({ endAdornment }) => endAdornment && "auto"};
	align-items: center;
	border: 1px solid ${(props) => (props.error ? Theme.colors.error : props.disabled ? Theme.colors.textMuted : props.isFocused ? Theme.colors.warning : Theme.colors.textMuted)};
	border-radius: ${(props) => (props.size === "small" || props.size === "v-small" ? "4px" : "8px")};
	height: ${(props) => (props.size === "v-small" ? "26px" : props.size === "small" ? "40px" : props.multiline ? "auto" : "56px")};
	box-shadow: ${(props) => (props.isFocused ? `0px 0px 0px ${props.disabled ? "0px" : "1px"} ${props.error ? Theme.colors.error : props.disabled ? Theme.colors.textMuted : Theme.colors.warning} inset` : "none")};
	padding: 0 10px;
	background-color: white;

	&:hover {
		border-color: ${(props) => (props.error ? Theme.colors.error : props.disabled ? Theme.colors.textMuted : props.isFocused ? Theme.colors.warning : Theme.colors.danger)};
	}

	label {
		left: ${(props) => props.labelSpacing};
		top: ${(props) => (props.multiline ? "20%" : "50%")};
		transform: translateY(-50%);
		font-size: 1rem;
		color: ${(props) => (props.error ? Theme.colors.error : props.disabled ? Theme.colors.textMuted : Theme.colors.textSecondary)};
		letter-spacing: 1px;
		transition: 0.2s;
		font-weight: 600;
		position: absolute;
	}
`;

const InputField = styled.input`
	width: 100%;
	background: none;
	color: ${(props) => (props.disabled ? Theme.colors.textMuted : Theme.colors.text)};
	font-size: 1rem;
	max-height: ${(props) => (props.size === "v-small" ? "26px" : props.size === "small" ? "42px" : "58px")};
	border: none;
	line-height: 2rem;
	position: relative;
	z-index: 1;
	padding: 0;

	&:focus-visible {
		outline: none;
	}

	&:focus {
		border-width: 2px;
		border-color: ${(props) => (props.error ? Theme.colors.error : Theme.colors.warning)};
		&:hover {
			border-color: ${(props) => (props.error ? Theme.colors.error : Theme.colors.warning)};
		}
	}

	&::placeholder {
		opacity: ${(props) => (props.variant === "float" ? 0 : 1)};
	}

	&:focus + label,
	&:not(:placeholder-shown) + label {
		top: 0;
		font-size: 0.8rem;
		background: ${Theme.colors.backgroundSecondary};
		left: 10px;
		font-weight: normal;
	}

	&:focus + label {
		color: ${(props) => (props.error ? Theme.colors.error : props.disabled ? Theme.colors.textMuted : Theme.colors.warning)};
	}
`;

const InputMultilineField = styled.textarea`
	padding: 14px 0;
	width: 100%;
	animation-duration: 10ms;
	box-sizing: border-box;
	background: none;
	border: none;
	color: ${(props) => (props.disabled ? Theme.colors.textMuted : Theme.colors.text)};
	border-radius: 4px;
	font-size: 1rem;
	resize: none;
	position: relative;
	z-index: 1;
	font-family: ${Theme.fonts["heading"]};

	&:focus-visible {
		outline: none;
	}

	&:focus {
		border-width: 2px;
		border-color: ${(props) => (props.error ? Theme.colors.error : Theme.colors.warning)};
		&:hover {
			border-color: ${(props) => (props.error ? Theme.colors.error : Theme.colors.warning)};
		}
	}

	&:hover {
		border-color: ${(props) => (props.disabled ? Theme.colors.textMuted : Theme.colors.danger)};
	}

	&::placeholder {
		opacity: ${(props) => (props.variant === "float" ? 0 : 1)};
	}

	&:focus + label,
	&:not(:placeholder-shown) + label {
		top: 0;
		font-size: 0.8rem;
		background: ${Theme.colors.backgroundSecondary};
		font-weight: normal;
		left: 10px;
	}

	&:focus + label {
		color: ${(props) => (props.error ? Theme.colors.error : props.disabled ? Theme.colors.textMuted : Theme.colors.warning)};
	}
`;

export const HelperText = styled.p`
	color: ${(props) => (props.error ? Theme.colors.error : Theme.colors.textSecondary)};
	font-size: 0.75rem;
	line-height: 1.2rem;
	margin: 0px;
`;

const AdornmentWrap = styled.div`
	font-size: 1.2rem;
	font-weight: bold;
	display: inline-flex;
`;

const InputLabelWrap = styled.div`
	width: 100%;
	padding-left: ${(props) => (props.multiline ? "0" : props.startAdornment ? ".5rem" : 0)};
	padding-right: ${(props) => (props.multiline ? "0" : props.endAdornment ? ".5rem" : 0)};
`;

const TextLabel = styled.label`
	font-size: 0.9rem;
	font-weight: bold;
	${(props) => (props.required ? astrickStyles : "")}
	${(props) =>
		props.variant === "static"
			? `
		font-size: 1rem;
		line-height: 1.7rem;
		font-weight: 400;
	`
			: ""}
`;

export function TextField({
	label,
	size,
	id,
	helperText,
	error,
	placeholder,
	value,
	onChange,
	name,
	multiline,
	rows,
	required,
	disabled,
	type,
	inputRef,
	startAdornment,
	endAdornment,
	labelSpacing,
	startAdornmentStyle,
	endAdornmentStyle,
	variant = "float",
	style = {},
	defaultValue = "",
	labelStyle = {},
	showRequired = false,
	onBlur,
	onKeyDown = () => {},
	onFocus = () => {},
	inputWrapperStyle = {},
	min = "",
}) {
	const [isFocused, setIsFocused] = useState(false);

	const handleFocus = (e) => {
		setIsFocused(true);
		if(onFocus) {
			onFocus(e);
		}
	};

	const handleBlur = (e) => {
		setIsFocused(false);
		if (onBlur) {
			onBlur(e);
		}
	};

	const extraProps = type === "date" ? { min } : {};
	return (
		<Wrapper style={style}>
			{variant === "static" && (
				<TextLabel variant={variant} required={required || showRequired} style={labelStyle}>
					{label}
				</TextLabel>
			)}
			<TextFieldWrapper size={size} startAdornment={startAdornment} endAdornment={endAdornment} labelSpacing={labelSpacing} error={error} isFocused={isFocused} disabled={disabled} multiline={multiline} style={inputWrapperStyle}>
				{startAdornment && (
					<AdornmentWrap size={size} style={startAdornmentStyle}>
						{startAdornment}
					</AdornmentWrap>
				)}
				<InputLabelWrap size={size} multiline={multiline} startAdornment={startAdornment} endAdornment={endAdornment}>
					{multiline ? (
						<InputMultilineField
							placeholder={placeholder}
							value={value}
							id={id}
							onChange={onChange}
							ref={inputRef}
							error={error}
							name={name}
							rows={rows}
							onFocus={handleFocus}
							onBlur={handleBlur}
							required={required}
							disabled={disabled}
							variant={variant}
							defaultValue={defaultValue}
						/>
					) : (
						<InputField
							placeholder={placeholder}
							value={value}
							id={id}
							onChange={onChange}
							onFocus={handleFocus}
							onBlur={handleBlur}
							ref={inputRef}
							size={size}
							error={error}
							name={name}
							required={required}
							disabled={disabled}
							type={type}
							startAdornment={startAdornment}
							endAdornment={endAdornment}
							labelSpacing={labelSpacing}
							variant={variant}
							defaultValue={defaultValue}
							onKeyDown={onKeyDown}
							{...extraProps}
						/>
					)}
					{variant === "float" && <TextLabel required={required || showRequired}>{label}</TextLabel>}
					{/* <label htmlFor="input-field">{label}{required ? <RequiredAsterisk>*</RequiredAsterisk> : null}</label> */}
				</InputLabelWrap>
				{endAdornment && (
					<AdornmentWrap size={size} style={endAdornmentStyle}>
						{endAdornment}
					</AdornmentWrap>
				)}
			</TextFieldWrapper>
			{helperText && <HelperText error={error}>{helperText}</HelperText>}
		</Wrapper>
	);
}

TextField.defaultProps = {
	error: false,
	placeholder: "",
	multiline: false,
	rows: "5",
	required: false,
	disabled: false,
	label: "",
	id: "",
	helperText: "",
	onChange: () => {},
	name: "",
	type: "text",
	inputRef: null,
	startAdornment: false,
	endAdornment: false,
	labelSpacing: "14px",
	onKeyDown: () => {},
};
