import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import UserProfile from "./UserProfile.component";
import Styles from "./UserProfile.style";
import useOrgranizationHook from "../../hooks/useOrganizationHook";
import { userStore } from "../../store/userStore";

const useStyles = makeStyles(Styles);
export default function UserProfileContainer() {
	const classes = useStyles();
	const [orgUsageData, setOrgUsageData] = useState({
		credentialDefinitionsCreated: 0,
		credentialsIssued: 0,
		credentialsRevoked: 0,
	});
	const organizations = userStore((state) => state.organizations);
  const [organizationEmail, setOrganizationEmail] = useState("");
	const { getOrganizationUsage } = useOrgranizationHook({ organizations });

	useEffect(() => {
		if (organizations) {
			getOrganizationUsage()
				.then((res) => {
          setOrgUsageData(res);
          setOrganizationEmail(res.organizationEmail ?? "");
				})
				.catch((err) => console.error(err));
		}
	}, [organizations]);

  return <UserProfile classes={classes} numCerts={orgUsageData?.credentialsIssued} numCreds={orgUsageData?.credentialDefinitionsCreated} numRevoked={orgUsageData?.credentialsRevoked} organizationEmail={organizationEmail} />;
}
