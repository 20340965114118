import Select from "react-select";
import styled from "styled-components";
import { ThemeProvider } from "theme-ui";
import Theme from "../theme/theme";
import { HelperText } from "@cti-workspace/ui";

const StyledSelect = styled(Select)`
	.Select__control {
		height: ${({ size }) => (size === "small" ? "40px" : "50px")};
		width: 100%;
		border: 1px solid rgb(192, 192, 192);
		border-radius: 4px;
		cursor: auto;
		${({ style }) => ({ ...style })}
	}

	.Select__control:hover {
		border-color: #324353;
	}

	.Select__control--is-focused {
		box-shadow: 0 0 0 1px black;
		outline: none;
	}

	.Select__indicator-separator {
		display: none;
	}

	.Select__menu {
		color: #000;
		background-color: #fff;
		z-index: 1000;
	}

	.Select__option--is-selected {
		background-color: rgba(156, 73, 237, 1);
	}

	.Select__option--is-focused {
		background-color: rgba(156, 73, 237, 0.5);
		&:active {
			background-color: rgba(156, 73, 237, 0.3);
		}
	}
`;

const astrickStyles = `
	::after {
		content: " *";
		font-weight:normal;
		font-size:.8em;
		vertical-align: top;
	}
`;

const TextLabel = styled.label`
	font-size: 0.9rem;
	font-weight: normal;
	line-height: 1.7rem;
	${(props) => (props.required ? astrickStyles : "")}
`;

export function ComboBox({ children, label = "", required = false, style = {}, size = "", className, helperText, error, ...rest }) {
	return (
		<div className={className}>
			<ThemeProvider theme={Theme}>
				{label && <TextLabel required={required}>{label}</TextLabel>}
				<StyledSelect label={label} required={required} size={size} style={style} {...rest} classNamePrefix={"Select"} />
				{helperText && <HelperText error={error}>{helperText}</HelperText>}
			</ThemeProvider>
		</div>
	);
}

/** @jsxImportSource theme-ui */
export default ComboBox;
