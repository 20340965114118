import TextField from "@material-ui/core/TextField";
import { hasProperty, getObjectValue } from "@cti-workspace/helpers";

function AdvTextField({ name, errors, inputRef, placeholder, variant, color, displayName, ...rest }) {
	return (
		<TextField
			fullWidth={true}
			id={name}
			error={hasProperty(errors, name)}
			inputRef={inputRef}
			label={displayName}
			name={name}
			placeholder={placeholder}
			variant={variant || "outlined"}
			color={color || "primary"}
			helperText={getObjectValue(errors, `${name}.message`)}
			{...rest}
		/>
	);
}

AdvTextField.propTypes = {};

export default AdvTextField;
