import { useEffect, useState } from "react";
import { ORGANIZATIONS_PREFIX, getApi } from "@cti-workspace/helpers";
import useNProgressHook from "./useNProgressHook";

export default function useAnalyticsHook() {
	const [analytics, setAnalytics] = useState({});
	const [loading, setLoading] = useState(true);

	const { progressStart, progressDone } = useNProgressHook();

	const getAnalytics = async (startDate = "", endDate = "") => {
		progressStart();
		try {
			setLoading(true);
			let query = "";
			if (startDate && endDate) {
				query = `?startDate=${startDate}&endDate=${endDate}`;
			}
			const resData = await getApi(`/${ORGANIZATIONS_PREFIX}/getAnalytics${query}`);
			setAnalytics(resData);
			progressDone();
			return resData;
		} catch (err) {
			console.error(err);
			progressDone();
			throw err;
		} finally {
			setLoading(false);
		}
	};
	return { getAnalytics, analytics, loading };
}
