import TableCell from "@material-ui/core/TableCell";
import { getObjectValue } from "@cti-workspace/helpers";

const BulkDisplayRow = ({ data, columnIndex, rowIndex, style }) => (
	<TableCell style={{ ...style, overflow: "hidden", textOverflow: "ellipsis", fontWeight: rowIndex == 0 ? "bolder" : "normal" }} key={`${rowIndex}+${columnIndex}`}>
		{getObjectValue(data, `${rowIndex}.${columnIndex}`)}
	</TableCell>
);

export { BulkDisplayRow };
