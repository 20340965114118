import { useForm, useFieldArray, Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import RemoveIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { ORGANIZATIONS_PREFIX, postApi } from "@cti-workspace/helpers";
import HeaderWithLine from "../../components/HeaderWithLine";
import { Box } from "@cti-workspace/ui";
import OrgDropDown from "../../components/OrgDropDown/OrgDropDown";

import { useSnackbar } from "notistack";

import useNProgressHook from "../../hooks/useNProgressHook";

const ConfigRenderer = () => {
	const { enqueueSnackbar } = useSnackbar();
	const { progressStart, progressDone } = useNProgressHook();

	const { register, control, handleSubmit, errors } = useForm({
		defaultValues: {
			config: [{ key: "bgImage", value: "" }],
		},
	});
	const { fields, append, remove } = useFieldArray({
		control,
		name: "config",
	});

	const onSubmit = async (params) => {
		if (params.orgId) {
			try {
				progressStart();
				let data = await postApi(`/${ORGANIZATIONS_PREFIX}/renderers`, params);
				enqueueSnackbar(`Added: ${JSON.stringify(params)}`, {
					variant: "success",
				});
				progressDone();
			} catch (error) {
				progressDone();
				enqueueSnackbar(`${JSON.stringify(error)}`, {
					variant: "error",
				});
				console.log(error);
			}
		}
	};

	return (
		<form
			onSubmit={handleSubmit(async ({ config, ...rest }) => {
				let val = config.reduce((item, { value, key }) => {
					item = {
						...item,
						[key]: value,
					};
					return item;
				}, {});
				await onSubmit({ ...rest, config: { ...val } });
			})}>
			<Grid container spacing={1} direction="column" justifyContent="center" alignItems="center" alignContent="center" wrap="nowrap">
				<Grid item container spacing={1} direction="row" justifyContent="center" alignItems="center" alignContent="center">
					<Grid item xs={12}>
						<Box mx="auto" p={-2}>
							<HeaderWithLine title={"Renderer Configuration"} variant={"h6"} />
						</Box>
						<Typography variant="h6" color="initial">
							Info
						</Typography>
					</Grid>
					<Grid item container spacing={1} direction="row" justifyContent="flex-start" alignItems="center" alignContent="center">
						<Grid item xs={5}>
							<TextField inputRef={register()} variant="outlined" name="id" fullWidth label="renderer" defaultValue="TRYBE_DEFAULT" />
						</Grid>
						<Grid item xs={5}>
							<Controller name="orgId" control={control} rules={{ required: true }} render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => <OrgDropDown ref={ref} onChange={onChange} required />} />
						</Grid>
					</Grid>
					<Grid item xs={3}></Grid>
				</Grid>
				<Grid item container spacing={1} direction="row" justifyContent="center" alignItems="center" alignContent="center">
					<Grid item xs={9}>
						<Typography variant="h6" color="initial">
							Config
						</Typography>
					</Grid>
					<Grid item xs={3}>
						<Button variant="text" color="default" size="small" onClick={() => append({})}>
							<AddIcon /> Add
						</Button>
					</Grid>
				</Grid>
				<Grid item container spacing={1}>
					{fields.map((item, index) => (
						<Grid item container key={item.id} spacing={1}>
							<Grid item xs={5}>
								<TextField inputRef={register()} variant="outlined" name={`config[${index}].key`} defaultValue={item.key} fullWidth label="key" />
							</Grid>
							<Grid item xs={5}>
								<TextField inputRef={register()} variant="outlined" name={`config[${index}].value`} defaultValue={item.value} fullWidth label="value" />
							</Grid>
							<Grid container item xs={1} justifyContent="center">
								<Button variant="text" color="default" size="small" onClick={() => remove(index)}>
									<RemoveIcon />
								</Button>
							</Grid>
						</Grid>
					))}
				</Grid>
				<Grid item container spacing={1} direction="row" justifyContent="flex-start" alignItems="center" alignContent="center">
					<Grid item xs={10}>
						<Button type="submit" variant="contained" color="primary" fullWidth>
							Submit
						</Button>
					</Grid>
				</Grid>
				<Typography align="left">// bgImage: url // gradient: "linear-gradient(to right bottom, #430089, #82ffa1)" // text1: json style object // text2 : json style object</Typography>
			</Grid>
		</form>
	);
};

ConfigRenderer.propTypes = {};

export default ConfigRenderer;
