import parseExcelSheetToJson from "./parseExcelSheetToJson";

function ParseExcelSheetToArray(excelSheet) {
	return new Promise((resolve, reject) => {
		parseExcelSheetToJson(excelSheet, true).then((rows) => {
			resolve(rows);
		});
	});
}

export default ParseExcelSheetToArray;
