import { CheckBox, Typography } from "@cti-workspace/ui";

const MobileWalletOptions = ({ enableMobileWallet, setEnableMobileWallet }) => {
  return (
    <div>
      <Typography type="body1">
        Mobile Wallet Options
      </Typography>
      <div style={{ marginTop: "0.3rem" }}>
        <CheckBox label="Enable Mobile Wallet" name="enableMobileWallet" labelMargin="medium" checked={enableMobileWallet} onChange={(e) => {
          setEnableMobileWallet(
            e.target.checked,
          );
        }} labelStyle={{ fontSize: "0.8rem" }} />
      </div>
    </div>
  );
};

export default MobileWalletOptions;
