import { useEffect, useState, useCallback } from "react";
import Modal from "styled-react-modal";
import { ORGANIZATIONS_PREFIX, IMAGE_CONTENT, getApi } from "@cti-workspace/helpers";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import HeaderWithLIne from "../HeaderWithLine";
import { Button } from "@cti-workspace/ui";
import SelectCustomCredential from "../VisualDefineCredential/SelectCustomCredential";
import SharingOptions from "../VisualDefineCredential/SharingOptions";
import { useSnackbar } from "notistack";
import MobileWalletOptions from "../VisualDefineCredential/MobileWalletOptions";
import PDFSettings from "../VisualDefineCredential/PDFSettings";
import SelectEmailTemplate from "../VisualDefineCredential/SelectEmailTemplate";
import { useTheme, TextField, Grid, Paper, Container, Box, Switch } from "@material-ui/core";
import { useImmer } from "use-immer";

const StyledModal = Modal.styled`
  width: auto;
  height: auto;
  display: flex;
  overflow: auto;
  padding-block: 20px;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

function CredentialContentMapping({ credDefContentName, credDefContentDefaultValue, credSubjectKey, credSubjectType, required, rowID, handleChange }) {
	// console.log(credSubjectKey);
	// console.log(credSubjectType);
	return (
		<>
			<Grid item xs={12} sm={4}>
				<TextField fullWidth={true} placeholder={"Content Name"} variant={"outlined"} color={"primary"} value={credDefContentName} />
			</Grid>
			<Grid item xs={12} sm={1} />
			<Grid item xs={12} sm={3}>
				{credSubjectType != "image" ? (
					<TextField fullWidth={true} placeholder={"Content default value"} variant={"outlined"} color={"primary"} defaultValue={credDefContentDefaultValue} onChange={({ target }) => handleChange(rowID, target.value)} />
				) : (
					<>
						<TextField type="hidden" disabled={true} defaultValue={IMAGE_CONTENT} onChange={handleChange(rowID, IMAGE_CONTENT)} /> <div>image</div>{" "}
					</>
				)}
			</Grid>
			<Grid item xs={12} sm={1} container alignItems="center" justifyContent="center" />
			<Grid item xs={12} sm={2} container alignItems="center" justifyContent="center">
				<Switch checked={required} />
			</Grid>
			<Grid item xs={12} sm={1} container alignItems="center" justifyContent="center" />
		</>
	);
}

function CredentialDefineModal({ setValue, toggleModal, isOpen, data }) {
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();
	const [name, setName] = useState("");
	const [check, setCheck] = useState(false);

	const [customCredData, setCustomCredData] = useState({});
	const [setting, setSetting] = useState({});
	const [sharingOptions, setSharingOptions] = useState({});
	const [enableMobileWallet, setEnableMobileWallet] = useState(false);
	const [encryptingContent, setEncryptingContent] = useState([]);
	const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(null);
	const [pdfSettings, setPdfSettings] = useState({
		generatePdf: null,
		pdfTemplate: null,
		encryptContent: null,
	});

	const { selectedOrgId } = data;

	// console.log(selectedOrgId);

	const [credentialMappings, setCredentialMappings] = useImmer([]);

	const handleChange = useCallback((id, value) => {
		setCredentialMappings((draft) => {
			const row = draft.find((_, index) => index === id);
			row.credDefContentDefaultValue = value;
		});
	}, []);

	useEffect(() => {
		let isSubscribed = true;

		const fetchData = async () => {
			const temp = await getApi(`/${ORGANIZATIONS_PREFIX}/${selectedOrgId}`);

			// console.log(temp);
			const { settings } = temp;

			if (isSubscribed) {
				// console.log(settings);
				setSetting(settings);
				setPdfSettings(settings?.pdfOptions);
				setSharingOptions(settings?.sharingOptions);
			}
		};

		const customCredList = data.customCredentials;

		const selectedCustomCred = customCredList.filter((val) => val["_id"] == data["Custom Credential"]);

		if (selectedCustomCred[0]) {
			setCustomCredData(selectedCustomCred[0]);
			setCredentialMappings(selectedCustomCred[0]?.credentialMappings);
		}

		fetchData().catch(console.error);

		return () => (isSubscribed = false);
	}, [selectedOrgId]);

	// console.log(credentialMappings);

	const onSave = ({ credentialName: name, readOnly: check, sharingOptions, settings, enableMobileWallet, selectedEmailTemplate }) => {
		const transcripts = credentialMappings ? credentialMappings.map((item) => ({ key: item.credDefContentName, value: item.credDefContentDefaultValue || "", required: item.required || false })) : [];
		const { enableCustomCredentials, enableSharingOptions, enablePdfOptions, enableMobileWalletOptions } = settings?.credentialDefinitions || {};
		let newSettings = undefined;
		if (enableCustomCredentials) {
			newSettings = {};
			if (enableSharingOptions) {
				newSettings.sharingOptions = {
					disablePublicURL: !sharingOptions?.publicUrl,
					disableAddToLinkedIn: !sharingOptions?.addToLinkedIn,
					disablePostToLinkedIn: !sharingOptions?.shareToLinkedIn,
					disablePostToTwitter: !sharingOptions?.addToTwitter,
					disableAddToWallet: !sharingOptions?.addToWallet,
				};
			}
			if (enablePdfOptions) {
				newSettings.pdfOptions = {
					generatePDF: pdfSettings?.generatePdf === "Yes",
					encryptPDF: !!pdfSettings?.encryptContent,
					encryptOptions: {
						encryptKey: pdfSettings?.encryptContent,
					},
					pdfTemplate: pdfSettings?.pdfTemplate,
				};
			}
			if (enableMobileWalletOptions) {
				newSettings.walletOptions = {
					enableMobileWallet: enableMobileWallet,
				};
			}
		}

		enqueueSnackbar(`Credential data is saved successfully.`, { variant: "success" });
		setValue("credDef", JSON.stringify({ credentialName: name, readOnly: check, transcripts, selectedEmailTemplate, settings: newSettings }));

		toggleModal(false);
		setCheck(false);
		setName("");
		setCustomCredData({});
		setSetting({});
		setSharingOptions({});
		setEnableMobileWallet({});
		setEncryptingContent([]);
		setPdfSettings({
			generatePdf: null,
			pdfTemplate: null,
			encryptContent: null,
		});
		setSelectedEmailTemplate(null);
	};

	return customCredData.name ? (
		<StyledModal isOpen={isOpen} onBackgroundClick={() => toggleModal(!isOpen)} onEscapeKeydown={() => toggleModal(!isOpen)}>
			<Container maxWidth={"md"}>
				<Box borderColor="primary" border={8} style={{ maxHeight: "80vh", overflow: "auto" }}>
					<Paper
						style={{
							display: "flex",
							padding: theme.spacing(3),
							width: "100%",
						}}>
						<Grid container spacing={2} justifyContent="flex-start" alignContent="flex-start">
							<Grid item xs={12}>
								<HeaderWithLIne title="Create a Credential Definition" variant="h6" />
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth={true}
									onChange={({ target }) => setName(target.value)}
									value={name}
									id={"credentialName"}
									required
									label={"Credential Name"}
									name={"credentialName"}
									placeholder={"Credential Name"}
									variant={"outlined"}
									color={"primary"}
								/>
							</Grid>

							<Grid item xs={12}>
								<HeaderWithLIne title="Credential Content" variant="h6" />
							</Grid>

							<Grid item xs={12}>
								<Container maxWidth="md">
									{credentialMappings ? (
										<Grid container spacing={2}>
											<Grid item xs={12} sm={4}>
												Content Name
											</Grid>
											<Grid item xs={12} sm={1}></Grid>
											<Grid item xs={12} sm={4}>
												Content default Value
											</Grid>
											<Grid item xs={12} sm={2} container justifyContent="center">
												Required
											</Grid>
											<Grid item xs={12} sm={1}></Grid>
											{credentialMappings.map((item, i) => (
												<CredentialContentMapping key={i} rowID={i} handleChange={handleChange} {...item} />
											))}
										</Grid>
									) : null}
								</Container>
							</Grid>

							<Grid item xs={12}>
								{setting?.sharingOptions && <SharingOptions sharingOptions={sharingOptions} setSharingOptions={setSharingOptions} />}
								{setting?.walletOptions && <MobileWalletOptions enableMobileWallet={enableMobileWallet} setEnableMobileWallet={setEnableMobileWallet} />}
								{setting?.pdfOptions && <PDFSettings encryptingContent={encryptingContent} pdfSettings={pdfSettings} setPdfSettings={setPdfSettings} isEditing={false} organizationId={data.selectedOrgId} />}
							</Grid>
							<SelectEmailTemplate selectedEmailTemplate={selectedEmailTemplate} setSelectedEmailTemplate={setSelectedEmailTemplate} organizationId={selectedOrgId} isEditing={false} />
							<Grid item xs={12}>
								<SelectCustomCredential selectedCustomCredential={customCredData["_id"]} setSelectedCustomCredential={() => {}} organizationId={""} readOnly={true} data={[customCredData]} />
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel control={<Checkbox checked={check} color="secondary" onChange={() => setCheck(!check)} />} label="Read Only" labelPlacement="start" />
							</Grid>

							<Grid item xs={12} style={{ marginTop: theme.spacing(1) }}>
								<Button
									onClick={() => {
										onSave({ credentialName: name, readOnly: check, settings: setting, sharingOptions, selectedEmailTemplate, enableMobileWallet, pdfSettings });
									}}
									disabled={name == "" ? true : false}
									color="warning">
									Save
								</Button>
							</Grid>
						</Grid>
					</Paper>
				</Box>
			</Container>
		</StyledModal>
	) : (
		<div></div>
	);
}

export default CredentialDefineModal;
