import { useState, useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";
import styled from "styled-components";
import QRDialog from "../QRDialog";
import AlertDialog from "../AlertDialog";
import { ORGANIZATIONS_PREFIX, PUBLIC_PREFIX, getApi, delApi, ShareCredentialOverEmail, getObjectValue } from "@cti-workspace/helpers";
import ob from "../../assets/images/ob.jpeg";
import TransferCredential from "./TransferCredential";
import { NewCard, WalletCardBody, NewCardFooter } from "@cti-workspace/ui";
import { useQueryClient } from "@tanstack/react-query";
import { formatInTimeZone } from "date-fns-tz";
import { useSnackbar } from "notistack";

const WalletContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(3, auto);
	margin-top: 1rem;
	grid-gap: 0.5rem;
	@media (max-width: 1024px) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(9, auto);
	}
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
		grid-template-rows: repeat(10, auto);
	}
`;

const RevokedIndicator = styled.div`
	text-transform: uppercase;
	font-size: 0.73rem;
	font-weight: 600;
	display: flex;
	align-items: center;
	gap: 0.2rem;
	border-radius: 0.2rem;
	padding: 0.2rem;
`;

function WalletCard({ primaryColor, item, setPublicUrl, setShowQrCode, ShareCredentialOverEmail, setEmail, email, deleteCredential, setSharedCredentialIds, cardName, cardNameVal, cardNameRef }) {
  let { issuerOrganizationId, rawCertificate, shortURLs } = item;
  let rendererId = "TRYBE_DEFAULT";
  const [config, setconfig] = useState({});
  const [showDeleteCredential, setShowDeleteCredential] = useState(false);
  const [selectedCredId, setSelectedCredId] = useState(undefined);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showShareCredential, setShowShareCredential] = useState(false);
  const [copyUrl, setCopyUrl] = useState("Copy Url");
  const { enqueueSnackbar } = useSnackbar();
  // console.log(item);

  useEffect(() => {
    if (issuerOrganizationId && item.state !== "imported") {
      (async function iife() {
        try {
          // check if the rendererConfigId exists in the additionalData of rawDocument
          const {
            data: {
              additionalData: { rendererConfigId },
            },
          } = JSON.parse(rawCertificate);
          const configId = rendererConfigId.split(":string:").pop();
          if (configId) {
            // remove the :string: from the rendererConfigId string
            let newConfigData = await getApi(`/${PUBLIC_PREFIX}/rendererConfig/${configId}`, { cachePolicy: "no-cache" }, false);
            setconfig({ ...newConfigData, type: "custom" });
          } else {
            let newData = await getApi(`/${ORGANIZATIONS_PREFIX}/renderers/getConfig/?orgId=${issuerOrganizationId}&rendererId=${rendererId}`, { cachePolicy: "no-cache" }, false);
            setconfig(newData);
          }
        } catch (err) {
          try {
            let newData = await getApi(`/${ORGANIZATIONS_PREFIX}/renderers/getConfig/?orgId=${issuerOrganizationId}&rendererId=${rendererId}`, { cachePolicy: "no-cache" }, false);
            setconfig(newData);
          } catch (err1) {
            console.error(err1);
          }
        }
      })();
    }
  }, []);

  const isRevoked = item.state === "revoked";
  const certificate = JSON.parse(item.rawCertificate);
  const dateIssuedOn = item.issuedOn ? item.issuedOn : new Date(certificate.data.issuedOn.split(":string:")[1].split(".")[0]);
  const localeTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const issuedOnDate = formatInTimeZone(new Date(dateIssuedOn), localeTimeZone, "PP hh:mm a");

  let isExpired = false;
  if (certificate.vc && certificate.vc.expirationDate) {
    var expiredOn = certificate.vc.expirationDate.match(/(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g);
    if (expiredOn) {
      let givenDate = new Date(expiredOn[0]);
      let diff = new Date().getTime() - givenDate.getTime();

      if (diff > 0) {
        isExpired = true;
      }
    }
  }

  let CardTitle = "";
  // const test = getObjectValue(certificate, cardName, "") === cardNameRef ? getObjectValue(certificate, cardNameVal, item.credentialName) : item.credentialName;
  const rawTranscriptObject = getObjectValue(certificate, cardName, "");
  // console.log(certificate);
  if (typeof rawTranscriptObject === "object") {
    const temp = rawTranscriptObject.find(function (arrayItem) {
      let x = arrayItem.name.split(":string:")[1];
      return x === cardNameRef;
    });
    CardTitle = getObjectValue(temp, "grade", item.credentialName);
    let splitTitle = CardTitle.split(":string:");
    if (splitTitle.length == 2) {
      CardTitle = splitTitle[1];
    }
  } else {
    CardTitle = item.credentialName;
  }

  // Expired certs are yellow and revoked are red
  let haContent = isExpired ? "expired" : "";
  let bgColor = isExpired ? "#fdfdd0" : "white";
  if (isRevoked) {
    haContent = "revoked";
    bgColor = "#ff000014";
  }

	if (CardTitle != "") {
		return (
			<>
        <NewCard
          title={CardTitle.length < 60 ? CardTitle : item.credentialName}
          backgroundColor={bgColor}
          headerActionContent={haContent}
					imageUrl={item.state === "imported" && item.type === "openbadges2.0" ? item?.Assertion?.image || item?.BadgeClass?.image?.id || item?.imageUrl : item?.imageUrl || ""}
					secondaryTitle={`Issued on ${issuedOnDate || ""}`}
					bodyContent={
						<WalletCardBody
							primaryColor={primaryColor}
							imageUrl={item.issuerImageUrl}
							title={item.issuerOrganization}
							onQr={() => {
								setPublicUrl(shortURLs?.publicUrl || item?.publicUrl);
								setShowQrCode(!!shortURLs?.publicUrl || !!item?.publicUrl);
							}}
							onEmail={() => setShowSendEmail(true)}
							onTransfer={() => setShowShareCredential(true)}
              showTransfer={!isRevoked && !isExpired && !primaryColor}
              showLinkedIn={!isRevoked && !isExpired && (!!shortURLs?.addToLinkedinUrl || !!item?.addToLinkedinUrl)}
							onLinkedIn={() => window.open(shortURLs?.addToLinkedinUrl || item?.addToLinkedinUrl, "_blank").focus()}
							showCopy={!!shortURLs?.publicUrl || !!item?.publicUrl}
							onCopy={() => {
								navigator.clipboard.writeText(shortURLs?.publicUrl || item?.publicUrl);
								setCopyUrl("Url Copied");
                enqueueSnackbar(`URL copied to clipboard.`, { variant: "success" });
								setTimeout(() => {
									setCopyUrl("Copy Url");
								}, 3000);
							}}
							onDelete={() => {
								setSelectedCredId(item.id);
								setShowDeleteCredential(true);
							}}
						/>
					}
					footerContent={
						<NewCardFooter
							primaryColor={primaryColor}
							leftButtonTitle="download"
							onLeftButtonClick={() => {
								const a = document.createElement("a");
								a.href = URL.createObjectURL(new Blob([item.rawCertificate], { type: "json" }));
								a.download = `${item.recipient}-${item.credentialName}.json`;
								a.click();
							}}
							centerButtonTitle="view"
							showCenter={!!shortURLs?.publicUrl || !!item?.publicUrl}
							onCenterButtonClick={() => window.open(`${shortURLs?.publicUrl || item?.publicUrl}`)}
							showLeft={false}
						/>
					}
				/>
				<AlertDialog
					title="Share Credential"
					open={showSendEmail}
					cleanup={() => setShowSendEmail(false)}
					continueToAdd={async() => {
            if (email !== "") {
              try {
                await ShareCredentialOverEmail(email, shortURLs?.publicUrl || item?.publicUrl);

                enqueueSnackbar(`Credential shared.`, { variant: "success" });
              } catch (error) {
                enqueueSnackbar("Failed to share credential", {
                  variant: "error",
                });
              }
              setEmail("");
              setShowSendEmail(false);
           } else {
              enqueueSnackbar("Please enter a valid email address", {
                variant: "error",
              });
            }
					}}
					body={
						<Grid container>
							<Grid item xs={12}>
								Please enter a valid email address.
							</Grid>
							<Grid item xs={12}>
								<TextField fullWidth={true} onChange={(event) => setEmail(event.target.value)} value={email} required />
							</Grid>
						</Grid>
					}
					primaryColor={primaryColor}
				/>
				<AlertDialog
					title="Delete Credential?"
					open={showDeleteCredential}
					cleanup={() => setShowDeleteCredential(false)}
					continueToAdd={() => {
						deleteCredential(selectedCredId);
						setShowDeleteCredential(false);
					}}
					body={
						<Grid container>
							<Grid item xs={12}>
								This cannot be undone.
							</Grid>
						</Grid>
					}
					primaryColor={primaryColor}
				/>
				<TransferCredential primaryColor={primaryColor} setShowShareCredential={setShowShareCredential} showShareCredential={showShareCredential} credentialId={item.id} setSharedCredentialIds={setSharedCredentialIds} publicUrl={item.publicUrl} />
			</>
		);
	}
}

export default function WalletGrid({ primaryColor, classes, recipientData, baseUrl, email: userEmail, setSharedCredentialIds, cardName, cardNameVal, cardNameRef }) {
	const [showQrCode, setShowQrCode] = useState(false);
	const [publicUrl, setPublicUrl] = useState("");
	const [email, setEmail] = useState("");

	const queryClient = useQueryClient();

	const deleteCredential = async (certId) => {
		await delApi(`/wallets/removeCredential`, { certId });
		queryClient.invalidateQueries({ queryKey: [baseUrl, userEmail] });

		// TODO: revalidate the
		// setRecipientData(recipientData.filter((data) => data.id !== certId));
	};
	return (
		<>
			<WalletContainer>
				{recipientData &&
					recipientData.map((item, index) => (
						<WalletCard
							key={item._id}
							setPublicUrl={setPublicUrl}
							setShowQrCode={setShowQrCode}
							ShareCredentialOverEmail={ShareCredentialOverEmail}
							email={email}
							setEmail={setEmail}
							item={item}
							index={index}
							classes={classes}
							deleteCredential={deleteCredential}
							setSharedCredentialIds={setSharedCredentialIds}
							primaryColor={primaryColor}
							cardName={cardName}
							cardNameVal={cardNameVal}
							cardNameRef={cardNameRef}
						/>
					))}
			</WalletContainer>
			<QRDialog qrCodevalue={publicUrl} open={showQrCode} handleClose={() => setShowQrCode(false)} primaryColor={primaryColor} />
		</>
	);
}
