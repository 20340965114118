import { Grid, Typography, TextField, FormHelperText } from "@material-ui/core";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { FormattedMessage } from "react-intl";
import { IMAGE_CONTENT, getObjectValue } from "@cti-workspace/helpers";
import { Button, Switch, Box } from "@cti-workspace/ui";
import useSettingsHook from "../../hooks/useSettingsHook";

const ArrayField = ({
	control,
	register,
	name,
	errors,
	fieldStructure,
	displayName,
	help,
	helpButton,
	removeField,
	required = true,
	validationErrorMessage = "",
	disableEntries = 0,
	requiredValue = true,
	valueHelpText = "",
	showRequired = false,
	setEncryptingContent,
	enableImageContentRow = false,
}) => {
	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});
	let { [name]: e } = errors;
	const values = useWatch({ control });
	const { getPdfSettings } = useSettingsHook();

	const orgEncryptingKey = getPdfSettings()?.encryptOptions?.encryptKey;

	const updateEncryptingContent = (index = null) => {
		if (setEncryptingContent) {
			const encryptingContent = values?.transcripts ? [...values.transcripts] : [];
			if (index !== null) {
				encryptingContent.splice(index, 1);
			}
			if (orgEncryptingKey && encryptingContent[0]?.key !== orgEncryptingKey) {
				encryptingContent.unshift({ key: orgEncryptingKey });
			}
			setEncryptingContent(encryptingContent.filter((field) => field?.value !== IMAGE_CONTENT && field?.key !== "" && field?.key !== null).map((field) => ({ key: field.key })));
		}
	};

	return (
		<>
			<Grid item container spacing={1} direction="row" justifyContent="center" alignItems="center" alignContent="center">
				<Grid item xs>
					<Typography variant="h6" color="initial">
						{displayName}
						<FormHelperText>{help}</FormHelperText>
					</Typography>
				</Grid>
				<Grid container xs={3} justifyContent="flex-end">
					<Button data-testid="addCredContent" popover={`Add a new row of content`} leftIcon="plus" color="white" disabled={e?.length > 0} onClick={() => append(fieldStructure)}>
						Add
					</Button>
					{enableImageContentRow && (
						<Button popover={`Add a new row of image`} leftIcon="image" color="white" leftSpacing disabled={e?.length > 0 || fields.find((item) => item?.value === IMAGE_CONTENT)} onClick={() => append({ ...fieldStructure, value: IMAGE_CONTENT })}>
							Image
						</Button>
					)}
				</Grid>
				{/* <Grid item xs={1}> */}
				{helpButton && (
					<PopupState variant="popover" popupId="demo-popup-popover">
						{(popupState) => (
							<>
								<IconButton {...bindTrigger(popupState)}>
									<Tooltip title="Help" placement="left" arrow>
										<HelpOutlineIcon />
									</Tooltip>
								</IconButton>
								<Popover
									{...bindPopover(popupState)}
									anchorOrigin={{
										vertical: "center",
										horizontal: "left",
									}}
									transformOrigin={{
										vertical: "center",
										horizontal: "right",
									}}>
									<Box p={2}>
										<FormattedMessage
											id={`${help}.headerWithLine`}
											values={{
												p: (...chunks) => (
													<Typography component={"span"} display={"block"}>
														{chunks}
													</Typography>
												),
												cta: (...chunks) => (
													<Typography
														style={{
															fontWeight: 600,
														}}
														component={"span"}
														display={"inline"}
														color={"secondary"}>
														{chunks}
													</Typography>
												),
											}}>
											{(txt) => (
												<Typography component={"span"} variant={"body1"}>
													{txt}
												</Typography>
											)}
										</FormattedMessage>
									</Box>
								</Popover>
							</>
						)}
					</PopupState>
				)}
			</Grid>
			<Grid item container spacing={2}>
				{fields.map((item, index) => (
					<Grid item container key={item.id} spacing={1}>
						<Grid item xs>
							<TextField
								inputRef={register(
									required
										? {
												required: "Name is required",
												validate: (value) => {
													if (validationErrorMessage) {
														let errorVal = "";
														getObjectValue(values, name, []).forEach((field, i) => {
															if (field.key === value && index !== i) {
																errorVal = validationErrorMessage;
															}
														});
														if (errorVal) {
															return validationErrorMessage;
														}
													}
												},
										  }
										: {}
								)}
								variant="outlined"
								name={`${name}[${index}].key`}
								id={`${name}${index}`}
								data-testid={`${name}${index}_key`}
								error={e?.length && getObjectValue(e, `${index}.key`) ? true : false}
								defaultValue={item.key}
								fullWidth
								label="Name"
								helperText={e?.length && getObjectValue(e, index) ? getObjectValue(e, `${index}.key.message`, "") : ""}
								onBlur={updateEncryptingContent}
							/>
							{/* <FormHelperText>This is what will be displayed in your certificate. Name it carefully</FormHelperText> */}
							<FormHelperText>{item.value === IMAGE_CONTENT ? "This is a File Input. This is not displayed in your certificate" : "This will be displayed on your certificate. Name it carefully"}</FormHelperText>
						</Grid>

						{item.hasOwnProperty("value") && (
							<Grid item xs style={{ visibility: item.value === IMAGE_CONTENT ? "hidden" : "visible" }}>
								<TextField
									inputRef={register(requiredValue && required ? { required: "value is required" } : {})}
									variant="outlined"
									error={requiredValue && e?.length === index + 1}
									name={`${name}[${index}].value`}
									id={`${name}${index}`}
									data-testid={`${name}${index}_value`}
									defaultValue={item.value}
									fullWidth
									label="Value"
									helperText={valueHelpText ? valueHelpText : e?.length === index + 1 ? getObjectValue(e, `${index}.value.message`, "") : ""}
								/>
							</Grid>
						)}
						{showRequired && (
							<Grid container item xs={2} alignItems="flex-start" justifyContent="center">
								<Controller
									control={control}
									name={`${name}[${index}].required`}
									defaultValue={item.required == undefined ? true : item.required}
									render={(propsField) => {
										const { value, onChange, name } = propsField;
										return <Switch name={name} color="secondary" size="small" checked={value} onChange={onChange} label={"Required"} labelPlacement="top" />;
									}}
								/>
								{/* <Controller
									control={control}
									name={`${name}[${index}].required`}
									defaultValue={item.required || false}
									render={({ value, onChange }) => (
										<FormControlLabel
											value={item.required || false}
											control={<Switch color="secondary" size="small" checked={value} onChange={(event) => onChange({ ...event, target: { ...event.target, value: !value } })} />}
											label="Required"
											labelPlacement="top"
										/>
									)}
									/> */}
							</Grid>
							// 	<Controller
							// 	control={control}
							// 	name={`${name}[${index}].required`}
							// 	defaultValue={item.required || false}
							// 	render={({ value, onChange }) => (
							// 		<FormControlLabel
							// 			value={item.required || false}
							// 			control={<Switch color="secondary" size="small" checked={value} onChange={(event) => onChange({ ...event, target: { ...event.target, value: !value } })} />}
							// 			label="Required"
							// 			labelPlacement="top"
							// 		/>
							// 	)}
							// />
						)}
						<Grid container item xs={1} justifyContent="center" alignItems="center">
							{disableEntries === index + 1 ? null : (
								<Button
									buttonType="icon"
									color="danger"
									icon="cancel"
									invert
									iconSize="1.3rem"
									onClick={() => {
										removeField(name, index, remove);
										updateEncryptingContent(index);
									}}
									disabled={orgEncryptingKey === item.key}
									style={{ marginBottom: "20px" }}
								/>
							)}
						</Grid>
					</Grid>
				))}
			</Grid>
		</>
	);
};

ArrayField.propTypes = {};

export default ArrayField;
