import { useContext, useState } from "react";
import { fabric } from "fabric-pure-browser";
import { FabricContext } from "../../context/FabricContext";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import { Image } from "@phosphor-icons/react";
import { getObjectValue } from "@cti-workspace/helpers";

const supportedImageTypes = ["image/png", "image/apng", "image/bmp", "image/gif", "image/x-icon", "image/jpeg"];

function FabricAddImage({ id, enableMultiPageCertificate }) {
	const { canvas, activeObject } = useContext(FabricContext);

	const addImageBox = (e) => {
		document.getElementById(`fabric-image-upload`).click();
	};

	const onImageUpload = (e) => {
		const file = e.target.files[0];
		let fileType = e.target.files[0].type;
		const reader = new FileReader();

		reader.addEventListener(
			"load",
			function () {
				if (supportedImageTypes.indexOf(fileType) !== -1) {
					fabric.Image.fromURL(reader.result, function (img) {
						const activeCanvas = enableMultiPageCertificate ? getObjectValue(canvas, id) : canvas;
						img.scaleToWidth(100);
						activeCanvas.add(img);
					});
				} else if (fileType === "image/svg+xml") {
					fabric.loadSVGFromURL(reader.result, function (objects, options) {
						const activeCanvas = enableMultiPageCertificate ? getObjectValue(canvas, id) : canvas;
						let svg = fabric.util.groupSVGElements(objects, options);
						svg.scaleToWidth(100);
						activeCanvas.add(svg);
					});
				}
			},
			false
		);

		if (file) {
			reader.readAsDataURL(file);
			document.getElementById(`fabric-image-upload`).value = null;
		}
	};

	return (
		<>
			<Image onClick={addImageBox} size={17} />
			<input type="file" id={`fabric-image-upload`} accept="image/*" onChange={onImageUpload} style={{ display: "none" }} />
		</>
	);
}

export default FabricAddImage;
