import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import HeaderWithLine from "../HeaderWithLine";
import { findObject, isEmptyObject } from "@cti-workspace/helpers";

const ListOrgDbConnections = ({ orgs, existingDbConnections, selectedOrdId }) => {
	if (!selectedOrdId) {
		return null;
	}

	const selectedOrg = findObject(orgs, selectedOrdId);

	if (isEmptyObject(selectedOrg)) {
		return `Selected org with org id : ${selectedOrdId} not found!`;
	}

	const selectedOrgDbConnectionIds = selectedOrg && selectedOrg.dbConnections;
	if (isEmptyObject(selectedOrgDbConnectionIds)) {
		return `Selected org does not have any db connections attached!`;
	}

	const selectedOrgDbConnections = existingDbConnections.filter((con) => selectedOrgDbConnectionIds.includes(con._id));

	if (isEmptyObject(selectedOrgDbConnections)) {
		return `Attached DB connections not found for the organization in all db connections.`;
	}

	return (
		<>
			<HeaderWithLine title={"Existing Organization Database Connections"} variant={"h6"} />
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell component="th" style={{ fontWeight: "bold" }} width={250}>
							Connection Name
						</TableCell>
						<TableCell component="th" style={{ fontWeight: "bold" }}>
							URL
						</TableCell>
						<TableCell component="th" style={{ fontWeight: "bold" }}>
							Status
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{selectedOrgDbConnections.map((orgDbConnection) => (
						<TableRow key={orgDbConnection._id}>
							<TableCell>{orgDbConnection.connectionName}</TableCell>
							<TableCell>{orgDbConnection.connectionURL}</TableCell>
							<TableCell>{orgDbConnection.active ? "ACTIVE" : "INACTIVE"}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</>
	);
};

export default ListOrgDbConnections;
