import { useEffect } from "react";
import { createBrowserRouter, createRoutesFromElements, Route, Link, useLocation, redirect, useNavigate, Outlet, Navigate } from "react-router-dom";
import { LoginRoute } from "./login";
import { SetPasswordRoute } from "./setPassword";
import CreateOrganization from "./createOrganization/createOrganization";
import { ErrorRoute } from "./error";
import { DeniedAccess } from "./deniedAccess";
import { NewCredDesign } from "./newCredDesign";
import RouteWrapper from "./RouteWrapper";
import useSettingsHook from "../hooks/useSettingsHook";
import { GlobalEdRedirect, MenaRedirect}  from "./globalEdRedirect/globalEdRedirect";
//import from "./globalEdRedirect/globalEdRedirect";
import NFTView from "./nft/NFTView";
import Newpathway from "./newPathway";
import CreateHolderAccount from "./createAccount";
import { userStore } from "../store/userStore";
import { JWT_TOKEN } from "@cti-workspace/helpers";
import { AdminRoute } from "./admin";
import { HomeRoute } from "./home";
import RestrictedRoute from "./RestrictedRoute";
import OrganizationAdmin from "./orgAdmin";
import { WalletRoute } from "./wallet";
import { ProfileRoute } from "./profile";
import Analytics from "./analytics";
import VisualDefineCredential from "../components/VisualDefineCredential";
import ViewCredential from "../components/ViewCredential";
import { NewCredConfig } from "./newCredConfig";
import CredentialIssue from "../components/CredentialIssue";
import PresentationCardVerifier from "./presentationCatalog/presentationCardVerifier";
import VerifyCredentials from "./verifyCredentials";
import IssuedCredentials from "./issuedCredentials/issuedCredentials";
import Pathways from "./pathways";
import DefinePathway from "./definePathway/definePathway";
import MainPathway from "./pathway";
import MintNft from "./nft/MintNft";
import NFTCatalog from "./nft";
import DefinePresentation from "./presentationCatalog/definePresentation";
import PresentationCatalog from "./presentationCatalog";
import WalletPathway from "./walletPathway";
import Authorizations from "./authorizations";
import AuthorizeDefinitionForm from "./authorizations/authorizationDefinitionForm";

const CatchRoute = () => {
	// let location = useLocation();
	//
	//TODO: denied access routing
	// return <DeniedAccess />;
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (location.pathname === "/") {
			navigate("/home", { replace: true });
		}
	}, [location, navigate]);

	return <ErrorRoute />;
};

const publicRoute = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" errorElement={<CatchRoute />}>
			<Route path="/" element={<RestrictedRoute />}>
				<Route index element={<Navigate replace to="home" />} />
				<Route path="admin" element={<AdminRoute />} />
				<Route path="orgAdmin" element={<OrganizationAdmin />} />
				<Route path="analytics" element={<Analytics />} />
				{/* Holder routes */}
				<Route path="wallet/pathway/:id/:userPathwayId" element={<WalletPathway />} />
				<Route path="wallet/*" element={<WalletRoute />} />
				<Route path="profile" element={<ProfileRoute />} />
				{/* Issuer routes */}
				<Route path="home" element={<HomeRoute />} />
				<Route path="credentialDefinition/new" element={<VisualDefineCredential />} />
				<Route path="credentialDefinition/:id/edit" element={<VisualDefineCredential />} />
				<Route path="credentialDefinition/:id/view" element={<ViewCredential />} />
				<Route path="credentialDefinition/:id/design" element={<NewCredDesign />} />
				<Route path="credentialDefinition/:id/config" element={<NewCredConfig />} />
				<Route path="credentialIssue/:id/new" element={<CredentialIssue />} />
				<Route path="verify-credentials" element={<VerifyCredentials />} />
				<Route path="issuedcredentials" element={<IssuedCredentials />} />

				{/* Pathway routes */}
				<Route path="pathways" element={<Pathways />} />
				<Route path="pathways/new" element={<DefinePathway />} />
				<Route path="pathway/:id/*" element={<MainPathway />} />

				{/* NFT routes */}
				<Route path="presentation-catalog" element={<PresentationCatalog />} />
				<Route path="define-presentation/new" element={<DefinePresentation />} />
				<Route path="define-presentation/:id/edit" element={<DefinePresentation />} />
				<Route path="verify-presentation" element={<PresentationCardVerifier />} />
				<Route path="nft" element={<NFTCatalog />} />
				<Route path="nft/mint" element={<MintNft />} />

				{/* Authorizations Routes */}
				<Route path="authorizations" element={<Authorizations />} />
				<Route path="authorizations/authorize" element={<AuthorizeDefinitionForm />} />
			</Route>
			<Route path="/login" element={<LoginRoute />} />
			<Route path="/setPassword" element={<SetPasswordRoute />} />
			<Route path="/credDesign" element={<NewCredDesign />} />
			<Route path="/nft/view" element={<NFTView />} />
			<Route path="/globaled/callback" element={<GlobalEdRedirect />} />
      <Route path="/mena/callback" element={<MenaRedirect />} />
			<Route path="/newPathway" element={<Newpathway />} />
			<Route path="/createAccount" element={<CreateHolderAccount />} />
		</Route>
	)
);

export default publicRoute;

// if user is logged in => go to credential home
//

// /admin
// /orgAdmin
// /home
// /presentation-catalog
// /verify-credentials
// /issuedcredentials
// /pathways
// /pathways/new
// /pathways/:id/*
// /wallet
// /profile

// /analytics
// /credentialDefinition/new
// /credentialDefinition/:id/edit
// /credentialDefinition/:id/view
// /credentialDefinition/:id/design
// /credentialDefinition/:id/config
// /credentialIssue/:id/new

// /define-presentation/new
// /define-presentation/:id/edit
// verify-presentation
// /nft/
// /nft/mint
