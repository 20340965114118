import styled from "styled-components";
import { ThemeProvider } from "theme-ui";
import Theme from "../theme/theme";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { Button } from "@cti-workspace/ui";
import CircularProgress from "@material-ui/core/CircularProgress";

const DFlex = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const DataTableContainer = styled.div`
	${({ isLoading = false, bgColor }) => isLoading ? `
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		background-color: ${bgColor || "transparent"};
		`: ``}
	border-radius: 8px;
	box-shadow: 0px 3px 10px rgba(163, 182, 204, 0.2);
	margin-top: 1rem;
	.rdt_TableHeadRow {
		background-color: #F1E8F9;
		font-weight: bold;
		border-bottom-width: 0px;
		border-radius: 8px 8px 0px 0px;
	}

	.rdt_TableCol {
		justify-content: left;
	}

	.rdt_TableCell {
	  justify-content: left;
	}
`;

export function Table({
	config: { headers = [] },
	data = [], sort = false,
	selectableRows = false,
	totalRows = 0,
	pagination = false,
	toSelectRows = 0,
	onSelectedRows = () => { },
	footer = () => "",
	header = () => "",
	leftHeader = () => "",
	onSearch = () => { },
	onResetSearch = () => { },
	showSearch = true,
	searchPlaceholder = "Search",
	onChangeRowsPerPage = () => { },
	onChangePage = () => { },
	conditionalRowStyles = {},
	dense = false,
	onSort = () => { },
	loading = false,
	loaderCenter = false,
	noDataView = undefined,
	inTableLoader = true,
}) {
	const [selectedRows, setSelectedRows] = useState({
		selectedCount: 0,
		selectedRows: [],
	});
	const [search, setSearch] = useState("");

	useEffect(() => {
		if (Object.keys(selectableRows).length) {
			onSelectedRows(selectedRows);
		}
	}, [selectedRows]);

	return (
		<ThemeProvider theme={Theme}>
			{header && header(selectedRows)}
			<DFlex>
				<div style={{ width: "100%" }}>{leftHeader(selectedRows)}</div>
				{showSearch &&
					<div>
						<div style={{ display: "flex" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "3px", border: "0.5px solid #999999", borderRadius: "3px" }}>
                <input placeholder={searchPlaceholder} onChange={event => setSearch(event.target.value)} onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    onSearch(search);
                    ev.preventDefault();
                  }
                }} value={search} style={{ outline: "none", border: "none", paddingLeft: "0.5rem" }} />
								<Button buttonType="icon" icon="cancel" color="danger" invert onClick={() => {
									setSearch("");
									onResetSearch();
								}} />
								<Button onClick={() => onSearch(search)} color="warning" fullWidth={false} buttonStyle={{ marginLeft: "0.5rem" }}>Search</Button>
							</div>
						</div>
					</div>
				}
			</DFlex>
			<DataTableContainer isLoading={loading && loaderCenter} bgColor={!inTableLoader ? "white !important" : "transparent"}>
				<DataTable
					columns={headers.map((header) => ({
						...header,
						name: header.label,
						selector: header.prop,
						sortable: header.sort || sort,
						center: header.center === false ? false : true,
					}))}
					data={data}
					selectableRows={selectableRows}
					onSelectedRowsChange={(rows) => setSelectedRows(rows)}
					{
					...(selectableRows && toSelectRows > 0 ? {
						selectableRowsNoSelectAll: true,
						selectableRowsHighlight: true,
						selectableRowDisabled: (row) => selectedRows.selectedCount >= toSelectRows ? JSON.stringify(selectedRows.selectedRows || []).includes(JSON.stringify(row)) ? false : true : false,
					} : {})
					}
					{
					...(pagination ? {
						pagination: true,
						...(totalRows ? {
							paginationServer: true,
							paginationTotalRows: totalRows,
							onChangeRowsPerPage,
							onChangePage
						} : {})
					} : {})
					}
					conditionalRowStyles={conditionalRowStyles}
					dense={dense}
					progressPending={inTableLoader ? loading : false}
					progressComponent={<CircularProgress color="secondary" style={{ marginTop: "20px", marginBottom: "20px" }} />}
					onSort={onSort}
					noDataComponent={noDataView}
				/>
				{!inTableLoader && loading &&
					<CircularProgress color="secondary" style={{ marginTop: "20px", marginBottom: "20px" }} />
				}
				<div dangerouslySetInnerHTML={{ __html: footer(selectedRows) }} />
			</DataTableContainer>
		</ThemeProvider>
	);
}

export default Table;
