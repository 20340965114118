import { postApi, getApi } from "@cti-workspace/helpers";
import { useState } from "react";
import { ORGANIZATIONS_PREFIX } from "../lib/Constants";

export default function useVerifierRegisterHook() {
    const [pendingList, setPendingList] = useState([]);
    const [totalItems, setTotalItems] = useState(0);

    const registerVerifier = async (data = {}) => {
        try {
            const res = await postApi("/admin/createOrgCreateRequest", data)
            return res;
        } catch (error) {
            throw error?.message || error;
        }
    }
    const listPendingApprovals = async () => {
        try {
            const { items, totalItems } = await getApi("/admin/listOrgCreateRequests?statuses=pending");
            setPendingList(items);
            setTotalItems(totalItems)
            return { items, totalItems };
        } catch (error) {
            throw error?.message || error;
        }
    }
    const processApproval = async (id, action) => {
        try {
            const res = await postApi(`/${ORGANIZATIONS_PREFIX}/processOrgCreateRequest`, {
                createRequestId: id,
                action,
            });
            return res;
        } catch (error) {
            throw error?.message || error;
        }
    }
    return {
        registerVerifier,
        listPendingApprovals,
        pendingList,
        totalItems,
        processApproval
    }
}