import { ORGANIZATIONS_PREFIX } from "@cti-workspace/helpers";
import { getApi } from "./api";

export default async function fetchOrgEmailTemplates(organizationId, category = "ISSUE_CREDENTIALS", templateFor = "ORG") {
	const emailTemplates = await getApi(`/${ORGANIZATIONS_PREFIX}/getEmailTemplates?organizationId=${organizationId}&category=${category}&templateFor=${templateFor}`);
	return emailTemplates.emailTemplates.map((emailTemplate) => ({
		...emailTemplate,
		default: emailTemplate.categoryDefault,
		id: emailTemplate.templateId,
	}));
}
