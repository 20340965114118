import styled from "styled-components";

import NewCard from "../newCard/NewCard";

export const NewCardDisplayContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.3rem;
  max-width: 1063px;
  padding: 2rem;
`;

const NewCardDisplay = ({ list }) => {
  return (
    <NewCardDisplayContainer>
      {list.map((credential) => <NewCard key={credential.id || credential.title} {...credential} />)}
    </NewCardDisplayContainer>
  );
};

export default NewCardDisplay;
