import { useCallback, useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { ACCOUNTS_PREFIX, delApi, getAdapter, getApi, getObjectValue, ISSUE_CREDENTIALS, ISSUER_PREFIX, isValidHttpUrl, ORGANIZATIONS_PREFIX, postApi, putApi, SORT_OPTION, SORT_QUERY_OPTIONS } from "@cti-workspace/helpers";
import useNProgressHook from "../useNProgressHook";
import useWidthHook from "../useWidthHook";

// const innerSchema = {
// 	title: "Items of Credential",
// 	type: "object",
// 	required: ["_id", "definition", "renderer", "issuerEmail", "issuerOrganization", "name", "additionalData", "recipients", "imageUrl"],
// 	properties: {
// 		renderer: { type: "object" },
// 		recipients: { type: "number" },
// 	},
// };

// const apiGetSchema = {
// 	title: "Get Credentials",
// 	type: "array",
// 	items: innerSchema,
// };

// const apiAddSchema = {
// 	title: "Add Credentials",
// 	type: "object",
// 	required: ["additionalData", "id", "issuers", "name", "recipient", "transcript"],
// 	properties: {
// 		additionalData: {
// 			type: "object",
// 			required: ["description", "imageUrl", "issuerImageUrl", "resources", "sections"],
// 		},
// 		transcript: {
// 			type: "array",
// 		},
// 	},
// };

// const apiUpdateSchema = {
// 	title: "Update Credential",
// 	type: "object",
// 	required: ["query", "update"],
// 	properties: {
// 		query: {
// 			type: "object",
// 			required: ["name"],
// 			properties: {
// 				name: {
// 					type: "string",
// 				},
// 			},
// 		},
// 		update: {
// 			type: "object",
// 			properties: {
// 				name: {
// 					type: "string",
// 				},
// 				description: {
// 					type: "string",
// 				},
// 				imageUrl: {
// 					type: "string",
// 				},
// 				transcript: {
// 					type: "array",
// 				},
// 				resources: {
// 					type: "array",
// 				},
// 				sections: {
// 					type: "array",
// 				},
// 			},
// 		},
// 	},
// };

// const stateSchema = {
// 	title: "Credentials State",
// 	type: "array",
// 	items: innerSchema,
// };
const useCredentialHook = ({ account: { roles, organizations }, location, loadInitialCredentials = true }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [credsData, setCredsData] = useState([]);
	const [importedCredsData, setImportedCredsData] = useState([]);
	const [connectedCredsData, setConnectedCredsData] = useState([]);
	const [credentials, setCredentials] = useState({ items: [] });

	const { progressStart, progressDone } = useNProgressHook();
	const fetchCredData = useRef();
	const currentWidth = useWidthHook();

	// const protectedSetState = useCallback(async (data, stateFunction, schema) => {
	// 	stateFunction(data);
	// });

	useEffect(() => {
		// Initial loading of credentials
		if (roles && loadInitialCredentials) {
			if (!(roles.length === 1 && roles[0] === "holder")) {
				initialGetCreds();
				getCreds();
			}
		}
	}, []);

	const initialGetCreds = useCallback(async () => {
		try {
			progressStart();
			if (!(location && location.search)) {
				const sortVal = localStorage.getItem(SORT_OPTION);
				await getCredsPerPage(1, 1, "", false, SORT_QUERY_OPTIONS[sortVal || "atoz"]);
			}
			await getConnectedCreds();
			await getImportedCreds();
			progressDone();
		} catch (err) {
			console.error(err);
			progressDone();
		}
	});

	const getConnectedCreds = async () => {
		try {
			if (!organizations) return;
			const orgId = organizations.default?._id;
			const url = `/adapter/getConnectedCredentials?orgId=${orgId}`;
			const data = await getAdapter(url);
			setConnectedCredsData(data);
			return data;
		} catch (error) {
			if (typeof error === "string") {
				throw new Error(error);
			} else if (error[0]) {
				error.message = `Error type:${getObjectValue(error, "[0].keyword")} message: ${getObjectValue(error, "[0].message")}`;
			}
			throw error;
		}
	};

	const getCreds = async () => {
    try {
      console.log("getCreds");
      const data = await getApi(`/${ISSUER_PREFIX}/getCredentialDefinitions`);
      console.log("getCreds post");
			const credData = data.filter((item) => getObjectValue(item, "state") !== "archived");
			setCredsData(credData);
			return credData;
    } catch (error) {
      console.log(`getCreds error: ${JSON.stringify(error, null, 1)}`);
      if (error[0]) {
        error.message = `Error type:${getObjectValue(error, "[0].keyword")} message: ${getObjectValue(error, "[0].message")}`;
      }
      enqueueSnackbar(`${error.message}`, { variant: "error" });
			// throw error;
		}
	};

	const getCredsPerPage = async (pageSize = 1, pageNumber = 1, search = "", onlyCredDef = false, sortBy = "") => {
		return new Promise((resolve, reject) => {
			if (fetchCredData.current) clearTimeout(fetchCredData.current);
			fetchCredData.current = setTimeout(async () => {
				try {
					fetchCredData.current = null;
					switch (currentWidth) {
						case "xs":
							pageSize = 10;
							break;
						case "sm":
						case "md":
							pageSize = 20;
							break;
						case "lg":
							pageSize = 9;
							break;
						default:
							pageSize = 9;
					}
					const data = await getApi(
						`/${ISSUER_PREFIX}/getBatchCredentialDefinitions?pageSize=${pageSize}&pageNumber=${pageNumber}&search=${search || ""
						}&sortBy=${sortBy || "%5B%7B%22field%22%3A%22pinned%22%2C%20%22order%22%3A%22desc%22%7D,%7B%22field%22%3A%22_id%22%2C%20%22order%22%3A%22desc%22%7D%5D"}`
					);

					if (onlyCredDef) {
						resolve(data);
						return;
					}

					// const orgId = organizations?.default?._id;
					const { totalItems, items = [], pageSize: currentPageSize, pageNumber: currentPageNumber } = data;
					try {
						const { customCredentials } = await getApi(`/${ORGANIZATIONS_PREFIX}/getCustomCredentials`);

						if (customCredentials) {
							if (items) {
								const newArr = items.map((obj) => {
									if (obj.customCredentials) {
										let val = customCredentials.filter((v) => v["_id"] === obj.customCredentials[0]);
										return { ...obj, customCredentials: val };
									}
									return obj;
								});
								setCredentials({ items: newArr, totalItems, currentPageNumber, currentPageSize });
								return resolve({ ...data, items: newArr });
							} else {
								reject(data);
							}
						}
					} catch (error) {
						setCredentials({ items, totalItems, currentPageNumber, currentPageSize });
						return resolve(data);
					}
				} catch (error) {
					fetchCredData.current = null;
          enqueueSnackbar(`${error.message}`, { variant: "error" });
					if (error[0]) {
						error.message = `Error type:${getObjectValue(error, "[0].keyword")} message: ${getObjectValue(error, "[0].message")}`;
					}
					throw reject(error);
				}
			}, 500);
		});
	};

	const getImportedCreds = async () => {
		try {
			const data = await getApi(`/${ISSUER_PREFIX}/getImportedCredentialsByRecipient`);
			setImportedCredsData(data);
			return data;
		} catch (error) {
			enqueueSnackbar(`Error getting imported credentials: ${error.message}`, { variant: "error" });
		}
	};

	const deleteCredentialDefinition = async (credDefId) => {
		try {
			await delApi(`/${ISSUER_PREFIX}/deleteCredentialDefinition`, {
				credDefId,
			});
			return true;
		} catch (error) {
			enqueueSnackbar(`Error: ${error.message}`, { variant: "error" });
			throw error;
		}
	};

	const archiveCredentialDefinition = async (_id) => {
		try {
			const data = await putApi(`/${ISSUER_PREFIX}/archiveCredentialDefinition`, { credDefId: _id });
			return data;
		} catch (err) {
			enqueueSnackbar(`Error: ${err.message}`, { variant: "error" });
			throw err;
		}
	};

	const copyCredentialDefinition = async (credDefId) => {
		try {
			const data = await postApi(`/${ISSUER_PREFIX}/copyCredentialDefinition`, { credDefId });
			return data;
		} catch (error) {
			enqueueSnackbar(`Error: ${error.message}`, { variant: "error" });
			throw error;
		}
	};

	const importCredential = useCallback(async ({ data, type }) => {
		try {
			const { credential } = await postApi(`/${ACCOUNTS_PREFIX}/importCredential`, {
				data,
				type,
			});
			if (credential) {
				enqueueSnackbar(`Successfully imported!`, { variant: "success" });
				setImportedCredsData([...importedCredsData, credential]);
				return true;
			}
		} catch (error) {
			enqueueSnackbar(`Error: ${error.message}`, { variant: "error" });
		}
	});

	const updateCredentialDefinition = async (
		credDefId,
		name,
		description,
		transcriptFieldsArray,
		imageUrl,
		resources,
		rendererConfigId = "",
		sections = [],
		saveIcon = false,
		iconName,
		selectedEmailTemplate = null,
		settings = undefined,
		pinned = false,
		customCredential = null,
		requiredUserAuthentications = null,
		tags = []
	) => {
		const transcript = transcriptFieldsArray.map((item, i) => ({
			name: item.key,
			transcriptType: item.type,
			courseCode: String(i),
			grade: item.value || "",
			required: item.required || false,
		}));

		const query = {
			credDefId,
		};

		const update = {
			name,
			resources,
			description,
			transcript,
			imageUrl,
			rendererConfigId,
			sections,
			iconData: {
				iconURL: imageUrl || "https://ipwru.s3.amazonaws.com/prod/seal.png",
				persist: saveIcon,
				iconName,
			},
			emailTemplates: selectedEmailTemplate
				? {
          [ISSUE_CREDENTIALS]: selectedEmailTemplate,
        }
				: { [ISSUE_CREDENTIALS]: selectedEmailTemplate },
			pdfTemplates: settings?.pdfOptions
				? {
          [ISSUE_CREDENTIALS]: settings.pdfOptions.pdfTemplate,
        }
				: undefined,
			customCredentials: customCredential ? [customCredential] : [],
			settings,
			pinned,
			requiredUserAuthentications,
			tags,
		};
		if (update?.settings?.pdfOptions) {
			update.settings.pdfOptions.pdfTemplate = undefined;
		}

		const requestBody = { query, update };
		const data = await putApi(`/${ISSUER_PREFIX}/updateCredentialDefinition`, requestBody);
		return data;
	};

	const addCreds = async (
		credentialName,
		description,
		transcriptFieldsArray,
		imageUrl,
		resources,
		rendererConfigId = "",
		sections = [],
		saveIcon = false,
		iconName,
		selectedEmailTemplate = null,
		settings = undefined,
		pinned = false,
		customCredential = null,
		requiredUserAuthentications = null,
		tags = []
	) => {
		let transcriptFields = null;
		if (transcriptFieldsArray) {
			transcriptFields = transcriptFieldsArray.map((item, i) => ({
				name: item.key,
				transcriptType: item.type,
				courseCode: String(i),
				grade: item.value || "",
				required: item.required || false,
			}));
		}
		const credDef = {
			id: "",
			name: credentialName,
			issuers: [],
			recipient: {},
			transcript: transcriptFields,
			additionalData: {
				description,
				imageUrl: imageUrl || "https://ipwru.s3.amazonaws.com/prod/seal.png",
				issuerImageUrl: "",
				resources,
				rendererConfigId,
				sections,
				iconData: {
					iconURL: imageUrl || "https://ipwru.s3.amazonaws.com/prod/seal.png",
					persist: saveIcon,
					iconName,
				},
				emailTemplates: selectedEmailTemplate
					? {
            [ISSUE_CREDENTIALS]: selectedEmailTemplate,
          }
					: undefined,
				pdfTemplates: settings?.pdfOptions
					? {
            [ISSUE_CREDENTIALS]: settings.pdfOptions.pdfTemplate,
          }
					: undefined,
				tags,
			},
			customCredentials: customCredential ? [customCredential] : [],
			settings,
			resources,
			pinned,
			requiredUserAuthentications,
			tags,
		};
		if (credDef?.settings?.pdfOptions) {
			credDef.settings.pdfOptions.pdfTemplate = undefined;
		}
		try {
			const data = await postApi(`/${ISSUER_PREFIX}/addCredentialDefinition`, credDef);
			return data;
		} catch (error) {
			if (error[0]) {
				error.message = `Error type:${getObjectValue(error, "[0].keyword")} message: ${getObjectValue(error, "[0].message")}`;
			}
			throw error;
		}
	};

	const bulkAddCreds = async (credDefRows) => {
		// credentialName, description, transcriptFieldsArray, imageUrl, resources, courseId
		const credDefs = credDefRows.map((row, i) => {
			let transcriptFields = [];
			let resources = [];
			const [credentialName, description, transcriptFieldsString, imageUrl, resourceString, courseId] = row;

			if (!credentialName) {
				throw new Error(`A Credential Name is missing. Column 1, row ${i + 2}.`);
			}

			let credName = credentialName;

			if (imageUrl && !isValidHttpUrl(imageUrl)) {
				throw new Error(`Image URL must be a valid HTTP URL. Column 4, row ${i + 2}. Got ${imageUrl}.`);
			}

			if (transcriptFieldsString) {
				const transcriptFieldsArray = transcriptFieldsString.split(/\r?\n/);
				transcriptFields = transcriptFieldsArray.map((item, i) => ({
					name: item,
					courseCode: String(i),
					grade: "",
				}));
			}
			if (resourceString) {
				let id;
				let url;
				try {
					[id, url] = resourceString.split(",");
				} catch (err) {
					throw new Error(`Invalid resources. Column 5, row ${i + 2}. It must include two values separate by a comma: resourceId, resourceUrl.`);
				}

				if (!id || !url) {
					throw new Error(`Invalid resources. Column 5, row ${i + 2}. It must include two values separate by a comma: resourceId, resourceUrl. Got ${id}, ${url}`);
				} else if (!isValidHttpUrl(url)) {
					throw new Error(`Resource URL must be a valid HTTP URL. Column 5, row ${i + 2}. Got: ${url}`);
				}

				resources = [{ id, url }];
			}

			if (courseId) {
				credName = `[${courseId}] ${credName}`;
			}

			const credDef = {
				id: "",
				name: String(credName),
				issuers: [],
				recipient: {},
				transcript: transcriptFields,
				additionalData: {
					description: (description && description.split(/\r?\n/).join("<br />")) || "",
					imageUrl: imageUrl || "https://ipwru.s3.amazonaws.com/prod/seal.png",
					issuerImageUrl: "",
					resources,
				},
			};
			return credDef;
		});
		try {
			const data = await postApi(`/${ISSUER_PREFIX}/bulkAddCredentialDefinition`, credDefs);
			return data;
		} catch (error) {
			if (error[0]) {
				error.message = `Error type:${getObjectValue(error, "[0].keyword")} message: ${getObjectValue(error, "[0].message")}`;
			}
			throw error;
		}
	};

	const getCredentialById = async (id) => {
		try {
			return await getApi(`/${ISSUER_PREFIX}/getCredentialDefinitionById/${id}`);
		} catch (error) {
			enqueueSnackbar(`Failed to retrieve credential definition`, { variant: "error" });
			throw error;
		}
	};

	return {
		connectedCredsData,
		credsData,
		setCredsData,
		copyCredentialDefinition,
		deleteCredentialDefinition,
		getConnectedCreds,
		getCreds,
		getImportedCreds,
		addCreds,
		bulkAddCreds,
		importCredential,
		importedCredsData,
		setConnectedCredsData,
		updateCredentialDefinition,
		archiveCredentialDefinition,
		getCredsPerPage,
		credentials,
		setCredentials,
		getCredentialById,
	};
};

export default useCredentialHook;
