import styled from "styled-components";
import Container from "../container/container";
import { useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";

const DialogContainer = styled.div`
	position: fixed;
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	overflow: hidden;
	top: 0;
	left: 0;
	z-index: 1300;

	animation: fadeIn 0.1s;
	opacity: 1;

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
`;

const DialogCenterContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

const DialogContentContainer = styled(Container)`
	background-color: white;
	padding: 0px;
	border-radius: ${(props) => props.borderRadius};
	display: flex;
	max-height: 80vh;
	overflow: hidden;
	display: grid;
	grid-template-rows: auto 1fr auto;
	box-shadow: 0px 0px 3px 1px rgb(0 0 0 / 10%);
`;

export function Dialog({ open = false, maxWidth = "md", onClose = () => { }, disableBackdropClick = true, disableEscapeKeyDown = true, children, borderRadius = "3px", isDialogContainerActive = true, dialogStyle = {}, dialogRefStyle = {}, dialogContainerStyle = {} }) {
	return (
		<>
			{open ? (
				<DialogUI
					open={open}
					maxWidth={maxWidth}
					onClose={onClose}
					disableBackdropClick={disableBackdropClick}
					disableEscapeKeyDown={disableEscapeKeyDown}
					borderRadius={borderRadius}
					isDialogContainerActive={isDialogContainerActive}
					dialogStyle={dialogStyle}
					dialogRefStyle={dialogRefStyle}
					dialogContainerStyle={dialogContainerStyle}
				>
					{children}
				</DialogUI>
			) : null}
		</>
	);
}

function DialogUI({ maxWidth = "md", onClose = () => { }, disableBackdropClick = true, disableEscapeKeyDown = true, children, borderRadius, isDialogContainerActive = true, dialogStyle = {}, dialogRefStyle = {}, dialogContainerStyle = {} }) {
	const dialogRef = useRef(null);

	useEffect(() => {
		function handleClickEscBtn(event) {
			if (!disableEscapeKeyDown && event.keyCode === 27) {
				document.removeEventListener("keydown", handleClickEscBtn);
				onClose(event);
			}
		}
		document.addEventListener("keydown", handleClickEscBtn);
		return () => {
			document.removeEventListener("keydown", handleClickEscBtn);
		};
	}, []);

	const handleMouseDown = (e) => {
		if (!disableBackdropClick && dialogRef.current && !dialogRef.current.contains(e.target)) {
			onClose(e);
		}
	};

	return (
		<DialogContainer onMouseDown={handleMouseDown}>
			<DialogCenterContainer>
				<Container maxWidth={maxWidth} style={dialogStyle}>
					<div ref={dialogRef} style={dialogRefStyle}>
						{isDialogContainerActive ? (
							<DialogContentContainer maxWidth={maxWidth} borderRadius={borderRadius} style={dialogContainerStyle}>
								{children}
							</DialogContentContainer>
						) : (
							children
						)}
					</div>
				</Container>
			</DialogCenterContainer>
		</DialogContainer>
	);
}

/** @jsxImportSource theme-ui */
export default Dialog;
