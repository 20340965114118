import { getApi } from "./api";
import { API_URL } from "@cti-workspace/helpers";

const getPaginatedResponseItems = async (url, pageSize = null, pageNumber = null, search = null, returnItems = true) => {
	const queryParameters = {
		pageSize,
		pageNumber,
		search: search,
	};
	let queryString;
	let parameterizedUrl;
	try {
		queryString = Object.entries(queryParameters)
			.filter((parameter) => parameter[1] !== null && parameter[1] !== "")
			.map((pair) => pair.map(encodeURIComponent).join("="))
			.join("&");
	} catch (e) {
		queryString = null;
	}
	if (queryString) {
		parameterizedUrl = `${url}?${queryString}`;
	} else {
		parameterizedUrl = url;
	}
	// console.log(parameterizedUrl)

	return getApi(parameterizedUrl, {}, true, API_URL, {}, returnItems);
};

export default getPaginatedResponseItems;
