import { Fragment, useState, useEffect } from "react";
import MuiGrid from "@material-ui/core/Grid";
import { FixedSizeList as List, FixedSizeGrid as Grid } from "react-window";
import useCertificateHook from "../../hooks/useCertificateHook";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Alert from "@material-ui/lab/Alert";
import { isMatch, format } from "date-fns";
import { BulkDisplayRow } from "./BulkDisplay";
import useSettingsHook from "../../hooks/useSettingsHook";
import * as yup from "yup";
import { FormHelperText, Typography } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Box, CheckBox, Button, Dialog, DialogActions, DialogTitle, DialogContent, Spinner } from "@cti-workspace/ui";
import parse from "parse-duration";
import { getErrMsg, IMAGE_CONTENT, BULK_ISSUE_LIMITS, buildCertificateToIssue, getEncodedFileContent, getFileName, getFileValidationMessage, getObjectValue } from "@cti-workspace/helpers";

export default function BulkIssue({ state, setState, reloadCreds, enqueueSnackbar, isMultiIssue = false, onReview, resetMultiIssueState, isFinal, issuingMultiCredentials, isFirst }) {
	// console.log(state);
	const selectedCred = state?.selectedCred;
	const { customCredentials = [] } = selectedCred || {};
	// console.log(customCredentials["0"]);
	const handleClose = () => {
		setState({ dialogue: false, data: null, error: null, selectedCred: null, modalType: "issuemany" });
	};
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState([]);
	const [rowData, setRowData] = useState([]);
	const [certData, setCertData] = useState([]);
	const [submit, setSubmit] = useState(false);
	const [batchLabelValue, setBatchLabelValue] = useState("");
	const [columnSize, setColumnSize] = useState(0);
	const [rowSize, setRowSize] = useState(0);
	const [imageUploadField, setImageUploadField] = useState(null);
	const [imageUploadValidation, setImageUploadValidation] = useState("");
	const [allowPickup, setAllowPickup] = useState(false);
	const [selectedLinkFactors, setSelectedLinkFactors] = useState([]);

	// console.log(selectedLinkFactors);

	const { canView, updateSavedBatchLabel } = useSettingsHook();

	const setErrorDisplay = (msg) => {
		setError((prev) => [...prev, msg]);
		setLoading(false);
	};

	const { createCertificate } = useCertificateHook({});

	const areArraysEqualSets = (a1, a2) => {
		let superSet = {};
		for (const i of a1) {
			const e = i;
			superSet = {
				...superSet,
				[e]: 1,
			};
		}

		for (const i of a2) {
			const e = i;
			const { [e]: superSetElement } = superSet;
			if (!superSetElement) {
				return false;
			}
			superSet = {
				...superSet,
				[e]: 2,
			};
		}

		for (let e in superSet) {
			const { [e]: superSetElement } = superSet;
			if (superSetElement === 1) {
				return false;
			}
		}
		return true;
	};

	useEffect(() => {
		(async () => {
			if (state?.error) {
				setErrorDisplay(state?.error?.msg);
			} else {
				try {
					// Issue limit validations
					let maximumAllowedRecordsForBulkIssue = state.headers.includes(IMAGE_CONTENT) ? BULK_ISSUE_LIMITS.WITH_IMAGES : BULK_ISSUE_LIMITS.WITHOUT_IMAGES;
					if (state.data.length > maximumAllowedRecordsForBulkIssue) {
						setErrorDisplay(`<p>The maximum limit that can be issued is ${maximumAllowedRecordsForBulkIssue}. Please try to issue with multiple sheets.</p>`);
						return;
					}

					let certArray = [];
					const columnHeaders = state.headers;
					let fixedKeys = [];
					if (state.modalType === "issuemany") fixedKeys = ["first name", "last name", "contact"];
					let expiryDateKey = "expiration date/duration";
					// get Sections and Transcripts expected by the credential definition.

					if (columnHeaders.includes("expiration date/duration") === true) {
						fixedKeys = [...fixedKeys, expiryDateKey];
					}

					let transcriptKeys = [];
					// let sectionsLength = 0; sectionsData?.length
					let sectionsKeys = [];
					let imageUploadTranscriptRow = null;

					const sectionsDefinition = getObjectValue(JSON.parse(state.selectedCred.definition), "additionalData.sections", []) || [];

					sectionsDefinition.map((item) => {
						let mainStr = `Section_${item.name}_`;
						item.claims.map((claim) => {
							let columnHeader = `${mainStr}${claim}`;
							sectionsKeys.push(columnHeader.toLowerCase().trim());
						});
					});

					// console.log(sectionsKeys); // list of section based column headers expected as credential definition
					const credDefTranscript = JSON.parse(state.selectedCred.definition)?.transcript || [];
					transcriptKeys = credDefTranscript.map((item) => item.name.toLowerCase().trim());

					// console.log(transcriptKeys) // list of transcripts based column headers expected as credential definition

					const expectedHeaders = [...fixedKeys, ...sectionsKeys, ...transcriptKeys];

					let requiredColumnObj = {};
					credDefTranscript.forEach((item) => {
						if (item.grade === IMAGE_CONTENT) {
							imageUploadTranscriptRow = item;
							if (item.required) {
								setImageUploadValidation("Please upload the file/files required for " + item.name);
							}
						}
						requiredColumnObj = {
							...requiredColumnObj,
							[item.name.toLowerCase().trim()]: item.required || false,
						};
					});
					[...fixedKeys, ...sectionsKeys].forEach((item) => {
						requiredColumnObj = {
							...requiredColumnObj,
							[item]: true,
						};
					});

					const matchHeaders = areArraysEqualSets(expectedHeaders, columnHeaders);

					if (!matchHeaders) {
						setErrorDisplay(`
						<p>The column headers in the uploaded file do not match the credential content / sections.</p>
						<p>Credential content / sections: ${JSON.stringify(expectedHeaders, null, 1)}.</p>
						<p>Column headers: ${JSON.stringify(columnHeaders, null, 1)}.</p>`);
					} else {
						let temp = {};
						// Building Yup validation object
						let dateError = "";
						const disableBulkIssueDateValidation = canView("disableBulkIssueDateValidation", true);
						if (!disableBulkIssueDateValidation) {
							for (const transcriptKey in credDefTranscript) {
								if (!credDefTranscript.hasOwnProperty(transcriptKey)) continue;
								const transcript = credDefTranscript[transcriptKey];
								if (transcript.transcriptType === "DATE") {
									for (const index in state.data) {
										if (!state.data.hasOwnProperty(index)) continue;
										const importedValue = state.data[index];
										const dateValue = importedValue[transcript.name?.toLowerCase()]?.trim() ?? "";
										if (!transcript.isRequired && !dateValue) continue;
										if (!isMatch(dateValue, transcript.grade)) {
											const columnIndex = columnHeaders.findIndex((value) => value === transcript.name?.toLowerCase());
											dateError = `Row ${Number(index) + 1}, Column ${columnIndex + 1}: Date format is incorrect, please ensure you are following your selected format (${format(new Date(dateValue), transcript.grade)}).`;
											break;
										}
									}
								}

								if (dateError?.length > 0) {
									break;
								}
							}
						}

						if (dateError.length > 0) {
							setErrorDisplay(`<p>${dateError}</p>`);
						} else {
							expectedHeaders.forEach((element) => {
								if (element == "contact") {
									temp = {
										...temp,
										[element]: yup.string().email(`Column: ${element}, Error: email address is Invalid.`).required(`Column: ${element}, Error: value is required.`),
									};
								} else if (element == "expiration date/duration") {
                  temp = {
										...temp,
										[element]: yup
											.string()
											.test("test-name", `Column: ${element}, Error: Please enter valid date in MM/DD/YYYY format (Exp: 04/24/2022) & durations in days, months, years (Exp: 10 months). Please do not add past date.`, function (value) {
												if (value !== undefined) {
													const isValidDate = function (date) {
														return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
													};

													if (isValidDate(value)) {
														if (!isMatch(value, "MM/dd/yyyy")) return false;
														let givenDate = new Date(value);
														let diff = new Date().getTime() - givenDate.getTime();

														if (diff > 0) {
															return false;
														} else {
															return true;
														}
													} else {
														return parse(value, "d");
													}
												} else {
													return true;
												}
											}),
									};
								} else if (element == "First Name") {
									const { [element]: requiredColumnObjElement } = requiredColumnObj;
									if (requiredColumnObjElement) {
										temp = {
											...temp,
											[element]: yup.string().required(`Column: ${element}, Error: value is required.`),
										};
									} else {
										temp = {
											...temp,
											[element]: yup.string(),
										};
									}
								} else if (element == "Last Name") {
									const { [element]: requiredColumnObjElement } = requiredColumnObj;
									if (requiredColumnObjElement) {
										temp = {
											...temp,
											[element]: yup.string().required(`Column: ${element}, Error: value is required.`),
										};
									} else {
										temp = { ...temp, [element]: yup.string() };
									}
								} else {
									const { [element]: requiredColumnObjElement } = requiredColumnObj;
									if (requiredColumnObjElement) {
										temp = {
											...temp,
											[element]: yup.string().required(`Column: ${element}, Error: value is required.`),
										};
									} else {
										temp = { ...temp, [element]: yup.string() };
									}
								}
							});

							const yupObject = yup.object().shape(temp);

							const uploadData = state.data;

							const sectionBuilder = (sectionsDefinition, val) => {
								let tempObj = sectionsDefinition;
								const sectionsObj = tempObj.map((section, i) => {
									return {
										...section,
										displayName: section.name,
										claims: section.claims.map((claim) => {
											return {
												name: claim,
												value: getObjectValue(val, `section_${section.name}_${claim}`.toLowerCase().trim()),
											};
										}),
									};
								});
								return sectionsObj;
							};

							for (let item in uploadData) {
								const { [item]: uploadItem } = uploadData;
								await yupObject
									.validate(uploadItem, { abortEarly: false })
									.then((val) => {
										let recipientName = `${val["first name"]} ${val["last name"]}`;
										let recipientFirstName = `${val["first name"]}`;
										let recipientLastName = `${val["last name"]}`;
										let recipientEmail = val["contact"];
										let expiryDate = val["expiration date/duration"] ? `${val["expiration date/duration"]}` : null;
										let transcriptGrades = {};
										let expirationDateDuration = {};

										const isValidDate = function (date) {
											return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
										};
										if (isValidDate(expiryDate) && expiryDate !== null) {
											expirationDateDuration = {
												expiryDate: expiryDate + " " + "00:00:00",
											};
										}

										if (!isValidDate(expiryDate) && expiryDate !== null) {
											expirationDateDuration = {
												expiryDuration: expiryDate,
											};
										}

										let sections = sectionBuilder(sectionsDefinition, val);

										for (const key in val) {
											if (transcriptKeys.includes(key)) {
												transcriptGrades = {
													...transcriptGrades,
													[key]: getObjectValue(val, key),
												};
											}
										}

										let cert = buildCertificateToIssue(recipientName, recipientFirstName, recipientLastName, recipientEmail, state.selectedCred, transcriptGrades, sections, expirationDateDuration, state.modalType);
										certArray.push(cert);
									})
									.catch((err) => {
										console.log(err);
										if (err && err.errors) {
											err.errors.forEach((e) => {
												let temp = parseInt(item);
												setErrorDisplay(`Row: ${temp + 2}, ${e}`);
											});
										}
									});
							}
						}
					}

					setCertData(certArray);
					if (imageUploadTranscriptRow) {
						const imageUploadTranscriptRowIndex = state.arr[0].findIndex((header) => header === imageUploadTranscriptRow.name);
						imageUploadTranscriptRow = {
							...imageUploadTranscriptRow,
							index: imageUploadTranscriptRowIndex,
						};
						setImageUploadField(imageUploadTranscriptRow);
					}
					setRowData(state.arr);
					setColumnSize(columnHeaders?.length);
					setRowSize(certArray?.length);
					setLoading(false);
				} catch (error) {
					console.log(error);
					setErrorDisplay("Unknown error, Possible Template format issue. Please download a new Template");
				}
			}
		})();

		return () => {
			setError([]);
			setLoading(true);
		};
	}, [state]);

	// get required headers from
	// Header check
	// data check
	// contact check
	// missing value check

	const handleCreateIssueCredential = async (certs, batchLabel, selectedCred, linkingFactor = []) => {
		try {
			let recipients = [];
			// contact hook not complete yet
			let credDef = selectedCred;
			const data = await createCertificate({
				recipients,
				credDef,
				transcriptGrades: {},
				certs,
				batchLabel,
				issueType: state.modalType === "issuemany" ? "ISSUE_MANY" : "SAVE_MANY",
				linkingFactor,
      });
      // See cypress app.trybe.id-dev\cypress\integration\cti-app\credentialCatalog\issue.spec.js createCertificate for the test
			if (data && data.issuedCerts && data.issuedCerts?.length) enqueueSnackbar(`New Certs Issued. Count: ${data.issuedCerts?.length}`, { variant: "success" });
			return data;
		} catch (error) {
      enqueueSnackbar(getErrMsg(error, "There was an error issuing certificates. Please try again later."), {
				variant: "error",
			});
		}
	};

	const issueCerts = async (certs, batchLabel, selectedCred) => {
		try {
			if (state.modalType === "savemany" && allowPickup && selectedLinkFactors?.length === 0) {
				enqueueSnackbar("Please select at least one linking factor", {
					variant: "error",
				});
				return;
			}
			setSubmit(true);
			if (certs && certs?.length) {
				const { issuedCerts = [] } = await handleCreateIssueCredential(certs, batchLabel, selectedCred, selectedLinkFactors);
				reloadCreds({ type: "ISSUED_CRED_COUNT_UPDATE", payload: { length: issuedCerts?.length, _id: selectedCred._id } });
			}
			if (batchLabel) {
				updateSavedBatchLabel(batchLabel);
			}
			handleClose();
		} catch (error) {
			handleClose();
			enqueueSnackbar(error?.message ? `Error ${error.code || ""} ${error.message}` : getErrMsg(error), {
				variant: "error",
			});
		}
	};

	const ErrorRow = ({ index, data, style }) => (data?.length == 1 ? <span style={style} dangerouslySetInnerHTML={{ __html: getObjectValue(data, index) }} /> : <li style={style}>{getObjectValue(data, index)}</li>);

	const DataRow = ({ data, columnIndex, rowIndex, style }) => {
		return <BulkDisplayRow style={style} data={data} columnIndex={columnIndex} rowIndex={rowIndex} />;
	};

	const onBulkImageUpload = async (e) => {
		let bulkImageUploadValidationMessage = "";
		const files = e.target.files;
		const fileNames = [];
		const fullFileNames = [];
		let encodedFiles = {};
		for (let fileIndex = 0; fileIndex < files?.length; fileIndex++) {
			const { [fileIndex]: file } = files;
			bulkImageUploadValidationMessage = getFileValidationMessage(file, true);
			if (bulkImageUploadValidationMessage) {
				break;
			}
			const fileName = getFileName(file);
			fileNames.push(fileName);
			fullFileNames.push(file.name);
			const { [fileName]: encodedFilesFileName } = encodedFiles;
			if (!encodedFilesFileName) {
				try {
					encodedFiles = {
						...encodedFiles,
						[fileName]: await getEncodedFileContent(file),
					};
				} catch (e) {
					bulkImageUploadValidationMessage = `Failed to encode file ${fileName}. Please re-upload all files`;
				}
			}
		}
		for (let rowIndex = 1; rowIndex < rowData?.length; rowIndex++) {
			const requiredFileName = getObjectValue(rowData, `${rowIndex}.${imageUploadField.index}`);
			if (!(fileNames.includes(requiredFileName) || fullFileNames.includes(requiredFileName)) && !bulkImageUploadValidationMessage) {
				bulkImageUploadValidationMessage = `Required file ${requiredFileName} for row no. ${rowIndex} was not found in upload files. Please re-upload all files.`;
				break;
			}
		}
		if (!bulkImageUploadValidationMessage) {
			const encodedCertData = [...certData];
			for (let encodedCertIndex = 0; encodedCertIndex < encodedCertData?.length; encodedCertIndex++) {
				const fileTranscriptRow = getObjectValue(encodedCertData, encodedCertIndex).transcript.find((transcript) => transcript.name === imageUploadField.name);
				fileTranscriptRow.grade = getObjectValue(encodedFiles, fileTranscriptRow.grade);
			}
			setCertData(encodedCertData);
		}
		setImageUploadValidation(bulkImageUploadValidationMessage);
	};

	const addToLinkingFactor = (e, i) => {
		const checked = e.target.checked;
		if (checked) {
			setSelectedLinkFactors(selectedLinkFactors.concat({ key: i.credSubjectKey, name: i.credDefContentName }));
		} else {
			setSelectedLinkFactors(selectedLinkFactors.filter((item2) => item2.key !== i.credSubjectKey));
		}
	};

	return (
		<Dialog open={state && state.dialogue} onClose={handleClose} aria-labelledby="bulk-issue-modal" maxWidth="md" fullWidth>
			<DialogTitle id="bulk-issue-modal-title">
				{loading && "Loading"}
				{!loading && !error?.length ? (
					<Alert icon={false} severity="success">
						<h3>{`${rowSize} Credentials are ready to be ${state.modalType === "issuemany" ? "issued" : "saved"} ${isMultiIssue ? " for " + `${state.selectedCred.name} (${state.selectedCred._id})` : ""}.`}</h3>
					</Alert>
				) : (
					<Alert icon={false} severity="error">
						<h3>
							Error in Bulk {state.modalType === "issuemany" ? "Issue" : "Save"} {isMultiIssue ? " for " + `${state.selectedCred.name} (${state.selectedCred._id})` : ""}
						</h3>
					</Alert>
				)}
			</DialogTitle>
			<DialogContent>
				{!loading && !error?.length && state.modalType === "savemany" && (
					<>
						<Divider />
						<div style={{ paddingBottom: 10 }} />
						<div>
							<MuiGrid container spacing={4}>
								<MuiGrid item xs={12} sm={4} md={3}>
									<Typography variant="body1">Allow pickup:</Typography>
								</MuiGrid>
								<MuiGrid item xs={12} sm={8} md={9}>
									<CheckBox color="warning" onChange={(e) => setAllowPickup(e.target.checked)} checked={allowPickup} />
								</MuiGrid>
							</MuiGrid>
							{allowPickup && (
								<MuiGrid container spacing={4}>
									<MuiGrid item xs={12} sm={4} md={3}>
										<Typography variant="body1">Linking factor:</Typography>
									</MuiGrid>
									<MuiGrid item xs={12} sm={8} md={9}>
										{customCredentials &&
											customCredentials["0"].credentialMappings.map((item, _i) => (
												<div>
													<CheckBox key={item.credSubjectKey} label={item.credDefContentName} color="warning" checked={selectedLinkFactors.some((link) => link.key === item.credSubjectKey)} onChange={(e) => addToLinkingFactor(e, item)} />
												</div>
											))}
										<div>
											<FormHelperText>One linking factor should be selected</FormHelperText>
										</div>
									</MuiGrid>
								</MuiGrid>
							)}
						</div>
					</>
				)}
				<Divider />
				<div style={{ paddingBottom: 10 }} />
				<div>
					{error?.length ? (
						<>
							<h3> Identified {error?.length} error(s) </h3>
							<List height={300} itemCount={error?.length} itemData={error} itemSize={50} width={"100%"}>
								{ErrorRow}
							</List>
						</>
					) : (
						<>
							<h3>Preview</h3>

							<Grid columnCount={columnSize} columnWidth={183} height={300} rowCount={rowSize + 1} rowHeight={50} width={columnSize * 183} itemData={rowData}>
								{DataRow}
							</Grid>

							<Divider />
							<div style={{ paddingBottom: 10 }} />
							<TextField
								id="batch-issue-label"
								label="Batch Label"
								style={{ margin: 8 }}
								placeholder="Label text"
								helperText="Labels are used to identify batches of issued credentials. Labels can be used to search for specific batches that were previously issued."
								fullWidth
								margin="normal"
								InputLabelProps={{
									shrink: true,
								}}
								variant="outlined"
								onChange={({ target }) => setBatchLabelValue(target.value)}
								value={batchLabelValue}
								defaultValue={batchLabelValue}
								inputProps={{ maxLength: 30 }}
							/>
							{imageUploadField && (
								<Box border={2} style={{ borderStyle: "dashed", padding: "3rem 0", position: "relative", margin: "8px" }} backgroundColor="textSecondary" display="flex" flexDirection="column" alignItems="center">
									<Typography variant="h5">Drag and Drop/Select Files for {imageUploadField.name}</Typography>
									<CloudUploadIcon style={{ fontSize: 80 }} />
									<input accept="image/*" style={{ opacity: "0", position: "absolute", width: "100%", height: "100%", left: "0", right: "0", top: "0", zIndex: "9" }} multiple onChange={onBulkImageUpload} type="file" />
								</Box>
							)}
							{imageUploadValidation && <Typography style={{ marginLeft: "8px", color: "red" }}>{imageUploadValidation}</Typography>}
						</>
					)}
				</div>
			</DialogContent>
			<DialogActions>
				{!submit ? (
					<>
						<Button onClick={isMultiIssue ? resetMultiIssueState : handleClose} color="white" disabled={issuingMultiCredentials}>
							Close
						</Button>
						{!isMultiIssue ? (
							<Button onClick={async () => issueCerts(certData, batchLabelValue, state.selectedCred)} color="warning" disabled={!!error?.length || imageUploadValidation}>
								{state.modalType === "issuemany" ? "Issue" : "Save"}
							</Button>
						) : (
							<>
								<Button onClick={() => onReview("prev")} color="white" disabled={issuingMultiCredentials || isFirst}>
									Review Previous
								</Button>
								{isFinal ? (
									<Button onClick={() => onReview("submit", certData, batchLabelValue)} color="warning" disabled={!!error?.length || imageUploadValidation || issuingMultiCredentials}>
										{issuingMultiCredentials ? "Issuing Multiple Credentials ..." : "Issue Multiple Credentials"}
									</Button>
								) : (
									<Button onClick={() => onReview("next", certData)} color="warning" disabled={!!error?.length || imageUploadValidation}>
										Review Next
									</Button>
								)}
							</>
						)}
					</>
				) : (
					<Spinner />
				)}
			</DialogActions>
		</Dialog>
	);
}
