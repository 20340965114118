import uuid from 'uuid';
import {Typography, Grid} from "@material-ui/core";
import styled from 'styled-components';
import {useEffect, useState} from "react";
import usePresentationHook from "../../hooks/usePresentationHook";
import {useNProgressHook} from "@cti-workspace/helpers";
import {useSnackbar} from "notistack";
import QRCodeViewer from "../../components/QRCodeViewer/QRCodeViewer";

let nonceRefreshInterval;

function PresentationCardVerifier() {

  const List = styled.ol`
    margin: 0 auto;
    padding: 0 1rem;
    list-style: decimal;
    display: inline-block;
    text-align: left;
  `
  const ListItem = styled.li`
    margin: 0.5rem 0;
  `

  const QrWrapper = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 2rem;

    > div {
      display: inline-block;
      margin-bottom: 2rem;
    }
  `
  const Container = styled.div`
    width: 100%;
    padding: 0 1rem;
    text-align: center;
    margin-top: 5rem;
    border-top: 1px solid #dddddd;
    padding-top: 2rem;
    padding-bottom: 3rem;
  `

  const Logo = styled.img`
    max-height: 50px;
  `;

  const [currentUrl, setCurrentUrl] = useState('');
  const [credentialTypes, setCredentialTypes] = useState([]);

  const {getPresentationDefinitions, presentationDefinitions} = usePresentationHook();
  const {progressStart, progressDone} = useNProgressHook();
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    progressStart();
    getPresentationDefinitions().catch((err) => {
      enqueueSnackbar('Could not load presentation definitions. Please try again', {variant: "error"});
    }).finally(() => {
      progressDone();
    })
    return () => {
      clearInterval(nonceRefreshInterval)
    }
  }, []);

  useEffect(() => {
    let presentationRequest = presentationDefinitions?.find((pDef) => pDef?.id === window.location.href.split('?id=')[1]);
    let presentationRequestUrl = presentationRequest?.presentationRequest;
    if (presentationRequestUrl) {
      presentationRequestUrl = decodeURI(presentationRequestUrl);
    }
    setCurrentUrl(presentationRequestUrl)
    setCredentialTypes(presentationRequest?.credentialTypes || [])
  }, [presentationDefinitions])

  useEffect(() => {
    if (currentUrl) {
      nonceRefreshInterval = setTimeout(() => {
        const nonce = currentUrl?.split('nonce=')?.[1]?.split('&')?.[0];
        if (nonce) {
          const updatedUrl = currentUrl.replace(nonce, uuid());
          setCurrentUrl(updatedUrl)
        }
      }, 10000);
    }
  }, [currentUrl]);

  return (
    <Container>
      {/* <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography body1 align={'left'}>
            <Logo src={orgImg}/>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography color="secondary" variant="h5" align={'right'}>
            {orgName}
          </Typography>
        </Grid>
      </Grid> */}
      <Grid container direction="column" justifyContent="center" wrap="nowrap">
        <Typography color="secondary" variant="h5" align={'center'} style={{marginBottom: '1rem', marginTop: '2rem'}}>
          Credentials Requested
        </Typography>
        <Typography body1 align={'center'}>
          Please present the following credentials:
        </Typography>
      </Grid>
      <List>
        {(credentialTypes || []).map(credType => {
          return (
            <ListItem>
              {typeof credType === "string" ? credType : credType?.name}
            </ListItem>
          )
        })}
      </List>
      <QrWrapper>
          {currentUrl && <QRCodeViewer value={currentUrl} size={256}/>}
        <Typography color="secondary" variant="h5" align={'center'}>
          Please scan this QR code from your<br/>Wallet to do so.
        </Typography>
      </QrWrapper>
    </Container>
  )
}

export default PresentationCardVerifier;
