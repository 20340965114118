import { useState, useCallback } from "react";
import Button from "../button/button";
import Theme from "../theme/theme";
import Dialog from "../dialog/dialog";
import { Upload } from "@phosphor-icons/react";
import { DialogContent, DialogActions, DialogTitle } from "../dialog/dialogItems";
import { useDropzone } from "react-dropzone";
import Icon from "../icon/icon";
import styled from "styled-components";
import Typography from "../typography/Typography";

const ChangeText = styled.span`
	color: ${Theme.colors.purple};
`;

const DashedContainer = styled.div`
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='1' stroke-dasharray='5%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	border-radius: 2px;
	min-height: 100px;
	padding: ${({ isPreviewMode }) => (isPreviewMode ? "0.6rem" : "1rem")};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #757575;
`;

export const DropzoneDialog = ({
	dialogTitle,
	open,
	clearOnUnmount = true,
	maxFileSize = 5120000,
	showPreviews = false,
	dropzoneText = "Drag and drop some files here, or click to select files",
	acceptedFiles,
	onClose = () => {},
	displaySavedFiles,
	onSave,
	allowMultipleUploads = false,
	submitButtonText = "Upload",
	onError,
}) => {
	const [filesList, setFilesList] = useState([]);

	const isPreviewMode = showPreviews && filesList.length >= 1;

	const onDrop = useCallback((files) => {
		try {
			files.forEach((file) => {
				const reader = new FileReader();
				const { name, size, type } = file;
				if (file.size > maxFileSize) throw new Error("File size too large, should be less than 5MB");
				reader.onload = function (e) {
					if (allowMultipleUploads) {
						setFilesList((prevList) => [...prevList, { src: e.target.result, name, size, type }]);
					} else {
						setFilesList([{ src: e.target.result, name, size, type }]);
					}
				};
				reader.readAsDataURL(file);
				return file;
			});
		} catch (error) {
			onError(error);
		}
	}, []);
	const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: acceptedFiles });

	const closeDropZone = () => {
		if (filesList.length > 0 && clearOnUnmount) {
			setFilesList([]);
		}
		onClose();
	};

	const getPreviewMode = (file) => {
		let content = <span>{file.name}</span>;
		if (file.type.indexOf("image") >= 0) {
			content = <img style={{ maxWidth: "100px", height: "auto", paddingBottom: "10px" }} src={file.src}></img>;
		}
		if (file.type.indexOf("pdf") >= 0) {
			content = (
				<>
					<Icon name="pdf" size={100} />
					{content}
				</>
			);
		}
		// Need to add the icon for PDF  if added as not mentioned in the design
		// for now just showing the file name
		return content;
	};

	const onUpload = (filesList) => {
		setFilesList([]);
		if (allowMultipleUploads) {
			onSave(filesList);
			return;
		}
		onSave(filesList[0]);
	};

	return (
		<Dialog open={open} maxWidth="xs" borderRadius="8px">
			<DialogTitle>
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					<Typography type="body2">{dialogTitle}</Typography>
					<Button buttonType="icon" icon="cancel" color="primary" iconSize="0.9rem" invert onClick={closeDropZone} />
				</div>
			</DialogTitle>
			<DialogContent>
				<DashedContainer isPreviewMode={isPreviewMode}>
					{isPreviewMode && (
						<div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
							<Typography type="body3" color="caption">
								Preview
							</Typography>
							<Button buttonType="icon" icon="cancel" iconSize="0.7rem" buttonStyle={{ padding: "3px" }} color="danger" onClick={() => setFilesList([])} />
						</div>
					)}
					<div style={{ width: "100%", height: "100%" }} {...getRootProps({ className: "dropzone" })}>
						<input className="input-zone" {...getInputProps()} />
						<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
							{!isPreviewMode && (
								<>
									<Typography type="body3" color="caption" style={{ textAlign: "center" }}>
										{dropzoneText}
									</Typography>
									{/* className="dropzone-content" */}
									<Upload weight="fill" size={20} />
								</>
							)}
							{isPreviewMode && filesList.map((file) => getPreviewMode(file))}
							{isPreviewMode && (
								<Typography color="warning" type="body3" fontWeight="700" style={{ cursor: "pointer" }}>
									CHANGE
								</Typography>
							)}
						</div>
					</div>
				</DashedContainer>
				{displaySavedFiles}
			</DialogContent>
			<DialogActions>
				<Button onClick={closeDropZone} color="white">
					Cancel
				</Button>
				<Button onClick={() => onUpload(filesList)} disabled={filesList.length === 0} color="primary">
					{submitButtonText}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DropzoneDialog;
