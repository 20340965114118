import { useMemo } from "react";
import PropTypes from "prop-types";

import "./capabilities.css";
import { userStore } from "../../store/userStore";

const CapabilityBoundary = ({ render, capability, hide = false, showToolTip = true }) => {
	const capabilities = userStore((state) => state.capabilities);

	const isCapable = useMemo(() => {
		if (capabilities && capability) {
			return capability in capabilities;
		}
		return false;
	}, [capabilities, capability]);

	const tooltip = useMemo(() => {
		return `You don't have permission to ${capability.replace(/_/g, " ").toLowerCase()}`;
	}, [capability]);

	if (isCapable || process.env.NODE_ENV === "test") {
		return render();
	} else if (hide) {
		return null;
	} else if (showToolTip) {
		return (
			<span title={tooltip} className="capability-boundary">
				{render(true)}
			</span>
		);
	} else {
		return render(true, tooltip);
	}
};

CapabilityBoundary.propTypes = {
	render: PropTypes.func.isRequired,
	capability: PropTypes.string.isRequired,
	hide: PropTypes.bool,
};

export default CapabilityBoundary;
