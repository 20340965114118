import { useState, useEffect, useCallback } from "react";
import { ORGANIZATIONS_PREFIX, findUniqueObject, getApi, putApi, delApi, getObjectValue } from "@cti-workspace/helpers";
import { useSnackbar } from "notistack";
import { isPermissionUnauthorizedError } from "../../routes/admin/userAccounts/permissionUtils";

const uploadInnerSchema = {
	title: "upload contact row",
	type: "array",
	items: { type: "string" },
	maxItems: 4,
	minItems: 4,
};

const uploadSchema = {
	title: "Upload Contacts",
	type: "array",
	items: uploadInnerSchema,
};

const apiSchema = {
	title: "api Contacts",
	type: "array",
	items: {
		type: "object",
		required: ["contact", "firstName", "lastName", "type"],
	},
};

const contactTagsApiSchema = {
	title: "api Contacts Tags",
	type: "object",
};

const contactTagsStateSchema = {
	title: "api Contacts Tags",
	type: "array",
};

const stateSchema = apiSchema;

const useContactHook = ({ account }) => {
	const { enqueueSnackbar } = useSnackbar();

	const [contacts, setContacts] = useState([]);
	const [contactsTags, setContactsTags] = useState([]);
	const protectedSetState = useCallback(async (data, stateFunction, schema) => {
		stateFunction(data);
	});

	useEffect(() => {
		const { roles } = account;
		if (!(roles.length === 1 && roles[0] === "holder")) {
			getContacts();
			getContactsTags();
		}
	}, []);

	const deleteContacts = async (contactsToDelete) => {
		try {
			const data = await delApi(`/${ORGANIZATIONS_PREFIX}/deleteContacts`, contactsToDelete);
			// API response
			if (data) {
				setContacts(contacts.filter((contact) => !data.deleted.includes(contact.contact)));
			} else {
				throw data;
			}
		} catch (error) {
			console.log(error);
			if (error) {
				enqueueSnackbar(`Error type:${getObjectValue(error, "code")} message: ${getObjectValue(error, "message")}`, { variant: "error" });
			}
			if (error[0]) {
				// Each contact should have all four attributes
				console.log(getObjectValue(error, "[0]"));
				enqueueSnackbar(`Error type:${getObjectValue(error, "[0].keyword")} message: ${getObjectValue(error, "[0].message")}`, { variant: "error" });
			}
			throw error;
		}
	};

	const getContactsTags = async () => {
		try {
			let data = await getApi(`/${ORGANIZATIONS_PREFIX}/getContactTags`);
			if (data) {
				if (data) {
					protectedSetState(data?.tags, setContactsTags, contactTagsStateSchema);
				} else {
					throw data;
				}
			} else {
				throw data;
			}
		} catch (error) {
			console.log(error);
			if (error) {
				const type = getObjectValue(error, "code");
				const message = getObjectValue(error, "message");
				if (!isPermissionUnauthorizedError(type, message)) {
					enqueueSnackbar(`Error type:${type} message: ${message}`, { variant: "error" });
				}
			}
			if (error[0]) {
				// Each contact should have all four attributes
				console.log(getObjectValue(error, "[0]"));
				const type = getObjectValue(error, "[0].keyword");
				const message = getObjectValue(error, "[0].message");
				if (!isPermissionUnauthorizedError(type, message)) {
					enqueueSnackbar(`Error type:${type} message: ${message}`, { variant: "error" });
				}
			}
			throw error;
		}
	};

	const getContacts = async (tags = null) => {
		try {
			let data = await getApi(`/${ORGANIZATIONS_PREFIX}/getContacts/${(tags && "?tags=" + tags) || ""}`);
			if (data) {
				if (data) {
					protectedSetState(data, setContacts, stateSchema);
				} else {
					return data;
				}
			} else {
				return data;
			}
		} catch (error) {
			let throwError = false;
			if (error[0]) {
				// Each contact should have all four attributes
				const type = getObjectValue(error, "[0].keyword");
				const message = getObjectValue(error, "[0].message");
				if (!isPermissionUnauthorizedError(type, message)) {
					throwError = true;
					error.message = `Error type:${type} message: ${message}`;
				}
			}
			if (throwError) {
				throw error;
			}
		}
	};

	const putContacts = async (contactInput, tags) => {
		// converting input to uploadSchema

		let flattenedContacts = [];
		if (typeof contactInput === "array") {
			flattenedContacts = contactInput.filter((x) => !!x);
		} else if (typeof contactInput === "object") {
			Object.keys(contactInput).map((key, i) => {
				flattenedContacts.push(Object.values(getObjectValue(contactInput, key)).filter((x) => !!x));
			});
		}

		try {
			// remove duplicates before upload.
			let newContacts = [...flattenedContacts];

			if (newContacts.length > 1) {
				newContacts = findUniqueObject(flattenedContacts, function (e) {
					return e[2];
				});
			}

			let data = await putApi(`/${ORGANIZATIONS_PREFIX}/addContacts`, { contacts: newContacts, tags: tags });
			if (data) {
				// remove duplicates between state and api response, should be caught by api..
				// the returned data = { newContacts, duplicates }
				if (data?.duplicates?.length > 0) {
					enqueueSnackbar(`${data.duplicates.length} duplicate(s) not added: (${data.duplicates.map((d) => d[2])})`, { variant: "warning" });
				}

				let uniqueData = findUniqueObject([...data.newContacts, ...contacts], function (e) {
					return e.contact;
				});

				await protectedSetState(uniqueData, setContacts, stateSchema);
				if (tags?.length > 0) getContactsTags();
			} else {
				throw data;
			}
		} catch (error) {
			console.log(error);
			if (error[0].keyword === "minItems" || error[0].keyword === "maxItems") {
				// Each contact should have all four attributes
				error.message = `Error Row:${getObjectValue(error, "[0].dataPath")} type: ${getObjectValue(error, "[0].message")}`;
			}
			throw error;
		}
	};

	const selectAllContacts = (checked) => {
		const selectedContacts = contacts.map((contact) => ({ ...contact, tableData: { ...contact.tableData, checked: checked } }));
		setContacts(selectedContacts);
	};

	return {
		contacts,
		deleteContacts,
		getContacts,
		putContacts,
		selectAllContacts,
		contactsTags,
	};
};

export default useContactHook;
