import { useState } from "react";
import styled from "styled-components";
import { X, CaretDown, CaretUp } from "@phosphor-icons/react";
import { useSnackbar } from "notistack";
import { Button, Dialog, DialogContent, Typography, TextField } from "@cti-workspace/ui";
import { getObjectValue, usePathwaysHook } from "@cti-workspace/helpers";
import ContactTable from "../../../components/GenericTable";
import { getRecipient, addRecipient, handleRecipientInformationChange, removeRecipient, getRecipientInformationFieldErrors } from "../utils/inviteUtils";

const DialogTitle = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.2rem;
	align-items: center;
`;

const DialogContentWrapper = styled.div`
	padding: 0.5rem;
	min-width: 0;
	overflow-y: auto;
`;

const RecipientInformationContainer = styled.div`
	display: flex;
	gap: 1.3rem;
	margin-bottom: 0.5rem;
`;

const HalfWidthField = styled.div`
	flex: 1;
`;

const RecipientInformationField = styled.div`
	display: flex;
	flex-direction: column;
`;

const AddMoreContainer = styled.div`
	border-bottom: 1px solid #f0f0f0;
	padding-bottom: 1rem;
	margin-bottom: 0.5rem;
	display: flex;
	justify-content: right;
`;

const SendContainer = styled.div`
	border-top: 1px solid #f0f0f0;
	padding-top: 1rem;
	display: flex;
	justify-content: right;
`;

const PastRecipientsContainer = styled.div`
	max-height: 100px;
	overflow-y: auto;
`;

const RemoveRecipient = styled.div`
	padding-top: ${(props) => (props.isFirstRow ? "1.9" : "0.6")}rem;
`;

const RecipientRecord = ({ recipient: { firstName, lastName, email }, index, onRecipientInformationChange, onRemoveRecipient, fieldErrors }) => (
	<RecipientInformationContainer>
		<HalfWidthField>
			<RecipientInformationContainer>
				<HalfWidthField>
					<RecipientInformationField>
						{index === 0 && <Typography>First Name</Typography>}
						<TextField
							error={getObjectValue(fieldErrors, `${index}.firstName`)}
							size="small"
							placeholder="First Name"
							style={{ borderRadius: "1rem" }}
							value={firstName}
							onChange={(e) => onRecipientInformationChange(index, "firstName", e.target.value)}
						/>
					</RecipientInformationField>
				</HalfWidthField>
				<HalfWidthField>
					<RecipientInformationField>
						{index === 0 && <Typography>Last Name</Typography>}
						<TextField error={getObjectValue(fieldErrors, `${index}.lastName`)} size="small" placeholder="Last Name" value={lastName} onChange={(e) => onRecipientInformationChange(index, "lastName", e.target.value)} />
					</RecipientInformationField>
				</HalfWidthField>
			</RecipientInformationContainer>
		</HalfWidthField>
		<HalfWidthField>
			<RecipientInformationField>
				{index === 0 && <Typography>Email</Typography>}
				<TextField error={getObjectValue(fieldErrors, `${index}.email`)} size="small" placeholder="Email" value={email} onChange={(e) => onRecipientInformationChange(index, "email", e.target.value)} />
			</RecipientInformationField>
		</HalfWidthField>
		<RemoveRecipient isFirstRow={index === 0}>
			<X size={18} color="#202124" style={{ cursor: "pointer" }} onClick={() => onRemoveRecipient(index)} />
		</RemoveRecipient>
	</RecipientInformationContainer>
);

const InviteOne = ({ issuingPathwayId, setIssuingPathwayId, pastRecipients, fetchPathwayPastRecipients }) => {
	const [showPastRecipients, setShowPastRecipients] = useState(false);
	const [selectedPastRecipients, setSelectedPastRecipients] = useState([]);
	const [recipients, setRecipients] = useState([getRecipient()]);
	const [recipientFieldErrors, setRecipientFieldErrors] = useState([]);
	const [isInviting, setIsInviting] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const { inviteToPathway } = usePathwaysHook();

	const onCloseModal = () => {
		setSelectedPastRecipients([]);
		setRecipients([getRecipient()]);
		setRecipientFieldErrors([]);
		setIssuingPathwayId(null);
	};

	const onAddMoreRecipients = () => {
		setRecipients(addRecipient(recipients));
	};

	const onRecipientInformationChange = (index, key, value) => {
		setRecipients(handleRecipientInformationChange(recipients, index, key, value));
	};

	const onRemoveRecipient = (index) => {
		setRecipients(removeRecipient(recipients, index));
	};

	const onClickSend = async () => {
		const fieldErrors = getRecipientInformationFieldErrors(recipients);
		const allRecipients = [...selectedPastRecipients, ...recipients];
		if (fieldErrors.length) {
			setRecipientFieldErrors(fieldErrors);
			enqueueSnackbar(`Please enter the required information of the recipients.`, { variant: "error" });
		} else if (!allRecipients.length) {
			enqueueSnackbar(`Please select at least one recipient.`, { variant: "error" });
		} else {
			setRecipientFieldErrors([]);
			setIsInviting(true);

			try {
				await inviteToPathway(issuingPathwayId, allRecipients);
				enqueueSnackbar(`${allRecipients.length} recipients were successfully invited to the pathway.`, { variant: "success" });
				fetchPathwayPastRecipients();
				onCloseModal();
			} catch (e) {
				enqueueSnackbar(`Failed to invite the selected recipients${e.message ? `. ${e.message}` : ""}. Please try again.`, { variant: "error" });
			} finally {
				setIsInviting(false);
			}
		}
	};

	return (
		<Dialog onClose={onCloseModal} open={!!issuingPathwayId} maxWidth="sm" borderRadius="0.8rem">
			<DialogContentWrapper>
				<DialogContent>
					<DialogTitle>
						<Typography type="subtitle">Recipient Details</Typography>
						<X size={20} color="#757575" style={{ cursor: "pointer" }} onClick={onCloseModal} />
					</DialogTitle>
					{recipients.map((recipient, index) => (
						<RecipientRecord recipient={recipient} key={`recipient_${index}`} index={index} onRecipientInformationChange={onRecipientInformationChange} onRemoveRecipient={onRemoveRecipient} fieldErrors={recipientFieldErrors} />
					))}
					<AddMoreContainer>
						<Button onClick={onAddMoreRecipients} color="borderedGrey" size="small" invert leftIcon="plus" buttonStyle={{ height: "28px", fontSize: "11px", fontWeight: "600", borderRadius: "0.3rem" }}>
							ADD {recipients.length ? "MORE" : "RECIPIENT"}
						</Button>
					</AddMoreContainer>
					<DialogTitle>
						<Typography type="subtitle">Past Recipients</Typography>
						<div onClick={() => setShowPastRecipients(!showPastRecipients)} style={{ cursor: "pointer" }}>
							{showPastRecipients ? <CaretUp size={24} weight="fill" /> : <CaretDown size={24} weight="fill" />}
						</div>
					</DialogTitle>
					{showPastRecipients && (
						<PastRecipientsContainer>
							<ContactTable
								config={{
									headers: [
										{ label: "First Name", prop: "firstName", sort: true },
										{ label: "Last Name", prop: "lastName", sort: true },
										{ label: "Email", prop: "email", sort: true },
									],
								}}
								data={pastRecipients}
								searchPlaceholder="Search Recipients"
								// search={allowSearchingContacts ? ["firstName", "lastName", "contact"] : []}
								dense
								checkbox
								emptyText="No past recipients"
								pagination
								onCheckBoxClickedRows={setSelectedPastRecipients}
								header={
									selectedPastRecipients && selectedPastRecipients.length > 0 ? (
										<div style={{ display: "flex", alignItems: "center" }}>
											<Typography variant="subtitle1">
												{selectedPastRecipients.length} recipient{selectedPastRecipients.length > 1 ? "s" : ""} selected
											</Typography>
										</div>
									) : (
										<></>
									)
								}
							/>
						</PastRecipientsContainer>
					)}
					<SendContainer>
						<Button disabled={isInviting} onClick={onClickSend} color="secondary" size="small" invert buttonStyle={{ width: "160px", height: "36px", borderRadius: "0.4rem" }}>
							Send
						</Button>
					</SendContainer>
				</DialogContent>
			</DialogContentWrapper>
		</Dialog>
	);
};

export default InviteOne;
