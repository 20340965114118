const CAPABILITIES = {
	ACCESS_CREDENTIAL_CATALOG: "ACCESS_CREDENTIAL_CATALOG",
	SEARCH_CREDENTIAL_DEFINITIONS: "SEARCH_CREDENTIAL_DEFINITIONS",
	ACCESS_CREDENTIAL_DEFINITIONS: "ACCESS_CREDENTIAL_DEFINITIONS",
	VIEW_CREDENTIAL_DEFINITIONS: "VIEW_CREDENTIAL_DEFINITIONS",
	CREATE_CREDENTIAL_DEFINITION: "CREATE_CREDENTIAL_DEFINITION",
	EDIT_CREDENTIAL_DEFINITION: "EDIT_CREDENTIAL_DEFINITION",
	COPY_CREDENTIAL_DEFINITION: "COPY_CREDENTIAL_DEFINITION",
	DELETE_CREDENTIAL_DEFINITION: "DELETE_CREDENTIAL_DEFINITION",
	BULK_CREATE_CREDENTIAL_DEFINITIONS: "BULK_CREATE_CREDENTIAL_DEFINITIONS",
	ATTACH_EMAIL_TEMPLATE_TO_CREDENTIAL_DEFINITIONS: "ATTACH_EMAIL_TEMPLATE_TO_CREDENTIAL_DEFINITIONS",
	BULK_ISSUE_CREDENTIALS: "BULK_ISSUE_CREDENTIALS",
	ISSUE_CREDENTIALS: "ISSUE_CREDENTIALS",
	VIEW_CREDENTIAL_FILTER_BY_BATCH: "VIEW_CREDENTIAL_FILTER_BY_BATCH",
	VIEW_CREDENTIAL_DOWNLOAD_BY_BATCH: "VIEW_CREDENTIAL_DOWNLOAD_BY_BATCH",
	ISSUE_CREDENTIAL_ADD_CONTACT: "ISSUE_CREDENTIAL_ADD_CONTACT",
	ISSUE_CREDENTIAL_DELETE_CONTACTS: "ISSUE_CREDENTIAL_DELETE_CONTACTS",
	ISSUE_CREDENTIAL_SEARCH_CONTACTS: "ISSUE_CREDENTIAL_SEARCH_CONTACTS",
	ISSUE_CREDENTIAL_FILTER_CONTACTS_BY_TAG: "ISSUE_CREDENTIAL_FILTER_CONTACTS_BY_TAG",
	ACCESS_ISSUED_CREDENTIALS: "ACCESS_ISSUED_CREDENTIALS",
	SEARCH_ISSUED_CREDENTIALS_FOR_ORG: "SEARCH_ISSUED_CREDENTIALS_FOR_ORG",
	COPY_URL_ISSUED_CREDENTIALS_FOR_ORG: "COPY_URL_ISSUED_CREDENTIALS_FOR_ORG",
	VIEW_ISSUED_CREDENTIALS_FOR_ORG: "VIEW_ISSUED_CREDENTIALS_FOR_ORG",
	RESEND_ISSUED_CREDENTIALS_FOR_ORG: "RESEND_ISSUED_CREDENTIALS_FOR_ORG",
	REVOKE_ISSUED_CREDENTIALS_FOR_ORG: "REVOKE_ISSUED_CREDENTIALS_FOR_ORG",
	DOWNLOAD_QR_CODE_ISSUED_CREDENTIALS_FOR_ORG: "DOWNLOAD_QR_CODE_ISSUED_CREDENTIALS_FOR_ORG",
	ACCESS_PROFILE: "ACCESS_PROFILE",
	VIEW_PROFILE_BASIC_INFO: "VIEW_PROFILE_BASIC_INFO",
	VIEW_PROFILE_USER_OPTIONS: "VIEW_PROFILE_USER_OPTIONS",
	VIEW_PROFILE_CONTACT_LIST: "VIEW_PROFILE_CONTACT_LIST",
	PROFILE_ADD_CONTACT: "PROFILE_ADD_CONTACT",
	PROFILE_DELETE_CONTACTS: "PROFILE_DELETE_CONTACTS",
	PROFILE_SEARCH_CONTACTS: "PROFILE_SEARCH_CONTACTS",
	PROFILE_FILTER_CONTACTS_BY_TAG: "PROFILE_FILTER_CONTACTS_BY_TAG",
	ACCESS_ANALYTICS: "ACCESS_ANALYTICS",
	ANALYTICS_FILTER_BY_DATE_RANGE: "ANALYTICS_FILTER_BY_DATE_RANGE",
	ACCESS_ADVANCE_ANALYTICS: "ACCESS_ADVANCE_ANALYTICS",
	DOWNLOAD_ADVANCE_ANALYTICS: "DOWNLOAD_ADVANCE_ANALYTICS",

	OVERRIDE: "OVERRIDE",
};

export const CAPABILITIES_ERROR_MESSAGES = {
	UNAUTHORIZED: "Unauthorized",
	NOT_ALLOWED: "You are not allowed for this action.",
};

export default CAPABILITIES;
