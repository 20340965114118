import { useContext } from "react";
import { fabric } from "fabric-pure-browser";
import { FabricContext } from "../../context/FabricContext";
import { Button } from "@cti-workspace/ui";
import { getObjectValue } from "@cti-workspace/helpers";

const supportedImageTypes = ["image/png", "image/apng", "image/bmp", "image/gif", "image/x-icon", "image/jpeg"];

function FabricAddBadge({ badges, id, enableMultiPageCertificate }) {
	const { canvas } = useContext(FabricContext);
	const defaultBadeUrl = "https://ipwru.s3.amazonaws.com/prod/seal.png";

	const onAddBadge = () => {
		let imageUrl = defaultBadeUrl;
		if (badges.length > 0) {
			imageUrl = badges[0].value;
		}
		fabric.Image.fromURL(imageUrl, function (newImg) {
			newImg.scaleToWidth(100);
			const activeCanvas = enableMultiPageCertificate ? getObjectValue(canvas, id) : canvas;
			activeCanvas.add(newImg);
		});
	};

	return (
		<Button buttonStyle={{ padding: "0px", display: "flex", alignItems: "center" }} leftIcon="plus" popover="Badge" style={{ borderRight: "0.5px solid black", padding: "5px 10px" }} noBorder color="white" onClick={onAddBadge}>
			Add badge
		</Button>
	);
}

export default FabricAddBadge;
