import Typography from "@material-ui/core/Typography";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Popover from "@material-ui/core/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { FormattedMessage } from "react-intl";
import { Button } from '@cti-workspace/ui';
import { Box } from '@cti-workspace/ui';

export default function TitleWithCloseButton({ title, variant, onCLose, help }) {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
			}}
		>
			<Typography variant={variant} padding={2}>
				{title}
			</Typography>

			<div style={{ alignSelf: "right" }}>
				{help && (
					<PopupState variant="popover" popupId={`${title}-help-popover`}>
						{(popupState) => (
							<>
								<IconButton {...bindTrigger(popupState)}>
									<Tooltip title="Help" placement="left" arrow>
										<HelpOutlineIcon />
									</Tooltip>
								</IconButton>
								<Popover
									{...bindPopover(popupState)}
									anchorOrigin={{
										vertical: "center",
										horizontal: "left",
									}}
									transformOrigin={{
										vertical: "center",
										horizontal: "right",
									}}
								>
									<Box p={2}>
										<FormattedMessage
											id={`${help}.headerWithLine`}
											values={{
												p: (...chunks) => (
													<Typography component={"span"} display={"block"}>
														{chunks}
													</Typography>
												),
												cta: (...chunks) => (
													<Typography style={{ fontWeight: 600 }} component={"span"} display={"inline"} color={"secondary"}>
														{chunks}
													</Typography>
												),
											}}
										>
											{(txt) => (
												<Typography component={"span"} variant={"body1"}>
													{txt}
												</Typography>
											)}
										</FormattedMessage>
									</Box>
								</Popover>
							</>
						)}
					</PopupState>
				)}

				<Button buttonType="icon" icon="cancel" color="danger" invert iconSize="1.2rem" noBorder buttonTransparent buttonStyle={{ alignSelf: "right" }} onClick={onCLose} />

				{/* <Button style={{ alignSelf: "right" }} onClick={onCLose}>
					<CloseIcon />
				</Button> */}
			</div>
		</div>
	);
}
