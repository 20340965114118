import { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ShowOnScroll from "../components/ShowOnScroll";
import cookie from "js-cookie";
import AppMainBar from "./apphome/AppBar";
import SideDrawer from "./apphome/SideDrawer";
import SimpleAppBar from "./apphome/SimpleAppBar";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexGrow: 1,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(1),
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		minHeight: "100vh",
		// marginRight: -drawerWidth,
	},
	menuShift: {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
	helpShift: {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: 0,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
		alignItems: "flex-start",
		overflow: "auto",
	},
	simpleContent: {
		flexGrow: 1,
		padding: theme.spacing(1),
	},
	drawerOpenScrollTopBtn: {
		left: drawerWidth + theme.spacing(1),
		transition: theme.transitions.create("left", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerCloseScrollTopBtn: {
		transition: theme.transitions.create("left", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		left: theme.spacing(8) + 1,
	},
}));

const RouteWrapper = (props) => {
	const { component: Element, routeType, userLogOut, setUserState, showAppBar = true } = props;
	const DRAWER_STATE = "drawer";
	const HELP_DRAWER_STATE = "help_drawer";
	const classes = useStyles();
	const theme = useTheme();
	const drawerState = cookie.get(DRAWER_STATE) == "false" ? false : true;
	const [menuOpen, setMenuOpen] = useState(drawerState);

	useEffect(() => {
		cookie.set(DRAWER_STATE, menuOpen);
	}, [menuOpen]);

	return routeType === "route" ? (
		<div className={classes.root}>
			<AppMainBar {...props} menuOpen={menuOpen} openMenuClick={() => setMenuOpen(true)} />
			<SideDrawer {...props} open={menuOpen} drawerClick={(drawerState) => (drawerState ? setMenuOpen(true) : setMenuOpen(false))} />
			<main
				className={clsx(classes.content, {
					[classes.menuShift]: menuOpen,
				})}
				id="routes-main-el"
				style={{ display: "flex" }}>
				<div style={{ offset: theme.mixins.toolbar }} />
				<Element userLogOut={userLogOut} setUserState={setUserState} />
			</main>
			<ShowOnScroll
				className={clsx({
					[classes.drawerOpenScrollTopBtn]: menuOpen,
					[classes.drawerCloseScrollTopBtn]: !menuOpen,
				})}
			/>
		</div>
	) : (
		<div className={classes.root}>
			{showAppBar && <SimpleAppBar {...props} />}
			<main className={showAppBar ? classes.simpleContent : {}}>
				<div style={{ offset: theme.mixins.toolbar }} />
				<Element userLogOut={userLogOut} />
			</main>
		</div>
	);
};

RouteWrapper.propTypes = {};

export default RouteWrapper;
