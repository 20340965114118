const tabs = ["credentials", "pathways", "invitations"];

export const updateTabUrl = (tabIndex, navigate) => {
	const { origin, pathname } = window.location;
	const { [tabIndex]: selectedTab } = tabs;
	const newSearch = tabIndex > 0 ? `?view=${selectedTab}` : "";
	const newUrl = `${pathname}${newSearch}`;
	navigate(newUrl);
};

export const getTabIndex = () => {
	try {
		const tabIndex = tabs.indexOf(window.location.search.split("view=")[1]);
		return tabIndex === -1 ? 0 : tabIndex;
	} catch (e) {
		console.error(e);
		return 0;
	}
};
