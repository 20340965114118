import { useLocation, Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import errorImage from "../../assets/images/errorImage.png";

function ErrorRoute() {
	let location = useLocation();

	return (
		<div
			style={{
				display: "flex",
				flex: "auto",
				height: "100vh",
				color: "white",
				justifyContent: "center",
				alignContent: "space-around",
				alignItems: "center",
				flexDirection: "column",
				background: "#FF991F",
			}}
		>
			<Typography variant={"h6"}>
				Make sure you’ve got the right link, or you could try going to <Link to="/">homepage</Link>
			</Typography>
			<img style={{ width: "100%" }} src={errorImage} alt="404" />
			<Typography variant={"h6"}>
				{" "}
				No match for path <code>{location.pathname}</code>
			</Typography>
		</div>
	);
}

export default ErrorRoute;
