import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import HomeIcon from "@material-ui/icons/Home";
import CAPABILITIES from "../capabilities";
import { Presentation, QrCode, UserCirclePlus } from "@phosphor-icons/react";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import PathwayIcon from "./pathways/components/PathwayIcon";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import PersonIcon from "@material-ui/icons/Person";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import NFTIcon from "./nft/NFTIcon";
import { canView } from "@cti-workspace/helpers";
import { isCapable } from "./admin/userAccounts/permissionUtils";

const privateRoutes = [
	{
		level: 2,
		path: "/admin",
		name: "SuperAdmin",
		type: "route",
		icon: <SupervisorAccountIcon />,
		roles: ["admin"],
	},
	{
		level: 1,
		path: "/orgAdmin",
		name: "Admin",
		type: "route",
		icon: <SupervisorAccountIcon />,
		roles: ["orgAdmin", "admin"],
	},
	{
		level: 1,
		path: "/home",
		name: "Credential Catalog",
		type: "route",
		icon: <HomeIcon />,
		capability: CAPABILITIES.ACCESS_CREDENTIAL_CATALOG,
		roles: ["issuer", "verifier", "orgAdmin", "admin"],
	},
	{
		level: 1,
		path: "/authorizations",
		name: "Authorizations",
		type: "route",
		// TODO: capability and roles accordingly
		icon: <UserCirclePlus size={24} />,
		// capability: CAPABILITIES.ACCESS_CREDENTIAL_CATALOG,
		roles: ["issuer", "verifier", "orgAdmin", "admin"],
	},
	{
		level: 1,
		path: "/authorizations/authorize",
		name: "Authorizations",
		type: "action",
		// capability: CAPABILITIES.ACCESS_CREDENTIAL_CATALOG,
		roles: ["issuer", "verifier", "orgAdmin", "admin"],
	},
	{
		level: 3,
		path: "/presentation-catalog",
		type: "route",
		name: "Presentation Catalog",
		icon: <Presentation size={24} />,
		roles: ["verifier", "admin"],
	},
	{
		level: 3,
		path: "/verify-credentials",
		type: "route",
		name: "Verify Credentials",
		icon: <QrCode size={24} />,
		roles: ["verifier", "admin"],
	},
	{
		level: 1,
		path: "/issuedcredentials",
		name: "Issued Credentials",
		type: "route",
		icon: <CardMembershipIcon />,
		capability: CAPABILITIES.ACCESS_ISSUED_CREDENTIALS,
		roles: ["issuer", "orgAdmin", "admin"],
	},
	{
		level: 1,
		path: "/pathways",
		type: "route",
		name: "Pathways",
		icon: <PathwayIcon />,
		roles: ["issuer", "orgAdmin", "admin"],
		hideTopNav: true,
	},
	{
		level: 1,
		path: "/pathways/new",
		type: "action",
		name: "Pathways",
		icon: <PathwayIcon />,
		roles: ["issuer", "orgAdmin", "admin"],
		hideTopNav: true,
	},
	{
		level: 1,
		path: "/pathway/*",
		type: "designer",
		name: "Pathways",
		icon: <PathwayIcon />,
		roles: ["issuer", "orgAdmin", "admin"],
		hideTopNav: true,
	},
	{
		level: 0,
		path: "/wallet",
		name: "Wallet",
		type: "route",
		icon: <AccountBalanceWalletIcon />,
		roles: ["holder", "admin"],
	},
	{
		level: 0,
		path: "/wallet/pathways",
		name: "Pathways",
		type: "subRoute",
		icon: <AccountBalanceWalletIcon />,
		roles: ["holder", "admin"],
	},
	{
		level: 0,
		path: "/wallet/invitations",
		name: "Invitations",
		type: "subRoute",
		icon: <AccountBalanceWalletIcon />,
		roles: ["holder", "admin"],
	},
	{
		level: 0,
		path: "/wallet/pathway/*",
		name: "Pathway view",
		type: "designer",
		roles: ["holder", "admin"],
		hideTopNav: true,
	},
	{
		level: 1,
		path: "/profile",
		name: "Profile",
		type: "route",
		icon: <PersonIcon />,
		// capability: CAPABILITIES.ACCESS_PROFILE,
		roles: ["admin", "orgAdmin", "issuer", "holder", "verifier"],
	},
	{
		level: 0,
		path: "/analytics",
		name: "Analytics",
		type: "route",
		icon: <EqualizerIcon />,
		roles: ["issuer", "orgAdmin", "admin"],
	},
	{
		level: 1,
		path: "/credentialDefinition/new",
		type: "action",
		roles: ["issuer", "orgAdmin", "admin"],
	},
	{
		level: 1,
		path: "/credentialDefinition/:id/edit",
		type: "action",
		roles: ["issuer", "orgAdmin", "admin"],
	},
	{
		level: 1,
		path: "/credentialDefinition/:id/view",
		type: "action",
		roles: ["issuer", "orgAdmin", "admin"],
	},
	// define-presentation/new
	{
		level: 1,
		path: "/define-presentation/new",
		type: "action",
		roles: ["verifier", "admin"],
	},
	{
		level: 3,
		path: "/define-presentation/:id/edit",
		type: "action",
		roles: ["verifier", "admin"],
	},
	{
		level: 3,
		path: "verify-presentation",
		type: "action",
		roles: ["verifier", "admin"],
	},
	{
		level: 1,
		path: "/credentialDefinition/:id/design",
		type: "action",
		roles: ["issuer", "orgAdmin", "admin"],
	},
	{
		level: 1,
		path: "/credentialDefinition/:id/config",
		type: "action",
		roles: ["issuer", "orgAdmin", "admin"],
	},
	{
		level: 1,
		path: "/credentialIssue/:id/new",
		type: "action",
		roles: ["issuer", "orgAdmin", "admin"],
	},
	{
		level: 1,
		path: "/credentialIssue/:id/new",
		type: "action",
		roles: ["issuer", "orgAdmin", "admin"],
	},
	{
		level: 0,
		path: "/nft",
		name: "NFT Catalog",
		type: "route",
		icon: <NFTIcon />,
		roles: ["issuer", "orgAdmin", "admin"],
	},
	{
		level: 0,
		path: "/nft/mint",
		type: "action",
		roles: ["issuer", "admin"],
	},
];

export const routesPerAccountType = ({ roles, capabilities }) => {
	const canViewAnalytics = canView("analytics", true) && isCapable(capabilities, CAPABILITIES.ACCESS_ANALYTICS);
	const canViewNFT = canView("enableNft", true);
	const canViewPathways = canView("enablePathways", true);
	const canViewAuthorization = canView("enableAuthorization", true);

	// TODO: set canView back
	let privateRoutesToView = [];
	privateRoutes.forEach((privateRoute) => {
		if (!privateRoute.capability || isCapable(capabilities, privateRoute.capability)) {
			privateRoutesToView.push(privateRoute);
		}
	});
	if (!canViewAnalytics) {
		privateRoutesToView = privateRoutesToView.filter((item) => item.path !== "/analytics");
	}

	if (!canViewNFT) {
		privateRoutesToView = privateRoutesToView.filter((item) => !item.path.includes("/nft"));
		privateRoutesToView = privateRoutesToView.filter((item) => !item.path.includes("/nft/mint"));
	}

	if (!canViewPathways) {
		privateRoutesToView = privateRoutesToView.filter((item) => item.path !== "/pathways");
		privateRoutesToView = privateRoutesToView.filter((item) => item.path !== "/pathways/new");
		privateRoutesToView = privateRoutesToView.filter((item) => item.path !== "/pathway/*");
	}

	if (!canViewAuthorization) {
		privateRoutesToView = privateRoutesToView.filter((item) => item.path !== "/authorizations");
		privateRoutesToView = privateRoutesToView.filter((item) => item.path !== "/authorizations/authorize");
	}

	// console.log(!canViewPathways);
	// console.log(privateRoutesToView);

	const allowedPRoutes = privateRoutesToView.filter((item) => (item.roles || []).filter((item) => roles?.includes(item)).length > 0);
	const deniedPRoutes = privateRoutesToView.filter((item) => !(item.roles || []).filter((item) => roles?.includes(item)).length > 0);

	// console.log(deniedPRoutes);

	return { allowedPRoutes, deniedPRoutes };

	// all the code bellow is unreachable [NAGA - why is this here] - removed old code
};
