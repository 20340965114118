import styled from "styled-components";
import { Typography } from "@cti-workspace/ui";
import Theme from "../theme/theme";
import { useEffect, useState } from "react";

const TabsContainer = styled.div``;

const TabsHeaderContainer = styled.div`
	display: flex;
	border-bottom: 1px solid ${Theme.colors.gray1};
`;

const TabHeader = styled.div`
	padding: 0.6rem 1.1rem;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	margin-bottom: -1px;
	border-color: ${Theme.colors.gray1} !important;
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
	opacity: ${({ disabled }) => (disabled ? "0.7" : "1")};
	& * {
		cursor: pointer;
		font-weight: bold;
		text-transform: uppercase;
		font-size: 0.9rem;
		${({ active }) => (active ? `color: ${Theme.colors.purple}` : `color: ${Theme.colors.gray1};`)}
	}
	${({ active }) =>
		active
			? `
        border-color: ${Theme.colors.purple} !important;
        background-color: ${Theme.colors.purple}10;
    `
			: ""}
`;

const TabContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: relative;
	${({ animationDirection }) => (animationDirection === "right" ? `right: 0%;` : `left: 0%;`)}
	right: 0%;
	animation-name: ${({ animationDirection }) => (animationDirection === "right" ? "fromLeft" : "fromRight")};
	animation-duration: 0.2s;
	@keyframes fromRight {
		from {
			left: 100%;
		}
		to {
			left: 0%;
		}
	}
	@keyframes fromLeft {
		from {
			right: 100%;
		}
		to {
			right: 0%;
		}
	}
`;

export function Tabs({ options = [], activeStep = 0, tabContainerStyles = {}, showHeader = true, onTabChange }) {
	const [active, setActive] = useState(activeStep);
	const [prevTab, setPrevTab] = useState(0);
	const optionsHeader = options.filter((item) => item.title);

	useEffect(() => {
		setActive(activeStep);
		setPrevTab(active);
	}, [activeStep]);

	return (
		<TabsContainer>
			{showHeader && (
				<TabsHeaderContainer>
					{optionsHeader.map((item, index) => (
						<TabHeader
							key={item.title}
							active={active === index}
							disabled={item.disabled}
							style={{
								border: `1px solid`,
								...(optionsHeader.length !== 1 && index > 0 && index < optionsHeader.length
									? {
											borderLeft: `0.5px solid`,
											borderRight: `0.5px solid`,
									  }
									: {}),
								...(optionsHeader.length !== 1 && index === 0 ? { borderRight: `0.5px solid` } : optionsHeader.length !== 1 && index === optionsHeader.length - 1 ? { borderLeft: `0.5px solid`, borderRight: `1px solid` } : {}),
							}}
							onClick={() => {
								if (item.disabled) return;
								setActive(index);
								setPrevTab(active);
								onTabChange?.(index);
							}}>
							<Typography type="subtitle" style={{ cursor: item.disabled ? "not-allowed" : "pointer" }}>
								{item.title}
							</Typography>
						</TabHeader>
					))}
				</TabsHeaderContainer>
			)}
			<div style={{ overflow: "hidden", display: "flex", width: "100%" }}>
				{options.map((item, index) =>
					active === index ? (
						<TabContainer animationDirection={active > prevTab ? "left" : "right"} style={tabContainerStyles}>
							{item?.component}
						</TabContainer>
					) : null
				)}
			</div>
		</TabsContainer>
	);
}

export default Tabs;
