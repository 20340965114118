import styled from "styled-components";
import { CopySimple, ArrowsLeftRight, LinkedinLogo, XLogo, Trash, EnvelopeSimple, QrCode } from "@phosphor-icons/react";
import { usePopperTooltip } from "react-popper-tooltip";
import Theme from "../theme/theme";

const WalletCardBodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.3rem;
`;

const WalletCardBodyButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.3rem;
	font-size: 1.2rem;
	color: ${Theme.colors.grey};
`;

const WalletCardBodyTitle = styled.div`
	text-align: center;
	font-weight: ${Theme.fontWeights.bold};
	color: ${Theme.colors.grey};
`;

const WalletCardBodyImage = styled.div`
	text-align: center;
	height: 2rem;
`;

export const WalletCardBody = ({
	onView = () => {
		console.log("WalletCardBody action click");
	},
	onEdit = () => {
		console.log("WalletCardBody action click");
	},
	onQr = () => {
		console.log("WalletCardBody action click");
	},
	onEmail = () => {
		console.log("WalletCardBody action click");
	},
	onTransfer = () => {
		console.log("WalletCardBody action click");
	},
	onLinkedIn = () => {
    console.log("WalletCardBody LinkedIn action click");
  },
  onTwitter = () => {
    console.log("WalletCardBody Twitter action click");
  },
	onCopy = () => {
		console.log("WalletCardBody action click");
	},
	onDelete = () => {
		console.log("WalletCardBody action click");
	},
	showLinkedIn = true,
	showCopy = true,
	title = "Test Global Ed",
	imageUrl = "https://res.cloudinary.com/dadlqfryt/image/upload/v1571451072/convergence-logos/Convergence_Logo_Final-01_tq5wtd.png",
	showTransfer = true,
}) => {
  let transBody = {}
  if (showTransfer) {
    transBody = <ToolTip title="Transfer"><ArrowsLeftRight title="Transfer" style={{ cursor: "pointer" }} color={Theme.colors.grey} onClick={onTransfer} /></ToolTip>
  }
  let linkedInBody = {}
  if (showLinkedIn) {
    linkedInBody = <ToolTip title="Post to linkedIn"><LinkedinLogo title="Share to Linked In" style={{ cursor: "pointer" }} color={Theme.colors.grey} onClick={onLinkedIn} /></ToolTip>
  }
  let twitterBody = {}
  if (showLinkedIn) {
    twitterBody = <ToolTip title="Post to X"><XLogo title="Share to X" style={{ cursor: "pointer" }} color={Theme.colors.grey} onClick={onTwitter} /></ToolTip>
  }
  let copyBody = {}
  if (showCopy) {
    copyBody = <ToolTip title="Copy URL"><CopySimple style={{ cursor: "pointer" }} color={Theme.colors.grey} onClick={onCopy} /></ToolTip>
  }
	return (
		<WalletCardBodyContainer>
			<WalletCardBodyTitle>{title}</WalletCardBodyTitle>
			<WalletCardBodyImage>
				<img src={imageUrl} style={{ maxHeight: "100%" }} alt="Credential" />
			</WalletCardBodyImage>
			<WalletCardBodyButtonContainer>
        <ToolTip title="Share QR Code">
          <QrCode style={{ cursor: "pointer" }} color={Theme.colors.grey} onClick={onQr} />
        </ToolTip>
        <ToolTip title="Email">
          <EnvelopeSimple style={{ cursor: "pointer" }} color={Theme.colors.grey} onClick={onEmail} />
        </ToolTip>
        {showTransfer && transBody}
        {showLinkedIn && linkedInBody}
        {showLinkedIn && twitterBody}
        {showCopy && copyBody}
        <ToolTip title="Delete.">
          <Trash style={{ cursor: "pointer" }} color={Theme.colors.grey} onClick={onDelete} />
        </ToolTip>
			</WalletCardBodyButtonContainer>
		</WalletCardBodyContainer>
	);
};

const TipText = styled.span`
	font-family: Roboto;
	font-weight: 400;
	font-size: 0.7rem;
	line-height: 0.9rem;
	color: rgba(255, 255, 255, 0.87);
`;

function ToolTip({ children = null, title = "", containerStyle = {} }) {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({ placement: "top" });
  return (
    <>
      <WalletCardBodyButtonContainer ref={setTriggerRef} style={containerStyle}>
        {children}
      </WalletCardBodyButtonContainer>
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container", style: { backgroundColor: "#606060", borderRadius: "8px" } })}>
          <TipText>{title}</TipText>
        </div>
      )}
    </>
  );
}

export default WalletCardBody;
