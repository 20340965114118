import styled from "styled-components";

const CardContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	width: 100%;
`;

export function CardContent({ children, ...rest }) {
	return <CardContentContainer {...rest}>{children}</CardContentContainer>;
}
