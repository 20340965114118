import { getApi } from "../api";
import { PUBLIC_PREFIX } from "@cti-workspace/helpers";
import { decompress } from "compress-json";

async function checkFabricCertDesigner(rendererConfigId, setDisable) {
	const { data } = await getApi(`/${PUBLIC_PREFIX}/rendererConfig/${rendererConfigId}`, { cachePolicy: "no-cache" }, false);
	let val = JSON.parse(data);
	if (val.type == "fabricDesign" && val.data) {
		try {
			let fabricCertDesigner = decompress(val.data);
			if (!fabricCertDesigner || (!fabricCertDesigner?.backgroundImage && !fabricCertDesigner?.objects?.length && !fabricCertDesigner.isMultiPage)) {
				if (setDisable) setDisable(true);
				return true;
			}
			return false;
		} catch (error) {
			if (setDisable) setDisable(true);
			return true;
		}
	}
	return false;
}

export { checkFabricCertDesigner };
