import axios from "axios";
import { API_URL, JWT_TOKEN, VCX_API_KEY } from "@cti-workspace/helpers";
import { getObjectValue, hasProperty } from "./commonUtilityFunctions";

const version = "1.0.0";

export const postApi = (url, data, additionalHeaders = {}, hasToken = true, apiUrl = API_URL, authHeaders = {}) => {
	return new Promise((resolve, reject) => {
		let authorizationHeader = {
			...authHeaders,
		};
		if (hasToken === true) {
			const jwtToken = localStorage.getItem(JWT_TOKEN);
			if (jwtToken) {
				authorizationHeader = {
					...authorizationHeader,
					Authorization: `Bearer ${jwtToken}`,
				};
			}
		}
		axios
			.post(`${apiUrl}${url}`, data, {
				cachePolicy: "no-cache",
				headers: {
					"Content-Type": "application/json",
					...authorizationHeader,
					"Accept-Version": version,
					"vcx-api-key": VCX_API_KEY,
				},
				...additionalHeaders,
			})
			.then((res) => {
				const { data } = res;
				const hasPayload = hasProperty(data, "payload");
				if (hasPayload) {
					resolve({ ...data, ...data.payload });
				} else {
					resolve(data);
				}
			})
			.catch((err) => {
				if (err.response) {
          if (getObjectValue(err.response, "data.name") === "TokenExpiredError") {
            console.log("API request 1: TokenExpiredError");
						window.location.href = "/login";
					}
					reject(err.response.data);
				} else if (err.request) {
					console.log("request", err.message);
					reject(err.request);
				} else {
					console.log("err", err.message);
					reject(err.message);
				}
			});
	});
};

export const getApi = (url, additionalHeaders = {}, hasToken = true, apiUrl = API_URL, authHeaders = {}) => {
	return new Promise((resolve, reject) => {
		let authorizationHeader = {
			...authHeaders,
		};
		if (hasToken === true) {
			const jwtToken = localStorage.getItem(JWT_TOKEN);
			if (jwtToken) {
				authorizationHeader = {
					...authorizationHeader,
					Authorization: `Bearer ${jwtToken}`,
				};
			}
		}
		axios
			.get(`${apiUrl}${url}`, {
				cachePolicy: "no-cache",
				headers: {
					"Content-Type": "application/json",
					...authorizationHeader,
					"Accept-Version": version,
				},
				...additionalHeaders,
			})
			.then((res) => {
				const { data } = res;
				resolve(data);
			})
			.catch((err) => {
				if (err.response) {
					if (getObjectValue(err.response, "data.name") === "TokenExpiredError") {
            console.log("API request 2: TokenExpiredError");
						window.location.href = "/login";
					}
					reject(err.response.data);
				} else if (err.request) {
					reject(err.request);
				} else {
					reject(err.message);
				}
			});
	});
};

export const putApi = (url, data, additionalHeaders = {}, hasToken = true) => {
	return new Promise((resolve, reject) => {
		let authorizationHeader = {};
		if (hasToken === true) {
			const jwtToken = localStorage.getItem(JWT_TOKEN);
			if (jwtToken) {
				authorizationHeader = {
					Authorization: `Bearer ${jwtToken}`,
				};
			}
		}
		axios
			.put(`${API_URL}${url}`, data, {
				cachePolicy: "no-cache",
				headers: {
					"Content-Type": "application/json",
					...authorizationHeader,
					"Accept-Version": version,
				},
				...additionalHeaders,
			})
			.then((res) => {
				const { data } = res;
				resolve(data);
			})
			.catch((err) => {
				if (err.response) {
					if (getObjectValue(err.response, "data.name") === "TokenExpiredError") {
            console.log("API request 3: TokenExpiredError");
						window.location.href = "/login";
					}
					reject(err.response.data);
				} else if (err.request) {
					reject(err.request);
				} else {
					reject(err.message);
				}
			});
	});
};

export const delApi = (url, data, additionalHeaders = {}, hasToken = true) => {
	return new Promise((resolve, reject) => {
		let authorizationHeader = {};
		if (hasToken === true) {
			const jwtToken = localStorage.getItem(JWT_TOKEN);
			if (jwtToken) {
				authorizationHeader = {
					Authorization: `Bearer ${jwtToken}`,
				};
			}
		}
		axios
			.request({
				data,
				url: `${API_URL}${url}`,
				method: "delete",
				headers: {
					"Content-Type": "application/json",
					"Cache-Control": "no-cache",
					...authorizationHeader,
					...additionalHeaders,
				},
			})
			.then((res) => {
				const { data } = res;
				resolve(data);
			})
			.catch((err) => {
				if (err.response) {
					if (getObjectValue(err.response, "data.name") === "TokenExpiredError") {
            console.log("API request 4: TokenExpiredError");
						window.location.href = "/login";
					}
					reject(err.response.data);
				} else if (err.request) {
					reject(err.request);
				} else {
					reject(err.message);
				}
			});
	});
};

export const downloadApi = (url, data, method, fileName, additionalHeaders = {}, hasToken = true, apiUrl = API_URL, authHeaders = {}) => {
	return new Promise((resolve, reject) => {
		let authorizationHeader = {
			...authHeaders,
		};
		if (hasToken === true) {
			const jwtToken = localStorage.getItem(JWT_TOKEN);
			if (jwtToken) {
				authorizationHeader = {
					...authorizationHeader,
					Authorization: `Bearer ${jwtToken}`,
				};
			}
		}
		axios({
			url: `${apiUrl}${url}`,
			method: method,
			responseType: "blob",
			headers: {
				...authorizationHeader,
			},
			data,
		})
			.then((res) => {
				const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", fileName); //or any other extension
				document.body.appendChild(link);
				link.click();
				resolve();
			})
			.catch((err) => {
				if (err.response) {
					if (getObjectValue(err.response, "data.name") === "TokenExpiredError") {
            console.log("API request 5: TokenExpiredError");
						window.location.href = "/login";
					}
					reject(err.response.data);
				} else if (err.request) {
					console.log("request", err.message);
					reject(err.request);
				} else {
					console.log("err", err.message);
					reject(err.message);
				}
			});
	});
};

export const createBatch = (url, data, method, additionalHeaders = {}, hasToken = true, apiUrl = API_URL, authHeaders = {}) => {
	return new Promise((resolve, reject) => {
		let authorizationHeader = {
			...authHeaders,
		};
		if (hasToken === true) {
			const jwtToken = localStorage.getItem(JWT_TOKEN);
			if (jwtToken) {
				authorizationHeader = {
					...authorizationHeader,
					Authorization: `Bearer ${jwtToken}`,
				};
			}
		}
		axios({
			url: `${apiUrl}${url}`,
			method: method,
			headers: {
				...authorizationHeader,
			},
			data,
		})
			.then((res) => {
				if (res.status === 201) {
					const { data } = res;
					resolve(data);
				} else {
					reject("Something went wrong!");
				}
			})
			.catch((err) => {
				if (err.response) {
					if (getObjectValue(err.response, "data.name") === "TokenExpiredError") {
            console.log("API request 6: TokenExpiredError");
						window.location.href = "/login";
					}
					reject(err.response.data);
				} else if (err.request) {
					console.log("request", err.message);
					reject(err.request);
				} else {
					console.log("err", err.message);
					reject(err.message);
				}
			});
	});
};
