import { useEffect } from "react";

import { Navigate, useLocation, matchPath } from "react-router-dom";
import { userStore } from "../store/userStore";
import RouteLayout from "./RouteLayout";
import jwt from "jsonwebtoken";
import { ALGORITHM, JWT_PUB, PREV_ROUTE } from "@cti-workspace/helpers";
import { routesPerAccountType } from "./RoutesObject";
import { DeniedAccess } from "./deniedAccess";

const RestrictedRoute = () => {
	const isLoggedIn = userStore((state) => state.isLoggedIn);
	const roles = userStore((state) => state.roles);
	const capabilities = userStore((state) => state.capabilities);
	const userLogOut = userStore((state) => state.userLogOut);
	const jwtToken = userStore((state) => state.jwtToken);
	const { pathname } = useLocation();
	// console.log(pathname);

	const pub = JWT_PUB.replace(/\\n/g, "\n");
	const algorithms = [ALGORITHM];
	const { allowedPRoutes } = routesPerAccountType({ roles, capabilities });
	// console.log(pathname);
	const allowedRouteArray = allowedPRoutes.filter((item) => matchPath(item.path, pathname));

	useEffect(() => {
		(async () => {
			try {
				jwt.verify(jwtToken, pub, { algorithms });
			} catch (error) {
				// store the last router in the sessionStorage
				sessionStorage.setItem(PREV_ROUTE, pathname);
				userLogOut();
				console.error(error);
			}
		})();
	}, []);

	return isLoggedIn ? allowedRouteArray.length ? <RouteLayout allowedRouteArray={allowedRouteArray} /> : <DeniedAccess /> : <Navigate to="/login" />;
	// return <RouteLayout />;
};

export default RestrictedRoute;
