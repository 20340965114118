import styled from "styled-components";

import Theme from "../theme/theme";

const NewCardFooterContainer = styled.div`
	border-top: 0.5px solid ${Theme.colors.gray};
	display: flex;
	gap: 1rem;
	padding: 0.8rem;
	flex: none;
`;

const NewCardFooterButton = styled.div`
	cursor: pointer;
	border: 1px solid ${(props) => (props.primaryColor ? props.primaryColor : Theme.colors.purple)};
	border-radius: 0.3rem;
	padding: 0.5rem;
	color: ${Theme.colors.purple};
	text-transform: uppercase;
	font-size: 0.8rem;
	font-weight: bold;
	text-align: center;
	width: ${(props) => props.width};
	background-color: ${(props) => (props.primary ? (props.primaryColor ? props.primaryColor : Theme.colors.purple) : "white")};
	color: ${(props) => (!props.primary ? (props.primaryColor ? props.primaryColor : Theme.colors.purple) : "white")};
`;

const getButtonWidth = (showLeft, showCenter, showRight) => {
	const buttonVisibility = [showLeft, showCenter, showRight];
	const visibleButtonsCount = buttonVisibility.filter((v) => v === true).length;
	return `${(100 / visibleButtonsCount).toFixed(2)}%`;
};

export const NewCardFooter = ({
	primaryColor,
	showLeft = true,
	showCenter = true,
	showRight = false,
	leftButtonTitle = "issue one",
	centerButtonTitle = "issue many",
	rightButtonTitle = "save many",
	onLeftButtonClick = () => {
		console.log("onLeftButtonClick");
	},
	onCenterButtonClick = () => {
		console.log("onCenterButtonClick");
	},
	onRightButtonClick = () => {
		console.log("onRightButtonClick");
	},
	id = "",
	rightButtonPrimary = false,
	centerButtonPrimary = false,
}) => {
	const buttonWidth = getButtonWidth(showLeft, showCenter, showRight);

	return (
		<NewCardFooterContainer>
			{showLeft && (
				<NewCardFooterButton data-testid={`credCardLeft_${id}`} onClick={onLeftButtonClick} width={buttonWidth}>
					{leftButtonTitle}
				</NewCardFooterButton>
			)}
			{showCenter && (
				<NewCardFooterButton primaryColor={primaryColor} primary={centerButtonPrimary} data-testid={`credCardCenter_${id}`} onClick={onCenterButtonClick} width={buttonWidth}>
					{centerButtonTitle}
				</NewCardFooterButton>
			)}
			{showRight && (
				<NewCardFooterButton primary={rightButtonPrimary} data-testid={`credCardRight_${id}`} onClick={onRightButtonClick} width={buttonWidth}>
					{rightButtonTitle}
				</NewCardFooterButton>
			)}
		</NewCardFooterContainer>
	);
};

export default NewCardFooter;
