import { useEffect, useState } from "react";
import { Box, IconButton, Link } from "@material-ui/core";
import useCertificateHook from "../../hooks/useCertificateHook";
import useUserHook from "../../hooks/useUserHook";
import GetAppIcon from "@material-ui/icons/GetApp";
import SendIcon from "@material-ui/icons/Send";
import HeaderWithLIne from "../../components/HeaderWithLine";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import qrImg from "../../assets/images/qr.svg";
import QRCode from "qrcode.react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useSnackbar } from "notistack";
import CAPABILITIES from "../../capabilities";
import { isCapable } from "../admin/userAccounts/permissionUtils";
import CapabilityBoundary from "../../components/CapabilityBoundary/CapabilityBoundary";
import { Table } from "@cti-workspace/ui";
import { Alarm, Clock, Check, Warning, X, CircleNotch } from "@phosphor-icons/react";
import cookie from "js-cookie";
import { ISSUER_PREFIX, REVOKED_LIST, USER_ROLES, disableIssuedCredentialView, postApi, notAllowedToRevoke, getObjectValue, convertIssuedTimeToLocal } from "@cti-workspace/helpers";
import { userStore } from "../../store/userStore";
import { usePopperTooltip } from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";
import styled from "styled-components";
import { format } from "date-fns";
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";

const TipText = styled.span`
	font-family: Roboto;
	font-weight: 400;
	font-size: 0.7rem;
	line-height: 0.9rem;
	color: rgba(255, 255, 255, 0.87);
`;

export default function IssuedCredentials() {
	const [publicUrl, setPublicUrl] = useState(null);
	const [searchText, setSearchText] = useState("");
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalItems, setTotalItems] = useState(0);
	const [certificatesWithLocalIssuedTime, setCertificatesWithLocalIssuedTime] = useState([]);
	const [loading, setLoading] = useState(true);
	const [containerWidth, setContainerWidth] = useState(0);

	const { certificates, resendEmailForIssuedCert, revokeIssuedCert, getCertificates, searchCertificates } = useCertificateHook();
	const roles = userStore((state) => state.roles);
	const capabilities = userStore((state) => state.capabilities);

	const { enqueueSnackbar } = useSnackbar();

	const imgExt = {
		"image/png": ".png",
		"image/jpeg": ".jpg",
	};

	useEffect(() => {
		setContainerWidth(document.getElementById("issued-credential-top-margin").offsetWidth - 295);
		const resizeListener = () => {
			setContainerWidth(document.getElementById("issued-credential-top-margin").offsetWidth - 295);
			// console.log(containerWidth);
		};
		window.addEventListener("resize", resizeListener);
		return () => {
			window.removeEventListener("resize", resizeListener);
		};
	}, []);

	useEffect(() => {
		(async () => {
			try {
				setLoading(true);
				if (searchText) {
					const { totalItems } = await searchCertificates(searchText, pageSize, pageNumber);
					setTotalItems(totalItems);
				} else {
					const { totalItems } = await getCertificates(pageSize, pageNumber);
					setTotalItems(totalItems);
				}
				setLoading(false);
			} catch (err) {
				setLoading(false);
				console.error(err);
			}
		})();
	}, [searchText, pageNumber, pageSize]);

	useEffect(() => {
		if (publicUrl) {
			try {
				const element = document.getElementById("qrCode");
				const canvas = element.querySelector("canvas");
				const base64 = canvas.toDataURL();
				let mimeType = base64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
				const a = document.createElement("a");
				a.href = base64;
				a.download = publicUrl["recipient"] + " - QR Code" + imgExt[mimeType];
				a.click();
				setPublicUrl(null);
			} catch (err) {
				console.error(err);
			}
		}
	}, [publicUrl]);

	const qrDownload = (row) => {
		setPublicUrl(row);
	};

	const onPaginationChange = async (pageSize, pageNumber) => {
		setPageSize(pageSize);
		setPageNumber(pageNumber);
	};

	const onSearch = async (str) => {
		setSearchText(str);
		setPageNumber(1);
	};

	const addToRevokedCredList = (id) => {
		const val = cookie.get(REVOKED_LIST);
		const revokedList = val ? JSON.parse(val) : [];
		cookie.set(REVOKED_LIST, JSON.stringify([...revokedList, id]));
	};

	const onRevoke = async (val) => {
		try {
			if (revokeIssuedCert) {
				await revokeIssuedCert(val.id);
				addToRevokedCredList(val.id);
				setTimeout(() => {
					setCertificatesWithLocalIssuedTime(
						certificatesWithLocalIssuedTime.map((event) => ({
							...event,
							...(event.id === val.id ? { state: "revoked" } : {}),
						}))
					);
				}, 100);
			}
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		setCertificatesWithLocalIssuedTime(convertIssuedTimeToLocal(certificates));
	}, [certificates]);

	const viewIpaPdf = async (credentialId) => {
		enqueueSnackbar("Getting PDF URL. Please wait.", {
			variant: "info",
		});
		try {
			const { url } = await postApi(`/${ISSUER_PREFIX}/generateCredentialPdfLink`, {
				credentialId,
			});
			const newWindow = window.open(url);
			newWindow.opener = null;
		} catch (e) {
			enqueueSnackbar("Failed to retrieve PDF URL. Please try again ", {
				variant: "error",
			});
		}
	};

	const revokingRoleAvailable = roles?.includes(USER_ROLES.ORG_ADMIN);

	return (
		<Box paddingLeft={3} paddingRight={3} style={{ width: "100%" }}>
			<Box
				padding={3}
				style={{
					width: "100%",
					backgroundColor: "white",
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}>
				<HeaderWithLIne title="Issued Credentials" variant="h4" />
				<div style={{ marginTop: "24px" }} id="issued-credential-top-margin" />
				<Table
					config={{
						headers: [
							{
								label: "Issued At",
                prop: (row) => row["issuedOn"],
								sort: true,
								width: `${containerWidth / 4}px`,
								cell: (row) => {
									const issuedOnDate = row.issuedOn && formatInTimeZone(new Date(row.issuedOn), "", "PP hh:mm a");
									return (
										<ToolTip
											containerStyle={{
												whiteSpace: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}
											title={issuedOnDate}>
											{issuedOnDate}
										</ToolTip>
									);
								},
							},
							{
								label: "Credential Name",
                prop: (row) => row["credentialName"],
								sort: false,
								width: `${containerWidth / 4}px`,
								cell: (row) => (
									<ToolTip
										containerStyle={{
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
										}}
										title={row.credentialName}>
										{row.credentialName}
									</ToolTip>
								),
							},
							{
								label: "Recipient Name",
                prop: (row) => row["recipientName"],
								sort: false,
								width: `${containerWidth / 4}px`,
								cell: (row) => (
									<ToolTip
										containerStyle={{
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
										}}
										title={row.recipientName}>
										{row.recipientName}
									</ToolTip>
								),
							},
							{
								label: "Identifier",
                prop: (row) => row["recipient"],
								sort: false,
								width: `${containerWidth / 4}px`,
								cell: (row) => (
									<ToolTip
										containerStyle={{
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
										}}
										title={row.recipient}>
										{row.recipient}
									</ToolTip>
								),
							},
							{
								label: "Status",
								//prop: "",
								sort: false,
								width: "75px",
								textAlign: "center",
								cell: (row) => {
									let creWillExp = false;
									let creExpired = false;
									if (row.expireAt) {
										var expiredOn = row.expireAt.match(/(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g);
										let givenDate = new Date(expiredOn[0]);
										let diff = new Date().getTime() - givenDate.getTime();
										if (diff < 0) {
											creWillExp = true;
										}

										if (diff > 0) {
											creExpired = true;
										}
									}
									return row.state == "revoked" ? (
										<ToolTip title="This credential has been revoked.">
											<IconButton style={{ color: "grey" }} size="small">
												<Warning size={25} />
											</IconButton>
										</ToolTip>
									) : row.state == "deleted" ? (
										<ToolTip title="This credential has been deleted by the Holder.">
											<IconButton style={{ color: "rgb(231, 73, 75)" }} size="small">
												<X size={25} />
											</IconButton>
										</ToolTip>
									) : row.state == "pending" ? (
										<ToolTip title="This credential is ready for pickup.">
											<IconButton style={{ color: "#9d49ee" }} size="small">
												<CircleNotch size={25} style={{ position: "absolute" }} />
												<Check size={16} style={{ position: "absolute" }} />
											</IconButton>
										</ToolTip>
									) : creExpired ? (
										<ToolTip title={`This credential expired on ${expiredOn[0]}`}>
											<IconButton style={{ color: "grey" }} size="small">
												<Alarm size={25} />
											</IconButton>
										</ToolTip>
									) : creWillExp ? (
										<ToolTip title={`This credential will expire on ${expiredOn[0]}`}>
											<IconButton style={{ color: "#9d49ee" }} size="small">
												<Clock size={25} />
											</IconButton>
										</ToolTip>
									) : (
										<ToolTip title="This credential is active.">
											<IconButton style={{ color: "#9d49ee" }} size="small">
												<Check size={25} />
											</IconButton>
										</ToolTip>
									);
								},
							},
							{
								label: "Actions",
								//prop: "",
								width: "200px",
								cell: (row) => {
									const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({ placement: "top" });
									return (
										<>
											<ToolTip title="Download">
												<Link href={`data:text/json;charset=utf-8,${encodeURIComponent(row.rawCertificate)}`} download={`${row.recipient}-${row.credentialName}.json`}>
													<IconButton color="secondary" size="small">
														<GetAppIcon fontSize="small" />
													</IconButton>
												</Link>
											</ToolTip>
											{navigator && (
												<CapabilityBoundary
													render={(disabled) => (
														<ToolTip title="Copy URL">
															<IconButton
																disabled={disabled || !getObjectValue(row, "publicUrl")}
																color="secondary"
																size="small"
																onClick={() => {
																	navigator && navigator.clipboard.writeText(getObjectValue(row, "shortURLs.publicUrl", getObjectValue(row, "publicUrl")));
																	enqueueSnackbar("Url copied to clipboard", {
																		variant: "success",
																	});
																}}>
																<FileCopyIcon fontSize="small" />
															</IconButton>
														</ToolTip>
													)}
													capability={CAPABILITIES.COPY_URL_ISSUED_CREDENTIALS_FOR_ORG}
												/>
											)}
											<CapabilityBoundary
												render={(disabled) => (
													<ToolTip title="View">
														<IconButton disabled={disabled || disableIssuedCredentialView(row)} color="secondary" onClick={row.pdfKey ? () => viewIpaPdf(row.id) : () => window.open(`${row.publicUrl}`)} size="small">
															<VisibilityIcon fontSize="small" />
														</IconButton>
													</ToolTip>
												)}
												capability={CAPABILITIES.VIEW_ISSUED_CREDENTIALS_FOR_ORG}
											/>
											<CapabilityBoundary
												render={(disabled) => (
													<ToolTip title="Resend">
														<IconButton disabled={disabled} color="secondary" onClick={() => resendEmailForIssuedCert && resendEmailForIssuedCert(row.id)} size="small">
															<SendIcon fontSize="small" />
														</IconButton>
													</ToolTip>
												)}
												capability={CAPABILITIES.RESEND_ISSUED_CREDENTIALS_FOR_ORG}
											/>
											<CapabilityBoundary
												render={(disabled) => (
													<span title={!revokingRoleAvailable || !isCapable(capabilities, CAPABILITIES.REVOKE_ISSUED_CREDENTIALS_FOR_ORG) ? "You don’t have permission to revoke" : ""}>
														<ToolTip title="Revoke">
															<IconButton color="secondary" onClick={() => onRevoke(row)} size="small" disabled={!revokingRoleAvailable || notAllowedToRevoke(row) || disabled}>
																<HighlightOffIcon fontSize="small" />
															</IconButton>
														</ToolTip>
													</span>
												)}
												capability={CAPABILITIES.REVOKE_ISSUED_CREDENTIALS_FOR_ORG}
											/>
											<CapabilityBoundary
												render={(disabled) => (
													<ToolTip title="Download QR">
														<IconButton disabled={disabled} color="secondary" onClick={() => qrDownload(row)} size="small">
															<img src={qrImg} style={{ width: "15px" }} />
														</IconButton>
													</ToolTip>
												)}
												capability={CAPABILITIES.DOWNLOAD_QR_CODE_ISSUED_CREDENTIALS_FOR_ORG}
											/>
										</>
									);
								},
							},
						],
					}}
					data={certificatesWithLocalIssuedTime}
					pagination
					totalRows={totalItems}
					onChangePage={(page) => onPaginationChange(pageSize, page)}
					onChangeRowsPerPage={(pageSize) => setPageSize(pageSize)}
					// conditionalRowStyles={[
					// 	{
					// 		when: (row) => row.state == "revoked",
					// 		style: {
					// 			backgroundColor: 'green',
					// 			color: 'white',
					// 			'&:hover': {
					// 			  cursor: 'pointer',
					// 			},
					// 		  },
					// 	},
					// 	{
					// 		when: (row) => row.state === "deleted",
					// 		style: {
					// 			backgroundColor: "#FFB6C1"
					// 		}
					// 	}
					// ]}
					onSearch={onSearch}
					loaderCenter
					loading={loading}
					onResetSearch={() => setSearchText("")}
				/>
			</Box>
			<div id="qrCode" style={{ display: "none" }}>
				<QRCode value={(publicUrl && publicUrl?.publicUrl) || ""} />
			</div>
		</Box>
	);
}

export function ToolTip({ children = null, title = "", containerStyle = {} }) {
	const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({ placement: "top" });
	return (
		<>
			<div ref={setTriggerRef} style={containerStyle}>
				{children}
			</div>
			{visible && (
				<div
					ref={setTooltipRef}
					{...getTooltipProps({
						className: "tooltip-container",
						style: { backgroundColor: "#606060", borderRadius: "8px" },
					})}>
					<TipText>{title}</TipText>
				</div>
			)}
		</>
	);
}
