import { API_URL, VCX_API_KEY } from "@cti-workspace/helpers";
import { postApi, getApi } from "./api";

export const postAdapter = async (url, data, additionalHeaders = {}, hasToken = true) => {
	return postApi(url, data, {}, false, API_URL, { "vcx-api-key": VCX_API_KEY });
};

export const getAdapter = async (url) => {
	try {
		return getApi(url, {}, false, API_URL, { "vcx-api-key": VCX_API_KEY });
	} catch (err) {
		return err;
	}
};
