import { useState, useEffect } from "react";
import { useWatch } from "react-hook-form";
import { getObjectValue } from "@cti-workspace/helpers";
import { Spinner } from "@cti-workspace/ui";
import { userStore } from "../../store/userStore";
// import Verifier from "../../routes/newVerifier/Verifier";

const PreviewDefineCredential = ({ value, selectedSaveIconUrl }) => {
	const organizations = userStore((state) => state.organizations);

	let issuerImageUrl = "";
	let issuerData = {
		name: "",
		website: "",
		domain: "",
	};
	try {
		const orgData = Object.values(organizations);
		if (orgData.length) {
			issuerData = {
				name: orgData[0].name,
				domain: Object.values(orgData[0].domains)[0].replace("http://", "").replace("https://", "").split(/[/?#]/)[0] || "",
				website: Object.values(orgData[0].domains)[0] || "",
			};
			issuerImageUrl = Object.values(orgData[0].imageUrls)[0] || "https://res.cloudinary.com/dadlqfryt/image/upload/v1571451072/convergence-logos/Convergence_Logo_Final-01_tq5wtd.png";
		}
	} catch (err) {
		issuerImageUrl = "https://res.cloudinary.com/dadlqfryt/image/upload/v1571451072/convergence-logos/Convergence_Logo_Final-01_tq5wtd.png";
	}
	const [document, setDocument] = useState({
		id: "ed53974f-ad5d-4f7e-a512-488823c66734",
		name: "",
		issuers: [
			{
				name: issuerData.name,
				url: issuerData.website,
				identityProof: {
					type: "DNS-TXT",
					location: issuerData.domain,
				},
			},
		],
		recipient: {
			name: "<Recipient Name>",
			email: "durgaprasad814@gmail.com",
		},
		transcript: [
			{
				name: "",
				courseCode: "0",
				grade: "",
			},
		],
		additionalData: {
			description: "",
			imageUrl: "https://ipwru.s3.amazonaws.com/prod/seal.png",
			issuerImageUrl,
			resources: [],
			linkedinOrganizationId: "28185997",
			rendererConfigId: getObjectValue(value, "rendererConfigId", ""),
		},
		issuedOn: new Date(),
		$template: {
			name: "TRYBE_DEFAULT",
			type: "EMBEDDED_RENDERER",
			url: `${window?.location?.origin}/renderer?orgId=${organizations?.default?._id}&rendererId=TRYBE_DEFAULT`,
		},
	});
	const isHTML = (text) => {
		try {
			const fragment = new DOMParser().parseFromString(text, "text/html");
			return fragment.body.children.length > 0;
		} catch (error) { }
		return false;
	};

	const strDocument = encodeURIComponent(
		JSON.stringify({
			...document,
			additionalData: {
				...document.additionalData,
				description: isHTML(value.description || "") ? (value.description || "").replace(/['"]+/g, "") : value.description || "",
				imageUrl: selectedSaveIconUrl || (value?.images?.length ? value.images[0].value : "https://ipwru.s3.amazonaws.com/prod/seal.png"),
				resources:
					value &&
					value.resources &&
					value.resources.map((item, index) => ({
						id: item.key,
						url: item.value,
					})),
				rendererConfigId: getObjectValue(value, "rendererConfigId", ""),
			},
			transcript:
				(value &&
					value.transcripts &&
					value.transcripts.map((item, index) => ({
						name: item.key,
						courseCode: index,
						grade: "",
					}))) ||
				[],
			...value,
		})
	);

	const strConfig = encodeURIComponent(
		JSON.stringify({
			bgImage: getObjectValue(value, "bgImage[0].value"),
			textContent1: value.textContent1 || "has successfully completed the",
			textContent2: value.textContent2 || "Certification through training administered by",
			text1: JSON.stringify({ color: getObjectValue(value, "text1", "") }),
			text2: JSON.stringify({ color: getObjectValue(value, "text2", "") }),
			designType: getObjectValue(value, "designType"),
		})
	);

	let url;

	if (window.location.host == "localhost:3000") {
		const urlPieces = [window.location.protocol, "//", "localhost:3000", `/verifier/?document=${strDocument}&config=${strConfig}`];
		const urlP = [window.location.protocol, "//", "localhost:4200", `/verifier/?document=${strDocument}&config=${strConfig}`];
		console.log(urlP.join(""));
		url = urlPieces.join("");
	} else {
		const urlPieces = [window.location.protocol, "//", window.location.host, `/verifier/?document=${strDocument}&config=${strConfig}`];
		console.log(urlPieces.join(""));
		url = urlPieces.join("");
	}

	const [iFrameHeight, setIFrameHeight] = useState(600);
	const [loading, setloading] = useState(true);

	const onLoad = () => {
		setIFrameHeight(900);
		setloading(false);
	};

	return (
		<>
			{loading && (
				<div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
					{" "}
					<Spinner />{" "}
				</div>
			)}
			<iframe src={url} width="100%" sandbox="allow-scripts allow-modals allow-same-origin" onLoad={onLoad} seamless height={iFrameHeight} style={{ border: "none" }}></iframe>
		</>
	);
};

PreviewDefineCredential.propTypes = {};

export default PreviewDefineCredential;
