import { AsyncComboBox } from "@cti-workspace/ui";
import { forwardRef } from "react";
import differenceBy from "lodash/differenceBy";
import { getOrganization } from "@cti-workspace/helpers";

const OrgDropDown = forwardRef(({ onChange, defaultValue, initialOptions = [], required = false, returnValue = false }, ref) => {
	async function loadOptions(search, loadedOptions, { page }) {
		const response = await getOrganization(10, page, search, false, false);
		// console.log(response)
		return {
			options: response.items,
			hasMore: response.totalItems >= response.pageNumber * response.pageSize,
			additional: {
				page: page + 1,
			},
		};
	}

	return (
		<AsyncComboBox
			debounceTimeout={500}
			selectRef={ref}
			getOptionValue={(item) => item["_id"]}
			getOptionLabel={(item) => item.name}
			loadOptions={loadOptions}
			onChange={(e) => onChange(returnValue ? e : e["_id"])}
			required
			additional={{
				page: 1,
			}}
		/>
	);
});

export default OrgDropDown;
