import { useEffect, useState } from "react";

import { getOrganization, getDatabaseConnections, orderBy } from "@cti-workspace/helpers";
import CreateNewConnection from "./CreateNewConnection";
import UpdateOrgDatabaseConnection from "./UpdateOrgDbConnection";

const DatabaseConfig = ({}) => {
	const [orgs, setOrgs] = useState([]);
	const [existingDbConnections, setExistingDbConnections] = useState([]);
	const [reloadCountForDbs, setReloadCountForDbs] = useState(1);
	const [reloadCountForOrgs, setReloadCountForOrgs] = useState(1);

	useEffect(() => {
		async function fetchData() {
			const { items } = await getOrganization();
			setOrgs(items);
		}
		fetchData();
	}, [reloadCountForOrgs]);

	useEffect(() => {
		async function fetchData() {
			const dbConnections = await getDatabaseConnections();
			if (dbConnections) {
				setExistingDbConnections(orderBy(dbConnections.databaseConnections, ["connectionName"], ["asc"]));
			}
		}
		fetchData();
	}, [reloadCountForDbs]);

	const reloadDbConnections = () => {
		setReloadCountForDbs(reloadCountForDbs + 1);
	};

	const reloadOrgData = () => {
		setReloadCountForOrgs(reloadCountForOrgs + 1);
	};

	return (
		<div>
			<CreateNewConnection reloadDbConnections={reloadDbConnections} />
			<br />
			<UpdateOrgDatabaseConnection orgs={orgs} existingDbConnections={existingDbConnections} reloadOrgData={reloadOrgData} />
		</div>
	);
};

export default DatabaseConfig;
