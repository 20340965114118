import { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "@cti-workspace/ui";
import { FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import HeaderWithLine from "../../components/HeaderWithLine";
import { getObjectValue } from "@cti-workspace/helpers";
import WalletCards from "../../components/WalletCard/WalletCard";
import ActionDialog from "../../components/ActionDialog";
import { useSnackbar } from "notistack";
import useCredentialHook from "../../hooks/useCredentialHook";
import LinearProgress from "@material-ui/core/LinearProgress";
import Fade from "@material-ui/core/Fade";
import { Container } from "@cti-workspace/ui";
import { userStore } from "../../store/userStore";

function WalletComponent({
	classes,
	recipientData,
	baseUrl,
	email,
	credCount = () => {
		return null;
	},
	primaryColor,
	cardName,
	cardNameVal,
	cardNameRef,
}) {
	const [importCredentialDialogOpen, setImportCredentialDialogOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [credentialToImportUrl, setCredentialToImportUrl] = useState("");
	const { enqueueSnackbar } = useSnackbar();
	const roles = userStore((state) => state.roles);
	const firstName = userStore((state) => state.firstName);
	const lastName = userStore((state) => state.lastName);
	const account = { firstName, lastName };
	const organizations = userStore((state) => state.organizations);
	const { importCredential, importedCredsData, getImportedCreds } = useCredentialHook({ account: { roles, organizations } });
	const [sharedCredentialIds, setSharedCredentialIds] = useState([]);

	useEffect(() => {
		getImportedCreds();
	}, []);

	useEffect(() => {
		credCount([...recipientData, ...importedCredsData].filter((recipient) => !sharedCredentialIds.includes(recipient.id)).length);
	}, [recipientData, importedCredsData]);

	const handleImport = async () => {
		if (!credentialToImportUrl.startsWith("https://")) {
			enqueueSnackbar(`Credential URL must be a valid URL, beginning with "https://". Invalid: "${credentialToImportUrl}"`, {
				variant: "error",
			});
		} else {
			const data = credentialToImportUrl;
			const type = "openbadges2.0";
			const success = await importCredential({ data, type });
			if (success) {
				setImportCredentialDialogOpen(false);
			}
		}
	};

	const addSharedCredentialIds = (sharedCredentialId) => {
		setSharedCredentialIds([...sharedCredentialIds, sharedCredentialId]);
		// console.log([...sharedCredentialIds, sharedCredentialId]);
	};

	return (
		<Container className={classes.root} disableGutters>
			<main style={{ paddingTop: "24px" }}>
				<div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
					{primaryColor ? (
						<Button id="import-credential-button" buttonStyle={{ backgroundColor: primaryColor, borderColor: primaryColor }} leftIcon="plus-circle" fullWidth={false} onClick={() => setImportCredentialDialogOpen(true)}>
							Import Credential
						</Button>
					) : (
						<Button id="import-credential-button" color="warning" leftIcon="plus-circle" fullWidth={false} onClick={() => setImportCredentialDialogOpen(true)}>
							Import Credential
						</Button>
					)}
				</div>
				<ActionDialog
					open={importCredentialDialogOpen}
					onClose={() => setImportCredentialDialogOpen(false)}
					title={"Import a Credential"}
					content={
						<>
							<Fade in={loading}>
								<LinearProgress />
							</Fade>
							<TextField autoFocus disabled={loading} id="import-url" label="Badge URL" placeholder="https://badgr.com/public/assertions/X0dddyL5RGydB81omsnaaa" fullWidth onChange={(e) => setCredentialToImportUrl(e.target.value)} />
							<p>
								Please first ensure your URL validates here:
								<a href="https://openbadgesvalidator.imsglobal.org" target="_blank" rel="noopener noreferrer">
									{" "}
									https://openbadgesvalidator.imsglobal.org
								</a>
								<br />
								<i>Support currently exists for OpenBadges 2.0 URLs.</i>
							</p>
						</>
					}
					primaryAction={
						primaryColor ? (
							<Button
								id="confirm-import-credential"
								buttonStyle={{ backgroundColor: primaryColor }}
								onClick={async () => {
									setLoading(true);
									await handleImport();
									setLoading(false);
								}}
								fullWidth={false}>
								Import
							</Button>
						) : (
							<Button
								id="confirm-import-credential"
								color="warning"
								onClick={async () => {
									setLoading(true);
									await handleImport();
									setLoading(false);
								}}
								fullWidth={false}>
								Import
							</Button>
						)
					}
					secondaryAction={
						<Button color="danger" invert fullWidth={false} onClick={() => setImportCredentialDialogOpen(false)}>
							Cancel
						</Button>
					}
					preventClosing
				/>
				<WalletCards
					primaryColor={primaryColor}
					recipientData={[...recipientData, ...importedCredsData].filter((recipient) => !sharedCredentialIds.includes(recipient.id))}
					setSharedCredentialIds={addSharedCredentialIds}
					classes={classes}
					baseUrl={baseUrl}
					email={email}
					cardName={cardName}
					cardNameVal={cardNameVal}
					cardNameRef={cardNameRef}
				/>
			</main>
		</Container>
	);
}

WalletComponent.propTypes = {};

export default WalletComponent;
