import { getApi, postApi, useNProgressHook, useWidthHook } from "@cti-workspace/helpers";
import { ISSUER_PREFIX, PUBLIC_PREFIX } from "../lib/Constants";

export default function useNftHook() {
	const { progressStart, progressDone } = useNProgressHook();
	const currentWidth = useWidthHook();

	const getMintedNfts = async (pageSize = 10, pageNumber = 1, search = "") => {
		progressStart();
		try {
			switch (currentWidth) {
				case "xs":
					pageSize = 10;
					break;
				case "sm":
				case "md":
					pageSize = 20;
					break;
				case "lg":
					pageSize = 9;
					break;
				default:
					pageSize = 9;
			}
			const res = await getApi(`/${ISSUER_PREFIX}/getMintedNFTs?pageSize=${pageSize}&pageNumber=${pageNumber}&search=${search || ""}`);
			return res;
		} catch (error) {
			throw error?.message || error;
		} finally {
			progressDone();
		}
	};
	const getPublicNftData = async (id = "") => {
		progressStart();
		try {
			const { mintedNFT } = await getApi(`/${PUBLIC_PREFIX}/NFT/${id}`);
			return mintedNFT;
		} catch (error) {
			throw error?.message || error;
		} finally {
			progressDone();
		}
	};
	const mintNft = async (data = {}) => {
		progressStart();
		try {
			const res = await postApi(`/${ISSUER_PREFIX}/mintNFT`, { ...data });
			return res;
		} catch (error) {
			throw error?.message || error;
		} finally {
			progressDone();
		}
	};
	const getHolderNFTs = async (pageSize = 10, pageNumber = 1, search = "") => {
		progressStart();
		try {
			switch (currentWidth) {
				case "xs":
					pageSize = 10;
					break;
				case "sm":
				case "md":
					pageSize = 20;
					break;
				case "lg":
					pageSize = 9;
					break;
				default:
					pageSize = 9;
			}
			const res = await getApi(`/holder/getMintedNFTs?pageSize=${pageSize}&pageNumber=${pageNumber}&search=${search || ""}`);
			return res;
		} catch (error) {
			console.log(error?.message || error);
			return null;
		} finally {
			progressDone();
		}
	};
	const retryNFT = async (nftId) => {
		progressStart();
		try {
			const res = await postApi(`/${ISSUER_PREFIX}/retryMintNFT`, { nftId });
			return res;
		} catch (error) {
			throw error?.message || error;
		} finally {
			progressDone();
		}
	};
	return {
		getMintedNfts,
		getPublicNftData,
		mintNft,
		getHolderNFTs,
		retryNFT,
	};
}
