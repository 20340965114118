import styled from "styled-components";

import Theme from "../theme/theme";
import Button from "../button/button";

const CredentialBodyContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.3rem;
	font-size: 1.2rem;
	color: ${Theme.colors.grey};
`;

export const CredentialBody = ({
	showView = true,
	showEdit = true,
	showCopy = true,
	showDelete = true,
	onView = () => {
		console.log("credentialBody action click");
	},
	onEdit = () => {
		console.log("credentialBody action click");
	},
	onCopy = () => {
		console.log("credentialBody action click");
	},
	onDelete = () => {
		console.log("credentialBody action click");
	},
	id = "",
}) => {
	return (
		<CredentialBodyContainer>
			{showView && <Button data-testid={`credView_${id}`} buttonType="icon" icon="eye" onClick={onView} invert color="grey" popover="View credential recipients" />}
			{showEdit && <Button data-testid={`credEdit_${id}`} buttonType="icon" icon="edit" onClick={onEdit} invert color="grey" popover="Edit" />}
			{showCopy && <Button data-testid={`credCopy_${id}`} buttonType="icon" icon="copy" onClick={onCopy} invert color="grey" popover="Copy" />}
			{showDelete && <Button data-testid={`credDelete_${id}`} buttonType="icon" icon="delete" onClick={onDelete} invert color="grey" popover="Delete" />}
		</CredentialBodyContainer>
	);
};

export default CredentialBody;
