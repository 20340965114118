import { useEffect, useMemo, useState } from "react";
import * as React from "react";
import { Button, Box, Typography, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from "@material-ui/core";
import useNProgressHook from "../../hooks/useNProgressHook";
import useSettingsHook from "../../hooks/useSettingsHook";
import { parseExcelSheetToJson, checkFileExtension, checkFabricCertDesigner } from "@cti-workspace/helpers";
import { useSnackbar } from "notistack";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import BulkIssue from "../BulkIssue";
import CardButtonGroup from "./CardButtonGroup";
import CapabilityBoundary from "../CapabilityBoundary/CapabilityBoundary";
import CAPABILITIES from "../../capabilities";
import { isCapable } from "../../routes/admin/userAccounts/permissionUtils";
// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogActions from "@material-ui/core/DialogActions";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import WarningAmberIcon from "@material-ui/icons/Warning";
import { Button as Btn, Dialog, DialogActions, DialogTitle, DialogContent, NewCard, NewCardFooter, NewCardHeaderAction } from "@cti-workspace/ui";
import { utils, writeFile } from "xlsx";
import styled from "styled-components";
import { CopySimple, PushPin } from "@phosphor-icons/react";
import { userStore } from "../../store/userStore";
import RevokeManyDialog from "./revokeMany/revokeManyDialog";

// Add Route based Style logic here, when routes are finalized

function CredentialCard({ value, getCredentialEditOptions, handleIssueCredentialButton, reloadCreds = () => {}, setRevokeCredDialog = () => {} }) {
	const { progressStart, progressDone } = useNProgressHook();
	const { canView } = useSettingsHook();
	const { enqueueSnackbar } = useSnackbar();
	const capabilities = userStore((state) => state.capabilities);

	const [openModal, setOpenModal] = useState(null);
	const [open, setOpen] = React.useState(false);
	const [bulkIssueState, setBulkIssueState] = React.useState({
		dialogue: false,
		data: null,
		error: null,
		selectedCred: null,
		modalType: "issuemany",
	});
	const [disableIssueInput, setDisableIssueInput] = useState(false);

	const anchorRef = React.useRef(null);

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	// BulkIssue update 1.4
	// after the file is read, all errors are displayed in a Modal/Dialogue
	// Types of errors
	// File type, format , file not found errors - File Errors
	// Columns missing errors. - Format errors
	// field value missing errors - Data errors

	const bulkIssueXlsx = async (event, selectedCred) => {
		try {
			if (!event.target.files) return;
			const excelSheet = event.target.files[0];
			if (!excelSheet) return;
			if (!checkFileExtension(excelSheet.type, "xlsx")) throw new Error("Please select valid file format");
			const disableBulkIssueDateValidation = canView("disableBulkIssueDateValidation", true);
			const jsonExcelSheet = await parseExcelSheetToJson(excelSheet, true, true, !disableBulkIssueDateValidation);

			if (jsonExcelSheet && jsonExcelSheet.data) {
				if (jsonExcelSheet.data.length < 1) {
					setBulkIssueState({
						dialogue: true,
						data: null,
						modalType: openModal.modalType,
						error: { msg: `Uploaded file must contain column headers and at least one row of data. ${jsonExcelSheet.data.length} rows found.` },
					});
				} else {
					setBulkIssueState({
						dialogue: true,
						error: null,
						data: jsonExcelSheet.data,
						arr: jsonExcelSheet.arr,
						headers: jsonExcelSheet.headers,
						selectedCred,
						modalType: openModal.modalType,
					});
				}
			} else {
				if (jsonExcelSheet.error === "has duplicate column") {
					setBulkIssueState({
						dialogue: true,
						data: null,
						modalType: openModal.modalType,
						error: { msg: `Uploaded file ${jsonExcelSheet.error} column headers ${JSON.stringify(jsonExcelSheet.headers)}` },
					});
				} else if (jsonExcelSheet.error === "The Date is incorrectly formatted. Please ensure you are using the Date Number type (Format > Number > Date) and following the MM/DD/YYYY format.") {
					setBulkIssueState({
						dialogue: true,
						data: null,
						modalType: openModal.modalType,
						error: { msg: jsonExcelSheet.error },
					});
				}
			}
		} catch (err) {
			console.error(err);
			setBulkIssueState({ dialogue: true, error: { ...err }, data: null, selectedCred: null, modalType: "issuemany" });
		}
	};

	const enableIssueCredential = useMemo(() => {
		return isCapable(capabilities, CAPABILITIES.ISSUE_CREDENTIALS);
	}, [capabilities]);

	const enableBulkIssueCredentials = useMemo(() => {
		return isCapable(capabilities, CAPABILITIES.BULK_ISSUE_CREDENTIALS);
	}, [capabilities]);

	const handleOpenModal = async (modalType = "issuemany") => {
		// setOpenModal(true);
		const {
			additionalData: { rendererConfigId },
		} = JSON.parse(value?.definition);

		if (rendererConfigId) {
			await checkFabricCertDesigner(rendererConfigId, setDisableIssueInput);
		}

		switch (modalType) {
			case "issuemany":
				setOpenModal({
					title: "Issue Many",
					modalType,
				});
				break;
			case "savemany":
				setOpenModal({
					title: "Save Many",
					modalType,
				});
				break;
		}
	};

	const handleCloseModal = () => {
		setOpenModal(null);
	};

	const buildBulkIssueCsvToDownload = () => {
		try {
			const { definition, definitionObj: { transcript = [], additionalData: { sections = [] } } = JSON.parse(definition) } = value;
			const headers = [];

			if (openModal.modalType === "issuemany") {
				headers.unshift("First Name", "Last Name", "Contact", "Expiration Date/Duration");
			}

			transcript.map((item) => {
				try {
					if (item.name) {
						headers.push(item.name);
					}
				} catch (error) {}
			});
			sections &&
				sections.map((item) => {
					try {
						const { name, claims = [] } = item;
						claims.map((claim) => {
							headers.push(`Section_${name}_${claim}`);
						});
					} catch (error) {}
				});
			const wb = utils.book_new();
			var ws = utils.aoa_to_sheet([headers]);
			utils.book_append_sheet(wb, ws, "Sheet1");
			writeFile(wb, "Credential-Issuance-Template.xlsx");
		} catch (error) {
			console.error(error);
		}
	};

	// console.log(value);

	const onCopyCrendentialId = async () => {
		try {
			await navigator.clipboard.writeText(value._id);
			enqueueSnackbar("ID copied to clipboard.", { variant: "success" });
		} catch (e) {
			enqueueSnackbar("Failed to copy ID. Please try again.", { variant: "error" });
		}
	};

	const onCopyRendererId = async () => {
		try {
			var additional_data = JSON.parse(value.additionalData);
			await navigator.clipboard.writeText(additional_data.rendererConfigId);
			enqueueSnackbar("Renderer ID copied to clipboard.", { variant: "success" });
		} catch (e) {
			enqueueSnackbar("Failed to copy renderer ID. Please try again.", { variant: "error" });
		}
	};

	return (
		<>
			<Dialog onClose={handleCloseModal} open={Boolean(openModal)} maxWidth="sm">
				<DialogTitle id="customized-dialog-title" disableTypography onClose={handleCloseModal} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					<div>
						<Typography variant="h6">{openModal?.title}</Typography>
						<Typography variant="body2" gutterBottom style={{ fontStyle: "italic" }}>
							Supported file types: xlsx.
						</Typography>
					</div>
					<Btn color="warning" invert leftIcon="cloud-download" onClick={buildBulkIssueCsvToDownload}>
						Template
					</Btn>
				</DialogTitle>
				<DialogContent dividers>
					<Box
						border={2}
						style={{
							borderStyle: "dashed",
							padding: "3rem 0",
							position: "relative",
							opacity: disableIssueInput ? 0.5 : 1,
						}}
						backgroundColor="textSecondary"
						display="flex"
						flexDirection="column"
						alignItems="center">
						<Typography variant="h5" style={{ width: "90%", textAlign: "center" }}>
							{disableIssueInput ? "Please update credential with a valid certificate template first" : "Drag and Drop/Select"}
						</Typography>
						{!disableIssueInput ? (
							<>
								<CloudUploadIcon style={{ fontSize: 80 }} />
								<input
									style={{
										opacity: "0",
										position: "absolute",
										width: "100%",
										height: "100%",
										left: "0",
										right: "0",
										top: "0",
										zIndex: "9",
									}}
									id="csv-upload"
									multiple
									type="file"
									onChange={async (e) => {
										progressStart();
										handleClose(e);
										await bulkIssueXlsx(e, value);
										progressDone();
									}}
									accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
								/>
							</>
						) : (
							<WarningAmberIcon style={{ fontSize: 80 }} />
						)}
					</Box>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={handleCloseModal}>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
			<NewCard
				title={value?.name}
				secondaryTitle={`${value?.recipients} Issued`}
				issuerTags={value?.tags || []}
				imageUrl={value?.imageUrl}
				titleIcon={
					value.pinned && (
						<span title="Pinned">
							<PushPin weight="fill" style={{ marginLeft: "0.2rem" }} color="#9c49edde" />
						</span>
					)
				}
				bodyContent={<CardButtonGroup getCredentialEditOptions={getCredentialEditOptions} definition={value} capabilities={capabilities} />}
				footerContent={
					<NewCardFooter
						id={value._id}
						showLeft={enableIssueCredential}
						onLeftButtonClick={() => handleIssueCredentialButton(value)}
						showCenter={enableBulkIssueCredentials}
						onCenterButtonClick={() => handleOpenModal("issuemany")}
						showRight={canView("bulkSaveCredentials", true) && value?.customCredentials?.length > 0}
						onRightButtonClick={() => handleOpenModal("savemany")}
					/>
				}
				headerActionContent={
					<Btn
						buttonType="dropdown"
						buttonStyle={{ display: "none" }}
						splitButtonStyle={{ border: "1px solid #C0C0C0", borderRadius: "50%", width: "25px", height: "25px" }}
						splitButtonColor="warning"
						splitButtonInvert
						splitButtonOptions={[
							{
								label: "Revoke Many",
								style: {
									fontSize: "0.75rem",
									borderBottom: "0.5px solid #E0E0E0",
								},
								onClick: () => setRevokeCredDialog(value),
							},
							{ label: "Copy Credential Id", style: { fontSize: "0.75rem", borderBottom: "0.5px solid #E0E0E0" }, iconRight: "copy", iconColor: "#9C49ED", iconWeight: "regular", onClick: onCopyCrendentialId },
							{ label: "Copy Renderer Id", style: { fontSize: "0.75rem" }, iconRight: "copy", iconColor: "#9C49ED", iconWeight: "regular", onClick: onCopyRendererId },
						]}
					/>
				}
			/>
			{canView("bulkIssue", true) && (
				<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="top-end">
					{({ TransitionProps }) => (
						<Grow {...TransitionProps}>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList id="split-button-menu" disablePadding>
										<CapabilityBoundary
											render={(disabled) => (
												<MenuItem style={{ padding: "0px" }} disabled={disabled}>
													<Button disabled={disabled} variant="text" color={"primary"} id="bulk-upload" component={"label"} size="small" fullWidth>
														Bulk Issue
														<input
															style={{
																display: "none",
															}}
															id="csv-upload"
															multiple
															type="file"
															onChange={async (e) => {
																progressStart();
																handleClose(e);
																await bulkIssueXlsx(e, value);
																progressDone();
															}}
															accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
														/>
													</Button>
												</MenuItem>
											)}
											capability={CAPABILITIES.BULK_ISSUE_CREDENTIALS}
										/>
										<MenuItem style={{ padding: "0px" }}>
											<Button
												variant="text"
												color={"secondary"}
												id="bulk-issue-template"
												size="small"
												href="https://s3.ca-central-1.amazonaws.com/trybe.public.assets/Trybe.ID/Example+Credential+Issuance+Sheet.xlsx"
												download="Credential-Issuance-Template.xlsx">
												<CloudDownloadIcon fontSize="small" style={{ marginRight: "5px" }} />
												Template
											</Button>
										</MenuItem>
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			)}
			{bulkIssueState?.dialogue && <BulkIssue state={bulkIssueState} setState={setBulkIssueState} reloadCreds={reloadCreds} enqueueSnackbar={enqueueSnackbar} />}
		</>
	);
}

const CredentialContainer = styled.div`
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(3, auto);
		margin-top: 1rem;
		grid-gap: 0.5rem;
		@media (max-width: 1024px) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(9, auto);
		}
		@media (max-width: 768px) {
			grid-template-columns: 1fr;
			grid-template-rows: repeat(10, auto);
		}
	`;

export default function CredCards({ credDefs, getCredentialEditOptions, handleIssueCredentialButton, reloadCreds = () => {}, setRevokeCredDialog = () => {} }) {
	const { progressStart, progressDone } = useNProgressHook();

	useEffect(() => {
		progressStart();
		if (credDefs) {
			progressDone();
		}
	}, [credDefs]);


	return (
		<CredentialContainer>
			{credDefs.map((value, key) =>
				value ? (
					<CredentialCard value={value} key={key} getCredentialEditOptions={getCredentialEditOptions} handleIssueCredentialButton={handleIssueCredentialButton} reloadCreds={reloadCreds} setRevokeCredDialog={setRevokeCredDialog} />
				) : (
					<div key={key}>{JSON.stringify(value)}</div>
				)
			)}
		</CredentialContainer>
	);
}
