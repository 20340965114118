import { Button, Card } from "@cti-workspace/ui";
import { Typography } from "@material-ui/core";

export default function PresentationCard({ data = {}, onClickEdit = () => { }, onClickDelete = () => { }, onClickLaunch}) {
    return <Card elevation={1}>
        <div style={{ padding: "1rem", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
            <div style={{ paddingBottom: "2rem", borderBottom: "0.5px solid rgba(0, 0, 0, 0.12)", height: "100%" }}>
                <Typography variant="h6">{data.name}</Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "1rem" }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
                    <Button buttonType="icon" icon="edit" invert onClick={onClickEdit} /> &nbsp;
                    <Button buttonType="icon" icon="delete" invert onClick={onClickDelete} />
                </div>
                <Button color="warning" fullWidth={false} onClick={onClickLaunch}>Launch</Button>
            </div>
        </div>
    </Card>
}
