import { useState, useEffect } from "react";
import { useFormContext } from "../../providers/form-provider";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const ConnectedCheckbox = (props) => {
	const [checked, setChecked] = useState(props?.defaultChecked || false);

	const handleChange = () => {
		setChecked(!checked);
	};

	return <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} inputProps={{ "aria-label": "primary checkbox" }} {...props} />} label={props.label || "Sovrin"} />;
};

export default ConnectedCheckbox;
