import { useEffect } from "react";
import nProgress from "nprogress";
import "nprogress/nprogress.css";

export default function useNProgressHook() {
	useEffect(() => {
		nProgress.configure({
			parent: "body",
			template: '<div class="bar" role="bar" style="z-index: 99999;"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>',
		});
	}, []);

	const progressStart = () => {
		if (!nProgress.isStarted() && !nProgress.isRendered()) {
			nProgress.start();
		}
	};

	const progressDone = () => {
		nProgress.done();
	};

	const progress = (value) => {
		nProgress.set(value);
	};

	return {
		progress,
		progressStart,
		progressDone,
	};
}