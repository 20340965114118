import { useState, useEffect, useCallback } from "react";
import { useSnackbar } from "notistack";
import { ADAPTER_URL, GOOGLE_CLASSROOM_EMAIL, IS_VERIFIED_TEACHER, ADAPTER_PREFIX, postApi, getApi, getObjectValue } from "@cti-workspace/helpers";
import useWidthHook from "../useWidthHook";

const useGoogleClassroomHook = ({ organizations, connectedCredsData, setConnectedCredsData }) => {
	const name = "Google Classroom";
	const type = "googleClassroom";
	const { enqueueSnackbar } = useSnackbar();
	const [importedCourses, setImportedCourses] = useState(null);
	const [importedCourseWork, setImportedCourseWork] = useState(null);
	const [loggedInEmail, setLoggedInEmail] = useState("");
	const [loggedInProvider, setLoggedInProvider] = useState("");
	const [selectedCourse, setSelectedCourse] = useState({});
	const [selectedCourseWork, setSelectedCourseWork] = useState({});
	const [selectedCredential, setSelectedCredential] = useState({});
	const [verifiedTeacher, setVerifiedTeacher] = useState(false);
	const currentWidth = useWidthHook();
	const [showLoadMore, setShowLoadMore] = useState({ items: [] });

	const isAuthorized = (response) => {
		if (response === "Unauthorized") {
			enqueueSnackbar("Your session has timed out... Please login again.", {
				variant: "error",
			});
			logout();
			return false;
		}
		return true;
	};

	useEffect(() => {
		const email = localStorage.getItem(GOOGLE_CLASSROOM_EMAIL);
		const isVerifiedTeacher = localStorage.getItem(IS_VERIFIED_TEACHER);
		if (email) {
			setLoggedInEmail(email);
			setLoggedInProvider("googleClassroom");
		}
		if (isVerifiedTeacher == "true") setVerifiedTeacher(true);
	}, []);

	const connectItemWithCredential = useCallback(async (courseWork, credential, course) => {
		try {
			// Course work and credential passed in if using the automated single click flow
			const courseWorkToConnect = courseWork || selectedCourseWork;
			const credentialToConnect = credential || selectedCredential;
			const courseToConnect = course || selectedCourse;
			const courseId = courseToConnect.id;
			const courseWorkId = courseWorkToConnect.id;
			const email = localStorage.getItem(GOOGLE_CLASSROOM_EMAIL);

			// Save the binding of the LMS event with the credential
			let request = {
				email,
				credDefId: credentialToConnect._id,
				orgId: organizations.default._id,
				courseId,
				courseName: courseToConnect.name,
				courseWorkId,
				courseWorkName: courseWorkToConnect.title,
				lmsType: type,
			};

			const url = "/adapter/saveConnectedCredential";
			const response = await postApi(url, request);

			setConnectedCredsData([...connectedCredsData, response.credential]);
			// Clear the selections
			setSelectedCourseWork({});
			setSelectedCredential({});

			// Now start listening for events
			request = {
				courseId,
				courseWorkId,
				email,
			};
			const data = await postApi(`/googleClassroom/registerToFeed`, request, {}, true, ADAPTER_URL);
			enqueueSnackbar(`Success!`, {
				variant: "Success",
			});
		} catch (error) {
			enqueueSnackbar("Could not register to feed... please contact support@globaledfoundation.com", {
				variant: "error",
			});
		}
	});

	const deleteConnectedCredential = useCallback(async (courseId, courseWorkId) => {
		try {
			const request = {
				courseId,
				courseWorkId,
			};
			const url = "/adapter/deleteConnectedCredential";
			await postApi(url, request);
			setConnectedCredsData(connectedCredsData.filter((cred) => !(cred.courseId === courseId && cred.courseWorkId === courseWorkId)));
			enqueueSnackbar(`Success!`, {
				variant: "Success",
			});
		} catch (error) {
			enqueueSnackbar("Could not be deleted... please contact support@globaledfoundation.com", {
				variant: "error",
			});
		}
	});

	const getCourseWork = useCallback(async (course) => {
		try {
			const data = await getApi(`/googleClassroom/listCourseWork/${course.id}/${loggedInEmail}`, {}, true, ADAPTER_URL);
			if (isAuthorized(data) && data) {
				return data;
			} else {
				return false;
			}
		} catch (error) {
			console.error({ error });
		}
	});

	const importCourses = useCallback(async () => {
		try {
			const data = await getApi(`/googleClassroom/listCourses/${loggedInEmail}`, {}, true, ADAPTER_URL);
			if (isAuthorized(data)) {
				getCoursesPerPage(1, 1, "", true);
				return true;
			} else {
				return false;
			}
		} catch (error) {
			console.error({ error });
			enqueueSnackbar("Courses could not be downloaded at this time...", {
				variant: "error",
			});
		}
	});

	const reimportCourses = useCallback(async () => {
		try {
			const data = await getApi(`/googleClassroom/reimportCourses/${loggedInEmail}`, {}, true, ADAPTER_URL);
			if (isAuthorized(data)) {
				getCoursesPerPage(1, 1, "", true);
				return true;
			} else {
				return false;
			}
		} catch (error) {
			console.error({ error });
			enqueueSnackbar("Courses could not be downloaded at this time...", {
				variant: "error",
			});
		}
	});

	const importCourseWork = useCallback(async (course) => {
		try {
			setSelectedCourse(course);

			const data = await getApi(`/googleClassroom/listCourseWork/${course.id}/${loggedInEmail}`, {}, true, ADAPTER_URL);
			if (isAuthorized(data) && data) {
				enqueueSnackbar(`Success! Downloaded ${data.length} course work.`, {
					variant: "success",
				});
				setImportedCourseWork(data);
				return true;
			} else {
				return false;
			}
		} catch (error) {
			console.error({ error });
			enqueueSnackbar("Course Work could not be downloaded at this time...", {
				variant: "error",
			});
		}
	});

	const login = useCallback(async () => {
		try {
			/**
			 * @TODO - don't do this and properly send an auth request to adapter when cors fixed
			 */
			window.location = `${ADAPTER_URL}/auth/getNewTokenGoogleClassRoom`;
			// await axios.get(`${ADAPTER_URL}/auth/getNewTokenGoogleClassRoom`);
		} catch (error) {
			enqueueSnackbar("Unfortunately there is an issue connecting to Google Classroom. Please contact support@globaledfoundation.com.", {
				variant: "error",
			});
		}
	});

	const logout = useCallback(() => {
		setLoggedInEmail("");
		setLoggedInProvider("");
		localStorage.removeItem(GOOGLE_CLASSROOM_EMAIL);
	});

	const saveAuthorizationCredentials = useCallback(async (queryString) => {
		try {
			let orgId = organizations.default._id;
			const data = await getApi(`/auth/redirectURLTokenGoogleClassRoom${queryString}&orgId=${orgId}`, {}, true, ADAPTER_URL);
			if ("email" in data) {
				enqueueSnackbar(`Success! Logged in as ${data.email} `, {
					variant: "success",
				});
				setLoggedInEmail(data.email);
				setLoggedInProvider("googleClassroom");
				setVerifiedTeacher(data.verifiedTeacher || false);

				// Save to localStorage for across session auth
				localStorage.setItem(GOOGLE_CLASSROOM_EMAIL, data.email);
				localStorage.setItem(IS_VERIFIED_TEACHER, data.verifiedTeacher || false);
			}
		} catch (error) {
			console.error({ error });
			enqueueSnackbar("The authorization credentials could not be stored...", {
				variant: "error",
			});
		}
	});

	const subscribeToCourseWorkEvents = useCallback(async (selection) => {
		try {
			const email = localStorage.getItem(GOOGLE_CLASSROOM_EMAIL);
			const request = {
				courseId: selection.courseId,
				courseWorkId: selection.courseWorkId,
				email,
			};
			const data = await postApi(`/googleClassroom/registerToFeed`, request, {}, true, ADAPTER_URL);
			console.log({ data });
		} catch (error) {
			console.error({ error });
			enqueueSnackbar("The authorization credentials could not be stored...", {
				variant: "error",
			});
		}
	});

	const updateConnectedCredential = useCallback(async (_id, credentialName, imageUrl) => {
		try {
			const request = {
				_id,
				credentialName,
				imageUrl,
			};
			const url = "/adapter/updateConnectedCredential";
			await postApi(url, request);
			enqueueSnackbar(`Success!`, {
				variant: "Success",
			});
		} catch (error) {
			enqueueSnackbar("Could not be updated... please contact support@globaledfoundation.com", {
				variant: "error",
			});
		}
	});

	const getCoursesPerPage = async (pageSize = 1, pageNumber = 1, search = "", clearCredentials = false) => {
		try {
			switch (currentWidth) {
				case "xs":
					pageSize = 10;
					break;
				case "sm":
					pageSize = 20;
					break;
				case "md":
					pageSize = 20;
					break;
				case "lg":
					pageSize = 9;
					break;
				default:
					pageSize = 12;
			}

			const email = localStorage.getItem(GOOGLE_CLASSROOM_EMAIL);

			const data = await getApi(`/${ADAPTER_PREFIX}/getLMSCourses?pageSize=${pageSize}&pageNumber=${pageNumber}&search=${search}&email=${email}&type=googleClassroom`);
			const { totalItems, items = [], pageSize: currentPageSize, pageNumber: currentPageNumber } = data;
			enqueueSnackbar(`Success! Downloaded ${items.length} courses.`, { variant: "success" });

			if (items) {
				if (clearCredentials) {
					setImportedCourses(items);
					setShowLoadMore({ items, totalItems, currentPageNumber, currentPageSize });
				} else {
					setImportedCourses([...importedCourses, ...items]);
					setShowLoadMore({ items: [...getObjectValue(showLoadMore, "items", []), ...items], totalItems, currentPageNumber, currentPageSize });
				}
			} else {
				throw data;
			}
		} catch (error) {
			if (error[0]) {
				error.message = `Error type:${getObjectValue(error, "[0].keyword")} message: ${getObjectValue(error, "[0].message")}`;
			}
			throw error;
		}
	};

	return {
		connectItemWithCredential,
		deleteConnectedCredential,
		importCourses,
		reimportCourses,
		importedCourses,
		importCourseWork,
		importedCourseWork,
		getCourseWork,
		loggedInEmail,
		loggedInProvider,
		logout,
		name,
		login,
		saveAuthorizationCredentials,
		setImportedCourses,
		setImportedCourseWork,
		setSelectedCourse,
		selectedCourse,
		setSelectedCourseWork,
		selectedCourseWork,
		setSelectedCredential,
		selectedCredential,
		subscribeToCourseWorkEvents,
		type,
		updateConnectedCredential,
		verifiedTeacher,
		getCoursesPerPage,
		showLoadMore,
		setShowLoadMore,
	};
};

export default useGoogleClassroomHook;
