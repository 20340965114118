import { getObjectValue, IMAGE_CONTENT } from "@cti-workspace/helpers";

const transcriptGradeEmptyState = {
	"MMMM dd, yyyy": true,
	"dd MMMM yyyy": true,
	"MM/dd/yyyy": true,
	IMAGE_CONTENT: true,
};

const findInvalidTranscriptGrade = (transcripts, transcriptGrades) => {
	let invalidTranscriptGrade;
	const transcriptNames = transcripts.filter((transcript) => transcript.required).map((transcript) => transcript.name);
	if (!invalidTranscriptGrade) {
		invalidTranscriptGrade =
			transcriptNames.filter((item) => {
				const { [item]: transcriptGradesItem } = transcriptGrades || {};
				const { [transcriptGradesItem]: isEmptyState } = transcriptGradeEmptyState;
				return transcriptGradesItem?.trim() === "" || !!isEmptyState;
			})[0] || "";
	}
	return invalidTranscriptGrade;
};

export const findInvalidSectionRows = (sections, sectionsData) => {
	if (!sections?.length) {
		return null;
	}
	for (let section in sectionsData) {
		const { [section]: row } = sectionsData ?? {};

		for (let value in row) {
			const { [value]: rowValue } = row ?? {};
			if (!rowValue?.trim()) {
				return `${value} of ${section}`;
			}
		}
	}
	return null;
};

export default findInvalidTranscriptGrade;
