import styled from "styled-components";
import PropTypes from "prop-types";

import Theme from "../theme/theme";

const getFontWeightByType = (type) => {
	switch (type) {
		case "h5":
		case "subtitle":
		case "h6":
			return Theme.fontWeights.bold;
		case "caption":
			return "400";
		default:
			return "400";
	}
};

const getFontSizeByType = (type) => {
	switch (type) {
		case "h5": return "1.5rem";
		case "subtitle":
			return "1.1rem";
		case "caption":
			return "0.75rem";
		case "h6": return "1.3rem";
		case "body1": return "1rem";
		case "body2": return "0.9rem";
		case "body3": return "0.8rem";
		case "body4": return "0.8rem";
		case "body5": return "0.7rem";
		case "body6": return "0.6rem";
		default:
			return "0.92rem";
	}
};

const getColorByType = (type) => {
	switch (type) {
		case "secondary":
		case "caption":
			return "#757575";
		case "warning":
			return Theme.colors.warning;
		case "white":
			return "#FFFFFF"
		case "textValue":
			return "rgba(0,0,0,0.54)";
		case "textValue2":
			return "rgba(0,0,0,0.87)";
		case "text":
			return "#324353";
		case "error":
			return "#FF3D33";
		default:
			return "#000000";
	}
};

const TypographyContainer = styled.div((props) => ({
	fontWeight: props.fontWeight || getFontWeightByType(props.type),
	fontSize: getFontSizeByType(props.type),
	cursor: props.onClick ? "pointer" : "auto",
	color: getColorByType(props.color || props.type),
	textAlign: props.textAlign,
	fontFamily: Theme.fonts.body,
	...props.style,
}));

export const Typography = ({ children, type, onClick, style = {}, color = "", textAlign = "left", fontWeight = "", className = "" }) => {
	return (
		<TypographyContainer className={className} type={type} color={color} fontWeight={fontWeight} onClick={onClick ? onClick : null} style={style} textAlign={textAlign}>
			{children}
		</TypographyContainer>
	);
};

Typography.propTypes = {
	type: PropTypes.oneOf(["subtitle", "secondary", null]),
	children: PropTypes.element.isRequired,
	onClick: PropTypes.func,
	style: PropTypes.object,
};

export default Typography;
