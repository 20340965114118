import styled from "styled-components";
import { X, Eye, Check } from "@phosphor-icons/react";

import Theme from "../theme/theme";

const PathwayCardBodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`;

const PathwayCardBodyImage = styled.div`
	text-align: center;
	height: 2rem;
`;

const PathwayCardBodyIcons = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.3rem;
	font-size: 1.2rem;
	color: ${Theme.colors.grey};
`;

const PathwayCardBody = ({
	showView = true,
	showApprove = true,
	showReject = true,
	onView = () => {
		console.log("credentialBody action click");
	},
	onApprove = () => {
		console.log("credentialBody action click");
	},
	onReject = () => {
		console.log("credentialBody action click");
	},
	imageUrl = "https://res.cloudinary.com/dadlqfryt/image/upload/v1571451072/convergence-logos/Convergence_Logo_Final-01_tq5wtd.png",
}) => {
	return (
		<PathwayCardBodyContainer>
			{imageUrl && (
				<PathwayCardBodyImage>
					<img src={imageUrl} style={{ maxHeight: "100%" }} />
				</PathwayCardBodyImage>
			)}
			<PathwayCardBodyIcons>
				{showView && <Eye style={{ cursor: "pointer" }} color={Theme.colors.grey} onClick={onView} />}
				{showApprove && <Check style={{ cursor: "pointer" }} color={Theme.colors.grey} onClick={onApprove} />}
				{showReject && <X style={{ cursor: "pointer" }} color={Theme.colors.grey} onClick={onReject} />}
			</PathwayCardBodyIcons>
		</PathwayCardBodyContainer>
	);
};

export default PathwayCardBody;
