import { Component } from "react";
import QRCode from "qrcode.react";

class QrBlock extends Component {
	render() {
		const { blockProps } = this.props;
		const { entity } = blockProps;
		const { src, styleString, hAlign, vAlign, qrStyleData = {} } = entity.getData();
		// let val = encodeURIComponent(src);
		let size = parseInt(styleString) * 40;
		// const url = `https://chart.googleapis.com/chart?chs=${size}x${size}&cht=qr&chl=${val}&choe=UTF-8`;

		// console.log(url)

		return (
			<span
				style={{
					display: "flex",
					justifyContent: hAlign || "center",
					alignItems: vAlign || "center",
				}}
			>
				<QRCode style={{ width: `${styleString * 3}vw`, height: `${styleString * 3}vw`, ...qrStyleData }} value={src} />
			</span>
		);
	}
}

export default QrBlock;
