import { useNProgressHook } from "@cti-workspace/helpers";
import { Button, Container, Tabs as PresentationTabs } from "@cti-workspace/ui";
import { TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePresentationHook from "../../hooks/usePresentationHook";
import Presentations from "./presentations";

function TabPanel({ children, value = 0, index = 0 }) {
	return value === index ? <div style={{ width: "100%", overflow: "hidden" }}>{children}</div> : null;
}

export default function PresentationCatalog() {
	const [searchValue, setSearchValue] = useState();

	const navigate = useNavigate();

	return (
		<Container maxWidth="lg">
			<div style={{ height: "64px" }} />
			<PresentationTabs
				options={[
					{
						title: `Presentations`,
						component: (
							<>
								<SearchBar onSearch={setSearchValue} />
								<div style={{ marginTop: "1rem", marginBottom: "1rem", display: "flex", justifyContent: "flex-end" }}>
									<Button color="warning" fullWidth={false} leftIcon="plus-circle" invert onClick={() => navigate("/define-presentation/new")}>
										Define Presentation
									</Button>
								</div>
								<Presentations search={searchValue} />
								<div style={{ height: "1rem" }} />
							</>
						),
					},
				]}
				tabContainerStyles={{ marginTop: "1rem" }}
			/>
		</Container>
	);
}

function SearchBar({ onSearch = () => {} }) {
	const [searchValue, setSearchValue] = useState("");

	return (
		<TextField
			id="standard-adornment-amount"
			fullWidth
			color="secondary"
			InputProps={{
				startAdornment: <MagnifyingGlass size="1.3rem" weight="bold" style={{ marginRight: "1rem" }} />,
				endAdornment: (
					<>
						{searchValue && (
							<Button
								buttonType="icon"
								icon="cancel"
								invert
								color="danger"
								iconSize="1.3rem"
								buttonStyle={{ marginRight: "0.5rem" }}
								onClick={() => {
									setSearchValue("");
									onSearch("");
								}}
							/>
						)}
						<Button color="warning" invert={searchValue ? false : true} onClick={() => onSearch(searchValue)}>
							Search
						</Button>
					</>
				),
			}}
			variant="outlined"
			size="small"
			value={searchValue}
			onChange={(e) => setSearchValue(e.target.value)}
		/>
	);
}
