import { useState, useCallback } from "react";
import { useSnackbar } from "notistack";
import { ISSUER_PREFIX, ISSUE_AND_PUBLISH_CERTIFICATES_ROUTE, postApi, getApi, getObjectValue, buildCertificateToIssue } from "@cti-workspace/helpers";
import useNProgressHook from "../useNProgressHook";

const apiGetSchema = {
	type: "array",
	items: {
		type: "object",
		required: ["credentialName", "id", "imageUrl", "issuerAccount", "issuerImageUrl", "issuerOrganization", "issuerOrganizationId", "merkleRoot", "publicUrl", "rawCertificate", "recipient", "recipientName", "state", "targetHash", "_id"],
	},
};

const apiAddchema = {
	type: "array",
	items: {
		required: ["name", "recipient", "additionalData", "id", "issuedOn"],
		type: "object",
		properties: {
			name: {
				type: "string",
				example: "Blockchain Learning Group's Impact Series",
			},
			transcript: {
				type: "array",
			},
			additionalData: { type: "object" },
			recipient: { type: "object" },
			id: {
				type: "string",
				example: "034d1dab-f0c2-49d7-91e3-6a5e9c87f70e",
			},
			issuedOn: { type: "string", example: "2020-04-07T19:51:24.335Z" },
		},
		description: "A built cert that is ready to be issued.",
	},
};

// const addToRevokedCredList = (id) => {
// 	let revokedList = JSON.parse(cookie.get(REVOKED_LIST)) || [];
// 	revokedList = JSON.stringify([...revokedList,id])
// 	cookie.set(REVOKED_LIST,revokedList);
// }

const useCertificateHook = () => {
	const { enqueueSnackbar } = useSnackbar();

	const [certificates, setCertificates] = useState([]);
	const [totalIssued, setTotalIssued] = useState(0);

	const { progressStart, progressDone } = useNProgressHook();

	const protectedSetState = useCallback(async (data, stateFunction, schema) => {
		stateFunction(data);
	});

	// useEffect(() => {
	// 	getCertificates();
	// }, []);

	const getCertificates = async (pageSize = 10, pageNumber) => {
		try {
			progressStart();
			//TODO: remove false check and have pageSize check
			if (pageSize) {
				let data = await getApi(`/${ISSUER_PREFIX}/getIssuedCertsBatchByDefaultOrg?pageSize=${pageSize}&pageNumber=${pageNumber}`);
				setTotalIssued(data.totalItems || 0);
				await protectedSetState(data.items, setCertificates, apiGetSchema);
				progressDone();
				return data;
			} else {
				let data = await getApi(`/${ISSUER_PREFIX}/getIssuedCertsByDefaultOrg`);
				setTotalIssued(data.totalItems || 0);
				await protectedSetState(data, setCertificates, apiGetSchema);
				progressDone();
				return data;
			}
		} catch (error) {
			console.log(error);
			progressDone();
			throw error;
		}
	};

	const searchCertificates = async (searchString, pageSize = 10, pageNumber = 1) => {
		try {
			progressStart();
      let data = await getApi(`/${ISSUER_PREFIX}/defaultSearch?text=${encodeURIComponent(searchString)}&pageSize=${pageSize}&pageNumber=${pageNumber}`);
			await protectedSetState(data.items, setCertificates, apiGetSchema);
			progressDone();
			return data;
		} catch (error) {
			console.log(error);
			progressDone();
			throw error;
		}
	};

	const searchCertificatesByCredDefId = async (id, searchString, pageSize = 10, pageNumber = 1) => {
		try {
			progressStart();
			let data = await getApi(`/${ISSUER_PREFIX}/search?credDefId=${id}&text=${searchString}&pageSize=${pageSize}&pageNumber=${pageNumber}`);
			progressDone();
			return data;
		} catch (error) {
			console.log(error);
			progressDone();
			throw error;
		}
	};

	const getCertificatesByCredDefId = async (id, pageSize, pageNumber, batchLabel = null) => {
		try {
			progressStart();
			//TODO: remove false check and have pageSize check
			const requestBody = {
				pageSize: pageSize ?? undefined,
				pageNumber: pageNumber ?? undefined,
				config: {
					filterBy: [
						{
							field: "credDefId",
							values: [id],
						},
					],
				},
			};
			if (batchLabel) {
				requestBody.config.filterBy.push({
					field: "batchLabel",
					values: [batchLabel],
				});
			}

			if (pageSize) {
				let data = await postApi(`/${ISSUER_PREFIX}/getIssuedCertsBatchByOrg`, requestBody);

				// passing an empty function as there is no state being set
				await protectedSetState(data.items, () => {}, apiGetSchema);
				progressDone();
				return data;
			} else {
				let data = await postApi(`/${ISSUER_PREFIX}/getIssuedCertsByDefaultOrg`, requestBody);
				// passing an empty function as there is no state being set
				await protectedSetState(data, () => {}, apiGetSchema);
				progressDone();
				return data;
			}
		} catch (error) {
			console.log(error);
			progressDone();
			throw error;
		}
	};

	const createCertificate = async ({ recipients, credDef, transcriptGrades, certs = [], sections = [], batchLabel = "", expirationDateDuration, issueType = "ISSUE_ONE", linkingFactor = [] }) => {
		try {
			if (!certs.length) {
				const keys = Object.keys(recipients);

				if (!keys.length) {
					throw new Error("No recipients specified!");
				}

				certs = keys.map((key, i) =>
					buildCertificateToIssue(
						`${getObjectValue(recipients, key).firstName} ${getObjectValue(recipients, key).lastName}`,
						getObjectValue(recipients, key).firstName,
						getObjectValue(recipients, key).lastName,
						getObjectValue(recipients, key).contact,
						credDef,
						transcriptGrades,
						sections,
						expirationDateDuration
					)
				);
			}

			let data = await postApi(`/${ISSUER_PREFIX}/${ISSUE_AND_PUBLISH_CERTIFICATES_ROUTE}`, {
				certs,
				credDefVersion: credDef.version || null,
				credDefId: credDef._id,
				batchLabel,
				processType: issueType,
				options: {
					linkingFactor,
					allowPickup: linkingFactor.length > 0,
				},
			});
			let { savedCerts = [] } = data;
			protectedSetState([...certificates, ...savedCerts], setCertificates, apiGetSchema);
			return data;
		} catch (error) {
			if (error[0]) {
				error.message = `Error type:${getObjectValue(error, "[0].keyword")} message: ${getObjectValue(error, "[0].message")}`;
			}
			throw error;
		}
	};

	const resendEmailForIssuedCert = useCallback(async (credentialId) => {
		try {
			await postApi(`/email/issuedCredential`, { credentialId });
			enqueueSnackbar("Success!", {
				variant: "success",
			});
		} catch (error) {
			enqueueSnackbar("The email could not be resent... please contact support@globaledfoundation.com", {
				variant: "error",
			});
			throw new Error("The email could not be resent... please contact support@globaledfoundation.com");
		}
	});

	const revokeIssuedCert = useCallback(async (credentialId) => {
		try {
			const data = await postApi(`/issuer/revoke/${credentialId}`);
			// addToRevokedCredList(credentialId);
			setCertificates(
				certificates.map((cert) => {
					if (cert.id === data.id) {
						return data;
					} else {
						return cert;
					}
				})
			);
			enqueueSnackbar("Success!", {
				variant: "success",
			});
			return true;
		} catch (error) {
			if (error?.code === "ErrorNotAuthorized") {
				enqueueSnackbar("You are not authorized to perform this operation.", {
					variant: "error",
				});
				throw new Error("You are not authorized to perform this operation");
			} else if (error?.code === 5008) {
				enqueueSnackbar("Credential is already revoked.", {
					variant: "error",
				});
				throw new Error("Credential is already revoked.");
			} else {
				enqueueSnackbar("The credential could not be revoked... please contact support@globaledfoundation.com.", {
					variant: "error",
				});
				throw new Error("The credential could not be revoked... please contact support@globaledfoundation.com");
			}
		}
	});

	return {
		certificates,
		getCertificates,
		searchCertificates,
		createCertificate,
		resendEmailForIssuedCert,
		revokeIssuedCert,
		getCertificatesByCredDefId,
		searchCertificatesByCredDefId,
		totalIssued,
	};
};

export default useCertificateHook;
