import { ACCOUNTS_PREFIX, API_URL, AUTH_PREFIX, SSO_PAYLOAD_VERIFICATION_KEY } from "@cti-workspace/helpers";
import { postApi } from "./api";

async function ForgotPassword(email, whiteLabelID) {
  try {
    /* return (await postApi(`/${AUTH_PREFIX}/globalEd/login`, {
      email, "payloadkey": SSO_PAYLOAD_VERIFICATION_KEY
    },
    {      
    })); */
    
		const url = `/${ACCOUNTS_PREFIX}/forgotPassword/${email}`;
		if (whiteLabelID) {
			await postApi(url, { email, whiteLabelID }, {}, false);
		} else {
			await postApi(url, { email }, {}, false);
		}
		return true;
	} catch (err) {
		throw err;
	}
}

export default ForgotPassword;
