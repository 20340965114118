import styled from "styled-components";
import Theme from "../theme/theme";
import Typography from "../typography/Typography";

const Bar = styled.div`
	height: 16px;
	width: 28px;
	background-color: ${(props) => (props.checked ? Theme.colors.purple : Theme.colors.grey)};
	position: relative;
	border-radius: 15px;
`;

const Circle = styled.div`
	background-color: white;
	width: 12px;
	height: 12px;
	position: absolute;
	transition: left 240ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
	left: ${(props) => (props.checked ? "14px" : "2px")};
	top: 2px;
	border-radius: 15px;
`;
export const Switch = ({ onChange, name, checked, disabled, label, labelPlacement, style = {}, className }) => {
	const getFlexDirection = () => {
		if (labelPlacement === "top") {
			return "column";
		} else if (labelPlacement === "bottom") {
			return "column-reverse";
		} else if (labelPlacement === "right") {
			return "reverse-row";
		}
		return "row";
	};

	const onClick = (e) => {
		if (disabled) {
			return;
		}

		onChange({
			...e,
			target: {
				...e.target,
				value: !checked,
			},
		});
	};

	return (
		<div className={className} style={{ display: "flex", alignItems: "center", flexDirection: getFlexDirection(), gap: "0.5rem", cursor: "pointer", height: "fit-content", ...style }} onClick={onClick} labelPlacement={labelPlacement}>
			<Typography type="body1" style={{ cursor: "pointer" }}>
				{label}
			</Typography>
			<Bar checked={checked}>
				<Circle checked={checked} />
			</Bar>
			<input style={{ display: "none" }} type="checkbox" name={name} checked={checked} disabled={disabled} />
		</div>
	);
};
