import { Grid, TextField } from "@material-ui/core";
import { format } from "date-fns";
import { Button } from "@cti-workspace/ui";

const DataKeyValueField = ({ name, transcriptGrades, settranscriptGrades, required = false, transcriptType = "TEXT", transcriptDefaultValue = "" }) => {
	const isDateField = transcriptType === "DATE";

	function setNewValue(e) {
		const { id, value } = e.target;
		let updateValue = value;
		if (isDateField) updateValue = updateValue ? format(new Date(`${updateValue}T00:00`), transcriptDefaultValue) : transcriptDefaultValue;
		settranscriptGrades({ ...transcriptGrades, [id]: updateValue });
	}

	const { [name]: dataFieldValue = "" } = transcriptGrades ?? {};
	const extraProps = isDateField ? { InputProps: { inputComponent: DateInput } } : { value: dataFieldValue || "" };
	return (
		<Grid container spacing={3}>
			<Grid item xs={2}>
				<TextField readOnly disabled fullWidth={true} value={transcriptType} type="text" label="Type" variant="filled" />
			</Grid>
			<Grid item xs={3}>
				<TextField readOnly disabled fullWidth={true} value={name} type="text" label="Name" variant="filled" />
			</Grid>
			<Grid item xs={7}>
				<TextField
					required={required}
					fullWidth={true}
					label={isDateField ? "" : "Value"}
					id={name}
					type={transcriptType?.toLowerCase()}
					placeholder={isDateField ? dataFieldValue : `PASS / 100%`}
					onChange={setNewValue}
					variant="outlined"
					className="expiryDate"
					helperText={isDateField ? `Date format: ${dataFieldValue}` : ""}
					{...extraProps}
				/>
			</Grid>
		</Grid>
	);
};

const DateInput = ({ onChange, ...props }) => {
	const staticId = `datePicker_ID_${Math.floor(Math.random() * 1000000)}`;
	return (
		<div {...props} style={{ display: "flex", width: "100%", alignItems: "center", position: "relative" }}>
			<div style={{ zIndex: 2, flexGrow: "1" }}>{props.placeholder}</div>
			<input {...props} data-id={staticId} style={{ ...(props.style ?? {}), position: "absolute", top: 0, left: 0, width: 1 }} onChange={onChange} />
			<Button
				buttonType="icon"
				noBorder={true}
				borderRadius={"0"}
				color={"white"}
				icon="calendarBlank"
				iconSize="1.2rem"
				buttonTransparent={true}
				style={{ marginRight: "2px" }}
				buttonHover={true}
				onClick={() => {
					document.querySelectorAll(`[data-id=${staticId}]`)?.[0]?.showPicker();
				}}
			/>
		</div>
	);
};

export default DataKeyValueField;
