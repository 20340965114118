import { TextField } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import { Autocomplete } from "@material-ui/lab";
import CapabilityBoundary from "../CapabilityBoundary/CapabilityBoundary";
import CAPABILITIES from "../../capabilities";
import { Icon, Box } from "@cti-workspace/ui";
import useSettingsHook from "../../hooks/useSettingsHook";

const FilterByBatch = ({ batchLabels, selectedBatch, setSelectedBatch, downloadBatchFilteredCredentials, disabled, downloadBatchFilteredCredentialsPdf }) => {
	const { canView } = useSettingsHook();
	return (
		<Box display="flex" alignItems="center" width={"40%"}>
			<CapabilityBoundary
				render={() => (
					<Autocomplete
						disabled={disabled}
						fullWidth
						options={batchLabels}
						id="select-batch"
						value={selectedBatch}
						onChange={(e, value) => setSelectedBatch(value)}
						renderInput={(params) => <TextField {...params} placeholder="Filter By Batch" variant="outlined" size="small" />}
					/>
				)}
				capability={CAPABILITIES.VIEW_CREDENTIAL_FILTER_BY_BATCH}
				hide
			/>
			{canView("enableBulkDownloadPdf", true) ? (
				<CapabilityBoundary
					render={() => (
						<IconButton
							style={{ marginLeft: "10px" }}
							disabled={!selectedBatch || disabled}
							onClick={downloadBatchFilteredCredentialsPdf}
							aria-controls="simple-menu"
							variant="outlined"
							title="Download Filtered Credentials PDF"
							color="black"
							size="small"
							aria-haspopup="true"
							className="Left-Controls">
							<Icon name={"pdf"} size={24} />
						</IconButton>
					)}
					capability={CAPABILITIES.VIEW_CREDENTIAL_DOWNLOAD_BY_BATCH}
					hide
				/>
			) : null}
			{canView("enableBulkDownloadExcel", true) ? (
				<CapabilityBoundary
					render={() => (
						<IconButton
							style={{ marginLeft: "10px" }}
							disabled={!selectedBatch || disabled}
							onClick={downloadBatchFilteredCredentials}
							aria-controls="simple-menu"
							variant="outlined"
							title="Download Filtered Credentials to Excel"
							color="black"
							size="small"
							aria-haspopup="true"
							className="Left-Controls">
							<Icon name={"microsoftExcelLogo"} size={24} />
						</IconButton>
					)}
					capability={CAPABILITIES.VIEW_CREDENTIAL_DOWNLOAD_BY_BATCH}
					hide
				/>
			) : null}
		</Box>
	);
};

export default FilterByBatch;
