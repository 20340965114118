import { Controller, useFieldArray, useWatch, useFormContext } from "react-hook-form";
import { Button, Switch, FieldWrapper, TextField, Card, Typography as Text, Dialog, Typography, ComboBox, Icon } from "@cti-workspace/ui";
import styled from "styled-components";
import { IMAGE_CONTENT, getObjectValue, CONTENT_TYPE, FABRIC_PAGE_TYPES } from "@cti-workspace/helpers";
import useSettingsHook from "../../hooks/useSettingsHook";
import AlertDialog from "../AlertDialog";
import { useState } from "react";
import format from "date-fns/format";

const todayDate = new Date();
const DateOption = [
	{ label: format(todayDate, "MMMM dd, yyyy"), value: "MMMM dd, yyyy" },
	{ label: format(todayDate, "dd MMMM yyyy"), value: "dd MMMM yyyy" },
	{ label: format(todayDate, "MM/dd/yyyy"), value: "MM/dd/yyyy" },
];

const SwitchV2 = styled(Switch)`
	position: relative;
	::after {
		content: "";
		position: absolute;
		height: 100%;
		width: 2px;
		background: #b6b6b6;
		right: -10px;
	}
`;

const ComboBoxV2 = styled(ComboBox)`
	position: relative;
	::after {
		content: "";
		position: absolute;
		height: 100%;
		width: 2px;
		background: #b6b6b6;
		right: -7px;
		top: 0;
	}
`;
const Description = styled.p`
	color: rgba(0, 0, 0, 0.54);
	font-size: 0.75rem;
	line-height: 1.2rem;
	margin: 0px;
`;

const contentOptionIcon = {
	TEXT: "textT",
	NUMBER: "hashStraight",
	DATE: "calendar",
	IMAGE: "image",
};

const FieldSwitchCase = ({name,index,value,onChange,item}) =>{
	if(item.type === CONTENT_TYPE.HTML){
		return (
			<TextField
			size="small"
			variant="static"
			name={name}
			id={`${name}${index}`}
			data-testid={`${name}${index}_value`}
			type={item.type?.toLowerCase() ?? "text"}
			value={value}
			onChange={onChange}
			multiline={4}
			// label="Value"
			placeholder={"Default criteria value"}
			// helperText="Default criteria value"
			style={{
				visibility: item.value === IMAGE_CONTENT || item.type === CONTENT_TYPE.DATE ? "hidden" : "visible",
				flexGrow: 1,
			}}
		/>
		)
	}
	if (item.value === IMAGE_CONTENT || item.type === CONTENT_TYPE.IMAGE) {
		return (
			<span style={{ opacity: 0.8, fontSize: "0.7rem" }}>
			Supported file types include: png and jpg.
			<br />
			{"Each file must be < 1MB"}
		</span>
		)
	} else {
		return (
			<TextField
			size="small"
			variant="static"
			name={name}
			id={`${name}${index}`}
			data-testid={`${name}${index}_value`}
			type={item.type?.toLowerCase() ?? "text"}
			value={value}
			onChange={onChange}
			// label="Value"
			placeholder={"Default criteria value"}
			// helperText="Default criteria value"
			style={{
				visibility: item.value === IMAGE_CONTENT || item.type === CONTENT_TYPE.DATE ? "hidden" : "visible",
				flexGrow: 1,
			}}
		/>
		)
	}
}



const Content = ({ control, register, name, errors, setEncryptingContent, enableImageContentRow = false, setFabricContentVariables = () => {}, fabricContentVariables = {}, setFabricContentChange = () => {}, fabricContentChange = {} }) => {
	const { watch, setValue } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	const fieldsWatch = watch(name, fields);
	const fieldStructure = { key: "", value: "" };
	const { [name]: errored } = errors;
	const [showDeleteDialog, setShowDeleteDialog] = useState(-1);

	// console.log({ errored });

	const values = useWatch({ control });


	const { getPdfSettings } = useSettingsHook();

	const orgEncryptingKey = getPdfSettings()?.encryptOptions?.encryptKey;

	const updateEncryptingContent = (e, index = null) => {
		if (setEncryptingContent) {
			const encryptingContent = values?.transcripts ? [...values.transcripts] : [];
			if (index !== null) {
				encryptingContent.splice(index, 1);
			}
			if (orgEncryptingKey && encryptingContent[0]?.key !== orgEncryptingKey) {
				encryptingContent.unshift({ key: orgEncryptingKey });
			}
			setEncryptingContent(encryptingContent.filter((field) => field?.value !== IMAGE_CONTENT && field?.key !== "" && field?.key !== null).map((field) => ({ key: field.key })));
		}
		const arrContentChange = Object.keys(fabricContentChange || {});
		if (arrContentChange.length && arrContentChange[0].toLowerCase() !== e.target.value?.toLowerCase()) {
			setFabricContentChange({ [arrContentChange[0]]: e.target.value?.toLowerCase() });
		}
	};

	const handleFocus = (e) => {
		if (Object.keys(fabricContentVariables?.content || {}).findIndex((item) => item && item.toLowerCase() === e.target.value?.toLowerCase()) > -1) {
			setFabricContentChange({
				[e.target.value]: "",
			});
		}
	};

	const isDuplicateContent = (value, index) => {
		let errorVal;
		fieldsWatch.forEach((field, i) => {
			if (field.key === value && index !== i) {
				errorVal = "Criteria already exists";
			}
		});
		return errorVal;
	};

	const getHelperText = (index, value) => {
		let obj = {};
		let text = value === IMAGE_CONTENT ? "This is a File Input. This is not displayed in your certificate" : "This will be displayed on your certificate. Name it carefully";
		const currentError = getObjectValue(errored, index);
		if (currentError) {
			text = getObjectValue(currentError, "key.message", "");
			obj.helperText = text;
			obj.error = true;
		}
		return { ...obj };
	};

	const removeContentIndex = (i) => {
		const fabricContentValue = getObjectValue(fabricContentVariables.content, values?.transcripts?.[i]?.key?.toLowerCase(), "");
		if (fabricContentValue) {
			setShowDeleteDialog(i);
		} else {
			remove(i);
		}
	};

	const removeContentFromFabric = (i) => {
		setShowDeleteDialog(-1);
		const fabricContentValue = getObjectValue(fabricContentVariables.content, values?.transcripts?.[i].key?.toLowerCase(), "");
		setFabricContentVariables((prevValue) => {
			remove(i);
			const toRemove = { [values?.transcripts[i].key]: fabricContentValue };
			delete prevValue.content[values?.transcripts[i].key];
			return {
				...prevValue,
				toRemove,
			};
		});
	};

	return (
		<Card hasBorder elevation={0} borderRadius="2px" style={{ backgroundColor: "#FAFAFA", padding: "0.8rem", margin: "0" }}>
			<Text type="body1">Variables</Text>
			<Description>Criteria to be met before earning the credential</Description>
			<div style={{ display: "flex", gap: "0.7rem", marginTop: "0.5rem" }}>
				<Button
					data-testid="addCredContent"
					popover={`Add a new row of content`}
					leftIcon="textT"
					iconStyle={{ color: "#9c49ed" }}
					color="warning"
					invert
					leftIconColor="black"
					disabled={errored?.length > 0}
					onClick={() => append({ key: "", value: "", type: CONTENT_TYPE.TEXT, required: true })}
					buttonStyle={{ padding: "6px" }}>
					Add Text
				</Button>
				<Button
					data-testid="addCredContent"
					popover={`Add a new row of content`}
					leftIcon="html"
					iconStyle={{ color: "#9c49ed" }}
					color="warning"
					invert
					leftIconColor="black"
					disabled={errored?.length > 0}
					onClick={() => append({ key: "", value: "", type: CONTENT_TYPE.HTML, required: true })}
					buttonStyle={{ padding: "6px" }}>
					Add HTML
				</Button>
				<Button
					data-testid="addCredContent"
					popover={`Add a new row of content`}
					iconStyle={{ color: "#9c49ed" }}
					leftIcon="hashStraight"
					color="warning"
					invert
					leftIconColor="black"
					disabled={errored?.length > 0}
					onClick={() => append({ key: "", value: "", type: CONTENT_TYPE.NUMBER, required: true })}
					buttonStyle={{ padding: "6px" }}>
					Add Number
				</Button>
				{enableImageContentRow && (
					<Button
						popover={`Add a new row of image`}
						leftIcon="image"
						iconStyle={{ color: "#9c49ed" }}
						color="warning"
						invert
						leftIconColor="black"
						disabled={errored?.length > 0 || fieldsWatch.filter((item) => item?.value === IMAGE_CONTENT).length >= 2}
						onClick={() => append({ key: "", value: IMAGE_CONTENT, type: CONTENT_TYPE.IMAGE, required: true })}
						buttonStyle={{ padding: "6px" }}>
						Add Image
					</Button>
				)}
				<Button
					data-testid="addCredContent"
					popover={`Add a new row of content`}
					leftIcon="calendar"
					iconStyle={{ color: "#9c49ed" }}
					color="warning"
					invert
					leftIconColor="black"
					disabled={errored?.length > 0}
					onClick={() => append({ key: "", value: "MM/dd/yyyy", type: CONTENT_TYPE.DATE, required: true })}
					buttonStyle={{ padding: "6px" }}>
					Add Date
				</Button>
			</div>

			{fields.map((fieldItem, index) => {
				const item = fieldsWatch[index] || fieldItem;
				if (!item) return null;
				return (
					<FieldWrapper key={item.id || index} style={{ marginTop: "1rem" }} contentStyle={{ padding: 15 }} title={item.value === IMAGE_CONTENT ? "New Image Content" : "New Content"} isSecondary={true} hideTitle={true}>
						<div
							style={{
								display: "grid",
								gap: "0.8rem",
								gridTemplateColumns: "0.5fr 0.7fr 0.7fr auto",
							}}>
							<Controller
								control={control}
								name={`${name}[${index}].type`}
								defaultValue={fields[index].type || (fields[index].value === IMAGE_CONTENT ? CONTENT_TYPE.IMAGE : CONTENT_TYPE.TEXT)}
								render={(propsField) => {
									const { value, onChange, name } = propsField;
									return (
										<ComboBoxV2
											size="small"
											name={name}
											placeholder="Type"
											options={Object.values(CONTENT_TYPE).map((contentOption) => ({
												label: contentOption,
												value: contentOption,
												icon: contentOptionIcon[contentOption],
												isDisabled: contentOption === "IMAGE" && fieldsWatch.find((item) => item?.value === IMAGE_CONTENT),
											}))}
											getOptionValue={(item) => item["value"]}
											getOptionLabel={(item) => (
												<div style={{ display: "flex", alignItems: "center", gap: 5 }}>
													<Icon name={item.icon} size={"1rem"} weight={"fill"} />
													<span style={{ fontSize: 12 }}>{item.label}</span>
												</div>
											)}
											value={{ label: value, value: value, icon: contentOptionIcon[value] }}
											helperText={" "}
											onChange={(e) => {
												const currentTranscript = name.split(".");
												currentTranscript.pop();
												setValue(currentTranscript.join("."), {
													...item,
													type: e["value"],
													value: e["value"] === CONTENT_TYPE.IMAGE ? IMAGE_CONTENT : "",
												});
											}}
											isOptionDisabled={(option) => option.isDisabled}
										/>
									);
								}}
							/>

							<Controller
								control={control}
								name={`${name}[${index}].key`}
								rules={{
									validate: (val) => isDuplicateContent(val, index),
									required: "Name is required",
								}}
								defaultValue={fields[index].key}
								render={(propsField) => {
									const { value, onChange, name } = propsField;
									return (
										<TextField
											variant="static"
											name={name}
											size="small"
											value={value}
											id={`${name}${index}`}
											data-testid={`${name}${index}_key`}
											onFocus={handleFocus}
											onBlur={(e) => updateEncryptingContent(e, index)}
											placeholder={"Name*"}
											onChange={onChange}
											{...getHelperText(index, item.value)}
											style={{ flexGrow: 1 }}
										/>
									);
								}}
							/>
							{item.hasOwnProperty("value") && item.type !== CONTENT_TYPE.DATE && (
								<Controller
									control={control}
									name={`${name}[${index}].value`}
									defaultValue={item.value !== IMAGE_CONTENT ? item.value : IMAGE_CONTENT}
									render={(propsField) => {
										const { value, onChange, name } = propsField;
										return (<FieldSwitchCase name={name} index={index} value={value} onChange={onChange} item={item} />)
									}}
								/>
							)}
							{item.type === CONTENT_TYPE.DATE && (
								<Controller
									control={control}
									name={`${name}[${index}].value`}
									defaultValue={item.value || "MM/dd/yyyy"}
									render={(propsField) => {
										const { value, onChange, name } = propsField;
										return (
											<div
												style={{
													position: "relative",
												}}>
												<ComboBox
													size="small"
													style={{ maxWidth: "250px" }}
													placeholder="Select format"
													options={DateOption}
													isSearchable={false}
													getOptionValue={(item) => item["value"]}
													getOptionLabel={(item) => item.label}
													value={DateOption.find((option) => {
														return option.value === value;
													})}
													onChange={(e) => onChange(e["value"])}
												/>
												<p
													style={{
														opacity: "0.8",
														fontSize: "0.6rem",
														position: "absolute",
														margin: 0,
													}}>
													{"This is the format you must follow during issuance."}
												</p>
											</div>
										);
									}}
								/>
							)}
							<Controller
								control={control}
								name={`${name}[${index}].required`}
								defaultValue={item.required === undefined ? true : item.required}
								render={(propsField) => {
									const { value, onChange, name } = propsField;
									return (
										<div style={{ display: "flex", alignItems: "center", gap: "0.8rem", height: "100%" }}>
											<SwitchV2 name={name} color="secondary" size="small" checked={value} onChange={onChange} label={"Required"} labelPlacement="left" />
											<Button buttonType="icon" icon="delete" iconSize="1rem" borderRadius={"5px"} color={"white"} iconStyle={{ color: "grey" }} noBorder={true} onClick={() => removeContentIndex(index)} />
										</div>
									);
								}}
							/>
						</div>
					</FieldWrapper>
				);
			})}

			<AlertDialog
				title="Confirm Delete"
				body={<Typography type="body2">This item is currently used on your certificate design. Would you still like to remove it?</Typography>}
				open={showDeleteDialog >= 0}
				continueLabel="Confirm"
				continueToAdd={() => removeContentFromFabric(showDeleteDialog)}
				cleanup={() => setShowDeleteDialog(-1)}
			/>
		</Card>
	);
};

export default Content;
