import { createStyles, Theme } from "@material-ui/core/styles";

const Styles = (theme) =>
	createStyles({
		root: {
			flex: "1 1 100%",
		},
		toolbar: theme.mixins.toolbar,
	});

export default Styles;
