import { useState, useEffect } from "react";
import { flushSync } from "react-dom";
import { Box, Button, Container, Table } from "@cti-workspace/ui";
import HeaderWithLIne from "../../components/HeaderWithLine";
import styled from "styled-components";
import { IconButton, Tooltip, Typography, Popover, Switch, FormControl, FormControlLabel } from "@material-ui/core";
import { Select } from "react-functional-select";
import { useSnackbar } from "notistack";
import { Check, Warning } from "@phosphor-icons/react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import QRCodeViewer from "../../components/QRCodeViewer/QRCodeViewer";
import QRCodeScanner from "../../components/QRCodeScanner/QRCodeScanner";
import { getErrMsg, FACING_MODES, getPaginatedResponseItems, getApi, postApi } from "@cti-workspace/helpers";

const ContainerGrid = styled.div`
	display: grid;
	grid-template-columns: 300px 1fr;
	grid-template-rows: auto;
	margin-top: 1rem;
	@media only screen and (max-width: 960px) {
		grid-template-columns: 1fr;
		grid-template-rows: auto auto;
	}

	> div {
		padding: 0.5rem;
	}
`;

const SubjectPreview = styled.div`
	background-color: #263238;
	color: #eeffff;
	margin: 0 !important;
	padding: 1rem;
`;
const isMobileView = () => window.screen.width <= 768;

export default function VerifyCredentials() {
	const { enqueueSnackbar } = useSnackbar();

	const [presentationDefinitions, setPresentationDefinitions] = useState([]);
	const [selectedPresentationDefinitions, setSelectedPresentationDefinitions] = useState([]);
	const [verifiedCredentials, setVerifiedCredentials] = useState([]);
	const [showQrScanner, setShowQrScanner] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [previewSubject, setPreviewSubject] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const [facingMode, setFacingMode] = useState(() => (isMobileView() ? FACING_MODES.ENVIRONMENT : FACING_MODES.USER));

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const fetchVerifiedCredentials = async () => {
		try {
			let { items } = await getPaginatedResponseItems("/verifier/getVerifiedCertsBatchByDefaultOrg");
			items = items.map((vC) => ({
				...vC,
				errors: vC?.errors?.map((error) => error.error),
			}));
			setVerifiedCredentials(items);
		} catch (e) {
			enqueueSnackbar("Failed to verified credentials. Please try again.", { variant: "error" });
		}
	};

	const fetchPresentationDefinitions = async () => {
		try {
			const data = await getApi("/presentation/getPresentationDefinitions");
			setPresentationDefinitions(data);
		} catch (e) {
			const { code } = e;
			if (code === "NotFound") {
				setPresentationDefinitions([]);
			} else {
				enqueueSnackbar(getErrMsg(e), { variant: "error" });
			}
		}
	};

	const onClickStartStop = () => {
		setShowQrScanner(!showQrScanner);
	};

	const onResult = async (result, error) => {
		if (result) {
			enqueueSnackbar("1. QR code scanned.", { variant: "info" });

			const resultText = result?.text;

			if (resultText?.startsWith("B")) {
				enqueueSnackbar("2. Valid QR Code. Verifying ...", { variant: "success" });

				const verificationRequest = {
					credential: resultText,
					selectedPresentationDefinitions: selectedPresentationDefinitions.map((pD) => pD.id),
					verificationRequirements: {
						presentationDefinitions: selectedPresentationDefinitions,
					},
				};
				try {
					await postApi("/verifier/verifyCredential", verificationRequest);
				} catch (e) {
					console.error(e);
				} finally {
					enqueueSnackbar("3. Updating verified credentials ...", { variant: "info" });
					setShowQrScanner(false);
					fetchVerifiedCredentials();
				}
			} else {
				enqueueSnackbar("2. Invalid QR Code. A valid QR Code content should start with B. Please try again.", { variant: "error" });
				setShowQrScanner(false);
			}
		}
		if (error) {
			console.info(error);
		}
	};

	const onToggleCamera = () => {
		flushSync(() => {
			setShowQrScanner(false);
		});
		if (facingMode === FACING_MODES.ENVIRONMENT) {
			setShowQrScanner(true);
			setFacingMode(FACING_MODES.USER);
		}
		if (facingMode === FACING_MODES.USER) {
			setShowQrScanner(true);
			setFacingMode(FACING_MODES.ENVIRONMENT);
		}
	};

	const onPresentationDefinitionSelect = (values) => {
		setSelectedPresentationDefinitions(values);
	};

	const handleClick = (event, subject) => {
		setPreviewSubject(subject);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		fetchPresentationDefinitions();
		fetchVerifiedCredentials();
	}, []);

	let timer = null;

	useEffect(() => {
		if (refresh) {
			timer = setInterval(async () => {
				try {
					await fetchVerifiedCredentials();
				} catch (error) {
					console.error(error);
				}
			}, 5000);
		} else if (!refresh && timer) {
			clearInterval(timer);
		}
		return () => clearInterval(timer);
	}, [refresh]);

	return (
		<Container maxWidth="xl">
			<div style={{ height: "64px" }} />
			<Box style={{ backgroundColor: "white", height: "100%", padding: "1rem" }}>
				<HeaderWithLIne variant={"h4"} title="Verify Credentials" />
				<ContainerGrid>
					<div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "1rem" }}>
						{showQrScanner ? (
							<div style={{ width: "20rem", height: "25rem" }}>
								<QRCodeScanner
									onResult={onResult}
									style={{
										width: "100%",
									}}
									preferredCamera={facingMode}
								/>
							</div>
						) : (
							<QRCodeViewer value={{}} />
						)}
						{showQrScanner && isMobileView() && (
							<Button buttonType="icon" title="Switch Cameras" color="warning" onClick={onToggleCamera} icon="rotate" buttonStyle={{ marginBottom: "1rem" }}>
								Switch Camera
							</Button>
						)}
						<Button color="warning" onClick={onClickStartStop} disabled={!selectedPresentationDefinitions.length}>
							Start / Stop Scanning
						</Button>
						<div style={{ width: "100%", marginTop: "1rem" }}>
							<Typography variant="subtitle1">Select Presentation Definition(s)</Typography>
							<Select options={presentationDefinitions} onOptionChange={onPresentationDefinitionSelect} getOptionValue={(option) => option.id} getOptionLabel={(option) => option.name} isClearable isMulti closeMenuOnSelect={false} />
						</div>
					</div>
					<div style={{ width: "100%" }}>
						<Table
							config={{
								headers: [
									{ label: "Verified At", prop: "verifiedAt" },
									{
										label: "Credential Type",
										prop: "credentialType",
										cell: (row) => {
											return (
												<Tooltip title={row.credentialType}>
													<p>{row.credentialType}</p>
												</Tooltip>
											);
										},
									},
									{
										label: "Subject",
										prop: "subject",
										cell: (row) => {
											return (
												<Tooltip title="Click to view">
													<IconButton color="secondary" size="small" aria-describedby={id} onClick={(e) => handleClick(e, row.subject)}>
														<VisibilityIcon fontSize="small" />
													</IconButton>
												</Tooltip>
											);
										},
									},
									{ label: "Issued By", prop: "issuedBy" },
									{
										label: "Status",
										prop: "status",
										cell: (row) => {
											if (row.status === "valid") {
												return (
													<Tooltip title="This credential is valid.">
														<IconButton style={{ color: "#9d49ee" }} size="small">
															<Check size={25} />
														</IconButton>
													</Tooltip>
												);
											} else if (row.status === "invalid") {
												return (
													<Tooltip title="This credential is invalid.">
														<IconButton style={{ color: "#e82121" }} size="small">
															<Warning size={25} />
														</IconButton>
													</Tooltip>
												);
											}
											return null;
										},
									},
									{
										label: "Error (s)",
										prop: "errors",
										cell: (row) => {
											return (
												<p style={{ display: "flex", flexDirection: "column" }}>
													{row.errors.map((error, index) => (
														<div key={error}>
															{error}
															{index <= row.errors.length - 2 && <hr />}
														</div>
													))}
												</p>
											);
										},
									},
								],
							}}
							data={verifiedCredentials}
							pagination
							showSearch={false}
							leftHeader={() => (
								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-end",
										height: "100%",
										paddingRight: "1rem",
									}}>
									<FormControl>
										<FormControlLabel labelPlacement="start" control={<Switch value={refresh} size="medium" onChange={(e, val) => setRefresh(val)} />} label="Refresh" />
									</FormControl>
								</div>
							)}
						/>
					</div>
				</ContainerGrid>
			</Box>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}>
				<SubjectPreview>
					<pre>{JSON.stringify(previewSubject, null, 2)}</pre>
				</SubjectPreview>
			</Popover>
		</Container>
	);
}
