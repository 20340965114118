import he from "he";

const isHTML = (text) => {
	try {
		const fragment = new DOMParser().parseFromString(text, "text/html");
		return fragment.body.children.length > 0;
	} catch (error) {
		console.error(error)
	}
	return false;
};

const createMarkup = (txt) => {
	let val = he.decode(txt);
	let txtz = document.createElement("textarea");
	txtz.innerHTML = val;
	return { __html: txtz.value };
};
export { isHTML, createMarkup };
