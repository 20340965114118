//Text Capitalization
export const capitalizeText = (text) => {
	return text.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
};

//find hasOwnProperty of object
export const hasProperty = (obj, path) => {
	return !!path.split(".").reduce((obj, prop) => {
		return obj && obj.hasOwnProperty(prop) ? getObjectValue(obj, prop) : undefined;
	}, obj);
};

//get the value of object
export const getObjectValue = (obj, path, defaultValue) => {
	try {
		const travel = (regexp) =>
			String.prototype.split
				.call(path, regexp)
				.filter(Boolean)
				.reduce((res, key) => {
					const { [key]: objectValue } = res ?? {};
					if (key === "constructor" && typeof objectValue === "function") {
						return undefined;
					} else if (key == "__proto__") {
						return undefined;
					} else if (res !== null && res !== undefined) {
						return objectValue;
					} else {
						return res;
					}
				}, obj);
		const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
		return result === undefined || result === obj ? defaultValue : result;
	} catch (error) {
		return defaultValue;
	}
};

export function safeGet(object, key) {
	const { [key]: objectValue } = object;
	if (key === "constructor" && typeof objectValue === "function") {
		return;
	}
	if (key == "__proto__") {
		return;
	}
	return objectValue;
}

//match value & return object
export const findObject = (obj, matchValue) => {
	return obj.find((item) => item._id === matchValue);
};

//check empty object or array
export const isEmptyObject = (obj) => {
	if (obj !== Object(obj)) {
		return true;
	} else if ((Object.keys(obj).length !== 0 && Object.getPrototypeOf(obj) === Object.prototype) || (Array.isArray(obj) && obj.length !== 0)) {
		return false;
	} else {
		return true;
	}
};

//find unique Object
export const findUniqueObject = (arr, iteratee) => {
	if (typeof iteratee === "string") {
		const prop = iteratee;
		iteratee = (item) => getObjectValue(item, prop);
	}

	return arr.filter((x, i, self) => i === self.findIndex((y) => iteratee(x) === iteratee(y)));
};

//order by text
export const orderBy = (arrObject, item, order) => {
	return arrObject?.sort((a, b) => {
		const aUpper = getObjectValue(a, item).toUpperCase();
		const bUpper = getObjectValue(b, item).toUpperCase();
		return aUpper.toUpperCase() > bUpper.toUpperCase() ? (order == "asc" ? 1 : order == "desc" ? -1 : 1) : order == "asc" ? -1 : order == "desc" ? 1 : -1;
	});

	// return arrObject.sort(function (x, y) {
	//     let a = x[item].toUpperCase(),
	//         b = y[item].toUpperCase();
	//     return a == b ? 0 : a > b ? 1 : -1;
	// });
};
